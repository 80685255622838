import {
    FINISH_BASIC_DATA_SAVE,
    LOADING_ENUMS,
    PENDING_ACTIVITYTYPE_ENUM,
    PENDING_CALLDIRECTION_ENUM,
    PENDING_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM,
    PENDING_CARE_BOX_INGREDIENTS_STATUS_ENUM,
    PENDING_CARE_BOX_REJECTION_REASON_ENUM,
    PENDING_CARE_BOX_STATUS_ENUM,
    PENDING_CAREDEGREE_ENUM,
    PENDING_CAREDEGREEAPPLICATION_ENUM,
    PENDING_CAREOTHERREQUIREMENTS_ENUM,
    PENDING_CITIES_ENUM,
    PENDING_CONTRACTRATETYPE_ENUM,
    PENDING_CONTRACTSTATUS_ENUM,
    PENDING_DISEASES_ENUM,
    PENDING_DS_TEMPLATES_ENUM,
    PENDING_HEALTH_INCURANCE_ENUM,
    PENDING_HEALTHSTATE_ENUM,
    PENDING_INVOICEAPPROVALSTATUS_ENUM,
    PENDING_INVOICECORRECTION_ENUM,
    PENDING_INVOICEPAYMENT_ENUM,
    PENDING_LEAD_CAREBOX_STATUS_ENUM,
    PENDING_LEADSOURCE_ENUM,
    PENDING_LEADSTATUS_ENUM,
    PENDING_LIVEALONE_ENUM,
    PENDING_MARKETING_ENUM,
    PENDING_MULTIPLIERS_CATEGORIES_ENUM,
    PENDING_PROMEDICA_PRODUCTS_PRICES_ENUM,
    PENDING_RATE_DATA_ENUM,
    PENDING_REJECT_REASON_ENUM,
    PENDING_SERVICE_TYPE_DE_ENUM,
    PENDING_SITTERS_AVAILABILITY_ENUM,
    PENDING_SMOKINGATTITUDE_ENUM,
    PENDING_TASKPRIORITY_ENUM,
    PENDING_TRIP_TRACKING_STATUS_ENUM,
    REMOVE_ACTIVITYTYPE_ENUM,
    REMOVE_ALL_BASIC_DATA_SAVE,
    REMOVE_CALLDIRECTION_ENUM,
    REMOVE_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM,
    REMOVE_CARE_BOX_INGREDIENTS_STATUS_ENUM,
    REMOVE_CARE_BOX_REJECTION_REASON_ENUM,
    REMOVE_CARE_BOX_STATUS_ENUM,
    REMOVE_CAREDEGREE_ENUM,
    REMOVE_CAREDEGREEAPPLICATION_ENUM,
    REMOVE_CAREOTHERREQUIREMENTS_ENUM,
    REMOVE_CITIES_ENUM,
    REMOVE_CONTRACTRATETYPE_ENUM,
    REMOVE_CONTRACTSTATUS_ENUM,
    REMOVE_DISEASES_ENUM,
    REMOVE_DS_TEMPLATES_ENUM,
    REMOVE_HEALTH_INCURANCE_ENUM,
    REMOVE_HEALTHSTATE_ENUM,
    REMOVE_INVOICEAPPROVALSTATUS_ENUM,
    REMOVE_INVOICECORRECTION_ENUM,
    REMOVE_INVOICEPAYMENT_ENUM,
    REMOVE_LEAD_CAREBOX_STATUS_ENUM,
    REMOVE_LEADSOURCE_ENUM,
    REMOVE_LEADSTATUS_ENUM,
    REMOVE_LIVEALONE_ENUM,
    REMOVE_MARKETING_ENUM,
    REMOVE_MULTIPLIERS_CATEGORIES_ENUM,
    REMOVE_PROMEDICA_PRODUCTS_PRICES_ENUM,
    REMOVE_RATE_DATA_ENUM,
    REMOVE_REJECT_REASON_ENUM,
    REMOVE_SERVICE_TYPE_DE_ENUM,
    REMOVE_SITTERS_AVAILABILITY_ENUM,
    REMOVE_SMOKINGATTITUDE_ENUM,
    REMOVE_TASKPRIORITY_ENUM,
    REMOVE_TRIP_TRACKING_STATUS_ENUM,
    SET_ACTIVITYTYPE_ENUM,
    SET_CALLDIRECTION_ENUM,
    SET_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM,
    SET_CARE_BOX_INGREDIENTS_STATUS_ENUM,
    SET_CARE_BOX_REJECTION_REASON_ENUM,
    SET_CARE_BOX_STATUS_ENUM,
    SET_CAREDEGREE_ENUM,
    SET_CAREDEGREEAPPLICATION_ENUM,
    SET_CAREOTHERREQUIREMENTS_ENUM,
    SET_CITIES_ENUM,
    SET_CONTRACTRATETYPE_ENUM,
    SET_CONTRACTSTATUS_ENUM,
    SET_DISEASES_ENUM,
    SET_DS_TEMPLATES_ENUM,
    SET_HEALTH_INCURANCE_ENUM,
    SET_HEALTHSTATE_ENUM,
    SET_INVOICEAPPROVALSTATUS_ENUM,
    SET_INVOICECORRECTION_ENUM,
    SET_INVOICEPAYMENT_ENUM,
    SET_LEAD_CAREBOX_STATUS_ENUM,
    SET_LEADSOURCE_ENUM,
    SET_LEADSTATUS_ENUM,
    SET_LIVEALONE_ENUM,
    SET_MARKETING_ENUM,
    SET_MULTIPLIERS_CATEGORIES_ENUM,
    SET_PROMEDICA_PRODUCTS_PRICES_ENUM,
    SET_RATE_DATA_ENUM,
    SET_REJECT_REASON_ENUM,
    SET_SERVICE_TYPE_DE_ENUM,
    SET_SITTERS_AVAILABILITY_ENUM,
    SET_SMOKINGATTITUDE_ENUM,
    SET_TASKPRIORITY_ENUM,
    SET_TRIP_TRACKING_STATUS_STATUS_ENUM,
    SET_GROUP_CAUSE_FP_ENUM,
    PENDING_GROUP_CAUSE_FP_ENUM,
    REMOVE_GROUP_CAUSE_FP_ENUM
} from "../actions/action-types";
import axiosAuth from '../../_services/config/axios-auth';
import {CareOtherRequirementsEnums} from '../../_constance/enums/careOtherRequirements';

import Fee from "../../_components/form-bb-sections/SectionOfferSummary/classes/Fee";

import {getLanguagePacketDetails} from "../../_constance/enums/summaryOfferLanguagePackets";
import {getAdditionalOptionDetails} from "../../_constance/enums/summaryOfferAdditionalOptions";

export const loadBasicEnumsToRedux = (lang) => {
    return dispatch => Promise.all([
        dispatch(loadingBasicEnums()),
        dispatch(getDiseasesData(lang)),
        dispatch(getCareDegreeApplicationData()),
        dispatch(getInvoicePaymentData(lang)),
        dispatch(getCareOtherRequirementsData(lang)),
        dispatch(getContractRateTypeData(lang)),
        dispatch(getHealthStateData(lang)),
        dispatch(getTaskPriorityData(lang)),
        dispatch(getLeadStatusData(lang)),
        dispatch(getInvoiceApprovalStatusData(lang)),
        dispatch(getContractStatusData(lang)),
        dispatch(getLiveAloneData(lang)),
        dispatch(getLeadSourceData(lang)),
        dispatch(getActivityTypeData(lang)),
        dispatch(getInvoiceCorrectionData(lang)),
        dispatch(getCareDegreeData()),
        dispatch(getSmokingAttitudeData(lang)),
        dispatch(getCallDirectionData(lang)),
        dispatch(getRejectReasonData(lang)),
        dispatch(getServiceTypeDeData()),
        dispatch(getMarketingData()),
        dispatch(getCitiesData()),
        dispatch(healthIncuranceSugestion()),
        dispatch(getMultipliersCategoriesData()),
        dispatch(getSittersAvailabilityData()),
        dispatch(getPromedicaProductsPricesData()),
        dispatch(getDSTemplatesData()),
        dispatch(getRateData()),
        dispatch(getCareBoxStatusData(lang)),
        dispatch(getCareBoxDeliveryToAddressData(lang)),
        dispatch(getCareBoxIngredientsStatusData(lang)),
        dispatch(getLeadCareBoxStatusData(lang)),
        dispatch(getCareBoxRejectionReasonData(lang)),
        dispatch(getTripTrackingStatus(lang)),
        dispatch(getGroupCauseFpData(lang))
    ]);
    // .then(()=>{
    //   dispatch(loadingBasicEnums())
    //   dispatch(finishBasicDataSave())
    // });
}

export const finishBasicDataSave = () => {
    return {
        type: FINISH_BASIC_DATA_SAVE
    };
};

export const loadingBasicEnums = () => {
    return {
        type: LOADING_ENUMS
    };
};

export const removeBasicDataSave = () => {
    return {
        type: REMOVE_ALL_BASIC_DATA_SAVE
    };
};


export const getDiseasesData = (lang) => {
    return dispatch => {
        dispatch(pendingDiseasesData());
        axiosAuth.get("lists/diseases/" + lang)
            .then(res => {
                dispatch(setDiseasesData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeDiseasesData());
            });
    }
}
export const setDiseasesData = (baseData) => {
    return {
        type: SET_DISEASES_ENUM,
        data: baseData
    };
};
export const pendingDiseasesData = () => {
    return {
        type: PENDING_DISEASES_ENUM
    };
};
export const removeDiseasesData = () => {
    return {
        type: REMOVE_DISEASES_ENUM
    };
};


export const getCareDegreeApplicationData = () => {
    return dispatch => {
        dispatch(pendingCareDegreeApplicationData());
        axiosAuth.get("lists/pflegegradApplication")
            .then(res => {
                dispatch(setCareDegreeApplicationData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeCareDegreeApplicationData());
            });
    }
}
export const setCareDegreeApplicationData = (baseData) => {
    return {
        type: SET_CAREDEGREEAPPLICATION_ENUM,
        data: baseData
    };
};
export const pendingCareDegreeApplicationData = () => {
    return {
        type: PENDING_CAREDEGREEAPPLICATION_ENUM
    };
};
export const removeCareDegreeApplicationData = () => {
    return {
        type: REMOVE_CAREDEGREEAPPLICATION_ENUM
    };
};


export const getInvoicePaymentData = (lang) => {
    return dispatch => {
        dispatch(pendingInvoicePaymentData());
        axiosAuth.get("lists/invoicePaymentStatus/" + lang)
            .then(res => {
                dispatch(setInvoicePaymentData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeInvoicePaymentData());
            });
    }
}
export const setInvoicePaymentData = (baseData) => {
    return {
        type: SET_INVOICEPAYMENT_ENUM,
        data: baseData
    };
};
export const pendingInvoicePaymentData = () => {
    return {
        type: PENDING_INVOICEPAYMENT_ENUM
    };
};
export const removeInvoicePaymentData = () => {
    return {
        type: REMOVE_INVOICEPAYMENT_ENUM
    };
};


export const getCareOtherRequirementsData = (lang) => {
    return dispatch => {
        if (lang === 'DE') {
            dispatch(setCareOtherRequirementsData(CareOtherRequirementsEnums.DE));
        } else {
            dispatch(setCareOtherRequirementsData(CareOtherRequirementsEnums.EN));
        }
        /*dispatch(pendingCareOtherRequirementsData());
        axiosAuth.get("lists/carerOtherRequirements/"+lang)
          .then(res => {dispatch(setCareOtherRequirementsData(res.data));})
          .catch((error) => {console.log(error);dispatch(removeCareOtherRequirementsData());});*/
    }
}
export const setCareOtherRequirementsData = (baseData) => {
    return {
        type: SET_CAREOTHERREQUIREMENTS_ENUM,
        data: baseData
    };
};
export const pendingCareOtherRequirementsData = () => {
    return {
        type: PENDING_CAREOTHERREQUIREMENTS_ENUM
    };
};
export const removeCareOtherRequirementsData = () => {
    return {
        type: REMOVE_CAREOTHERREQUIREMENTS_ENUM
    };
};


export const getContractRateTypeData = (lang) => {
    return dispatch => {
        dispatch(pendingContractRateTypeData());
        axiosAuth.get("lists/contractRateType/" + lang)
            .then(res => {
                dispatch(setContractRateTypeData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeContractRateTypeData());
            });
    }
}
export const setContractRateTypeData = (baseData) => {
    return {
        type: SET_CONTRACTRATETYPE_ENUM,
        data: baseData
    };
};
export const pendingContractRateTypeData = () => {
    return {
        type: PENDING_CONTRACTRATETYPE_ENUM
    };
};
export const removeContractRateTypeData = () => {
    return {
        type: REMOVE_CONTRACTRATETYPE_ENUM
    };
};


export const getHealthStateData = (lang) => {
    return dispatch => {
        dispatch(pendingHealthStateData());
        axiosAuth.get("lists/healthState/" + lang)
            .then(res => {
                dispatch(setHealthStateData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeHealthStateData());
            });
    }
}
export const setHealthStateData = (baseData) => {
    return {
        type: SET_HEALTHSTATE_ENUM,
        data: baseData
    };
};
export const pendingHealthStateData = () => {
    return {
        type: PENDING_HEALTHSTATE_ENUM
    };
};
export const removeHealthStateData = () => {
    return {
        type: REMOVE_HEALTHSTATE_ENUM
    };
};


export const getTaskPriorityData = (lang) => {
    return dispatch => {
        dispatch(pendingTaskPriorityData());
        axiosAuth.get("lists/taskPriority/" + lang)
            .then(res => {
                dispatch(setTaskPriorityData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeTaskPriorityData());
            });
    }
}
export const setTaskPriorityData = (baseData) => {
    return {
        type: SET_TASKPRIORITY_ENUM,
        data: baseData
    };
};
export const pendingTaskPriorityData = () => {
    return {
        type: PENDING_TASKPRIORITY_ENUM
    };
};
export const removeTaskPriorityData = () => {
    return {
        type: REMOVE_TASKPRIORITY_ENUM
    };
};


export const getLeadStatusData = (lang) => {
    return dispatch => {
        dispatch(pendingLeadStatusData());
        axiosAuth.get("lists/leadStatus/" + lang)
            .then(res => {
                dispatch(setLeadStatusData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeLeadStatusData());
            });
    }
}
export const setLeadStatusData = (baseData) => {
    return {
        type: SET_LEADSTATUS_ENUM,
        data: baseData
    };
};
export const pendingLeadStatusData = () => {
    return {
        type: PENDING_LEADSTATUS_ENUM
    };
};
export const removeLeadStatusData = () => {
    return {
        type: REMOVE_LEADSTATUS_ENUM
    };
};
export const getLeadCareBoxStatusData = (lang) => {
    return dispatch => {
        dispatch(pendingLeadCareBoxStatusData());
        _getPickList('contact', 'is_lead_status_care_box', lang,
            (list) => {
                dispatch(setLeadCareBoxStatusData(list));
            },
            () => {
                dispatch(removeLeadCareBoxStatusData());
            });
    }
}
export const setLeadCareBoxStatusData = (baseData) => {
    return {
        type: SET_LEAD_CAREBOX_STATUS_ENUM,
        data: baseData
    };
};
export const pendingLeadCareBoxStatusData = () => {
    return {
        type: PENDING_LEAD_CAREBOX_STATUS_ENUM
    };
};
export const removeLeadCareBoxStatusData = () => {
    return {
        type: REMOVE_LEAD_CAREBOX_STATUS_ENUM
    };
};

export const getInvoiceApprovalStatusData = (lang) => {
    return dispatch => {
        dispatch(pendingInvoiceApprovalStatusData());
        axiosAuth.get("lists/invoiceApprovalStatus/" + lang)
            .then(res => {
                dispatch(setInvoiceApprovalStatusData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeInvoiceApprovalStatusData());
            });
    }
}
export const setInvoiceApprovalStatusData = (baseData) => {
    return {
        type: SET_INVOICEAPPROVALSTATUS_ENUM,
        data: baseData
    };
};
export const pendingInvoiceApprovalStatusData = () => {
    return {
        type: PENDING_INVOICEAPPROVALSTATUS_ENUM
    };
};
export const removeInvoiceApprovalStatusData = () => {
    return {
        type: REMOVE_INVOICEAPPROVALSTATUS_ENUM
    };
};


export const getContractStatusData = (lang) => {
    return dispatch => {
        dispatch(pendingContractStatusData());
        axiosAuth.get("lists/contractStatus/" + lang)
            .then(res => {
                dispatch(setContractStatusData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeContractStatusData());
            });
    }
}
export const setContractStatusData = (baseData) => {
    return {
        type: SET_CONTRACTSTATUS_ENUM,
        data: baseData
    };
};
export const pendingContractStatusData = () => {
    return {
        type: PENDING_CONTRACTSTATUS_ENUM
    };
};
export const removeContractStatusData = () => {
    return {
        type: REMOVE_CONTRACTSTATUS_ENUM
    };
};


export const getLiveAloneData = (lang) => {
    return dispatch => {
        dispatch(pendingLiveAloneData());
        axiosAuth.get("lists/liveAlone/" + lang)
            .then(res => {
                dispatch(setLiveAloneData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeLiveAloneData());
            });
    }
}
export const setLiveAloneData = (baseData) => {
    return {
        type: SET_LIVEALONE_ENUM,
        data: baseData
    };
};
export const pendingLiveAloneData = () => {
    return {
        type: PENDING_LIVEALONE_ENUM
    };
};
export const removeLiveAloneData = () => {
    return {
        type: REMOVE_LIVEALONE_ENUM
    };
};


export const getLeadSourceData = (lang) => {
    return dispatch => {
        dispatch(pendingLeadSourceData());
        axiosAuth.get("lists/leadSource/" + lang)
            .then(res => {
                dispatch(setLeadSourceData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeLeadSourceData());
            });
    }
}
export const setLeadSourceData = (baseData) => {
    return {
        type: SET_LEADSOURCE_ENUM,
        data: baseData
    };
};
export const pendingLeadSourceData = () => {
    return {
        type: PENDING_LEADSOURCE_ENUM
    };
};
export const removeLeadSourceData = () => {
    return {
        type: REMOVE_LEADSOURCE_ENUM
    };
};


export const getActivityTypeData = (lang) => {
    return dispatch => {
        dispatch(pendingActivityTypeData());
        axiosAuth.get("lists/activityType/" + lang)
            .then(res => {
                dispatch(setActivityTypeData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeActivityTypeData());
            });
    }
}
export const setActivityTypeData = (baseData) => {
    return {
        type: SET_ACTIVITYTYPE_ENUM,
        data: baseData
    };
};
export const pendingActivityTypeData = () => {
    return {
        type: PENDING_ACTIVITYTYPE_ENUM
    };
};
export const removeActivityTypeData = () => {
    return {
        type: REMOVE_ACTIVITYTYPE_ENUM
    };
};


export const getInvoiceCorrectionData = (lang) => {
    return dispatch => {
        dispatch(pendingInvoiceCorrectionData());
        axiosAuth.get("lists/powodkorekty/" + lang)
            .then(res => {
                dispatch(setInvoiceCorrectionData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeInvoiceCorrectionData());
            });
    }
}
export const setInvoiceCorrectionData = (baseData) => {
    return {
        type: SET_INVOICECORRECTION_ENUM,
        data: baseData
    };
};
export const pendingInvoiceCorrectionData = () => {
    return {
        type: PENDING_INVOICECORRECTION_ENUM
    };
};
export const removeInvoiceCorrectionData = () => {
    return {
        type: REMOVE_INVOICECORRECTION_ENUM
    };
};


export const getCareDegreeData = () => {
    return dispatch => {
        dispatch(pendingCareDegreeData());
        axiosAuth.get("lists/pflegegrad")
            .then(res => {
                dispatch(setCareDegreeData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeCareDegreeData());
            });
    }
}
export const setCareDegreeData = (baseData) => {
    return {
        type: SET_CAREDEGREE_ENUM,
        data: baseData
    };
};
export const pendingCareDegreeData = () => {
    return {
        type: PENDING_CAREDEGREE_ENUM
    };
};
export const removeCareDegreeData = () => {
    return {
        type: REMOVE_CAREDEGREE_ENUM
    };
};


export const getSmokingAttitudeData = (lang) => {
    return dispatch => {
        dispatch(pendingSmokingAttitudeData());
        axiosAuth.get("lists/smokingAttitude/" + lang)
            .then(res => {
                dispatch(setSmokingAttitudeData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeSmokingAttitudeData());
            });
    }
}
export const setSmokingAttitudeData = (baseData) => {
    return {
        type: SET_SMOKINGATTITUDE_ENUM,
        data: baseData
    };
};
export const pendingSmokingAttitudeData = () => {
    return {
        type: PENDING_SMOKINGATTITUDE_ENUM
    };
};
export const removeSmokingAttitudeData = () => {
    return {
        type: REMOVE_SMOKINGATTITUDE_ENUM
    };
};


export const getCallDirectionData = (lang) => {
    return dispatch => {
        dispatch(pendingCallDirectionData());
        axiosAuth.get("lists/callDirection/" + lang)
            .then(res => {
                dispatch(setCallDirectionData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeCallDirectionData());
            });
    }
}
export const setCallDirectionData = (baseData) => {
    return {
        type: SET_CALLDIRECTION_ENUM,
        data: baseData
    };
};
export const pendingCallDirectionData = () => {
    return {
        type: PENDING_CALLDIRECTION_ENUM
    };
};
export const removeCallDirectionData = () => {
    return {
        type: REMOVE_CALLDIRECTION_ENUM
    };
};

export const getCitiesData = () => {
    return dispatch => {
        dispatch(pendingCitiesData());
        axiosAuth.get("lists/cities/0")
            .then(res => {
                dispatch(setCitiesData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeCitiesData());
            });
    }
}
export const setCitiesData = (baseData) => {
    return {
        type: SET_CITIES_ENUM,
        data: baseData
    };
};
export const pendingCitiesData = () => {
    return {
        type: PENDING_CITIES_ENUM
    };
};
export const removeCitiesData = () => {
    return {
        type: REMOVE_CITIES_ENUM
    };
};

export const healthIncuranceSugestion = () => {
    return dispatch => {
        dispatch(pendingHealthIncuranceData());
        axiosAuth.get("lists/institution/Krankenkasse/0")
            .then(res => {
                dispatch(setHealthIncuranceData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeHealthIncuranceData());
            });
    }
}

export const setHealthIncuranceData = (baseData) => {
    return {
        type: SET_HEALTH_INCURANCE_ENUM,
        data: baseData
    };
};
export const pendingHealthIncuranceData = () => {
    return {
        type: PENDING_HEALTH_INCURANCE_ENUM
    };
};
export const removeHealthIncuranceData = () => {
    return {
        type: REMOVE_HEALTH_INCURANCE_ENUM
    };
};


export const getMultipliersCategoriesData = () => {
    return dispatch => {
        dispatch(pendingMultipliersCategoriesData());
        axiosAuth.get("lists/multiplierCategories/0")
            .then(res => {
                dispatch(setMultipliersCategoriesData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeMultipliersCategoriesData());
            });
    }
}
export const setMultipliersCategoriesData = (baseData) => {
    return {
        type: SET_MULTIPLIERS_CATEGORIES_ENUM,
        data: baseData
    };
};
export const pendingMultipliersCategoriesData = () => {
    return {
        type: PENDING_MULTIPLIERS_CATEGORIES_ENUM
    };
};
export const removeMultipliersCategoriesData = () => {
    return {
        type: REMOVE_MULTIPLIERS_CATEGORIES_ENUM
    };
};


export const getMarketingData = () => {
    return dispatch => {
        dispatch(pendingMarketingData());
        axiosAuth.get("lists/marketings/0")
            .then(res => {
                dispatch(setMarketingData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeMarketingData());
            });
    }
}
export const setMarketingData = (baseData) => {
    return {
        type: SET_MARKETING_ENUM,
        data: baseData
    };
};
export const pendingMarketingData = () => {
    return {
        type: PENDING_MARKETING_ENUM
    };
};
export const removeMarketingData = () => {
    return {
        type: REMOVE_MARKETING_ENUM
    };
};


export const getRejectReasonData = (lang) => {
    return dispatch => {
        dispatch(pendingRejectReasonData());
        axiosAuth.get(`lists/rejectionReasons/${lang}`)
            .then(res => {
                dispatch(setRejectReasonData(res.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeRejectReasonData());
            });
    }
}
export const setRejectReasonData = (baseData) => {
    return {
        type: SET_REJECT_REASON_ENUM,
        data: baseData
    };
};
export const pendingRejectReasonData = () => {
    return {
        type: PENDING_REJECT_REASON_ENUM
    };
};
export const removeRejectReasonData = () => {
    return {
        type: REMOVE_REJECT_REASON_ENUM
    };
};

export const getServiceTypeDeData = () => {
    return dispatch => {
        dispatch(pendingServiceTypeDeData());
        const data = {
            "EntityName": "contact",
            "AttributeName": "is_service_type_de"
        };
        axiosAuth.post(`dynamic/picklist`, data)
            .then(json => {
                return json.data
            })
            .then(res => {
                const data = res.Translations.filter(it => {
                    return it.LangCode === 1045
                })[0].Values;
                const list = Object.keys(data).map(item => ({
                    Value: item,
                    Label: data[item],
                    Hidden: false
                }));
                dispatch(setServiceTypeDeData(list));
            })
            .catch((error) => {
                console.log(error);
                dispatch(removeServiceTypeDeData());
            });
    }
}
export const setServiceTypeDeData = (baseData) => {
    return {
        type: SET_SERVICE_TYPE_DE_ENUM,
        data: baseData
    };
};
export const pendingServiceTypeDeData = () => {
    return {
        type: PENDING_SERVICE_TYPE_DE_ENUM
    };
};
export const removeServiceTypeDeData = () => {
    return {
        type: REMOVE_SERVICE_TYPE_DE_ENUM
    };
};

export const getCareBoxStatusData = (lang) => {
    return dispatch => {
        dispatch(pendingCareBoxStatusData());
        _getPickList('is_care_box_app', 'is_status', lang,
            (list) => {
                dispatch(setCareBoxStatusData(list));
            },
            () => {
                dispatch(removeCareBoxStatusData());
            });
    }
}

export const setCareBoxStatusData = (baseData) => {
    return {
        type: SET_CARE_BOX_STATUS_ENUM,
        data: baseData
    };
};
export const pendingCareBoxStatusData = () => {
    return {
        type: PENDING_CARE_BOX_STATUS_ENUM
    };
};
export const removeCareBoxStatusData = () => {
    return {
        type: REMOVE_CARE_BOX_STATUS_ENUM
    };
};

export const getCareBoxDeliveryToAddressData = (lang) => {
    return dispatch => {
        dispatch(pendingCareBoxDeliveryToAddressData());
        _getPickList('is_care_box_app', 'is_delivery_to_address', lang,
            (list) => {
                dispatch(setCareBoxDeliveryToAddressData(list));
            },
            () => {
                dispatch(removeCareBoxDeliveryToAddressData());
            });
    }
}

export const setCareBoxDeliveryToAddressData = (baseData) => {
    return {
        type: SET_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM,
        data: baseData
    };
};
export const pendingCareBoxDeliveryToAddressData = () => {
    return {
        type: PENDING_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM
    };
};
export const removeCareBoxDeliveryToAddressData = () => {
    return {
        type: REMOVE_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM
    };
};

export const getCareBoxIngredientsStatusData = (lang) => {
    return dispatch => {
        dispatch(pendingCareBoxIngredientsStatusData());
        _getPickList('is_care_box_ingridients', 'is_status', lang,
            (list) => {
                dispatch(setCareBoxIngredientsStatusData(list));
            },
            () => {
                dispatch(removeCareBoxIngredientsStatusData());
            });
    }
}

export const setCareBoxIngredientsStatusData = (baseData) => {
    return {
        type: SET_CARE_BOX_INGREDIENTS_STATUS_ENUM,
        data: baseData
    };
};
export const pendingCareBoxIngredientsStatusData = () => {
    return {
        type: PENDING_CARE_BOX_INGREDIENTS_STATUS_ENUM
    };
};
export const removeCareBoxIngredientsStatusData = () => {
    return {
        type: REMOVE_CARE_BOX_INGREDIENTS_STATUS_ENUM
    };
};

export const getSittersAvailabilityData = () => {
    return dispatch => {
        dispatch(pendingSittersAvailabilityData());
        axiosAuth.get(`/lists/dictionary/OPAvailibility`).then(res => {
            const {Positions} = res.data;
            dispatch(
                setSittersAvailabilityData(Positions)
            )
        }).catch((error) => {
            console.log(error);
            dispatch(removeSittersAvailabilityData());
        });
    };
};
export const setSittersAvailabilityData = (baseData) => {
    return {
        type: SET_SITTERS_AVAILABILITY_ENUM,
        data: baseData
    }
};
export const pendingSittersAvailabilityData = () => {
    return {
        type: PENDING_SITTERS_AVAILABILITY_ENUM
    };
};
export const removeSittersAvailabilityData = () => {
    return {
        type: REMOVE_SITTERS_AVAILABILITY_ENUM
    };
};


export const getDSTemplatesData = () => {
    return dispatch => {
        dispatch(pendingDSTemplatesData());
        axiosAuth.get(`/lists/dictionary/DSTemplates`).then(res => {
            const {Positions} = res.data;
            dispatch(
                setDSTemplatesData(Positions)
            )
        }).catch((error) => {
            console.log(error);
            dispatch(removeDSTemplatesData());
        });
    };
};
export const setDSTemplatesData = (baseData) => {
    return {
        type: SET_DS_TEMPLATES_ENUM,
        data: baseData
    }
};
export const pendingDSTemplatesData = () => {
    return {
        type: PENDING_DS_TEMPLATES_ENUM
    };
};
export const removeDSTemplatesData = () => {
    return {
        type: REMOVE_DS_TEMPLATES_ENUM
    };
};


export const getPromedicaProductsPricesData = () => {
    return dispatch => {
        dispatch(pendingPromedicaProductsPricesData());
        axiosAuth.get(`/lists/dictionary/fees`).then(res => {
            const {Positions} = res.data;

            const mappedResponse = Positions.map(array => {
                return array.reduce((obj, item) => Object.assign(obj, {
                    [item.FieldName]: item.FieldValue
                }), {});
            });

            const fees = mappedResponse.map((item) => {
                const fee = new Fee({
                    id: item['ID'],
                    category: item['Kategoria'],
                    label: item['Label'],
                    label_crm: item['Label_CRM'],
                    price: item['Kwota'],
                })

                if (fee.category === "Produkt") {
                    Object.assign(fee, getLanguagePacketDetails(fee.id));
                } else if (fee.category === "Dodatek") {
                    Object.assign(fee, getAdditionalOptionDetails(fee.id));
                }

                return fee;
            })

            dispatch(
                setPromedicaProductsPricesData(fees)
            )
        }).catch((error) => {
            console.log(error);
            dispatch(removePromedicaProductsPricesData());
        });
    };
};
export const setPromedicaProductsPricesData = (baseData) => {
    return {
        type: SET_PROMEDICA_PRODUCTS_PRICES_ENUM,
        data: baseData
    }
};
export const pendingPromedicaProductsPricesData = () => {
    return {
        type: PENDING_PROMEDICA_PRODUCTS_PRICES_ENUM,
    };
};
export const removePromedicaProductsPricesData = () => {
    return {
        type: REMOVE_PROMEDICA_PRODUCTS_PRICES_ENUM,
    };
};

export const getRateData = () => {
    return dispatch => {
        dispatch(pendingRateData());
        axiosAuth.get(`/lists/dictionary/rate`).then(res => {
            const {Positions} = res.data;
            dispatch(
                setRateData(Positions[0][0]['FieldValue'])
            )
        }).catch((error) => {
            console.log(error);
            dispatch(removeRateData());
        });
    };
};
export const setRateData = (baseData) => {
    return {
        type: SET_RATE_DATA_ENUM,
        data: baseData
    }
};
export const pendingRateData = () => {
    return {
        type: PENDING_RATE_DATA_ENUM
    };
};
export const removeRateData = () => {
    return {
        type: REMOVE_RATE_DATA_ENUM
    };
};

export const getCareBoxRejectionReasonData = (lang) => {
    return dispatch => {
        dispatch(pendingCareBoxRejectionReasonData());
        _getPickList('contact', 'is_reason_for_rejection_care_box', lang,
            (list) => {
                const data = Object.keys(list).map(item => ({
                    Value: item,
                    Label: list[item],
                    Hidden: false
                }));
                dispatch(setCareBoxRejectionReasonData(data));
            },
            () => {
                dispatch(removeCareBoxRejectionReasonData());
            });
    }
}
export const setCareBoxRejectionReasonData = (baseData) => {
    return {
        type: SET_CARE_BOX_REJECTION_REASON_ENUM,
        data: baseData
    };
};
export const pendingCareBoxRejectionReasonData = () => {
    return {
        type: PENDING_CARE_BOX_REJECTION_REASON_ENUM
    };
};
export const removeCareBoxRejectionReasonData = () => {
    return {
        type: REMOVE_CARE_BOX_REJECTION_REASON_ENUM
    };
};


export const getGroupCauseFpData = (lang) => {
    return dispatch => {
        dispatch(pendingGroupCauseFpData());
        _getPickList('task', 'is_group_cause_fp', lang,
            (list) => {
                const data = Object.keys(list).map(item => ({
                    Value: item,
                    Label: list[item],
                    Hidden: false
                }));
                dispatch(setGroupCauseFpData(data));
            },
            () => {
                dispatch(removeGroupCauseFpData());
            });
    }
};
export const setGroupCauseFpData = (baseData) => {
    return {
        type: SET_GROUP_CAUSE_FP_ENUM,
        data: baseData
    };
};
export const pendingGroupCauseFpData = () => {
    return {
        type: PENDING_GROUP_CAUSE_FP_ENUM
    };
};
export const removeGroupCauseFpData  = () => {
    return {
        type: REMOVE_GROUP_CAUSE_FP_ENUM
    };
};

export const getTripTrackingStatus = (lang) => {
    return dispatch => {
        dispatch(pendingTripTrackingStatusData());
        _getPickList('is_travel', 'is_tracking_status', lang,
            (list) => {
                const data = Object.keys(list).map(item => ({
                    Value: item,
                    Label: list[item],
                    Hidden: false
                }));
                dispatch(setTripTrackingStatusData(data));
            },
            () => {
                dispatch(removeTripTrackingStatusData());
            });
    }
}

export const setTripTrackingStatusData = (baseData) => {
    return {
        type: SET_TRIP_TRACKING_STATUS_STATUS_ENUM,
        data: baseData
    };
};

export const pendingTripTrackingStatusData = () => {
    return {
        type: PENDING_TRIP_TRACKING_STATUS_ENUM
    };
};

export const removeTripTrackingStatusData = () => {
    return {
        type: REMOVE_TRIP_TRACKING_STATUS_ENUM
    };
};

export const _getPickList = (entity, attribute, lang, onSuccess, onFailed) => {
    const data = {
        "EntityName": entity,
        "AttributeName": attribute
    };
    axiosAuth.post(`dynamic/picklist`, data)
        .then(json => {
            return json.data
        })
        .then(res => {
            let langToSelect = 1031
            switch (lang) {
                case 'DE' :
                    langToSelect = 1031;
                    break;
                case 'EN' :
                    langToSelect = 1033;
                    break;
                case 'PL' :
                    langToSelect = 1045;
                    break;
                default :
                    langToSelect = 1031;
                    break;
            }
            const data = res.Translations.filter(it => {
                return it.LangCode === langToSelect
            })[0].Values;
            const list = Object.keys(data).map(item => {
                const i = [];
                i["Id"] = item;
                i["Value"] = data[item];
                return i;
            });
            const hashMap = list.reduce((result, item) => {
                return {...result, [item.Id]: item.Value};
            }, {});
            onSuccess(hashMap);
        })
        .catch((error) => {
            console.log(error);
            onFailed()
        });
}