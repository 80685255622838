export default class KoPilotenForm {
    categories = [];

    constructor({ categories = [] } = {}) {
        this.categories = categories;
    }

    gotAnyCategoriesWithMultiToShow() {
        const catsWithData = this.categories.find(categories => categories.MultiCanBeSelected.length>0);
        if(catsWithData){
            return true;
        }
        return false;
    }

    getCategoryByKey(CategoryId) {
        return this.categories.find(categories => categories.CategoryId === CategoryId);
    }

    getCategoryIdByName(Name) {
        let found = this.categories.find(categories => categories.Name === Name);
        if(found){
            return found.CategoryId;
        }
        return null
    }

    getCategoryByName(Name) {
        let found = this.categories.find(categories => categories.Name === Name);
        if(found){
            return found;
        }
        return null
    }

    getCategoryIndexByName(Name) {
        for (let index = 0; index < this.categories.length; index++) {
            if(this.categories[index].Name === Name){
                return index;
            }
        }
        return null
    }

    setMultipliersToCategory(data, key) {
        const field = this.getCategoryByKey(key);
        if(field){
            field.MultiplierIds = data;
        }
    }

    addOrRemoveMultipliersFromToCategory(multiId, key, action) {
        const field = this.getCategoryByKey(key);
        if(field){
            if(action==="ADD"){
                const exists = field.MultiplierIds.indexOf(multiId);
                if(exists===-1){
                    field.MultiplierIds = [...field.MultiplierIds, multiId];
                }
            }else{
                const exists = field.MultiplierIds.indexOf(multiId);
                if(exists>-1){
                    let newCatList = [...field.MultiplierIds];
                    newCatList.splice(exists, 1);
                    field.MultiplierIds = newCatList
                }
            }
        }
    }

    moveCategory(toIndex, category) {
        if(category){
            if(category.Name){
                let fromIndex = this.getCategoryIndexByName(category.Name);
                if(fromIndex){
                    this.categories.splice(fromIndex, 1);
                    this.categories.splice(toIndex, 0, category);
                }
            }
        }
    }

    assingSubCategorie(mainKey, subKey){
        const field = this.getCategoryByKey(mainKey);
        if(field){
            const subField = this.getCategoryByKey(subKey);
            if(subField){
                field.SubCategoriesList.push(subField);
            }
        }
    }

    assingSubCategories(mainKey, data){
        const field = this.getCategoryByKey(mainKey);
        if(field){
            field.SubCategoriesList = data;
        }
    }

    setDataToCategory(data, key) {
        const field = this.getCategoryByKey(key);
        if(field){
            if(data.MultiplierIds){
                field.MultiplierIds=data.MultiplierIds;
            }
            if(data.MultiplierRangeId){
                field.MultiplierRangeId=data.MultiplierRangeId;
            }
            if(data.Note){
                field.Note=data.Note;
            }
            if(data.MultiCanBeSelected){
                field.MultiCanBeSelected=data.MultiCanBeSelected;
            }
            if(data.SubCategoriesKeyList){
                field.SubCategoriesKeyList=data.SubCategoriesKeyList;
            }
        }
    }
}
