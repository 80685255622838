import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../_redux/actions/index";
import { LoaderTable } from "../index";
import MultiplicatorSidebar from "../mutliplicatorSidebar/multiplicatorSidebar";
import MainPanelMultiplicators from "../mutliplicatorSidebar/MainPanelMultiplicators";
import { LeadCancelSaveComponent, AlertModalInfo, EmptyTableIcon} from "../../_components/index";

class KoPilotenCategoryComponent extends React.Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove("modal-open");
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { edited } = this.props;
    if (edited) {this.props.saveChange();}
  }

  changeCategoryActive = (id) => {
    this.props.changeCategoryActiveKoPiloten(id);
  }

  addMultiToCategory=(multiplicatorId, value)=>{
    if(!this.props.disabled){this.props.addMultiToCategory(multiplicatorId, value);}
  }

  changeNoteData=(key, val)=>{
    if(!this.props.disabled){this.props.changeNoteData(key, val);}
  }

  getMeContent = () => {
    const { translateKey, form, rerender, activeCategoryId, errorComponent, edited, saving, loadingBasics, saveChange, generatePdf, generatePdfFunction } = this.props;
    let content;
    if (loadingBasics) {
      content = (
        <div className="flexCenterItems emptyLoaderMin">
          <LoaderTable />
        </div>
      );
    } else {
      if(form.gotAnyCategoriesWithMultiToShow()){
        content = (
          <React.Fragment>
            <MultiplicatorSidebar rerender={rerender} form={form} activeCategory={activeCategoryId} changeCategoryActive={(id)=>this.changeCategoryActive(id)}/>
            <MainPanelMultiplicators rerender={rerender} form={form} activeCategoryId={activeCategoryId} addMultiToCategory={this.addMultiToCategory} changeNote={this.changeNoteData}/>
            {(edited || generatePdf) &&
              <LeadCancelSaveComponent generatePdf={generatePdf} generatePdfFunction={generatePdfFunction} saveChange={()=> saveChange()} key="saveMultiMultiplier01" edited={edited} savingData={saving} onlySave={true}/>
            }
            <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={translateKey(errorComponent.message)}/>       
          </React.Fragment>
        );
      }else{
        content = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => {}} text={translateKey("no_data_to_show")} /></div>;
      }
    }
    return content;
  };

  render() {
    return (
     <div className="ko-piloten-main-wrapper">
       {this.getMeContent()}
    </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(null, mapDispatchToProps)(KoPilotenCategoryComponent));
