export const TableEnums = {
    FILTER: "FILTER",
    ADD: "ADD",
    EDIT: "EDIT",
    SAVE: "SAVE",
    SEARCH: "SEARCH",
    REMOVE: "REMOVE",
    CREATECONTRACT: "CREATECONTRACT",
    CREATEQUATATION: "CREATEQUATATION",
    ACTIVECOLUMNS: "ACTIVECOLUMNS",
    ADDMEETING: "ADDMEETING",
    SENDKOPILOTEN: "SENDKOPILOTEN",
    MILTICATEGORIES: "MILTICATEGORIES",
};