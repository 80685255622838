export const KOPILOTENSUBTOCATCONNECTION = {
  SRODKIPOMOCNICZE : [
      "ko-piloten-category-hausnotrufsystem",
      "ko-piloten-category-kostenlose",
      "ko-piloten-category-sanitatshaus",
      "ko-piloten-category-rollstuhl",
      "ko-piloten-category-toilettensitzerhohung",
      "ko-piloten-category-pflegebett",
      "ko-piloten-category-patientenlifter",
      "ko-piloten-category-rollator",
      "ko-piloten-category-badewannenlifter",
      "ko-piloten-category-badewannen",
      "ko-piloten-category-treppenlift"
  ],
  INNE : [
      "ko-piloten-category-arzneimittel",
      "ko-piloten-category-hausarzt",
      "ko-piloten-category-nachbarschaftshilfe",
      "ko-piloten-category-einkauf",
      "ko-piloten-category-essen",
      "ko-piloten-category-internet",
      "ko-piloten-category-mmbaumabnahmen",
      "ko-piloten-category-fahrservice",
      "ko-piloten-category-podologen",
      "ko-piloten-category-allgemeine"
  ],
  TERAPEUCI : [
      "ko-piloten-category-logopadie",
      "ko-piloten-category-ergotherapia",
      "ko-piloten-category-physiotherapia"
  ]
}