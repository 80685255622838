import * as React from 'react';
import MaskedTextInput from "react-text-mask";

export default class CalendarCustomMaskInput extends React.Component {
    render() {
        const {value, onChange, onClick} = this.props;
        return (
            <div className="form-group no_margin">
                <MaskedTextInput 
                    type="text"
                    className="form-control newUI"
                    value={ value !== null || value !== '' ? value : ' ' }
                    mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
                    placeholder="__.__.____"
                    onChange={onChange}
                    onClick={onClick}
                />
                <i onClick={onClick} className="far fa-2x fa-calendar-alt cursor-pointer calendar-input-icon-right"></i>
            </div>
        )
    }
}