import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { addNoteData } from "../../../_redux/actions/auth";
import { TableColumHead, TablePagination, LoaderTable, EmptyTableIcon, TableDateFormat, ContractStatus, AlertModalInfo, ConfirmModal} from "../../index";
import { sortingContracts } from "../../../_constance/classes/sortingClasses/index";
import { Paging, Alert } from "../../../_constance/classes/index";
import { ReqHelper } from "../../../_helpers/index";
//import * as FormTypeToShow from '../../../_forms/BBForm/values/formTypeToShow';
import * as LanguageSelect from "../../../_constance/values/languageSelect";
import { baseData } from '../../../_constance/base_data';
import { saveDynamicFields } from '../../../_services/user_service';

class ClientContractData extends React.Component {
    alertD = Alert;
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id: props.id,
            loading: true,
            sorting: sortingContracts,
            pagingItem: Paging,
            retries: 0,
            selectedKey: 0,
            clientId: this.props.clientId,
            errorComponent: Alert,
            confirmDeleteContract: false,
            contractToRemove: {},
        };
    }

    componentDidMount() {
        document.body.classList.remove('modal-open');
        this.getInitialDataTime();
    }

    componentWillUnmount() {clearTimeout(this.myTimeout);};

    getInitialDataTime(){
        if(this.props.contractsData.length>0){
          clearTimeout(this.myTimeout);
          this.filterOrDataChange();
        }else{
            if(this.state.retries<10){
                this.setState({retries: this.state.retries+1})
                this.myTimeout = setTimeout(() => {
                    this.getInitialDataTime()
                  }, 500);
            }else{
                this.setState({
                    retries: 0,
                    loading: false
                })
            }
        }
    }
    
    filterOrDataChange(){
        this.setState({loading: true });
        let finalDataToShow = this.props.contractsData;
        ReqHelper.sortMeData(finalDataToShow, this.state.sorting);
        this.propsPagingData(finalDataToShow);
        setTimeout(() => {
            let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
            this.setState({
                data: finalData,
                loading: false
            })
        }, 50);
    }
    
    propsSortType(type){
        let sortingObj = [];
        for(let i=0; i<this.state.sorting.length; i++){
            if(this.state.sorting[i].column===type){
            if(this.state.sorting[i].active===true){
                let item = this.state.sorting[i];
                item.type==='ASC'? item.type="DESC" : item.type='ASC';
                sortingObj.push(item);
            }else{
                let item = this.state.sorting[i];
                item.type='ASC';
                item.active = true;
                sortingObj.push(item);
            }
            }else{
            let item = this.state.sorting[i];
            item.type='ASC';
            item.active = false;
            sortingObj.push(item);
            }
        }
        this.setState({sorting: sortingObj});
        this.filterOrDataChange();
    }

    propsPagingData(data){
        if(data.length<=25){
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                totalItems: data.length,
                itemsPerPage: prevState.pagingItem.itemsPerPage,
                currentPage: 1,
                visiblePages: 5,
                pageFrom: 1,
                pageTo: 5,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }else{
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                ...prevState.pagingItem,
                totalItems: data.length,
                pageFrom: prevState.pagingItem.pageFrom,
                pageTo: prevState.pagingItem.pageTo,
                currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }
    }

    navigateToContractsDetails(element){
        const { history, appLang, id} = this.props;
        if(appLang === LanguageSelect.EN){
            history.push(baseData.subFolders+'contract/'+id+'/'+element.id);
        }else{
            history.push(baseData.subFolders+'contract/'+id+'/'+element.id);
            //this.props.history.push(baseData.subFolders+'/bb-form-client/'+this.props.id+'/'+element.id+'/'+FormTypeToShow.ENQUIRY);
        }
    }

    resetContract(element) {
        const data = [
            {
                "id": element.id,
                "entity":"is_contract",
                "fields":
                {
                    "is_contract_status":"717700004"
                }
            }
        ];

        //check if has other contracts
        const isOtherContractActive =  this.state.data.filter(el => el.id !== element.id).some(el => ( el.status === '717700000' || el.status === '717700001' || el.status === '717700002' || el.status === '717700006'));

        if (!isOtherContractActive) {
            data.push(
                {
                    "id": this.state.clientId,
                    "entity":"contact",
                    "fields":
                    {
                        "is_customer_status_de":"717700020"
                    }
                }
            );
        }
        this.setState({
            loading: true,
        });

        saveDynamicFields(data).then(response => {
            const { translateKey, history } = this.props;
            const message = isOtherContractActive ? translateKey("contract_canceled") : `${translateKey("contract_canceled")}\n${translateKey("contract_withdrawn")}`;  

            if(response === 801) {
                this.setState({
                    loading: false,
                    errorComponent: {
                        show: true,
                        type: "danger",
                        message: translateKey("cancelation_contract_error")
                    }
                  })    
                clearTimeout(this.myTimeout);
                this.myTimeout = setTimeout(() => {
                  this.setState({errorComponent: {show: false}})
                  document.body.classList.remove('modal-open');
                }, 3000);
            } else {
                const updatedData = this.state.data.map(el => { 
                    if(el.id === element.id) {
                        el.status = '717700004';
                    }
                    return el;
                });

                this.setState({
                    data: updatedData,
                    loading: false,
                    errorComponent: {
                    show: true,
                    type: "success",
                    message: message,
                    }
                })    
                clearTimeout(this.myTimeout);
                this.myTimeout = setTimeout(() => {
                    this.setState({errorComponent: {show: false}})
                    document.body.classList.remove('modal-open');
                    if (!isOtherContractActive) {
                        history.push(`${baseData.subFolders}leadDetails/${this.state.clientId}`);
                    }
                }, 3000);
            }
            const date = new Date();
            const note = `DLV am ${date.getDate() < 10 ? '0' : ''}${date.getDate()}.${date.getMonth()+1 < 10 ? '0' : ''}${date.getMonth()+1}.${date.getFullYear()} wieder storniert`;
            this.props.addNote({Note: note}, this.props.clientId, "contact")
            .catch((err)=>{
                console.log(err);
            });
        })
        .catch((err) => {
            this.setState({
                loading: false,
                errorComponent: {
                  show: true,
                  type: "danger",
                  message: err.message
                }
              })    
            clearTimeout(this.myTimeout);
            this.myTimeout = setTimeout(() => {
              this.setState({errorComponent: {show: false}})
              document.body.classList.remove('modal-open');
            }, 3000);
        });
    }

    removeItem(item){
        this.setState({
            confirmDeleteContract: true,
            contractToRemove: item,
        })
    }

    rejectMeNote() {
        this.setState({
            confirmDeleteContract: false
        })
    }

    acceptMe() {
        this.setState({
            confirmDeleteContract: false
        });

        this.resetContract(this.state.contractToRemove);
    }

    render() {
        const { errorComponent } = this.state;
        const { aditionalData, translateKey } = this.props;

        let emptyHandler;
        let contractsData = [];
        if (this.state.loading) {
            emptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
        }else{
            if (this.state.data.length === 0) {
                emptyHandler = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.RefreshMe()} text={translateKey("no_data_to_show")} /></div>
            }else{
                for(let j=0; j<this.state.data.length; j++){
                    contractsData.push(
                        <tr className={"table-row-wrapper cursor-pointer client-contract-status-"+this.state.data[j].status} key={this.state.data[j].id} >
                            <td className={"table-cell-wrapper " + (this.state.data[j].contract !== null ? "got-data" : 'no-data')} onClick={()=>{this.navigateToContractsDetails(this.state.data[j])}}>{this.state.data[j].contract}</td>
                            <td className={"table-cell-wrapper " + (this.state.data[j].status !== null ? "got-data" : 'no-data')} onClick={()=>{this.navigateToContractsDetails(this.state.data[j])}}><ContractStatus element={this.state.data[j].status}/></td>
                            <td className={"table-cell-wrapper " + (this.state.data[j].product !== null ? "got-data" : 'no-data')} onClick={()=>{this.navigateToContractsDetails(this.state.data[j])}}>{this.state.data[j].product}</td>
                            <td className={"table-cell-wrapper " + (this.state.data[j].rate !== null ? "got-data" : 'no-data')} onClick={()=>{this.navigateToContractsDetails(this.state.data[j])}}>{ReqHelper.numberWithSpaces(this.state.data[j].rate) + ' ' + this.state.data[j].currency}</td>
                            <td className={"table-cell-wrapper " + (this.state.data[j].agreementDate !== null ? "got-data" : 'no-data')} onClick={()=>{this.navigateToContractsDetails(this.state.data[j])}}><TableDateFormat timestamp={this.state.data[j].agreementDate}/></td>
                            {
                                this.state.data[j].status === '717700006' ? // if status is equal "zur Aktivierung"
                                <td className={"table-cell-wrapper got-data"}><button type="button" styles="z-index: 1000000" className="btn btn-danger cursor-pointer" onClick={() => this.removeItem(this.state.data[j])}>{ translateKey("reset_btn") }</button></td>
                                : <td></td>
                            }
                        </tr>
                     )
                }
            }
        }
        return (
            <>
                <div className="client-data-wrapper-tab">
                <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message}/>
                <ConfirmModal rejectMe={() => this.rejectMeNote()} acceptMe={() => this.acceptMe()} show={this.state.confirmDeleteContract} titleMessage="" mainMessage={translateKey("delete_contract")+"?"} messageYes={translateKey("yes")} messageNo={translateKey("no")}/>
                    <div className="listData">
                        <table className="table">
                            <thead>
                                <tr>
                                    <TableColumHead sorting={this.state.sorting} name={translateKey("contracts")} onSortType={(data) => this.propsSortType(data)} type="contract" />
                                    <TableColumHead sorting={this.state.sorting} name={translateKey("status")} onSortType={(data) => this.propsSortType(data)} type="status" />
                                    <TableColumHead sorting={this.state.sorting} name={translateKey("product")} onSortType={(data) => this.propsSortType(data)} type="product" />
                                    <TableColumHead sorting={this.state.sorting} name={translateKey("rate")} onSortType={(data) => this.propsSortType(data)} type="rate" />
                                    <TableColumHead sorting={this.state.sorting} name={translateKey("signed")} onSortType={(data) => this.propsSortType(data)} type="agreementDate" />
                                    <th scope="col">{ translateKey("reset_btn") }</th>
                                </tr>
                            </thead>
                            <tbody>{contractsData}</tbody>
                        </table>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">{ translateKey("original_product") }</th>
                                    <th scope="col">{ translateKey("fee_licence") }</th>
                                    <th scope="col">{ translateKey("additional_person") }</th>
                                    <th scope="col">{ translateKey("fee_2ndlang") }</th>
                                    <th scope="col">{ translateKey("fee_plus") }</th>
                                    <th scope="col">{ translateKey("fee_diploma") }</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={"table-row-wrapper"} >
                                    <td className={"table-cell-wrapper no-data"}>{aditionalData.isPrimaryProduct ? translateKey(`original_product_${aditionalData.isPrimaryProduct}`) : '----'}</td>
                                    <td className={"table-cell-wrapper no-data"}>{aditionalData.isDrivingLicence === '717700000' ? translateKey("yes") : translateKey("no")}</td>
                                    <td className={"table-cell-wrapper no-data"}>{aditionalData.isSecondOoo === '717700000' ? translateKey("yes") : translateKey("no")}</td>
                                    <td className={"table-cell-wrapper no-data"}>{aditionalData.isAdditionalForeignLanguage === '717700000' ? translateKey("yes") : translateKey("no")}</td>
                                    <td className={"table-cell-wrapper no-data"}>{aditionalData.isTransfer === '717700000' ? translateKey("yes") : translateKey("no")}</td>
                                    <td className={"table-cell-wrapper no-data"}>{aditionalData.isCarerWithDiploma === '717700000' ? translateKey("yes") : translateKey("no")}</td>
                                </tr>
                            </tbody>
                        </table>
                        {emptyHandler}
                        <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {return {
    userId: state.auth.userId,
    appLang: state.lang.appLanguage
}};

const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    addNote: (note, userid, type) => dispatch(addNoteData(note, userid, type)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientContractData));