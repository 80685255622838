import * as React from 'react';
class TileOffer extends React.Component {

    takeAction(){
        if(this.props.element.action===true){
            this.props.takeAction(this.props.element)
        };
    }

    render() {
        const {translateKey, element, showAll } = this.props;
        if(showAll){
            return (
                <div className={`offer-element-wrapper ${element.active ? 'active-element' : ''} ${element.action?'cursor-pointer':''}`} onClick={()=>this.takeAction()}>
                    <div className="from-init-offer-icon">
                        <img className="form-offer-tile-icon" src={element.active?element.iconActive:element.icon} alt="Icon"/>
                    </div>
                    <div className="from-init-offer-text">
                        {translateKey(element.name)}
                    </div>
                </div>
            );
        }else{
            if(element.show){
                return (
                    <div className={`offer-element-wrapper ${element.active ? 'active-element' : ''} ${element.action?'cursor-pointer':''}`} onClick={()=>this.takeAction()}>
                        <div className="from-init-offer-icon">
                            <img className="form-offer-tile-icon" src={element.active?element.iconActive:element.icon} alt="Icon"/>
                        </div>
                        <div className="from-init-offer-text">
                            {translateKey(element.name)}
                        </div>
                    </div>
                );
            }else{
                return null;
            }
        }
    }
}
export default TileOffer;