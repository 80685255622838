export const allowEmptyList = [
    'is_applied_pflegegrad',
    'is_pflegegrad',
    'is_degree_relationship_with_patient_carer',
    'is_zg_de_1',
    'is_zg_de_2',
    'is_zg_de_3',
    'is_zg_de_4',
    'is_zg_de_5',
    'is_zg_de_6',
    'is_zg_de_7',
    'is_zg_de_8',
    'is_home_doctor',
    'is_taking_care_of_animals_which_de',
    'is_moving_with_rollator_alone',
    'is_moving_with_rollator_in_flat_alone',
    'is_moving_rollator_outside_flat',
    'is_moving_on_wheelchair_alone',
    'is_moving_wheelchair_in_apartment_alone',
    'is_moving_wheelchair_outside_alone',
    'is_care_box_appid'
]