export const RELEASE_DATE = '29.01.2025, 21:33 1.0.51.000'
export const ENVIRONMENT = process.env.REACT_APP_PM_ENV

const Config = {
    TEST: {
        currencyID: '7F52070D-FE9A-E811-80CE-00155D13F317',
        multiToLeadSelection: "2669ACCE-A798-E911-80D1-00155D140163",
        enumClient: "formid=8e6c630a-0698-4eb8-a0bc-2a2d8117d98b",
        enumMultiplier: "formid=8e6c630a-0698-4eb8-a0bc-2a2d8117d98b",
        enumContract: "formid=0b87917a-9cc7-4022-bda7-5af1c95b283b",
        enumInvoice: "formid=c40cd045-c809-4f18-a6dc-bdc0f8ef3559",
        enumTaskTypeEvent: "formid=549a8aa3-c349-4ab9-8bac-4873fa9a7a5c",
        enumTaskTypeTask: "formid=16654191-19ad-48ca-9e46-c4b4fbdad76f",
        enumTaskTypeCall: "formid=a91390a5-99bb-4d10-9eee-3a5c87f841f1",
        enumPatient: "formid=c1784d20-94ca-4575-8694-557ab28855d7",
        enumCarer: "formid=ea978e6a-2681-4e0a-a45c-ee84ac6d768f",
        taskComplaintId: "8b7bc17b-7919-ed11-8102-00155d174726",
        taskTerminationId: "b940e781-7919-ed11-8102-00155d174726",
        docuSignTemplateIdCareBox: "eb34856f-882d-46f6-b5c6-80654e1e8988",
        individualCareBoxId: "73463c98-8353-ec11-80f7-00155d174726"
    },
    PROD: {
        currencyID: '7F52070D-FE9A-E811-80CE-00155D13F317',
        multiToLeadSelection: "2669ACCE-A798-E911-80D1-00155D140163",
        enumClient: "formid=8e6c630a-0698-4eb8-a0bc-2a2d8117d98b",
        enumMultiplier: "formid=8e6c630a-0698-4eb8-a0bc-2a2d8117d98b",
        enumContract: "formid=0b87917a-9cc7-4022-bda7-5af1c95b283b",
        enumInvoice: "formid=c40cd045-c809-4f18-a6dc-bdc0f8ef3559",
        enumTaskTypeEvent: "formid=549a8aa3-c349-4ab9-8bac-4873fa9a7a5c",
        enumTaskTypeTask: "formid=16654191-19ad-48ca-9e46-c4b4fbdad76f",
        enumTaskTypeCall: "formid=a91390a5-99bb-4d10-9eee-3a5c87f841f1",
        enumPatient: "formid=c1784d20-94ca-4575-8694-557ab28855d7",
        enumCarer: "formid=ea978e6a-2681-4e0a-a45c-ee84ac6d768f",
        docuSignTemplateIdCareBox: "82cd5782-d960-4485-ae15-3bdf6448383d",
        taskComplaintId: "28014579-1c1e-ed11-80ff-00155d0bac11",
        taskTerminationId: "fbeb3e83-1c1e-ed11-80ff-00155d0bac11",
        individualCareBoxId: "f8c9bfad-7153-ec11-80f3-00155d0ba118"
    },
    PREPROD: {
        currencyID: '7F52070D-FE9A-E811-80CE-00155D13F317',
        multiToLeadSelection: "2669ACCE-A798-E911-80D1-00155D140163",
        enumClient: "formid=8e6c630a-0698-4eb8-a0bc-2a2d8117d98b",
        enumMultiplier: "formid=8e6c630a-0698-4eb8-a0bc-2a2d8117d98b",
        enumContract: "formid=0b87917a-9cc7-4022-bda7-5af1c95b283b",
        enumInvoice: "formid=c40cd045-c809-4f18-a6dc-bdc0f8ef3559",
        enumTaskTypeEvent: "formid=549a8aa3-c349-4ab9-8bac-4873fa9a7a5c",
        enumTaskTypeTask: "formid=16654191-19ad-48ca-9e46-c4b4fbdad76f",
        enumTaskTypeCall: "formid=a91390a5-99bb-4d10-9eee-3a5c87f841f1",
        enumPatient: "formid=c1784d20-94ca-4575-8694-557ab28855d7",
        enumCarer: "formid=ea978e6a-2681-4e0a-a45c-ee84ac6d768f",
        taskComplaintId: "28014579-1c1e-ed11-80ff-00155d0bac11",
        taskTerminationId: "fbeb3e83-1c1e-ed11-80ff-00155d0bac11",
        docuSignTemplateIdCareBox: "82cd5782-d960-4485-ae15-3bdf6448383d",
        individualCareBoxId: "f8c9bfad-7153-ec11-80f3-00155d0ba118"
    },
};

const EnvConfig = {
    TEST: {
        base_url: 'http://promedica-betreuung.de',
        base_backend: 'http://promedica-betreuung.de/dev/backend/',
        base_kunde: 'http://promedica-betreuung.de/dev/',
        base_docuSign: 'https://app.docusign.com/documents/details/',
        subFolders: '/dev/app/',
        base_api: 'http://crm-vp1.promedica24.pl:2180/',
        public_key: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDV4NyfCnT4KLud4RySvdOJECNgF6NYgpe8qul7Q+F4RsTO4BR0Ghm2CKYKQV1Iqrb+4MBkoz9h2fw6Tty8bvOn8kW2LjBmjKdKt0dltsC1XMdlbrTVvSW+Kt88Fh8UGOoBgXyejxL0PF+az9pCW8PkkRirPQYdo/BHeEiJHQHX2QIDBLAB",
        releaseDate: RELEASE_DATE,
        bbFormVersion: "2020/01",
        hash: "5f039b4ef0058a1d652f13d612375a5b",
        //new version new hash
        deepLinkStart: "https://app365.pm24test.local/PM365/main.aspx",
        fireBaseURL: "https://us-central1-promedica24-96610.cloudfunctions.net/",
        fireBaseAuthToken: "QFjf9Sh5RcUDJXGufkIIn1C1MHH6eMqnqgdNDSlh",
        meetingM:'4f10424efb2e9cf56770d6069e6d531c',
        meetingS:'a83e31af9de5221c94cfd0f6281fdaab',
        structureLink: 'http://crm-vp1.promedica24.pl:2182/organization-tree',
    },
    PROD: {
        base_url: 'https://promedica-betreuung.de',
        base_backend: 'https://promedica-betreuung.de/backend/',
        base_kunde: 'https://promedica-betreuung.de/',
        base_docuSign: 'https://app.docusign.com/documents/details/',
        subFolders: '/app/',
        base_api: 'https://mobileapi.promedica24.pl/',
        public_key: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDV4NyfCnT4KLud4RySvdOJECNgF6NYgpe8qul7Q+F4RsTO4BR0Ghm2CKYKQV1Iqrb+4MBkoz9h2fw6Tty8bvOn8kW2LjBmjKdKt0dltsC1XMdlbrTVvSW+Kt88Fh8UGOoBgXyejxL0PF+az9pCW8PkkRirPQYdo/BHeEiJHQHX2QIDBLAB",
        releaseDate: RELEASE_DATE,
        bbFormVersion: "2020/01",
        hash: "5f039b4ef0058a1d652f13d612375a5b",
        //new version new hash
        deepLinkStart: "https://pmcrm.promedica24.pl/main.aspx",
        fireBaseURL: "https://us-central1-promedica24-96610.cloudfunctions.net/",
        fireBaseAuthToken: "QFjf9Sh5RcUDJXGufkIIn1C1MHH6eMqnqgdNDSlh",
        meetingM: '4f10424efb2e9cf56770d6069e6d531c',
        meetingS: 'a83e31af9de5221c94cfd0f6281fdaab',
        structureLink: 'https://structure.promedica24.pl/organization-tree',
    },
    PREPROD: {
        base_url: 'http://promedica-betreuung.de',
        base_backend: 'https://promedica-betreuung.de/backend/',
        base_kunde: 'https://promedica-betreuung.de/',
        base_docuSign: 'https://app.docusign.com/documents/details/',
        subFolders: '/pre-prod/app/',
        base_api: 'https://mobileapi.promedica24.pl/',
        public_key: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDV4NyfCnT4KLud4RySvdOJECNgF6NYgpe8qul7Q+F4RsTO4BR0Ghm2CKYKQV1Iqrb+4MBkoz9h2fw6Tty8bvOn8kW2LjBmjKdKt0dltsC1XMdlbrTVvSW+Kt88Fh8UGOoBgXyejxL0PF+az9pCW8PkkRirPQYdo/BHeEiJHQHX2QIDBLAB",
        releaseDate: "17.11.2021, 16:27 1.0.9.32",
        bbFormVersion: "2020/01",
        hash: "5f039b4ef0058a1d652f13d612375a5b",
        //new version new hash
        deepLinkStart: "https://app365.pm24test.local/PM365/main.aspx",
        fireBaseURL: "https://us-central1-promedica24-96610.cloudfunctions.net/",
        fireBaseAuthToken: "QFjf9Sh5RcUDJXGufkIIn1C1MHH6eMqnqgdNDSlh",
        meetingM:'4f10424efb2e9cf56770d6069e6d531c',
        meetingS:'a83e31af9de5221c94cfd0f6281fdaab'
    }
}

// Create config object, that includes env-specific options
export const baseData = Object.assign({}, EnvConfig[ENVIRONMENT], Config[ENVIRONMENT]);

export const refreshTokenTime = {
    expirationTime: 604800000,
    actionExpirationTime: 900000,
    refreshBasicTime: 300000,
};
