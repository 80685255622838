import Form from "../Form";
import FormSection from "../FormSection";
import FormField from "../FormField";
import * as TaskType from "../../_constance/values/taskType";
import * as AddressType from "../../_constance/values/addressType";

function timeRounded () {
  let myTime = new Date();
  let minutes = myTime.getMinutes()
  let intervals = Math.floor(minutes / 15);
  if(minutes % 15 !== 0)
    intervals++; 
    if(intervals === 4){
      intervals = 0
    }
    minutes = intervals * 15
    const objT = new Date(myTime.getFullYear(),myTime.getMonth() , myTime.getDate() , myTime.getHours(), minutes , 0 , 0)
    return objT;
}

export default class ArrangeMeetingForm extends Form {
  constructor() {
    super({
      sections: [
        new FormSection({
          key: "section-meeting",
          sectionName: "arrange-meeting-title",
          number: 1,
          fields: [
            new FormField({
              key: "meeting_first_name",
              defaultValue: '',
            }),
            new FormField({
              key: "meeting_last_name",
              defaultValue: '',
            }),
            new FormField({
              key: "meeting_email",
              defaultValue: '',
            }),
            new FormField({
              key: "meeting_gender",
              defaultValue: '',
            }),
            new FormField({
              key: "meeting_email_content",
              defaultValue: '',
            }),
            new FormField({
              key: "meeting_email_client_id",
              defaultValue: '',
            }),
            new FormField({
              key: "meeting_email_password",
              defaultValue: '',
            }),
            new FormField({
              defaultValue: true,
              key: "meeting_fugen_sie_den_link",
              isFalseable: true
            }),
            new FormField({
              defaultValue: '',
              key: "meeting_kundenbereich_link",
              isFalseable: true
            }),
            new FormField({
              defaultValue: true,
              key: "meeting_fugt_dokumente_hinzu",
              isFalseable: true
            }),
            new FormField({
              defaultValue: true,
              key: "meeting_nur_die_materialen",
              isFalseable: true
            }),
            new FormField({
              defaultValue: TaskType.EVENT,
              key: "meeting_art_des_gesprachs",
              isFalseable: true
            }),
            new FormField({
              defaultValue: timeRounded(),
              key: "meeting_timestamp",
              isFalseable: true
            }),
            new FormField({
              defaultValue: true,
              key: "meeting_fugt_dokumente_hinzu",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_betreuungsfragebogen",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_iso_zertifikat",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_sicher_und_gut_versorgt",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_pflegeratgeber",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_prasentation_pm24",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_tuv_zertifikat",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_carebox_leaflet",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_kundeportal",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_urlaub_von_de_pflege",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_verhinderung",
              isFalseable: true
            }),
            new FormField({
              defaultValue: false,
              key: "meeting_vollmacht",
              isFalseable: true
            }),
            new FormField({
              defaultValue: true,
              key: "meeting_multiple_patients",
              isFalseable: true
            }),
            new FormField({
              defaultValue: true,
              key: "meeting_copy_to_fp",
              isFalseable: true
            }),
            new FormField({
              defaultValue: AddressType.CLIENT,
              key: "meeting_place_type",
              isFalseable: true
            }),
            new FormField({
              defaultValue: '',
              key: "meeting_strasse",
              isFalseable: true
            }),
            new FormField({
              defaultValue: '',
              key: "meeting_strasse_nr",
              isFalseable: true
            }),
            new FormField({
              defaultValue: '',
              key: "meeting_wohungsnummer",
              isFalseable: true
            }),
            new FormField({
              defaultValue: '',
              key: "meeting_plz",
              isFalseable: true
            }),
            new FormField({
              defaultValue: '',
              key: "meeting_stadt",
              isFalseable: true
            }),
            new FormField({
              defaultValue: 'Ihr Kundenbereich – Angaben vervollständigen',
              key: "mail_title",
              isFalseable: true
            }),
          ]
        }),
      ]
    });
  }
}