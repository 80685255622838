import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import * as LangSelect from "../../_constance/values/languageSelect";
import { Consents } from "../../_constance/enums/consentsFields";
import ReqHelper from "../../_helpers/request_helper";
import * as Entity from '../../_forms/BBForm/values/entity';
import { getDynamicFields } from '../../_services/user_service';
import DatePickerComponent from "../../_components/date-picker-component/DatePickerComponent"

class ConsentListComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            id: this.props.id,
            isEditable: this.props.isEditable,
            consents: Consents,
            ready: false,
            initMeAgain: this.props.initMeAgain
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.myTimeout = setTimeout(() => {
          this.getInitialDataTime()
        }, 200);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };

    initMeAgain() {
        if(this.state.isEditable===true){
            setTimeout(() => {
                this.setState({initMeAgain: false})
                this.getInitialDataTime()
            }, 200);
        }
    }
    
    handleDateConsentsChange(element, selectedDate){
        let timestamp = null;
        if(selectedDate!==null){
            timestamp = new Date(selectedDate);
            //timestamp.setUTCHours(0,0,0,0);
            timestamp = timestamp.getTime()/1000;
            this.props.setConsents(timestamp);
        }else{
            this.props.setConsents(null);
        }
        this.setState(prevState => {
            let elem = {...prevState.consents}
            elem[element] = timestamp;
            return {
              ...prevState,
              consents: elem
          }
        });
        this.props.editedData();
    }


    getInitialDataTime(){
        if(this.state.isEditable===false){
            let dataToSend = [
                {
                    "Id": this.props.id,
                    "Entity": Entity.CONTACT,
                    "Fields": ReqHelper.flattenFieldsArray(Consents)
                }
            ];
            getDynamicFields(dataToSend).then(res => {
                if (this._isMounted) {
                    this.setState({
                        consents: res[0].fields,
                        ready: true
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }else{
            if (this._isMounted) {
                this.setState({
                    consents: ReqHelper.flattenFields(Consents),
                    ready: true
                })
            }
        }
    }

    onOpenShow(){
        if (this._isMounted) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    isOpen: prevState.isOpen? false: true
                    }
                }
            );
        }
    }

    prepareMeConsents(){
        const { isEditable, translateKey, showValidation } = this.props;
        const { consents } = this.state;
        if(this.props.appLang === LangSelect.EN){
            if(isEditable===false){
                if(consents["is_zg_uk_1"]!==null){
                    return  (
                        <React.Fragment>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-12 col-form-label form-label-basic">{translateKey("is_zg_uk_1")}</label>
                            </div>
                        </React.Fragment>
                    ) 
                }else{return  '';}
            }else{
                return (
                  <React.Fragment>
                    <div className="form-group row flexAlignCenter no-margin-row">
                      <label className={`col-form-label form-label-basic ${isEditable?"col-sm-6":"col-sm-12"}`}>{translateKey("is_zg_uk_1")}<span className="red-star-required-field"> *</span></label>
                      <div className="col-sm-6">
                        <DatePickerComponent
                          placeholderText=""
                          className={consents["is_zg_uk_1"] === null && showValidation ? "is-invalid" : ""}
                          isClearable={true}
                          key={"simpleTimeStamp_is_zg_uk_1"}
                          timestamp={consents["is_zg_uk_1"] !== null? consents["is_zg_uk_1"]: ""}
                          withTime={false}
                          withPortal={false}
                          onDateChange={(e) =>this.handleDateConsentsChange("is_zg_uk_1", e)}
                          withMax={new Date()}
                        />
                        <div className={`invalid-feedback ${consents["is_zg_uk_1"] === null && showValidation ? "showError"   : ""}`}>
                          {translateKey("cannot_edit_lead_reason_consents")}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );   
            }
        }else{
            if(isEditable===false){
                let consentList = [];
                Object.keys(consents).forEach(key => {
                    if(key!=="is_zg_uk_1"){
                        let newRow = (
                            <React.Fragment key={key}>
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className={"col-form-label form-label-basic "+(isEditable===true?"col-sm-6": "col-sm-12")}>{translateKey(key)}</label>
                                    {isEditable===true &&
                                        <div className="col-sm-6">
                                            <DatePickerComponent className={consents["is_zg_uk_1"]===null && showValidation?"is-invalid": ''} readOnly="true" placeholderText="" isClearable={true} key={"simpleTimeStamp"+key} timestamp={consents[key]!==null?consents[key]:''} withTime={false} withPortal={false} onDateChange={(e)=>this.handleDateConsentsChange(key, e)}/>
                                            <div className={"invalid-feedback "+(consents["is_zg_uk_1"]===null && showValidation?"showError": '')}>{translateKey("cannot_edit_lead_reason_consents")}</div>
                                        </div>
                                    }
                                </div>
                            </React.Fragment>
                        );
                        if(isEditable===false){
                            if(consents[key]!==null){
                                consentList.push(newRow);
                            }
                        }else{
                            consentList.push(newRow);
                        }
                    }
                }); 
                return consentList;
            }else{
                return (
                  <React.Fragment>
                    <div className="form-group row flexAlignCenter no-margin-row">
                      <label className={`col-form-label form-label-basic ${isEditable === true ? "col-sm-6" : "col-sm-12"}`}>
                        {translateKey("is_zg_de_1")}<span className="red-star-required-field"> *</span>
                      </label>
                      <div className="col-sm-6">
                        <DatePickerComponent
                          className={consents["is_zg_de_1"] === null && showValidation? "is-invalid":""}
                          placeholderText=""
                          isClearable={true}
                          key="simpleTimeStamp01"
                          timestamp={consents["is_zg_de_1"] !== null? consents["is_zg_de_1"]: ""}
                          withTime={false}
                          withPortal={false}
                          onDateChange={(e) => this.handleDateConsentsChange("is_zg_de_1", e)}
                          withMax={new Date()}
                        />
                        <div className={`invalid-feedback ${consents["is_zg_de_1"] === null && showValidation? "showError": ""}`}>
                          {translateKey("cannot_edit_lead_reason_consents")}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );  
            }
        }
    }

    isAtLeastOneSigned(){
        const { isEditable, appLang } = this.props;
        if(isEditable){
            return true;
        }else{
            const { consents } = this.state;
            if(appLang === LangSelect.EN){
                if(consents["is_zg_uk_1"]!==null){
                    return true;
                }else{
                    return false;
                }
            }else{
                let gotSome = false;
                Object.keys(consents).forEach(key => {
                    if(consents[key]!==null){
                        gotSome = true;
                    }
                });
                return gotSome;
            }
        }
    }

    render() {
        if(this.props.initMeAgain){this.initMeAgain()}
        const { translateKey } = this.props;
        const { ready, isOpen} = this.state;
        let consentsData;
        if(isOpen){
            consentsData = (
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-12">
                        {this.prepareMeConsents()}
                    </div>
                </div>
            )
        }
        if(ready){
            if(this.isAtLeastOneSigned()){
                return (
                    <div className="lead-client-row">
                        <div className="lead-details-header">
                            <div className="filter-modal-option-text">{translateKey("consents")}</div>
                            <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                                {isOpen?translateKey("show_less"):translateKey("show_more")}
                            </div>
                        </div>
                        {consentsData}
                    </div>
                );
            }else{
                return '';
            }
        }else return '';
    }
} 

const mapStateToProps = state => {return {appLang: state.lang.appLanguage}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConsentListComponent));