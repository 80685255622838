import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ListMovementComponent from "../../form-bb-components/list-movement-component/list-movement-component";
import { InputTypeTextComponent } from "../../form-bb-components/input-type-text-component/input-type-text-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import SliderRangeComponent from "../../form-bb-components/slider-range-component/slider-range-component";
import produce from "immer";

const IS_HOW_OFTEN_CARER_MUST_GET_AP_AT_NIGHT_DE = 'is_how_often_carer_must_get_up_at_night_de'; 
const IS_REMARKS_SLEEP_DE = 'is_remarks_sleeps_de';
const IS_REMARKS_SLEEP_DE_HELPER = 'is_remarks_sleeps_de_helper'; 
const STATIC_ENUM_FOR_5_AND_MORE = '5 und Mehr'; 
const marks = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "Mehr" }
];
const TYPETOGET = "SLIDER";

class SectionRuhenUndSchlafen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-13'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidMount() {
      const { section } = this.state;
      const Field = section.getFieldByKey(IS_REMARKS_SLEEP_DE);
      const foundSpecial = this._formatHelperToSeparateSliderValue(Field.value, TYPETOGET);
      if(foundSpecial!==0){
        this.setTypeChange(IS_REMARKS_SLEEP_DE_HELPER, this._formatHelperToSeparateSliderValue(Field.value));
      }
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({showValidation: showValidationErrors});
        }
    }

    setTypeChange = (key, value) => {
      const nextState = produce(this.state, draftState => {
          const { section } = draftState;
          const field = section.getFieldByKey(key);
          if (field) {
              field.value = value;
          }
      });
      this.setState(nextState);
    };

    setSliderRangeChange = (key, value) => {
      const nextState = produce(this.state, draftState => {
          const { section } = draftState;
          const field = section.getFieldByKey(key);
          const fieldRemarkableInput = section.getFieldByKey(IS_REMARKS_SLEEP_DE_HELPER);
          if(key===IS_REMARKS_SLEEP_DE){
            if(value===0){
              if(fieldRemarkableInput){
                fieldRemarkableInput.value='';
              }
              field.value = '0';
            }else{
              const restString = this._formatHelperToSeparateSliderValue(field.value)
              if (field) {
                if(value===5){
                  field.value = `${STATIC_ENUM_FOR_5_AND_MORE}|${restString}`;
                }else{
                  field.value = `${value}|${restString}`;
                }
              }
            }
          }else{
            if (field) {
              if(value===5){
                field.value = STATIC_ENUM_FOR_5_AND_MORE;
              }else{
                field.value = value; 
              }
            }
          }
      });
      this.setState(nextState);
    }

    setRemarksSleepTypeChange = (key, value) => {
      const nextState = produce(this.state, draftState => {
        const { section } = draftState;
        const field = section.getFieldByKey(key);
        if (field) {
          field.value = value;
        }
        const fieldWithSleep = section.getFieldByKey(IS_REMARKS_SLEEP_DE);
        if (fieldWithSleep) {
          fieldWithSleep.value = `${this._formatHelperToSeparateSliderValue(fieldWithSleep.value, TYPETOGET)}|${value}`;
        }
      });
      this.setState(nextState);
    }

    getMeSpecialValueFromKey(key){
        const { section } = this.state;
        let Field = section.getFieldByKey(IS_HOW_OFTEN_CARER_MUST_GET_AP_AT_NIGHT_DE);
        if(key===IS_REMARKS_SLEEP_DE){
          Field = section.getFieldByKey(IS_REMARKS_SLEEP_DE);
        }
        let ValueOfField = Field.value;
        if(ValueOfField === STATIC_ENUM_FOR_5_AND_MORE){
            return 5;
        }else{
            let found = marks.find(element => element.value.toString() === ValueOfField.toString());
            if(found){
                return ValueOfField;
            }else{
              const foundSpecial = this._formatHelperToSeparateSliderValue(ValueOfField, TYPETOGET);
              if(foundSpecial===STATIC_ENUM_FOR_5_AND_MORE){
                return 5;
              }else{
                if(foundSpecial>0){
                  return foundSpecial;
                }
              }
              return 0;
            }
        }
    }

    _formatHelperToSeparateSliderValue =(value, type) =>{
      if(type===TYPETOGET){
        if(value){
          if(value.length>0){
            const sliderValueArr = value.split('|');
            if(sliderValueArr.length>0){
              return sliderValueArr[0];
            }
          }
        }
        return 0;
      }else{
        if(value){
          if(value.length>0){
            const sliderValueArr = value.split('|');
            if(sliderValueArr.length>1){
              return sliderValueArr[1]
            }
          }
        }
        return '';
      }
    }

    checkIfValueExists=(value)=>{
      if(value === STATIC_ENUM_FOR_5_AND_MORE){
        return true;
      }else{
        let found = marks.find(element => element.value.toString() === value.toString());
        if(found){
            if(value>0){
              return true;
            }else{ 
              return false;
            }
        }else{
          return false;
        }
      }
    }

    checkIfValueExistsRemarksSleep=()=>{
      const { section } = this.state;
      const Field = section.getFieldByKey(IS_REMARKS_SLEEP_DE);
      if(Field){
        const foundSpecial = this._formatHelperToSeparateSliderValue(Field.value, TYPETOGET);
        if(foundSpecial===STATIC_ENUM_FOR_5_AND_MORE){
          return true;
        }else{
          if(foundSpecial>0){
            return true
          }
        }
      }
      return false;
    }

    render() {
        const {
          disabled,
          translateKey,
          isImportant,
          showValidationErrors,
          sectionNumber,
          subsectionName,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const isRemarksSleepsDe = section.getFieldByKey(IS_REMARKS_SLEEP_DE);
        const isRemarksSleepsDeHelper = section.getFieldByKey(IS_REMARKS_SLEEP_DE_HELPER);
        const isHowOftenCarerMustGetUpAtNightDe = section.getFieldByKey(IS_HOW_OFTEN_CARER_MUST_GET_AP_AT_NIGHT_DE);
        const isActivitiesPBCAND = section.getFieldByKey('is_activities_performed_by_carer_at_night_de');
        const isPatientMHD = section.getFieldByKey('is_patient_medicines_hypnotics_de');

        const listClassName = "col-sm-12 col-lg-3 form-margin-bottom-fix";
        const inputClassName = "col-sm-12 col-lg-6 form-margin-bottom-fix";
        const labelClass = "col-sm-12";
        const directionType ="column";
        const isNewUI = true; 

        return (
          <div className="form-section-main">
            <div className="new-section-name-wrapper">
              {sectionNumber}. {subsectionName}
            </div>
            <form className="new-section-content-wrapper container form_serializer">
              <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>

                {isImportant===true && !section.allImportantsFilled &&
                    <div className="newUI-section-padding important-message-class">
                        <div className="tumor-or-dekubitus-info-wrapper"> 
                            {translateKey("form_important_fields")}
                        </div>
                    </div>
                }

                <div className="newUI-section-padding">
                  <div className="row">
                    <ListMovementComponent
                      newUI={isNewUI}
                      type={directionType}
                      showValidation={showValidationErrors}
                      setTypeChangeToParent={this.setTypeChange}
                      className={listClassName}
                      transKey="form-text-einschlafen"
                      key="is_falling_asleep"
                      keyName="is_falling_asleep"
                      field={section.getFieldByKey("is_falling_asleep")}
                      value={section.getFieldByKey("is_falling_asleep").value}
                      validationErrors={
                        section.getFieldByKey("is_falling_asleep")
                          .validationErrors
                      }
                    />
                    <ListMovementComponent
                      newUI={isNewUI}
                      type={directionType}
                      showValidation={showValidationErrors}
                      setTypeChangeToParent={this.setTypeChange}
                      className={listClassName}
                      transKey="form-text-durchschlafen"
                      key="is_sleep_over"
                      keyName="is_sleep_over"
                      field={section.getFieldByKey("is_sleep_over")}
                      value={section.getFieldByKey("is_sleep_over").value}
                      validationErrors={
                        section.getFieldByKey("is_sleep_over").validationErrors
                      }
                    />
                  </div>
                </div>
                <div className="newUI-section-padding">
                  <div className="row">
                    <InputTypeTextComponent
                      newUI={isNewUI}
                      isImportant={isImportant && isPatientMHD.checkImportant}
                      showValidation={showValidationErrors}
                      className={inputClassName}
                      labelClass={labelClass}
                      setTypeChangeToParent={this.setTypeChange}
                      field={isPatientMHD}
                      text={translateKey("form-text-schlafmedikation")}
                      transKey="form-text-schlafmedikation"
                      keyName="is_patient_medicines_hypnotics_de"
                    />
                  </div>
                </div>







                <div className="newUI-section-padding">
                  <div className="row">
                    <SliderRangeComponent
                        key={Math.random().toString(36).substr(2, 9)}
                        keyName={IS_REMARKS_SLEEP_DE}
                        value={this.getMeSpecialValueFromKey(IS_REMARKS_SLEEP_DE)}
                        showValidation={showValidationErrors}
                        validationErrors={isRemarksSleepsDe.validationErrors}
                        className="col-sm-12 col-lg-6"
                        disabled={disabled}
                        text={translateKey("form-text-bemerkungen")}
                        minValue={0}
                        maxValue={5} 
                        step={1}
                        marks={marks}
                        setTypeChangeToParent={this.setSliderRangeChange}
                    />
                  </div>
                </div>
                {this.checkIfValueExistsRemarksSleep(isRemarksSleepsDe.value) && (
                  <div className="newUI-section-padding">
                    <div className="row">
                      <InputTypeTextComponent
                        newUI={isNewUI}
                        showValidation={showValidationErrors}
                        className={inputClassName}
                        labelClass={labelClass}
                        setTypeChangeToParent={this.setRemarksSleepTypeChange}
                        field={isRemarksSleepsDeHelper}
                        text={translateKey("form-text-aus-welchem")}
                        transKey="form-text-aus-welchem"
                        keyName={IS_REMARKS_SLEEP_DE_HELPER}
                      />
                    </div> 
                  </div>
                )}







                <div className="newUI-section-padding">
                  <div className="row">
                    <SliderRangeComponent
                        key={Math.random().toString(36).substr(2, 9)}
                        keyName={IS_HOW_OFTEN_CARER_MUST_GET_AP_AT_NIGHT_DE}
                        value={this.getMeSpecialValueFromKey(IS_HOW_OFTEN_CARER_MUST_GET_AP_AT_NIGHT_DE)}
                        showValidation={showValidationErrors}
                        validationErrors={isHowOftenCarerMustGetUpAtNightDe.validationErrors}
                        className="col-sm-12 col-lg-6"
                        disabled={disabled}
                        text={translateKey("form-text-wieoftmuss")}
                        minValue={0}
                        maxValue={5} 
                        step={1}
                        marks={marks}
                        setTypeChangeToParent={this.setSliderRangeChange}
                    />
                  </div>
                </div>
                {this.checkIfValueExists(isHowOftenCarerMustGetUpAtNightDe.value) && (
                  <div className="newUI-section-padding">
                    <div className="row">
                      <InputTypeTextComponent
                        newUI={isNewUI}
                        showValidation={showValidationErrors}
                        className={inputClassName}
                        labelClass={labelClass}
                        setTypeChangeToParent={this.setTypeChange}
                        field={isActivitiesPBCAND}
                        text={translateKey("form-text-beitatigkeiten")}
                        transKey="form-text-beitatigkeiten"
                        keyName="is_activities_performed_by_carer_at_night_de"
                      />
                    </div> 
                  </div>
                )}
              </fieldset>
            </form>
            <SectionNavigationButtonsComponent
              form={form}
              analizeFinished={analizeFinished}
              currentSection={currentSection}
              rerender={rerender}
              previousPageClick={previousPageClick}
              nextPageClick={nextPageClick}
              finishMe={finishMe}
              showNotePop={disabled?false:true}
            />
          </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(SectionRuhenUndSchlafen));