import * as React from 'react';

import { connect } from 'react-redux';
import { translateKey } from '../../../../_redux/actions/index';
import { IconsMap } from "../../../../_helpers/icons_set";

import CircleWithBadge from './CircleWithBadge';
import MovieButton from './MovieButton';
const VideoUrlLinkAllTag = 'https://www.youtube.com/embed/BzFTdNDxCLA?rel=0';
const VideoUrlLinkBildFilm= 'https://www.youtube.com/embed/8TF-yqh4r1w?rel=0';

class FirstSliderContent extends React.Component {
  state = {
    isVideoActive: false,
    videoSrc: VideoUrlLinkAllTag
  }

  toggleVideo = (sliderNumber) => {
    let videoSrcTemp = VideoUrlLinkAllTag;
    if(sliderNumber!==0){
      videoSrcTemp=VideoUrlLinkBildFilm;
    }
    this.setState({
      isVideoActive: !this.state.isVideoActive,
      videoSrc: videoSrcTemp
    })
  }

  renderSecondColumn = (sliderNumber) =>{
    return (
      <div className="col-xl-4 col-sm-12 no-gutters d-flex justify-content-center align-items-center">
        <MovieButton onClick={()=>this.toggleVideo(sliderNumber)} secondTransKey={sliderNumber===0?'summary-offer-promedica-alltagsbetruung':'summary-offer-promedica-bildfilm'}/>
      </div>
    );
  }

  render() {
    const { translateKey } = this.props;
    const { isVideoActive, videoSrc } = this.state;

    if (isVideoActive) {
      return (
        <div className="video-wrapper d-flex flex-column w-100 h-100 m-6">
          <div 
            role="button"
            className="d-flex justify-content-end default-btn-hover" 
            onClick={this.toggleVideo}
          >
            <img src={IconsMap.other.close_icon} alt="x"/>
          </div>
          <iframe 
            width="100%" 
            height="100%" 
            src={videoSrc} 
            frameBorder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
            title={videoSrc}
          />
        </div>
      )
    }

    const header = (
      <CircleWithBadge
        circleClassName="mr-3"
        iconClassName="custom-circle-icon"
        iconSource={IconsMap.svg.medal}
        rightNode={
          <div className="custom-circle-right-label">
            {translateKey("summary-offer-section-1-label")}
          </div>
        }
      />
    );

    return (
      <div className="carousel-inner">
        <div className="carousel-item active h-100" data-interval="false">
          <div className="carousel-section d-none d-md-block h-100">
            <div className="container-fluid no-gutters p-0 h-100">
              <div className="row no-gutters w-100">
                <div className="col no-gutters">
                  {header}
                </div>
              </div>
              <div className="row no-gutters w-100">
                <div className="col-xl-8 col-sm-12 no-gutters h-100">
                  <div className="section-title">
                    {translateKey("summary-offer-section-1-title-1")}
                  </div>
                  <div className="section-item">
                    <i className="fas fa-check fa-1x mr-md-2" />
                    {translateKey("summary-offer-section-1-item-1")}
                  </div>
                  <div className="section-item">
                    <i className="fas fa-check fa-1x mr-md-2" />
                    {translateKey("summary-offer-section-1-item-2")}
                  </div>
                  <div className="section-item">
                    <i className="fas fa-check fa-1x mr-md-2" />
                    {translateKey("summary-offer-section-1-item-3")}
                  </div>
                  <div className="section-item">
                    <i className="fas fa-check fa-1x mr-md-2" />
                    {translateKey("summary-offer-section-1-item-4")}
                  </div>
                  <div className="section-item">
                    <i className="fas fa-check fa-1x mr-md-2" />
                    {translateKey("summary-offer-section-1-item-5")}
                  </div>
                </div>
                {this.renderSecondColumn(1)}
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item h-100" data-interval="false">
          <div className="carousel-section d-none d-md-block h-100">
            <div className="container-fluid no-gutters p-0 h-100">
              <div className="row no-gutters w-100">
                <div className="col no-gutters">
                  {header}
                </div>
              </div>
              <div className="row no-gutters w-100">
                <div className="col-xl-8 col-sm-12 no-gutters h-100">
                  <div className="section-title">
                    {translateKey("summary-offer-section-2-title-1")}
                  </div>
                  <div className="section-item d-flex">
                    <i className="fas fa-check fa-1x mr-md-2 mt-md-1" />
                    <div>
                      <b>{translateKey("summary-offer-section-2-item-1_part_1")}</b>
                      {`: `}
                      {translateKey("summary-offer-section-2-item-1_part_2")}
                    </div>
                  </div>
                  <div className="section-item d-flex">
                    <i className="fas fa-check fa-1x mr-md-2 mt-md-1" />
                    <div>
                      <b>{translateKey("summary-offer-section-2-item-2_part_1")}</b>
                      {`: `}
                      {translateKey("summary-offer-section-2-item-2_part_2")}
                    </div>
                  </div>
                  <div className="section-item d-flex">
                    <i className="fas fa-check fa-1x mr-md-2 mt-md-1" />
                    <div>
                      <b>{translateKey("summary-offer-section-2-item-3_part_1")}</b>
                      {`: `}
                      {translateKey("summary-offer-section-2-item-3_part_2")}
                    </div>
                  </div>
                  <div className="section-item d-flex">
                    <i className="fas fa-check fa-1x mr-md-2 mt-md-1" />
                    <div>
                      <b>{translateKey("summary-offer-section-2-item-4_part_1")}</b>
                      {`: `}
                      {translateKey("summary-offer-section-2-item-4_part_2")}
                    </div>
                  </div>
                  <div className="section-item d-flex">
                    <i className="fas fa-check fa-1x mr-md-2 mt-md-1" />
                    <div>
                      <b>{translateKey("summary-offer-section-2-item-5_part_1")}</b>
                      {`: `}
                      {translateKey("summary-offer-section-2-item-5_part_2")}
                    </div>
                  </div>
                </div>
                {this.renderSecondColumn(0)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });

export default connect(null, mapDispatchToProps)(FirstSliderContent);