import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ListComponent from "../../form-bb-components/list-component/list-component";
import produce from "immer";
import { IconsMap } from "../../../_helpers/icons_set";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";

class Section5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-5'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    render() {
        const {
          disabled,
          subsectionName,
          sectionNumber,
          showValidationErrors,
          translateKey,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const isCommunicationHearing = section.getFieldByKey('is_communication_hearing');
        const isCommunicationVision = section.getFieldByKey('is_communication_vision');
        const isCommunicationSpeech = section.getFieldByKey('is_communication_speech');
        const classForList = "col-sm-12 col-lg-4 form-margin-bottom-fix text-align-center";
        const isNewUI = true;
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListComponent
                                    disabled={disabled}
                                    newUI={isNewUI}
                                    showValidation={showValidationErrors}
                                    setTypeChangeToParent={this.setTypeChange}
                                    className={classForList}
                                    transKey="form-text-horen"
                                    key="is_communication_hearing"
                                    keyName="is_communication_hearing"
                                    field={isCommunicationHearing}
                                    type="column"
                                    icon={IconsMap.other.kommunikation_ear}
                                    value={isCommunicationHearing.value}
                                    validationErrors={isCommunicationHearing.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: translateKey("form-text-uneingeschrankt") },
                                        { value: '717700001', label: translateKey("form-text-teilweise") },
                                        { value: '717700002', label: translateKey("form-text-eingeschrankt") }
                                    ]}
                                />

                                <ListComponent
                                    disabled={disabled}
                                    newUI={isNewUI}
                                    showValidation={showValidationErrors}
                                    setTypeChangeToParent={this.setTypeChange}
                                    className={classForList}
                                    transKey="form-text-sehen"
                                    key="is_communication_vision"
                                    keyName="is_communication_vision"
                                    field={isCommunicationVision}
                                    type="column"
                                    icon={IconsMap.other.kommunikation_eye}
                                    value={isCommunicationVision.value}
                                    validationErrors={isCommunicationVision.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: translateKey("form-text-uneingeschrankt") },
                                        { value: '717700001', label: translateKey("form-text-teilweise") },
                                        { value: '717700002', label: translateKey("form-text-eingeschrankt") }
                                    ]}
                                />

                                <ListComponent
                                    disabled={disabled}
                                    newUI={isNewUI}
                                    showValidation={showValidationErrors}
                                    setTypeChangeToParent={this.setTypeChange}
                                    className={classForList}
                                    transKey="form-text-sprechen"
                                    key="is_communication_speech"
                                    keyName="is_communication_speech"
                                    field={isCommunicationSpeech}
                                    type="column"
                                    icon={IconsMap.other.kommunikation_hearing}
                                    value={isCommunicationSpeech.value}
                                    validationErrors={isCommunicationSpeech.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: translateKey("form-text-uneingeschrankt") },
                                        { value: '717700001', label: translateKey("form-text-teilweise") },
                                        { value: '717700002', label: translateKey("form-text-eingeschrankt") }
                                    ]}
                                />
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section5)); 