import * as React from 'react';
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";
import { ReqHelper } from "../../../_helpers/index";
import Slider from "@material-ui/core/Slider";
import { withStyles } from '@material-ui/core/styles';
const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.2)';
const IOSSlider = withStyles({
    root: {
    color: '#003883',
    height: 2,
    padding: '15px 0',
    },
    thumb: {
    height: 34,
    width: 34,
    backgroundColor: '#045aa7',
    border: '9px solid white',
    boxShadow: iOSBoxShadow,
    marginTop: -16,
    marginLeft: -17,
    '&:focus, &:hover, &$active': {
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
        },
    },
    },
    active: {},
    valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
        background: 'transparent',
        color: '#000',
    },
    },
    track: {
    height: 2,
    },
    markLabel:{
        top: 40,
    },
    rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#aab5c1',
    }
})(Slider);
    
export default class SliderRangeComponent extends React.Component {

    setRangeTypeValue = (event, value) => {
        const { keyName, setTypeChangeToParent, disabled } = this.props;
        if(!disabled){
            setTypeChangeToParent(keyName, value);
        }
    }

    render() {
        const { keyName, value, validationErrors, showValidation, className,labelClass, disabled, text, minValue, maxValue, step, marks} = this.props;
        const showErrors = showValidation && validationErrors.length > 0;
        let finalValue = value;
        if(!ReqHelper.isInteger(value)){
            finalValue=0;
        }else{
            finalValue= parseInt(finalValue);
        }
        return (
            <div className={`${className} ${showErrors ? 'is-invalid' : ''}`}>
                <label className={`form-label-basic newUI ${labelClass?labelClass:''}`}>
                    <span className="quick-label-position-fix">
                        {text.toUpperCase()}
                    </span>
                </label>
                <div className='col-sm-12'>
                    <IOSSlider aria-label="ios slider"
                        value={finalValue?finalValue:minValue}
                        aria-labelledby="discrete-slider-custom"
                        step={step?step:1}
                        valueLabelDisplay="off"
                        marks={marks}
                        min={minValue}
                        max={maxValue}
                        disabled={disabled}
                        onChange={this.setRangeTypeValue}
                    />
                </div>

                { showErrors && (
                    <ValidationErrorsList errors={validationErrors} keyName={keyName} />
                )}
            </div>
        );
    }
}