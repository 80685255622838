export const SECTION_LIST = [
  { pageNumber:0,name: "rodo_section_1" },
  { pageNumber:1, name: "rodo_section_2" },
  { pageNumber:2, name: "rodo_section_signature" },
  { pageNumber:3, name: "angaben_zur_kontaktperson" },
  { pageNumber:4, name: "angaben_zur_kontaktperson_vor_ort" },
  { pageNumber:5, name: "angaben_zur_zu_betreuenden_person" },
  { pageNumber:6, name: "angaben_zur_pflegebediurftigkeit" },
  { pageNumber:7, name: "krankheitsbilder" },
  { pageNumber:8, name: "kommunikation" },
  { pageNumber:9, name: "orientierung" },
  { pageNumber:10, name: "sich_bewegen" },
  { pageNumber:11, name: "pflegedienst" },
  { pageNumber:12, name: "angaben_und_aufgaben_zur_betreuungskraft" },
  { pageNumber:13, name: "angaben_zur_tatigkeit_der_betreuungskraft" },
  { pageNumber:14, name: "beschreibung_der_unterkunft" },
  { pageNumber:15, name: "Erstangebot" },
  { pageNumber:16, name: "krankheitsbilder_after_offer" },
  { pageNumber:17, name: "hilfsmittel" },
  { pageNumber:18, name: "gemutszustand" },
  { pageNumber:19, name: "inkontinenz" },
  { pageNumber:20, name: "hygiene" },
  { pageNumber:21, name: "essen_und_trinken" },
  { pageNumber:22, name: "sich_kleiden" },
  { pageNumber:23, name: "ruhen_und_schlafen" },
  { pageNumber:24, name: "pflegedienst_after_offer" },
  { pageNumber:25, name: "therapien" },
  { pageNumber:26, name: "wohnort_der_zu_betreuenden_person" },
  { pageNumber:27, name: "anwesend_bei_der_erstellung_der_anamnese" },
  { pageNumber:28, name: "notiz" },
  { pageNumber:29, name: "zusammenfassung" },
  { pageNumber:30, name: "care_box" },
  { pageNumber:31, name: "finales_angebot" } 
]

export const getSectionNumberByName = (name) => {
  let elementNeeded = SECTION_LIST.find(element=>element.name===name)
  if(elementNeeded){
    return elementNeeded.pageNumber;
  }else{
    return 3;
  }
}

export const getSectionNameByNumber = (sectionNumber) => {
  let elementNeeded = SECTION_LIST.find(element=>element.pageNumber===sectionNumber)
  if(elementNeeded){
    return elementNeeded.name;
  }else{
    return 'rodo_section_1';
  }
}