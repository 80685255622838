import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey, authSetActionTimestamp } from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, EmptyTableIcon, AlertMessage, PhoneEmailContactComponent } from "../../_components/index";
import { IconsMap } from "../../_helpers/icons_set";
import { Alert} from "../../_constance/classes/index";
import axiosAuth from "../../_services/config/axios-auth";
import { baseData } from '../../_constance/base_data';
import * as ContactTypes from '../../_constance/values/contactFieldType';
import * as LangSelect from "../../_constance/values/languageSelect";
class InformationPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      contactDataObj: null,
      errorComponent: Alert,
      loading: true,
      breadCrumbs:[
        {
          link: "contact",
          key: "contact",
          name: null
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.getBasicData();
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };

  getBasicData(){
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    axiosAuth.get("contact/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        if (this._isMounted) {
          this.setState({
            contactDataObj: res,
            loading: false,
            errorComponent: Alert
          })
        }
      })
      .catch((error) => {
        this.alertD.show = true;
        this.alertD.type = "danger";
        this.alertD.message = this.props.translateKey("basic_error_message");
        if (this._isMounted) {
          this.setState({
            contactDataObj: null,
            errorComponent: this.alertD
          })
        }
        console.log(error);
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ errorComponent: Alert });
          }
        }, 3000);
      });
  }

  render() {
    const {translateKey} = this.props;
    let ITSupportEmail = "salesconfigurator@promedica24.pl";
    let ITInstruction = 'https://crm.promedica24.com/mobileApps/prod/pm24/Anleitung_Web.pdf';
    if(this.props.appLang!==LangSelect.DE){
      ITSupportEmail = "app@promedica24.co.uk";
      ITInstruction = 'https://crm.promedica24.com/mobileApps/prod/pm24/Manual_Web.pdf';
    }
    let emptyHandler;
    let PreparedTableContent;
    if (this.state.loading) {
      emptyHandler = <LoaderTable />
    } else {
      if (this.state.contactDataObj === null) {
        emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data")} />
      } else {
        PreparedTableContent = (
          <div className="main-information-content">
            <div className="information-option-wrapper">
              <div className="filter-modal-option-info">
                <div className="filter-modal-option-text">{translateKey("contact_emergency")}</div>
              </div>
              <div className="basic-information">
                <PhoneEmailContactComponent text={this.state.contactDataObj.EmergencyNumber} type={ContactTypes.PHONE}></PhoneEmailContactComponent>
              </div>
            </div>

            <div className="information-option-wrapper">
              <div className="filter-modal-option-info">
                <div className="filter-modal-option-text">{translateKey("contact_debt")}</div>
              </div>
              <div className="basic-information">
                <span className="basic-information-info person-title">{this.state.contactDataObj.Debt.Name}</span>
                <PhoneEmailContactComponent text={this.state.contactDataObj.Debt.Phone} type={ContactTypes.PHONE}></PhoneEmailContactComponent>
                <PhoneEmailContactComponent text={this.state.contactDataObj.Debt.Email} type={ContactTypes.EMAIL}></PhoneEmailContactComponent>
              </div>
            </div>

            <div className="information-option-wrapper">
              <div className="filter-modal-option-info">
                <div className="filter-modal-option-text">{translateKey("contact_coordinator")}</div>
              </div>
              <div className="basic-information">
                <span className="basic-information-info person-title">{this.state.contactDataObj.Coordinator.Name}</span>
                <PhoneEmailContactComponent text={this.state.contactDataObj.Coordinator.Phone} type={ContactTypes.PHONE}></PhoneEmailContactComponent>
                <PhoneEmailContactComponent text={this.state.contactDataObj.Coordinator.Email} type={ContactTypes.EMAIL}></PhoneEmailContactComponent>
              </div>
            </div>

            
            <div className="information-option-wrapper">
              <div className="filter-modal-option-info">
                <div className="filter-modal-option-text">{translateKey("technical-support-for-apps")}</div>
              </div>
              <div className="basic-information">
                <PhoneEmailContactComponent text={ITSupportEmail} type={ContactTypes.EMAIL}></PhoneEmailContactComponent>
              </div>
            </div>

            <div className="information-option-wrapper">
              <div className="filter-modal-option-info">
                <div className="filter-modal-option-text">{translateKey("web-application-instruction")}</div>
              </div>
              <div className="basic-information">
                <span className="basic-information-info">
                  <a target="_blank" href={ITInstruction} rel="noopener noreferrer">
                    {translateKey("web-application-manual")}
                  </a>
                </span>
              </div>
            </div>

            <div className="information-option-wrapper">
              <div className="filter-modal-option-info">
                <div className="filter-modal-option-text">{this.state.contactDataObj.Promedica.Name}</div>
              </div>
              <div className="basic-information">
                <span className="basic-information-info">{this.state.contactDataObj.Promedica.Address}</span>
                <span className="basic-information-info">{this.state.contactDataObj.Promedica.Fax}</span>
                <span className="basic-information-info">
                  <a target="_blank" href={this.state.contactDataObj.Promedica.Web} rel="noopener noreferrer">
                    {this.state.contactDataObj.Promedica.Web}
                  </a>
                </span>
                <PhoneEmailContactComponent text={this.state.contactDataObj.Promedica.Phone} type={ContactTypes.PHONE}></PhoneEmailContactComponent>
                <PhoneEmailContactComponent text={this.state.contactDataObj.Promedica.Email} type={ContactTypes.EMAIL}></PhoneEmailContactComponent>
              </div>
            </div>

            <div className="information-option-wrapper">
              <div className="filter-modal-option-info">
                <div className="filter-modal-option-text">{this.state.contactDataObj.Headquarters.Name}</div>
              </div>
              <div className="basic-information">
                <span className="basic-information-info">{this.state.contactDataObj.Headquarters.Address}</span>
                <span className="basic-information-info">{this.state.contactDataObj.Headquarters.Fax}</span>
                <span className="basic-information-info">
                  <a target="_blank" href={this.state.contactDataObj.Headquarters.Web} rel="noopener noreferrer">
                    {this.state.contactDataObj.Headquarters.Web}
                  </a>
                </span>
                <PhoneEmailContactComponent text={this.state.contactDataObj.Headquarters.Phone} type={ContactTypes.PHONE}></PhoneEmailContactComponent>
                <PhoneEmailContactComponent text={this.state.contactDataObj.Headquarters.Email} type={ContactTypes.EMAIL}></PhoneEmailContactComponent>
              </div>
            </div>
            <div className="information-option-wrapper">
              <div className="basic-information">
                <b>{translateKey('profile-app-version')+' '} {baseData.releaseDate}</b>
              </div>
            </div>
          </div>
        );
      }
    }
    let alertComponent = <AlertMessage options={this.state.errorComponent}></AlertMessage>
    return (
      <div className="mainContentWrapper" onClick={()=>this.props.tookAction()}>
        <NavBar breadCrumbs={this.state.breadCrumbs}/>
        <TableProperties options={[]} filterData={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper">
            <div className="text-center">
              <img className="prom-logo-information" src={IconsMap.svg.promo_logo} alt={"Promedica"}/>
            </div>
            {PreparedTableContent}
            {emptyHandler}
            {alertComponent}
            <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {return {appLang: state.lang.appLanguage}};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp())
});
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(InformationPage));