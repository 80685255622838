import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import * as Relationship from "../../../_forms/BBForm/values/relationship";
import produce from "immer";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import { getIp } from '../../../_services/backend_service';
const isDegreeRelationship = 'is_degree_relationship_with_patient_carer';
const isCustomerAreaIpAddress = 'is_customer_area_ip_address';

class RodoSection2 extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-rodo-consents'),
            showLeft:false,
            myIP: '0.0.0.0'
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const { form } = this.props;
        const isDegreeRelationShipWithPatientCarer = form.getFieldByKey(isDegreeRelationship);
        if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8 ) {
            this.setState({ showLeft: true})
        }else{
            this.setState({ showLeft: false })
        }
        getIp()
        .then(res => {
          if(res){
            if(res.status===200){
              if (this._isMounted) {
                  this.setState({
                    myIP:res.data.data
                  })
              }
            }
          }
        })
        .catch((error) => {
            console.log(error);
        });
        this.prepareMeDataToBasedOn();
    }

    componentWillUnmount() {
        this._isMounted = false;
    };

    getMeTimeStampNow(){
        let timestamp = new Date().getTime();
        timestamp = Math.floor(timestamp / 1000);
        return timestamp;
    }

    prepareMeDataToBasedOn(){
        const { section } = this.state;
        const { multiplePatients, form } = this.props;
        const isZgDe1 = section.getFieldByKey("is_zg_de_1");
        const isZgDe2 = section.getFieldByKey("is_zg_de_2");
        const isZgDe3 = section.getFieldByKey("is_zg_de_3");
        const isZgDe4 = section.getFieldByKey("is_zg_de_4");
        const isZgDe5 = section.getFieldByKey("is_zg_de_5");
        const isZgDe6 = section.getFieldByKey("is_zg_de_6");
        const isZgDe7 = section.getFieldByKey("is_zg_de_7");
        const isZgDe8 = section.getFieldByKey("is_zg_de_8");

        if(isZgDe1.value !==null && isZgDe1.value!==0){
            const field = section.getFieldByKey("einwilligung_zur_unterbeitung");
            if (field) {
                field.value = true;
            }
        }
        if(isZgDe2.value !==null && isZgDe2.value!==0){
            const field = section.getFieldByKey('einwilligung_in_die_verarbeitung');
            if (field) {
                field.value = true;
            }
        }
        if(isZgDe3.value !==null && isZgDe3.value!==0){
            const field = section.getFieldByKey('die_verwendent_werden');
            if (field) {
                field.value = true;
            }
        }
        if(isZgDe4.value !==null && isZgDe4.value!==0){
            const field = section.getFieldByKey('marketing_einwilligungen_post_yes');
            if (field) {
                field.value = true;
            }
        }
        if(isZgDe5.value !==null && isZgDe5.value!==0){
            const field = section.getFieldByKey('marketing_einwilligungen_telephone_yes');
            if (field) {
                field.value = true;
            }
        }
        if(isZgDe6.value !==null && isZgDe6.value!==0){
            const field = section.getFieldByKey('einwilligungen_die_daten_telephone_yes');
            if (field) {
                field.value = true;
            }
        }
        if(isZgDe7.value !==null && isZgDe7.value!==0){
            const field = section.getFieldByKey('einwilligungen_die_daten_post_yes');
            if (field) {
                field.value = true;
            }
        }
        if(isZgDe8.value !==null && isZgDe8.value!==0){
            const field = section.getFieldByKey('einwilligungen_die_daten_email_yes');
            if (field) {
                field.value = true;
            }
        }
        if(multiplePatients) {
            form.getFieldByKey('die_verwendent_werden').value = null;
            form.getFieldByKey('einwilligung_in_die_verarbeitung').value = null;
            form.getFieldByKey('einwilligung_zur_unterbeitung').value = null;

            if (!form.getFieldByKey('marketing_einwilligungen_telephone_yes').value) {
                const field = section.getFieldByKey("marketing_einwilligungen_telephone_no");
                if (field) {
                    field.value = true;
                }
            }

            if (!form.getFieldByKey('marketing_einwilligungen_post_yes').value) {
                const field = section.getFieldByKey("marketing_einwilligungen_post_no");
                if (field) {
                    field.value = true;
                }
            }

            if (!form.getFieldByKey('einwilligungen_die_daten_email_yes').value) {
                const field = section.getFieldByKey("einwilligungen_die_daten_email_no");
                if (field) {
                    field.value = true;
                }
            }

            if (!form.getFieldByKey('einwilligungen_die_daten_telephone_yes').value) {
                const field = section.getFieldByKey("einwilligungen_die_daten_telephone_no");
                if (field) {
                    field.value = true;
                }
            }

            if (!form.getFieldByKey('einwilligungen_die_daten_post_yes').value) {
                const field = section.getFieldByKey("einwilligungen_die_daten_post_no");
                if (field) {
                    field.value = true;
                }
            }
        }
    }

    setTypeChangeConsentsType2Rodo1 = ( key, value) => {
        const myIP = this.state.myIP;
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
            const fieldCon = section.getFieldByKey('is_zg_de_2');
            if (fieldCon) {
                if(value){
                    fieldCon.value = this.getMeTimeStampNow();
                }else{
                    fieldCon.value = 0;
                }
            }

            const fieldIP = section.getFieldByKey(isCustomerAreaIpAddress);
            if (fieldIP) {fieldIP.value = myIP;}
        });
        this.setState(nextState);
    };

    setTypeChangeConsentsType2Rodo2 = ( key, value) => {
        const myIP = this.state.myIP;
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
            const fieldCon = section.getFieldByKey('is_zg_de_3');
            if (fieldCon) {
                if(value){
                    fieldCon.value = this.getMeTimeStampNow();
                }else{
                    fieldCon.value = 0;
                }
            }
            const fieldIP = section.getFieldByKey(isCustomerAreaIpAddress);
            if (fieldIP) {fieldIP.value = myIP;}
        });
        this.setState(nextState);
    };

    setTypeChangeConsentsType1Rodo1 = ( key, value) => {
        const myIP = this.state.myIP;
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }

            const fieldCon = section.getFieldByKey('is_zg_de_1');
            if (fieldCon) {
                if(value){
                    fieldCon.value = this.getMeTimeStampNow();
                }else{
                    fieldCon.value = 0;
                }
            }

            const fieldIP = section.getFieldByKey(isCustomerAreaIpAddress);
            if (fieldIP) {fieldIP.value = myIP;}
        });
        this.setState(nextState);
    };

    setTypeChangeMarketingEinwilligungenPost = ( key, value) => {
        const yesPost = 'marketing_einwilligungen_post_yes';
        const noPost = 'marketing_einwilligungen_post_no';
        const myIP = this.state.myIP;
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            let timestamp = this.getMeTimeStampNow();
            if (field) {
                field.value = value;
            }
            if(key === yesPost){
                if(value===true){
                    const field = section.getFieldByKey(noPost);
                    if (field) {
                        field.value = false;
                    }
                }else{
                    timestamp = 0;
                }
            }else{
                if(value===true){
                    const field = section.getFieldByKey(yesPost);
                    if (field) {
                        field.value = false;
                    }
                }
                timestamp = 0;
            }
            const fieldCon = section.getFieldByKey('is_zg_de_4');
            if (fieldCon) {
                fieldCon.value = timestamp;
            }

            const fieldIP = section.getFieldByKey(isCustomerAreaIpAddress);
            if (fieldIP) {fieldIP.value = myIP;}
        });
        this.setState(nextState);
    };

    setTypeChangeMarketingEinwilligungenTelephone = ( key, value) => {
        const yesPost = 'marketing_einwilligungen_telephone_yes';
        const noPost = 'marketing_einwilligungen_telephone_no';
        const myIP = this.state.myIP;
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            let timestamp = this.getMeTimeStampNow();
            if (field) {
                field.value = value;
            }
            if(key === yesPost){
                if(value===true){
                    const field = section.getFieldByKey(noPost);
                    if (field) {
                        field.value = false;
                    }
                }else{
                    timestamp = 0;
                }
            }else{
                if(value===true){
                    const field = section.getFieldByKey(yesPost);
                    if (field) {
                        field.value = false;
                    }
                }
                timestamp = 0;
            }
            const fieldCon = section.getFieldByKey('is_zg_de_5');
            if (fieldCon) {
                fieldCon.value = timestamp;
            }

            const fieldIP = section.getFieldByKey(isCustomerAreaIpAddress);
            if (fieldIP) {fieldIP.value = myIP;}

        });
        this.setState(nextState);
    };

    setTypeChangeAllMarketing = ( key, value) => {
        const myIP = this.state.myIP;
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {field.value = value;}
            if(value===true){

                const fieldTelephone = section.getFieldByKey('marketing_einwilligungen_telephone_yes');
                if (fieldTelephone) {fieldTelephone.value = value;}
                const fieldPost = section.getFieldByKey('marketing_einwilligungen_post_yes');
                if (fieldPost) {fieldPost.value = value;}
                const fieldDatenEmail = section.getFieldByKey('einwilligungen_die_daten_email_yes');
                if (fieldDatenEmail) {fieldDatenEmail.value = value;}
                const fieldDatenPhone = section.getFieldByKey('einwilligungen_die_daten_telephone_yes');
                if (fieldDatenPhone) {fieldDatenPhone.value = value;}
                const fieldDatenPost = section.getFieldByKey('einwilligungen_die_daten_post_yes');
                if (fieldDatenPost) {fieldDatenPost.value = value;}

                const fieldTelephoneNeg = section.getFieldByKey('marketing_einwilligungen_telephone_no');
                if (fieldTelephoneNeg) {fieldTelephoneNeg.value = !value;}
                const fieldPostNeg = section.getFieldByKey('marketing_einwilligungen_post_no');
                if (fieldPostNeg) {fieldPostNeg.value = !value;}
                const fieldDatenEmailNeg = section.getFieldByKey('einwilligungen_die_daten_email_no');
                if (fieldDatenEmailNeg) {fieldDatenEmailNeg.value = !value;}
                const fieldDatenPhoneNeg = section.getFieldByKey('einwilligungen_die_daten_telephone_no');
                if (fieldDatenPhoneNeg) {fieldDatenPhoneNeg.value = !value;}
                const fieldDatenPostNeg = section.getFieldByKey('einwilligungen_die_daten_post_no');
                if (fieldDatenPostNeg) {fieldDatenPostNeg.value = !value;}

                let timestamp = this.getMeTimeStampNow();
                const fieldCon4 = section.getFieldByKey('is_zg_de_4');
                if (fieldCon4) {fieldCon4.value = timestamp;}
                const fieldCon5 = section.getFieldByKey('is_zg_de_5');
                if (fieldCon5) {fieldCon5.value = timestamp;}
                const fieldCon6 = section.getFieldByKey('is_zg_de_6');
                if (fieldCon6) {fieldCon6.value = timestamp;}
                const fieldCon7 = section.getFieldByKey('is_zg_de_7');
                if (fieldCon7) {fieldCon7.value = timestamp;}
                const fieldCon8 = section.getFieldByKey('is_zg_de_8');
                if (fieldCon8) {fieldCon8.value = timestamp;}

            }else{
                const falseValue = false;
                const fieldTelephone = section.getFieldByKey('marketing_einwilligungen_telephone_yes');
                if (fieldTelephone) {fieldTelephone.value = falseValue;}
                const fieldPost = section.getFieldByKey('marketing_einwilligungen_post_yes');
                if (fieldPost) {fieldPost.value = falseValue;}
                const fieldDatenEmail = section.getFieldByKey('einwilligungen_die_daten_email_yes');
                if (fieldDatenEmail) {fieldDatenEmail.value = falseValue;}
                const fieldDatenPhone = section.getFieldByKey('einwilligungen_die_daten_telephone_yes');
                if (fieldDatenPhone) {fieldDatenPhone.value = falseValue;}
                const fieldDatenPost = section.getFieldByKey('einwilligungen_die_daten_post_yes');
                if (fieldDatenPost) {fieldDatenPost.value = falseValue;}
                const fieldTelephoneNeg = section.getFieldByKey('marketing_einwilligungen_telephone_no');
                if (fieldTelephoneNeg) {fieldTelephoneNeg.value = falseValue;}
                const fieldPostNeg = section.getFieldByKey('marketing_einwilligungen_post_no');
                if (fieldPostNeg) {fieldPostNeg.value = falseValue;}
                const fieldDatenEmailNeg = section.getFieldByKey('einwilligungen_die_daten_email_no');
                if (fieldDatenEmailNeg) {fieldDatenEmailNeg.value = falseValue;}
                const fieldDatenPhoneNeg = section.getFieldByKey('einwilligungen_die_daten_telephone_no');
                if (fieldDatenPhoneNeg) {fieldDatenPhoneNeg.value = falseValue;}
                const fieldDatenPostNeg = section.getFieldByKey('einwilligungen_die_daten_post_no');
                if (fieldDatenPostNeg) {fieldDatenPostNeg.value = falseValue;}

                let timestamp = 0;
                const fieldCon4 = section.getFieldByKey('is_zg_de_4');
                if (fieldCon4) {fieldCon4.value = timestamp;}
                const fieldCon5 = section.getFieldByKey('is_zg_de_5');
                if (fieldCon5) {fieldCon5.value = timestamp;}
                const fieldCon6 = section.getFieldByKey('is_zg_de_6');
                if (fieldCon6) {fieldCon6.value = timestamp;}
                const fieldCon7 = section.getFieldByKey('is_zg_de_7');
                if (fieldCon7) {fieldCon7.value = timestamp;}
                const fieldCon8 = section.getFieldByKey('is_zg_de_8');
                if (fieldCon8) {fieldCon8.value = timestamp;}
            }

            const fieldIP = section.getFieldByKey(isCustomerAreaIpAddress);
            if (fieldIP) {fieldIP.value = myIP;}
        });
        this.setState(nextState);
    };

    //SECONDS ROWS

    setTypeChangeEinwilligungenDieDatenEmail = ( key, value) => {
        const yesPost = 'einwilligungen_die_daten_email_yes';
        const noPost = 'einwilligungen_die_daten_email_no';
        const myIP = this.state.myIP;
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            let timestamp = this.getMeTimeStampNow();
            if (field) {
                field.value = value;
            }
            if(key === yesPost){
                if(value===true){
                    const field = section.getFieldByKey(noPost);
                    if (field) {
                        field.value = false;
                    }
                }else{
                    timestamp = 0;
                }
            }else{
                if(value===true){
                    const field = section.getFieldByKey(yesPost);
                    if (field) {
                        field.value = false;
                    }
                }
                timestamp = 0;
            }
            const fieldCon = section.getFieldByKey('is_zg_de_8');
            if (fieldCon) {
                fieldCon.value = timestamp;
            }

            const fieldIP = section.getFieldByKey(isCustomerAreaIpAddress);
            if (fieldIP) {fieldIP.value = myIP;}
        });
        this.setState(nextState);
    };

    setTypeChangeEinwilligungenDieDatenTelephone = ( key, value) => {
        const yesPost = 'einwilligungen_die_daten_telephone_yes';
        const noPost = 'einwilligungen_die_daten_telephone_no';
        const myIP = this.state.myIP;
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            let timestamp = this.getMeTimeStampNow();
            if (field) {
                field.value = value;
            }
            if(key === yesPost){
                if(value===true){
                    const field = section.getFieldByKey(noPost);
                    if (field) {
                        field.value = false;
                    }
                }else{
                    timestamp = 0;
                }
            }else{
                if(value===true){
                    const field = section.getFieldByKey(yesPost);
                    if (field) {
                        field.value = false;
                    }
                }
                timestamp = 0;
            }
            const fieldCon = section.getFieldByKey('is_zg_de_6');
            if (fieldCon) {
                fieldCon.value = timestamp;
            }

            const fieldIP = section.getFieldByKey(isCustomerAreaIpAddress);
            if (fieldIP) {fieldIP.value = myIP;}
        });
        this.setState(nextState);
    };

    setTypeChangeEinwilligungenDieDatenPost = ( key, value) => {
        const yesPost = 'einwilligungen_die_daten_post_yes';
        const noPost = 'einwilligungen_die_daten_post_no';
        const myIP = this.state.myIP;
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            let timestamp = this.getMeTimeStampNow();
            if (field) {
                field.value = value;
            }
            if(key === yesPost){
                if(value===true){
                    const field = section.getFieldByKey(noPost);
                    if (field) {
                        field.value = false;
                    }
                }else{
                    timestamp = 0;
                }
            }else{
                if(value===true){
                    const field = section.getFieldByKey(yesPost);
                    if (field) {
                        field.value = false;
                    }
                }
                timestamp = 0;
            }
            const fieldCon = section.getFieldByKey('is_zg_de_7');
            if (fieldCon) {
                fieldCon.value = timestamp;
            }

            const fieldIP = section.getFieldByKey(isCustomerAreaIpAddress);
            if (fieldIP) {
                fieldIP.value = myIP;
            }
        });
        this.setState(nextState);
    };

    render() {
        const {
          disabled,
          subsectionName,
          showValidationErrors,
          translateKey,
          rodoDisabled,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section, showLeft } = this.state;
        //RODO1
        const einwilligungZurUnterbeitung = section.getFieldByKey("einwilligung_zur_unterbeitung");
        //RODO2
        const einwilligungInDieVerarbeitung = section.getFieldByKey("einwilligung_in_die_verarbeitung");
        const dieWerwendentWerden = section.getFieldByKey("die_verwendent_werden");

        const marketingEinwilligungenPostYes = section.getFieldByKey("marketing_einwilligungen_post_yes");
        const marketingEinwilligungenPostNo = section.getFieldByKey("marketing_einwilligungen_post_no");

        const marketingEinwilligungenTelephoneYes = section.getFieldByKey("marketing_einwilligungen_telephone_yes");
        const marketingEinwilligungenTelephoneNo = section.getFieldByKey("marketing_einwilligungen_telephone_no");

        const einwilligungenDieDatenEmailYes = section.getFieldByKey("einwilligungen_die_daten_email_yes");
        const einwilligungenDieDatenEmailNo = section.getFieldByKey("einwilligungen_die_daten_email_no");

        const einwilligungenDieDatenTelephoneYes = section.getFieldByKey("einwilligungen_die_daten_telephone_yes");
        const einwilligungenDieDatenTelephoneNo = section.getFieldByKey("einwilligungen_die_daten_telephone_no");

        const einwilligungenDieDatenPostYes = section.getFieldByKey("einwilligungen_die_daten_post_yes");
        const einwilligungenDieDatenPostNo = section.getFieldByKey("einwilligungen_die_daten_post_no");

        const marketingZustimmungen = section.getFieldByKey("marketing_zustimmungen");

        const isPatientFirstName = section.getFieldByKey("is_patient_first_name");
        const isPatientLastName = section.getFieldByKey("is_patient_last_name");

        let fullNameDynamic = '';
        if(isPatientFirstName.value){
            fullNameDynamic+=isPatientFirstName.value;
            if(isPatientLastName.value){
                fullNameDynamic+= ' '+isPatientLastName.value;
            }
        }else{
            if(isPatientLastName.value){
                fullNameDynamic+= isPatientLastName.value;
            }
        }

        const disabledField = disabled || rodoDisabled;
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper container">
                    {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabledField}>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <div className="col-sm-12 rodo-description">
                                    {showLeft && 
                                        <React.Fragment>
                                            <div className="rodo-section">
                                                <div>
                                                    {translateKey('form-rodo2-text-paragraph1-part1')}
                                                    <a href={`mailto: ${translateKey('form-rodo1-text-email')}`} target="_top">{' ' + translateKey('form-rodo1-text-email')} </a>
                                                    {translateKey('form-rodo2-text-paragraph1-part2')}
                                                    <a href={`tel: ${translateKey('form-rodo1-text-phone')}`} target="_top">{' ' + translateKey('form-rodo1-text-phone')} </a>
                                                    {translateKey('form-rodo2-text-paragraph1-part3')}
                                                </div>
                                            </div>
                                            <div className="rodo-section">
                                                <div className="title">
                                                    {translateKey('form-rodo2-text-paragraph2').toUpperCase()}
                                                </div>
                                                <div className="row justify-content-md-center">
                                                    <CheckBoxComponent 
                                                        newUI={true} 
                                                        disabled={disabledField}
                                                        checkClassWrapper={'newUI-radio-check-box-class'} 
                                                        className="col-sm-12 col-lg-12" 
                                                        showValidation={showValidationErrors} 
                                                        setTypeChangeToParent={this.setTypeChangeConsentsType1Rodo1} 
                                                        value={einwilligungZurUnterbeitung.value?einwilligungZurUnterbeitung.value:false} 
                                                        validationErrors={einwilligungZurUnterbeitung.validationErrors} 
                                                        text={translateKey("form-rodo2-text-paragraph3-new-company")} 
                                                        transKey="einwilligung_zur_unterbeitung" 
                                                        keyName="einwilligung_zur_unterbeitung"
                                                        field={einwilligungZurUnterbeitung}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }

                                    {!showLeft && 
                                        <React.Fragment>
                                            <div className="rodo-section">
                                                <div>
                                                    {translateKey('form-rodo3-text-paragraph1-part1')}
                                                    <a href={`mailto: ${translateKey('form-rodo1-text-email')}`} target="_top">{' ' + translateKey('form-rodo1-text-email')} </a>
                                                    {translateKey('form-rodo3-text-paragraph1-part2')}
                                                    <a href={`tel: ${translateKey('form-rodo1-text-phone')}`} target="_top">{' ' + translateKey('form-rodo1-text-phone')} </a>
                                                    {translateKey('form-rodo3-text-paragraph1-part3')}
                                                </div>
                                            </div>

                                            <div className="rodo-section">
                                                <div className="title">
                                                    {translateKey('form-rodo3-text-paragraph2').toUpperCase()}
                                                </div>
                                                <div className="row justify-content-md-center">
                                                    <CheckBoxComponent 
                                                        newUI={true} 
                                                        disabled={disabledField}
                                                        checkClassWrapper={'newUI-radio-check-box-class'} 
                                                        className="col-sm-12 col-lg-12" 
                                                        showValidation={showValidationErrors} 
                                                        setTypeChangeToParent={this.setTypeChangeConsentsType2Rodo1} 
                                                        value={einwilligungInDieVerarbeitung.value?einwilligungInDieVerarbeitung.value:false} 
                                                        validationErrors={einwilligungInDieVerarbeitung.validationErrors} 
                                                        text={translateKey("form-rodo3-text-paragraph7-new-company")} 
                                                        transKey="einwilligung_in_die_verarbeitung" 
                                                        keyName="einwilligung_in_die_verarbeitung"
                                                        field={einwilligungInDieVerarbeitung}
                                                    />
                                                </div>
                                                <div className='row justify-content-md-center no-pointer-events'>
                                                    <div className="input-with-rodo-fullname-wrapper">
                                                        <input type="text" readOnly className='form-control newUI input-with-rodo-fullname' value={fullNameDynamic}/>
                                                    </div>
                                                </div>
                                                <div className="rodo-subsection-text-wrapper with-padd">
                                                    {translateKey('form-rodo3-text-paragraph4-new-company')}
                                                </div>
                                                <div className="rodo-subsection-text-wrapper">
                                                    {translateKey('form-rodo3-text-paragraph5')}
                                                </div>
                                            </div>

                                            <div className="rodo-section">
                                                <div className="title">
                                                    {translateKey('form-rodo3-text-paragraph6').toUpperCase()}
                                                </div>
                                                <div className="row justify-content-md-center">
                                                    <CheckBoxComponent 
                                                        newUI={true} 
                                                        disabled={disabledField}
                                                        checkClassWrapper={'newUI-radio-check-box-class'} 
                                                        className="col-sm-12 col-lg-12" 
                                                        showValidation={showValidationErrors} 
                                                        setTypeChangeToParent={this.setTypeChangeConsentsType2Rodo2} 
                                                        value={dieWerwendentWerden.value?dieWerwendentWerden.value:false} 
                                                        validationErrors={dieWerwendentWerden.validationErrors} 
                                                        text={translateKey("form-rodo3-text-paragraph7-new-company")} 
                                                        transKey="die_verwendent_werden" 
                                                        keyName="die_verwendent_werden"
                                                        field={dieWerwendentWerden}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }





                                    <div className="rodo-section">
                                        <div className="title">
                                            {translateKey('form-rodo2-text-marketing-einwilligungen').toUpperCase()}
                                        </div>
                                        <div className="rodo-options-wrapper-subtitle">
                                            {translateKey('form-rodo2-text-paragraph5')}
                                        </div>
                                        <div className="row rodo-options-wrapper">
                                            <div className="col-sm-12 col-lg-3">
                                                <span className="rodo-options-span-padding">{translateKey('form-rodo2-text-title-per-elektro-email')}</span><span className="red-star-required-field"> * </span>
                                            </div>
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-3" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeMarketingEinwilligungenPost} 
                                                value={marketingEinwilligungenPostYes.value?marketingEinwilligungenPostYes.value:false} 
                                                validationErrors={marketingEinwilligungenPostYes.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph5-option-yes")} 
                                                transKey="marketing_einwilligungen_post_yes" 
                                                keyName="marketing_einwilligungen_post_yes"
                                            />
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-3" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeMarketingEinwilligungenPost} 
                                                value={marketingEinwilligungenPostNo.value?marketingEinwilligungenPostNo.value:false} 
                                                validationErrors={marketingEinwilligungenPostNo.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph5-option-no")} 
                                                transKey="marketing_einwilligungen_post_no" 
                                                keyName="marketing_einwilligungen_post_no"
                                            />
                                        </div>
                                        <div className="row rodo-options-wrapper">
                                            <div className="col-sm-12 col-lg-3">
                                                <span className="rodo-options-span-padding">{translateKey('form-rodo2-text-title-per-phone')}</span><span className="red-star-required-field"> * </span>
                                            </div>
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-3" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeMarketingEinwilligungenTelephone} 
                                                value={marketingEinwilligungenTelephoneYes.value?marketingEinwilligungenTelephoneYes.value:false} 
                                                validationErrors={marketingEinwilligungenTelephoneYes.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph5-option-yes")} 
                                                transKey="marketing_einwilligungen_telephone_yes" 
                                                keyName="marketing_einwilligungen_telephone_yes"
                                            />
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-3" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeMarketingEinwilligungenTelephone} 
                                                value={marketingEinwilligungenTelephoneNo.value?marketingEinwilligungenTelephoneNo.value:false} 
                                                validationErrors={marketingEinwilligungenTelephoneNo.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph5-option-no")} 
                                                transKey="marketing_einwilligungen_telephone_no" 
                                                keyName="marketing_einwilligungen_telephone_no"
                                            />
                                        </div>
                                    </div>

                                    <div className="rodo-section">
                                        <div className="title">
                                            {translateKey('form-rodo2-text-einwilligung').toUpperCase()}
                                        </div>
                                        <div className="rodo-options-wrapper-subtitle">
                                            {translateKey('form-rodo2-text-paragraph7')}
                                        </div>
                                        <div className="row rodo-options-wrapper">
                                            <div className="col-sm-12 col-lg-3">
                                                <span className="rodo-options-span-padding">{translateKey('form-rodo2-text-title-per-email')}</span><span className="red-star-required-field"> * </span>
                                            </div>
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-3" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeEinwilligungenDieDatenEmail} 
                                                value={einwilligungenDieDatenEmailYes.value?einwilligungenDieDatenEmailYes.value:false} 
                                                validationErrors={einwilligungenDieDatenEmailYes.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph7-option-yes")} 
                                                transKey="einwilligungen_die_daten_email_yes" 
                                                keyName="einwilligungen_die_daten_email_yes"
                                            />
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-3" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeEinwilligungenDieDatenEmail} 
                                                value={einwilligungenDieDatenEmailNo.value?einwilligungenDieDatenEmailNo.value:false} 
                                                validationErrors={einwilligungenDieDatenEmailNo.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph7-option-no")} 
                                                transKey="einwilligungen_die_daten_email_no" 
                                                keyName="einwilligungen_die_daten_email_no"
                                            />
                                        </div>
                                        <div className="row rodo-options-wrapper">
                                            <div className="col-sm-12 col-lg-3">
                                                <span className="rodo-options-span-padding">{translateKey('form-rodo2-text-title-per-phone')}</span><span className="red-star-required-field"> * </span>
                                            </div>
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-3" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeEinwilligungenDieDatenTelephone} 
                                                value={einwilligungenDieDatenTelephoneYes.value?einwilligungenDieDatenTelephoneYes.value:false} 
                                                validationErrors={einwilligungenDieDatenTelephoneYes.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph7-option-yes")} 
                                                transKey="einwilligungen_die_daten_telephone_yes" 
                                                keyName="einwilligungen_die_daten_telephone_yes"
                                            />
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-3" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeEinwilligungenDieDatenTelephone} 
                                                value={einwilligungenDieDatenTelephoneNo.value?einwilligungenDieDatenTelephoneNo.value:false} 
                                                validationErrors={einwilligungenDieDatenTelephoneNo.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph7-option-no")} 
                                                transKey="einwilligungen_die_daten_telephone_no" 
                                                keyName="einwilligungen_die_daten_telephone_no"
                                            />
                                        </div>
                                        <div className="row rodo-options-wrapper">
                                            <div className="col-sm-12 col-lg-3">
                                                <span className="rodo-options-span-padding">{translateKey('form-rodo2-text-title-per-post')}</span><span className="red-star-required-field"> * </span>
                                            </div>
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-3" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeEinwilligungenDieDatenPost} 
                                                value={einwilligungenDieDatenPostYes.value?einwilligungenDieDatenPostYes.value:false} 
                                                validationErrors={einwilligungenDieDatenPostYes.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph7-option-yes")} 
                                                transKey="einwilligungen_die_daten_post_yes" 
                                                keyName="einwilligungen_die_daten_post_yes"
                                            />
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-3" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeEinwilligungenDieDatenPost} 
                                                value={einwilligungenDieDatenPostNo.value?einwilligungenDieDatenPostNo.value:false} 
                                                validationErrors={einwilligungenDieDatenPostNo.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph7-option-no")} 
                                                transKey="einwilligungen_die_daten_post_no" 
                                                keyName="einwilligungen_die_daten_post_no"
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="rodo-section">
                                        <div className="row">
                                            <CheckBoxComponent 
                                                newUI={true} 
                                                disabled={disabledField}
                                                checkClassWrapper={'newUI-radio-check-box-class'} 
                                                className="col-sm-12 col-lg-12" 
                                                showValidation={showValidationErrors} 
                                                setTypeChangeToParent={this.setTypeChangeAllMarketing} 
                                                value={marketingZustimmungen.value?marketingZustimmungen.value:false} 
                                                validationErrors={marketingZustimmungen.validationErrors} 
                                                text={translateKey("form-rodo2-text-paragraph8")} 
                                                transKey="form-rodo2-text-paragraph8" 
                                                keyName="marketing_zustimmungen"
                                                field={marketingZustimmungen}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={false}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(RodoSection2));