export const RejectionReasonOrder = [
    "717700013",
    "717700018",
    "717700011",
    "717700012",
    "717700008",
    "717700020",
    "717700003",
    "717700009",
    "717700000",
    "717700002",
    "717700015",
    "717700016",
    "717700021",
    "717700005",
    "717700006",
    "717700004",
    "717700001",
    "717700007"
]