import React from 'react';
import {connect} from 'react-redux';

import {translateKey} from "../../_redux/actions";

const ValidationErrorsList = (props) => {
    const {errors, keyName, translateKey, args} = props;
    return (
        <div className="invalid-feedback" id={`${keyName}_error_id`}>
            {errors.map(error => {
                let message = translateKey(`form-text-${error}`);
                if(args !== undefined) {
                    message = message.replace(/{([^{}]+)}/g, function(keyExpr, key) {
                        return args[key] || "";
                    });
                }
                return (<div key={`${keyName}-error-${error}`}>{message}</div>)
            })}
        </div>
    );
};

ValidationErrorsList.defaultProps = {
    errors: [],
    keyName: '',
    args: {}
};

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))});
export default connect(null, mapDispatchToProps)(ValidationErrorsList);
