import React from 'react';
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";
import { Asterisk } from "../../../_helpers/field_asterix";

export default class SelectComponent extends React.Component {
    static defaultProps = {
        valuesList: [],
        validationErrors: [],
        optionValueKey: 'value',
        optionLabelKey: 'label',
        className: '',
    };

    setTypeChange = event => {
        const { target: { value } } = event;
        const { keyName, setTypeChangeToParent } = this.props;
        setTypeChangeToParent(keyName, value);
    };

    render() {
        const { valuesList, keyName, value, validationErrors, showValidation, className, label, optionValueKey, optionLabelKey, newUI, chooseText, isRequired} = this.props;
        const showErrors = showValidation && validationErrors.length > 0;

        return (
            <div className={`${className} select-component-wrapper ${showErrors ? 'is-invalid' : ''}`}>
                <label className={`form-label-basic ${newUI? 'newUI':''}`}><span className="quick-label-position-fix">{label.toUpperCase()}</span> <Asterisk isRequired={isRequired === true}/></label>
                <select className={`form-control ${newUI? 'newUI':''} ${showErrors ? 'is-invalid' : ''}`} data-field-name={keyName} onChange={this.setTypeChange} value={value}>
                    <option hidden>{chooseText?chooseText:''}</option>
                    { valuesList.map(possibleValue => {
                        const id = `${keyName}+${possibleValue[optionValueKey]}`;

                        return (
                            <option key={id} value={possibleValue[optionValueKey]}>{possibleValue[optionLabelKey]}</option>
                        );
                    })}
                </select>
                { showErrors && (
                    <ValidationErrorsList keyName={keyName} errors={validationErrors} />
                )}
            </div>
        );
    }
}
