import * as FieldTypes from "../fieldTypes";
import Form from "../Form";
import FormSection from "../FormSection";
import FormField from "../FormField";
import * as CareBoxDeliveryAddress from "./values/careBoxDeliveryAddress";
import { duplicatedIngredients } from "./values/careBoxIngredientsDuplicated";
import * as CareBoxTypes from "./values/careBoxTypes";
import * as Entity from "./values/entity";
import * as CareBoxInsuranceType from "./values/careBoxInsuranceType";
import * as CareBoxStatus from "../BBForm/values/careBoxStatus";
import { skipList } from "./values/formKeySkipList";
import * as ReasonForDisinterest from "./values/reasonForDisinterest";

const LAST_SECTION_KEY = 'is_last_section';

const skipListPatient = [
  "is_patient_last_name",
  "is_patient_first_name",
  "is_service_address_street",
  "is_service_address_street_number",
  "is_service_address_house_number",
  "is_service_address_postal_code",
  "is_service_address_city",
  "is_patient_cellphone",
  "is_patient_phone"
];
const flattenFields = fieldsList =>
  fieldsList.reduce(
    (previous, field) =>
      Object.assign(previous, {
        [field.key]: field.value
      }),
    {}
  );
const flattenFieldsArraywithSkiped = fieldsList =>
  fieldsList
    .filter(element => {
      if (skipList.indexOf(element.key) !== -1) {
        return false;
      }
      return true;
    })
    .map(field => field.key)
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    })
   
const flattenFieldsArraySkipedBoth = fieldsList =>
  fieldsList
    .filter(element => {
      if (skipListPatient.indexOf(element.key) !== -1 || skipList.indexOf(element.key) !== -1) {
        return false;
      }
      return true;
    })
    .map(field => field.key)
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    })

const birthdayField = new FormField({
  defaultValue: "",
  key: "is_birthday",
  entity: Entity.PATIENT,
  isRequired: true
});

export default class CareBoxForm extends Form {
  constructor() {
    super({
      sections: [
        //CARE BOX
        new FormSection({
          key: "section-care-box",
          sectionName: "form-text-care-box",
          number: 1,
          isSpecialOffer: false,
          fields: [
            new FormField({
              key: "is_lead_status_care_box",
              entity: Entity.CONTACT,
              isFalseable: true,
              defaultValue: '717700003'
            }),
            new FormField({
              key: "is_service_type_de",
              entity: Entity.CONTACT,
              defaultValue: "717700000"
            }),
            new FormField({
              key: "is_reason_for_rejection_care_box",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700002') {
                  return true;
                }
                return false;
              },
              validationRules: {
                possibleValues: ReasonForDisinterest.LIST
              },
              valueMapper: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700003') {
                  return null;
                }
              }
            }),
            new FormField({
              key: "is_private_client",
              entity: Entity.CONTACT,
              defaultValue: '717700000',
            }),
            new FormField({
              key: "is_health_insurance",
              entity: Entity.CARE_BOX,
              defaultValue: '',
              isRequired: true,
            }),
            new FormField({
              key: "is_insurance_number",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const field = form.getFieldsByEntityAndKey(Entity.CARE_BOX, 'is_private_client');
                const value = field !== undefined ? field.value : CareBoxInsuranceType.IS_PRIVATE;
                const isPrivate = value === CareBoxInsuranceType.IS_PRIVATE;
                return isPrivate === false;
              }
            }),
            new FormField({
              key: "is_outpatient_assistance_name",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700003' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_street_pa",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700003' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_house_number_pa",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700003' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_apartement_number_pa",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_pa",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_pa",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700003' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_delivery_to_address",
              entity: Entity.CARE_BOX,
              validationRules: {
                possibleValues: CareBoxDeliveryAddress.LIST
              },
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700003') {
                  return true;
                }
                return false;
              },
            }),
            new FormField({
              key: "is_last_name_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_first_name_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_street_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_house_number_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_apartment_number_d",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_d",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_care_box_setid",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              validationRules: {
                possibleValues: CareBoxTypes.LIST
              }
            }),
            new FormField({
              key: "is_bed_protectors",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_o",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_bed_protectors"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_one_time_gloves",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_r",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_one_time_gloves"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_one_time_gloves_type",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: '717700000',
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_one_time_gloves"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_one_time_gloves_size",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: '717700001',
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_one_time_gloves"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_hand_sanitizer",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dr",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_hand_sanitizer"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_surface_disinfectant",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dp",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_surface_disinfectant"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_masks",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_m",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_masks"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_masks_ffp2",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_m_ffp",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_masks_ffp2"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_masks_ffp2_set",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_ffp_set",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_masks_ffp2_set");
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_protective_aprons",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_f",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_protective_aprons"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_reusable_bed_protectors",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_protectors",
              entity: Entity.CARE_BOX,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_reusable_bed_protectors"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_finger_overlay",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_njp",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_finger_overlay"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_surface_disinfectant_1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dp1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_surface_disinfectant_1000"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_hand_sanitizer_1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dr1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_hand_sanitizer_1000"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_reusable_protective_aprons",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_wfo",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_reusable_protective_aprons"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disinfecting_wipes",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_ch",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disinfecting_wipes"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            //automatic filed fields
            new FormField({
              key: "is_contactid",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_patientid",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_status",
              entity: Entity.CARE_BOX,
              value: CareBoxStatus.APP_NEW
            }),
            new FormField({
              key: "is_name",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_name_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_surname_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_street_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_house_number_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_apartment_number_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_home_phone_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_cell_phone_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_email_address_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_birth_date",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_pflegegrad_duplicated",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_name_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_surname_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_street_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_house_number_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_apartment_number_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_home_phone_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_cell_phone_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_email_address_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "ownerid",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_contactid_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_name_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_care_box_appid",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_month",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_year",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_status_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              value: '717700000'
            }),
            new FormField({
              key: "ownerid_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
          ]
        }),

        // Hidden section (for fields that have constant values)
        new FormSection({
          key: "section-hidden",
          fields: [
            new FormField({
              key: "is_first_name_and_last_name_of_patient",
              entity: Entity.PATIENT,
            }),
            new FormField({
              key: "is_contact_source",
              entity: Entity.CONTACT,
              defaultValue: "717700001"
            }),
            new FormField({
              key: "is_last_section",
              entity: Entity.PATIENT,
              defaultValue: "section-care-box-rodo"
            })
          ]
        })
      ]
    });
  }

  get payload() {
    const patientFields = this.getFieldsByEntity(Entity.PATIENT);
    const contactFields = this.getFieldsByEntity(Entity.CONTACT);
    const careBoxFields = this.getFieldsByEntity(Entity.CARE_BOX);
    const careBoxIngredientsFields = this.getFieldsByEntity(Entity.CARE_BOX_INGRIDIENTS);
    
    return {
      patient: flattenFields(patientFields),
      contact: flattenFields(contactFields),
      ccareBox: flattenFields(careBoxFields),
      careBoxIngredients: flattenFields(careBoxIngredientsFields),
    };
  }

  getRetiveDataCareBox(id) {
    let retrieveData = [];
    const careBoxFields = this.getFieldsByEntity(Entity.CARE_BOX);
    let flattenFields = flattenFieldsArraywithSkiped(careBoxFields);
    flattenFields = flattenFields.map(item => {
      if (item === 'is_pflegegrad_duplicated' ) {
        return 'is_pflegegrad';
      } else {
        return item;
      }
    });
    retrieveData.push({
      id: id,
      entity: Entity.CARE_BOX,
      fields: flattenFields
    });
    return retrieveData;
  }

  getRetiveDataCareBoxIng(id) {
    let retrieveData = [];
    const careBoxIngFields = this.getFieldsByEntity(Entity.CARE_BOX_INGRIDIENTS);
    let flattenFields = flattenFieldsArraywithSkiped(careBoxIngFields);
    flattenFields = flattenFields.map(item => {
      if (duplicatedIngredients.includes(item)) {
        return item.replace('_duplicated', '');
      } else {
        return item;
      }
    });
    retrieveData.push({
      id: id,
      entity: Entity.CARE_BOX_INGRIDIENTS,
      fields: flattenFields
    });
    return retrieveData;
  }

  getRetiveDataALl(lkId, patietList) {
    let retrieveData = [];
    let patietListWithOutZero = patietList.filter(element => element !== 0);
    if (patietList.length !== "0") {
      const patientFields = this.getFieldsByEntity(Entity.PATIENT);
      const flattenFields = flattenFieldsArraywithSkiped(patientFields);
      patietListWithOutZero.forEach(element => {
        retrieveData.push({
          id: element,
          entity: Entity.PATIENT,
          fields: flattenFields
        });
      });
    }
    if (lkId !== "0") {
      const contactFields = this.getFieldsByEntity(Entity.CONTACT);
      if (patietList.length !== "0") {
        if (patietListWithOutZero.length > 0) {
          retrieveData.push({
            id: lkId,
            entity: Entity.CONTACT,
            fields: flattenFieldsArraywithSkiped(contactFields)
          });
        } else {
          retrieveData.push({
            id: lkId,
            entity: Entity.CONTACT,
            fields: flattenFieldsArraySkipedBoth(contactFields)
          });
        }
      } else {
        retrieveData.push({
          id: lkId,
          entity: Entity.CONTACT,
          fields: flattenFieldsArraySkipedBoth(contactFields)
        });
      }
    }
    return retrieveData;
  }

  getPayloadForSection(sectionKey, lkId, patientId, careBoxId) {
    const patientFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.PATIENT,
      true
    );
    const contactFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CONTACT,
      true
    );
    let careBoxFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CARE_BOX,
      true
    );

    let patientDataToSave = flattenFields(patientFields);
    if (patientId === "0") {
      if (Object.keys(patientDataToSave).length > 0) {
        patientDataToSave["is_contact_id"] = lkId;
      }
    }
    let returnArray = [];
    if (patientId === "0" || patientId === 0) {
      patientDataToSave["is_contact_id"] = lkId;
    }
    let field = this.getFieldByKey(LAST_SECTION_KEY);
    if(field){
      patientDataToSave[LAST_SECTION_KEY] = field.value
    }
    
    returnArray.push({
      Id: patientId,
      Entity: Entity.PATIENT,
      Fields: patientDataToSave
    });
    let contactFieldsToSave = flattenFields(contactFields);
    if(sectionKey === 'section-care-box') {
      const valueCareBox = this.getFieldByKey("is_lead_status_care_box").value;
      if(valueCareBox == '717700002') {
        delete contactFieldsToSave.is_private_client;
      }
    }
    if (Object.keys(contactFieldsToSave).length > 0) {
      returnArray.push({
        Id: lkId,
        Entity: Entity.CONTACT,
        Fields: contactFieldsToSave
      });
    }

    if (Object.keys(careBoxFields).length > 0) {
      careBoxFields = careBoxFields.map(item => {
        if(item.key.endsWith('_duplicated')) {
            item.key = item.key.replace('_duplicated', '');
        }
        return item;
      });
      let careBoxFieldsToSave = flattenFields(careBoxFields);
      returnArray.push({
        Id: careBoxId,
        Entity: Entity.CARE_BOX,
        Fields: careBoxFieldsToSave
      });
    }
    return returnArray;
  }

  getCareBox(sectionKey, careBoxId) {
    let careBoxFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CARE_BOX,
      true
    );
    
    let returnArray = [];
    
    if (Object.keys(careBoxFields).length > 0) {
      careBoxFields = careBoxFields.map(item => {
        if(item.key.endsWith('_duplicated')) {
            item.key = item.key.replace('_duplicated', '');
        }
        return item;
      });
      let careBoxFieldsToSave = flattenFields(careBoxFields);
      returnArray.push({
        Id: careBoxId,
        Entity: Entity.CARE_BOX,
        Fields: careBoxFieldsToSave
      });
    }

    return returnArray;
  }

  getCareBoxIngredients(sectionKey, ingredientsId, idToSave) {
    let careBoxIngredientsFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CARE_BOX_INGRIDIENTS,
      false
    );
    if (ingredientsId) {
      let careBoxIngredientsIDFields = this.getFieldByKey('is_care_box_appid');
      careBoxIngredientsIDFields.value = ingredientsId;
      careBoxIngredientsFields["is_care_box_appid"] = careBoxIngredientsIDFields;
    }

    let returnArray = [];
    if (Object.keys(careBoxIngredientsFields).length > 0) {
      careBoxIngredientsFields = careBoxIngredientsFields.map(item => {
        if(item.key.endsWith('_duplicated')) {
            item.key = item.key.replace('_duplicated', '');
        }
        return item;
      });
      let careBoxIngredientsFieldsToSave = flattenFields(careBoxIngredientsFields);
      returnArray.push({
        Id: idToSave,
        Entity: Entity.CARE_BOX_INGRIDIENTS,
        Fields: careBoxIngredientsFieldsToSave
      });
    }

    return returnArray;
  }

  isEditable(status, hasDraft) {
    return false;
  }

  isDisabled(status, hasDraft) {
    return true;
  }
}
