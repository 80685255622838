import { SubCategoryList } from "../_forms/KoPilotenForm/values/subCategoryList";
import { CategoriesKeyList } from "../_forms/KoPilotenForm/values/categoriesKeyList";
import { KOPILOTENSUBTOCATCONNECTION } from "../_constance/enums/koPilotenSubcategoryList";

class KoPilotenCategoryHelper{
    static getMeSubcategoriesBasedOnLabel(label){
        const stripLabel = label.replace(/\s/g, "");
        let list = [];
        switch (stripLabel) {
            case "Środkipomocnicze":    list = KOPILOTENSUBTOCATCONNECTION.SRODKIPOMOCNICZE;break;
            case "Inne":                list = KOPILOTENSUBTOCATCONNECTION.INNE;break;
            case "Terapeuci":           list = KOPILOTENSUBTOCATCONNECTION.TERAPEUCI;break;
            default:
        }
        return list;
    }

    static getMeNameBasedOnLabel(label) {
        const stripLabel = label.replace(/\s/g, "");
        let found = CategoriesKeyList.find(
          (element) => element.label.replace(/\s/g, "") === stripLabel
        );
        if (found) {
          return found.key;
        }
        return label;
      }
    
    static isSubCategory(label) {
        const stripLabel = label.replace(/\s/g, "");
        let found = SubCategoryList.find(
          (element) => element.label.replace(/\s/g, "") === stripLabel
        );
        if (found) {
          return true;
        }
        return false;
    }
}

export default KoPilotenCategoryHelper;