import { UPDATE_ACTION_TIMESTAMP, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_FAIL, AUTH_START, REFRESHING_TOKEN, REFRESHED_TOKEN, CLEAR_ALL_DATABASE, UPDATE_LOGIN_DS } from "./action-types"
import { baseData, refreshTokenTime } from '../../_constance/base_data';
import ReqHelper from '../../_helpers/request_helper';
import axios from '../../_services/config/axios-no-auth';
import axiosAuth from '../../_services/config/axios-auth';
import { sendTaskInfoEmail } from '../../_services/backend_service';

export const authCheckState = () => {
  return dispatch => {
    const currentTime = new Date().getTime();
    const token = localStorage.getItem('ap:appToken');
    const tokenTime = localStorage.getItem('ap:appTokenTime');
    const rsaToken = localStorage.getItem('ap:rsaToken');
    const actionTime = localStorage.getItem('ap:appActionTime');
    const appLogin = localStorage.getItem('ap:appLogin');
    const userId = localStorage.getItem('ap:userId');
    const CooperationId = localStorage.getItem('ap:CooperationId');
    const Title = localStorage.getItem('ap:Title');
    const ContactFpFirstEmail = localStorage.getItem('ap:ContactFpFirstEmail');
    const ContactFpName = localStorage.getItem('ap:ContactFpName');
    const LoginDS = localStorage.getItem('ap:LoginDS');
    const FPphone = localStorage.getItem('ap:FPphone');
    if (
      (
      token && 
      rsaToken && 
      appLogin && 
      tokenTime && 
      userId && 
      CooperationId && 
      Title && 
      ContactFpFirstEmail &&
      ContactFpName &&
      LoginDS &&
      FPphone
      ) 
      && 
      (actionTime >= currentTime)) {
      dispatch(authSuccess(appLogin, token, rsaToken, tokenTime, userId, CooperationId, Title, ContactFpFirstEmail, ContactFpName, LoginDS, FPphone))
      dispatch(authSetActionTimestamp());
    } 
    else if (actionTime < currentTime)  {
      onLogout();
    } else {
      onLogout();
    }
  }
}

export const auth = (login, rsaPassword) => {
  return dispatch => {
    dispatch(authStart());
    return axios.post('login', null, {
      headers: {
        'X-Auth-Login': login,
        'X-Auth-Password': rsaPassword,
        'X-Device-Id': "ce4e1ce8cb2c9a9f",
        'X-Device-Os': "WEB",
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 200) {
          if(res.data.Title === "FP" || res.data.Title === "BB" || res.data.Title === "CC_DE"){
            const currentTime = new Date().getTime();
            let tokenTime = currentTime + refreshTokenTime.expirationTime;
            dispatch(authSuccess(login, res.data.Token, rsaPassword, tokenTime, res.data.UserId, res.data.CooperationId, res.data.Title, res.data.ContactFpFirstEmail, res.data.ContactFpName, res.data.LoginDS, res.data.ContactFpTelephone))
            dispatch(authSetActionTimestamp());
            return Promise.resolve();
          }else{
            dispatch(authFail("Not FP"))
            let error = {
              code: {
                response:{
                  status: 808
                }
              }
            }
            return Promise.reject(error);
          }
        } else {
          dispatch(authFail("Not 200"))
          return Promise.reject(res.status);
        }
      })
      .catch(error => {
        if(error.code.response){
          dispatch(authFail(ReqHelper.responseErrorHelper(error.code.response.status)))
          return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
        }else{
          dispatch(authFail(ReqHelper.responseErrorHelper(999)))
          return Promise.reject(ReqHelper.responseErrorHelper(999));
        }
      });
  }
}

export const leadSave = function (data, lang) {
  return () => {
    let preparedData = {
      "leadId" : data.leadId,
      "dateOfEntry" : data.dateOfEntry,
      "status" : data.status === ''? null: data.status,
      "source" : data.source,
      "sourceFp" : data.sourceFp,
      "sourceCountry" : lang==="DE"? 717700000:717700001,
      "noteFp":null,
      "client" : data.client,
      "patient" : data.patientObj ? data.patientObj : data.patient,
      "payer" : data.payer,
      "ViewedInApplication": data.ViewedInApplication,
      "rating": data.rating,
      "reasonForRejection": data.reasonForRejection,
      "reasonsForRejectionDetails": data.reasonsForRejectionDetails,
      "dateFirstInterview": data.dateFirstInterview,
      "leadExclusiveForPm24": data.leadExclusiveForPm24,
      "referralContactId": data.referralContactId,
    }
      return axiosAuth.post('leads/save', preparedData)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          } else if (typeof error === 'number'){
            return Promise.reject(ReqHelper.responseErrorHelper(error));
          } else {
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const savePatientData = function (data) {
  return () => {
      return axiosAuth.post('patient/save', data)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const multiplierSave = function (data) {
  return () => {
      return axiosAuth.post('/multipliers/save', data)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const taskSaveTaskData = function (data) {
  return () => {
      let apiCallUrl;
      let TaskData;
      switch(data.taskType.toLowerCase()){
        case "event": apiCallUrl = '/activity/event/save';
                      TaskData = {
                        StartTime: data.StartTime,
                        FinishTime: data.FinishTime,
                        Id: data.id,
                        Title: data.title,
                        Concerns: data.concerns,
                        Description: data.description,
                        DueDay: data.dueDay.toString().length<=10?data.dueDay:Math.floor(data.dueDay / 1000),
                        Priority: data.priority,
                        Owner: data.owner,
                        Status: data.status,
                        Type: data.taskType,
                        Subject: data.subject,
                        ViewedInApplication: false,
                        ConcernType: data.concernType,
                        ConcernName: data.concernName,
                        CreatedDate: data.createdDate
                      }
                      break;
        case "call":  apiCallUrl = '/activity/call/save';
                      const dueDay = data.dueDay.toString().length<=10?data.dueDay:Math.floor(data.dueDay / 1000);
                      TaskData = {
                        Direction: data.direction,
                        StartTime: data.StartTime,
                        FinishTime: data.FinishTime,
                        Id: data.id,
                        Title: data.title,
                        Concerns: data.concerns,
                        Description: data.description,
                        // DueDay: data.dueDay.toString().length<=10?data.dueDay:Math.floor(data.dueDay / 1000),
                        DueDay: dueDay===0?data.StartTime:dueDay,
                        Priority: data.priority,
                        Owner: data.owner,
                        Status: data.status,
                        Type: data.taskType,
                        Subject: data.subject,
                        ViewedInApplication: false,
                        ConcernType: data.concernType,
                        ConcernName: data.concernName,
                        CreatedDate: data.createdDate
                      }
                      break;
        default:      apiCallUrl = '/activity/task/save';
                      TaskData = {
                        Id: data.id,
                        Title: data.title,
                        Concerns: data.concerns,
                        Description: data.description,
                        DueDay: data.dueDay.toString().length<=10?data.dueDay:Math.floor(data.dueDay / 1000),
                        Priority: data.priority,
                        Owner: data.owner,
                        Status: data.status,
                        Type: data.taskType,
                        Subject: data.subject,
                        ViewedInApplication: false,
                        ConcernType: data.concernType,
                        ConcernName: data.concernName,
                        CreatedDate: data.createdDate,
                        FpComments: data.FpComments ? data.FpComments : null,
                        GroupCauseFp: data.GroupCauseFp ? data.GroupCauseFp : null,
                        GroupCauseFpString: data.GroupCauseFpString ? data.GroupCauseFpString : null,
                        ReasonForTerminationDetails: data.ReasonForTerminationDetails ? data.ReasonForTerminationDetails : null,
                        SubjectId: data.SubjectId
                      }
      }
      return axiosAuth.post(apiCallUrl, TaskData)
      .then(() => {
        if (TaskData.SubjectId === baseData.taskComplaintId || TaskData.SubjectId === baseData.taskTerminationId) {
          return sendTaskInfoEmail(TaskData);
        };
        return true
      })
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const markTaskAsCompleted = function (data) {
  return () => {
      let apiCallUrl;
      switch(data.taskType.toLowerCase()){
        case "event": apiCallUrl = '/activity/tasks/markAsCompleted/'+ data.id + '/4201';break;
        case "call": apiCallUrl = '/activity/tasks/markAsCompleted/'+ data.id + '/4210';break;
        default: apiCallUrl = '/activity/tasks/markAsCompleted/'+ data.id + '/4212';
      }
      return axiosAuth.get(apiCallUrl)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const markTaskAsCancelled = function (data) {
  // TODO maybe update URL
  return () => {
      let apiCallUrl;
      switch(data.taskType.toLowerCase()){
        case "event": apiCallUrl = `/activity/tasks/markAsCanceled/${data.id}/4201`;break;
        case "call": apiCallUrl = `/activity/tasks/markAsCanceled/${data.id}/4210`;break;
        default: apiCallUrl = `/activity/tasks/markAsCanceled/${data.id}/4212`;
      }
      return axiosAuth.get(apiCallUrl)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const acceptInvoice = function (id) {
  return () => {
      return axiosAuth.get(`invoices/accept/${id}`)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const rejectInvoice = function (data) {
  return () => {
    return axiosAuth.post('/invoices/addComment', data)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const addNoteData = function (element, userid, type) {
  return () => {
      let data = {
        "ownerId" : userid,
        "ownerType" : type,
        "note" : element.Note
      }
      return axiosAuth.post('/notes/add', data)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const removeNoteData = function (id) {
  return () => {
      return axiosAuth.get(`/notes/delete/${id}`)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const getCarerPhoto = function (contactId, photoVersion=0) {
  return () => {
      if(photoVersion===''){
        photoVersion = 0;
      }
      return axiosAuth.get(`/carer/photo/${contactId}/${photoVersion}`)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const onLogout = () => {
  return dispatch => {
    dispatch({type: CLEAR_ALL_DATABASE});
    dispatch(logout());
  }
}

export const refreshToken = () => {
  return dispatch => {
      dispatch(authRefreshToken());
  }
}

export const onRefreshToken = (token) => {
  return dispatch => {
    dispatch(authUpdatedToken(token));
  };
}

export const logout = () => {
  localStorage.removeItem("ap:appToken")
  localStorage.removeItem("ap:appLogin")
  localStorage.removeItem("ap:rsaToken")
  localStorage.removeItem("ap:appTokenTime");
  localStorage.removeItem("ap:appActionTime");
  localStorage.removeItem("ap:userId");
  localStorage.removeItem("ap:CooperationId");
  localStorage.removeItem("ap:Title");
  localStorage.removeItem("ap:ContactFpFirstEmail");
  localStorage.removeItem("ap:ContactFpName");
  localStorage.removeItem("ap:ContactFpSecondEmail");
  localStorage.removeItem("ap:LoginDS");
  localStorage.removeItem("ap:FPphone");
  return {
    type: AUTH_LOGOUT
  };
};

export const authStart = () => {
  return {
    type: AUTH_START
  };
};

export const authUpdatedToken = (token) => {
  localStorage.setItem("ap:appToken", token)
  return {
    type: REFRESHED_TOKEN,
    appToken: token
  };
};

export const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error: error
  };
}

export const authUpdatedLoginDS = (loginDS) => {
  return {
    type: UPDATE_LOGIN_DS,
    LoginDS: loginDS
  };
};

export const authSuccess = (login, token, rsaToken, tokenTime, userId, CooperationId, title, cFPFirstEmail, cFPName, LoginDS, FPphone) => {
  localStorage.setItem('ap:appLogin', login);
  localStorage.setItem('ap:appToken', token);
  localStorage.setItem('ap:rsaToken', rsaToken);
  localStorage.setItem('ap:appTokenTime', tokenTime);
  localStorage.setItem('ap:userId', userId);
  localStorage.setItem('ap:CooperationId', CooperationId);
  localStorage.setItem('ap:Title', title);
  localStorage.setItem('ap:ContactFpFirstEmail', cFPFirstEmail);
  localStorage.setItem('ap:ContactFpName', cFPName);
  localStorage.setItem('ap:LoginDS', LoginDS);
  if (FPphone !== null) {
    localStorage.setItem('ap:FPphone', FPphone);
  }
  return {
    type: AUTH_SUCCESS,
    login: login,
    appToken: token,
    rsaToken: rsaToken,
    appTokenTime: tokenTime,
    userId: userId,
    cooperationId: CooperationId,
    title: title,
    contactFpFirstEmail: cFPFirstEmail,
    contactFpName: cFPName,
    LoginDS: LoginDS,
    FPphone: FPphone,
  }
}

export const authSetActionTimestamp = () => {
  const currentTime = new Date().getTime();
  let actionTime = currentTime + refreshTokenTime.actionExpirationTime;
  localStorage.setItem("ap:appActionTime", actionTime);
  return {
    type: UPDATE_ACTION_TIMESTAMP,
    actionTimestamp: actionTime
  };
}

export const authRefreshToken = () => {
  return {
    type: REFRESHING_TOKEN
  };
}
