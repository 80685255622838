export const ConfigContractAndOffer = {
    ALLOWED_EMPTY_FIELDS:[
        "is_date_from_1",
        "is_date_to_1",
        "is_date_from_2",
        "is_date_to_2",
        "is_date_from_3",
        "is_date_to_3",
        "is_date_from_4",
        "is_date_to_4",
        "is_date_from_5",
        "is_date_to_5",
        "is_date_from_6",
        "is_date_to_6"
    ],
    FIELDS_TO_CHANGE_IF_NULL:[
        "is_invoice_delivery_method_letter", 
        "is_invoice_delivery_method_mail"
    ],
    SKIPPED_HELP_FIELDS:[
        'skip_patient_full_name',
        'skip_region_franchise',
        'skip_pcm_name_contract',
        'skip_pcm_email_contract',
        'skip_is_city_docusign',
        'skip_is_dlv_docusign',
        'signature_method_docusign',
        'suchanfrage_signature',
        'is_customer_status_de',
        'skip_date_valid',
    ]
}