import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { Asterisk } from "../../../_helpers/field_asterix";
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";

class ListComponent extends React.Component {
    setTypeChange = event => {
        const { target: { value } } = event;
        const { keyName, setTypeChangeToParent } = this.props;
        setTypeChangeToParent(keyName, value);
    };

    setRadioboxValue = (value) => {
        const { keyName, setTypeChangeToParent, shouldBeEdited } = this.props;
        if(!shouldBeEdited){
            setTypeChangeToParent(keyName, value);
        }
    };

    render() {
        const { field = {}, valuesList = [], keyName, value, validationErrors, showValidation, className, translateKey, transKey, keyFix, type, newUI, icon, svgIcon, labelClass, disabled } = this.props;
        const showErrors = showValidation && validationErrors.length > 0;

        return (
            <div className={`${className} list-component-wrapper ${showErrors ? 'is-invalid' : ''}`}>
                {icon &&
                    <div className="list-icon-class-wrapper">
                        <img src={icon} alt="" className={`list-icon-class ${svgIcon && 'list-icon-class-svg'}`}/>
                    </div>
                }
                <label className={`form-label-basic ${newUI? 'newUI':''}`}><span className="quick-label-position-fix">{translateKey(transKey).toUpperCase()}</span><Asterisk isRequired={field.isRequired}/></label>
                <div className={`radio-button-group-wrapper ${type?type:''} ${icon?"special-center-class-for-icon":''} ${disabled?'no-pointer-events':''}`}>
                    { valuesList.map(({ value: possibleValue, label }) => {
                        let id = `${keyName}_${possibleValue}`;
                        if(keyFix){
                            id = `${keyFix}_${possibleValue}`;
                        }
                        return (
                            <div className={`custom-control custom-radio custom-control-inline ${type?type:''} newUI-radio-check-box-class `} key={id}>
                                <i className={`fas fa-2x fa-circle radio-fa-radiobox-icon ${disabled?'':'curosr-pointer'} ${value === possibleValue?'radio-fa-active':'radio-fa-not-active'}`} onClick={()=>this.setRadioboxValue(possibleValue)}></i>
                                <input className={`form-check-input ${type?type:''}`} hidden checked={value === possibleValue} onChange={this.setTypeChange} data-field-name={keyName} type="radio" name={keyName} id={id} value={possibleValue} disabled={this.props.shouldBeEdited}/>
                                <label className={`form-check-label cursor-pointer ${newUI? 'newUI-text-color':''} ${type?type:''} ${labelClass?labelClass:''}` } htmlFor={id}>{label}</label>
                            </div>
                        )
                    })}
                </div>
                { showErrors && (
                    <ValidationErrorsList errors={validationErrors} keyName={keyName} />
                )}
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default withRouter(connect(null, mapDispatchToProps)(ListComponent));