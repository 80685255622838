import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey} from "../../_redux/actions/index";
import { getCarerPhoto } from "../../_redux/actions/auth";
import { IconsMap } from "../../_helpers/icons_set";
import { ReqHelper } from "../../_helpers/index";
import { SurveyModal } from "../survey-modal/SurveyModal";
import { PhoneEmailContactComponent, TableDateFormat, CarerModal } from "../../_components/index";
import { SurveyModalAlternative } from "../survey-modal/SurveyModalAlternative";
import * as ContactTypes from '../../_constance/values/contactFieldType';
import * as TravelStatus from '../../_constance/values/TravelStatus';
import axiosAuth from "../../_services/config/axios-auth";
import { generateCarerCvPdf } from '../../_services/backend_service';

class CarerTableDetails extends React.Component {
    _isMounted = false;
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            loadingPhoto: true,
            photoBase: '',
            photoVersion: '',
            showModal: false,
            isCvPoupOpen: false,
            pdfLink: '',
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.remove('modal-open');
        this.getCarerPhoto()
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };

    toggleModal() {
        if(this.state.showModal) {
            document.body.classList.remove('modal-open');
        } else {
            document.body.classList.add('modal-open');
        }
        this.setState({
            showModal: !this.state.showModal
        })
    }

    getCarerPhoto(){
        this.props.getPhoto(this.props.element.contactId, this.props.element.photoVersion)
        .then((res)=>{
            if (this._isMounted) {
                this.setState({
                    loadingPhoto: false,
                    photoBase: res.Photo,
                    photoVersion: res.PhotoVersion
                })
            }
        })
        .catch((err)=>{
            console.log(err);
            if (this._isMounted) {
                this.setState({loadingPhoto: false})
            }
        })
    }

    getMeExperienceText(monthsCount){
        const { translateKey } = this.props;
        let rest = Math.floor(monthsCount%12);
        let finalString = '';
        if(rest===0){
            let years = monthsCount/12;
            if(years===1){
                finalString = years + ' ' + translateKey("time_year") + ' ';
            }else{
                if(years===0){
                    finalString = rest + ' ' + translateKey("time_months");
                }else{
                    finalString = years + ' ' + translateKey("time_years") + ' ';
                }
            }
        }else{
            let years = monthsCount/12;
            if(years===0){
                if(rest===1){
                    finalString += rest + ' ' + translateKey("time_month");
                }else{
                    finalString = rest + ' ' + translateKey("time_months");
                }
            }else{
                finalString = parseInt(years) + ' ' + translateKey("time_year_short") + ' ';
                finalString += rest + ' ' + translateKey("time_month_short");
            }

        }
        return finalString;
    }

    getTrackingStatus(travelStatus, trackingStatus) {
        const { translateKey } = this.props;
        if(travelStatus === TravelStatus.PLANNED) {
            if (this.props.tripStatuses !== undefined) {
                const translated = this.props.tripStatuses.filter(e => {
                    return e.Value == trackingStatus
                });
                if (translated.length > 0) {
                    return translated[0].Label;
                }
            }
            return "---";
        } else {
            return translateKey("carer-trip-status-planned")
        }
    }

    async displayPdf(wzId) {
        axiosAuth.get("/contact/cvData/new/wz/" + wzId + "/" + this.props.appLang)
        .then(async res => {
            const image = this.state.photoBase ? `data:image/png;base64,${this.state.photoBase}` : '';
            const resPdf = await generateCarerCvPdf(res.data, this.props.element.cvProduct, image);
            this.setState({
                isCvPoupOpen: true, 
                pdfLink: resPdf.data.data,
            });
        });
    }

    closePopup = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                isCvPoupOpen: false,
                }
            }
        );
    }

    render() {
        const { translateKey, element } = this.props;
        const nowTime = Math.floor(Date.now() / 1000);
        const showTripFields = element.dateAndTimeArrival !== null; // && element.dateAndTimeArrival >= nowTime;
        const showTripDetailsFields = element.travelStatus === TravelStatus.PLANNED;
        const isLinkAvailable = element.tripTrackingLink !== null && element.tripTrackingLink !== undefined;
        return (
            <div className="invoice-table-details-wrapper">
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-3 flexCenterItems">
                        {this.state.loadingPhoto?
                            <i className="fas fa-spinner fa-spin fa-3x empty-table-icon"></i>
                            :
                            this.state.photoBase==='' || this.state.photoBase===null?
                                <img className="client-carer-image" src={IconsMap.xhdpi.carer_no_image} alt="Icon"/>
                                :
                                <img className="client-carer-image" src={`data:image/jpeg;base64,${this.state.photoBase}`} alt="Icon"/>
                        }
                    </div>
                    <div className="col-sm-12 col-lg-9">
                        {element.age>0 &&
                            <div className="carer-details-info hideMe">
                                <div className="carer-descriptions-header">{translateKey("age")}:</div>
                                <div>{element.age}</div>
                            </div>
                        }
                        {element.country.trim().length>0 &&
                            <div className="carer-details-info">
                                <div className="carer-descriptions-header">{translateKey("citizenship")}:</div>
                                <div>{element.country}</div>
                            </div>
                        }
                        <div className="carer-details-info">
                            <div className="carer-descriptions-header">{translateKey("driving_licence_manual")}:</div>
                            <div>
                                {element.drivingManual?translateKey("yes"):translateKey("no")}
                            </div>
                        </div>
                        <div className="carer-details-info">
                            <div className="carer-descriptions-header">{translateKey("driving_licence_automatic")}:</div>
                            <div>
                                {element.drivingAutomatic?translateKey("yes"):translateKey("no")}
                            </div>
                        </div>
                        <div className="carer-details-info">
                            <div className="carer-descriptions-header">{translateKey("experience_in_care")}:</div>
                            <div>{this.getMeExperienceText(element.experience)}</div>
                        </div>
                        <div className="carer-details-info">
                            <div className="carer-descriptions-header">{translateKey("language_group")}:</div>
                            <div>{element.cvProduct}</div>
                        </div>
                        {this.props.title !=="BB" && element.phoneNumber!== undefined && element.phoneNumber!== null && element.phoneNumber.trim() !=='' && ReqHelper.dateBetweenDates(Math.floor(new Date().getTime() / 1000), element.from, element.to) &&
                            <div className="carer-details-info">
                                <div className="carer-descriptions-header">{translateKey("phone")}:</div>
                                <PhoneEmailContactComponent text={element.phoneNumber} type={ContactTypes.PHONE}></PhoneEmailContactComponent>
                            </div>
                        }
                        {element.about.trim().length>0 &&
                            <div className="carer-details-info about-me">
                                <div className="carer-descriptions-header">{translateKey("about_me")}:</div>
                                <div>{element.about}</div>
                            </div>
                        }
                        {showTripFields === true &&
                            <div className="carer-details-info trip-section-header">
                                <div
                                    className="carer-descriptions-header">{translateKey("carer-trip-section-header")}</div>
                            </div>
                        }
                        {showTripFields === true &&
                            <div className="carer-details-info">
                                <div
                                    className="carer-descriptions-header">{translateKey("carer-trip-tracking-status")}:
                                </div>
                                <div>{this.getTrackingStatus(element.travelStatus, element.trackingStatus)}</div>
                            </div>
                        }
                        {showTripFields === true && showTripDetailsFields === true && isLinkAvailable &&
                            <div className="carer-details-info">
                                <div
                                    className="carer-descriptions-header">{translateKey("carer-trip-date-and-time-arrival")}:
                                </div>
                                <div><TableDateFormat withTime={true} timestamp={element.dateAndTimeArrival}/></div>
                            </div>
                        }
                        {showTripFields === true && showTripDetailsFields === true && isLinkAvailable &&
                            <div className="carer-details-info">
                                <div className="carer-descriptions-header">{translateKey("carer-trip-tracking-link")}:</div>
                                <div><a href={element.tripTrackingLink} target={"_blank"}>{element.tripTrackingLink}</a></div>
                            </div>
                        }
                        {element.survey.length > 0 &&
                            <div className="carer-details-info">
                                <div>
                                    <button type="button" className="btn btn-primary cursor-pointer" onClick={() => this.toggleModal()}>{translateKey("survey_modal_button")}</button>
                                </div>
                                { (element.survey[0].survey.Variant === 1 || element.survey[0].survey.Variant === 0) &&
                                    <SurveyModal 
                                        size="lg" 
                                        centeredVertical={true} 
                                        centeredAlert={true} 
                                        show={this.state.showModal} 
                                        closeModal={() => this.toggleModal()}
                                        survey={element.survey[0].survey}
                                    />
                                }
                                { element.survey[0].survey.Variant === 2 &&
                                    <SurveyModalAlternative
                                        size="lg" 
                                        centeredVertical={true} 
                                        centeredAlert={true} 
                                        show={this.state.showModal} 
                                        closeModal={() => this.toggleModal()}
                                        survey={element.survey[0].survey}
                                    />
                                }
                            </div>
                        }
                        <button type="button" className="btn btn-primary cursor-pointer btn-carer-cv" onClick={() => this.displayPdf(element.wzid)}>
                            {translateKey("carer-see-pdf")}
                        </button>
                        <CarerModal 
                            show={ this.state.isCvPoupOpen } 
                            onClose={ this.closePopup } 
                            pdfLink={ this.state.pdfLink }
                        />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {return {
    appLang: state.lang.appLanguage,
    title: state.auth.title,
    tripStatuses: state.enums.tripTrackingStatusList,
}};
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    getPhoto: (id, v) => dispatch(getCarerPhoto(id, v))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarerTableDetails));