export const CategoriesKeyList = [
    {
        label: 'Pomoc ambulatoryjna / służby medyczne', 
        translabel: 'Ambulante Pflege',
        key: 'ko-piloten-category-ambulante-pflege',
        order: 1,
    },
    {
        label: 'Opieka dzienna', 
        translabel: 'Tagespflege',
        key: 'ko-piloten-category-tagespflege',
        order: 2
    },
    {
        label: 'Opieka dzienna lub nocna', 
        translabel: 'Tages - oder Nachtpflege',
        key: 'ko-piloten-category-tages-oder',
        order: 3
    },
    {
        label: 'Opieka krótkoterminowa', 
        translabel: 'Kurzzeitpflege',
        key: 'ko-piloten-category-kurzzeitpflege',
        order: 4
    },   
    {
        label: 'Środki pomocnicze', 
        translabel: 'Pflegehilfsmittel',
        key: 'ko-piloten-category-pflegehilfsmittel',
        order: 5
        
    },
    {
        label: 'Domowy system powiadamiania alarmowego', 
        translabel: 'Hausnotrufsystem',
        key: 'ko-piloten-category-hausnotrufsystem',
        order: 6
        
    },
    {
        label: 'Nieodpłatne środki pomocnicze do pielęgnacji',
        translabel: 'Kostenlose Pflegehilfsmittel',
        key: 'ko-piloten-category-kostenlose',
        order: 7
    },
    {
        label: 'Sklep ze sprzętem medycznym,/rehabilitacyjnym',
        translabel: 'Sanitätshaus',
        key: 'ko-piloten-category-sanitatshaus',
        order: 8
        
    },
    {
        label: 'Wózek inwalidzki/ krzesło toaletowe', 
        translabel: 'Rollstuhl / Toilettenstuhl',
        key: 'ko-piloten-category-rollstuhl',
        order: 9
    },
    {
        label: 'Podwyższenie do toalety', 
        translabel: 'Toilettensitzerhöhung',
        key: 'ko-piloten-category-toilettensitzerhohung',
        order: 10
    },
    {
        label: 'Łóżko specjalistyczne', 
        translabel: 'Pflegebett und Zubehör',
        key: 'ko-piloten-category-pflegebett',
        order: 11
    },
    {
        label: 'Podnośnik', 
        translabel: 'Patientenlifter',
        key: 'ko-piloten-category-patientenlifter',
        order: 12
    },
    {
        label: 'Chodzik', 
        translabel: 'Rollator',
        key: 'ko-piloten-category-rollator',
        order: 13
    },
    {
        label: 'Podnośnik do wanny', 
        translabel: 'Badewannenlifter',
        key: 'ko-piloten-category-badewannenlifter',
        order: 14
    },
    {
        label: 'Krzesło pod prysznic/ na wannę', 
        translabel: 'Badewannen- o. Duschsitz',
        key: 'ko-piloten-category-badewannen',
        order: 15
    },
    {
        label: 'Podnośnik na schody', 
        translabel: 'Treppenlift',
        key: 'ko-piloten-category-treppenlift',
        order: 16
    },
    {
        label: 'Opieka i wsparcie prawne', 
        translabel: 'Vorsorge und rechtliche Betreuung',
        key: 'ko-piloten-category-vorsorge',
        order: 17
    },
    {
        label: 'Opieka paliatywna/ hospicjum', 
        translabel: 'Palliativversorgung / Hospiz',
        key: 'ko-piloten-category-palliativversorgung',
        order: 18
    },
    {
        label: 'Opieka na godziny', 
        translabel: 'Stundenweise Betreuung',
        key: 'ko-piloten-category-stundenweise',
        order: 19
    },
    {
        label: 'Inne', 
        translabel: 'Sonstiges',
        key: 'ko-piloten-category-sonstiges',
        order: 20
    },
    {
        label: 'Dostawa leków', 
        translabel: 'Arzneimittel Lieferservice',
        key: 'ko-piloten-category-arzneimittel',
        order: 21
    },
    {
        label: 'Lekarz domowy', 
        translabel: 'Hausarzt',
        key: 'ko-piloten-category-hausarzt',
        order: 22
    },
    {
        label: 'Pomoc sąsiedzka', 
        translabel: 'Nachbarschaftshilfe',
        key: 'ko-piloten-category-nachbarschaftshilfe',
        order: 23
    },
    {
        label: 'Zakupy', 
        translabel: 'Einkauf',
        key: 'ko-piloten-category-einkauf',
        order: 24
    },
    {
        label: 'Jedzenie z dostawą', 
        translabel: 'Essen auf Räder',
        key: 'ko-piloten-category-essen',
        order: 25
    },
    {
        label: 'Internet', 
        translabel: 'Internet',
        key: 'ko-piloten-category-internet',
        order: 26
    },
    {
        label: 'Przebudowa', 
        translabel: 'Umbaumaßnahmen',
        key: 'ko-piloten-category-mmbaumabnahmen',
        order: 27
    },
    {
        label: 'Transport osób', 
        translabel: 'Fahrservice',
        key: 'ko-piloten-category-fahrservice',
        order: 28
    },
    {
        label: 'Podolog', 
        translabel: 'Podologen',
        key: 'ko-piloten-category-podologen',
        order: 29
    },
    {
        label: 'Allgemeine Versorgung', 
        translabel: 'Allgemeine Versorgung',
        key: 'ko-piloten-category-allgemeine',
        order: 30
    },
    {
        label: 'Dom opieki', 
        translabel: 'Pflegeheim',
        key: 'ko-piloten-category-pflegeheim',
        order: 31
    },
    {
        label: 'Lekarz rodzinny', 
        translabel: 'Hausarzt',
        key: 'ko-piloten-category-hausarzt-2',
        order: 32
    },
    {
        label: 'Szpital (opieka społeczna)', 
        translabel: 'Krankenhaus (Sozialer Dienst)',
        key: 'ko-piloten-category-krankenhaus',
        order: 33
    },
    {
        label: 'Terapeuci', 
        translabel: 'Therapeuten',
        key: 'ko-piloten-category-therapeuten',
        order: 34
    },
    {
        label: 'Logopeda', 
        translabel: 'Logopädie',
        key: 'ko-piloten-category-logopadie',
        order: 35
    },
    {
        label: 'Terapia zajęciowa', 
        translabel: 'Ergotherapie',
        key: 'ko-piloten-category-ergotherapia',
        order: 36
    },
    {
        label: 'Fizjoterapeuta', 
        translabel: 'Physiotherapie',
        key: 'ko-piloten-category-physiotherapia',
        order: 37
    },
    {
        label: 'Ogrodnik', 
        translabel: 'Gartenpflege',
        key: 'ko-piloten-category-garten',
        order: 38
    }
];