import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../../_redux/actions/index";
import {
  MDBCollapse,
  MDBCard,
  MDBCardBody
} from "mdbreact";
import { CheckBoxComponent } from "../../form-bb-components/checkbox-component/checkbox-component";

class KoPilotenMultiCollapse extends Component {
  _isMounted = false;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      collapseIsOpen: false
    };
  }

  toggleCollapse = () =>{
    if(!this.props.category.IsSubCategory){
      this.setState(prevState => {
        return {
          ...prevState,
          collapseIsOpen: !prevState.collapseIsOpen,
        }
      });
    }
  }

  changeCategory = (cat, value)=>{
    this.props.editCategory(cat, value)
  }

  getMeCategoryChildren=()=>{
    const { category, translateKey } = this.props;
    let newList = [];
    category.SubCategoriesList.forEach(SubCategory => {
      if(SubCategory.IsSubCategory){
        newList.push(
          <div key={SubCategory.CategoryId} className="sub-category-kopilonte-wrapper">
            <CheckBoxComponent
              className=""
              newUI={true}
              disabled={false}
              checkClassWrapper={'newUI-radio-check-box-class'}
              showValidation={false}
              setTypeChangeToParent={this.changeCategory}
              value={this.checkIfCategoryAssinged(SubCategory.CategoryId.toUpperCase())}
              text={translateKey(SubCategory.Name)}
              transKey={SubCategory.Name}
              keyName={SubCategory.CategoryId.toUpperCase()}
              labelClass="check-full-label-width"
            />
          </div>
        );
      }
    });
    return newList;
  }

  checkIfCategoryAssinged=(catId)=>{
    const { assignedCategories } = this.props;
    let exists = assignedCategories.indexOf(catId);
    if (exists === -1){
      return false;
    }else{
      return true;
    }
  }

  render() {
    const { collapseIsOpen } = this.state;
    const { category, name} = this.props;
    return (
      <React.Fragment>
        {category.SubCategoriesList.length>0?
            <MDBCard className="ko-piloten-card">
              <div className="ko-piloten-card-header" onClick={()=>this.toggleCollapse()}>
                <div className="multiplicator-subcontainer-with-subcategory">
                  <label className="ko-piloten-category-text newUI-text-color">{name}</label>
                  <i className={collapseIsOpen? "fa fa-minus":"fa fa-plus"}/>
                </div>
              </div>
              <MDBCollapse isOpen={collapseIsOpen} id="basicCollapse">
                <MDBCardBody className="ko-piloten-card-body">
                  <div className="ko-piloten-sub-categories-wrapper">
                    {this.getMeCategoryChildren()}
                  </div>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
          :
            <MDBCard className="ko-piloten-card">
              <div className="ko-piloten-card-header">
                  <CheckBoxComponent
                    className="multiplicator-checkbox"
                    newUI={true}
                    checkClassWrapper={'newUI-radio-check-box-class'}
                    showValidation={false}
                    setTypeChangeToParent={this.changeCategory}
                    value={this.checkIfCategoryAssinged(category.CategoryId.toUpperCase())}
                    text={name}
                    transKey={category.Name}
                    keyName={category.CategoryId.toUpperCase()}
                    labelClass="check-full-label-width"
                  />
              </div>
            </MDBCard>
        }
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) =>  dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(KoPilotenMultiCollapse));