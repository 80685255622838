import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import LoginPage from './_pages/login/login_page';
import LeadsMainPage from './_pages/leads/leads';
import LeadsDetailPage from './_pages/leads/leadsDetails';
import LeadPatientPage from './_pages/leadPatient/leadPatient';
import NewLeadPage from './_pages/leads/newLead';
import ClientsMainPage from './_pages/clients/clients';
import ClientDetails from './_pages/clients/clientDetails';
import ClientPatientPage from './_pages/clientPatient/clientPatient';
import ContractPage from './_pages/contract/contractDetails';
import MultipliersMainPage from './_pages/multipliers/multipliers';
import EditMultiplierPage from './_pages/multipliers/editMultiplier';
import NewMultiplierPage from './_pages/multipliers/newMultiplier';
import InvoicesMainPage from './_pages/invoices/invoices';
import TasksMainPage from './_pages/tasks/tasks';
import EditTaskPage from './_pages/tasks/editTask';
import NewTaskPage from './_pages/tasks/newTask';
import DashboardPage from './_pages/dashboard/dashboard';
import SurveyPage from './_pages/survey/survey';
import ArrivalsPage from './_pages/arrivals/arrivals';
import InformationPage from './_pages/information/information';
import ProfilePage from './_pages/profile/profile';
import LogoutPage from './_pages/logoutPage/logoutPage';
import MainPatientWrapper from './_pages/patientBB/mainPatientWrapper';
import MainCareBoxWrapper from './_pages/careBox/mainCareBoxWrapper';
import MainCareBoxWrapperClient from './_pages/careBox/mainCareBoxWrapperClient';
import IngredientsCareBoxWrapperClient from './_pages/careBox/ingredientsCareBoxWrapperClient';
import MainClientBBWrapper from './_pages/clientBB/mainClientBBWrapper';
import ArrangeMeetingComponent from './_pages/arrangeMeeting/arrangeMeetingComponent';
import { baseData } from './_constance/base_data';
import RouteWrapper from './RouteWrapper';
//Redux
import { authCheckState } from './_redux/actions/auth';
import { getBasicSelectedLang, loadBasicDataToRedux, loadBasicEnumsToRedux } from './_redux/actions/index';
import { connect } from 'react-redux';
require('es6-promise/auto');
require("jspolyfill-array.prototype.findIndex")
require('ie-array-find-polyfill');

class Root extends Component {

    componentDidMount() {
        this.props.tryAutoLogin();
        this.props.getBasicSelectedLang();
    }

    componentDidUpdate() {
        const {langCode, isAuthenticated, loadBasicEnumsToRedux, loadBasicDataToRedux} = this.props;
        if (isAuthenticated && langCode) {
            loadBasicEnumsToRedux(langCode);
            loadBasicDataToRedux(langCode);
        }
    }

    render() {
        let routes = null;
        const isAuth = !this.props.isAuthenticated;
        if (isAuth) {
            routes = (
                <Switch>
                    <Route path={baseData.subFolders} component={LoginPage} />
                </Switch>
            );
        } else {
            routes = (
                <Switch>
                    <Route path={`${baseData.subFolders}logout`} exact component={LogoutPage} />

                    <Route path={`${baseData.subFolders}leads`} exact>
                        <RouteWrapper component={<LeadsMainPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}leadDetails/:leadId`} exact>
                        <RouteWrapper component={<LeadsDetailPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}leadPatient/:leadId/:patientId?`} exact>
                        <RouteWrapper component={<LeadPatientPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}leadArrangeMeeting/:leadId/:type?`} exact>
                        <RouteWrapper component={<ArrangeMeetingComponent/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}newlead`} exact>
                        <RouteWrapper component={<NewLeadPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}clients`} exact>
                        <RouteWrapper component={<ClientsMainPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}clientDetails/:id/:contractId?`} exact>
                        <RouteWrapper component={<ClientDetails/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}clientPatient/:id/:patientId?`} exact>
                        <RouteWrapper component={<ClientPatientPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}contract/:id/:contractId`} exact>
                        <RouteWrapper component={<ContractPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}multipliers`} exact>
                        <RouteWrapper component={<MultipliersMainPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}multiplierDetails/:id`} exact>
                        <RouteWrapper component={<EditMultiplierPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}newmultiplier`} exact>
                        <RouteWrapper component={<NewMultiplierPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}invoices/:id?`} exact>
                        <RouteWrapper component={<InvoicesMainPage/>}/>
                    </Route>
           
                    <Route path={`${baseData.subFolders}tasks`} exact>
                        <RouteWrapper component={<TasksMainPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}taskDetails/:id`} exact>
                        <RouteWrapper component={<EditTaskPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}newtask/:assignTo?/:type?`} exact>
                        <RouteWrapper component={<NewTaskPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}dashboard`} exact>
                        <RouteWrapper component={<DashboardPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}survey`} exact>
                        <RouteWrapper component={<SurveyPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}arrivals`} exact>
                        <RouteWrapper component={<ArrivalsPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}contact`} exact>
                        <RouteWrapper component={<InformationPage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}profile`} exact>
                        <RouteWrapper component={<ProfilePage/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}bb-form-lead/:id/:patientId?/:offerOrContractId?/:type?`} exact>
                        <RouteWrapper component={<MainPatientWrapper/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}care-box-form/:id/:patientId?/:offerOrContractId?/:type?`} exact>
                        <RouteWrapper component={<MainCareBoxWrapper/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}bb-form-client/:id/:patientId?/:offerOrContractId?/:type?`} exact>
                        <RouteWrapper component={<MainClientBBWrapper/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}care-box-form-client/:id/:patientId?/:offerOrContractId?/:type?`} exact>
                        <RouteWrapper component={<MainCareBoxWrapperClient/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}new-care-box-form-client/:id/:patientId?/:offerOrContractId?/:type?`} exact>
                        <RouteWrapper component={<MainCareBoxWrapperClient alwaysNew={true}/>}/>
                    </Route>

                    <Route path={`${baseData.subFolders}care-box-form-client-sketch/:id/:patientId/:careBoxId`} exact>
                        <RouteWrapper component={<MainCareBoxWrapperClient/>}/>
                    </Route>


                    <Route path={`${baseData.subFolders}care-box-form-client-ingredients/:id/:patientId/:careBoxId`} exact>
                        <RouteWrapper component={<IngredientsCareBoxWrapperClient/>}/>
                    </Route>

                    <Redirect to={`${baseData.subFolders}leads`} />
                </Switch>
            );
        }
        return (
            <>
                <Route render={({ history }) => {
                    const hash = history.location.hash.substring(1);
                    if(hash !== '') {  
                        history.push(hash);
                    }
                }} />
                <Router>
                    {routes}
                </Router>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        tryAutoLogin: () => dispatch(authCheckState()),
        getBasicSelectedLang: () => dispatch(getBasicSelectedLang()),
        loadBasicDataToRedux: (lang) => dispatch(loadBasicDataToRedux(lang)),
        loadBasicEnumsToRedux: (lang) => dispatch(loadBasicEnumsToRedux(lang))
    };
};
const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.appToken !== null && state.auth.appToken !== undefined,
        basicDataSaved: state.enums.finishSave,
        langCode: state.lang.appLanguage
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Root);