export const MockTaskData = {
    concernName: "",
    concernType: "",
    concerns: "",
    convertedCreatedDate: "",
    convertedDueDay: "",
    createdDate: Math.floor(new Date().getTime() / 1000),
    description: "",
    direction: '',
    dueDay: 0,
    id: "",
    owner: "",
    priority: "",
    startType:"",
    status: "Open",
    subject: "",
    taskType: "",
    title: "",
    type: "",
    StartTime: 0,
    FinishTime: 0
};