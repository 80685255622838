import * as React from 'react';
import { connect } from 'react-redux';

import { translateKey } from "../../../../_redux/actions/index";

import { OFFER_SECTION } from '../../../../_constance/enums/summaryOfferMenuSections';

class SummaryOfferToolbar extends React.Component {
  _isMounted = false;
  myTimeout

  constructor(props) {
    super(props);
    this.state = {
      element: props.data,
      analizeOffer: true,
      progressWidth: 0,
      myInterval: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.startProgress();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.myInterval);
    clearTimeout(this.myTimeout);
  };

  increaseProgress = () => {
    if (this._isMounted) {
      if (this.state.progressWidth >= 100) {
        clearInterval(this.state.myInterval);
        this.myTimeout = setTimeout(() => {
          this.analizeComplete()
        }, 1000);
      } else {
        this.setState(previousState => ({
          ...previousState,
          progressWidth: previousState.progressWidth + 2
        }));
      }
    }
  }

  startProgress = () => {
    if (this._isMounted) {
      if (this.state.analizeOffer === true) {
        let intervalId = setInterval(() => {
          this.increaseProgress();
        }, 100);
        this.setState({ myInterval: intervalId });
      } else {
        this.setState({ analizeOffer: false })
      }
    }
  }

  analizeComplete = () => {
    this.setState({ analizeOffer: false })
    this.props.setOfferLoaded();
  }

  setActiveOfferSection = () => {
    this.props.setActiveSection(OFFER_SECTION);
  }

  render() {
    const { translateKey, isOfferButtonVisibleInToolbar, isAnwendedButtonVisibleInToolbar } = this.props;
    const { progressWidth, analizeOffer } = this.state;

    return (
      <div className="summary-offer-toolbar">
        <div className="d-flex w-100 align-items-center">
          {isOfferButtonVisibleInToolbar && (
            <>
              <div className="summary-offer-progress-label">
                {analizeOffer ? translateKey("summary-offer-header-label-1") : translateKey("summary-offer-header-label-2")}
              </div>
              {analizeOffer ? (
                <div className="progress special-progress summary-offer-progress">
                  <div className="progress-bar summary-offer-progress-bar" role="progressbar" style={{ width: progressWidth+ "%" }}></div>
                </div>
              ) : (
                <>
                <button 
                  type="button" 
                  className="btn btn-success summary-offer-button"
                  onClick={this.setActiveOfferSection} 
                >
                  {translateKey("summary-offer-header-siehe-angebot")}
                  <i className="fas fa-chevron-right summary-offer-icon" />
                </button>

                </>
              )}
            </>
          )}
        </div>
        {isAnwendedButtonVisibleInToolbar && (
          <button 
            type="button" 
            className="btn btn-success summary-offer-button"
            onClick={this.setActiveOfferSection} 
          >
            {translateKey("summary-offer-header-anwenden")}
            <i className="fas fa-chevron-right summary-offer-icon" />
          </button>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });

export default connect(null, mapDispatchToProps)(SummaryOfferToolbar);