import * as React from 'react';
import styled from 'styled-components'

const InfoMessage = ({ text }) => {
  
  const InfoMessage = styled.div`
    display: block;
    width: 100%;
    margin-top: .25rem;
    font-size: .8em;
    color: #dc3545;
  `;

  return (
    <InfoMessage>
        { text }
    </ InfoMessage>
  )
}

export default InfoMessage