import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from '../../../../_redux/actions/index';
import { Alert } from "../../../../_constance/classes/index";
import { InfoModal, LoaderModal, AlertModalInfo } from "../../../index";
import ListComponent from "../../list-component/list-component";
import { FormHtmlHelper} from '../../../../_helpers/rodo_html_helper';
import { sendZusammenfasungMail } from '../../../../_services/backend_service';
import CancelationObj from "../../../form-bb-sections/SectionOfferSummary/classes/CancelationObj";
import { CANCELATION_EMAIL_ENUMS } from '../../../../_constance/enums/enumsForCancelationEmail';
import { formatPrice } from '../../../../_helpers/price_format';
import { PriceType } from '../../../../_constance/enums/priceType';
import { CheckBoxComponent } from '../../../form-bb-components/checkbox-component/checkbox-component';
import * as RecordType from "../../../../_constance/values/recordType";

// key needed to retrieve information about pflegegrad
const IS_PFLEGEGRAD = 'is_pflegegrad';

// key needed to retrieve information about pflegegrad
const IS_KEIN_PFLEGEGRAD = 'is_kein_pflegegrad';

// key needed to retrieve information about Bawaria postalcode
const IS_SERVICE_ADDRESS_POSTAL_CODE = 'is_service_address_postal_code';

//key name to select isBawaria discount 
const bawariaKeyName = 'fee_bayern';

class Section4 extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.showComponent = this.showComponent.bind(this);
    this.state = {
      sectionList: this.props.sectionList,
      activeSection: this.props.activeSection,
      idComponent: false,
      emailTosendZussamenfassung: this.propstype === RecordType.LEAD ? this.props.clientLeadBaseData.client.email : this.props.form.getFieldByKey("is_email").value,
      showValidation: false,
      loaderModal: false,
      errorComponent: Alert,
      infoModalOptions: Alert,
      chosedEmail: '717700002',
      availableLanguagePackets: this.getAvailableLanguagePackets(),
      availableAdditionalOptions: this.getAvailableAdditionalOptions(),
      sendZussamenfasungFirstPatient: true,
      sendZussamenfasungSecondPatient: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  changeSectionPage(number) {
    this.props.changeSection(number);
  }

  showComponent(idComponent) {
    this.props.showComponent(idComponent);
  }

  emailHendler(value) {
    this.setState({
      ...this.state,
      emailTosendZussamenfassung: value,
    });
  }

  sendZusammenfasungMail() {
    const isEmailValid = this.validate();

    if(isEmailValid){
      this.sendZusammenfasung();
    }
  }

  validate(){
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if(!this.state.emailTosendZussamenfassung || reg.test(this.state.emailTosendZussamenfassung) === false){
        this.setState({showValidation: true});
        return false;
    } else {
      this.setState({showValidation: false});
      return true;
    }
  }

  hideInfo = () => {
    this.setState({
      infoModalOptions: {
        show: false,
        type: "danger",
        message: this.props.translateKey("basic_error_message")
      },
    });
    this.props.toogleMenu();
  }

  setTypeChangeAndSetEmail = (key, value) => {
      let setEmail = '';
      switch(value){
        case '717700000':   setEmail = '';
                            break;
        case '717700001':   
                            if(this.props.clientLeadBaseData.patient.email !== null){
                              setEmail = this.props.clientLeadBaseData.patient.email;
                            } else {
                              setEmail = '';
                            }
                            break;
        case '717700002':   
                            if (this.propstype === RecordType.LEAD) {
                              if(this.props.clientLeadBaseData.client.email !== null){
                                setEmail = this.props.clientLeadBaseData.client.email;
                              } else {
                                setEmail = '';
                              }
                            } else {
                              if(this.props.form.getFieldByKey("is_email").value !== null){
                                setEmail = this.props.form.getFieldByKey("is_email").value;
                              } else {
                                setEmail = '';
                              }
                            }
                            break;
        case '717700003':   
                            if(this.props.fpFirstMail !== null){
                              setEmail = this.props.fpFirstMail;
                            } else {
                              setEmail = '';
                            }
                            break;
        default:
      }
      
      this.setState({
        emailTosendZussamenfassung: setEmail,
        chosedEmail: value,
      })

  }

  getAvailableLanguagePackets = () => {
    return this.props.promedicaProductsPrices.filter(item => item.category === "Produkt");
  }

  getAvailableAdditionalOptions = () => {
    return this.props.promedicaProductsPrices.filter(item => item.category === "Dodatek");
  }

  getDiscount = (form) => {
    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const isKeinPflegegrad = form.getFieldByKey(IS_KEIN_PFLEGEGRAD);
    if (isKeinPflegegrad.value) {return false;}
    if(pflegegrad.value.toString() === '717700000') {
      return {
        name: "from-text-pflegegrad1",
        price: "0"
      }
    }

    if(pflegegrad.value.toString() === '717700001') {
      return {
        name: "from-text-pflegegrad2",
        price: "347"
      }
    }

    if(pflegegrad.value.toString() === '717700002') {
      return {
        name: "from-text-pflegegrad3",
        price: "599"
      }
    }

    if(pflegegrad.value.toString() === '717700003') {
      return {
        name: "from-text-pflegegrad4",
        price: "800"
      }
    }

    if(pflegegrad.value.toString() === '717700004') {
      return {
        name: "from-text-pflegegrad5",
        price: "990"
      }
    }
  }

  isPostalCodeBawaria = () => {
    const { cities, form, secondPatientForm } = this.props;
    const isServiceAddressPostalCode = form.getFieldByKey(IS_SERVICE_ADDRESS_POSTAL_CODE).value;
    if (cities.length > 0) {
      for (let index = 0; index < cities.length; index++) {
        if (cities[index].PostalCode.indexOf(isServiceAddressPostalCode) > -1) {
          if (cities[index].Bawaria === 1) {
            const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
            const pflegegradStringValue = pflegegrad.value.toString();
            const pflegegradSecondPatient = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);
            const pflegegradStringValueSecondPatient = pflegegradSecondPatient.value.toString();
            if(
              pflegegradStringValue === '717700001' ||
              pflegegradStringValue === '717700002' ||
              pflegegradStringValue === '717700003' ||
              pflegegradStringValue === '717700004' ||
              pflegegradStringValueSecondPatient === '717700001' ||
              pflegegradStringValueSecondPatient === '717700002' ||
              pflegegradStringValueSecondPatient === '717700003' ||
              pflegegradStringValueSecondPatient === '717700004'
            ) {
              return true;
            }
          }
        }
      }
      return false;
    } else {
      return false;
    }
  }

  get priceOfLanguagePacket() {
    const { customersOptions, match: { params }} = this.props;
    const { availableLanguagePackets } = this.state;
    const customerData = customersOptions.find(element => element.id === params.patientId);
    return availableLanguagePackets.find(item => item.id === customerData.langOption).price;
  }

  get bawariaDiscount(){
    const { form, secondPatientForm, promedicaProductsPrices } = this.props;
    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValue = pflegegrad.value.toString();
    const pflegegradSecondPatient = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValueSecondPatient = pflegegradSecondPatient.value.toString();
    if(
      pflegegradStringValue === '717700001' ||
      pflegegradStringValue === '717700002' ||
      pflegegradStringValue === '717700003' ||
      pflegegradStringValue === '717700004' ||
      pflegegradStringValueSecondPatient === '717700001' ||
      pflegegradStringValueSecondPatient === '717700002' ||
      pflegegradStringValueSecondPatient === '717700003' ||
      pflegegradStringValueSecondPatient === '717700004'
    ) {
      const priceDiscount = promedicaProductsPrices.find(item => item.id === bawariaKeyName);
      if(priceDiscount){
        if(
          (pflegegradStringValue === '717700001' ||
          pflegegradStringValue === '717700002' ||
          pflegegradStringValue === '717700003' ||
          pflegegradStringValue === '717700004' ) &&
          (pflegegradStringValueSecondPatient === '717700001' ||
          pflegegradStringValueSecondPatient === '717700002' ||
          pflegegradStringValueSecondPatient === '717700003' ||
          pflegegradStringValueSecondPatient === '717700004')
        ) {
          return (Math.abs(parseFloat(priceDiscount.price))*2);
        } else {
          return Math.abs(parseFloat(priceDiscount.price));
        }
      }
    }

    return 0;
  }

  get totalPriceWithDiscount() {
    const { form, promedicaProductsPrices, customersOptions, match: { params }, isAditionalPatient, secondPatientForm} = this.props;
    const { availableAdditionalOptions} = this.state;
    let totalPriceOfAdditionalOptions = 0.00;
    let bawariaDiscount = 0.00;
    let pflegegradDiscount = 0.00;
    let pflegegradDiscountSecondPatient = 0.00;
    const customerData = customersOptions.find(element => element.id === params.patientId);
    customerData.aditionalOptions.forEach(element => {totalPriceOfAdditionalOptions = totalPriceOfAdditionalOptions + parseFloat(availableAdditionalOptions.find(item => item.id === element).price)});
    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValue = pflegegrad.value.toString();
    const pflegegradSecondPatient = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValueSecondPatient = pflegegradSecondPatient.value.toString();

    if(
      pflegegradStringValue === '717700001' ||
      pflegegradStringValue === '717700002' ||
      pflegegradStringValue === '717700003' ||
      pflegegradStringValue === '717700004' ||
      pflegegradStringValueSecondPatient === '717700001' ||
      pflegegradStringValueSecondPatient === '717700002' ||
      pflegegradStringValueSecondPatient === '717700003' ||
      pflegegradStringValueSecondPatient === '717700004'
    ) {
      if(this.isPostalCodeBawaria()) {
        const priceDiscount = promedicaProductsPrices.find(item => item.id === bawariaKeyName);
        if(priceDiscount){
          bawariaDiscount = parseFloat(priceDiscount.price);

          if(
            (pflegegradStringValue === '717700001' ||
            pflegegradStringValue === '717700002' ||
            pflegegradStringValue === '717700003' ||
            pflegegradStringValue === '717700004' )&&
            (pflegegradStringValueSecondPatient === '717700001' ||
            pflegegradStringValueSecondPatient === '717700002' ||
            pflegegradStringValueSecondPatient === '717700003' ||
            pflegegradStringValueSecondPatient === '717700004')
          ) {
            bawariaDiscount = bawariaDiscount * 2;
          }
        }
      }
    }
    if (this.getDiscount(form)) {pflegegradDiscount = parseFloat(this.getDiscount(form).price);}
    if (this.getDiscount(secondPatientForm) && isAditionalPatient) {pflegegradDiscountSecondPatient = parseFloat(this.getDiscount(secondPatientForm).price);}
    return (parseFloat(this.priceOfLanguagePacket) + totalPriceOfAdditionalOptions - pflegegradDiscount - pflegegradDiscountSecondPatient + bawariaDiscount).toString();
  }

  get totalPrice() {
    const { customersOptions, match: { params }} = this.props;
    const { availableAdditionalOptions } = this.state;
    const customerData = customersOptions.find(element => element.id === params.patientId);
    let totalPriceOfAdditionalOptions = 0.00;
    customerData.aditionalOptions.forEach(element => {totalPriceOfAdditionalOptions = totalPriceOfAdditionalOptions + parseFloat(availableAdditionalOptions.find(item => item.id === element).price)});
    return (parseFloat(this.priceOfLanguagePacket) + totalPriceOfAdditionalOptions).toString();
  }

  sendZusammenfasung = () => {
    const { form, fpName, fpFirstMail, fpPhone, userId, customersOptions, translateKey, priceType, type, match: { params }, secondPatientForm, isAditionalPatient } = this.props;
    const { emailTosendZussamenfassung, availableLanguagePackets, availableAdditionalOptions, sendZussamenfasungFirstPatient, sendZussamenfasungSecondPatient} = this.state;
    let dataToTableSummary = {}
    if (type === RecordType.LEAD) {
      const customerData = customersOptions.find(element => element.id === params.patientId);
      const foundedLang = availableLanguagePackets.find(item => item.id === customerData.langOption);
      const patientName = form.getFieldByKey("is_first_name_and_last_name_of_patient").value;
      const secondPateintName = secondPatientForm.getFieldByKey("is_first_name_and_last_name_of_patient").value;
      // const isMultiplePatients = form.getFieldByKey("").value;
      dataToTableSummary = {
        priceTypeText: priceType === PriceType.DAILY ? translateKey('summary-offer-sprache-footer-daily-payment') : translateKey('summary-offer-sprache-footer'),
        [CANCELATION_EMAIL_ENUMS.LANGTYPE]: new CancelationObj(
          CANCELATION_EMAIL_ENUMS.LANGTYPE,
          foundedLang.label,
          priceType === PriceType.DAILY ? formatPrice(foundedLang.price/30) : formatPrice(foundedLang.price),
        ), 
        [CANCELATION_EMAIL_ENUMS.ADDITIONALTYPE]: customerData.aditionalOptions.map(id => {
          let foundedItem = availableAdditionalOptions.find(item => item.id === id);
          if(foundedItem){
            return new CancelationObj(
              CANCELATION_EMAIL_ENUMS.ADDITIONALTYPE,
              translateKey(foundedItem.label),
              priceType === PriceType.DAILY ? formatPrice(foundedItem.price/30) : formatPrice(foundedItem.price),
            )
          }else{
            return '';
          }
        }).filter(el => el !=='')
      }
      let discountsAll = [];
      const discountBasedOnPflegegrad = this.getDiscount(form);
      const discountBasedOnPflegegradSecondPatient = this.getDiscount(secondPatientForm);
      if(discountBasedOnPflegegrad){
        discountsAll.push(new CancelationObj(
          CANCELATION_EMAIL_ENUMS.DISCOUNTTYPE,
          `${translateKey(discountBasedOnPflegegrad.name)} ${(discountBasedOnPflegegradSecondPatient && isAditionalPatient) ? `(${ patientName })` : ''} `,
          `-${priceType === PriceType.DAILY ? formatPrice(discountBasedOnPflegegrad.price/30) : formatPrice(discountBasedOnPflegegrad.price)}`,
          translateKey("summary-offer-cart-section-label-2")
        ))
      }
      if(discountBasedOnPflegegradSecondPatient && isAditionalPatient){
        discountsAll.push(new CancelationObj(
          CANCELATION_EMAIL_ENUMS.DISCOUNTTYPE,
          `${translateKey(discountBasedOnPflegegradSecondPatient.name)} (${ secondPateintName }) `,
          `-${priceType === PriceType.DAILY ? formatPrice(discountBasedOnPflegegradSecondPatient.price/30) : formatPrice(discountBasedOnPflegegradSecondPatient.price)}`,
          translateKey("summary-offer-cart-section-label-2")
        ))
      }

      const discountBawaria = this.isPostalCodeBawaria()
      if(discountBawaria){
        discountsAll.push(new CancelationObj(
          CANCELATION_EMAIL_ENUMS.DISCOUNTTYPE,
          translateKey("form-text-landesgeld-bayern"),
          `-${priceType === PriceType.DAILY ? formatPrice(this.bawariaDiscount/30) : formatPrice(this.bawariaDiscount)}`,
          translateKey("summary-offer-cart-section-label-2")
        ))
      }
      dataToTableSummary = {
        ...dataToTableSummary, 
        [CANCELATION_EMAIL_ENUMS.DISCOUNTTYPE]: discountsAll,
        [CANCELATION_EMAIL_ENUMS.SUMMARYWITHDISCOUNT]: new CancelationObj(
          CANCELATION_EMAIL_ENUMS.SUMMARYWITHDISCOUNT,
          translateKey("summary-offer-with-discount"),
          priceType === PriceType.DAILY ? formatPrice(this.totalPriceWithDiscount/30) : formatPrice(this.totalPriceWithDiscount),
        ),
        [CANCELATION_EMAIL_ENUMS.SUMMARYPRICE]: new CancelationObj(
          CANCELATION_EMAIL_ENUMS.SUMMARYPRICE,
          translateKey("summary-offer-without-discount"),
          priceType === PriceType.DAILY ? formatPrice(this.totalPrice/30) : formatPrice(this.totalPrice),
        ),
      };
    }
      let FPphoneFormated = '';
      if(fpPhone) {
        FPphoneFormated = `${fpPhone.slice(0, 3)} ${fpPhone.slice(3, fpPhone.length)}`;
      }
      let fields = form.getUniqueFlatFieldsList().map(f => ({
        key: f.entity + "_" + f.key,
        value: f.currentValue
      }))
      console.log(fields);
      let dataToSend = {
        userID: userId,
        toEmail: emailTosendZussamenfassung,
        fpName: fpName,
        fpEmail: fpFirstMail,
        fpPhone: FPphoneFormated,
        formPdfContent: FormHtmlHelper(form, translateKey),
        rawForm : fields,
        restData: dataToTableSummary,
        type: type,
      }
      if (isAditionalPatient) {
        if(sendZussamenfasungFirstPatient){
          dataToSend = {...dataToSend, formPdfContent: FormHtmlHelper(form, translateKey)}
        }
        if(sendZussamenfasungSecondPatient){
          let secondPatientFields = secondPatientForm.getUniqueFlatFieldsList().map(f => ({
            key: f.entity + "_" + f.key,
            value: f.currentValue
          }))
          dataToSend = {...dataToSend, formPdfContentSecondPatient: FormHtmlHelper(secondPatientForm, translateKey), rawFormSecondPatient : secondPatientFields}
        }
      } else {
        dataToSend = {...dataToSend, formPdfContent: FormHtmlHelper(form, translateKey)}
      }

      this.setState({loaderModal: true})
      sendZusammenfasungMail(dataToSend)
      .then(res => {
        if(res){
          if(res.status===200){
            if (this._isMounted) {
              this.setState({
                loaderModal: false,
                infoModalOptions: {
                  show: true,
                  type: "success",
                  message: translateKey('ko-piloten-success-pdf-send')
                },
              })
            }
          }else{
            if (this._isMounted) {
              this.setState({
                loaderModal: false,
                infoModalOptions: {
                  show: true,
                  type: "danger",
                  message: translateKey('basic_error_message')
                }
              })
            }
          }
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loaderModal: false,
            infoModalOptions: {
              show: true,
              type: "danger",
              message: translateKey('basic_error_message')
            }
          })
          document.body.classList.remove('overflow-y-scroll');
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({ 
                errorComponent: Alert,
              });
            }
          }, 3000);
        }
      });
  }

  setTypeChange = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  render() {
    const { translateKey, isAditionalPatient, form, secondPatientForm, type } = this.props;
    const { showValidation, errorComponent, infoModalOptions, loaderModal, chosedEmail } = this.state;
    const patientName = form.getFieldByKey("is_first_name_and_last_name_of_patient").value;
    const secondPateintName = secondPatientForm.getFieldByKey("is_first_name_and_last_name_of_patient").value;
    const buttons = [
      { value: '717700003', label: translateKey("die-bestellung-form-label-fp") },
      { value: '717700002', label: translateKey("die-bestellung-form-label-kunde") },
      { value: '717700001', label: translateKey("die-bestellung-form-label-patient") },
      { value: '717700000', label: translateKey("other") },
    ];

    if (type !== RecordType.LEAD) {
      buttons.splice(2, 1);
    }
    return (
      <div className="newUI-section-padding new-menu__section-row ">
        <LoaderModal show={loaderModal}/>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="new-menu__section-title">
                {translateKey("new-hamburger-menu_send-zusammenfassung").toUpperCase()}
            </div>
          </div>
        </div>
        {
          isAditionalPatient &&
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <CheckBoxComponent
                newUI={ true }
                checkClassWrapper='newUI-radio-check-box-class'
                showValidation={ false }
                className='col-sm-12 col-lg-12'
                setTypeChangeToParent={ this.setTypeChange }
                value={ this.state.sendZussamenfasungFirstPatient }
                validationErrors={ false }
                text={ patientName }
                transKey="sendZussamenfasungFirstPatient"
                keyName="sendZussamenfasungFirstPatient"
              />
              <CheckBoxComponent
                newUI={ true }
                checkClassWrapper='newUI-radio-check-box-class'
                showValidation={ false }
                className='col-sm-12 col-lg-12'
                setTypeChangeToParent={ this.setTypeChange }
                value={ this.state.sendZussamenfasungSecondPatient }
                validationErrors={ false }
                text={ secondPateintName }
                transKey="sendZussamenfasungSecondPatient"
                keyName="sendZussamenfasungSecondPatient"
              />
            </div>
          </div>
        }
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <ListComponent
              newUI={true}
              setTypeChangeToParent={this.setTypeChangeAndSetEmail}
              showValidation={false}
              className="form-margin-bottom-fix"
              transKey="new-hamburger-menu_email-check"
              key="is_identical_email"
              keyName="is_identical_email"
              value={chosedEmail}
              validationErrors={false}
              valuesList={buttons}
            />
            <p>{translateKey("new-hamburger-menu_email-address-zusammenfassung-send")}</p>
            <input
              style={{maxWidth: '400px'}}
              type='text'
              onChange={e => this.emailHendler(e.target.value)}
              value={this.state.emailTosendZussamenfassung}
              className={`form-control input-flex-firefox-fix`}
              placeholder='m.mustermann@promedica24.pl'
            />
            <div className={`invalid-feedback ${showValidation ? "showError" : ""}`}>
              {translateKey("form-text-err_not_an_email_address")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <button 
                type="button" 
                className="btn btn-success button-order"
                onClick={() => {this.sendZusammenfasungMail()}} 
                >
                    {translateKey("new-hamburger-menu_send").toUpperCase()}
                <i className="fas fa-chevron-right summary-offer-icon" />
            </button>
          </div>
        </div>
        <InfoModal okMe={this.hideInfo} show={infoModalOptions.show} titleMessage="" mainMessage={infoModalOptions.message} okMessage={translateKey("Ok")} />
        <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={translateKey(errorComponent.message)}/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    multipliersData: state.database.multipliers,
    multiCategoryEnum: state.enums.multipliersCategoryList,
    fpFirstMail: state.auth.contactFpFirstEmail,
    fpName: state.auth.contactFpName,
    fpPhone: state.auth.FPphone,
    userId: state.auth.userId,
    cities: state.enums.citiesList,
    promedicaProductsPrices: state.enums.promedicaProductsPrices,
    customersOptions: state.customersOptions.customersOptions,
    priceType: state.priceType.priceType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section4));
