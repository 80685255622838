import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import { CheckBoxSmallMultipleComponent } from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import  ListMovementComponent  from '../../form-bb-components/list-movement-component/list-movement-component';
import * as IsContactPersonDataSameAs from '../../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as SkillRange from '../../../_forms/BBForm/values/skillRange';
import ListComponent from "../../form-bb-components/list-component/list-component";
import SwitchComponent from "../../form-bb-components/switch-component/switch-component";
import SwitchWithTextComponent from "../../form-bb-components/switch-with-text/switch-with-text-component";
import { IconsMap } from "../../../_helpers/icons_set";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import ReqHelper from "../../../_helpers/request_helper";
import { Tiles } from "../../../_constance/classes/dndPriorityOffer/TilesIds";
import produce from "immer";

//switch
const IS_MOVING_WITH_ROLLATOR_ALONE = 'is_moving_with_rollator_alone'; 
const IS_MOVING_WITH_ROLLATOR_IN_FLAT_ALONE = 'is_moving_with_rollator_in_flat_alone';
const IS_MOVING_ROLLATOR_OUTSIDE_FLAT = 'is_moving_rollator_outside_flat';

const IS_MOVING_ON_WHEELCHAIR_ALONE = 'is_moving_on_wheelchair_alone';
const IS_MOVING_WHEELCHAIR_IN_APARTMENT_ALONE = 'is_moving_wheelchair_in_apartment_alone';
const IS_MOVING_WHELLCHAIR_OUTSIDE_ALONE = 'is_moving_wheelchair_outside_alone';
//checkboxes
const IS_ROLLATOR_OR_WALKER_LIST = 'is_rollator_or_walker_list';
const IS_WHEELCHAIR = 'is_wheelchair';

//list
const IS_CARRYING_BED_WHEELCHAIR = 'is_carrying_bed_wheelchair';
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles'; 

// filed to store tiles data
const KEY_NAME_TILES = 'is_most_important_taking_care_patient';

class Section8 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-8'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    saveDiabetesAndTiles = () =>{
      const saveDiabetesTileField = this.props.form.getFieldByKey(SKIP_SAVE_STRING_TILES);
      if(saveDiabetesTileField){saveDiabetesTileField.value = true}
    }

    setTileFieldValue = (action, string) =>{
      const { form } = this.props;
      const tilesString = form.getFieldByKey(KEY_NAME_TILES);
      const list = ReqHelper.prepareMeListFromString(tilesString.value, ';');
      if(ReqHelper.notNullAndUndefinied(tilesString.value) && action===true){
          if(list.indexOf(string)<0){
              tilesString.value += ';'+string;
          }
      }else{
          if(action){
              tilesString.value += ';'+string;
          }else{
              if(list.indexOf(string)>-1){
                  tilesString.value += ';'+string;
                  list.splice(list.indexOf(string),1);
                  tilesString.value = list.join(';')
              }
          }
      }
    }

    setTypeChange = (key, value) => {
      const nextState = produce(this.state, draftState => {
          const { section } = draftState;
          const field = section.getFieldByKey(key);
          if (field) {
              field.value = value;
          }
          if(key===IS_CARRYING_BED_WHEELCHAIR){
            if(value===SkillRange.LIMITED){
              this.setTileFieldValue(true, Tiles.TILEFIFTHBED);
              this.saveDiabetesAndTiles();
            }else{
              this.setTileFieldValue(false, Tiles.TILEFIFTHBED);
              this.saveDiabetesAndTiles();
            }
          }
      });
      this.setState(nextState);
      if(key==="is_palliative_care" && value===true){
        setTimeout(() => {
          const importantFieldToScroll = document.getElementById('tumor-or-dekubitus-info-wrapper');
          if(importantFieldToScroll){
            importantFieldToScroll.parentNode.scrollIntoView({ behavior: 'smooth'});
          }
        }, 100);
      }
    };

    setRollTypeChange = (key, value) => {
      this.setTypeChange(key, value);
      switch (key) {
        case IS_MOVING_WITH_ROLLATOR_ALONE:
            this.setTypeChange(IS_MOVING_WITH_ROLLATOR_ALONE, value);
            if(value===IsContactPersonDataSameAs.NO){
              this.setTypeChange(IS_MOVING_WITH_ROLLATOR_IN_FLAT_ALONE, '');
              this.setTypeChange(IS_MOVING_ROLLATOR_OUTSIDE_FLAT, '');
            }else{
              this.setTypeChange(IS_MOVING_WITH_ROLLATOR_IN_FLAT_ALONE, IsContactPersonDataSameAs.NO);
              this.setTypeChange(IS_MOVING_ROLLATOR_OUTSIDE_FLAT, IsContactPersonDataSameAs.NO);
            }
          break;
        case IS_MOVING_ON_WHEELCHAIR_ALONE:
            this.setTypeChange(IS_MOVING_ON_WHEELCHAIR_ALONE, value);
            if(value===IsContactPersonDataSameAs.NO){
              this.setTypeChange(IS_MOVING_WHEELCHAIR_IN_APARTMENT_ALONE, '');
              this.setTypeChange(IS_MOVING_WHELLCHAIR_OUTSIDE_ALONE, '');
            }else{
              this.setTypeChange(IS_MOVING_WHEELCHAIR_IN_APARTMENT_ALONE, IsContactPersonDataSameAs.NO);
              this.setTypeChange(IS_MOVING_WHELLCHAIR_OUTSIDE_ALONE, IsContactPersonDataSameAs.NO);
            }
          break;
        case IS_ROLLATOR_OR_WALKER_LIST:
          if(value===false){
            this.setTypeChange(IS_MOVING_WITH_ROLLATOR_ALONE, '');
            this.setTypeChange(IS_MOVING_WITH_ROLLATOR_IN_FLAT_ALONE, '');
            this.setTypeChange(IS_MOVING_ROLLATOR_OUTSIDE_FLAT, '');
          }else{
            this.setTypeChange(IS_MOVING_WITH_ROLLATOR_ALONE, IsContactPersonDataSameAs.NO);
          }
          break;
        case IS_WHEELCHAIR:
          if(value===false){
            this.setTypeChange(IS_MOVING_ON_WHEELCHAIR_ALONE, '');
            this.setTypeChange(IS_MOVING_WHEELCHAIR_IN_APARTMENT_ALONE, '');
            this.setTypeChange(IS_MOVING_WHELLCHAIR_OUTSIDE_ALONE, '');
          }else{
            this.setTypeChange(IS_MOVING_ON_WHEELCHAIR_ALONE, IsContactPersonDataSameAs.NO);
          }
          break;
        default:
      }
    }

    render() {
        const {
          disabled,
          translateKey,
          showValidationErrors,
          sectionNumber,
          subsectionName,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const isRegularChangeOfPosition = section.getFieldByKey('is_regular_change_of_position');
        const isMove = section.getFieldByKey('is_move');
        const isGettingUp = section.getFieldByKey('is_getting_up');
        const isCarryingBedWheelChar = section.getFieldByKey(IS_CARRYING_BED_WHEELCHAIR);
        const isWalkingUpStairs = section.getFieldByKey('is_walking_up_stairs');
        const isMovingWithRollarAlone = section.getFieldByKey(IS_MOVING_WITH_ROLLATOR_ALONE);
        const isMovingOnWheelChairAlone = section.getFieldByKey(IS_MOVING_ON_WHEELCHAIR_ALONE);
        const isMovingWithRollatorInFlatAlone =  section.getFieldByKey(IS_MOVING_WITH_ROLLATOR_IN_FLAT_ALONE);
        const isMovingRollatorOutsideFlat =  section.getFieldByKey(IS_MOVING_ROLLATOR_OUTSIDE_FLAT);
        const isMovingWheelCharInApartmentAlone =  section.getFieldByKey(IS_MOVING_WHEELCHAIR_IN_APARTMENT_ALONE);
        const isMovingWheelChairOutsideAlone =  section.getFieldByKey(IS_MOVING_WHELLCHAIR_OUTSIDE_ALONE);
        const isBedCare = section.getFieldByKey('is_bed_care');
        const isPalliativeCare = section.getFieldByKey('is_palliative_care');
        const isLyingPatient = section.getFieldByKey('is_lying_patient');

        const isRollatorOrWalkerList = section.getFieldByKey(IS_ROLLATOR_OR_WALKER_LIST);
        const isWheelchair = section.getFieldByKey(IS_WHEELCHAIR);
        const isNewUI = true;

        const classForList = "col-sm-12 col-lg-3 form-margin-bottom-fix text-align-center";
        const classForCheckBox = "newUI-radio-check-box-class";
        const classForMultiCheckbox = 'custom-control-inline newUI-radio-check-box-class cursor-pointer';
        const classForCheckBoxSimple = "newUI-radio-check-box-class";
        const basicCheckClass="col-lg-12";
        const switchWithTextClass="col-lg-12 switch-with-text";

        return (
          <div className="form-section-main">
            <div className="new-section-name-wrapper">
              {sectionNumber}. {subsectionName}
            </div>
            <form className="new-section-content-wrapper container form_serializer">
              <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                {isPalliativeCare.value===true &&
                  <div className="newUI-section-padding important-message-class">
                    <div className="tumor-or-dekubitus-info-wrapper" id="tumor-or-dekubitus-info-wrapper"> 
                      {translateKey("palliativ-stadium-important-message")}
                    </div>
                  </div>
                }
                <div className="newUI-section-padding">
                  <div className="row">
                    <CheckBoxComponent
                      newUI={isNewUI}
                      className={basicCheckClass}
                      checkClassWrapper={classForCheckBox} 
                      showValidation={showValidationErrors}
                      setTypeChangeToParent={this.setTypeChange}
                      value={isLyingPatient.value}
                      validationErrors={isLyingPatient.validationErrors}
                      text={translateKey("form-text-bettlagerig")}
                      transKey="form-text-bettlagerig"
                      keyName="is_lying_patient"
                    />
                  </div>
                  {isLyingPatient.value === true && 
                    <div className="row child-box">
                      <CheckBoxComponent 
                        checkClassWrapper={classForCheckBoxSimple} 
                        newUI={isNewUI} 
                        showValidation={showValidationErrors} 
                        className={basicCheckClass} 
                        setTypeChangeToParent={this.setTypeChange} 
                        value={isBedCare.value} 
                        validationErrors={isBedCare.validationErrors} 
                        text={translateKey("form-text-betreuendeperson")} 
                        transKey="form-text-betreuendeperson" 
                        keyName="is_bed_care"
                      />
                    </div>
                  }
                </div>
                <div className="newUI-section-padding">
                  <div className="row">
                    <CheckBoxComponent
                      newUI={isNewUI}
                      showValidation={showValidationErrors}
                      className={basicCheckClass} 
                      checkClassWrapper={classForCheckBox} 
                      setTypeChangeToParent={this.setTypeChange}
                      value={isRegularChangeOfPosition.value}
                      validationErrors={isRegularChangeOfPosition.validationErrors}
                      text={translateKey("form-text-gelagert")}
                      transKey="form-text-gelagert"
                      keyName="is_regular_change_of_position"
                    />
                  </div>
                  {isRegularChangeOfPosition.value === true && 
                    <div className="row child-box">
                      <CheckBoxComponent
                        newUI={isNewUI}
                        showValidation={showValidationErrors}
                        className={basicCheckClass} 
                        checkClassWrapper={classForCheckBox} 
                        setTypeChangeToParent={this.setTypeChange}
                        value={section.getFieldByKey("is_patient_help_change_position").value}
                        validationErrors={section.getFieldByKey("is_patient_help_change_position").validationErrors}
                        text={translateKey("form-text-mithelfen")}
                        transKey="form-text-mithelfen"
                        keyName="is_patient_help_change_position"
                      />
                    </div>
                  }
                </div>
                <div className="newUI-section-padding">
                  <div className="row">
                    <ListComponent
                        newUI={isNewUI}
                        keyName="is_patient_need_help_with_transfer"
                        field={section.getFieldByKey("is_patient_need_help_with_transfer")}
                        value={section.getFieldByKey("is_patient_need_help_with_transfer").value}
                        validationErrors={section.getFieldByKey("is_patient_need_help_with_transfer").validationErrors}
                        showValidation={showValidationErrors}
                        className={basicCheckClass}
                        transKey="form-text-braucht"
                        type="column"
                        valuesList={[
                            { value: true, label: translateKey("yes") },
                            { value: false, label: translateKey("no") },
                        ]}
                        setTypeChangeToParent={this.setTypeChange}
                    />
                  </div>
                </div>
                <div className="newUI-section-padding">
                  <div className="row">
                    <ListComponent
                        newUI={isNewUI}
                        keyName="is_patient_is_active_during_transfer"
                        field={section.getFieldByKey("is_patient_is_active_during_transfer")}
                        value={section.getFieldByKey("is_patient_is_active_during_transfer").value}
                        validationErrors={section.getFieldByKey("is_patient_is_active_during_transfer").validationErrors}
                        showValidation={showValidationErrors}
                        className={basicCheckClass}
                        transKey="form-text-arbeitet"
                        type="column"
                        valuesList={[
                            { value: true, label: translateKey("yes") },
                            { value: false, label: translateKey("no") },
                        ]}
                        setTypeChangeToParent={this.setTypeChange}
                    />
                  </div> 
                </div>
                <div className="newUI-section-padding">
                  <div className="row">
                    <CheckBoxComponent 
                      checkClassWrapper={classForCheckBoxSimple} 
                      newUI={isNewUI} 
                      showValidation={showValidationErrors} 
                      className={basicCheckClass}  
                      setTypeChangeToParent={this.setTypeChange} 
                      value={isPalliativeCare.value} 
                      validationErrors={isPalliativeCare.validationErrors} 
                      text={translateKey("form-text-palliativ-stadium")} 
                      transKey="form-text-palliativ-stadium" 
                      keyName="is_palliative_care"
                      popover
                      popoverText={translateKey("form-text-palliative-popover")}
                    />
                  </div> 
                </div>  
                <div className="newUI-section-padding">
                  <div className="row">
                    <ListMovementComponent
                      newUI={isNewUI}
                      type="column"
                      icon={IconsMap.other.bewegen_1}
                      showValidation={showValidationErrors}
                      setTypeChangeToParent={this.setTypeChange}
                      className={classForList}
                      transKey="form-text-gehen"
                      key="is_move"
                      keyName="is_move"
                      field={isMove}
                      value={isMove.value}
                      validationErrors={isMove.validationErrors}
                    />
                    <ListMovementComponent
                      newUI={isNewUI}
                      type="column"
                      icon={IconsMap.other.bewegen_2}
                      showValidation={showValidationErrors}
                      setTypeChangeToParent={this.setTypeChange}
                      className={classForList}
                      transKey="form-text-stehen"
                      key="is_getting_up"
                      keyName="is_getting_up"
                      field={isGettingUp}
                      value={isGettingUp.value}
                      validationErrors={isGettingUp.validationErrors}
                    />
                    <ListMovementComponent
                      newUI={isNewUI}
                      type="column"
                      icon={IconsMap.other.bewegen_3}
                      showValidation={showValidationErrors}
                      setTypeChangeToParent={this.setTypeChange}
                      className={classForList}
                      transKey="form-text-transfer"
                      key={IS_CARRYING_BED_WHEELCHAIR}
                      field={isCarryingBedWheelChar}
                      keyName={IS_CARRYING_BED_WHEELCHAIR}
                      value={isCarryingBedWheelChar.value}
                      validationErrors={isCarryingBedWheelChar.validationErrors}
                    />
                    <ListComponent
                      newUI={isNewUI}
                      setTypeChangeToParent={this.setTypeChange}
                      showValidation={showValidationErrors}
                      className={classForList}
                      transKey="form-text-treppen"
                      key="is_walking_up_stairs"
                      keyName="is_walking_up_stairs"
                      field={isWalkingUpStairs}
                      type="column"
                      icon={IconsMap.other.bewegen_4}
                      value={isWalkingUpStairs.value}
                      validationErrors={isWalkingUpStairs.validationErrors}
                      valuesList={[
                        {
                          value: SkillRange.LIMITED,
                          label: translateKey("form-text-uneingeschrankt")
                        },
                        {
                          value: SkillRange.PARTIAL,
                          label: translateKey("form-text-teilweise")
                        },
                        {
                          value: SkillRange.UNLIMITED,
                          label: translateKey("form-text-eingeschrankt")
                        }
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="hilfsmittel-wrapper container">
                    <div className={`form-label-basic cursor-pointer quick-label-position-fix ${isNewUI?'newUI':''}`}>
                      {translateKey("form-text-hilfsmittel").toUpperCase()}
                    </div>
                    <div className="checkbox-button-group-wrapper row">
                      <div className="col-sm-12 col-lg-3">
                        <CheckBoxSmallMultipleComponent
                          newUI={isNewUI}
                          className={classForMultiCheckbox}
                          fieldName={"form-text-antidekubitusmatratze"}
                          setTypeChangeToParent={this.setTypeChange}
                          field={section.getFieldByKey("is_antibedsore_mattress_list")}
                          text={translateKey("form-text-antidekubitusmatratze")}
                          transKey="form-text-antidekubitusmatratze"
                          keyName="is_antibedsore_mattress_list"
                        />
                      </div>
                      <div className="col-sm-12 col-lg-3">
                        <CheckBoxSmallMultipleComponent
                          newUI={isNewUI}
                          className={classForMultiCheckbox}
                          fieldName={"form-text-pflegebett"}
                          setTypeChangeToParent={this.setTypeChange}
                          field={section.getFieldByKey("is_specialist_bed_list")}
                          text={translateKey("form-text-pflegebett")}
                          transKey="form-text-pflegebett"
                          keyName="is_specialist_bed_list"
                        />
                      </div>
                      <div className="col-sm-12 col-lg-3">
                        <CheckBoxSmallMultipleComponent
                          newUI={isNewUI}
                          className={classForMultiCheckbox}
                          fieldName={"form-text-transferlifter"}
                          setTypeChangeToParent={this.setTypeChange}
                          field={section.getFieldByKey("is_transfer_lifter")}
                          text={translateKey("form-text-transferlifter")}
                          transKey="form-text-transferlifter"
                          keyName="is_transfer_lifter"
                        />
                      </div>
                      <div className="col-sm-12 col-lg-3">
                        <CheckBoxSmallMultipleComponent
                          newUI={isNewUI}
                          className={classForMultiCheckbox}
                          fieldName={"form-text-treppenlifter"}
                          setTypeChangeToParent={this.setTypeChange}
                          field={section.getFieldByKey("is_chair_lift")}
                          text={translateKey("form-text-treppenlifter")}
                          transKey="form-text-treppenlifter"
                          keyName="is_chair_lift"
                        />
                      </div>


                      <div className="col-sm-12 col-lg-12">
                        <CheckBoxSmallMultipleComponent
                          newUI={isNewUI}
                          className={classForMultiCheckbox}
                          fieldName={"form-text-rollator"}
                          setTypeChangeToParent={this.setRollTypeChange}
                          field={isRollatorOrWalkerList}
                          text={translateKey("form-text-rollator")}
                          transKey="form-text-rollator"
                          keyName={IS_ROLLATOR_OR_WALKER_LIST}
                        />
                      </div>
                      {isRollatorOrWalkerList.value &&
                        <div className="newUI-section-padding">
                            <SwitchComponent
                              newUI={isNewUI}
                              setTypeChangeToParent={this.setRollTypeChange}
                              showValidation={showValidationErrors}
                              className="col-sm-12"
                              transKey="form-text-fortbewegen-m"
                              key={IS_MOVING_WITH_ROLLATOR_ALONE}
                              keyName={IS_MOVING_WITH_ROLLATOR_ALONE}
                              value={isMovingWithRollarAlone.value}
                              validationErrors={isMovingWithRollarAlone.validationErrors}
                              valueYes={IsContactPersonDataSameAs.YES}
                              valueNo={IsContactPersonDataSameAs.NO}
                            />
                            {isMovingWithRollarAlone.value === IsContactPersonDataSameAs.YES && (
                              <React.Fragment>
                                <SwitchWithTextComponent
                                  setTypeChangeToParent={this.setTypeChange}
                                  showValidation={showValidationErrors}
                                  className={switchWithTextClass}
                                  transKey="form-text-innerhalb"
                                  key={IS_MOVING_WITH_ROLLATOR_IN_FLAT_ALONE}
                                  keyName={IS_MOVING_WITH_ROLLATOR_IN_FLAT_ALONE}
                                  value={isMovingWithRollatorInFlatAlone.value}
                                  validationErrors={isMovingWithRollatorInFlatAlone.validationErrors}
                                  valueYes={IsContactPersonDataSameAs.YES}
                                  valueNo={IsContactPersonDataSameAs.NO}
                                />
                                <SwitchWithTextComponent
                                  setTypeChangeToParent={this.setTypeChange}
                                  showValidation={showValidationErrors}
                                  className={switchWithTextClass}
                                  transKey="form-text-auberhalb"
                                  key={IS_MOVING_ROLLATOR_OUTSIDE_FLAT}
                                  keyName={IS_MOVING_ROLLATOR_OUTSIDE_FLAT}
                                  value={isMovingRollatorOutsideFlat.value}
                                  validationErrors={isMovingRollatorOutsideFlat.validationErrors}
                                  valueYes={IsContactPersonDataSameAs.YES}
                                  valueNo={IsContactPersonDataSameAs.NO}
                                />
                              </React.Fragment>
                            )}
                        </div>         
                      }



                      <div className="col-sm-12 col-lg-12">
                          <CheckBoxSmallMultipleComponent
                            newUI={isNewUI}
                            className={classForMultiCheckbox}
                            fieldName={"form-text-rollstuhl"}
                            setTypeChangeToParent={this.setRollTypeChange}
                            field={isWheelchair}
                            text={translateKey("form-text-rollstuhl")}
                            transKey="form-text-rollstuhl"
                            keyName={IS_WHEELCHAIR}
                          />
                      </div>
                      {isWheelchair.value &&
                        <div className="newUI-section-padding">
                            <SwitchComponent
                              setTypeChangeToParent={this.setRollTypeChange}
                              showValidation={showValidationErrors}
                              className="col-sm-12"
                              transKey="form-text-fortbewegen-im"
                              key={IS_MOVING_ON_WHEELCHAIR_ALONE}
                              keyName={IS_MOVING_ON_WHEELCHAIR_ALONE}
                              value={isMovingOnWheelChairAlone.value}
                              validationErrors={isMovingOnWheelChairAlone.validationErrors}
                              valueYes={IsContactPersonDataSameAs.YES}
                              valueNo={IsContactPersonDataSameAs.NO}
                            />
                            {isMovingOnWheelChairAlone.value === IsContactPersonDataSameAs.YES && (
                              <React.Fragment>
                                <SwitchWithTextComponent
                                  setTypeChangeToParent={this.setTypeChange}
                                  showValidation={showValidationErrors}
                                  className={switchWithTextClass}
                                  transKey="form-text-innerhalb"
                                  key={IS_MOVING_WHEELCHAIR_IN_APARTMENT_ALONE}
                                  keyName={IS_MOVING_WHEELCHAIR_IN_APARTMENT_ALONE}
                                  value={isMovingWheelCharInApartmentAlone.value}
                                  validationErrors={isMovingWheelCharInApartmentAlone.validationErrors}
                                  valueYes={IsContactPersonDataSameAs.YES}
                                  valueNo={IsContactPersonDataSameAs.NO}
                                />
                                <SwitchWithTextComponent
                                  setTypeChangeToParent={this.setTypeChange}
                                  showValidation={showValidationErrors}
                                  className={switchWithTextClass}
                                  transKey="form-text-auberhalb"
                                  key={IS_MOVING_WHELLCHAIR_OUTSIDE_ALONE}
                                  keyName={IS_MOVING_WHELLCHAIR_OUTSIDE_ALONE}
                                  value={isMovingWheelChairOutsideAlone.value}
                                  validationErrors={isMovingWheelChairOutsideAlone.validationErrors}
                                  valueYes={IsContactPersonDataSameAs.YES}
                                  valueNo={IsContactPersonDataSameAs.NO}
                                />
                              </React.Fragment>
                            )}
               
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
            <SectionNavigationButtonsComponent
              form={form}
              analizeFinished={analizeFinished}
              currentSection={currentSection}
              rerender={rerender}
              previousPageClick={previousPageClick}
              nextPageClick={nextPageClick}
              finishMe={finishMe}
              showNotePop={disabled?false:true}
            />
          </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section8));