import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router-dom";
import {
  setLeadsData,
  pendingLeadsData,
  removeLeadsData,
  translateKey,
  authSetActionTimestamp,
  removeLeadStatusData,
  getLeadStatusData,
  getPatientLead,
  setCareBoxIds,
  setCareBoxSets,
  setCareBoxSetsList,
  setGloveType,
  setCareBoxIndividualPrices, removeOneLeadData, getPatientClient
} from "../../_redux/actions/index";
import {
  TableProperties,
  NavBar,
  SideBar,
  LoaderTable,
  LoaderModal,
  AlertModalInfo,
  MultiPatientModal,
  InfoModal
} from "../../_components/index";
import { shouldRefresh } from "../../_helpers/index";
import {
  SectionAngabenZurKontakt,
  Section2,
  Section3,
  SectionCareBox, SectionNotiz
} from "../../_components/form-bb-sections/index";

import CareBoxRodoSection from "../../_components/form-care-box/CareBoxRodoSection/CareBoxRodoSection";
import SectionNavigationToolbarComponent from "../../_components/form-bb-components/section-navigation-toolbar/section-navigation-toolbar";
import axiosAuth from "../../_services/config/axios-auth";
import { refreshTokenTime, baseData } from '../../_constance/base_data';
import { getUserProducts, getRegionsFP } from "../../_redux/actions/bb-form-services";
import BBFormService from '../../_services/bbform';
import * as Entity from '../../_forms/BBForm/values/entity';
import * as Relationship from '../../_forms/BBForm/values/relationship';
import * as FormTypeToShow from '../../_forms/BBForm/values/formTypeToShow';
import * as IsContactPersonDataSameAs from '../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as Sections from '../../_forms/BBForm/values/sections';
import { Alert, FormNavigationActions, FormLoadedState } from "../../_constance/classes/index";
import CareBoxForm from '../../_forms/CareBoxForm/CareBoxForm';
import { FormConfig } from '../../_forms/CareBoxForm/formConfig';
import { ResponseStatus } from '../../_helpers/response_status';
import * as RecordType from "../../_constance/values/recordType";
import { SECTION_OUTSIDE_MENU,  OFFER_SECTION } from "../../_constance/enums/summaryOfferMenuSections";
import { getSectionNameByNumber, getSectionNumberByName } from "../../_constance/enums/sectionPageListCareBox";
import { toDuplicatIngredients } from "../../_forms/BBForm/values/careBoxIngredientsDuplicated";
import { getDynamicFields, getCareBoxSets, getPicklist } from '../../_services/user_service';
import * as CareBoxStatus from '../../_forms/BBForm/values/careBoxStatus';

const LAST_SECTION_KEY = 'is_last_section';
const SKIP_SAVE_IS_DIET_DIABETES = 'skip_save_is_diet_diabetes'; 
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles'; 

class mainCareBoxWrapper extends React.Component {
  _isMounted = false;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      clientLeadBaseData: null,
      editedForm: false,
      currentSection: 0,
      loader: null,
      modalLoader: true,
      savingData: false,
      errorsMessage: null,
      errorsType: "danger",
      errorComponent: Alert,
      LKId: 0,
      patientId: 0,
      products: [],
      regionsFP: [],
      formbbStatus: "0",
      type: FormTypeToShow.FORM,
      toPage: null,
      isImportant: false,
      hideBar: false,
      breadCrumbs: [
        {
          link: "leads",
          key: "leads",
          name: null
        }
      ],
      showValidationErrors: false,
      form: new CareBoxForm(),
      rerender: Date.now(),
      newOne: false,
      leadPatients: [],
      wholeResponse: null,
      sectionMenuOpen: false,
      analizeFinished: true,
      prevPageNumber: 0,
      rodoDisabled: false,
      firstInit: true,
      // final offer variables
      activeSection: SECTION_OUTSIDE_MENU,

      isOfferLoaded: false,
      isOfferOpened: false,

      hideMeAll: false,

      secondPatientForm: new CareBoxForm(),
      secondPatientId: null,
      multiplePatients: false,
      otherPatient: [],
      showMultiPatientModal: false,
      isAditionalPatient: false,
      wasCreateNewPatient: false,
      showModal: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { match: { params } } = this.props;
    let type = params.type;
    if (type !== FormTypeToShow.CONTRACT && type !== FormTypeToShow.ENQUIRY) {
      type = FormTypeToShow.FORM
    }
    this.setState({
      LKId: params.id,
      patientId: params.patientId,
      type: type,
      hideBar: true,
      hideMeAll: true
    })
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.myTimeout = setTimeout(() => {
      this.getInitialDataTime();
    }, 200);
    this.getDataForPflegbox();
  }

  componentDidUpdate() {
    this._promptUnsavedChange(this.state.editedForm)
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };

  toggleFirstInit = () => {
    this.setState({
      firstInit:false
    });
  }

  changeAditionalPateint = (value) => {
    this.setState({
      isAditionalPatient: value,
    });
  }

  toggleFullScreen = () =>{
    const { hideMeAll } = this.state;
    if(hideMeAll){
      this.setState({hideMeAll: false})
      if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen(); 
      }
    }else{
      this.setState({hideMeAll: true})
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } 
    }
  }

  _promptUnsavedChange(isUnsaved = false) {
    window.onbeforeunload = isUnsaved;
  }

  scrollToErrorOrImportantAlert = () =>{
    const section = this.state.form.getSectionByOrder(this.state.currentSection);
    setTimeout(() => {
      const scrollToField = section.fields.find(element => !element.isValid);
      if(scrollToField){
        const elmnt = document.getElementById(`${scrollToField.key}_error_id`);
        if(elmnt){
          elmnt.parentNode.scrollIntoView({ behavior: 'smooth'});
        }
      }else{
        const importantMessageList = document.getElementsByClassName('important-message-class');
        if(importantMessageList.length>0){
          importantMessageList[0].parentNode.scrollIntoView({ behavior: 'smooth'});
        }
      }
    }, 50);
  }

  navigationChangeState = (navObj = new FormNavigationActions()) =>{
    if (this._isMounted) {
      this.setState({
        currentSection: navObj.currentSection,
        savingData: navObj.savingData,
        showValidationErrors: navObj.showValidationErrors,
        isImportant: navObj.isImportant,
        prevPageNumber: navObj.prevPageNumber
      })
      if(navObj.showValidationErrors===true ||  navObj.isImportant === true){
        this.scrollToErrorOrImportantAlert();
      }
    }
    window.scrollTo(0, 0);
  }

  rerender = newPatient => {
    const { secondPatientId } = this.state;

    this.myTimeout = setTimeout(() => {
      this.getInitialDataTime();
    }, 200);

    this.prepareMeForm();

    this.setState({
      modalLoader: true,
      patientId: secondPatientId,
      multiplePatients: true,
      wasCreateNewPatient: newPatient,
    });
  }

  changePage = async number => {
    const { form, currentSection, formbbStatus, isImportant, savingData, showValidationErrors, prevPageNumber } = this.state;
    const isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    const rodoAvailable = this.checkRodoAvaliableSection();
    if (!isFormDisabled) {
      const section = form.getSectionByOrder(currentSection);
      let changePageObjOrAction = new FormNavigationActions(currentSection, savingData, showValidationErrors, isImportant, prevPageNumber);
      let lastSectionField = form.getFieldByKey(LAST_SECTION_KEY);
      let lastSectionValue = getSectionNumberByName(lastSectionField.value);
      let allImportantFieldsFilled = true;
      if (isImportant === false && !section.allImportantsFilled) {allImportantFieldsFilled = false;}
      if(section.isValid && allImportantFieldsFilled){
        let howFarCanWeGo = number;
        // saved change next section 'last_section_value'
        if(number>lastSectionValue){
          //check how far can we go
          let beginSection = 0;
          if (!rodoAvailable) {beginSection = FormConfig.firstPageAfterRodo;}
          for (let i = beginSection; i < number; i++) {
            let tempSection = form.getSectionByOrder(i);
            if (!tempSection.isValid) {
              howFarCanWeGo = i;
              break;
            }
          }
          lastSectionField.value = getSectionNameByNumber(howFarCanWeGo);
        }else{
          if(number<FormConfig.firstPageAfterRodo){
            if(number<=lastSectionValue && currentSection<FormConfig.firstPageAfterRodo){
              howFarCanWeGo = number;
            }else{
              if(!rodoAvailable){
                howFarCanWeGo = FormConfig.firstPageAfterRodo;
              }
            }
          }
        }
        if(howFarCanWeGo!==currentSection){
          if(number<FormConfig.firstPageAfterRodo){
            //user want to go to one of rodo sections
            if(rodoAvailable){
              this.setState({savingData: true})
              const [isSaved] = await this.savePatientWithoutStateChange();
              if(isSaved){
                this.navigationChangeState(new FormNavigationActions(howFarCanWeGo, false, false, false, currentSection));
              }else{
                changePageObjOrAction.isImportant = true;
                changePageObjOrAction.showValidationErrors = true;
                this.navigationChangeState(changePageObjOrAction);
              }
            }else{
              //rodo is not available
              if(howFarCanWeGo<=lastSectionValue){
                // but still in rodo sections
                this.navigationChangeState(new FormNavigationActions(howFarCanWeGo, false, false, false, currentSection));
              }else{
                //but outside of rodo sections
                changePageObjOrAction.savingData = false;
                changePageObjOrAction.isImportant = false;
                this.navigationChangeState(changePageObjOrAction);
              }
            }
          }else{
            //saved correctly and not rodo
            this.setState({savingData: true})
            const [isSaved] = await this.savePatientWithoutStateChange();
            if(isSaved){
              this.navigationChangeState(new FormNavigationActions(howFarCanWeGo, false, false, false, currentSection));
            }else{
              changePageObjOrAction.savingData = true;
              changePageObjOrAction.isImportant = true;
              this.navigationChangeState(changePageObjOrAction);
            }
          }
        }
      }else{
        //await this.savePatientWithoutStateChange();
        if(number<currentSection){
          if(number<FormConfig.firstPageAfterRodo){
            //lastSectionValue
            if(rodoAvailable){
              this.navigationChangeState(new FormNavigationActions(number, false, false, false, currentSection));
            }else{
              if(number<=lastSectionValue && currentSection<FormConfig.firstPageAfterRodo){
                this.navigationChangeState(new FormNavigationActions(number, false, false, false, currentSection));
              }else{
                changePageObjOrAction.savingData = false;
                changePageObjOrAction.isImportant = false;
                changePageObjOrAction.showValidationErrors = false;
                this.navigationChangeState(changePageObjOrAction);
              }
            }
          }else{
            this.navigationChangeState(new FormNavigationActions(number, false, false, false, currentSection));
          }
        }else{
          changePageObjOrAction.savingData = false;
          changePageObjOrAction.isImportant = true;
          changePageObjOrAction.showValidationErrors = true;
          this.navigationChangeState(changePageObjOrAction);
        }
      }
    }else{
      if(number>=FormConfig.firstPageAfterRodo){
        this.navigationChangeState(new FormNavigationActions(number, false, false, false, currentSection));
      }else{
        if(rodoAvailable){
          this.navigationChangeState(new FormNavigationActions(number, false, false, false, currentSection));
        }
      }
    }
  };

  previousPageClick = async () => {
    const { form, currentSection, formbbStatus } = this.state;
    let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if (!isFormDisabled) {
      const [isSaved, LKId, patientId] = await this.savePatient();
      if (isSaved) {
        const { match: { params } } = this.props;
        if (params.patientId === "0" || params.patientId === 0) {
          this.props.getPatientLeadData(this.props.appLang);
          const { history } = this.props;
          history.push(`${baseData.subFolders}bb-form-lead/${LKId}/${patientId}`);
        }
      }
      if (currentSection > FormConfig.initPage) {
        if (this._isMounted) {
          const section = form.getSectionByOrder(currentSection);
          this.setState({
            currentSection: section.prevSection,
            LKId: LKId,
            patientId: patientId,
            isImportant: false,
            prevPageNumber: currentSection
          });
        }
        window.scrollTo(0, 0);
      }
    } else {
      if (currentSection > FormConfig.initPage) {
        if (this._isMounted) {
          const section = form.getSectionByOrder(currentSection);
          this.setState({
            currentSection: section.prevSection,
            isImportant: false,
            prevPageNumber: currentSection
          });
        }
        window.scrollTo(0, 0);
      }
    }
  };

  openMultiplePatientsModal = () => {
    const { match: { params } } = this.props;

    let isFormsCompleated = false;
    const patientsIDs = this.getMeInitLeadPatientsList();

    if (patientsIDs.length < 2) {
      this.setState({
        showMultiPatientModal: true,
      });
    } else {
      BBFormService.retrievePatient(this.state.form.getRetiveDataALl(params.id, patientsIDs)).then(response => {
        const { data } = response;
        const isFormsFill = data.map(element => {
          if(element.entity === 'is_patient') {
            const pageNumber = getSectionNumberByName(element.fields.is_last_section);
            if (pageNumber >= 4) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }).every(item => item);
        isFormsCompleated =  isFormsFill;
      }).then(() => {
        if(isFormsCompleated) {
          this.nextPageClick();
        } else {
          this.setState({
            showMultiPatientModal: true,
          });
        }
      })
    }
  }

  acceptMultiplePatients = async (firstName, lastName) => {
    const { match: { params }, history } = this.props;
    const dataToSend = [{
            "Id": "0",
            "Entity": "is_patient",
            "Fields": {
                "is_contact_id": params.id,
                "is_last_section": "section-care-box-rodo",
                "is_first_name_and_last_name_of_patient": `${firstName} ${lastName}`
            }
        },
        {
            "Id": params.id,
            "Entity": "contact",
            "Fields": {
                "is_patient_last_name": lastName,
                "is_patient_first_name": firstName,
            }
        }];

    try{
      const response = await BBFormService.savePatient(dataToSend);
      let lkid = params.id?params.id:0;
      let patientid = params.patientId?params.patientId:0;
      const { data } = response;
      data.forEach(element => {
        if (element.entity === Entity.PATIENT) {
          patientid = element.id;
        } else {
          if (element.entity === Entity.CONTACT) {
            lkid = element.id;
          }
        }
      });
      history.push(`${baseData.subFolders}bb-form-lead/${lkid}/${patientid}`);
      history.go(0);
    } catch (e) {
      console.log(e);
    }
  }

  redirectToSecondPatient = (patientId) => {
    const { match: { params }, history } = this.props;

    this.setState({
      showMultiPatientModal: false,
      multiplePatients: true,
    });

    history.push(`${baseData.subFolders}bb-form-lead/${params.id}/${patientId}`);
    history.go(0);
  }

  cancelMultiplePatients = () => {
    this.setState({
      showMultiPatientModal: false,
    });

    this.nextPageClick();
  }

  finishFormClick = async () => {
    const { formbbStatus } = this.state;
    let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if (!isFormDisabled) {
      const [isSaved, LKId, patientId] = await this.savePatient();
      if (isSaved) {
        this.props.getPatientClientData(this.props.appLang);
        // await this.changeLeadStatus()
        if (this._isMounted) {
          this.setState({
            LKId: LKId,
            patientId: patientId,
            showModal: true
          });
        }
      }
    }
  }

  closeModalClick = () => {
    const { history } = this.props;
    history.push(`${baseData.subFolders}leads`);
    return true
  }

  nextPageClick = async () => {
    const { form, currentSection, formbbStatus } = this.state;
    let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if (!isFormDisabled) {
      const section = form.getSectionByOrder(currentSection);
      let lastSectionValue = form.getFieldByKey(LAST_SECTION_KEY);
      let lastSectionNumber = getSectionNumberByName(lastSectionValue.value);
      if(lastSectionNumber<section.nextSection){
        lastSectionValue.value = getSectionNameByNumber(section.nextSection);
      }
      const [isSaved, LKId, patientId] = await this.savePatient();
      if (isSaved) {
        const { match: { params } } = this.props;
        if (params.patientId === "0" || params.patientId === 0) {
          this.props.getPatientLeadData(this.props.appLang);
          const { history } = this.props;
          history.push(`${baseData.subFolders}bb-form-lead/${LKId}/${patientId}`);
        }
        if (this._isMounted) {
          this.setState({
            currentSection: section.nextSection,
            LKId,
            patientId,
            prevPageNumber: currentSection
          });
        }
        window.scrollTo(0, 0);
      }
    } else {
      if (currentSection !== FormConfig.finalPageNumber) {
        if (this._isMounted) {
          const section = form.getSectionByOrder(currentSection);
          this.setState({
            currentSection: section.nextSection,
            prevPageNumber: currentSection
          });
        }
        window.scrollTo(0, 0);
      }
    }
  };

  disableSupportFields = () =>{
    const saveDiabetesField = this.state.form.getFieldByKey(SKIP_SAVE_IS_DIET_DIABETES);
    if(saveDiabetesField){saveDiabetesField.value = false}
    const saveDiabetesTileField = this.state.form.getFieldByKey(SKIP_SAVE_STRING_TILES);
    if(saveDiabetesTileField){saveDiabetesTileField.value = false}
  }

  giveMeSpecialToGoSection() {
    const { currentSection, form } = this.state;
    const section = form.getSectionByOrder(currentSection);
    let toPageNumber = section.prevSection;
    if (toPageNumber === Sections.PATIENT) {
      const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
      if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
          toPageNumber = Sections.CLIENT
        } else {
          toPageNumber = Sections.CONTACT_PERSON
        }
      }
    } else {
      if (toPageNumber === Sections.CONTACT_PERSON) {
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
          toPageNumber = Sections.CLIENT
        }
      }
    }
    return toPageNumber;
  }

  clearAlertComponent = (withTimeOut = false) => {
    let timeOutTime = 0;
    if (withTimeOut) {
      timeOutTime = 2000;
    }
    setTimeout(() => {
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            errorComponent: {
              ...prevState.errorComponent,
              show: false,
              type: "danger",
              message: null
            }
          }
        })
      }
    }, timeOutTime);
  }

  showAlertMessage = (type, message) => {
    if (this._isMounted) {
      this.setState(prevState => {
        return {
          ...prevState,
          errorComponent: {
            ...prevState.errorComponent,
            show: true,
            type: type,
            message: message
          }
        }
      })
    }
    this.clearAlertComponent(true);
  }

  getDataForPflegbox = () => {
    getCareBoxSets()
    .then(async res => {
      this.props.setCareBoxSetsList(res);
      const definedBoxesId = res.filter(item => item !== baseData.individualCareBoxId);
      const careBoxSets = await Promise.all(definedBoxesId.map(async item => {
        let dataToSend = [
          {
            Id: item.Id,
            Entity: 'is_care_box_set',
            Fields: [
              "is_protective_aprons_number",
              "is_bed_protectors_number",
              "is_disposable_masks_number",
              "is_disposable_ffp2_masks_number",
              "is_disposable_gloves_number",
              "is_surface_disinfectant_number",
              "is_hand_sanitizer_number",
              "is_reusable_bed_protectors_number",
              "is_disposable_finger_overlay_number",
              "is_surface_disinfectant_1000_number",
              "is_hand_sanitizer_1000_number",
              "is_reusable_protective_aprons_number",
              "is_disinfecting_wipes_number",
              "is_disposable_masks_ffp2_set_number"
            ]
          }
        ];
        return await getDynamicFields(dataToSend)
        .then(res => {
          res[0].fields.id = item.Id;
          return  res[0].fields
        })
        .catch(error => {
          console.log(error);
        });
      }));

      this.props.setCareBoxSets(careBoxSets);
    })
    .then(async () => {
      let dataToSend = [
        {
          Id: baseData.individualCareBoxId,
          Entity: 'is_care_box_set',
          Fields: [
            "is_protective_aprons_return",
            "is_bed_protectors_return",
            "is_disposable_masks_return",
            "is_disposable_masks_ffp2_return",
            "is_one_time_gloves_return",
            "is_surface_disinfectant_500_return",
            "is_hand_sanitizer_500_return",
            "is_reusable_bed_protectors_return",
            "is_disposable_finger_overlay_return",
            "is_surface_disinfectant_1000_return",
            "is_hand_sanitizer_1000_return",
            "is_reusable_protective_aprons_return",
            "is_disinfecting_wipes_return",
            "is_disposable_masks_ffp2_set_return"
          ]
        }
      ];
      const careBoxPricesForIndividualSet =  await getDynamicFields(dataToSend)
        .then(res => {
          return  res[0].fields
        })
        .catch(error => {
          console.log(error);
        });
        this.props.setCareBoxIndividualPrices(careBoxPricesForIndividualSet);
    }).then(() => {
      const gloveTypeJson = {
        "EntityName": "is_care_box_ingridients",
        "AttributeName": "is_one_time_gloves_type"
      };
      getPicklist(gloveTypeJson).then(res => {
        this.props.setGloveType(res.Translations[0].Values);
      }).catch(error => {
        console.log(error)
      })
    }

    )
    .catch(error => {
      console.log(error);
    });
  }

  getCareBoxData = async () => {
    const { match: { params } } = this.props;
    const { form } = this.state;

    const response = await BBFormService.getCareBoxPflegebox(params.patientId);
    const filteredCraeboxes = response.data.filter(item => item.Status == CareBoxStatus.APP_NEW).sort((a, b) => b.CreatedOn - a.CreatedOn);
    if(filteredCraeboxes.length > 0) {
      const filteredItem = filteredCraeboxes[0];
      this.props.setCareBoxIds({careBoxId: filteredItem.Id, ingredientsId: filteredItem.CareBoxIngridientId});
  
      await BBFormService.retrievePatient(form.getRetiveDataCareBox(filteredItem.Id)).then(resCareBox => {
        const { data } = resCareBox;
        data.forEach(entity => {
          let fields = entity.fields
          for(const element in fields) {
            if(element === 'is_pflegegrad') {
              fields.is_pflegegrad_duplicated = fields[element];
              delete fields['is_pflegegrad'];
            }
          }
          form.setFields(fields)
        });
      });
  
      await BBFormService.retrievePatient(form.getRetiveDataCareBoxIng(filteredItem.CareBoxIngridientId)).then(resCareBoxIng => {
        const { data } = resCareBoxIng;
        data.forEach(entity => {
          let fields = entity.fields;
          for(const element in fields) {
            if(toDuplicatIngredients.includes(element)) {
              fields[element.concat('_duplicated')] = fields[element];
              delete fields[element];
            }
          }
  
          form.setFields(fields)
        });
      });
    }
  }

  changeLeadStatus=()=>{
    const { match: { params }, appLang, getPatientLeadData, getPatientClientData, removeLead} = this.props;
    const { form } = this.state;
    const saveSNewtatus = form.changeLeadStatusAfterEndOfForm();
    BBFormService.savePatient(saveSNewtatus).then(response => {
      removeLead(params.id);
    }).catch(error => {
      return false;
    });
  }

  keepCareBoxIdFromResponse = async(section, response) => {
    if(section.key === 'section-care-box-rodo' && (this.props.careBoxId === null || this.props.careBoxId === '')){
      const {data : sectionResponseData } = response;
      const careBoxResponse = sectionResponseData.filter(d => d.entity === 'is_care_box_app');
      if(careBoxResponse.length === 1){
        const savedCareBoxId = careBoxResponse[0].id
        const ingredientsId = await this.saveEmptyIngredients(savedCareBoxId);
        this.props.setCareBoxIds({careBoxId: savedCareBoxId, ingredientsId: ingredientsId})
        return {careBoxId: savedCareBoxId, ingredientsId: ingredientsId}
      }
    }
    return {careBoxId: '', ingredientsId: ''}
  }

  saveEmptyIngredients = async (careBoxId) => {
    const { form } = this.state;
    const { match: { params } } = this.props;
    const nameField = form.getFieldByKey('is_first_name_and_last_name_of_patient')
    const name = nameField.value;
    const contactId = params.id;
    const response = await BBFormService.createEmptyIngredients(careBoxId, contactId, this.props.userId, name)
    const {data} = response
    return data[0].id;
  }

  getCurrentCareBoxId = async (section) => {
    const {match: {params}} = this.props;
    let careBoxId = this.props.careBoxId;
    if (section.key === 'section-care-box' && (careBoxId === null || careBoxId === '')) {
      const response = await BBFormService.getCareBoxPflegebox(params.patientId);
      const filtered = response.data.filter(item => item.Status === '717700000').sort((a, b) => b.CreatedOn - a.CreatedOn);
      if (filtered.length > 0) {
        const filteredItem = filtered[0];
        this.props.setCareBoxIds({careBoxId: filteredItem.Id, ingredientsId: filteredItem.CareBoxIngridientId});
        return filteredItem.Id;
      } else {
        return careBoxId
      }
    } else {
      return careBoxId
    }
  }

  savePatient = async () => {
    const { form, currentSection, formbbStatus, isImportant } = this.state;
    const { match: { params } } = this.props;
    let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if (!isFormDisabled) {
      if (this._isMounted) {this.setState({ savingData: true });}
      const section = form.getSectionByOrder(currentSection);
      let allImportantFieldsFilled = true;
      if (isImportant === false && !section.allImportantsFilled) {
        allImportantFieldsFilled = false;
      }
      if (section.isValid && allImportantFieldsFilled) {
        try {
          const careBoxId = await this.getCurrentCareBoxId(section);
          const isCareBoxSet = CareBoxStatus.resolveIsLeadStatusCareBox(form.getFieldByKey('is_lead_status_care_box').value);
          if(isCareBoxSet === CareBoxStatus.CARE_BOX_INTERESTED_IN && section.key === 'section-care-box') {
            const originalCareBoxSetValue = form.getFieldByKey('is_lead_status_care_box').value;
            if (originalCareBoxSetValue !== isCareBoxSet) form.getFieldByKey('is_lead_status_care_box').value = isCareBoxSet;
          }
          const response = await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, params.patientId, careBoxId));
          await this.keepCareBoxIdFromResponse(section, response)
          if(isCareBoxSet === CareBoxStatus.CARE_BOX_INTERESTED_IN && section.key === 'section-care-box') {
            const responseFromCareBox = await BBFormService.savePatient(form.getCareBox(section.key, careBoxId));
            const { data : dataCareBox } = responseFromCareBox;
            await BBFormService.savePatient(form.getCareBoxIngredients(section.key, dataCareBox[0].id, this.props.careBoxIngredientsId));
          }
          let lkid = params.id?params.id:0;
          let patientid = params.patientId?params.patientId:0;
          const { data } = response;
          data.forEach(element => {
            if (element.entity === Entity.PATIENT) {
              patientid = element.id;
            } else {
              if (element.entity === Entity.CONTACT) {
                lkid = element.id;
              }
            }
          });
          if (this._isMounted) {
            this.setState({
              savingData: false,
              showValidationErrors: false,
              isImportant: false,
            });
          }
          this.disableSupportFields();
          if (currentSection === FormConfig.firstPageAfterRodo) {
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
            if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
              /// pass fields from 1 to third section
              const firstNameValue = form.getFieldByKey('firstname').value;
              const lastNameValue = form.getFieldByKey('lastname').value;
              form.getFieldByKey('is_patient_first_name').value = firstNameValue;
              form.getFieldByKey('is_patient_last_name').value = lastNameValue;        
              form.getFieldByKey('is_first_name_and_last_name_of_patient').value = firstNameValue + " " + lastNameValue;
              form.getFieldByKey('is_service_address_city').value = form.getFieldByKey('is_registered_city').value;
              form.getFieldByKey('is_service_address_house_number').value = form.getFieldByKey('is_registered_house_number').value;
              form.getFieldByKey('is_patient_cellphone').value = form.getFieldByKey('is_mobile_phone').value;
              form.getFieldByKey('is_patient_phone').value = form.getFieldByKey('is_home_phone').value;
              form.getFieldByKey('is_service_address_postal_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
              form.getFieldByKey('is_service_address_street_number').value = form.getFieldByKey('is_registered_street_number').value;
              form.getFieldByKey('is_service_address_street').value = form.getFieldByKey('is_registered_street').value;
              this.simpleSaveWithoutErrors(Sections.PATIENT, patientid);
            }
          }
          return [true, lkid, patientid];
        } catch (e) {
          console.log(e);
          if (this._isMounted) {
            this.setState({
              savingData: false,
              showValidationErrors: false,
              isImportant: false
            });
          }
          return [false];
        }
      } else {
        if (this._isMounted) {
          this.setState({
            savingData: false,
            showValidationErrors: true,
            isImportant: true,
          });
          setTimeout(() => {
            const scrollToField = section.fields.find(element => !element.isValid);
            if(scrollToField){
              const elmnt = document.getElementById(`${scrollToField.key}_error_id`);
              if(elmnt){
                elmnt.parentNode.scrollIntoView({ behavior: 'smooth'});
              }
            }else{
              const importantMessageList = document.getElementsByClassName('important-message-class');
              if(importantMessageList.length>0){
                importantMessageList[0].parentNode.scrollIntoView({ behavior: 'smooth'});
              }
            }
          }, 50);
        }
        return [false];
      }
    } else {
      if (this._isMounted) {
        this.setState({
          savingData: false,
          showValidationErrors: false,
          isImportant: false
        });
      }
      return [true, params.id, params.patientId]
    }
  };

  savePatientWithoutStateChange = async () => {
    const { form, currentSection, formbbStatus, isImportant } = this.state;
    const { match: { params } } = this.props;
    let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if (!isFormDisabled) {
      if (this._isMounted) {this.setState({ savingData: true });}
      const section = form.getSectionByOrder(currentSection);
      let allImportantFieldsFilled = true;
      if (isImportant === false && !section.allImportantsFilled) {
        allImportantFieldsFilled = false;
      }
      if (section.isValid && allImportantFieldsFilled) {
        try {
          const response = await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, params.patientId));
          let lkid = params.id?params.id:0;
          let patientid = params.patientId?params.patientId:0;
          response.data.forEach(element => {
            if (element.entity === Entity.PATIENT) {
              patientid = element.id;
            } else {
              if (element.entity === Entity.CONTACT) {
                lkid = element.id;
              }
            }
          });
          if (currentSection === FormConfig.firstPageAfterRodo) {
            const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
            if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
              /// pass fields from 1 to second section
              form.getFieldByKey('is_contact_person_firstname').value = form.getFieldByKey('firstname').value;
              form.getFieldByKey('is_contact_person_lastname').value = form.getFieldByKey('lastname').value;
              form.getFieldByKey('is_contact_person_mobile_phone').value = form.getFieldByKey('is_mobile_phone').value;
              form.getFieldByKey('is_relationship_with_patient_op').value = isDegreeRelationShipWithPatientCarer.value;
              this.simpleSaveWithoutErrors(Sections.CONTACT_PERSON, patientid);
            }
            if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
              /// pass fields from 1 to third section
              const firstNameValue = form.getFieldByKey('firstname').value;
              const lastNameValue = form.getFieldByKey('lastname').value;
              form.getFieldByKey('is_patient_first_name').value = firstNameValue;
              form.getFieldByKey('is_patient_last_name').value = lastNameValue;
              form.getFieldByKey('is_first_name_and_last_name_of_patient').value = firstNameValue + " " + lastNameValue;
              form.getFieldByKey('is_service_address_city').value = form.getFieldByKey('is_registered_city').value;
              form.getFieldByKey('is_service_address_house_number').value = form.getFieldByKey('is_registered_house_number').value;
              form.getFieldByKey('is_patient_cellphone').value = form.getFieldByKey('is_mobile_phone').value;
              form.getFieldByKey('is_service_address_postal_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
              form.getFieldByKey('is_service_address_street_number').value = form.getFieldByKey('is_registered_street_number').value;
              form.getFieldByKey('is_service_address_street').value = form.getFieldByKey('is_registered_street').value;
              this.simpleSaveWithoutErrors(Sections.PATIENT, patientid);
            } 
          }
          this.disableSupportFields();
          return [true, lkid, patientid];
        } catch (e) {
          return [false];
        }
      }
      return [false]
    }
    return [true, params.id, params.patientId]
  };

  simpleSaveWithoutErrors = async (sectionNumber, patientid) => {
    const { match: { params } } = this.props;
    const { form, formbbStatus } = this.state;
    let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if (!isFormDisabled) {
      const section = form.getSectionByOrder(sectionNumber);
      if (section.isValid) {
        let newPatient = params.patientId;
        if (patientid !== 0 || patientid !== "0") {newPatient = patientid;}
        await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, newPatient));
        this.props.getPatientLeadData(this.props.appLang);
      }
    }
  };

  getMeInitLeadPatientsList() {
    const { match: { params }, patientLeadData } = this.props;
    let pushedElements = [];
    let leadPatientsList = patientLeadData.filter(element => {
      if (element.leadId === params.id) {
        if (pushedElements.indexOf(element.id) > -1) {
          return false;
        } else {
          pushedElements.push(element.id);
          return element
        }
      } else {
        return false;
      }
    })
    if (this._isMounted) {
      this.setState({ leadPatients: leadPatientsList })
    }
    return pushedElements;
  }

  gotBasicDataPrepareWhichPageToShow() {
    this.prepareMeForm();
  }

  checkRodoAvaliableSection() {
    const { form } = this.state;
    const isDataProcessingAgreement = form.getFieldByKey("is_data_processing_agreement");
    if (
      isDataProcessingAgreement.value !== null
    ) {
      let lastSection = form.getFieldByKey(LAST_SECTION_KEY);
      let lastSectionNumber = getSectionNumberByName(lastSection.value);
      if(lastSectionNumber<FormConfig.firstPageAfterRodo){
        return true;
      }else{
        return false;
      }
    }
    return true;
  }

  formLoadedChangeState = (formObj = new FormLoadedState()) =>{
    if (this._isMounted) {
      this.setState({
        currentSection: formObj.currentSection,
        savingData: formObj.savingData,
        modalLoader: formObj.modalLoader,
        showValidationErrors: formObj.showValidationErrors,
        isImportant: formObj.isImportant,
        prevPageNumber: formObj.prevPageNumber,
        rodoDisabled: formObj.rodoDisabled
      })
    }
    window.scrollTo(0, 0);
  }


  prepareMeForm() {
    const { match: { params }, patientLeadData } = this.props;
    const { form, showValidationErrors, currentSection, savingData, modalLoader, prevPageNumber, rodoDisabled, secondPatientForm } = this.state;
    let patientIdList = [];
    if (params.patientId !== 0 && params.patientId !== "0") {
      patientIdList.push(params.patientId);
    }
    const allPatientsforLead = patientLeadData.filter(element => ( element.leadId === params.id) );
    if (allPatientsforLead.length > 1) {
      const otherPatients = allPatientsforLead.filter(element => ( element.id !== params.patientId) ).flat();
      BBFormService.retrievePatient(secondPatientForm.getRetiveDataALl(params.id, [otherPatients[0].id])).then(response => {
        const { data } = response;
        data.forEach(entity => secondPatientForm.setFields(entity.fields));
      });
      this.setState({
        multiplePatients: true,
        otherPatient: otherPatients,
        secondPatientId: otherPatients[0].id,
      });
    } else {
      if(!this.state.wasCreateNewPatient) {
        this.setState({
          multiplePatients: false,
          wasCreateNewPatient: false,
        });
      }
    }
    BBFormService.retrievePatient(form.getRetiveDataALl(params.id, patientIdList)).then(response => {
      const { data } = response;
      data.forEach(entity => form.setFields(entity.fields));
      let readyToGo = true;
      let formState = new FormLoadedState(showValidationErrors, currentSection, savingData, modalLoader, prevPageNumber, rodoDisabled);
      if (params.patientId === "0" || params.patientId === 0) {
          formState = new FormLoadedState(false, 0, false, false, 0, false);
      } else {
        let lastSection = form.getFieldByKey(LAST_SECTION_KEY);
        if(lastSection.value!==null){
          formState = new FormLoadedState(false, getSectionNumberByName(lastSection.value), false, false, 0, !this.checkRodoAvaliableSection());
        }else{
          let beginSection = 0;
          const rodoAvailable = this.checkRodoAvaliableSection();
          if (!rodoAvailable) {
            beginSection = FormConfig.firstPageAfterRodo;
            let lastSectionNumber = form.sections.length;
            for (let i = beginSection; i < lastSectionNumber; i++) {
              let section = form.getSectionByOrder(i);
              if (!section.isValid) {
                readyToGo = false;
                formState = new FormLoadedState(i !== 0, i, false, false, i, true);
                break;
              }
            }
            if (readyToGo) {
              formState = new FormLoadedState(true, FormConfig.finalPageNumber, false, false, FormConfig.finalPageNumber, true);
            }
          } else {
            formState = new FormLoadedState(true, 0, false, false, 0, false);
          }
        }
      }
      this.myTimeout = setTimeout(() => {
        this.formLoadedChangeState(formState);
      }, 3000);
    }).then(() => {
      this.getCareBoxData();
    }).then(() => {
      if (this._isMounted) {
        this.setState({ rerender: Date.now() });
      }
    })
  }

  getInitialDataTime() {
    if (this.props.leadsStatus.length > 0) {
      clearTimeout(this.myTimeout);
      this.getLeadsDataByTimestamp();
    } else {
      if (this.props.leadsStatusStatus !== ResponseStatus.PENDING) {
        this.props.getStatusLead(this.props.appLang);
      }
      this.myTimeout = setTimeout(() => {
        this.getInitialDataTime()
      }, 500);
    }
  }

  getLeadsList = () => {
    this.props.pendingRequestLeads();
    axiosAuth.get("leads/list/0/" + this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        const { match: { params } } = this.props;
        for (let i = 0; i < res.length; i++) {
          if (res[i].leadId === params.id) {
            let name = '---';
            if (res[i].client.lastName !== null) {
              name = res[i].client.lastName;
              if (res[i].client.firstName !== null) {
                name += ' ' + res[i].client.firstName;
              }
            } else {
              if (res[i].client.firstName !== null) {
                name = res[i].client.firstName;
              }
            }
            if (this._isMounted) {
              this.setState({
                LKId: params.id,
                clientLeadBaseData: res[i],
                breadCrumbs: [...this.state.breadCrumbs, {
                  link: "leadDetails/" + params.id,
                  key: "",
                  name: name
                },
                {
                  link: "",
                  key: "",
                  name: "BB"
                }],
                formbbStatus: res[i].status
              })
            }
            this.gotBasicDataPrepareWhichPageToShow();
            break;
          }
        }
      })
      .catch((error) => {
        this.props.clearDataLeads();
        this.getBreadCrumb();
      });
  }

  getLeadsDataByTimestamp() {
    const lastUpdate = this.props.leadsTimestamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getLeadsList();
    } else {
      if (this.props.leadsData.length > 0) {
        this.getBreadCrumb();
      } else {
        this.getLeadsList();
      }
    }
  }

  getBreadCrumb() {
    const { match: { params } } = this.props;
    for (let i = 0; i < this.props.leadsData.length; i++) {
      if (this.props.leadsData[i].id === params.id) {
        let name = '---';
        if (this.props.leadsData[i].client.lastName !== null) {
          name = this.props.leadsData[i].client.lastName;
          if (this.props.leadsData[i].client.firstName !== null) {
            name += ' ' + this.props.leadsData[i].client.firstName;
          }
        } else {
          if (this.props.leadsData[i].client.firstName !== null) {
            name = this.props.leadsData[i].client.firstName;
          }
        }
        if (this._isMounted) {
          this.setState({
            LKId: params.id,
            clientLeadBaseData: this.props.leadsData[i], 
            breadCrumbs: [...this.state.breadCrumbs, {
              link: "leadDetails/" + params.id,
              key: "",
              name: name
            },
            {
              link: "",
              key: "",
              name: "BB"
            }],
            formbbStatus: this.props.leadsData[i].status,
          })
        }
        this.gotBasicDataPrepareWhichPageToShow();
        break;
      }
    }
  }

  toggleAnalizeLoader = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        analizeFinished: !prevState.analizeFinished
      }
    })
  }

  toggleOffAnalizer = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        analizeFinished: true
      }
    })
  }

  toggleOffer = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        isOfferOpened: !prevState.isOfferOpened
      }
    })
  }

  setOfferLoaded = () => {
    this.setState({
      isOfferLoaded: true
    });
  }

  setActiveSection = (section) => {
    this.setState({activeSection: section});
    setTimeout(() => {
      document.body.classList.remove('modal-open');
    }, 100);
  }

  get isOfferButtonVisibleInToolbar() {
    return this.state.activeSection === SECTION_OUTSIDE_MENU;
  }

  get isAnwendedButtonVisibleInToolbar() {
    return this.state.activeSection !== SECTION_OUTSIDE_MENU && this.state.activeSection !== OFFER_SECTION
  }

  getDataBasic(isFormDisabled) {
    const { translateKey } = this.props;
    const { 
      form, 
      rerender, 
      showValidationErrors, 
      isImportant, 
      currentSection, 
      sectionMenuOpen, 
      loader, 
      rodoDisabled, 
      isOfferOpened, 
      isOfferLoaded, 
      activeSection,
      clientLeadBaseData, 
      analizeFinished,
      formbbStatus,
      wholeResponse,
      firstInit,
      modalLoader,
      multiplePatients,
      secondPatientForm,
      secondPatientId,
      isAditionalPatient
    } = this.state;
    const { match: { params } } = this.props;
    let sectionData = (
      <LoaderTable />
    );
    if (!loader) {
      switch (currentSection) {
        case 0:
          sectionData = (
            <div className="section-class-container">
              <CareBoxRodoSection
                firstInit={firstInit}
                rodoDisabled={rodoDisabled}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("form-text-care-box-rodo-title")}
                sectionNumber={1}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                activeSectionNumber={currentSection}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
                toggleFirstInit={this.toggleFirstInit}
                formLoaded = {!modalLoader}
                multiplePatients={ multiplePatients }
                contactId={ params.id }
                patientId={ this.state.patientId }
              />
            </div>
          );
          break;
        case 1:
          sectionData = (
            <div className="section-class-container">
              <SectionAngabenZurKontakt
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("form-text-care-box-ection1-title")}
                sectionNumber={2}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                activeSectionNumber={currentSection}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
                isCareBox={true}
              ></SectionAngabenZurKontakt>
            </div>
          );
          break;
        case 2:
          sectionData = (
            <div className="section-class-container">
              <Section2
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("form-text-care-box-ection2-title")}
                sectionNumber={3}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
                isCareBox={true}
              />
            </div>
          );
          break;
        case 3:
          sectionData = (
            <div className="section-class-container">
              <Section3
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_pflegegrad_data_p_3")}
                sectionNumber={4}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
                isCareBox={true}
              />
            </div>
          );
          break;
        case 4:
          sectionData = (
            <div className="section-class-container">
              <SectionCareBox 
                isImportant={isImportant}
                subsectionName={translateKey("section-care-box")}
                sectionNumber={5}
                form={form}
                showValidationErrors={showValidationErrors}
                disabled={isFormDisabled}
                rerender={rerender}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              />
            </div>
          );
          break;
        case 5:
          sectionData = (<div className="section-class-container">
            <SectionNotiz
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("form-text-notiz")}
                sectionNumber={6}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.finishFormClick}
                hideCommentField={true}
                carebox={true}
            ></SectionNotiz>
          </div>);
          break;
        default:
          sectionData = (
            <div className="section-class-container">
              <CareBoxRodoSection
                firstInit={firstInit}
                rodoDisabled={rodoDisabled}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("form-text-care-box-rodo-title")}
                sectionNumber={1}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                activeSectionNumber={currentSection}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
                toggleFirstInit={this.toggleFirstInit}
                formLoaded = {!modalLoader}
                multiplePatients={ multiplePatients }
                contactId={ params.id }
                patientId={ this.state.patientId }
              />
            </div>
          );
      }
    }

    const infoBox = (
        <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={this.state.showModal} key="care-box-saved-modal-content" type={"success"} message={translateKey("care-box-saved-modal-content")}/>
    )

    if (this.state.showModal === true) {
      clearTimeout(this.myTimeout);
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.closeModalClick();
        }
      }, 3000);
    }

    const content = (
      <React.Fragment>
        <SectionNavigationToolbarComponent
          rerender={rerender}
          showSectionValidation={true}
          changeSection={(number) => this.changePage(number)}
          toggleSectionMenu={this.toggleSectionMenu}
          hideSectionMenu={this.hideSectionMenu}
          form={form}
          currentSection={currentSection}
          sectionMax={FormConfig.finalPageNumber}
          sectionMenuOpen={sectionMenuOpen}
          isUnderAnalize={this.state.analizeFinished}
          // additionalToolbar={currentSection === 5}
          additionalToolbar={false}
          onOpenOffer={this.toggleOffer}
          setOfferLoaded={this.setOfferLoaded}
          isOfferButtonVisibleInToolbar={this.isOfferButtonVisibleInToolbar}
          isAnwendedButtonVisibleInToolbar={this.isAnwendedButtonVisibleInToolbar}
          setActiveSection={this.setActiveSection}
          toggleFullScreen={this.toggleFullScreen}
          type={RecordType.LEAD}
          clientLeadBaseData={clientLeadBaseData}
          secondPatientForm={secondPatientForm}
          isAditionalPatient={isAditionalPatient}
          isCareBoxForm={true}
        />
        {sectionData}
        {infoBox}

      </React.Fragment>
    )
    return content;
  }

  setBBstatus = (status) => {
    if (this._isMounted) {
      this.setState({ formbbStatus: status })
    }
  }

  showSaveModal = () => {
    if (this._isMounted) {
      this.setState({ savingData: !this.state.savingData })
    }
  }

  hideSaveModal = () => {
    if (this._isMounted) {
      this.setState({ savingData: false })
    }
  }

  shouldWeRerenderBasedOnImportant() {
    if (this._isMounted) {
      this.setState({ rerender: Date.now() });
    }
  }

  toggleSectionMenu = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        sectionMenuOpen: !prevState.sectionMenuOpen,
        prevPageNumber: prevState.currentSection === 12 ? 13 : prevState.currentSection
      }
    })
  }

  hideSectionMenu = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        sectionMenuOpen: false,
        prevPageNumber: prevState.currentSection === 12 ? 13 : prevState.currentSection
      }
    })
  }

  getMeClassesToMainContainer=()=>{
    const {  hideMeAll, currentSection, sectionMenuOpen} = this.state;
    let classesForContainer = '';
    if(hideMeAll){
      classesForContainer+='fullScreenClass allow-overflow';
    }else{
      if(currentSection===5 || currentSection===3 || sectionMenuOpen || currentSection===12){
        classesForContainer+='allow-overflow';
      }
    }
    return classesForContainer;
  }

  render() {
    const { formbbStatus, errorComponent, hideBar, hideMeAll, editedForm, modalLoader, savingData, breadCrumbs } = this.state;
    const { translateKey } = this.props;
    const isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    return (
      <div className={`mainContentWrapper ${hideMeAll?'white':''}`} onClick={() => { this.props.tookAction() }}>
        <NavBar breadCrumbs={breadCrumbs} className={`${hideMeAll?'hideMeAllClass':''}`}/>
        <TableProperties className={`${hideMeAll?'hideMeAllClass':''}`} hideBar={hideBar} options={[]} filterData={[]} />
        <div className="mainContent">
          <SideBar className={`${hideMeAll?'hideMeAllClass':''}`}/>
          <LoaderModal show={savingData || modalLoader} text={savingData ? translateKey("saving_with_dots") : ''} />
          <div className={`listDataWrapper ${this.getMeClassesToMainContainer()}`}>
            {this.getDataBasic(isFormDisabled)}
            <AlertModalInfo size="lg" centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message} />
          </div>
        </div>
        <Prompt
          when={editedForm}
          message={translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    leadsData: state.database.leads,
    leadsStatus: state.enums.leadStatus,
    leadsStatusStatus: state.enums.leadStatusStatus,
    patientLeadData: state.database.patientLead,
    careBoxId: state.database.careBoxId,
    careBoxIngredientsId: state.database.careBoxIngredientsId,
    userId: state.auth.userId,
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  setLeadsData: (data) => dispatch(setLeadsData(data)),
  getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
  getPatientClientData: (lang) => dispatch(getPatientClient(lang)),
  pendingRequestLeads: () => dispatch(pendingLeadsData()),
  clearDataLeads: () => dispatch(removeLeadsData()),
  getProducts: () => dispatch(getUserProducts()),
  getRegions: () => dispatch(getRegionsFP()),
  getStatusLead: (lang) => dispatch(getLeadStatusData(lang)),
  removeLeadsStatus: () => dispatch(removeLeadStatusData()),
  removeLead: (id) => dispatch(removeOneLeadData(id)),
  setCareBoxIds: (data) => dispatch(setCareBoxIds(data)),
  setCareBoxSets: (data) => dispatch(setCareBoxSets(data)),
  setCareBoxSetsList: (data) => dispatch(setCareBoxSetsList(data)),
  setGloveType: (data) => dispatch(setGloveType(data)),
  setCareBoxIndividualPrices: (data) => dispatch(setCareBoxIndividualPrices(data)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(mainCareBoxWrapper));