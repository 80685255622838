export const MENU_SECTIONS = {
  SECTION_WHY_PM24: "summary-offer-menu-warum-pm24",
  SECTION_ABOUT_OFFER: "summary-offer-menu-was-im-angebot",
  SECTION_LANGUAGE: "summary-offer-menu-sprache",
  SECTION_ADDITIONAL_OPTIONS: "summary-offer-menu-zusatzliche-pflegeoptionen",
  SECTION_PEOPLE: "summary-offer-menu-ko-piloten",
  SECTION_SUMMARY: "summary-offer-menu-zusammenfassung"
}

export const SUMMARY_OFFER_MENU_SECTIONS = [
  {
    icon: 'question',
    labelKey: MENU_SECTIONS.SECTION_WHY_PM24,
  },
  {
    icon: 'comments',
    labelKey: MENU_SECTIONS.SECTION_LANGUAGE,
  },
  {
    icon: 'angle-double-up',
    labelKey: MENU_SECTIONS.SECTION_ADDITIONAL_OPTIONS,
  },
  {
    icon: 'people-arrows',
    labelKey: MENU_SECTIONS.SECTION_PEOPLE,
  },
  {
    icon: 'calculator',
    labelKey: MENU_SECTIONS.SECTION_SUMMARY,
  }
];

// section "SECTION_WHY_PM24" when offer is not loaded;
export const SECTION_OUTSIDE_MENU = "initial-section";

export const OFFER_SECTION = "offer-section";

export const DIE_BESTELLUNG_SECTION = "die-bestellung";

export const DIE_SUCHANfRAGE = "die-suchanfrage";

export const CANCEL_CONTRACT = "summary-offer-offer-section-die-auf-vertrag-werzichten";