import SortingElement from "./sortingElement";
const SortingDataList = [
    new SortingElement(
        "ASC",
        'taskType',
        true,
        "text",
        'type',
        'tasks_status',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'title',
        false,
        "text",
        'title',
        'tasks_title',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'status',
        false,
        "text",
        'status',
        'tasks_status',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'concernName',
        false,
        "text",
        'concerns',
        'tasks_concern',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'priority',
        false,
        "date",
        'priority',
        'tasks_priority',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'createdDate',
        false,
        "date",
        'created',
        'tasks_createdDate',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'dueDay',
        false,
        "date",
        'due_day',
        'tasks_dueDate',
        true,
        false,
        null
    )
];
export default class SortingTasks {
    sortingColumns = [];

    constructor() {
        this.sortingColumns = SortingDataList;
    }

    getColumnByKey(key) {
        return this.sortingColumns.find(column => column.keyName === key);
    }

    getColumnByColumn(columnName) {
        return this.sortingColumns.find(column => column.column === columnName);
    }
    changeColumnByColumn(columnName) {
        this.sortingColumns.forEach(column => {
            if(column.column === columnName){
                if(column.active===true){
                    column.type==='ASC'? column.type="DESC" : column.type='ASC';
                }else{
                    column.type='ASC';
                    column.active = true;
                }
            }else{
                column.type='ASC';
                column.active = false;
            }
        });
    }

    setColumnEnabled (enabled, key){
        for(let i=0; i<this.sortingColumns.length; i++){
            if(this.sortingColumns[i].keyName===key){
                this.sortingColumns[i].enabled = enabled;
                break
            }
        }
    }

    isColumnActive(key){
        return this.sortingColumns.find(column => column.keyName === key && column.acitve === true);
    }

    isColumnVisibleByKey(key, selectedLang) {
        return this.sortingColumns.find(column => {
            if(column.keyName === key && column.blocked === false && column.enabled === true){
                if(column.languageRestrictions!==null){
                    if(selectedLang === column.languageRestrictions){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return true;
                }
            }else{
                return false;
            }
        });
    }
}