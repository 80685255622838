import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import { InputTypeTextComponent } from "../../form-bb-components/input-type-text-component/input-type-text-component";
import * as LocationRange from "../../../_forms/BBForm/values/locationRange";
import * as HouseType from "../../../_forms/BBForm/values/houseType";
import ListComponent from "../../form-bb-components/list-component/list-component";
import SelectComponent from "../../form-bb-components/select-component/SelectComponent";
import * as MarketingID from '../../../_forms/BBForm/values/marketingId';
import SwitchComponent from "../../form-bb-components/switch-component/switch-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import produce from "immer";

// keys for checkboxes
const IS_SEPARATE_BATHROOM_LIST = 'is_separate_bathroom_list';
const IS_SEPARATE_TOILETT = 'is_separate_toilett';

class SectionBeschreibungDerUnterkunft extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-18'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    setLiveTypeChange = (key, value) => {
        const newValue = value ? '717700004' : value;
        this.setTypeChange(key, newValue);
    };

    setTypeChangePet = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
            if(!value){
                const whichDe = section.getFieldByKey('is_taking_care_of_animals_which_de');
                if (whichDe) {whichDe.value = '';}
                const animalsCare = section.getFieldByKey('is_taking_care_of_animals');
                if (animalsCare) {animalsCare.value = '717700003';}
            }
        });
        this.setState(nextState);
    };


    render() {
        const {
          disabled,
          subsectionName,
          sectionNumber,
          showValidationErrors,
          translateKey,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const isLocation = section.getFieldByKey('is_location');
        const isHouseConditions = section.getFieldByKey('is_housing_conditions');
        const isShoppingAreMadeByFamily = section.getFieldByKey('is_shopping_are_made_by_family');
        const isShoppingOnFoot = section.getFieldByKey('is_shopping_on_foot');
        const isActivitiesAdditionalPeople = section.getFieldByKey('is_activities_additional_people');
        const isPets = section.getFieldByKey('is_pets');
        const isMarketingId = section.getFieldByKey('is_marketingid');
        const isBike = section.getFieldByKey('is_bike');
        const isGarden = section.getFieldByKey('is_garden');
        const isTakingCareAnimals =  section.getFieldByKey('is_taking_care_of_animals');

        //multicheckboxes
        const isSeparateBathroomList = section.getFieldByKey(IS_SEPARATE_BATHROOM_LIST);
        const isSeparateToilett = section.getFieldByKey(IS_SEPARATE_TOILETT);

        const isNewUI = true; 

        const classForCheckBoxSimple = "newUI-radio-check-box-class"

        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListComponent
                                    newUI={isNewUI}
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-3"
                                    transKey="form-text-personlebt"
                                    key="is_location"
                                    keyName="is_location"
                                    field={section.getFieldByKey('is_location')}
                                    type="column"
                                    value={isLocation.value}
                                    validationErrors={isLocation.validationErrors}
                                    valuesList={[
                                        { value: LocationRange.BIGCITY, label: translateKey("form-text-grobstadt-z") },
                                        { value: LocationRange.CITY, label: translateKey("form-text-grobstadt-v") },
                                        { value: LocationRange.SMALLCITY, label: translateKey("form-text-kleinstadt") },
                                        { value: LocationRange.VILLAGE, label: translateKey("form-text-dorf") },
                                    ]}
                                />
                                <ListComponent
                                    newUI={isNewUI}
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-3"
                                    transKey="form-text-question-needed"
                                    key="is_housing_conditions"
                                    keyName="is_housing_conditions"
                                    field={section.getFieldByKey('is_housing_conditions')}
                                    value={isHouseConditions.value}
                                    validationErrors={isHouseConditions.validationErrors}
                                    type="column"
                                    valuesList={[
                                        { value: HouseType.HOUSE, label: translateKey("form-text-einfamilienhaus") },
                                        { value: HouseType.BIGHOUSE, label: translateKey("form-text-mehrfamilienhaus") },
                                        { value: HouseType.APARTMENT, label: translateKey("form-text-Wohnung") },
                                        { value: HouseType.OTHER, label: translateKey("form-text-sonstige-gem") },
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="newUI-section-padding">
                            <div className="row">
                                <CheckBoxComponent checkClassWrapper={classForCheckBoxSimple} newUI={isNewUI} className="col-sm-12 col-lg-6" showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} value={isShoppingAreMadeByFamily.value} validationErrors={isShoppingAreMadeByFamily.validationErrors} text={translateKey("form-text-erledigt")} transKey="form-text-erledigt" keyName="is_shopping_are_made_by_family"/>
                            </div>
                        </div>

                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListComponent
                                    newUI={isNewUI}
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-3"
                                    transKey="form-text-einkaufsmöglichkeiten"
                                    key="is_shopping_on_foot"
                                    keyName="is_shopping_on_foot"
                                    field={section.getFieldByKey('is_shopping_on_foot')}
                                    type="column"
                                    value={isShoppingOnFoot.value}
                                    validationErrors={isShoppingOnFoot.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: translateKey("form-text-ca10min") },
                                        { value: '717700001', label: translateKey("form-text-ca20min") },
                                        { value: '717700002', label: translateKey("form-text-ca30min") },
                                        { value: '717700003', label: translateKey("form-text-more30min") },
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="newUI-section-padding">
                            <div className="row">
                                <div className="col-sm-12">
                                    <CheckBoxComponent checkClassWrapper={classForCheckBoxSimple} newUI={isNewUI} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} value={isActivitiesAdditionalPeople.value} validationErrors={isActivitiesAdditionalPeople.validationErrors} text={translateKey("form-text-mitbewohner")} transKey="form-text-mitbewohner" keyName="is_activities_additional_people"/>
                                    {isActivitiesAdditionalPeople.value===true &&
                                        <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_activities_regarding_additional_people_de')} text={translateKey("form-text-fallsja")} transKey="form-text-fallsja" keyName="is_activities_regarding_additional_people_de"/> 
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="newUI-section-padding">
                            <div className="row">
                                <div className="col-sm-12">
                                    <CheckBoxComponent checkClassWrapper={classForCheckBoxSimple} newUI={isNewUI} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChangePet} value={isPets.value} validationErrors={isPets.validationErrors} text={translateKey("form-text-haustiere")} transKey="form-text-haustiere" keyName="is_pets"/>
                                    {isPets.value===true &&
                                        <>
                                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_taking_care_of_animals_which_de')} text={translateKey("form-text-fallsja")} transKey="form-text-fallsja" keyName="is_taking_care_of_animals_which_de"/> 
                                            <ListComponent
                                            newUI={isNewUI}
                                            setTypeChangeToParent={this.setTypeChange}
                                            showValidation={showValidationErrors}
                                            transKey="form-text-sollendie"
                                            key="is_taking_care_of_animals"
                                            keyName="is_taking_care_of_animals"
                                            field={section.getFieldByKey('is_taking_care_of_animals')}
                                            type="column"
                                            value={isTakingCareAnimals.value}
                                            validationErrors={isTakingCareAnimals.validationErrors}
                                            valuesList={[
                                                { value: '717700000', label: translateKey("yes") },
                                                { value: '717700002', label: translateKey("no") },
                                                { value: '717700003', label: translateKey("contract-text-is-identical-patient-data1") },
                                            ]}
                                            />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
           
                        <div className="newUI-section-padding"> 
                            <div className="form-label-basic newUI cursor-pointer quick-label-position-fix">{translateKey("form-text-zimmerausstattung").toUpperCase()}</div>
                            <div className="checkboxes_wrapper row">
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxComponent 
                                        checkClassWrapper={classForCheckBoxSimple} 
                                        newUI={isNewUI} 
                                        showValidation={showValidationErrors} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        value={isSeparateBathroomList.value} 
                                        validationErrors={isSeparateBathroomList.validationErrors} 
                                        text={translateKey("form-text-eigenesbad")} 
                                        transKey="form-text-eigenesbad" 
                                        keyName={IS_SEPARATE_BATHROOM_LIST}
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxComponent 
                                        checkClassWrapper={classForCheckBoxSimple} 
                                        newUI={isNewUI} showValidation={showValidationErrors} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        value={section.getFieldByKey('is_bed_list').value} 
                                        validationErrors={section.getFieldByKey('is_bed_list').validationErrors} 
                                        text={translateKey("form-text-bett")} 
                                        transKey="form-text-bett"
                                         keyName="is_bed_list"
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxComponent 
                                        checkClassWrapper={classForCheckBoxSimple} 
                                        newUI={isNewUI} 
                                        showValidation={showValidationErrors} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        value={section.getFieldByKey('is_table_list').value} 
                                        validationErrors={section.getFieldByKey('is_table_list').validationErrors} 
                                        text={translateKey("form-text-tisch")} 
                                        transKey="form-text-tisch" 
                                        keyName="is_table_list"
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxComponent 
                                        checkClassWrapper={classForCheckBoxSimple} 
                                        newUI={isNewUI} showValidation={showValidationErrors} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        value={section.getFieldByKey('is_wardrobe_list').value} 
                                        validationErrors={section.getFieldByKey('is_wardrobe_list').validationErrors} 
                                        text={translateKey("form-text-schrank")} 
                                        transKey="form-text-schrank" 
                                        keyName="is_wardrobe_list"
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxComponent 
                                        checkClassWrapper={classForCheckBoxSimple} 
                                        newUI={isNewUI} showValidation={showValidationErrors} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        value={isSeparateToilett.value} 
                                        validationErrors={isSeparateToilett.validationErrors} 
                                        text={translateKey("form-text-radio")} 
                                        transKey="form-text-radio" 
                                        keyName={IS_SEPARATE_TOILETT}
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxComponent 
                                        checkClassWrapper={classForCheckBoxSimple} 
                                        newUI={isNewUI} 
                                        showValidation={showValidationErrors} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        value={section.getFieldByKey('is_television_list').value} 
                                        validationErrors={section.getFieldByKey('is_television_list').validationErrors} 
                                        text={translateKey("form-text-tv")} 
                                        transKey="form-text-tv" 
                                        keyName="is_television_list"
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxComponent 
                                        checkClassWrapper={classForCheckBoxSimple}
                                        newUI={isNewUI} 
                                        showValidation={showValidationErrors} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        value={section.getFieldByKey('is_internet_list').value} 
                                        validationErrors={section.getFieldByKey('is_internet_list').validationErrors} 
                                        text={translateKey("form-text-internet")} 
                                        transKey="form-text-internet" 
                                        keyName="is_internet_list"
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxComponent 
                                        checkClassWrapper={classForCheckBoxSimple} 
                                        newUI={isNewUI} 
                                        showValidation={showValidationErrors} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        value={section.getFieldByKey('is_pc_list').value} 
                                        validationErrors={section.getFieldByKey('is_pc_list').validationErrors} 
                                        text={translateKey("form-text-computer")} 
                                        transKey="form-text-computer" 
                                        keyName="is_pc_list"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="newUI-section-padding">
                            <div className="row">
                                <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_how_often_carer_free_de')} text={translateKey("form-text-geregelt")} transKey="form-text-geregelt" keyName="is_how_often_carer_free_de"/> 
                                <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_who_take_care_when_carer_free_de')} text={translateKey("form-text-werubernimmt")} transKey="form-text-werubernimmt" keyName="is_who_take_care_when_carer_free_de"/> 
                            </div>
                        </div>

                        <div className="newUI-section-padding">
                            <div className="row">
                                <SwitchComponent
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-3"
                                    transKey="form-text-garten"
                                    key="is_garden"
                                    keyName="is_garden"
                                    value={isGarden.value}
                                    validationErrors={isGarden.validationErrors}
                                    valueYes = '717700000'
                                    valueNo = '717700001'
                                />
                                <SwitchComponent
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-3"
                                    transKey="form-text-fahrrad"
                                    key="is_bike"
                                    keyName="is_bike"
                                    value={isBike.value}
                                    validationErrors={isBike.validationErrors}
                                    valueYes = '717700000'
                                    valueNo = '717700001'
                                />
                            </div>
                        </div>

                        <div className="newUI-section-padding">
                            <div className="row">
                                <SelectComponent
                                    newUI={isNewUI} 
                                    className="col-sm-12 col-lg-6"
                                    showValidation={this.state.showValidation}
                                    keyName="is_marketingid"
                                    valuesList={MarketingID.getOptions(translateKey)}
                                    value={isMarketingId.value!==null?isMarketingId.value.toUpperCase():isMarketingId.value}
                                    validationErrors={isMarketingId.validationErrors}
                                    label={translateKey("form-text-from-where-you-know")}
                                    setTypeChangeToParent={this.setTypeChange}
                                />
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                />
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(SectionBeschreibungDerUnterkunft));