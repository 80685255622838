export const LeadSourceBlocked = [
    //dev
    '0ae2872b-4a78-e811-80d7-00155d00f809',
    //prod
    'b7021442-a798-e911-80d1-00155d140163',
    '6ddfcc99-1cad-e911-80d2-00155d2d0e3e',
    '512f5161-a798-e911-80d1-00155d140163',
    'f9e0d487-a798-e911-80d1-00155d140163',
    '67d43453-a798-e911-80d1-00155d140163',
    '522f5161-a798-e911-80d1-00155d140163',
    '0f696795-a798-e911-80d1-00155d140163',
    'f1226248-a798-e911-80d1-00155d140163',
    '2769acce-a798-e911-80d1-00155d140163'
]
