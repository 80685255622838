import * as React from 'react';

export class MailToEmailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.emailAddress
        };
    }

    generateMailTo(emailAddress){
        if(emailAddress){
            if(this.validateEmail(emailAddress)){
                return <a href={"mailto:"+emailAddress}>{emailAddress}</a>
            }else{
                return emailAddress;
            }
        }else{
            return emailAddress;
        }
    }

    validateEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    render() {
        const aComponent = this.generateMailTo(this.props.emailAddress);
        return (
            <span>{aComponent}</span>
         );
    }
}