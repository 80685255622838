import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { MDBPopover, MDBPopoverBody, MDBBtn } from "mdbreact";
import { InputTypeTextAreaComponent } from "../../form-bb-components/input-type-textarea-component/input-type-textarea-component";
import produce from "immer";

class AbsoluteNotizPopoverComponent extends React.Component {
    state = {
      section: this.props.form.getSectionByKey('section-notiz'),
      isVisible: false
    };

    setTypeChange = (key, value) => {
      const nextState = produce(this.state, draftState => {
          const { section } = draftState;
          const field = section.getFieldByKey(key);
          if (field) {
              field.value = value;
          }
      });
      this.setState(nextState);
    };

    togglePopover = (isOpen) =>{
      this.setState({isVisible: isOpen})
    }
    
    render() {
        const { form, translateKey } = this.props;
        const { isVisible } = this.state;
        return (
          <MDBPopover isVisible={this.state.isVisible} placement="top" popover clickable id="popper1" onChange={this.togglePopover}>
            <MDBBtn className='notiz-button-popover'>
              {isVisible?
                  <i className="fas fa-times fa-2x notiz-icon-popover cursor-pointer"></i>
                :
                <React.Fragment>
                  <i className="far fa-clipboard fa-2x notiz-icon-popover cursor-pointer"></i>
                  <span className='popover-notiz-text'>{translateKey('note').toUpperCase()}</span>
                </React.Fragment>
              }
            </MDBBtn>
            <MDBPopoverBody className="notiz-body-popover">
              <InputTypeTextAreaComponent
                newUI={true}
                showValidation={false}
                className=''
                setTypeChangeToParent={this.setTypeChange}
                field={form.getFieldByKey("is_additional_fp_comments")}
                text={null}
                transKey="form-text-notiz"
                keyName="is_additional_fp_comments"
                textAreaClass='popover-notiz-text-area'
              />
            </MDBPopoverBody>
          </MDBPopover>
        );
    }
}
const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default withRouter(connect(null, mapDispatchToProps)(AbsoluteNotizPopoverComponent));