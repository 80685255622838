class CancelationObj {
  type;
  text;
  price;
  title;

  constructor (type, text, price, title='') {
    this.type = type;
    this.text = text;
    this.price = price;
    this.title = title;
  }
}

export default CancelationObj;