import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import { CheckBoxSmallMultipleComponent } from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import { InputTypeTextComponent } from "../../form-bb-components/input-type-text-component/input-type-text-component";
import { CounterWithPeriod } from "../../form-bb-components/index";
import * as IsContactPersonDataSameAs from '../../../_forms/BBForm/values/isContactPersonDataSameAs';
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import SwitchComponent from "../../form-bb-components/switch-component/switch-component";
import { ReqHelper } from "../../../_helpers/index";
import produce from "immer";

class SectionPflegedienst extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-14'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({showValidation: showValidationErrors});
        }
    }

    setTypeChange = (key, value) => {
        if(!this.props.disabled){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });

            this.setState(nextState);
        }
    };

    setTypeIfNumber = (key, value) => {
        if(ReqHelper.isInteger(value)){
            this.setTypeChange(key, value);
        }else{
            if(value.trim()===''){
                this.setTypeChange(key, value);
            }else{
                const { section } = this.state;
                const field = section.getFieldByKey(key);
                if(field){
                    if(field.value.length>value.length){
                        this.setTypeChange(key, '');
                    }
                }

            }
        }
    } 

    render() {
        const {
          disabled,
          showValidationErrors,
          sectionNumber,
          subsectionName,
          translateKey,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          isImportant,
          form
        } = this.props;
        const { section, showValidation } = this.state;
        const isDayCareHome = section.getFieldByKey('is_day_care_home');
        const isEmergencyCallSystem = section.getFieldByKey('is_emergency_call_system');
        const isHowOftenOutpatientHelpDe = section.getFieldByKey('is_how_often_outpatient_help_de');
        const isHomeCareDayFrequencyDe = section.getFieldByKey('is_home_care_day_frequency_de');

        const listClassName = "col-sm-12 col-lg-3 form-margin-bottom-fix";
        const inputClassName = "col-sm-12 col-lg-6 form-margin-bottom-fix";
        const labelClass = "col-sm-12";
        const isNewUI = true; 

        const classForCheckBox = "custom-control-inline illness-wrapper newUI-radio-check-box-class cursor-pointer";
        const classForCheckBoxSimple = "newUI-radio-check-box-class";

        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                        
                        <div className="newUI-section-padding"> 
                            <div className="row"> 
                                <CheckBoxComponent checkClassWrapper={classForCheckBoxSimple} className="col-lg-6" newUI={isNewUI} isImportant={false} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} value={isEmergencyCallSystem.value} validationErrors={isEmergencyCallSystem.validationErrors} text={translateKey("form-text-notrufsystem")} transKey="form-text-notrufsystem" keyName="is_emergency_call_system"/>
                            </div>
                        </div>

                        <div className="newUI-section-padding"> 
                            <div className="row">
                                <CounterWithPeriod
                                    periodList={[
                                        { value: 'Täglich', label: translateKey("form_text_new_counter_tag") },
                                        { value: 'Wöchentlich', label: translateKey("form_text_new_counter_wochen") },
                                    ]}
                                    labelClass={labelClass} 
                                    showValidation={showValidation} 
                                    validationErrors={isHowOftenOutpatientHelpDe.validationErrors}
                                    setTypeChangeToParent={this.setTypeChange} 
                                    field={isHowOftenOutpatientHelpDe} 
                                    label={translateKey("form-text-wie-pflegedienst")} 
                                    transKey="form-text-wie-pflegedienst" 
                                    keyName="is_how_often_outpatient_help_de"
                                    optionValueKey="value"
                                    optionLabelKey="label"
                                    chooseText={translateKey("choose_select")}
                                    contentWrapperClass="col-6"
                                    containerClass="counter-container-class"
                                    counterClass="counter-class"
                                    disabled={disabled}
                                />
                            </div>
                        </div>


                        <div className="newUI-section-padding"> 
                            <div className="row">
                                <InputTypeTextComponent newUI={isNewUI} labelClass={labelClass} showValidation={showValidation} className={inputClassName} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_outpatient_services_list_de')} text={translateKey("form-text-tatigkeiten")} transKey="form-text-tatigkeiten" keyName="is_outpatient_services_list_de"/>
                            </div>
                        </div>

                        <div className={`newUI-section-padding ${(isImportant===true && !section.allImportantsFilled)?'important-fields-new-wrapper':''}`}> 
                            <div className="form-label-basic newUI cursor-pointer quick-label-position-fix">{translateKey("form-text-welche-leistungen").toUpperCase()}</div>
                            <div className="checkboxes_wrapper row">
                                <div className="col-sm-6 col-lg-4">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-behandlungspflege"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_medical_care_sgb_v')} text={translateKey("form-text-behandlungspflege")} transKey="form-text-behandlungspflege" keyName="is_medical_care_sgb_v"/>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-pflegesachleistung"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_benefits_kind_for_sgb_care')} text={translateKey("form-text-pflegesachleistung")} transKey="form-text-pflegesachleistung" keyName="is_benefits_kind_for_sgb_care"/>
                                </div>
                                <div className="col-sm-6 col-lg-4">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-betreuungsleistungen"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_anicillary_services_45b')} text={translateKey("form-text-betreuungsleistungen")} transKey="form-text-betreuungsleistungen" keyName="is_anicillary_services_45b"/>
                                </div>
                            </div>
                        </div>
                        {isImportant===true && !section.allImportantsFilled &&
                            <div className="important-fields-text-wrapper">
                                {translateKey("form_important_fields")}
                            </div>
                        }


                        <div className="newUI-section-padding"> 
                            <div className="row">
                                <SwitchComponent
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className={listClassName}
                                    transKey="form-text-tagespflege"
                                    key="is_day_care_home"
                                    keyName="is_day_care_home"
                                    value={isDayCareHome.value}
                                    validationErrors={isDayCareHome.validationErrors}
                                    valueYes={IsContactPersonDataSameAs.YES}
                                    valueNo={IsContactPersonDataSameAs.NO}
                                />
                            </div>
                        </div>

                        {isDayCareHome.value === IsContactPersonDataSameAs.YES &&
                            <div className="newUI-section-padding"> 
                                <div className="row">
                                    <CounterWithPeriod
                                        periodList={[
                                            { value: 'Täglich', label: translateKey("form_text_new_counter_tag") },
                                            { value: 'Wöchentlich', label: translateKey("form_text_new_counter_wochen") },
                                            { value: 'Monatlich', label: translateKey("form_text_new_counter_mon") }
                                        ]}
                                        labelClass={labelClass} 
                                        showValidation={showValidation} 
                                        validationErrors={isHomeCareDayFrequencyDe.validationErrors}
                                        setTypeChangeToParent={this.setTypeChange} 
                                        field={isHomeCareDayFrequencyDe} 
                                        label={translateKey("form-text-wieoft")} 
                                        transKey="form-text-wieoft" 
                                        keyName="is_home_care_day_frequency_de"
                                        optionValueKey="value"
                                        optionLabelKey="label"
                                        chooseText={translateKey("choose_select")}
                                        contentWrapperClass="col-6"
                                        containerClass="counter-container-class"
                                        counterClass="counter-class"
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        }
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                /> 
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(SectionPflegedienst));