export const LANGUAGE_PACKET = {
  PACKET_100: 'fee_100',
  PACKET_300: 'fee_300',
  PACKET_500: 'fee_500',
  PACKET_750: 'fee_750',
  PACKET_1000: 'fee_1000'
}

const arrayFromNumber = (number) => [...Array(number).keys()];

export const LANGUAGE_PACKETS_DETAILS = {
  [LANGUAGE_PACKET.PACKET_100]: {
    subtitleKeys: arrayFromNumber(2).map(i => `summary-offer-sprache-100-subtitle-${i}`),
    listItemKeys: arrayFromNumber(4).map(i => `summary-offer-sprache-100-item-${i}`),
    video: "https://www.youtube.com/embed/DFsZ3sceW4Q?rel=0" 
  },
  [LANGUAGE_PACKET.PACKET_300]: {
    subtitleKeys: arrayFromNumber(2).map(i => `summary-offer-sprache-300-subtitle-${i}`),
    listItemKeys: arrayFromNumber(4).map(i => `summary-offer-sprache-300-item-${i}`),
    video: "https://www.youtube.com/embed/6JBtsu5zUNQ?rel=0" 
  },
  [LANGUAGE_PACKET.PACKET_500]: {
    subtitleKeys: arrayFromNumber(2).map(i => `summary-offer-sprache-500-subtitle-${i}`),
    listItemKeys: arrayFromNumber(3).map(i => `summary-offer-sprache-500-item-${i}`),
    video: "https://www.youtube.com/embed/35SASceYHX8?rel=0" 
  },
  [LANGUAGE_PACKET.PACKET_750]: {
    subtitleKeys: arrayFromNumber(2).map(i => `summary-offer-sprache-750-subtitle-${i}`),
    listItemKeys: arrayFromNumber(3).map(i => `summary-offer-sprache-750-item-${i}`),
    video: "https://www.youtube.com/embed/2zzZwZZ7GLA?rel=0"  
  },
  [LANGUAGE_PACKET.PACKET_1000]: {
    subtitleKeys: arrayFromNumber(1).map(i => `summary-offer-sprache-1000-subtitle-${i}`),
    listItemKeys: arrayFromNumber(4).map(i => `summary-offer-sprache-1000-item-${i}`),
    video: "https://www.youtube.com/embed/2CrbkmbfMuo?rel=0"  
  }
};

export const getLanguagePacketDetails = (id) => {
  return LANGUAGE_PACKETS_DETAILS[id];
}