import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { translateKey, authSetActionTimestamp, authUpdatedLoginDS } from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, AlertModalInfo } from "../../_components/index";
import { IconsMap } from "../../_helpers/icons_set";
import { Alert} from "../../_constance/classes/index";
import axiosAuth from "../../_services/config/axios-auth";
import { baseData } from '../../_constance/base_data';
import ValidationWithMessage from "../../_helpers/validationWithMessage";
import { getFooterData, sendFooterData } from '../../_services/backend_service';


import { MDBModal, MDBModalBody, MDBModalFooter} from 'mdbreact';
import { SimpleLoader } from "../../_components/index";
// additional styles for profile
import "./profile.css";

class ProfilePage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        username: {
          placehoder: this.props.translateKey("enter_login"),
          value:'',
          validList:[],
          validationRules: {
            minLength: 1,
            maxLength: 50,
            isEmail: true
          }
        },
        password: {
          placehoder: this.props.translateKey("login_password"),
          value:'',
          validList:[],
          validationRules: {
            minLength: 1,
            maxLength: 50
          }
        }
      },
      formSubmitted: false,
      makingCall: false,
      showProfileModal: false,
      errorComponent: Alert,
      loading: true,
      breadCrumbs:[
        {
          link: "profile",
          key: "profile-text",
          name: null
        }
      ],
      wysiwyg: '',
      wysiwygFromDB: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState(prevState => {
      return {
        controls: {
          ...prevState.controls,
          username: {
            ...prevState.controls.username,
            value: this.props.LoginDS,
            validList: ValidationWithMessage(this.props.LoginDS, prevState.controls.username.validationRules)
          },
          password: {
            ...prevState.controls.password,
            value: '',
            validList: ValidationWithMessage('', prevState.controls.password.validationRules)
          }
        }
      };
    });
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.getWysiwygfromDB();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };

  handleChange=(e)=>{
    const { name, value } = e.target;
    if (this._isMounted) {
      this.setState(prevState => {
        return {
          controls: {
            ...prevState.controls,
            [name]: {
              ...prevState.controls[name],
              value: value,
              validList: ValidationWithMessage(value, prevState.controls[name].validationRules)
            }
          }
        };
      });
    }
  };

  saveSettingsData=()=>{
    this.setState({formSubmitted:true, makingCall: true})
    const { translateKey } = this.props;
    const { controls } = this.state;
    if(controls.username.validList.length===0 && controls.password.validList.length===0){
      const login = controls.username.value;
      let credData = {
        LoginDS: login,
        PasswordDS: controls.password.value
      };
      axiosAuth.post("/user/settings/save", credData)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
          this.props.updateLoginDS(login);
          if (this._isMounted) {
            this.setState({
              loading: false,
              showProfileModal: false,
              formSubmitted: false,
              makingCall: false,
              errorComponent: {
                show:true,
                type: "success",
                message: translateKey("profile-pass-saved")
              }
            })
          }
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({ errorComponent: Alert });
            }
          }, 3000);
        })
        .catch((error) => {
          if (this._isMounted) {
            this.setState({
              loading: false,
              makingCall:false,
              errorComponent: {
                show:true,
                type: "danger",
                message: translateKey("profile-pass-failed")
              }
            })
          }
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({ errorComponent: Alert });
            }
          }, 3000);
        });
    }else{
      this.setState({makingCall: false})
    }
  }

  saveWysiwygData = () => {
    this.setState({formSubmitted:true, makingCall: true})
    const { translateKey, userId } = this.props;
    const { wysiwyg } = this.state;
      sendFooterData(userId, wysiwyg)
        .then(res => {
          if (this._isMounted) {
            this.setState({
              loading: false,
              showProfileModal: false,
              formSubmitted: false,
              makingCall: false,
              wysiwyg: wysiwyg,
              wysiwygFromDB: wysiwyg,
              errorComponent: {
                show:true,
                type: "success",
                message: translateKey("profile-pass-saved")
              }
            })
          }
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({ errorComponent: Alert });
            }
          }, 3000);
        })
        .catch((error) => {
          if (this._isMounted) {
            this.setState({
              loading: false,
              makingCall:false,
              errorComponent: {
                show:true,
                type: "danger",
                message: translateKey("profile-pass-failed")
              }
            })
          }
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({ errorComponent: Alert });
            }
          }, 3000);
        });
  }

  getWysiwygfromDB = () => {
    getFooterData(this.props.userId)
    .then(res => {
      if(res && this._isMounted){
        this.setState({
          wysiwyg: res.data.data[0].wysiwyg,
          wysiwygFromDB: res.data.data[0].wysiwyg,
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  toggleProfileModal = () => {
    if (this._isMounted) {
        this.setState({showProfileModal: !this.state.showProfileModal});
    }
}

  onEditorStateChange = (wysiwyg) => {
    this.setState({
      wysiwyg,
    });
  };

  getMeModalWithInputData = () =>{
    const { showProfileModal, controls, formSubmitted, makingCall} = this.state;
    const { translateKey } = this.props;

    return (
      <MDBModal isOpen={showProfileModal} fullHeight size={'lg'} toggle={()=> {}} centered={true}>
          <MDBModalBody>
            <form className="confirm-modal-main-message">
              <div className="form-group">
                  <label htmlFor="docu-login-input-field" className="form-label-basic newUI">{translateKey("login_login")}</label>
                  <input id="docu-login-input-field" type="text" className={"form-control" + (formSubmitted && controls.username.validList.length>0 ? " is-invalid" : "")}
                    placeholder={translateKey("enter_login")}
                    name="username"
                    value={controls.username.value}
                    onChange={this.handleChange}
                    required
                  />
                  {formSubmitted && controls.username.validList.length>0  && (
                    <div className="invalid-feedback" id={`username_error_id`}>
                      { controls.username.validList.map(error => (
                          <div key={`username-error-${error}`}>{translateKey(`form-text-${error}`)}</div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="docu-password-input-field" className="form-label-basic newUI">{translateKey("login_password")}</label>
                  <input id="docu-password-input-field" type="password" className={"form-control" + (formSubmitted && controls.password.validList.length>0 ? " is-invalid" : "")}
                    placeholder={translateKey("login_password")}
                    name="password"
                    value={controls.password.value}
                    onChange={this.handleChange}
                    required
                  />
                  {formSubmitted && controls.password.validList.length>0 && (
                    <div className="invalid-feedback" id={`password_error_id`}>
                      { controls.password.validList.map(error => (
                          <div key={`password-error-${error}`}>{translateKey(`form-text-${error}`)}</div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="wrap">
                    <div className="checkbox-info-subtitle">
                      <i className="fas fa-question-circle fa-1x orange-icon default-btn-hover"/>
                      <span>{translateKey("profile-docu-sign-pass-info")}</span>
                    </div>
                </div>
                {makingCall && (
                  <SimpleLoader />
                )}
            </form>
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" className="btn btn-success" onClick={this.saveSettingsData}>{translateKey('save')}</button>
            <button type="button" className="btn btn-primary" onClick={this.toggleProfileModal}>{translateKey('cancel')}</button>
          </MDBModalFooter>
      </MDBModal>
    )
  }
  render() {
    const { errorComponent, wysiwyg, wysiwygFromDB } = this.state;
    const {translateKey, fpName, LoginDS, FPphone } = this.props;
    return (
      <div className="mainContentWrapper" onClick={() => this.props.tookAction()}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]} filterData={[]} />
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper">
            <div className="information-option-wrapper">
              <AlertModalInfo
                size="lg"
                centeredVertical={true}
                centeredAlert={true}
                show={errorComponent.show}
                key="save-success-fail-message"
                type={errorComponent.type}
                message={errorComponent.message}
              />
              {this.getMeModalWithInputData()}
              <div className="profile-element-wrapper">
                  <div className="row">
                    <div className="col-sm-12 col-lg-5">
                      <div className="basic-information no_margin_bottom">
                        <div className="form-group row flexAlignCenter no-margin-row no_margin_bottom">
                          <div className="col-sm-12 no_padding">
                            <a href={baseData.structureLink} target="_blannk" className="btn btn-success cursor-pointer">
                              {translateKey("structure-of-organization")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div className="main-information-content">
                <div className="profile-element-wrapper">
                  <div className="row">
                    <div className="col-sm-12 col-lg-5">
                      <div className="basic-information">
                        <div className="form-group row flexAlignCenter no-margin-row no-pointer-events">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("profile-name-and-surname")}</label>
                            <div className="col-sm-8">
                                <input type="text" readOnly={true} className="form-control lead-input-wrapper}" onChange={e => {}} value={fpName}/>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="profile-element-wrapper">
                  <div className="row">
                    <div className="col-sm-12 col-lg-5">
                      <div className="basic-information">
                        <div className="form-group row flexAlignCenter no-margin-row no-pointer-events">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("profile-docu-sign-mail-address")}</label>
                            <div className="col-sm-8">
                                <input type="text" readOnly={true} className="form-control lead-input-wrapper}" onChange={e => {}} value={LoginDS}/>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="profile-element-wrapper">
                  <div className="row">
                    <div className="col-sm-12 col-lg-5">
                      <div className="basic-information">
                        <div className="form-group row flexAlignCenter no-margin-row">
                          <label className="col-sm-4 col-form-label form-label-basic">{translateKey("email-signature")}</label>
                          <div className="col-sm-8">
                            <ReactQuill theme="snow" value={wysiwyg} onChange={this.onEditorStateChange}/>
                            { wysiwyg !== wysiwygFromDB && !(wysiwygFromDB === '' && wysiwyg === '<p><br></p>') &&
                              <button type="button" style={{marginTop: "10px"}} className="btn btn-success" onClick={this.saveWysiwygData}>{translateKey("email-signature-save")}</button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-element-wrapper">
                  <div className="profile-section-class">
                    {translateKey("phone")}
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-lg-5">
                      <div className="basic-information">
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("phone")}</label>
                            <div className="col-sm-8">
                                <input type="text" readOnly={true} className="form-control lead-input-wrapper}" onChange={e => {}} value={FPphone !== null ? FPphone : ''}/>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="profile-element-wrapper">
                  <div className="profile-section-class">
                    {translateKey("profile-docu-sign-mail-address-info")}
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-lg-5">
                      <div className="basic-information no_margin_bottom">
                        <div className="form-group row flexAlignCenter no-margin-row no_margin_bottom">
                          <div className="col-sm-12 no_padding">
                            <button type="button" className="btn btn-success cursor-pointer" onClick={this.toggleProfileModal}>
                              {translateKey("profile-login-pass-input")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="profile-element-wrapper">
                  <div className="profile-section-class">
                    {translateKey("profile-app-version")}
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-lg-5">
                      <div className="basic-information">
                        <b>
                          {baseData.releaseDate}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="basic-bottom-section-line" style={{ backgroundImage: "url(" + IconsMap.svg.pasek_big + ")" }}></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    coopId: state.auth.CooperationId,
    fpName: state.auth.contactFpName,
    LoginDS: state.auth.LoginDS,
    FPphone: state.auth.FPphone,
    userId: state.auth.userId
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  updateLoginDS: (loginDS) => dispatch(authUpdatedLoginDS(loginDS))
});
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(ProfilePage));