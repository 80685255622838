import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../../_redux/actions/index";
import KoPilotenMultiCollapse from "./ko-piloten-multi-collapse";

class KoPilotenMultiCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edited: false,
    };
  }

  getMeContent = () => {
    const { form, translateKey, categoryIds} = this.props;
    let newList = [];
    form.categories.forEach(category => {
      if(!category.IsSubCategory){
        newList.push(
          <KoPilotenMultiCollapse
            key={category.CategoryId}
            category={category}
            assignedCategories={categoryIds}
            name={translateKey(category.Name)}
            editCategory={(key,value)=>this.props.editMeCategory(key,value)}
          />
        );
      }
    });
    return newList;
  };

  render() {
    return (
      <React.Fragment>
        <div className='container'>
          <div className="kopilote-title-tab newUI-text-color">
              {this.props.translateKey('ko_piloten_cateogry')}
          </div>
          {this.getMeContent()}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({translateKey: (firstLvl, secondLvl) =>dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(null, mapDispatchToProps)(KoPilotenMultiCategories));