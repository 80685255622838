import * as ValidationError from './errors';
import * as FieldTypes from './fieldTypes';

export const required = value => ({
    isValid: !!value,
    errors: !!value ? [] : [ValidationError.ERR_REQUIRED],
    args: {}
});

export const requiredNotNull = value => ({
    isValid: value !== null,
    errors: value !== null ? [] : [ValidationError.ERR_REQUIRED],
    args: {}
});

export const minLength = (value, minLength) => {
    const isValid = typeof value === "string" && value.length >= minLength;
    const errors = isValid ? [] : [ValidationError.ERR_TEXT_MIN_LENGTH];
    return {
        isValid,
        errors,
        args: {}
    };
};

export const maxLength = (value, maxLength) => {
    const isValid = typeof value === "string" && value.length <= maxLength;
    const errors = isValid ? [] : [ValidationError.ERR_TEXT_MAX_LENGTH];
    return {
        isValid,
        errors,
        args: {}
    };
};

export const fieldType = (value, type, minMaxLenth) => {
    switch (type) {
        case FieldTypes.EMAIL:
            return emailValidator(value);
        case FieldTypes.PHONE_NUMBER:
            return phoneNumberValidator(value);
        case FieldTypes.TEXT:
            return minLength(minMaxLenth);
        default:
            // TODO: add proper handling
            throw new Error('Something went wrong');
    }
};

export const possibleValues = (value, list) => {
    const isValid = list.findIndex(item => item === value) !== -1;
    const errors = isValid ? [] : [ValidationError.ERR_INCORRECT_VALUE];
    return {isValid, errors,  args: {}};
};

export const atLeastOneYes = (value, list) => {
    let isTrue = list.filter((elem) => {
        return elem.value !== elem.neededValue
    })
    let isValid = true;
    let errors = [];
    if (isTrue.length > 0) {
        isValid = false;
        errors = [ValidationError.AT_LEAST_ONE_YES];
        return {isValid, errors,  args: {}};
    } else {
        return {isValid, errors,  args: {}};
    }
};

// Field types validations
const emailValidator = value => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    return {
        isValid,
        errors: isValid ? [] : [ValidationError.ERR_NOT_AN_EMAIL_ADDRESS],
        args: {}
    }
};
const defaultPhoneNumberError = {
    isValid: false,
    errors: [ValidationError.ERR_INCORRECT_PHONE_NUMBER],
    args: {}
};
const phoneNumberValidator = validationValue => {
    const value = validationValue !== undefined && validationValue.hasOwnProperty("value") ? validationValue.value : validationValue;
    const dialCode =  validationValue !== undefined && validationValue.hasOwnProperty("countryData") ? validationValue.countryData.dialCode : undefined;
    if (typeof value !== 'string') {
        return defaultPhoneNumberError;
    }
    if (value[0] !== '+') {
        return {
            isValid: false,
            errors: [ValidationError.ERR_MISSING_COUNTRY_CODE],
            args: {}
        };
    }

    const codeLength = dialCode !== undefined ? dialCode.length + 1 : 3;
    const countryCode = value.substring(0, codeLength);
    const number = value.substr(codeLength);

    if (number[0] === '0') {
        return {
            isValid: false,
            errors: [ValidationError.ERR_INCORRECT_ZERO_PHONE_NUMBER],
            args: {}
        };
    }

    const phoneNumberLengthConfig = new Map();
    phoneNumberLengthConfig.set('any', {min: 5, max: 20});
    phoneNumberLengthConfig.set('+48', {min: 9, max: 9}); // Poland
    phoneNumberLengthConfig.set('+49', {min: 7, max: 20}); // Germany
    phoneNumberLengthConfig.set('+359', {min: 6, max: 8}); // Bulgaria
    phoneNumberLengthConfig.set('+44', {min: 9, max: 10}); // UK
    phoneNumberLengthConfig.set('+40', {min: 9, max: 9}); // Romania

    const validationParams = phoneNumberLengthConfig.has(countryCode) ? phoneNumberLengthConfig.get(countryCode) : phoneNumberLengthConfig.get('any');
    const min = validationParams.min;
    const max = validationParams.max;


    if (number.length < min || number.length > max) {
        const error = min !== max ? ValidationError.ERR_INCORRECT_PHONE_NUMBER_LENGTH_MIN_MAX : ValidationError.ERR_INCORRECT_PHONE_NUMBER_LENGTH
        return {
            isValid: false,
            minLength: min,
            maxLength: max,
            errors: [error],
            args: {min: min, max: max}
        };

    }

    return {
        isValid: true,
        errors: [],
        args: {}
    };
};