import * as React from 'react';
import styled from 'styled-components'

const Separator = () => {
  
  const Separator = styled.div`
    width: 100%;
    height: 1px;
    background: rgb(170,181,193);
    margin: 15px 0;
  `;

  return (
    <Separator />
  )
}

export default Separator