import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import SwitchComponent from "../../form-bb-components/switch-component/switch-component";
import { IconsMap } from '../../../_helpers';
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import produce from "immer";

class SectionKommunikation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-kommunikation-big'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const {
          disabled,
          subsectionName,
          sectionNumber,
          showValidationErrors,
          translateKey,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const isHearingAidList = section.getFieldByKey('is_hearing_aid_list');
        const isHearingAidSelfContainedUse = section.getFieldByKey('is_hearing_aid_self_contained_use');
        const isGlassSelfUse = section.getFieldByKey('is_glasses_self_use');
        const isGlassList= section.getFieldByKey('is_glasses_list');

        const classForItems = "col-sm-12 form-margin-bottom-fix";
        const classForWrap = "col-sm-12 col-md-6 col-lg-3"

        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                        <div className="newUI-section-padding">
                            <div className="row justify-content-md-center">
                                <div className={classForWrap}>
                                    <div className={`tile-class-simple ${isHearingAidList.value===true?'active':''}`} onClick={()=>this.setTypeChange('is_hearing_aid_list', !isHearingAidList.value)}>
                                        <img className="tile-icon-class" src={isHearingAidList.value===false?IconsMap.svg.horegat:IconsMap.svg.horegat_white} alt="Icon"/>
                                        <div className="tile-text-class">{translateKey('form-text-horgerat')}</div>
                                    </div>
                                    {isHearingAidList.value===true &&
                                        <SwitchComponent
                                            setTypeChangeToParent={this.setTypeChange}
                                            showValidation={showValidationErrors}
                                            className={classForItems} 
                                            transKey="form-text-einsetzen"
                                            key="is_hearing_aid_self_contained_use"
                                            keyName="is_hearing_aid_self_contained_use"
                                            value={isHearingAidSelfContainedUse.value}
                                            validationErrors={isHearingAidSelfContainedUse.validationErrors}
                                            valueYes = '717700000'
                                            valueNo = '717700001'
                                        />
                                    }
                                </div>
                                <div className={classForWrap}>
                                    <div className={`tile-class-simple ${isGlassList.value===true?'active':''}`} onClick={()=>this.setTypeChange('is_glasses_list', !isGlassList.value)}>
                                        <img className="tile-icon-class" src={isGlassList.value===false?IconsMap.svg.brille:IconsMap.svg.brille_white} alt="Icon"/>
                                        <div className="tile-text-class">{translateKey('form-text-brille')}</div>
                                    </div>
                                    {isGlassList.value===true &&
                                        <SwitchComponent
                                            setTypeChangeToParent={this.setTypeChange}
                                            showValidation={showValidationErrors}
                                            className={classForItems} 
                                            transKey="form-text-aufsetzen"
                                            key="is_glasses_self_use"
                                            keyName="is_glasses_self_use"
                                            value={isGlassSelfUse.value}
                                            validationErrors={isGlassSelfUse.validationErrors}
                                            valueYes = '717700000'
                                            valueNo = '717700001'
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(SectionKommunikation));