import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MDBModal, MDBModalBody, MDBNav, MDBCloseIcon } from 'mdbreact';
import axiosAuth from '../../_services/config/axios-auth';
import { EmptyTableIcon, AlertModalInfo } from '../../_components/index';
import { saveDynamicFields } from '../../_services/user_service';

const SearchContractModal = ({show, onClose, translateKey, appLang, redirectToLead}) =>  {
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [statusesList, setStatusesList] = useState([]);
    const [errorComponent, setErrorComponent] = useState({
        show: false,
        type: "danger",
        message: translateKey("basic_error_message")
      });

    useEffect(() => {
        axiosAuth.get("lists/leadStatus/" + appLang)
            .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
            .then(json => { return json.data })
            .then(res => {
                setStatusesList(res);
            });
    }, []);

    const search = () => {
        axiosAuth.get("leads/search/" + appLang + "?search=" + encodeURIComponent(searchValue))
        .then(res => setSearchResults(res.data));
    };

    const reactivate = (leadData) => {
        saveDynamicFields([
            {
                "id": leadData.leadId,
                "entity":"contact",
                "fields":
                {
                    "is_customer_status_de":"717700019",
                    "is_reactivation": "717700000"
                }
            }
        ])
        .then(res => {
            if(Array.isArray(res)) {
                setErrorComponent({ 
                     show: true,
                     type: "success",
                     message: translateKey("modal_reactivation_success")
                 });
                 setTimeout(() => {
                     setErrorComponent({ 
                         show: false,
                         type: "danger",
                         message: translateKey("basic_error_message")
                     });
                     redirectToLead();
                 }, 3000);
            } else {
                setErrorComponent({ 
                    show: true,
                    type: "danger",
                    message: translateKey("basic_error_message")
                });
                setTimeout(() => {
                    setErrorComponent({ 
                        show: false,
                        type: "danger",
                        message: translateKey("basic_error_message")
                    });
                }, 3000);
            }
        });
    }

    const prepareStatus = (status) => statusesList.filter(item => item.Value == status)[0].Label;


    return (  
        <MDBModal isOpen={show} fullHeight size="lg" centered={true} className="search-contract-popup-holder">
            <MDBNav className="search-contract-popup-nav">
                <MDBCloseIcon onClick={onClose}></MDBCloseIcon>
            </MDBNav>
            <MDBModalBody className="search-contract-popup-body">
                <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message}/>
                <p className="top-search-title">
                    {translateKey("modal_search_title")}
                </p>
                <div className="top-search">
                    <div className="input-group search-table-wrapper">
                        
                        <div className="input-group-prepend">
                            <i className="fas fa-search norbsoft-filter-icon"></i>
                        </div>
                        <input
                            id="search-query-table"
                            type="text"
                            className="form-control norbsoft-search-input"
                            placeholder={translateKey("search_bar_title")}
                            name="searchQuery"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && search()}
                            required
                        />
                    </div>
                    <div className="search-button">
                        <button className="btn btn-primary" onClick={search}>
                            {translateKey("search_bar_title")}
                        </button>
                    </div>
                </div>
                <div className="search-results">
                    {searchResults.length > 0 &&
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">{ translateKey("first_name") }</th>
                                    <th scope="col">{ translateKey("last_name") }</th>
                                    <th scope="col">{ translateKey("fp_acceptance_status") }</th>
                                    <th scope="col">{ translateKey("reactivate_btn") }</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults.map((item) => 
                                    <tr className={"table-row-wrapper cursor-pointer"} key={item.leadId} >
                                        <td className={"table-cell-wrapper got-data"}>{item.firstname}</td>
                                        <td className={"table-cell-wrapper got-data"}>{item.lastname}</td>
                                        <td className={"table-cell-wrapper got-data"}>{prepareStatus(item.status)}</td>
                                        { item.status != '717700006' ? 
                                            <td className={"table-cell-wrapper got-data"}>
                                                <button 
                                                    type="button"
                                                    styles="z-index: 1000000"
                                                    className="btn btn-danger cursor-pointer"
                                                    onClick={() => reactivate(item)}
                                                >
                                                    { translateKey("reactivate_btn") }
                                                </button>
                                            </td>
                                            : <td></td>
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
                    {searchResults.length === 0 &&
                        <EmptyTableIcon 
                            RefreshMe={() => search()} 
                            text={translateKey("modal_search_no_results")}
                        />
                    }
                </div>
            </MDBModalBody>
        </MDBModal>
    )
};

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
    };
};
  
export default connect(mapStateToProps, null)(SearchContractModal)