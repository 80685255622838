import * as React from 'react';
import { MDBModal, MDBModalBody, MDBNav, MDBCloseIcon } from 'mdbreact';
import {baseData} from '../../_constance/base_data';

export class CarerModal extends React.Component {

    render() {
        return (  
            <MDBModal isOpen={this.props.show} toggle={this.reject} fullHeight size="lg" centered={true} className="carer-popup-holder">
                <MDBNav className="carer-popup-nav">
                    <MDBCloseIcon onClick={this.props.onClose}></MDBCloseIcon>
                </MDBNav>
                <MDBModalBody className="carer-popup-body">
                    <iframe className="cv-iframe" src={`${baseData.base_backend}${this.props.pdfLink}`} frameBorder="0"></iframe>
                </MDBModalBody>
            </MDBModal>
        )
    }
}
