import * as React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { translateKey } from '../../../../_redux/actions/index';
import { CheckBoxComponent } from "../../../form-bb-components/checkbox-component/checkbox-component";
import { IconsMap } from "../../../../_helpers/icons_set";

import { formatPrice } from '../../../../_helpers/price_format';

import { ADDITIONAL_OPTIONS } from '../../../../_constance/enums/summaryOfferAdditionalOptions';
import { PriceType } from '../../../../_constance/enums/priceType';

//key of two patient checkbox
const FEE_2NDPAT = 'fee_2ndpat';

class AdditionalOptionsSection extends React.Component {
  static propTypes = {
    recommendedAdditionalOptions: PropTypes.array.isRequired,
    selectedAdditionalOptions: PropTypes.array.isRequired,
    setAdditionalOption: PropTypes.func.isRequired,
    availableAdditionalOptions: PropTypes.array.isRequired
  }

  state = {
    isDetailsActive: false,
    optionDetailsId: undefined
  }

  toggleDetails = (id) => {
    this.setState({
      isDetailsActive: !this.state.isDetailsActive,
      optionDetailsId: id
    })
  }

  renderAdditionalOptionBox = (packet) => {
    const { 
      translateKey, 
      setAdditionalOption, 
      selectedAdditionalOptions, 
      recommendedAdditionalOptions,
      priceType,
      secondPatientName,
      setMultipleClientsForCheckbox
    } = this.props;

    const isPacketSelected = selectedAdditionalOptions.findIndex(id => id === packet.id) >= 0;
    const isRecommendedOption = recommendedAdditionalOptions.findIndex(id => id === packet.id) >= 0;
    let price = '';
    if(priceType === PriceType.MONTHLY) {
      price = packet.price
    } else if (priceType === PriceType.DAILY){
      price = packet.price/30;
    } else {
      price = '-'
    }

    return (
      <div
        key={packet.id}
        className={`additional-option-pack-box ${isPacketSelected && 'additional-option-pack-box-selected'}`}
      >
        {isRecommendedOption && <div className="additional-option-pack-box-selected-label">
          {translateKey("summary-offer-sprache-empfohlen")}
        </div>}
        <CheckBoxComponent
          keyName={packet.id}
          className="additional-option-pack-check-box" 
          disabled={false}
          value={isPacketSelected} 
          setTypeChangeToParent={ packet.id === FEE_2NDPAT ? setMultipleClientsForCheckbox : setAdditionalOption }
        />
        <div className={`additional-option-pack-box-label pt-3 ${isPacketSelected && 'blue-icon'}`}>
          {translateKey(packet.label)} <br />
        </div>
        { (secondPatientName && packet.id === FEE_2NDPAT && isPacketSelected) &&
          <div className="additional-option-pack-box-patient-name">
            { secondPatientName }
          </div>
        }
        <div className={`additional-option-pack-box-price pt-5 pb-5 ${isPacketSelected && 'blue-icon'}`}>
        { priceType !== PriceType.NONE ?
          <>
            {'+ '}
            {formatPrice(price?price:0)}
            {packet.id === 'fee_addperson' ? ' **' : ' *'}
          </> : ''
        }
        </div>
        <div role="button" onClick={() => this.toggleDetails(packet.id)} >
          <i className={`far fa-file fa-3x default-btn-hover ${isPacketSelected ? 'green-icon' : 'grey-icon'}`} />
        </div>
      </div>
    );
  }

  render() {
    const { translateKey, availableAdditionalOptions, priceType } = this.props;
    const { isDetailsActive } = this.state;

    if (isDetailsActive) {
      const { optionDetailsId } = this.state;
      return (
        <div className="video-wrapper d-flex flex-column w-100 m-6">
          <div 
            role="button" 
            className="d-flex justify-content-end default-btn-hover" 
            onClick={() => this.toggleDetails(undefined)}
          >
            <img src={IconsMap.other.close_icon} alt="other"/>
          </div>
          <div className="d-flex flex-column">
            {optionDetailsId !== ADDITIONAL_OPTIONS.OPTION_5 ? (
              <>
                <div className="section-title">
                  {translateKey(`${optionDetailsId}`)}
                </div>
                <div className="section-item">
                  {translateKey(`${optionDetailsId}-subtitle`)}
                  {`: `}
                </div>
                {availableAdditionalOptions.find(item => item.id === this.state.optionDetailsId).listItemKeys.map((item, index) => (
                  <div key={index} className="section-item">
                    <i className="fas fa-check fa-1x mr-md-2" />
                    {translateKey(item)}
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="section-title">
                  {translateKey(`${optionDetailsId}`)}
                </div>
                <div className="section-item">
                  <i className="fas fa-check fa-1x mr-md-2" />
                  {translateKey("summary-offer-additional-option-5-item-0")}
                </div>
                <b><div className="section-item">
                  {translateKey("summary-offer-additional-option-5-subtitle")}
                  {`: `}
                </div></b>
                {availableAdditionalOptions.find(item => item.id === this.state.optionDetailsId).listItemKeys.map(((item, index) => (
                  index > 0 && (<div key={index} className="section-item">
                    <i className="fas fa-check fa-1x mr-md-2" />
                    {translateKey(item)}
                  </div>
                ))))}
              </>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid no-gutters p-0 d-flex flex-column h-100">
        <div className="row w-100 no-gutters">
          <div className="sprache-container col-12 d-flex justify-content-center flex-wrap">
            {availableAdditionalOptions.map((option) => this.renderAdditionalOptionBox(option))}
          </div>
        </div>
        <div className="row w-100 d-flex justify-content-center no-gutters">
          { priceType !== PriceType.NONE ?
            <div className="section-item">
              {`* `}
              { priceType === PriceType.MONTHLY ? translateKey("summary-offer-sprache-footer") : translateKey("summary-offer-sprache-footer-daily-payment") }
            </div>
            : ''
          }
        </div>
        <div className="row footer-center d-flex justify-content-center no-gutters">
            <div className="section-item">
              {`** `}
              {translateKey("summary-offer-additional-option-6-item-0")}
            </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    priceType: state.priceType.priceType,
  }
};

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalOptionsSection);