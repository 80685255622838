import * as React from 'react';
import styled from 'styled-components'

const InfoBoxHolder = styled.div`
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  padding: 14px 30px;
  border-color: rgb(170,181,193);
  background-color: #e9ecef;
  background-clip: padding-box;
  border: 1px solid rgb(170,181,193);
  border-radius: 10px;
  margin: 7px;
`;

const Icon = styled.i`
  margin-right: 10px;
`;

const InfoBox = ({ type, name}) => {
  return (
    <InfoBoxHolder>
        {type === 'pdf' ? <Icon className="fas fa-file-pdf" /> : null}{ name }
    </ InfoBoxHolder>
  )
}

export default InfoBox