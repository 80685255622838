export const SubCategoryList = [
    {
        label: 'Domowy system powiadamiania alarmowego', 
        translabel: 'Hausnotrufsystem',
        key: 'ko-piloten-category-hausnotrufsystem'
    },
    {
        label: 'Nieodpłatne środki pomocnicze do pielęgnacji',
        translabel: 'Kostenlose Pflegehilfsmittel',
        key: 'ko-piloten-category-kostenlose'
    },
    {
        label: 'Sklep ze sprzętem medycznym,/rehabilitacyjnym',
        translabel: 'Sanitätshaus',
        key: 'ko-piloten-category-sanitatshaus'
    },
    {
        label: 'Wózek inwalidzki/ krzesło toaletowe', 
        translabel: 'Rollstuhl / Toilettenstuhl',
        key: 'ko-piloten-category-rollstuhl'
    },
    {
        label: 'Podwyższenie do toalety', 
        translabel: 'Toilettensitzerhöhung',
        key: 'ko-piloten-category-toilettensitzerhohung'
    },
    {
        label: 'Łóżko specjalistyczne', 
        translabel: 'Pflegebett und Zubehör',
        key: 'ko-piloten-category-pflegebett'
    },
    {
        label: 'Podnośnik', 
        translabel: 'Patientenlifter',
        key: 'ko-piloten-category-patientenlifter'
    },
    {
        label: 'Chodzik', 
        translabel: 'Rollator',
        key: 'ko-piloten-category-rollator'
    },
    {
        label: 'Podnośnik do wanny', 
        translabel: 'Badewannenlifter',
        key: 'ko-piloten-category-badewannenlifter'
    },
    {
        label: 'Krzesło pod prysznic/ na wannę', 
        translabel: 'Badewannen- o. Duschsitz',
        key: 'ko-piloten-category-badewannen'
    },
    {
        label: 'Podnośnik na schody', 
        translabel: 'Treppenlift',
        key: 'ko-piloten-category-treppenlift'
    },
    {
        label: 'Dostawa leków', 
        translabel: 'Arzneimittel Lieferservice',
        key: 'ko-piloten-category-arzneimittel'
    },
    {
        label: 'Lekarz domowy', 
        translabel: 'Hausarzt',
        key: 'ko-piloten-category-hausarzt'
    },
    {
        label: 'Pomoc sąsiedzka', 
        translabel: 'Nachbarschaftshilfe',
        key: 'ko-piloten-category-nachbarschaftshilfe'
    },
    {
        label: 'Zakupy', 
        translabel: 'Einkauf',
        key: 'ko-piloten-category-einkauf'
    },
    {
        label: 'Jedzenie z dostawą', 
        translabel: 'Essen auf Räder',
        key: 'ko-piloten-category-essen'
    },
    {
        label: 'Internet', 
        translabel: 'Internet',
        key: 'ko-piloten-category-internet'
    },
    {
        label: 'Przebudowa', 
        translabel: 'Umbaumaßnahmen',
        key: 'ko-piloten-category-mmbaumabnahmen'
    },
    {
        label: 'Transport osób', 
        translabel: 'Fahrservice',
        key: 'ko-piloten-category-fahrservice'
    },
    {
        label: 'Podolog', 
        translabel: 'Podologen',
        key: 'ko-piloten-category-podologen'
    },
    {
        label: 'Allgemeine Versorgung', 
        translabel: 'Allgemeine Versorgung',
        key: 'ko-piloten-category-allgemeine',
    },
    {
        label: 'Logopeda', 
        translabel: 'Logopädie',
        key: 'ko-piloten-category-logopadie'
    },
    {
        label: 'Terapia zajęciowa', 
        translabel: 'Ergotherapie',
        key: 'ko-piloten-category-ergotherapia'
    },
    {
        label: 'Fizjoterapeuta', 
        translabel: 'Physiotherapie',
        key: 'ko-piloten-category-physiotherapia'
    }
];