import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { translateKey, authSetActionTimestamp, addNewMultiplierData, needsSaveData, doesNotNeedsSave} from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, AlertMessage, MultiplierComponent , LeadCancelSaveComponent } from "../../_components/index";
import { Alert} from "../../_constance/classes/index";
import { IconsMap } from "../../_helpers/index";
import { multiplierSave } from "../../_redux/actions/auth";
import { MockMultiplier } from "../../_constance/classes/mockMultiplier/mockMultiplier";
import { baseData } from '../../_constance/base_data';
import * as LeadTabList from "../../_constance/values/leadTabList";
import KoPilotenMultiplierCategoryComponentNewMulti from '../../_components/koplioten-multi-category-component/Ko-Piloten-Multiplier-Category-Component-New-Multi';
import { saveMultiplierCategory } from "../../_services/kopiloten_service";

class NewMultiplierModalPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  autoSaveTimeOut;
  constructor(props) {
    super(props);
    this.state = {
      multiplierData: {...MockMultiplier},
      savingMultiplier: false,
      savingKopiloten: false,
      loadingMultiplier: true,
      errorsMessage: null,
      errorsType: "danger",
      errorComponent: Alert,
      breadCrumbs:[
        {
          link: "multipliers",
          key: "multipliers",
          name: null
        },
        {
          link: "newmultiplier",
          key: "new_multiplier",
          name: null
        }
      ],
      activeTab: LeadTabList.MULTIPLIER,
      categoryIds: [],
      editedKopiloten: false,
    };
  }

  componentDidUpdate() {
    if (this.props.needsSave) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.props.doesNotNeedsSave();
    if (this._isMounted) {
      this.setState({
        loadingMultiplier: false,
        multiplierData: JSON.parse(JSON.stringify(MockMultiplier))
      })
    }
    this.autoSaveTimeOut = setInterval(() => {
      this.checkIfShouldWeSave();
    }, 15000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.onbeforeunload = null
    if(this.props.needsSave){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              multiplierData: {...MockMultiplier}
          }
        });
      }
    }
    clearInterval(this.autoSaveTimeOut);
    clearTimeout(this.myTimeout);
  };

  checkIfShouldWeSave = () =>{
    const currentTime = new Date().getTime();
    if(this.props.lastActionTime - currentTime <= 30000){
      this.saveChange(true);
    }
  }

  cancelChange(){
    this.props.doesNotNeedsSave();
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            multiplierData: MockMultiplier
        }
      });
    }
  }

  saveChange(shouldLogout=false){
    const { history } = this.props;
    if(this.state.categoryIds.length > 0) {
      this.props.tookAction();
      if(this.props.needsSave){
        if (this._isMounted) {
          this.setState({savingMultiplier: true})
        }
        if(this.state.multiplierData.multiplierQuality){
          this.props.saveMultiplierData(this.state.multiplierData, this.props.appLang)
          .then((res)=>{
            this.props.doesNotNeedsSave();
            if(shouldLogout){
              history.push(`${baseData.subFolders}logout`);
            }else{
              if (this._isMounted) {
                this.setState({
                  savingMultiplier: false,
                  errorsMessage: "multiplier_successfully_saved",
                  errorsType: "success",
                })      
              }
              clearTimeout(this.myTimeout);
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({
                    errorsMessage: null,
                    multiplierData: MockMultiplier,
                    errorsType: "danger"
                  })
                }
                this.props.selectedMultiplier(res);
              }, 3000);
              this.props.addMultiplierData(res,this.state.multiplierData);
            }
            this.saveKopiloten(res);
          })
          .catch((err)=>{
            if(shouldLogout){
              history.push(`${baseData.subFolders}logout`);
            }else{
              if (this._isMounted) {
                this.setState(prevState => {
                  return {
                      ...prevState,
                      savingMultiplier: false,
                      errorsMessage: err.message,
                      errorsType: "danger"
                  }
                });
              }
              clearTimeout(this.myTimeout);
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({
                    errorsMessage: null,
                    errorsType: "danger"
                  }) 
                }
              }, 3000);
            }
          })
        } else {
          this.setState(prevState => {
            return {
                ...prevState,
                savingMultiplier: false,
                errorsMessage: 'qualitat-data-are-missing',
                errorsType: "danger",
            }
          });
        }
      }
    } else {
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              savingMultiplier: false,
              errorsMessage: 'new_multiplikator_ko_piloten_error_message',
              errorsType: "danger"
          }
        });
      }
      clearTimeout(this.myTimeout);
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            errorsMessage: null,
            errorsType: "danger"
          }) 
        }
      }, 3000);
    }
  }

  basicEdit = () =>{this.props.needsSaveData();}

  setActiveTab(tabName){
    if (this._isMounted) {
      this.setState({activeTab: tabName})
    }
  }

  getMeKoPilotenSection = () =>{
    const { match: { params }, needsSave } = this.props;
    const { multiplierData, categoryIds, savingMultiplier, savingKopiloten } = this.state;
    return <KoPilotenMultiplierCategoryComponentNewMulti 
      multiplierData={multiplierData} 
      multiId={params.id} 
      modificationButtons={true}
      categoryIds={categoryIds}
      editedKopiloten={needsSave}
      editCategories={(categoryIds) => this.editCategories(categoryIds)}
      editKopiloten={(value) => this.editKopiloten(value)}
      changeCategories={(categoryIds) => this.changeCategories(categoryIds)}
      saveMulti={() => this.saveChange()}
      changeSavingState={(value) => this.changeSavingState(value)}
      savingMulti={savingMultiplier}
      savingKopiloten={savingKopiloten}
    />
  }

  editCategories = (categoryIds) => {
    this.setState(prevState => {
      return {
          ...prevState,
          editedKopiloten: true,
          categoryIds:[...prevState.categoryIds, categoryIds]
          }
      }
    );
  }

  changeCategories = (categoryIds) => {
    this.setState({
      categoryIds: categoryIds
    });
  }

  editKopiloten = (value) => {
    this.setState({
      editedKopiloten: value,
    });
  }

  saveKopiloten = (multiId) => {
    if(this._isMounted){
      this.setState({savingKopiloten: true});
    }
    let dataToSend = {
      "MultiplierId": multiId,
      "CategoryIds": this.state.categoryIds
    }
    saveMultiplierCategory(dataToSend)
    .then(res => {
      if (this._isMounted) {
        this.setState({
          savingKopiloten: false,
          errorComponent: {
            show: true,
            type: "success",
            message: "multiplier_successfully_saved"
          },
        })
        document.body.classList.remove('overflow-y-scroll');
      }
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({ 
            errorComponent: Alert,
            savingKopiloten: false
          });
        }
      }, 2500);
    })
    .catch(error => {
      if (this._isMounted) {
        this.setState({
          savingKopiloten: false,
          errorComponent: {
            show:true,
            type: "danger",
            message: "basic_error_message"
          }
        });
        document.body.classList.remove('overflow-y-scroll');
      }
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({ 
            errorComponent: Alert,
            savingKopiloten: false
          });
        }
      }, 2500);
    });
  }

  changeSavingState = (value) => {
    this.setState({
      savingKopiloten: value,
    });
  }

  back=()=>{
    const { history } = this.props;
    history.push(`${baseData.subFolders}multipliers`);
  }

  render() {
    const {translateKey, needsSave} = this.props;
    let multiplierEmptyHandler
    if (this.state.loadingMultiplier) {
      multiplierEmptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      let MultiplierRow =(
        <MultiplierComponent new={true} editedData={this.basicEdit} edited={needsSave} key="newMultiplierComponenet01" element={this.state.multiplierData}/>
      )
      let CancelSave = (
        <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="cancelSaveNewMultiplier01" edited={needsSave} savingData={this.state.savingMultiplier || this.state.savingKopiloten}  back={this.back}/>
      );
      let SaveAlert;
      if(this.state.errorsMessage!==null){
        SaveAlert = (
          <AlertMessage centeredAlert={true} show={this.state.errorsMessage!==null} key="alertNewMultiplier01" type={this.state.errorsType} message={translateKey(this.state.errorsMessage)}/>
        )
      }
      multiplierEmptyHandler = [
        MultiplierRow,
        SaveAlert,
        CancelSave
      ]
    }

    return (
      <>
        <h1>{translateKey("new_multiplier")}</h1>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a className={`nav-item nav-link nav-link-invoices ${this.state.activeTab === LeadTabList.MULTIPLIER && 'active'}`} onClick={()=>{this.setActiveTab(LeadTabList.MULTIPLIER)}} id="nav-details-tab">
              {translateKey("new_multiplier")}
            </a>
            <a className={`nav-item nav-link nav-link-invoices ${this.state.activeTab === LeadTabList.KOPILOTEN && 'active'}`} onClick={()=>{this.setActiveTab(LeadTabList.KOPILOTEN)}} id="nav-kopiloten-tab">
                {translateKey("ko_piloten_cateogry")}
            </a>
          </div>
        </nav>
        <div className="tab-content position-relative" id="nav-tabContent">
          <div className={`tab-pane fade ${this.state.activeTab === LeadTabList.MULTIPLIER && 'show active'}`} id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
            <div className="leadSection">
              {multiplierEmptyHandler}
            </div>
          </div>
          <div className={`tab-pane fade ${this.state.activeTab === LeadTabList.KOPILOTEN && 'show active'}`} id="nav-kopiloten" role="tabpanel" aria-labelledby="nav-kopiloten-tab">
            <div className="leadSection">
              {this.getMeKoPilotenSection()}
            </div>
          </div>
          <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
        </div>
        <Prompt
            when={needsSave}
            message={translateKey("unsaved_data_lost")}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    needsSave: state.refreshTokenStatus.needsDataSave,
    lastActionTime: state.auth.actionTimestamp
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  saveMultiplierData: (data, lang) => dispatch(multiplierSave(data, lang)),
  addMultiplierData: (multiId, data) => dispatch(addNewMultiplierData(multiId, data)),
  needsSaveData: () => dispatch(needsSaveData()),
  doesNotNeedsSave: () => dispatch(doesNotNeedsSave())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewMultiplierModalPage));