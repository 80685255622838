import * as React from "react";
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";
import PhoneInput from 'react-phone-input-2';
import { Asterisk } from "../../../_helpers/field_asterix";
import 'react-phone-input-2/lib/bootstrap.css'
import * as LanguageSelect from "../../../_constance/values/languageSelect";
import * as Validation from "../../../_forms/validation";
import * as FieldTypes from "../../../_forms/fieldTypes";

export class InputTypePhoneComponent extends React.Component {
  static defaultProps = {
    valueFormatter: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      text: props.text,
      isIm: props.isImportant,
      countryData: {}
    };
  }

  setTypeChange(key, value, countryData) {
    if(!this.props.disabled){
      if(value!==null && value!==''){
        value="+"+value;
      }
      this.setState({
        countryData : countryData
      })
      this.props.setTypeChangeToParent(key, value);
    }
  }

  getPriorLanguage = () =>{
    const {appLang} = this.props;
    if(appLang===LanguageSelect.DE){return ['de'];}
    if(appLang===LanguageSelect.EN){return ['gb'];}
    return ['de', 'gb', 'pl']
  }

  getDefaultLanguage = () =>{
      const {appLang} = this.props;
      if(appLang===LanguageSelect.DE){return 'de';}
      if(appLang===LanguageSelect.EN){return 'gb';}
      return 'de';
  }

  render() {
    const {
      field,
      showValidation,
      keyName,
      disabled,
      isImportant,
      labelClass,
      newUI,
      text,
      className,
      hideErrorRequired
    } = this.props;
    const validationValue = {value: field.value, countryData: this.state.countryData};
    let validationErrors = Validation.fieldType(validationValue, FieldTypes.PHONE_NUMBER).errors;
    const errorsArgs = Validation.fieldType(validationValue, FieldTypes.PHONE_NUMBER).args;

    if (hideErrorRequired) {
      const index = field.validationErrors.indexOf('err_required');
      validationErrors = field.validationErrors.splice(index-1, 1);
    }
    const showErrors = validationErrors.length > 0 && showValidation;

    return (
      <div className={`${className} ${showErrors?'is-invalid':''}`} >
        <div className={labelClass}>
          <label className={`form-label-basic ${newUI ? "newUI" : ""}`}>
            <span className={`quick-label-position-fix ${isImportant && field.checkImportant? "input_important_class": ""}`}>
              {text.toUpperCase()} <Asterisk isRequired={field.isRequired}/>
            </span>
          </label>
        </div>
        <div className={`${showErrors ? "is-invalid" : ""}`}>
          <PhoneInput
            preferredCountries={this.getPriorLanguage()}
            preserveOrder={['preferredCountries']}
            value={field.value}
            disabled={disabled}
            placeholder=''
            country={this.getDefaultLanguage()}
            containerClass={'d-flex'}
            inputClass={`form-control pm-phone-input ${newUI ? "newUI" : ""} ${isImportant && field.checkImportant? "input_important_input": ""}`}
            onChange={(phone, countryData) => this.setTypeChange(keyName, phone, countryData)}
          />
          {(showErrors && field.isRequired ) && (
            <ValidationErrorsList
              keyName={keyName}
              errors={validationErrors}
              args={errorsArgs}
            />
          )}
        </div>
      </div>
    );
  }
}