import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ListMovementComponent from "../../form-bb-components/list-movement-component/list-movement-component";
import ListComponent from '../../form-bb-components/list-component/list-component';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import * as SkillRange from '../../../_forms/BBForm/values/skillRange';
import {CheckBoxSmallMultipleComponent} from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import {InputTypeTextComponent} from "../../form-bb-components/input-type-text-component/input-type-text-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import { IconsMap } from '../../../_helpers';
import { ReqHelper } from "../../../_helpers/index";
import { Tiles } from "../../../_constance/classes/dndPriorityOffer/TilesIds";
import produce from "immer";

const IS_SONDA_PEG  = "is_sonda_peg";
const IS_DIET_DIABETES  = "is_diet_diabetes";
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles'; 

// filed to store tiles data
const KEY_NAME_TILES = 'is_most_important_taking_care_patient';

class Section11 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-11'),
            showValidation: props.showValidationErrors,
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    saveDiabetesAndTiles = () =>{
        const saveDiabetesTileField = this.props.form.getFieldByKey(SKIP_SAVE_STRING_TILES);
        if(saveDiabetesTileField){saveDiabetesTileField.value = true}
    }

    setTileFieldValue = (action, string) =>{
        const { form } = this.props;
        const tilesString = form.getFieldByKey(KEY_NAME_TILES);
        const list = ReqHelper.prepareMeListFromString(tilesString.value, ';');
        if(ReqHelper.notNullAndUndefinied(tilesString.value) && action===true){
            if(list.indexOf(string)<0){
                tilesString.value += ';'+string;
            }
        }else{
            if(action){
                tilesString.value += ';'+string;
            }else{
                if(list.indexOf(string)>-1){
                    tilesString.value += ';'+string;
                    list.splice(list.indexOf(string),1);
                    tilesString.value = list.join(';')
                }
            }
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
            if(key===IS_SONDA_PEG){
                this.setTileFieldValue(value, Tiles.TILENINESONDA);
                this.saveDiabetesAndTiles();
            }else{
                if(key===IS_DIET_DIABETES){
                    this.setTileFieldValue(value, Tiles.TILEEIGHTMEAL);
                    this.saveDiabetesAndTiles();
                }
            }
        });

        this.setState(nextState);
    };

    render() {
        const {
          disabled,
          sectionNumber,
          subsectionName,
          showValidationErrors,
          translateKey,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const isSondaPeg = section.getFieldByKey(IS_SONDA_PEG);
        const isDehydration = section.getFieldByKey('is_dehydration');
        const is_eating = section.getFieldByKey('is_eating');
        const is_drinking = section.getFieldByKey('is_drinking');
        const is_chewing_and_swallowing = section.getFieldByKey('is_chewing_and_swallowing');
        const is_nutritional_status = section.getFieldByKey('is_nutritional_status');
        const isNewUI = true; 

        const classForCheckBox = "custom-control-inline illness-wrapper newUI-radio-check-box-class cursor-pointer";
        const classForCheckBoxSimple = "newUI-radio-check-box-class";
        const classForList = 'col-sm-12 col-lg-3 form-margin-bottom-fix text-align-center';

        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListMovementComponent newUI={isNewUI} type="column" svgIcon icon={IconsMap.svg.essenUndTrinken.essen} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={classForList} transKey="form-text-essen" key="is_eating" keyName="is_eating" field={is_eating} value={is_eating.value} validationErrors={is_eating.validationErrors} />
                                <ListMovementComponent newUI={isNewUI} type="column" svgIcon icon={IconsMap.svg.essenUndTrinken.trinken} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={classForList} transKey="form-text-trinken" key="is_drinking" keyName="is_drinking" field={is_drinking} value={is_drinking.value} validationErrors={is_drinking.validationErrors} />
                                <ListMovementComponent newUI={isNewUI} type="column" svgIcon icon={IconsMap.svg.essenUndTrinken.kauen} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={classForList} transKey="form-text-schlucken" key="is_chewing_and_swallowing" keyName="is_chewing_and_swallowing" field={is_chewing_and_swallowing} value={is_chewing_and_swallowing.value} validationErrors={is_chewing_and_swallowing.validationErrors} />
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <CheckBoxComponent 
                                    checkClassWrapper={classForCheckBoxSimple} 
                                    newUI={isNewUI} 
                                    showValidation={showValidationErrors} 
                                    className="col-lg-12" 
                                    setTypeChangeToParent={this.setTypeChange} 
                                    value={isSondaPeg.value} 
                                    validationErrors={isSondaPeg.validationErrors} 
                                    text={translateKey("form-text-pegsonde")} 
                                    transKey="form-text-pegsonde" 
                                    keyName={IS_SONDA_PEG} 
                                />
                            </div>
                        </div> 
                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListComponent
                                    newUI={isNewUI}
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                                    transKey="form-text-ernahrungszustand"
                                    key="is_nutritional_status"
                                    keyName="is_nutritional_status"
                                    field={is_nutritional_status}
                                    type="column"
                                    value={is_nutritional_status.value}
                                    validationErrors={is_nutritional_status.validationErrors}
                                    valuesList={[
                                        { value: SkillRange.UNLIMITED, label: translateKey("form-text-normal") },
                                        { value: SkillRange.PARTIAL, label: translateKey("form-text-unterernahrt") },
                                        { value: SkillRange.LIMITED, label: translateKey("form-text-fettleibig") },
                                    ]}
                                />
                            </div>
                        </div>  
                        <div className="newUI-section-padding">
                            <div className="row">
                                <CheckBoxComponent checkClassWrapper={classForCheckBoxSimple} newUI={isNewUI} showValidation={showValidationErrors} className="col-lg-6" setTypeChangeToParent={this.setTypeChange} value={isDehydration.value} validationErrors={isDehydration.validationErrors} text={translateKey("form-text-entwassert")} transKey="form-text-entwassert" keyName="is_dehydration"/>
                            </div>
                        </div> 
                        <div className="newUI-section-padding">
                            <div className="form-label-basic newUI cursor-pointer quick-label-position-fix">{translateKey("form-text-kostform-diat").toUpperCase()}</div>
                            <div className="checkboxes_wrapper row">
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-normal"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_diet_normal')} text={translateKey("form-text-normal")} transKey="form-text-normal" keyName="is_diet_normal" />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-vollwert"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_diet_balanced')} text={translateKey("form-text-vollwert")} transKey="form-text-vollwert" keyName="is_diet_balanced" />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-vegetarisch"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_diet_vegetarian')} text={translateKey("form-text-vegetarisch")} transKey="form-text-vegetarisch" keyName="is_diet_vegetarian"/>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-sonderkost"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_diet_special')} text={translateKey("form-text-sonderkost")} transKey="form-text-sonderkost" keyName="is_diet_special"/>
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-diabeteskost"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey(IS_DIET_DIABETES)} text={translateKey("form-text-diabeteskost")} transKey="form-text-diabeteskost" keyName={IS_DIET_DIABETES} />
                                </div>
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListComponent
                                    newUI={isNewUI}
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                                    transKey="form-text-trinkmenge"
                                    key="is_taking_drink"
                                    keyName="is_taking_drink"
                                    field={section.getFieldByKey('is_taking_drink')}
                                    type="column"
                                    value={section.getFieldByKey('is_taking_drink').value}
                                    validationErrors={section.getFieldByKey('is_taking_drink').validationErrors}
                                    valuesList={[
                                        { value: SkillRange.UNLIMITED, label: translateKey("form-text-normal") },
                                        { value: SkillRange.PARTIAL, label: translateKey("form-text-wenig") },
                                        { value: SkillRange.LIMITED, label: translateKey("form-text-viel") },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <InputTypeTextComponent newUI={isNewUI} showValidation={this.state.showValidation} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_favorite_food_drink_de')} text={translateKey("form-text-lieblingsspeisen")} transKey="form-text-lieblingsspeisen" keyName="is_favorite_food_drink_de" />
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section11));