import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import { IconsMap } from "../../_helpers/index";

class CarerClientStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carer: props.carer
        }
    }

    getMeIconAndText(carer){
        let IconStatus, TextStatus;
        let dateNow = Math.floor(new Date().getTime() / 1000);
        if(carer.isBreak === false) {
            if (dateNow > carer.from && dateNow < carer.to) {
                IconStatus = (
                    <img className="client-carer-status" src={IconsMap.svg.current} alt="Icon"/>
                )
                TextStatus = (
                    <span>{this.props.translateKey("current")}</span>
                )
            } else {
                if (dateNow > carer.from) {
                    IconStatus = (
                        <img className="client-carer-status" src={IconsMap.svg.ended} alt="Icon"/>
                    )
                    TextStatus = (
                        <span>{this.props.translateKey("ended")}</span>
                    )
                } else {
                    IconStatus = (
                        <img className="client-carer-status" src={IconsMap.svg.future} alt="Icon"/>
                    )
                    TextStatus = (
                        <span>{this.props.translateKey("future")}</span>
                    )
                }
            }
        } else{
            IconStatus = null
            TextStatus = (
                <span>---</span>
            )
        }

        return (
            <div className="flexAlignCenter">
                {IconStatus}
                {TextStatus}
            </div>
        )
    }

    render() {return (this.getMeIconAndText(this.props.carer));}
}

const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
})

export default withRouter(connect(null, mapDispatchToProps)(CarerClientStatus));