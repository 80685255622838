import * as React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    authSetActionTimestamp,
    pendingClientsData,
    removeClientsData,
    setClientsData,
    translateKey
} from "../../_redux/actions/index";
import {
    AlertMessage,
    EmptyTableIcon,
    LoaderModal,
    LoaderTable,
    NavBar,
    SideBar,
    TableColumHead,
    TableDateFormat,
    TablePagination,
    TableProperties
} from "../../_components/index";
import axiosAuth from "../../_services/config/axios-auth";
import {TableEnums} from "../../_constance/enums";
import * as FilterType from "../../_constance/enums/filterType";
import {baseData, refreshTokenTime} from '../../_constance/base_data';
import {Alert, ClientTable, Paging} from "../../_constance/classes/index";
import {IconsMap, ReqHelper, shouldRefresh} from "../../_helpers/index";
import {SortingArrivals} from "../../_constance/classes/sortingClasses/index";
import * as LanguageSelect from "../../_constance/values/languageSelect";
import * as TravelStatus from "../../_constance/values/TravelStatus";
import {setFiltersData} from "../../_redux/actions";

//COLUMNS
import {
    addColumnData,
    removeColumnsData,
    setColumnsData,
    updateColumTypeData
} from "../../_redux/actions/active-columns";
import * as ColumnsNames from "../../_constance/values/columnsNames";
import {getColumnsData, saveColumnsData} from "../../_services/firebase_service";

class ArrivalsPage extends React.Component {
    _isMounted = false;
    alertD = Alert;
    myTimout;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            sorting: new SortingArrivals(),
            searchQuery: null,
            filterData: [],
            errorComponent: Alert,
            pagingItem: Paging,
            showModalLoader: false,
            rerender: Date.now(),
            breadCrumbs: [
                {
                    link: "clients",
                    key: "clients",
                    name: null
                }
            ]
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.remove('modal-open');
        window.scrollTo(0, 0);
        this.getMeColumnConfiguration();
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };


    getMeColumnConfiguration() {
        const lastUpdate = this.props.activeColumnsTimeStamp;
        const actualTime = new Date().getTime();
        if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
            this.getColumnsDataFromApi();
        } else {
            if (this._isMounted) {
                this.prepareMeSortingBasedOnColumns()
                this.getDataBasic();
            }
        }
    }

    getColumnsDataFromApi() {
        getColumnsData(this.props.userIdColumn)
            .then(res => {
                this.props.setColumns(res);
                this.prepareMeSortingBasedOnColumns();
                this.getDataBasic();
            })
            .catch((error) => {
                this.prepareMeSortingBasedOnColumns();
                this.getDataBasic();
            });
    }

    prepareMeSortingBasedOnColumns() {
        const {activeColumns} = this.props;
        if (activeColumns) {
            if (Array.isArray(activeColumns)) {
                activeColumns.forEach(element => {
                    if (element.name === ColumnsNames.CLIENTS) {
                        element.columns.forEach(item => {
                            this.state.sorting.setColumnEnabled(item.enabled, item.name)
                        });
                    }
                });
            }
        }
        this.setState({rerender: Date.now()})
    }

    prepareColumnsData = (data) => {
        data.forEach(column => {
            this.state.sorting.setColumnEnabled(column.enabled, column.keyName)
        });
        let columnsData = this.props.activeColumns.find(element => element.name === ColumnsNames.CLIENTS);
        if (columnsData) {
            let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.CLIENTS, data, false);
            this.props.updateColumnTypeData(ColumnsNames.CLIENTS, dataToSave);
            saveColumnsData(this.props.userIdColumn, ColumnsNames.CLIENTS, dataToSave.columns)
        } else {
            let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.CLIENTS, data, false);
            this.props.addNewColumnTable(dataToSave);
            saveColumnsData(this.props.userIdColumn, ColumnsNames.CLIENTS, dataToSave.columns)
        }
    }

    _prepareFilterObj() {
        const {translateKey, appLang} = this.props;
        let filterData = [];
        let Options = [];
        for (let i = 0; i < this.props.tripStatuses.length; i++) {
            let filtering = {
                column: this.props.tripStatuses[i].Label,
                value: this.props.tripStatuses[i].Value,
                active: false,
                type: "text"
            }
            Options.push(filtering);
        }
        filterData.push({
            filterName: "carer-trip-tracking-status",
            filterKey: "trackingStatus",
            filterType: FilterType.STATUS,
            filterOptions : Options
        });
        filterData.push({
            filterName: "carer-trip-date-and-time-arrival",
            filterKey: "dateAndTimeArrival",
            filterType: FilterType.FROMTO,
            filterOptions: [
                {
                    column: translateKey('date_from'),
                    active: false,
                    type: "dateFrom",
                    date: new Date().getTime()
                },
                {
                    column: translateKey('date_to'),
                    active: false,
                    type: "dateTo",
                    date: new Date().getTime()
                }
            ]
        });
        let saved = this.props.savedFilters;
        if (saved !== null) {
            return saved;
        } else {
            return filterData
        }
    }

    isNextDayArrival(client) {
        const dateToCheck = this.formatArrival(new Date(client.dateAndTimeArrival * 1000));
        const today = new Date();
        const nextDay = new Date(today);
        nextDay.setDate(nextDay.getDate() + 1);
        const next3Days = new Date(today);
        next3Days.setDate(next3Days.getDate() + 3);
        const todayFormatted = this.formatArrival(today);
        const nextDayFormatted = this.formatArrival(nextDay);
        const next3DaysFormatted = this.formatArrival(next3Days);

        return dateToCheck === todayFormatted || dateToCheck === nextDayFormatted || dateToCheck === next3DaysFormatted;
    }

    formatArrival(date) {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) { dd = '0' + dd; }
        if (mm < 10) { mm = '0' + mm; }
        return yyyy + mm + dd;
    }

    async getClientsList() {
        const { appLang } = this.props;
        this.props.pendingRequest();
        axiosAuth.get("clients/list/0")
            .then(res => {
                if (res.status === 200) {
                    return res;
                } else {
                    return Promise.reject(res.status);
                }
            })
            .then(json => {
                return json.data.map((element) => {
                    return new ClientTable(element);
                }).filter((client) => { return this.isNextDayArrival(client) });

            })
            .then(filteredClients => {
                const promises = filteredClients.map(client =>axiosAuth.get(`/carers/list/${client.id}/0/${appLang}`).then(({data})=>data));
                Promise.all(promises)
                .then(res => {
                    let carersMap = res
                        .map((response) => { return response.Carers })
                        .filter((carers) => { return carers.length > 0 })
                        .reduce(function(map, carers) { map[carers[0].clientId] = carers; return map; }, {});
                    for(let i = 0; i < filteredClients.length; i++){
                        const clientId = filteredClients[i].id;
                        const dateTimeToFind = filteredClients[i].dateAndTimeArrival;
                        if(carersMap[clientId] !== undefined) {
                            const carers = carersMap[clientId].filter((carer) => { return carer.dateAndTimeArrival === dateTimeToFind; });
                            const carer = carers.length > 0 ? carers[0] : null;
                            if(carer !== null) {
                                filteredClients[i].carerName = `${carer.firstName} ${carer.lastName}`;
                            }
                        }
                    }
                    this.props.setData(filteredClients);
                    if (this._isMounted) {
                        this.setState({
                            data: filteredClients,
                            loading: false,
                            filterData: this._prepareFilterObj()
                        }, () => this.filterOrDataChange());
                    }
                });
            })
            .catch((error) => {
                this.props.clearData();
                this.alertD.show = true;
                this.alertD.type = "danger";
                this.alertD.message = this.props.translateKey("basic_error_message");
                if (this._isMounted) {
                    this.setState({
                        data: [],
                        loading: false,
                        errorComponent: this.alertD
                    }, () => this.filterOrDataChange());
                }
                console.log(error);
                this.myTimeout = setTimeout(() => {
                    if (this._isMounted) {
                        this.setState({errorComponent: Alert});
                    }
                }, 3000);
            });
    }

    getDataBasic() {
        if (this._isMounted) {
            this.setState({loading: true})
        }
        this.getClientsList();
    }

    filterOrDataChange(val) {
        let finalDataToShow = this.propsFilterSearch(val);
        ReqHelper.sortMeData(finalDataToShow, this.state.sorting.sortingColumns);
        this.propsPagingData(finalDataToShow);
        setTimeout(() => {
            let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage - 1) * this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage * this.state.pagingItem.itemsPerPage);
            if (this._isMounted) {
                this.setState({data: finalData})
            }
        }, 50);
        this.props.setFilters('clients', this.state.filterData);
    }

    toggleModalLoader = () => {
        if (this._isMounted) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    showModalLoader: !prevState.showModalLoader
                }
            });
        }
    }

    propsSearchTable = async (val) => {
        const {history} = this.props;
        if (val.lastIndexOf(baseData.deepLinkStart, 0) === 0) {
            this.toggleModalLoader();
            let url = new URL(val);
            let id = url.searchParams.get("id");
            let form = url.searchParams.get("extraqs");
            if (form !== null && id !== null) {
                const {appLang} = this.props
                id = id.replace('{', '').replace('}', '').toLowerCase();
                switch (form) {
                    case baseData.enumClient:
                        axiosAuth.get("leads/list/0/" + appLang)
                            .then(res => {
                                let statusObj = res.data.find((element) => {
                                    return element.leadId.toString().toLowerCase() === id.toString()
                                })
                                if (statusObj) {
                                    this.toggleModalLoader();
                                    history.push(`${baseData.subFolders}leadDetails/${id}`);
                                } else {
                                    axiosAuth
                                        .get("clients/list/0")
                                        .then(res => {
                                            let statusObj = res.data.find((element) => element.id.toString().toLowerCase() === id.toString())
                                            if (statusObj) {
                                                this.toggleModalLoader();
                                                history.push(`${baseData.subFolders}clientDetails/${id}`);
                                            } else {
                                                axiosAuth.get("multipliers/list/0")
                                                    .then(res => {
                                                        this.toggleModalLoader();
                                                        let statusObj = res.data.find((element) => element.id.toString().toLowerCase() === id.toString())
                                                        if (statusObj) {
                                                            history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                        }
                                                    }).catch(() => this.toggleModalLoader())
                                            }
                                        }).catch(() => this.toggleModalLoader())
                                }
                            }).catch(() => {
                            axiosAuth
                                .get("clients/list/0")
                                .then(res => {
                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase() === id.toString())
                                    if (statusObj) {
                                        this.toggleModalLoader();
                                        history.push(`${baseData.subFolders}clientDetails/${id}`);
                                    } else {
                                        axiosAuth.get("multipliers/list/0")
                                            .then(res => {
                                                this.toggleModalLoader();
                                                let statusObj = res.data.find((element) => element.id.toString().toLowerCase() === id.toString())
                                                if (statusObj) {
                                                    history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                }
                                            }).catch(() => this.toggleModalLoader())
                                    }
                                }).catch(() => {
                                axiosAuth.get("multipliers/list/0")
                                    .then(res => {
                                        this.toggleModalLoader();
                                        let statusObj = res.data.find((element) => element.id.toString().toLowerCase() === id.toString())
                                        if (statusObj) {
                                            history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                        }
                                    }).catch(() => this.toggleModalLoader())
                            })
                        })
                        break;
                    case baseData.enumPatient:
                        if (this.props.patientLeadData.length > 0) {
                            this.toggleModalLoader();
                            let statusObj = this.props.patientLeadData.find((element) => element.id.toString().toLowerCase() === id.toString())
                            if (statusObj) {
                                if (appLang === LanguageSelect.DE) {
                                    history.push(`${baseData.subFolders}bb-form-lead/${statusObj.leadId}/${id}`);
                                } else {
                                    history.push(`${baseData.subFolders}leadPatient/${statusObj.leadId}/${id}`);
                                }
                            }
                        } else {
                            axiosAuth.get("patients/list/LEAD/0/" + appLang)
                                .then(res => {
                                    this.toggleModalLoader();
                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase() === id.toString())
                                    if (statusObj) {
                                        if (appLang === LanguageSelect.DE) {
                                            history.push(`${baseData.subFolders}bb-form-lead/${statusObj.leadId}/${id}`);
                                        } else {
                                            history.push(`${baseData.subFolders}leadPatient/${statusObj.leadId}/${id}`);
                                        }
                                    }
                                }).catch(() => this.toggleModalLoader())
                        }
                        if (this.props.patientClientData.length > 0) {
                            this.toggleModalLoader();
                            let statusObj = this.props.patientClientData.find((element) => element.id.toString().toLowerCase() === id.toString())
                            if (statusObj) {
                                if (appLang === LanguageSelect.DE) {
                                    history.push(`${baseData.subFolders}bb-form-client/${statusObj.clientId}/${id}`);
                                } else {
                                    history.push(`${baseData.subFolders}clientPatient/${statusObj.clientId}/${id}`);
                                }
                            }
                        } else {
                            axiosAuth.get("patients/list/CLIENT/0/" + appLang)
                                .then(res => {
                                    this.toggleModalLoader();
                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase() === id.toString())
                                    if (statusObj) {
                                        if (appLang === LanguageSelect.DE) {
                                            history.push(`${baseData.subFolders}bb-form-client/${statusObj.clientId}/${id}`);
                                        } else {
                                            history.push(`${baseData.subFolders}clientPatient/${statusObj.clientId}/${id}`);
                                        }
                                    }
                                }).catch(() => this.toggleModalLoader())
                        }
                        break;
                    case baseData.enumCarer:
                        axiosAuth
                            .get("clients/list/0")
                            .then(res => {
                                let foundCarer = false;
                                for (let i = 0; i < res.data.length && !foundCarer; i++) {
                                    foundCarer = this.getMeCarer(res.data[i].id, appLang, id, i === res.data.length - 1);
                                }
                            }).catch(() => this.toggleModalLoader())
                        break;
                    case baseData.enumContract:
                        let gotHim = false
                        await axiosAuth
                            .get("clients/list/0")
                            .then(res => {
                                let foundContract = false;
                                for (let i = 0; i < res.data.length && !gotHim; i++) {
                                    foundContract = this.getMeContract(res.data[i].id, appLang, id, "CLIENT");
                                }
                                if (!foundContract) {
                                    this.getMeLeads(appLang, id);
                                }
                            }).catch(() => this.toggleModalLoader())
                        break;
                    case baseData.enumInvoice:
                        this.toggleModalLoader()
                        history.push(`${baseData.subFolders}invoices/${id}`);
                        break;
                    case baseData.enumTaskTypeEvent:
                        this.toggleModalLoader()
                        history.push(`${baseData.subFolders}taskDetails/${id}`);
                        break;
                    case baseData.enumTaskTypeTask:
                        this.toggleModalLoader()
                        history.push(`${baseData.subFolders}taskDetails/${id}`);
                        break;
                    case baseData.enumTaskTypeCall:
                        this.toggleModalLoader()
                        history.push(`${baseData.subFolders}taskDetails/${id}`);
                        break;
                    default:
                        this.toggleModalLoader()
                }
            }
        } else {
            if (this._isMounted) {
                this.setState({searchQuery: val.trim()});
            }
            this.filterOrDataChange(val);
        }
    }

    propsSortType(type) {
        this.state.sorting.changeColumnByColumn(type);
        this.filterOrDataChange();
    }

    propsFilterSearch = (val) => {
        const {appLang} = this.props;
        const {sortingColumns} = this.state.sorting
        let finalDataAfter = [];
        if (this.props.clientsData !== null && this.props.clientsData.length > 0) {
            if (val && val !== null) {
                // searching
                let filters = ReqHelper.getMeFilersObj(this.state.filterData);
                if (filters.length > 0) {
                    // mamy search i filtry
                    // search filter
                    let afterSearchData = this.props.clientsData.filter((value) => {
                        return ReqHelper.valueExistsInObjBasedOnSorting(value, val, sortingColumns, appLang);
                    });
                    finalDataAfter = afterSearchData.filter((value) => {
                        let gotAllFilters = true;
                        for (let i = 0; i < filters.length; i++) {
                            if (gotAllFilters) {
                                switch (filters[i].type) {
                                    case FilterType.FROMTO:
                                        let gotDateFilter = true;
                                        for (let v = 0; v < filters[i].values.length; v++) {
                                            if (gotDateFilter) {
                                                if (filters[i].values[v].type === "dateFrom") {
                                                    if (filters[i].values[v].date > value[filters[i].key]) {
                                                        gotDateFilter = false;
                                                    }
                                                } else {
                                                    if (filters[i].values[v].type === "dateTo") {
                                                        if (filters[i].values[v].date < value[filters[i].key]) {
                                                            gotDateFilter = false;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        gotAllFilters = gotDateFilter;
                                        break;
                                    case FilterType.STATUS:
                                        let gotStatusFilter = false;
                                        for (let v = 0; v < filters[i].values.length; v++) {
                                            if (value[filters[i].key] !== null && value[filters[i].key] !== undefined) {
                                                if (value[filters[i].key].toString().toLowerCase() === filters[i].values[v].value.toString().toLowerCase()) {
                                                    gotStatusFilter = true;
                                                    break;
                                                }
                                            }
                                        }
                                        gotAllFilters = gotStatusFilter;
                                        break;
                                    default:
                                }
                            }
                        }
                        return gotAllFilters;
                    });
                } else {
                    // samo search bez filtrów
                    finalDataAfter = this.props.clientsData.filter((value) => {
                        return ReqHelper.valueExistsInObjBasedOnSorting(value, val, sortingColumns, appLang);
                    });
                }
            } else {
                let search = this.state.searchQuery;
                if (search && search !== null && val !== '') {
                    // filtrowanie z wpisanym search
                    let filters = ReqHelper.getMeFilersObj(this.state.filterData);
                    if (filters.length > 0) {
                        // mamy search i filtry
                        let afterSearchData = this.props.clientsData.filter((value) => {
                            return ReqHelper.valueExistsInObjBasedOnSorting(value, search, sortingColumns, appLang);
                        });
                        finalDataAfter = afterSearchData.filter((value) => {
                            let gotAllFilters = true;
                            for (let i = 0; i < filters.length; i++) {
                                if (gotAllFilters) {
                                    switch (filters[i].type) {
                                        case FilterType.FROMTO:
                                            let gotDateFilter = true;
                                            for (let v = 0; v < filters[i].values.length; v++) {
                                                if (gotDateFilter) {
                                                    if (filters[i].values[v].type === "dateFrom") {
                                                        if (filters[i].values[v].date > value[filters[i].key]) {
                                                            gotDateFilter = false;
                                                        }
                                                    } else {
                                                        if (filters[i].values[v].type === "dateTo") {
                                                            if (filters[i].values[v].date < value[filters[i].key]) {
                                                                gotDateFilter = false;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            gotAllFilters = gotDateFilter;
                                            break;
                                        case FilterType.STATUS:
                                            let gotStatusFilter = false;
                                            for (let v = 0; v < filters[i].values.length; v++) {
                                                if (value[filters[i].key] !== null && value[filters[i].key] !== undefined) {
                                                    if (value[filters[i].key].toString().toLowerCase() === filters[i].values[v].value.toString().toLowerCase()) {
                                                        gotStatusFilter = true;
                                                        break;
                                                    }
                                                }
                                            }
                                            gotAllFilters = gotStatusFilter;
                                            break;
                                        default:
                                    }
                                }
                            }
                            return gotAllFilters;
                        });
                    } else {
                        // samo search bez filtrów
                        finalDataAfter = this.props.clientsData.filter((value) => {
                            return ReqHelper.valueExistsInObjBasedOnSorting(value, search, sortingColumns, appLang);
                        });
                    }
                } else {
                    //samo filtrowanie
                    let filters = ReqHelper.getMeFilersObj(this.state.filterData);
                    if (filters.length > 0) {
                        finalDataAfter = this.props.clientsData.filter((value) => {
                            let gotAllFilters = true;
                            for (let i = 0; i < filters.length; i++) {
                                if (gotAllFilters) {
                                    switch (filters[i].type) {
                                        case FilterType.FROMTO:
                                            let gotDateFilter = true;
                                            for (let v = 0; v < filters[i].values.length; v++) {
                                                if (gotDateFilter) {
                                                    if (filters[i].values[v].type === "dateFrom") {
                                                        if (filters[i].values[v].date >= value[filters[i].key]) {
                                                            gotDateFilter = false;
                                                        }
                                                    } else {
                                                        if (filters[i].values[v].type === "dateTo") {
                                                            if (filters[i].values[v].date <= value[filters[i].key]) {
                                                                gotDateFilter = false;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            gotAllFilters = gotDateFilter;
                                            break;
                                        case FilterType.STATUS:
                                            let gotStatusFilter = false;
                                            for (let v = 0; v < filters[i].values.length; v++) {
                                                if (value[filters[i].key] !== null && value[filters[i].key] !== undefined) {
                                                    if (value[filters[i].key].toString().toLowerCase() === filters[i].values[v].value.toString().toLowerCase()) {
                                                        gotStatusFilter = true;
                                                        break;
                                                    }
                                                }
                                            }
                                            gotAllFilters = gotStatusFilter;
                                            break;
                                        default:
                                    }
                                }
                            }
                            return gotAllFilters;
                        });
                    } else {
                        finalDataAfter = this.props.clientsData;
                    }
                }
            }
        }
        return finalDataAfter;
    }

    propsPagingData(data) {
        if (data.length <= 25) {
            if (this._isMounted) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        pagingItem: {
                            totalItems: data.length,
                            itemsPerPage: prevState.pagingItem.itemsPerPage,
                            currentPage: 1,
                            visiblePages: 5,
                            pageFrom: 1,
                            pageTo: 5,
                            totalPages: Math.ceil(data.length / prevState.pagingItem.itemsPerPage)
                        }
                    }
                });
            }
        } else {
            if (this._isMounted) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        pagingItem: {
                            ...prevState.pagingItem,
                            totalItems: data.length,
                            pageFrom: prevState.pagingItem.pageFrom,
                            pageTo: prevState.pagingItem.pageTo,
                            currentPage: prevState.pagingItem.currentPage > Math.ceil(data.length / prevState.pagingItem.itemsPerPage) ? Math.ceil(data.length / prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                            totalPages: Math.ceil(data.length / prevState.pagingItem.itemsPerPage)
                        }
                    }
                });
            }
        }
    }

    navigateToDetails(clientData) {
        this.props.history.push(`${baseData.subFolders}clientDetails/${clientData.id}`);
    }

    getTripArrivalTime(dateAndTimeArrival) {
        if (this.showTripFields(dateAndTimeArrival)) {
            return <TableDateFormat withTime={true} timestamp={dateAndTimeArrival ? dateAndTimeArrival : ''}/>
        } else {
            return "---";
        }
    }

    showTripFields(dateAndTimeArrival) {
        const nowTime = Math.floor(Date.now() / 1000);
        const showTripFields = dateAndTimeArrival !== null && dateAndTimeArrival >= nowTime;
        return showTripFields;
    }

    getMeTrackingStatusText(travelStatus, trackingStatus, dateAndTimeArrival) {
        if (this.showTripFields(dateAndTimeArrival)) {
            const {translateKey} = this.props;
            if (travelStatus === TravelStatus.PLANNED) {
                if (this.props.tripStatuses !== undefined) {
                    const translated = this.props.tripStatuses.filter(e => {
                        return e.Value == trackingStatus
                    });
                    if (translated.length > 0) {
                        return translated[0].Label;
                    }
                }
                return "---";
            } else {
                return translateKey("carer-trip-status-planned")
            }
        } else {
            return "---";
        }
    }

    render() {
        const {translateKey, appLang} = this.props;
        const {sorting} = this.state;
        let emptyHandler;
        let PreparedTableContent;
        if (this.state.loading) {
            emptyHandler = <LoaderTable/>
        } else {
            if (this.props.clientsData === null) {
                emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data")}/>
            } else {
                if (this.props.clientsData.length === 0) {
                    emptyHandler =
                        <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data_to_show")}/>
                } else {
                    if (this.state.data.lengh === 0) {
                        emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()}
                                                       text={translateKey("no_data_to_show")}/>
                    } else {
                        PreparedTableContent = this.state.data.map(element => (
                            <tr className="table-row-wrapper cursor-pointer"
                                key={Math.random().toString(36).substr(2, 9)} onClick={() => {
                                this.navigateToDetails(element)
                            }}>
                                {
                                    sorting.isColumnVisibleByKey('clients_client', appLang) &&
                                    <td className="table-cell-wrapper">{element.client}</td>
                                }
                                {
                                    sorting.isColumnVisibleByKey('clients_carer', appLang) &&
                                    <td className="table-cell-wrapper">{element.carerName}</td>
                                }
                                {
                                    sorting.isColumnVisibleByKey('carer_trip_tracking_status', appLang) &&
                                    <td className="table-cell-wrapper">{this.getMeTrackingStatusText(element.travelStatus, element.trackingStatus, element.dateAndTimeArrival)}</td>
                                }
                                {
                                    sorting.isColumnVisibleByKey('carer_trip_date_and_time_arrival', appLang) &&
                                    <td className="table-cell-wrapper">{this.getTripArrivalTime(element.dateAndTimeArrival)}</td>
                                }
                                {
                                    sorting.isColumnVisibleByKey('arrivals_trip_tracking_link', appLang) &&
                                    <td className="table-cell-wrapper">{element.tripTrackingLink}</td>
                                }
                            </tr>
                        ))
                    }
                }
            }
        }
        let alertComponent = <AlertMessage options={this.state.errorComponent}></AlertMessage>
        return (
            <div className="mainContentWrapper" onClick={() => this.props.tookAction()}>
                <NavBar breadCrumbs={this.state.breadCrumbs}/>
                <TableProperties onShowMeData={() => this.filterOrDataChange()}
                                 prepareColumnsData={this.prepareColumnsData}
                                 onSearch={(val) => this.propsSearchTable(val)}
                                 options={[TableEnums.SEARCH, TableEnums.FILTER, TableEnums.ACTIVECOLUMNS]}
                                 filterData={this.state.filterData} sortData={sorting.sortingColumns}/>
                <div className="mainContent">
                    <SideBar/>
                    <LoaderModal show={this.state.showModalLoader}/>
                    <div className="listDataWrapper">
                        <div className="listData flipped">
                            <div className="flipped">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        {
                                            sorting.sortingColumns.map(column => {
                                                if (sorting.isColumnVisibleByKey(column.keyName, appLang)) {
                                                    return <TableColumHead key={Math.random().toString(36).substr(2, 9)}
                                                                           sorting={sorting.sortingColumns}
                                                                           name={translateKey(column.name)}
                                                                           onSortType={(data) => this.propsSortType(data)}
                                                                           type={column.column}/>
                                                } else {
                                                    return null
                                                }
                                            }).filter(element => element !== null)
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>{PreparedTableContent}</tbody>
                                </table>
                                {emptyHandler}
                                {alertComponent}
                                <TablePagination recalculateAll={() => this.filterOrDataChange()}
                                                 simpleDataUpdate={() => this.filterOrDataChange()}
                                                 loading={this.state.loading} paginationObj={this.state.pagingItem}
                                                 totalItems={this.state.pagingItem.totalItems}/>
                            </div>
                        </div>
                        <div className="basic-bottom-section-line"
                             style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        userIdColumn: state.auth.userId,
        clientsData: state.database.clients,
        activeColumns: state.activeColumns.data,
        activeColumnsTimeStamp: state.activeColumns.columnsTimeStamp,
        savedFilters: state.filters.data.clients,
        tripStatuses: state.enums.tripTrackingStatusList
    };
};

const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    setData: (data) => dispatch(setClientsData(data)),
    pendingRequest: () => dispatch(pendingClientsData()),
    clearData: () => dispatch(removeClientsData()),
    tookAction: () => dispatch(authSetActionTimestamp()),
    setColumns: (data) => dispatch(setColumnsData(data)),
    addNewColumnTable: (data) => dispatch(addColumnData(data)),
    updateColumnTypeData: (type, data) => dispatch(updateColumTypeData(type, data)),
    removeColumns: () => dispatch(removeColumnsData()),
    setFilters: (type, data) => dispatch(setFiltersData(type, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArrivalsPage));