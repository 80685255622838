import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { InputTypeTextAreaComponent } from "../../form-bb-components/input-type-textarea-component/input-type-textarea-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import * as CareBoxStatus from '../../../_forms/BBForm/values/careBoxStatus';
import { baseData } from "../../../_constance";
import { sendDocuSignCarebox } from '../../../_services/docuSign_service';
import { LoaderModal } from "../../loader-modal/LoaderModal";
import { InfoModal } from "../../info-modal/InfoModal";
import produce from "immer";

class SectionNotiz extends React.Component {
    constructor(props) {
        super(props);
        const hideCommentField = props.hideCommentField !== undefined ? props.hideCommentField : false;
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-notiz'),
            showValidation: props.showValidationErrors,
            hideCommentField: hideCommentField,
            showModal : false,
            showLoader : false,
            modalErrorClass: '',
            modalMessage: ''
        };
    }

    componentDidUpdate(prevProps) {
      const { showValidationErrors } = this.props;
      if (prevProps.showValidationErrors !== showValidationErrors) {
          this.setState({showValidation: showValidationErrors});
      }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    sendToDocuSign = async () => {
      this.setState({
          showModal : false,
          showLoader : true
      })
      const { match: { params }, translateKey } = this.props;
      const { form } = this.props;
      const templateId = baseData['docuSignTemplateIdCareBox'];
      const ingredientId =  this.getIngredientId();
      console.log(ingredientId);
      if(ingredientId !== null) {
          sendDocuSignCarebox(templateId, ingredientId)
              .then(res => {
                  if (res.status === 200) {
                      this.setState({
                          showModal: true,
                          showLoader: false,
                          modalErrorClass: '',
                          modalMessage: translateKey('send-ingredients-to-docusign-success')
                      })
                  } else {
                      this.setState({
                          showModal: true,
                          showLoader: false,
                          modalErrorClass: 'alert alert-danger',
                          modalMessage: translateKey('send-ingredients-to-docusign-error')
                      })
                  }
              }).catch((error) => {
              console.log(error);
              this.setState({
                  showModal: true,
                  showLoader: false,
                  modalErrorClass: 'alert alert-danger',
                  modalMessage: translateKey('send-ingredients-to-docusign-error')
              })
          });
      } else {
          this.setState({
              showModal: false,
              showLoader: false
          });
      }
  }

    isDocuSignButtonVisible = () => {
      const {form} = this.props;
      const statusIsNew = form.getFieldByKey('is_status').value === CareBoxStatus.APP_NEW
      const ingredientId =  this.getIngredientId();
      return statusIsNew && ingredientId !== null;
    }

    getIngredientId() {
      const ingredientId =  this.props.careBoxIngredientsId;
      return (ingredientId !== undefined && ingredientId !== null && ingredientId !== '') ? ingredientId : null;
    }

    hideModal = () => {
      this.setState({
          showModal : false,
          showLoader : false,
          modalErrorClass: '',
          modalMessage: ''
      })
    }

    render() {
        const {
          disabled,
          sectionNumber,
          subsectionName,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form,
          translateKey,
          carebox = false,
        } = this.props;
        const { section, showValidation, showLoader, showModal, modalErrorClass, modalMessage } = this.state;
        const isNewUI = true; 

        return (
          <div className="form-section-main">
            <LoaderModal show={showLoader} text={''} />
            <InfoModal okMe={this.hideModal} show={showModal} messageClass={modalErrorClass} titleMessage=""
                            mainMessage={modalMessage} okMessage={translateKey("Ok")}/>
            <div className="new-section-name-wrapper">
                {sectionNumber}. {subsectionName}
            </div>
            <form className="new-section-content-wrapper container form_serializer">
              <fieldset disabled={disabled}>
                <div className="newUI-section-padding"> 
                  <div className="row">
                    <InputTypeTextAreaComponent
                      newUI={isNewUI}
                      showValidation={showValidation}
                      className="col-sm-12"
                      setTypeChangeToParent={this.setTypeChange}
                      field={section.getFieldByKey("is_additional_fp_comments")}
                      text={translateKey("form-text-notiz-field")}
                      transKey="form-text-notiz-field"
                      keyName="is_additional_fp_comments"
                    />
                  </div>
                    {this.state.hideCommentField === false &&
                      <div className="row">
                        <InputTypeTextAreaComponent
                          newUI={isNewUI}
                          showValidation={showValidation}
                          className="col-sm-12"
                          setTypeChangeToParent={this.setTypeChange}
                          field={section.getFieldByKey("is_comment_for_carer_de")}
                          text={translateKey("form-text-notiz-carer")}
                          transKey="form-text-notiz-carer"
                          keyName="is_comment_for_carer_de"
                        />
                      </div>
                    }
                    
                    {carebox && this.isDocuSignButtonVisible() &&
                      <div className='zone-form-navigation-buttons'>
                        <button 
                          type="button"
                          className="btn btn-primary cursor-pointer prev-next-active-button"
                          style={{display: "flex"}}
                          onClick={this.sendToDocuSign}
                        >
                          <div className="section-navigation-button-text">{translateKey("send-ingredients-to-docusign").toUpperCase()}</div>
                          <i className="fas fa-envelope fa-1x section-navigation-icon"></i>
                        </button>
                      </div>
                    }
                </div>
              </fieldset>
            </form>
            <SectionNavigationButtonsComponent
              analizeFinished={analizeFinished}
              currentSection={currentSection}
              rerender={rerender}
              previousPageClick={previousPageClick}
              nextPageClick={nextPageClick}
              finishMe={finishMe}
              form={form}
              showNotePop={false}
            />
          </div>
        );
    }
}

const mapStateToProps = state => {
  return {
      careBoxIngredientsId: state.database.careBoxIngredientsId
  };
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionNotiz));