import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {translateKey} from "../../../_redux/actions/index";
import {EmptyTableIcon, LoaderTable, TableColumHead, TablePagination} from "../../../_components/index";
import {SortingPatient} from "../../../_constance/classes/sortingClasses/index";
import {Paging} from "../../../_constance/classes/index";
import {ReqHelper} from "../../../_helpers/index";
import * as LanguageSelect from "../../../_constance/values/languageSelect";
import {baseData} from '../../../_constance/base_data';
import BBFormService from "../../../_services/bbform";
import { getDynamicFields } from '../../../_services/user_service';
import * as Entity from "../../../_forms/BBForm/values/entity";
import { TableDateFormat } from "../../index";

class ClientPatientData extends React.Component {
    myTimout;
    constructor(props) {
        super(props);
        const titleKey = props.titleKey === undefined ? "client-patient-title" : props.titleKey
        this.state = {
            data: [],
            id: props.id,
            loading: true,
            sorting: SortingPatient,
            pagingItem: Paging,
            showDetails: false,
            patientToShow: '',
            titleKey: titleKey,
            onPatientSelected: props.onPatientSelected,
            onReload: props.onReload
        };
    }

    componentDidMount() {
        document.body.classList.remove('modal-open');
        this.filterOrDataChange();
    }

    componentWillUnmount() {clearTimeout(this.myTimeout);};

    openModalWithData(element){
        const { match: { params }, history, isCareBox } = this.props;
        if(isCareBox){
            this.props.onPatientSelected(params.id, element.id);
            //history.push(`${baseData.subFolders}care-box-form-client/${params.id}/${element.id}`);
        } else {
            if(this.props.appLang === LanguageSelect.EN){
                history.push(`${baseData.subFolders}bb-form-client/${params.id}/${element.id}`);
            }else{
                history.push(`${baseData.subFolders}bb-form-client/${params.id}/${element.id}`);
            }
        }
    }

    filterOrDataChange(){
        let pushedElements = [];
        let finalDataToShow = this.props.patientData.filter(element => {
            if(this.props.type==="contract"){
                if(element.contractId === this.props.id){
                    if(pushedElements.indexOf(element.id) > -1){
                        return false;
                    }else{
                        pushedElements.push(element.id);
                        return element
                    }
                  }else{
                    return false;
                }
            }else{
                if(element.clientId === this.props.id){
                    if(pushedElements.indexOf(element.id) > -1){
                        return false;
                    }else{
                        pushedElements.push(element.id);
                        return element
                    }
                  }else{
                    return false;
                }
            }
        })
        ReqHelper.sortMeData(finalDataToShow, this.state.sorting);
        this.propsPagingData(finalDataToShow);
        setTimeout(() => {
            let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
            this.getPlegegradDate(finalData);
            // this.setState({
            //     data: finalData,
            //     loading: false
            // })
        }, 50);
    }

    async getPlegegradDate(data) {

        const resolvePromisesSeq = async (items) => {
            const results = [];
            for (const item of items) {
                results.push(await item);
            }
            return results;
        };

        let mapedData = data.map(async element => {
            let dataToSend = [
                {
                    Id: element.id,
                    Entity: Entity.PATIENT,
                    Fields: ["is_pflegegrad_posting_date"]
                }
            ];
            const pflegegradPostingDate = await getDynamicFields(dataToSend)
            .then(res => (
                res[0].fields.is_pflegegrad_posting_date
            ));

            return ({
                ...element,
                is_pflegegrad_posting_date: pflegegradPostingDate
            });
        });
        mapedData = await resolvePromisesSeq(mapedData);

        this.setState({
            data: mapedData,
            loading: false
        });
    }

    propsSortType(type){
        let sortingObj = [];
        for(let i=0; i<this.state.sorting.length; i++){
            if(this.state.sorting[i].column===type){
            if(this.state.sorting[i].active===true){
                let item = this.state.sorting[i];
                item.type==='ASC'? item.type="DESC" : item.type='ASC';
                sortingObj.push(item);
            }else{
                let item = this.state.sorting[i];
                item.type='ASC';
                item.active = true;
                sortingObj.push(item);
            }
            }else{
            let item = this.state.sorting[i];
            item.type='ASC';
            item.active = false;
            sortingObj.push(item);
            }
        }
        this.setState({sorting: sortingObj});
        this.filterOrDataChange();
    }

    propsPagingData(data){
        if(data.length<=25){
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                totalItems: data.length,
                itemsPerPage: prevState.pagingItem.itemsPerPage,
                currentPage: 1,
                visiblePages: 5,
                pageFrom: 1,
                pageTo: 5,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }else{
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                ...prevState.pagingItem,
                totalItems: data.length,
                pageFrom: prevState.pagingItem.pageFrom,
                pageTo: prevState.pagingItem.pageTo,
                currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }
    }

    editToggle = () => {
        if(this.state.showDetails){
            document.body.classList.remove('modal-open');
        }
        this.setState({
            showDetails: !this.state.showDetails
        });
    }

    async addCareBox() {
        const { match: { params }, history } = this.props;
        if(this.state.data.length > 0) {
            this.showLoading(true);
            const {clientId, name} = this.state.data[0]
            const patientId = await this.createNewPatient(clientId, name);
            if(this.state.onReload !== undefined) {
                this.state.onReload();
            }
            this.showLoading(false);
            if (patientId !== 0) {
                history.push(`${baseData.subFolders}new-care-box-form-client/${params.id}/${patientId}`);
            }
        }
    }

    async createNewPatient(contactId, name) {
        const dataToSend = [{
            "Id": "0",
            "Entity": "is_patient",
            "Fields": {
                "is_contact_id": contactId,
                "is_last_section": "rodo_section_1",
                "is_first_name_and_last_name_of_patient": `${name.trim()}`
            }
        }];
        const response = await BBFormService.savePatient(dataToSend);
        let patientId = response.data[0].id;
        return patientId;
    }

    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show
            })
        }
    }


    render() {
        let emptyHandler;
        let patientData;
        if (this.state.loading) {
            emptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
        }else{
            if (this.state.data.length === 0) {
                emptyHandler = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.RefreshMe()} text={this.props.translateKey("no_data_to_show")} /></div>
            }else{
                patientData = this.state.data.map(element => (
                <tr className="table-row-wrapper cursor-pointer" key={element.id} onClick={()=>{this.openModalWithData(element)} }>
                    <td className={"table-cell-wrapper " + (element.name !== null ? "got-data" : 'no-data')}>{element.name}</td>
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.is_pflegegrad_posting_date}/></td>
                </tr>
                ))
            }
        }
        return (
            <div className="client-data-wrapper-tab">
                {this.props.isCareBox &&
                    <div className="add-icon-wrapper">
                        <button className="btn btn-success norbsoft-filter-icon" onClick={async() => {await this.addCareBox()}}><i
                            className="fas fa-plus norbsoft-filter-icon"></i>{this.props.translateKey('patient-new-care-box')}
                        </button>
                    </div>
                }
                <div className="listData">
                    <table className="table">
                        <thead>
                            <tr>
                                <TableColumHead sorting={this.state.sorting} name={this.props.translateKey(this.state.titleKey)} onSortType={(data) => this.propsSortType(data)} type="name" />
                                <TableColumHead sorting={this.state.sorting} name={this.props.translateKey("pflegegrad_posting_date")} onSortType={(data) => this.propsSortType(data)} type="name" />
                            </tr>
                        </thead>
                        <tbody>{patientData}</tbody>
                    </table>
                    {emptyHandler}
                    <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    appLang: state.lang.appLanguage,
    patientData: state.database.patientClient
}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientPatientData));