import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey, setTasksData, pendingTasksData, removeTasksData, authSetActionTimestamp, finishedTaskData, updateTaskData } from "../../_redux/actions/index";
import { TableColumHead, TableProperties, LoaderTable, NavBar, SideBar, EmptyTableIcon, AlertMessage, TableDateFormat, TaskStatusHandler, TablePagination, TaskPriorityConverter, TaskStatusLang} from "../../_components/index";
import axiosAuth from "../../_services/config/axios-auth";
import { TableEnums } from "../../_constance/enums";
import { Alert, TaskTable, Paging } from "../../_constance/classes/index";
import { SortingTasks } from "../../_constance/classes/sortingClasses/index";
import { shouldRefresh, ReqHelper, IconsMap } from "../../_helpers/index";
import * as MarkAsViewedEntity from "../../_constance/values/markAsViewedEntity";
import * as TaskStatusVal from "../../_constance/values/taskStatus";
import { markAsViewed } from "../../_services/user_service";
import * as TaskType from "../../_constance/values/taskType";
import {setFiltersData} from "../../_redux/actions";

//COLUMNS
import {
  setColumnsData,
  removeColumnsData,
  addColumnData,
  updateColumTypeData
} from "../../_redux/actions/active-columns";
import * as ColumnsNames from "../../_constance/values/columnsNames";
import {saveColumnsData, getColumnsData} from "../../_services/firebase_service";
import { refreshTokenTime, baseData } from '../../_constance/base_data';

class TasksMainPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      sorting: new SortingTasks(),
      filterData: [],
      rerender: Date.now(),
      errorComponent: Alert,
      pagingItem: Paging,
      firstLoad: true,
      breadCrumbs:[
        {
          link: "tasks",
          key: "tasks",
          name: null
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0)
    this.getMeColumnConfiguration();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };


  getMeColumnConfiguration(){
    const lastUpdate = this.props.activeColumnsTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getColumnsDataFromApi();
    } else {
      if (this._isMounted) {
        this.prepareMeSortingBasedOnColumns()
        this.getDataBasic();
      }
    }
  }
  getColumnsDataFromApi(){
   getColumnsData(this.props.userIdColumn)
    .then(res => {
        this.props.setColumns(res);
        this.prepareMeSortingBasedOnColumns();
        this.getDataBasic();
    })
    .catch((error) => {
      this.prepareMeSortingBasedOnColumns();
      this.getDataBasic();
    });
  }
  prepareMeSortingBasedOnColumns(){
    const {activeColumns} = this.props;
    if(activeColumns){
      if(Array.isArray(activeColumns)){
        activeColumns.forEach(element => {
          if(element.name === ColumnsNames.TASKS){
            element.columns.forEach(item => {
              this.state.sorting.setColumnEnabled(item.enabled, item.name)
            });
          }
        });
      }
    }
    this.setState({rerender: Date.now()})
  }
  prepareColumnsData = (data) =>{
    data.forEach(column => {
      this.state.sorting.setColumnEnabled(column.enabled, column.keyName)
    });
    let columnsData = this.props.activeColumns.find(element => element.name === ColumnsNames.TASKS); 
    if(columnsData){
      let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.TASKS, data, false);
      this.props.updateColumnTypeData(ColumnsNames.TASKS, dataToSave);
      saveColumnsData(this.props.userIdColumn, ColumnsNames.TASKS, dataToSave.columns)
    }else{
      let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.TASKS, data, false);
      this.props.addNewColumnTable(dataToSave);
      saveColumnsData(this.props.userIdColumn, ColumnsNames.TASKS, dataToSave.columns)
    }
  }



  _prepareFilterObj() {
    const {translateKey} = this.props;
    let filterData = [];
    let Options = [];
    filterData.push({
      filterName: "status",
      filterOptions: [
        {
          column: translateKey('task_open'),
          active: true,
          type: "text",

        },
        {
          column: translateKey('task_closed'),
          active: false,
          type: "text",
        }
      ]
    });
    for (let i = 0; i < this.props.taskPriority.length; i++) {
      let filtering = {
        column: this.props.taskPriority[i].Label,
        active: false,
        type: "text"
      }
      Options.push(filtering);
    }
    filterData.push({
      filterName: "priority",
      filterOptions: Options
    });
    let filterTypeOptions = [{
      column: this.props.translateKey('task_call'),
      taskType: TaskType.CALL,
      active: false,
      type: "text",

    },
    {
      column: this.props.translateKey('task_event'),
      taskType: TaskType.EVENT,
      active: false,
      type: "text",
    },
    {
      column: this.props.translateKey('task_task'),
      taskType: TaskType.TASK,
      active: false,
      type: "text",
    }]
    if(this.state.firstLoad===true){
      const query = new URLSearchParams(this.props.location.search);
      this.setState({firstLoad:false})
      if(query.get('type')==="type"){
        let param = query.get('param')
        if(param!==undefined && param!==null){
          filterTypeOptions = filterTypeOptions.map(element => {
            if(element.column === param){
              element.active = true;
              return element;
            }else{
              return element;
            }
          });
        }
      }
      this.setState({firstLoad:false})
    }
    filterData.push({
      filterName: "type",
      filterOptions: filterTypeOptions
    });
    let basicData = new Date();
    basicData.setHours(0,0,0,0);
    let minus7 = basicData.setDate(basicData.getDate()-7)
    let minus14 = basicData.setDate(basicData.getDate()-7)
    let minus21 = basicData.setDate(basicData.getDate()-7)
    minus7 = new Date(minus7).getTime()/1000;
    minus14 = new Date(minus14).getTime()/1000;
    minus21 = new Date(minus21).getTime()/1000;
    filterData.push({
      filterName: "new_task",
      filterOptions: [
        {
          column: this.props.translateKey('task_youger7'),
          active: false,
          type: "date",
          date: minus7
        },
        {
          column: this.props.translateKey('task_youger14'),
          active: false,
          type: "date",
          date: minus14
        },
        {
          column: this.props.translateKey('task_youger21'),
          active: false,
          type: "date",
          date: minus21
        }
      ]
    });
    let saved = this.props.savedFilters;
    if(saved !== null) {
      return saved;
    } else {
      return filterData
    }
  }

  getMePriorityNumberFromText(text){
    for (let i = 0; i < this.props.taskPriority.length; i++) {
      if(text === this.props.taskPriority[i].Label){
        return this.props.taskPriority[i].Value.toString()
      }
    }
    return 0;
  }

  getEventList = (previousList) => {
    axiosAuth.get("activityWeb/event/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new TaskTable(element, "Event");});
        if(previousList.length>0){mapedRes = [...mapedRes, ...previousList];}
        this.props.setData(mapedRes);
        this.props.finishBasicLoad();
        if (this._isMounted) {
          this.setState({
            data: mapedRes,
            loading: false,
            filterData: this._prepareFilterObj()
          }, () => this.filterOrDataChange());
        }
      })
      .catch((error) => {
        if(previousList.length>0){
          this.props.setData(previousList);
          this.props.finishBasicLoad();
          if (this._isMounted) {
            this.setState({
              data: previousList,
              filterData: this._prepareFilterObj()
            }, () => this.filterOrDataChange());
          }
        }else{
          this.props.clearData();
          this.alertD.show = true;
          this.alertD.type = "danger";
          this.alertD.message = this.props.translateKey("basic_error_message");
          if (this._isMounted) {
            this.setState({
              data: [],
              loading: false,
              errorComponent: this.alertD
            })
          }
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({ errorComponent: Alert });
            }
          }, 3000);
        }
      });
  }

  getCallList = (previousList) => {
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    this.props.pendingRequest();
    axiosAuth.get("activityWeb/call/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new TaskTable(element, "Call");});
        if(previousList.length>0){mapedRes = [...mapedRes, ...previousList];}
        this.getEventList(mapedRes);
      })
      .catch((error) => {
        if(previousList.length>0){
          this.getEventList(previousList);
        }else{
          this.getEventList([]);
        }
    });
  }

  getTaskList = () => {
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    this.props.pendingRequest();
    axiosAuth.get("activityWeb/task/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new TaskTable(element, "Task");});
        this.getCallList(mapedRes);
      })
      .catch((error) => {this.getCallList([]);});
  }

  getDataBasic() {
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    //const lastUpdate = this.props.tasksTimestamp;
    //const actualTime = new Date().getTime();
    this.getTaskList();
    /*if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getTaskList();
    } else {
      if (this._isMounted) {
        this.setState({
          data: this.props.tasksData,
          sorting: SortingTasks,
          loading: false,
          filterData: this._prepareFilterObj()
        }, () => this.filterOrDataChange());
      }
    }*/
  }

  filterOrDataChange(){
    let finalDataToShow = this.propsFilterSearch();
    ReqHelper.sortMeData(finalDataToShow, this.state.sorting.sortingColumns);
    this.propsPagingData(finalDataToShow);
    setTimeout(() => {
      let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
      if (this._isMounted) {
        this.setState({data: finalData})
      }
    }, 50);
    this.props.setFilters('tasks', this.state.filterData);
  }

  propsSortType(type){
    this.state.sorting.changeColumnByColumn(type);
    this.filterOrDataChange();
  }

  propsFilterSearch = () => {
    const {translateKey} = this.props
    let finalDataAfter = [];
    if (this.props.tasksData !== null && this.props.tasksData.length > 0) {
      //samo filtrowanie
      let filters = ReqHelper.getMeFilersObj(this.state.filterData);
      if(filters.length>0){
        finalDataAfter = this.props.tasksData.filter((value) => {
          let gotAllFilters = true;
          for(let i=0; i<filters.length; i++){
            if(gotAllFilters){
              switch(filters[i].name){
                case "status":    let gotStatus = false;
                                  for(let v=0; v<filters[i].values.length; v++){
                                    if(filters[i].values[v].column === translateKey('task_closed')){
                                      if(value.status===TaskStatusVal.CLOSED || value.status===TaskStatusVal.COMPLETED){
                                        gotStatus=true;
                                        break;
                                      }           
                                    }else{
                                      if(filters[i].values[v].column === translateKey('task_open')){
                                        if(value.status===TaskStatusVal.OPEN || value.status===TaskStatusVal.SCHEDULED){
                                          gotStatus=true;
                                          break;
                                        }   
                                      }
                                    }
                                  }
                                  gotAllFilters = gotStatus;
                                  break;
                case "priority":  let gotPriority = false;
                                  for(let v=0; v<filters[i].values.length; v++){
                                    if(value.priority.toString() === this.getMePriorityNumberFromText(filters[i].values[v].column)){
                                      gotPriority=true;
                                      break;
                                    }
                                  }
                                  gotAllFilters = gotPriority;
                                  break;
                case "type":      let gotType = false;
                                  for(let v=0; v<filters[i].values.length; v++){
                                    if(value.taskType === filters[i].values[v].taskType){
                                      gotType=true;
                                      break;
                                    }
                                  }
                                  gotAllFilters = gotType;
                                  break;
                case "new_task":  let gotDateFilter = true;
                                  for(let v=0; v<filters[i].values.length; v++){
                                    if(gotDateFilter){
                                      if(filters[i].values[v].date>value.createdDate){
                                        gotDateFilter = false;
                                      }
                                    }
                                  }
                                  gotAllFilters = gotDateFilter;
                                  break;
                default: 
              }
            }
          }
          return gotAllFilters;
        });
      }else{
        //brak search, brak filtrowania
        finalDataAfter = this.props.tasksData;
      }
    }
    return finalDataAfter;
  }

  propsPagingData(data){
    if(data.length<=25){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            pagingItem: {
              totalItems: data.length,
              itemsPerPage: prevState.pagingItem.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            pagingItem: {
              ...prevState.pagingItem,
              totalItems: data.length,
              pageFrom: prevState.pagingItem.pageFrom,
              pageTo: prevState.pagingItem.pageTo,
              currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
              totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
            }
          }
        });
      }
    }
  }

  navigateToDetails(taskData){
    const { history } = this.props;
    if(taskData.ViewedInApplication===false){
      let type=MarkAsViewedEntity.TASK;
      switch(taskData.taskType.toLowerCase()){
        case "event": type=MarkAsViewedEntity.APPOINTMENT;break;
        case "call": type=MarkAsViewedEntity.PHONECALL;break;
        default: 
      }
      markAsViewed(type, taskData.id).then(res => {
        taskData.ViewedInApplication = true;
        this.props.updateTaskDataFun(taskData.id, JSON.parse(JSON.stringify(taskData)));
        history.push(`${baseData.subFolders}taskDetails/${taskData.id}`);
      })
      .catch((error) => {
        history.push(`${baseData.subFolders}taskDetails/${taskData.id}`);
      });
    }else{
      history.push(`${baseData.subFolders}taskDetails/${taskData.id}`);
    }
  }

  checkIfViewedAndNotClosed = (el)=>{
    if(!el.ViewedInApplication){
      if(el.status !== 'Completed' && el.status !== 'Abgeschlossen'){
        return "not-readed-record";
      }
    }
  }

  render() {
    const { translateKey, appLang, history } = this.props;
    const { sorting } = this.state;
    let emptyHandler;
    let PreparedTableContent;
    if (this.state.loading) {
      emptyHandler = <LoaderTable />
    } else {
      if (this.props.tasksData === null) {
        emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data")} />
      } else {
        if (this.props.tasksData.length === 0) {
          emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data_to_show")} />
        }else{
          if(this.state.data.lengh===0){
            emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data_to_show")} />
          }else{
              PreparedTableContent = this.state.data.map(element => (
                <tr className={`table-row-wrapper cursor-pointer ${this.checkIfViewedAndNotClosed(element)}`} key={Math.random().toString(36).substr(2, 5)} onClick={()=>{this.navigateToDetails(element)}}>
                  {
                  sorting.isColumnVisibleByKey('tasks_status', appLang) &&
                    <td className="table-cell-wrapper"><TaskStatusHandler element={element}/></td>
                  }
                  {
                  sorting.isColumnVisibleByKey('tasks_title', appLang) &&
                    <td className="table-cell-wrapper">{element.title}</td>
                  }
                  {
                  sorting.isColumnVisibleByKey('tasks_status', appLang) &&
                    <td className="table-cell-wrapper"><TaskStatusLang element={element.status}/></td>
                  }
                  {
                  sorting.isColumnVisibleByKey('tasks_concern', appLang) &&
                    <td className="table-cell-wrapper">{element.concernName}</td>
                  }
                  {
                  sorting.isColumnVisibleByKey('tasks_priority', appLang) &&
                    <td className="table-cell-wrapper"><TaskPriorityConverter priority={element.priority}/></td>
                  }
                  {
                  sorting.isColumnVisibleByKey('tasks_createdDate', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.createdDate}/></td>
                  }
                  {
                  sorting.isColumnVisibleByKey('tasks_dueDate', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.dueDay}/></td>
                  }
                </tr>
              ))
            }
        }
      }
    }
    let alertComponent = <AlertMessage options={this.state.errorComponent}></AlertMessage>
    return (
      <div className="mainContentWrapper" onClick={()=>this.props.tookAction()}>
        <NavBar breadCrumbs={this.state.breadCrumbs}/>
        <TableProperties addClick={()=>history.push(`${baseData.subFolders}newtask`)} prepareColumnsData={this.prepareColumnsData} onShowMeData={()=>this.filterOrDataChange()} options={[TableEnums.FILTER, TableEnums.ADD, TableEnums.ACTIVECOLUMNS]} filterData={this.state.filterData} sortData={sorting.sortingColumns}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper">
            <div className="listData flipped">
              <div className="flipped">
                <table className="table">
                  <thead>
                    <tr>
                      {
                        sorting.sortingColumns.map(column => {
                          if(sorting.isColumnVisibleByKey(column.keyName, appLang)){
                            return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sorting.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.propsSortType(data)} type={column.column} />
                          }else{
                            return null
                          }
                        }).filter(element => element !==null )
                      }
                    </tr>
                  </thead>
                  <tbody>{PreparedTableContent}</tbody>
                </table>
                {emptyHandler}
                {alertComponent}
                <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
              </div>
            </div>
            <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    userIdColumn: state.auth.userId,
    tasksData: state.database.tasks,
    tasksTimestamp: state.database.tasksTimeStamp,
    taskPriority: state.enums.taskPriority,
    activeColumns: state.activeColumns.data,
    activeColumnsTimeStamp: state.activeColumns.columnsTimeStamp,
    savedFilters: state.filters.data.tasks
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) =>dispatch(translateKey(firstLvl, secondLvl)),
  setData: (data) => dispatch(setTasksData(data)),
  pendingRequest: () => dispatch(pendingTasksData()),
  clearData: () => dispatch(removeTasksData()),
  tookAction: () => dispatch(authSetActionTimestamp()),
  finishBasicLoad: () => dispatch(finishedTaskData()),
  updateTaskDataFun: (id, data) => dispatch(updateTaskData(id, data)),
  setColumns: (data) => dispatch(setColumnsData(data)),
  addNewColumnTable: (data) => dispatch(addColumnData(data)),
  updateColumnTypeData: (type,data) => dispatch(updateColumTypeData(type, data)),
  removeColumns: () => dispatch(removeColumnsData()),
  setFilters: (type, data) => dispatch(setFiltersData(type, data)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(TasksMainPage));