export class MultiplierTable {
    constructor(element){
        let multiplier = '';
        if(element.lastName){
            if(element.firstName){
                multiplier = element.lastName + ' ' + element.firstName
            }else{
                multiplier = element.lastName
            }
        }else{
            if(element.firstName){
                multiplier = element.firstName;
            }
        }
        let telephone = element.cellPhone!=null ? element.cellPhone : element.phone!=null ? element.phone : '';
        let location = element.address !== null ? element.address.city : ''
        this.address = element.address;
        this.birthDate = element.birthDate;
        this.phone = element.phone;
        this.cellPhone = element.cellPhone;
        this.multiplier = multiplier;
        this.company = element.company;
        this.location= location;
        this.telephone = telephone;
        this.email = element.email;
        this.firstName = element.firstName;
        this.id = element.id;
        this.lastName = element.lastName;
        this.nextContact = element.nextContact;
        this.profession = element.profession;
        this.businessPhone = element.businessPhone;
        this.careAmbasador = element.careAmbasador;
        this.emailAddress2 = element.emailAddress2;
        this.gender = element.gender;
        this.marketingId = element.marketingId;
        this.middleName = element.middleName;
        this.multiplierQuality = element.multiplierQuality;
        this.phoneOtherRemarks = element.phoneOtherRemarks;
        this.salutationAddress = element.salutationAddress;
        this.salutationLetter = element.salutationLetter;
        this.statusMultiplier = element.statusMultiplier;
        this.market = 717700000;
    }
};