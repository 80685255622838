import * as React from 'react';
import styled from 'styled-components'

export const RadioButtonsComponent = ({
  name,
  options,
  value,
  setTypeChangeToParent
}) => {
  
  const RadioBoxWrapper = styled.div`
    display: flex;
    margin-left: 8%;
    margin-bottom: 20px;

    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }
  `;

  const RadioWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 150px;

    @media (max-width: 992px) {
      min-width: 300px;
      margin-right: 0;
      margin-bottom: 10px;
    }

    &:last-child {
      margin-right: 0;

      @media (max-width: 992px) {
        margin-bottom: 0;
      }
    }
  `;

  const Input = styled.input`
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 0;
    cursor: pointer;
  `;

  const Label = styled.label`
    margin: 0;
    padding: 0 20px;
    cursor: pointer;
  `;
  const CheckIcon = styled.i`
    font-size: 1.2em;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid rgb(170,181,193);
    color: transparent;

    &.radio-checked {
      color: rgb(4,90,167);
    }
  `;

  return (
    <RadioBoxWrapper>
      { 
        options.map((item, index) => (
          <RadioWrapper key={name + index}>
            <CheckIcon className={`fas fa-2x fa-check ${ item.value === value ? 'radio-checked' : null }`} />
            <Input onChange={e => setTypeChangeToParent(name, e.target.value)} checked={ item.value === value } type="radio" name={name} id={`meeting_art_des_gesprachs${index+1}`} value={ item.value } />
            <Label htmlFor={`meeting_art_des_gesprachs${index+1}`}>{item.text}</Label>
          </RadioWrapper>
        ))
      }
    </RadioBoxWrapper>
  )
}