import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import { ContractBasicsComponent, ContractPCMComponent } from "./index";

class ContractData extends React.Component {
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            contractData: this.props.contractData
        };
    }

    componentDidMount() {
        document.body.classList.remove('modal-open');
    }

    componentWillUnmount() {clearTimeout(this.myTimeout);};

    render() {
        const {contractData, contractOptionList} = this.props;
        let Basics =(
            <ContractBasicsComponent key="contractDataComponent023" element={contractData} optionList={contractOptionList}/>
        )
        let PCM =(
            <ContractPCMComponent key="contractDataPCMComponent023" element={contractData}/>
        )
        return (
            <div className="client-data-wrapper-tab">
                {Basics}
                {PCM}
            </div>
        );
    }
}
  
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(ContractData));