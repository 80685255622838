import React, { Component } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './datePickerMeetingStyle.css';

class DatePickerMeetingComponent extends Component {

  getMeValueFromProps(){
    return this.props.timestamp
  }

  changeMeetingDate = (date) => {
    const { timestamp, changePropsDT} = this.props;
    let newDate = new Date(date);
    let oldDate = new Date(timestamp);
    const newDateTime = new Date(newDate.getFullYear(), newDate.getMonth() , newDate.getDate() , oldDate.getHours() , oldDate.getMinutes() , 0 , 0);
    changePropsDT(newDateTime);
  }


  render() {
    const { lang, type } = this.props;
    return (
      < div className="calendar-wrapper">
        <Calendar
          onChange={this.changeMeetingDate}
          value={this.getMeValueFromProps()}
          locale={lang}
          calendarType={type}
          prev2Label=""
          prev2AriaLabel=""
          next2AriaLabel=""
          next2Label=""
          showDoubleView={false}
          showFixedNumberOfWeeks={false}
          showNavigation={true}
          showNeighboringMonth={false}
          selectRange={false}
          minDate={new Date()}
        />
      </div>
    );
  }
}
export default DatePickerMeetingComponent