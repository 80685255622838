import * as React from 'react';

import { connect } from 'react-redux';
import { translateKey } from '../../../../_redux/actions/index';
import { IconsMap } from "../../../../_helpers/icons_set";

import CircleWithBadge from './CircleWithBadge';


class SecondSliderContent extends React.Component {
  render() {
    const { translateKey } = this.props; 

    return (
      <div className="carousel-inner">
        <div className="carousel-item active h-100" data-interval="false">
          <div className="carousel-section d-none d-md-block h-100">
            <div className="container-fluid no-gutters p-0 d-flex flex-column h-100">
              <div className="row no-gutters w-100">
                <div className="col-12 no-gutters">
                  <div className="section-title pt-0">
                    {translateKey("summary-offer-section-1-title-1")}
                  </div>
                </div>
              </div>
              <div className="row no-gutters w-100 mt-md-5"> 
                <div className="col-12 no-gutters d-flex justify-content-between">
                  <CircleWithBadge
                    className="custom-circle"
                    iconClassName="custom-circle-icon"
                    iconSource={IconsMap.svg.sicherheit}
                    bottomLabel={translateKey("summary-offer-slider-2-item-1")}
                  />
                  <CircleWithBadge
                    className="custom-circle"
                    iconClassName="custom-circle-icon"
                    iconSource={IconsMap.svg.freizeitgestaltung}
                    bottomLabel={translateKey("summary-offer-slider-2-item-2")}
                  />
                  <CircleWithBadge
                    className="custom-circle"
                    iconClassName="custom-circle-icon"
                    iconSource={IconsMap.svg.hilfe_beim_transfer}
                    bottomLabel={translateKey("summary-offer-slider-2-item-3")}
                  />
                  <CircleWithBadge
                    className="custom-circle"
                    iconClassName="custom-circle-icon"
                    iconSource={IconsMap.svg.wasche}
                    bottomLabel={translateKey("summary-offer-slider-2-item-4")}
                  />
                  <CircleWithBadge
                    className="custom-circle"
                    iconClassName="custom-circle-icon"
                    iconSource={IconsMap.svg.kochen}
                    bottomLabel={translateKey("summary-offer-slider-2-item-5")}
                  />
                </div>
              </div>
              <div className="row no-gutters w-100 mt-md-5"> 
                <div className="col-12 no-gutters d-flex justify-content-around pr-md-5 pl-md-5">
                  <CircleWithBadge
                    className="custom-circle"
                    iconClassName="custom-circle-icon"
                    iconSource={IconsMap.svg.baden}
                    bottomLabel={translateKey("summary-offer-slider-2-item-6")}
                  />
                  <CircleWithBadge
                    className="custom-circle"
                    iconClassName="custom-circle-icon"
                    iconSource={IconsMap.svg.anziehen}
                    bottomLabel={translateKey("summary-offer-slider-2-item-7")}
                  />
                  <CircleWithBadge
                    className="custom-circle"
                    iconClassName="custom-circle-icon"
                    iconSource={IconsMap.svg.einkaufen}
                    bottomLabel={translateKey("summary-offer-slider-2-item-8")}
                  />
                  <CircleWithBadge
                    className="custom-circle"
                    iconClassName="custom-circle-icon"
                    iconSource={IconsMap.svg.gesellschaft}
                    bottomLabel={translateKey("summary-offer-slider-2-item-9")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item h-100" data-interval="false">
          <div className="carousel-section d-none d-md-block h-100">
            <div className="container-fluid no-gutters p-0 h-100">
              <div className="row no-gutters w-100">
                <div className="col-12 no-gutters">
                  <div className="section-title pt-0 pb-5">
                    {translateKey("summary-offer-slider-2-title-2")}
                  </div>
                </div>
              </div>
              <div className="row no-gutters h-100 w-100">
                <div className="col-12 no-gutters h-100">
                  <CircleWithBadge
                    circleClassName="custom-big-circle mr-5"
                    iconClassName="custom-circle-big-icon"
                    iconSource={IconsMap.svg.certyfikat}
                    rightNode={
                      <div className="d-flex flex-column">
                        <div className="section-title pt-0">
                          {translateKey("summary-offer-slider-2-title-2-subtitle-1")}
                        </div>
                        <div className="section-item pt-0">
                          {translateKey("summary-offer-slider-2-title-2-subtitle-2")}
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item h-100" data-interval="false">
          <div className="carousel-section d-none d-md-block h-100">
            <div className="container-fluid no-gutters p-0 h-100">
              <div className="row no-gutters w-100">
                <div className="col-12 no-gutters">
                  <div className="section-title pt-0 pb-5">
                    {translateKey("summary-offer-slider-2-title-2")}
                  </div>
                </div>
              </div>
              <div className="row no-gutters h-100 w-100">
                <div className="col-12 no-gutters h-100">
                  <CircleWithBadge
                    circleClassName="custom-big-circle mr-5"
                    iconClassName="custom-circle-big-icon"
                    iconSource={IconsMap.svg.certyfikat}
                    rightNode={
                      <div className="d-flex flex-column">
                        <div className="section-title pt-0">
                          {translateKey("summary-offer-slider-2-title-2-subtitle-3")}
                        </div>
                        <div className="section-item pt-0">
                          {translateKey("summary-offer-slider-2-title-2-subtitle-4")}
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item h-100" data-interval="false">
          <div className="carousel-section d-none d-md-block h-100">
            <div className="container-fluid no-gutters p-0 h-100">
              <div className="row no-gutters w-100">
                <div className="col-12 no-gutters">
                  <div className="section-title pt-0 pb-5">
                    {translateKey("summary-offer-slider-2-title-2")}
                  </div>
                </div>
              </div>
              <div className="row no-gutters h-100 w-100">
                <div className="col-12 no-gutters h-100">
                  <CircleWithBadge
                    circleClassName="custom-big-circle mr-5"
                    iconClassName="custom-circle-big-icon"
                    iconSource={IconsMap.svg.certyfikat}
                    rightNode={
                      <div className="d-flex flex-column">
                        <div className="section-title pt-0">
                          {translateKey("summary-offer-slider-2-title-2-subtitle-5")}
                        </div>
                        <div className="section-item pt-0">
                          {translateKey("summary-offer-slider-2-title-2-subtitle-6")}
                        </div>
                        <div className="section-item pt-0">
                          {translateKey("summary-offer-slider-2-title-2-subtitle-7")}
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });

export default connect(null, mapDispatchToProps)(SecondSliderContent);