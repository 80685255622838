import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../../_redux/actions/index";
import produce from "immer";
import TextAreaForMeetingSection from "../TextAreaForMeetingSection";
import ReqHelper from "../../../_helpers/request_helper";
import * as Gender from "../../../_constance/enums/Gender";
import InfoBoxes from "../../style-helpers/infoBoxes";
import { CheckBoxComponent } from "../../form-bb-components/checkbox-component/checkbox-component";
import Separator from '../../style-helpers/separator';
import * as TaskType from "../../../_constance/values/taskType";
import {
  getGreetings
} from '../../../_services/footer_formatter';

class MeetingSection2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arrangeForm: props.arrangeForm,
      section: props.arrangeForm.getSectionByKey("section-meeting"),
      customGreetingsText: props.customGreetingsText
    };
  }

  componentDidMount() {
    this.prepareMeBaseHtmlToShow();
  }

  setTypeChange = (key, value) => {
    const nextState = produce(this.state, (draftState) => {
      const { section } = draftState;
      const field = section.getFieldByKey(key);
      if (field) {
        field.value = value;
      }
    });
    this.setState(nextState);
  };

  getMeDayText = (date) =>{
    const { translateKey } = this.props;
    const dateToConvert = new Date(date);
    let dayNumber = dateToConvert.getDay();
    let dayText = '';
    switch (dayNumber){
      case 0: dayText = translateKey('arrange-meeting-day-sunday'); break;
      case 1: dayText = translateKey('arrange-meeting-day-monday'); break;
      case 2: dayText = translateKey('arrange-meeting-day-thuesday'); break;
      case 3: dayText = translateKey('arrange-meeting-day-wednesday'); break;
      case 4: dayText = translateKey('arrange-meeting-day-thursday'); break;
      case 5: dayText = translateKey('arrange-meeting-day-friday'); break;
      case 6: dayText = translateKey('arrange-meeting-day-saturday'); break;
      default:
    }
    return dayText;
  }

  getMeDayTextAfter = (date) =>{
    const { translateKey } = this.props;
    const dateToConvert = new Date(date);
    let dayNumber = dateToConvert.getDay();
    let dayText = '';
    switch (dayNumber){
      case 0: dayText = translateKey('arrange-meeting-day-monday-after'); break;
      case 1: dayText = translateKey('arrange-meeting-day-thuesday-after'); break;
      case 2: dayText = translateKey('arrange-meeting-day-wednesday-after'); break;
      case 3: dayText = translateKey('arrange-meeting-day-thursday-after'); break;
      case 4: dayText = translateKey('arrange-meeting-day-friday-after'); break;
      case 5: dayText = translateKey('arrange-meeting-day-saturday-after'); break;
      case 6: dayText = translateKey('arrange-meeting-day-sunday-after'); break;
      default:
    }
    return dayText;
  }

  prepareMeBaseHtmlToShow(){
    const { section } = this.state;
    const { translateKey, fpFirstMail, fpName, fpPhone } = this.props;
    const meetingNurDieMaterialen = section.getFieldByKey("meeting_nur_die_materialen");
    const meetingFugenSieDenLink = section.getFieldByKey('meeting_fugen_sie_den_link');
    const meetingFugtDokumenteHinzuField = section.getFieldByKey('meeting_fugt_dokumente_hinzu');
    //const meetingFirstName = section.getFieldByKey("meeting_first_name");
    const meetingLastName = section.getFieldByKey("meeting_last_name");
    const meetingGender = section.getFieldByKey("meeting_gender");
    let genderText = 'Sehr geehrte/r Herr / Frau';
    const tdStyles = 'border:0;padding:0;';
    const spanClass = 'font-weight:bold;color:#000000;';
    const breakLineTable = `<tr><td style="border:0;padding: 0px 0 20px 0;"></td></tr>`;
    if(meetingGender.value === parseInt(Gender.MALE)){
      genderText =  `Sehr geehrter ${translateKey('contract-text-herr')}`;
    }else{
      if(meetingGender.value === parseInt(Gender.FEMALE)){
        genderText = `Sehr geehrte ${translateKey('form-text-frau')}`;
      }
    }

    if(meetingFugenSieDenLink.value===true) {
      this.setTypeChange('mail_title', translateKey('metting-mail-title-with-link'));
    } else if (meetingFugenSieDenLink.value===false && meetingNurDieMaterialen.value===true) {
      this.setTypeChange('mail_title', translateKey('metting-mail-title-with-date'));
    } else if (meetingFugenSieDenLink.value===false && meetingNurDieMaterialen.value===false && meetingFugtDokumenteHinzuField.value===true) {
      this.setTypeChange('mail_title', translateKey('metting-mail-title-with-files'));
    } else {
      this.setTypeChange('mail_title', translateKey('metting-mail-title-default'));
    }

    const greetings = getGreetings(this.state.customGreetingsText, breakLineTable, tdStyles, fpName, fpPhone, fpFirstMail);

    if(meetingNurDieMaterialen.value===false && meetingFugenSieDenLink.value===false){
      // EMPTY EMAIL WITH 
      const htmlMailContent = `
      <table style="width:100%;color:black;border:0;outline:0;">
        <tbody>
          <tr><td style="${tdStyles}">${genderText} <span style="${spanClass}">${meetingLastName.value}</span>,</td></tr>
          ${breakLineTable}
          <tr><td style="${tdStyles}">Vielen Dank für das freundliche Gespräch und Ihr Interesse am Promedica24-Angebot. Als Anlage sende ich Ihnen einige Materialien, damit Sie sich mit diese bekannt machen können.</td></tr>
          ${breakLineTable}
          <tr><td style="${tdStyles}">Wenn Sie Fragen haben, stehe ich Ihnen zur Verfügung.</td></tr>
          ${greetings}
        </tbody>
      </table>
      `;
      this.setTypeChange('meeting_email_content', htmlMailContent);
    }else{
      const meetingTimeStamp = section.getFieldByKey("meeting_timestamp");
      const hrefToWebsite = section.getFieldByKey('meeting_kundenbereich_link').value;
      const meetingFugtDokumenteHinzu = section.getFieldByKey('meeting_fugt_dokumente_hinzu').value;
      const meetingArtDesGesprachs = section.getFieldByKey("meeting_art_des_gesprachs").value;
      const meetingStrasse = section.getFieldByKey("meeting_strasse").value;
      const meetingStrasseNr = section.getFieldByKey("meeting_strasse_nr").value;
      const meetingWohungsnummer = section.getFieldByKey("meeting_wohungsnummer").value;
      const meetingPlz = section.getFieldByKey("meeting_plz").value;
      const meetingStadt = section.getFieldByKey("meeting_stadt").value;
      
      let previousDay = new Date(meetingTimeStamp.value);
      previousDay.setDate(previousDay.getDate() - 1);
      let forwardDate = new Date();
      forwardDate.setDate(forwardDate.getDate() + 3);
      /*
        <tr>
          <td style="${tdStyles}">
            <table style="border:0;outline:0;display:flex; width">
              <tbody style="display:block;border:1px solid #6aaa20;background-color:rgb(242,248,222);padding:2em;width: 100%;">
                <tr style="background-color:rgb(242,248,222);"><td style="${tdStyles}">Website-Adresse: <span style="${spanClass}"><a style="${spanClass} cursor:pointer;" href="${hrefToWebsite}">${hrefToWebsite}</a></span></td></tr>
                <tr style="background-color:rgb(242,248,222);"><td style="${tdStyles}">Passwort: <span style="${spanClass}">${this.props.pass}</span></td></tr>
              </tbody>
            </table>
          </td>
        </tr>
      */
      const newBUttonAndPassHtml = `
        <tr>
          <td style="${tdStyles} padding: 0px 0px 10px 0px; color: #153643; font-size: 24px;">
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="color:black;border:0;outline:0;">
                  <tr>
                      <td width="25%" bgcolor="#ffffff" style="${tdStyles}">
                      </td>
                      <td valign="center" width="50%" bgcolor="#0056a4" style="${tdStyles} cursor:pointer; padding: 20px 0 20px 0; color: #153643; font-size: 16px; line-height: 20px; text-align: center;">
                          <a href="${hrefToWebsite}" style="text-decoration: none; color: #ffffff; font-size: 13px; line-height: 15px;"><strong style="font-size: 19px; line-height: 25px;">${translateKey("meetings-click-here")}</strong><br>zur Seite gehen</a>
                      </td>
                      <td width="25%" bgcolor="#ffffff" style="${tdStyles}">
                          &nbsp;
                      </td>
                  </tr>
              </table>
          </td>
        </tr>
        <tr>
          <td style="${tdStyles} color: #153643; font-size: 18px; line-height: 20px; text-align: center;">
              <strong>Passwort:</strong><span> ${this.props.pass}</span>
          </td>
        </tr>
      `;
      const htmlMailContent = `
      <table style="width:100%;color:black;border:0;outline:0;">
        <tbody>
          <tr><td style="${tdStyles}">${genderText} <span style="${spanClass}">${meetingLastName.value}</span>,</td></tr>
          ${breakLineTable}
          <tr><td style="${tdStyles}">vielen Dank für Ihr Interesse an einer 24 Stunden Betreuung von Promedica24.</td></tr>
          ${ 
            meetingFugtDokumenteHinzu ? `
              ${breakLineTable}
              <tr><td style="${tdStyles}">Anbei übersende ich Ihnen, wie gewünscht, unsere Informationsmaterialien.</td></tr>`
              : ``
          }
          ${ 
            meetingFugenSieDenLink.value ? `
                ${breakLineTable}
                <tr><td style="${tdStyles}">Den Link zu Ihrem Kundenbereich und dem Betreuungsfragebogen finden Sie hier:</td></tr>
                ${breakLineTable}
                ${newBUttonAndPassHtml}
                ${breakLineTable}
                <tr>
                    <td style="color: #ff0000; font-size: 12px; text-align: center;  border: 1px solid red;">
                        <p style="color: #ff0000; margin:0px 0px 0px 0px; padding: 0px 0px 0px 0px;">Falls der Link sich nicht automatisch öffnet, kopieren Sie ihn bitte in den Internetbrowser.</p>
                        <p style="color:#000000; margin:0px 0px 0px 0px; padding: 0px 0px 0px 0px; line-height: 15px;">${hrefToWebsite}</p>
                    </td>
                </tr>
                ${breakLineTable}
                <tr><td style="${tdStyles}">Der Betreuungsfragebogen unterstützt uns dabei, Ihre individuelle Pflegesituation einzuschätzen und Ihnen die bestmögliche Betreuungslösung anzubieten.</td></tr>
                ${breakLineTable}
                ${ 
                  !meetingNurDieMaterialen.value ? `
                    <tr><td style="${tdStyles}">Wir würden uns freuen, wenn Sie unseren Betreuungsfragebogen bis <span style="${spanClass}">${this.getMeDayTextAfter(forwardDate)}, den ${ReqHelper.dateConversion(forwardDate.getTime(), false)}, 12:00 Uhr</span> ausfüllen könnten.</td></tr>
                    `
                    : `<tr><td style="${tdStyles}">Wir würden uns freuen, wenn Sie unseren Betreuungsfragebogen bis <span style="${spanClass}">${this.getMeDayTextAfter(previousDay)}, den ${ReqHelper.dateConversion(previousDay.getTime(), false)}, 12:00 Uhr</span> ausfüllen könnten.</td></tr>`
                }
              `
              : `` 
          }
          ${breakLineTable}
          ${ 
            meetingNurDieMaterialen.value ? `
            <tr><td style="${tdStyles}">Gerne bestätigen wir Ihnen den ${ meetingArtDesGesprachs === TaskType.EVENT ? 'Termin' : 'Telefon-Termin' } <span style="${spanClass}">am ${this.getMeDayText(meetingTimeStamp.value)} (${ReqHelper.dateConversion(meetingTimeStamp.value, false)}) um ${ReqHelper.dateConversionTime(meetingTimeStamp.value, false)} Uhr.</span></td></tr>
            ${breakLineTable}
            <tr><td style="${tdStyles}">Sollten Sie den Termin nicht wahrnehmen können, lassen Sie es mich bitte zeitnah wissen.</td></tr>
            ${breakLineTable}
            ${ meetingArtDesGesprachs === TaskType.EVENT ?
              `<tr><td style="${tdStyles}"> ${ translateKey("meeting-address-email") } ${meetingStrasse} ${meetingStrasseNr}${meetingWohungsnummer ? `/${meetingWohungsnummer}` : ''}, ${meetingPlz} ${meetingStadt}, statt. </td></tr>
              ${breakLineTable}
              `
              : ``
            }
            `
            : ``
          }
          <tr><td style="${tdStyles}">Bei Fragen stehe ich Ihnen gerne jederzeit zur Verfügung.</td></tr>
          ${greetings}
        </tbody>
      </table>
      `;
      this.setTypeChange('meeting_email_content', htmlMailContent);
    }
  }

  nextPageClick= () =>{
    this.props.nextPageClick();
  }

  previousPageClick= () =>{
    this.props.previousPageClick();
  }

  render() {
    const { translateKey, title } = this.props;
    const { section } = this.state;
    
    const meetingEmailContent = section.getFieldByKey("meeting_email_content");
    // CHECHBOXES
    const meetingBetreuungsfragebogen = section.getFieldByKey("meeting_betreuungsfragebogen");
    const meetingIsoZertifikat = section.getFieldByKey("meeting_iso_zertifikat");
    const meetingSicher = section.getFieldByKey("meeting_sicher_und_gut_versorgt");
    const meetingPflegeratgeber = section.getFieldByKey("meeting_pflegeratgeber");
    const meetingPrasentationPm24 = section.getFieldByKey("meeting_prasentation_pm24");
    const meetingTuvZertifikat = section.getFieldByKey("meeting_tuv_zertifikat");
    const meetingUrlaubVonDePflege = section.getFieldByKey("meeting_urlaub_von_de_pflege");
    const meetingVerhinderung = section.getFieldByKey("meeting_verhinderung");
    const meetingVollmacht = section.getFieldByKey("meeting_vollmacht");
    const meetingCopyToFP = section.getFieldByKey("meeting_copy_to_fp");
    const meetingMultiplePatients = section.getFieldByKey("meeting_multiple_patients");
    const meetingCareBoxLeaflet = section.getFieldByKey("meeting_carebox_leaflet");
    const meetingKundeportal = section.getFieldByKey("meeting_kundeportal");

    return (
      <div className="form-section-main">
        <div className="new-section-name-wrapper">
          {title}
        </div>
        <form className="new-section-content-wrapper container form_serializer">
          <div className="newUI-section-padding">
            <div className="row">
              <div className="col-sm-12 col-lg-12 ">
                <label className="form-label-basic newUI">
                  {translateKey("arrange-meeting-nachrichtenvor").toUpperCase()}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-12 arrange_meeting">
                <TextAreaForMeetingSection html={meetingEmailContent.value} changeEditorData={this.setTypeChange}/>
              </div>
            </div>
          </div>
          <div className="newUI-section-padding">
              <div className="row">
                <div className="col-sm-12 col-lg-12 ">
                  <label className="form-label-basic newUI">
                    {translateKey("arrange-meeting-nachrichten").toUpperCase()}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-lg-12">
                  {meetingBetreuungsfragebogen.value===true &&
                    <InfoBoxes 
                      type="pdf"
                      name={translateKey("meeting_betreuungsfragebogen")}
                    />
                  }

                  {meetingIsoZertifikat.value===true &&
                    <InfoBoxes 
                      type="pdf"
                      name={translateKey("docs-iso-cert")}
                    />
                  }

                  {meetingSicher.value===true &&
                    <InfoBoxes 
                      type="pdf"
                      name={translateKey("meeting_sicher")}
                    />
                  }

                  {meetingPflegeratgeber.value===true &&
                    <InfoBoxes 
                      type="pdf"
                      name={translateKey("docs-advisor")}
                    />
                  }

                  {meetingPrasentationPm24.value===true &&
                    <InfoBoxes 
                      type="pdf"
                      name={translateKey("docs-presentation")}
                    />
                  }

                  {meetingTuvZertifikat.value===true &&
                    <InfoBoxes 
                      type="pdf"
                      name={translateKey("docs-tuv-cert")}
                    />
                  }

                  {meetingUrlaubVonDePflege.value===true &&
                    <InfoBoxes 
                      type="pdf"
                      name={translateKey("docs-vacations")}
                    />
                  }

                  {meetingVerhinderung.value===true &&
                    <InfoBoxes 
                      type="pdf"
                      name={translateKey("docs-care")}
                    />
                  }

                  {meetingVollmacht.value===true &&
                    <InfoBoxes 
                      type="pdf"
                      name={translateKey("docs-fullpower")}
                    />
                  }

                  {meetingCareBoxLeaflet.value===true &&
                      <InfoBoxes
                          type="pdf"
                          name={translateKey("docs-care-box-leaflet")}
                      />
                  }
                  {meetingKundeportal.value===true &&
                      <InfoBoxes
                          type="pdf"
                          name={translateKey("docs-kundeportal")}
                      />
                  }
                </div>
              </div>
          </div>
          <Separator />
          <div className="newUI-section-padding">
            <div className="row">
              <div className="col-sm-12 col-lg-12 check-box-padding-bottom-newUI">
                <CheckBoxComponent
                  newUI={true}
                  checkClassWrapper='newUI-radio-check-box-class'
                  showValidation={false}
                  className="col-sm-12 col-lg-12"
                  setTypeChangeToParent={this.setTypeChange}
                  value={meetingCopyToFP.value}
                  validationErrors={meetingCopyToFP.validationErrors}
                  text={translateKey("meeting-copy-to-fp")}
                  transKey="meeting_copy_to_fp"
                  keyName="meeting_copy_to_fp"
                />
              </div>
            </div>
          </div>
        </form>
        <div className='zone-form-navigation-buttons'>
          <div className="previous-next-wrapper">
            <button type="button" className="btn btn-primary cursor-pointer next-previous-class prev-next-normal-button" style={{margin: '5px'}} onClick={this.previousPageClick}>
              <i className="fas fa-chevron-left fa-1x section-navigation-icon"></i>
              <div className="section-navigation-button-text">{translateKey("previous").toUpperCase()}</div>
            </button>
            <button type="button" className="btn btn-primary cursor-pointer next-previous-class prev-next-active-button" style={{margin: '5px'}} onClick={this.nextPageClick}>
              <div className="section-navigation-button-text special-width">{translateKey("next").toUpperCase()}</div>
              <i className="fas fa-chevron-right fa-1x section-navigation-icon"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    fpFirstMail: state.auth.contactFpFirstEmail,
    fpName: state.auth.contactFpName,
    fpPhone: state.auth.FPphone,
  };
};
const mapDispatchToProps = (dispatch) => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeetingSection2));
