import * as React from 'react';

export class CheckBoxSmallMultipleComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text,
        };
    }

    setTypeChange(key, value){
        const { disabled = false } = this.props;
        if (!disabled) {
            this.props.setTypeChangeToParent(key, value);
        }
    }

    setCheckboxValue = (value) => {
        const { disabled = false } = this.props;
        if (!disabled) {
            this.props.setTypeChangeToParent(this.props.keyName, value);
        }
    };
 
    render() {
        const { className, field, keyName, fieldName, text, newUI, disabled = false } = this.props;
        return (
            <div className={`custom-control custom-checkbox ${className}`}>
                <i className={`fas fa-2x fa-check check-fa-checkbox-icon ${field.value==="True" || field.value===true?'check-fa-active':'check-fa-not-active'} ${disabled ?'disabled-checkbox':''}`} onClick={()=>this.setCheckboxValue(!field.value)}></i>
                <input 
                    className="form-check-input" 
                    checked={field.value==="True" || field.value===true? true: false} 
                    onChange={e => this.setTypeChange(keyName, e.target.checked)} 
                    value={field.value} 
                    data-field-name={keyName} 
                    type="checkbox" 
                    name={fieldName}
                    id={fieldName}
                    hidden
                    disabled={disabled}
                />
                <label className={`form-check-label cursor-pointer break-word ${newUI? 'newUI-text-color':''}`} htmlFor={fieldName}>{text}</label>
            </div>
        );
    }
}