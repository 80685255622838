
import { SET_PRICE_FORMAT } from "../actions/price-types";

const initialState = {
  priceType: 'monthly',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICE_FORMAT:
      return {
        ...state,
        priceType: action.priceType,
      };
    default:
      return state;
  }
};

export default reducer;