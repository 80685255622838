import * as React from 'react';
import { MDBModal, MDBModalBody, MDBNav, MDBCloseIcon } from 'mdbreact';
import NewMultiplierModalPage from '../../_pages/multipliers/newMultiplierModal';;

export class NewMultiplierModal extends React.Component {

    selectedMultiplier = (multiplier) => {
        this.props.selectedMultiplier(multiplier);
    }

    render() {
        return (  
            <MDBModal isOpen={this.props.show} toggle={this.reject} fullHeight size="lg" centered={true} className="multiplier-popup-holder">
                <MDBNav className="multiplier-popup-nav">
                    <MDBCloseIcon onClick={this.props.onClose}></MDBCloseIcon>
                </MDBNav>
                <MDBModalBody className="multiplier-popup-body">
                    <NewMultiplierModalPage selectedMultiplier={this.selectedMultiplier}/>
                </MDBModalBody>
            </MDBModal>
        )
    }
}