import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ListControlComponent from "../../form-bb-components/list-control-component/list-control-component";
import ListMovementComponent from "../../form-bb-components/list-movement-component/list-movement-component";
import * as SkillRange from '../../../_forms/BBForm/values/skillRange';
import {InputTypeTextComponent} from "../../form-bb-components/input-type-text-component/input-type-text-component";
import {CheckBoxSmallMultipleComponent} from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import SliderRangeComponent from "../../form-bb-components/slider-range-component/slider-range-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import { ReqHelper } from "../../../_helpers/index";
import { Tiles } from "../../../_constance/classes/dndPriorityOffer/TilesIds";
import produce from "immer";

const STATIC_ENUM_FOR_5_AND_MORE = '5 und Mehr'; 
const marks = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "Mehr" }
];

const IS_USE_TOILET_OR_TOILET_CHAIR_ALONE = "is_use_toilet_or_toilet_chair_alone";
const IS_IF_LIMITED_HOW_OFTEN = "is_if_limited_how_often";
const IS_URINATION_CONTROL = "is_urination_control";
const IS_CONTROL_OF_DEFECATION = "is_control_of_defecation";
const IS_CATHETER_LIST  = "is_catheter_list";
const IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL  = "is_catheterized_through_the_abdominal_wall";
const IS_BEDPAN_A_LIST  = "is_bedpan_a_list";

// filed to store tiles data
const KEY_NAME_TILES = 'is_most_important_taking_care_patient';
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles'; 

class Section9 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-9'),
            showValidation: props.showValidationErrors,
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    saveDiabetesAndTiles = () =>{
        const saveDiabetesTileField = this.props.form.getFieldByKey(SKIP_SAVE_STRING_TILES);
        if(saveDiabetesTileField){saveDiabetesTileField.value = true}
      }

    setTileFieldValue = (action, string) =>{
        const { form } = this.props;
        const tilesString = form.getFieldByKey(KEY_NAME_TILES);
        const list = ReqHelper.prepareMeListFromString(tilesString.value, ';');
        if(ReqHelper.notNullAndUndefinied(tilesString.value) && action===true){
            if(list.indexOf(string)<0){
                tilesString.value += ';'+string;
            }
        }else{
            if(action){
                tilesString.value += ';'+string;
            }else{
                if(list.indexOf(string)>-1){
                    tilesString.value += ';'+string;
                    list.splice(list.indexOf(string),1);
                    tilesString.value = list.join(';')
                }
            }
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
            if(key===IS_URINATION_CONTROL || key===IS_CONTROL_OF_DEFECATION){
                if(value === SkillRange.LIMITED){
                    this.setTileFieldValue(true, Tiles.TILESIXPANTIES);
                    this.saveDiabetesAndTiles();
                }else{
                    let conditionField = section.getFieldByKey(IS_CONTROL_OF_DEFECATION);
                    if(key===IS_CONTROL_OF_DEFECATION){
                        conditionField = section.getFieldByKey(IS_URINATION_CONTROL);
                    }
                    if(conditionField.value !== SkillRange.LIMITED){
                        this.setTileFieldValue(false, Tiles.TILESIXPANTIES);
                        this.saveDiabetesAndTiles();
                    }
                }
            }else{
                if(key===IS_CATHETER_LIST || key===IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL || key===IS_BEDPAN_A_LIST){
                    if(value){
                        this.setTileFieldValue(value, Tiles.TILETENBAG)
                        this.saveDiabetesAndTiles();
                    }else{
                        const conditionFieldOne = section.getFieldByKey(IS_CATHETER_LIST);
                        const conditionFieldTwo = section.getFieldByKey(IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL);
                        const conditionFieldThree = section.getFieldByKey(IS_BEDPAN_A_LIST);
                        switch(key){
                            case IS_CATHETER_LIST: 
                                if(!conditionFieldTwo.value && !conditionFieldThree.value){
                                    this.setTileFieldValue(false, Tiles.TILETENBAG);
                                    this.saveDiabetesAndTiles();
                                }
                                break;
                            case IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL: 
                                if(!conditionFieldOne.value && !conditionFieldThree.value){
                                    this.setTileFieldValue(false, Tiles.TILETENBAG);
                                    this.saveDiabetesAndTiles();
                                }
                                break;
                            case IS_BEDPAN_A_LIST: 
                                if(!conditionFieldOne.value && !conditionFieldTwo.value){
                                    this.setTileFieldValue(false, Tiles.TILETENBAG);
                                    this.saveDiabetesAndTiles();
                                }
                                break;
                            default:
                        }
                    }
                }
            }
        });
        this.setState(nextState);
    };

    setTypeIfNumber = (key, value) => {
        if(ReqHelper.isInteger(value)){
            this.setTypeChange(key, value);
        }else{
            if(value.trim()===''){
                this.setTypeChange(key, value);
            }else{
                const { section } = this.state;
                const field = section.getFieldByKey(key);
                if(field){
                    if(field.value.length>value.length){
                        this.setTypeChange(key, '');
                    }
                }

            }
        }
    } 

    setSliderRangeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                if(value===5){
                    field.value = STATIC_ENUM_FOR_5_AND_MORE;
                }else{
                    field.value = value; 
                }
            }
        });
        this.setState(nextState);
    }

    getMeSpecialValueFromKey(){
        const { section } = this.state;
        const isIfLimitedHowOften = section.getFieldByKey(IS_IF_LIMITED_HOW_OFTEN);
        if(isIfLimitedHowOften.value === STATIC_ENUM_FOR_5_AND_MORE){
            return 5;
        }else{
            let found = marks.find(element => element.value.toString() === isIfLimitedHowOften.value.toString());
            if(found){
                return isIfLimitedHowOften.value
            }else{
                return 0
            }
        }
    }

    render() {
        const {
          disabled,
          sectionNumber,
          subsectionName,
          showValidationErrors,
          translateKey,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;

        const isUseToiletOrToiletChairAlone = section.getFieldByKey(IS_USE_TOILET_OR_TOILET_CHAIR_ALONE);
        const isIfLimitedHowOften = section.getFieldByKey(IS_IF_LIMITED_HOW_OFTEN);
        const isUrinationControlField = section.getFieldByKey(IS_URINATION_CONTROL);
        const isControlOfDefecationField = section.getFieldByKey(IS_CONTROL_OF_DEFECATION);
        const isCatheterListField = section.getFieldByKey(IS_CATHETER_LIST);
        const isCatheterizedThroughField = section.getFieldByKey(IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL);
        const isBedpanAListField = section.getFieldByKey(IS_BEDPAN_A_LIST);

        const isNewUI=true;
        const classForCheckBox = "custom-control-inline illness-wrapper newUI-radio-check-box-class cursor-pointer";

        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListControlComponent 
                                    newUI={isNewUI} 
                                    type="column" 
                                    showValidation={showValidationErrors} 
                                    setTypeChangeToParent={this.setTypeChange} 
                                    className="col-sm-12 col-lg-3 form-margin-bottom-fix" 
                                    transKey="form-text-urininkontinenz" 
                                    key={IS_URINATION_CONTROL}
                                    keyName={IS_URINATION_CONTROL}
                                    field={isUrinationControlField}
                                    value={isUrinationControlField.value} 
                                    validationErrors={isUrinationControlField.validationErrors} 
                                />
                                <ListControlComponent 
                                    newUI={isNewUI} 
                                    type="column" 
                                    showValidation={showValidationErrors} 
                                    setTypeChangeToParent={this.setTypeChange} 
                                    className="col-sm-12 col-lg-3 form-margin-bottom-fix" 
                                    transKey="form-text-stuhlinkontinenz" 
                                    key={IS_CONTROL_OF_DEFECATION}
                                    keyName={IS_CONTROL_OF_DEFECATION}
                                    field={isControlOfDefecationField}
                                    value={isControlOfDefecationField.value} 
                                    validationErrors={isControlOfDefecationField.validationErrors} 
                                />
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListMovementComponent 
                                    newUI={isNewUI} 
                                    type="column" 
                                    showValidation={showValidationErrors} 
                                    setTypeChangeToParent={this.setTypeChange} 
                                    className="col-sm-12 form-margin-bottom-fix" 
                                    transKey="form-text-toilettenstuhlsmoglich" 
                                    key="is_use_toilet_or_toilet_chair_alone" 
                                    keyName="is_use_toilet_or_toilet_chair_alone"
                                    field={isUseToiletOrToiletChairAlone}
                                    value={isUseToiletOrToiletChairAlone.value} 
                                    validationErrors={isUseToiletOrToiletChairAlone.validationErrors}
                                />
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                { isUseToiletOrToiletChairAlone.value !== SkillRange.UNLIMITED && (
                                    <SliderRangeComponent
                                        keyName={'is_if_limited_how_often'}
                                        value={this.getMeSpecialValueFromKey()}
                                        showValidation={showValidationErrors}
                                        validationErrors={isIfLimitedHowOften.validationErrors}
                                        className="col-sm-12 col-lg-6"
                                        disabled={disabled}
                                        text={translateKey("form-text-wieoft-toilet")}
                                        minValue={0}
                                        maxValue={5} 
                                        step={1}
                                        marks={marks}
                                        setTypeChangeToParent={this.setSliderRangeChange}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="form-label-basic newUI cursor-pointer quick-label-position-fix">{translateKey("form-text-hilfsmittel").toUpperCase()}</div>
                            <div className="checkboxes_wrapper row">
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxSmallMultipleComponent 
                                        newUI={isNewUI} 
                                        className={classForCheckBox} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        field={isCatheterListField} 
                                        text={translateKey("form-text-blasenkatheter")} 
                                        transKey="form-text-blasenkatheter"
                                        fieldName={IS_CATHETER_LIST} 
                                        keyName={IS_CATHETER_LIST}
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxSmallMultipleComponent 
                                        newUI={isNewUI} 
                                        className={classForCheckBox}
                                        setTypeChangeToParent={this.setTypeChange} 
                                        field={isCatheterizedThroughField} 
                                        text={translateKey("form-text-suprapubischer")} 
                                        transKey="form-text-suprapubischer" 
                                        fieldName={IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL} 
                                        keyName={IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL}
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxSmallMultipleComponent 
                                        newUI={isNewUI} 
                                        className= {classForCheckBox} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        field={isBedpanAListField}
                                        text={translateKey("form-text-urinflasche")} 
                                        transKey="form-text-urinflasche"
                                        keyName={IS_BEDPAN_A_LIST}
                                        fieldName={IS_BEDPAN_A_LIST}
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxSmallMultipleComponent 
                                        newUI={isNewUI} 
                                        className= {classForCheckBox} 
                                        fieldName={"form-text-vorlagen"} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        field={section.getFieldByKey('is_inserts_list')}
                                        text={translateKey("form-text-vorlagen")} 
                                        transKey="form-text-vorlagen" 
                                        keyName="is_inserts_list"
                                    />
                                </div>
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxSmallMultipleComponent 
                                        newUI={isNewUI} 
                                        className= {classForCheckBox} 
                                        fieldName={"form-text-windeln"} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        field={section.getFieldByKey('is_nappies_list')} 
                                        text={translateKey("form-text-windeln")}
                                        transKey="form-text-windeln"
                                        keyName="is_nappies_list"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix top-too" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_aids_other_de')} text={translateKey("form-text-sonstige-gem")} transKey="form-text-sonstige-gem" keyName="is_aids_other_de" />
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                /> 
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(null, mapDispatchToProps)(Section9));
