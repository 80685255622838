import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import * as SkillRange from '../../../_forms/BBForm/values/skillRange';
import ListComponent from "../list-component/list-component"; 

class ListMovementComponent extends React.Component {
    render() {
        const { keyName, value, validationErrors, showValidation, className, translateKey, transKey, setTypeChangeToParent, type, newUI, icon, svgIcon, field} = this.props;
        return (
            <ListComponent
                newUI={newUI}
                keyName={keyName}
                field={field}
                value={value}
                validationErrors={validationErrors}
                showValidation={showValidation}
                className={className}
                transKey={transKey}
                type={type}
                icon={icon}
                svgIcon={svgIcon}
                valuesList={[
                    { value: SkillRange.UNLIMITED, label: translateKey("form-text-uneingeschrankt") },
                    { value: SkillRange.PARTIAL, label: translateKey("form-text-teilweise") },
                    { value: SkillRange.LIMITED, label: translateKey("form-text-eingeschrankt") },
                ]}
                setTypeChangeToParent={setTypeChangeToParent}
            />
        );
    }
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(ListMovementComponent));