export const NEW_LEAD = "717700000"
export const FIRST_CONTACT_MADE = "717700001"
export const MEETING_PLANNED = "717700002"
export const MEETING_HELD = "717700003"
export const CONTRACT_SIGNED = "717700004"
export const REJECTED = "717700005"
export const ON_HOLD = "717700006"
export const METTING_PLANNED = "717700008"
export const RENEWED_CONTACT = "717700012"
export const INTERESTS_NOT_ACHIVED = "717700013"
export const DOPPELTER_LEAD = "717700014"
export const INFOMATERIAL_TERMINEINLADUG_VERSCHICK = "717700015"
export const WEITERGELEITET_AN_FRANCHISEPARTNER = "717700016"
export const ABSAGE_VERFAHRENSGRUNDE = "717700017"
export const REAKTIVIERT_DURCH_QUALITATSMANAGEMENT = "717700019"
export const CONTRACT_DECLINED = "717700020"
export const KEINE_ANFRAGE = "717700021"
export const PHONE_METTING = "717700022"
export const SENDED_BB = "717700023"
export const CLIENT_SENDED_BB = "717700024"
export const LEAD_MENAGMENT_VERIFICATION = "717700026"
export const SIGNED_CONTRACT_PAPER = "717700027"
export const SIGNED_CONTRACT_DOCKUSIGN = "717700028"

export const DE_LIST = [
    KEINE_ANFRAGE,
    FIRST_CONTACT_MADE,
    WEITERGELEITET_AN_FRANCHISEPARTNER,
    INTERESTS_NOT_ACHIVED,
    MEETING_PLANNED,
    PHONE_METTING,
    SENDED_BB,
    CLIENT_SENDED_BB,
    METTING_PLANNED,
    MEETING_HELD,
    LEAD_MENAGMENT_VERIFICATION,
    DOPPELTER_LEAD,
    REJECTED,
    NEW_LEAD,
    CONTRACT_DECLINED,
    ABSAGE_VERFAHRENSGRUNDE,
    SIGNED_CONTRACT_PAPER,
    SIGNED_CONTRACT_DOCKUSIGN
]
export const DE_LIST_CC_DE = [
    KEINE_ANFRAGE,
    FIRST_CONTACT_MADE,
    WEITERGELEITET_AN_FRANCHISEPARTNER,
    INTERESTS_NOT_ACHIVED,
    MEETING_PLANNED,
    PHONE_METTING,
    SENDED_BB,
    CLIENT_SENDED_BB,
    METTING_PLANNED,
    MEETING_HELD,
    LEAD_MENAGMENT_VERIFICATION,
    DOPPELTER_LEAD,
    REJECTED,
    NEW_LEAD,
    CONTRACT_DECLINED,
    ABSAGE_VERFAHRENSGRUNDE,
    SIGNED_CONTRACT_PAPER,
    SIGNED_CONTRACT_DOCKUSIGN
]
export const DE_LIST_FOR_SORTING = [
    REAKTIVIERT_DURCH_QUALITATSMANAGEMENT,
    KEINE_ANFRAGE,
    FIRST_CONTACT_MADE,
    WEITERGELEITET_AN_FRANCHISEPARTNER,
    INTERESTS_NOT_ACHIVED,
    MEETING_PLANNED,
    PHONE_METTING,
    SENDED_BB,
    CLIENT_SENDED_BB,
    METTING_PLANNED,
    MEETING_HELD,
    LEAD_MENAGMENT_VERIFICATION,
    DOPPELTER_LEAD,
    REJECTED,
    NEW_LEAD,
    CONTRACT_DECLINED,
    ABSAGE_VERFAHRENSGRUNDE,
    SIGNED_CONTRACT_PAPER,
    SIGNED_CONTRACT_DOCKUSIGN,
    CONTRACT_SIGNED,
    INFOMATERIAL_TERMINEINLADUG_VERSCHICK,
]
export const EN_LIST = [
    NEW_LEAD,
    FIRST_CONTACT_MADE,
    MEETING_PLANNED,
    MEETING_HELD,
    REJECTED,
    ON_HOLD
]
export const HIDDEN_ELEMENTS = [
    FIRST_CONTACT_MADE,
    WEITERGELEITET_AN_FRANCHISEPARTNER,
    NEW_LEAD,
    CONTRACT_DECLINED
]