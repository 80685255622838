export const duplicatedIngredients = [
    "is_contactid_duplicated",
    "is_name_duplicated",
    "is_status_duplicated",
    "ownerid_duplicated",
];

export const toDuplicatIngredients = [
    "is_contactid",
    "is_name",
    "is_status",
    "ownerid",
];
