
import axiosAuth from './config/axios-auth';
import { sendErrorLogToBackend } from './backend_service';

export const sendDocuSignCarebox = function(templateId, ingredientId) {
    const data = {
        "DocuSignTemplateId": templateId,
        "CareBoxIngridientId": ingredientId,
    }
    return axiosAuth.post('/docuSign/careBoxIngridient/send', data)
        .then(response => { return response;})
        .catch(error => {
            const errorContent = {
                fpName: data.ContactFpName,
                loginDS: data.LoginDS,
                page: "CARE BOX DOCU SIGN SEND",
                api: "/docuSign/careBoxIngridient/send",
                content: data,
                error: error
            }
            sendErrorLogToBackend(errorContent).catch((error2) => {console.log(error2);});
            return error;
        });
}

export const sendDocuSign = function (data, signatureMethod, isPilotUser) {
    // DOCUSINGTEMPLATE "76c60a19-1208-4e2b-b1c9-5423880dae46" or "ed37d3c9-c179-45cc-84f0-dc5002ab6028"
    /*
    {
        "ContractId": - CRMowy identyfikator kontraktu zwrócony przez API po przesłaniu danych z ekranów Die Bestellung i utworzeniu nowego kontraktu,
        "DocuSignTemplateId" - jeżeli na ekranie http://mediahub.promedica24.com.pl/sc/gallery/aplikacja/#lg=1&slide=62 w polu DLV zostanie wybrana wartość 
        "als Bevolmacht..." to "76c60a19-1208-4e2b-b1c9-5423880dae46" jeżeli wartość "zugunsten eines..." to "ed37d3c9-c179-45cc-84f0-dc5002ab6028"
        "ContactFpName" - z reduxa
        "ContactFpFirstEmail" - z reduxa,
        "ClientName" - imię i nazwisko leada/klienta,
        "PCM": - Na Name i Email trzeba dodać dwa nowe pola tekstowe na ekranie http://mediahub.promedica24.com.pl/sc/gallery/aplikacja/#lg=1&slide=62
        "PCMEmail" - Na Name i Email trzeba dodać dwa nowe pola tekstowe na ekranie http://mediahub.promedica24.com.pl/sc/gallery/aplikacja/#lg=1&slide=62
        "City" -  wartość z pola "Ort der vertragsun..." z http://mediahub.promedica24.com.pl/sc/gallery/aplikacja/#lg=1&slide=62
    }
    */
    let inlineDataItems = [
        {
            "DSName": "Ort",
            "DSValue": data.City
        },
        {
            "DSName": "is_rate",
            "DSValue": data.Rate
        }
    ]
    if(data.InlineDataItems!==null){
        inlineDataItems.push(data.InlineDataItems);
    }
    if(data.AditionalData.length > 0){
        inlineDataItems.push(data.AditionalData[0]);
        inlineDataItems.push(data.AditionalData[1]);
    }
    const someDataToSend = {
        "ContractId": data.ContractId,
        "DocuSignTemplateId": data.DocuSignTemplateId,
        "DocuSignRecipients": [
            {
                "Name": data.ContactFpName,
                "Email": data.LoginDS,
                "Type": "signer",
                "HostName": null,
                "HostEmail": null
            },
            {
                "Name": data.ClientName,
                "Email": signatureMethod === 'Email' ? data.ClientEmail : null,
                "Type": signatureMethod === 'Email' ? "signer" : "inPersonSigner",
                "HostName": signatureMethod === 'Email' ? null : data.ContactFpName,
                "HostEmail": signatureMethod === 'Email' ? null : data.LoginDS,
            },
            {
                "Name": "Koordinator: Neuer Vertrag",
                "Email": "zgloszenie_nowej_umowy_DE@promedica24.pl",
                "Type": "cc",
                "HostName": null,
                "HostEmail": null
            },
            {
                "Name": data.PCM,
                "Email": data.PCMEmail,
                "Type": "cc",
                "HostName": null,
                "HostEmail": null
            }
        ],
        "InlineDataItems": inlineDataItems,
    }

    if (isPilotUser === 'True' && signatureMethod === 'VorOrt') {
        someDataToSend.DocuSignRecipients.push(
            {
                "Name": data.ClientName,
                "Email": data.ClientEmail,
                "Type": "cc",
                "HostName": null,
                "HostEmail": null
            }
        );
    }
    
    if(isPilotUser === 'True') {
        if (signatureMethod === 'VorOrt') {
            someDataToSend.DocusignAccessCodes = [{ "RoleName": "Kunde", "AccessCode": data.ClientPostalCode }];
        } else {
            someDataToSend.DocusignAccessCodes = [{ "RoleName": "Unterschriftengeber", "AccessCode": data.ClientPostalCode }];
        }
    } else {
        someDataToSend.DocusignAccessCodes = [];
    }

    return axiosAuth.post('/docuSign/contract/send', someDataToSend)
        .then(response => { return response;})
        .catch(error => {
            const errorContent = {
                fpName: data.ContactFpName,
                loginDS: data.LoginDS,
                page: "CONTRAT DOCU SIGN SEND",
                api: "/docuSign/contract/send",
                content: someDataToSend,
                error: error
            }
            sendErrorLogToBackend(errorContent).catch((error2) => {console.log(error2);});
            return error;
        });
}