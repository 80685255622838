import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import SignatureCanvas from 'react-signature-canvas';
import produce from "immer";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import { Asterisk } from "../../../_helpers/field_asterix";

// remove prefix for api save
const prefixEnum = 'data:image/png;base64,';

class RodoSectionSignature extends React.Component {
    sigPad = {}
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-rodo-signature'),
            showCanvas: false,
        };
    }
    
    componentWillUnmount() {
        this.setState({
            showCanvas: false
        })
    };

    clear = () => {
        this.sigPad.clear()
    }

    saveData=()=>{
        if(!this.props.disabled && !this.props.rodoDisabled){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey('rodo_signature');
                let baseCan = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
                baseCan = baseCan.replace(prefixEnum,'');
                if (field) {
                    field.value = baseCan;
                }
                draftState.showCanvas = false;
            });
            this.setState(nextState);
        }
    }

    showMeCanvas(){
        if(!this.props.disabled && !this.props.rodoDisabled){
            this.setState({showCanvas: true})
        }
    }

    cancelCanvas=()=>{
        if(!this.props.disabled && !this.props.rodoDisabled){
            this.setState({showCanvas: false})
        }
    }
    
    prepareMeSignatureValue = (val) =>{
        if(val !== null && val){
            if(val.substring(0, prefixEnum.length) === prefixEnum){
                return val;
            }else{
                return prefixEnum+''+val;
            }
        }
        return '';
    }

    getMeContent(){
        if (this.state.showCanvas) {
            const { translateKey } = this.props;
            return (
                <React.Fragment>
                    <div className="rodo-class-signature-wrapper">
                        <SignatureCanvas 
                            penColor='black'
                            canvasProps={{width: 800, height: 400, className: 'sigCanvas'}} 
                            backgroundColor="white"
                            ref={(ref) => { this.sigPad = ref }}
                        />
                    </div>
                    <div className="rodo-class-signature-close-buttons">
                        <button 
                            type="button" 
                            className="btn btn-info cursor-pointer text-align-center"
                            onClick={()=> this.clear()}
                        >
                            {translateKey("clear")}
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-primary cursor-pointer text-align-center"
                            onClick={()=>this.cancelCanvas()}
                        >
                            {translateKey("cancel")}
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-success cursor-pointer text-align-center"
                            onClick={()=>this.saveData()}
                        >
                            {translateKey("save")}
                        </button>
                    </div>
                </React.Fragment>
            )
        } else {
            const { disabled, subsectionName, translateKey, rodoDisabled, showValidationErrors} = this.props;
            const { section,  } = this.state;
            const rodoSignatureField = section.getFieldByKey('rodo_signature')
            return (
                <React.Fragment>
                    <div className="new-section-name-wrapper container">
                        {subsectionName}
                    </div>
                    <form className="new-section-content-wrapper container form_serializer">
                        <fieldset disabled={disabled || rodoDisabled}>
                            <div className="newUI-section-padding">
                                <div className="row">
                                    <div className="col-sm-12 rodo-description">
                                        <div className="rodo-signature-subtitle">
                                            {translateKey('form-rodo-signature-text-paragraph1-part2')} <Asterisk isRequired={rodoSignatureField.isRequired}/>
                                        </div>
                                        <div className="flex-all-class column is-invalid">
                                            <div className={`rodo-signature-base-canvas ${disabled?'':'cursor-pointer'}`} onClick={()=>this.showMeCanvas()}>
                                                {rodoSignatureField && rodoSignatureField.value!==null &&
                                                    <img src={this.prepareMeSignatureValue(rodoSignatureField.value)} alt="signature" className="signature-base-image"/>
                                                }
                                            </div>
                                            {showValidationErrors && (rodoSignatureField.value===null || rodoSignatureField.value==='') &&
                                                <div className="invalid-feedback text-align-center">
                                                    <div >{translateKey('form-text-err_required')}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </React.Fragment>
            )
        }
    }

    render() {
        const {
            analizeFinished,
            currentSection,
            rerender,
            previousPageClick,
            nextPageClick,
            finishMe,
            form
        } = this.props;
        let content = this.getMeContent();
        return (
            <div className="form-section-main">
                {content}
                <SectionNavigationButtonsComponent
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    form={form}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={false}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(RodoSectionSignature));