export const SECTION_LIST = [
  { pageNumber:0,name: "section-care-box-rodo" },
  { pageNumber:1, name: "section-0" },
  { pageNumber:2, name: "section-2" },
  { pageNumber:3, name: "section-3" },
  { pageNumber:4, name: "section-care-box" }
]

export const getSectionNumberByName = (name) => {
  let elementNeeded = SECTION_LIST.find(element=>element.name===name)
  if(elementNeeded){
    return elementNeeded.pageNumber;
  }else{
    return 1;
  }
}

export const getSectionNameByNumber = (sectionNumber) => {
  let elementNeeded = SECTION_LIST.find(element=>element.pageNumber===sectionNumber)
  if(elementNeeded){
    return elementNeeded.name;
  }else{
    return 'section-care-box-rodo';
  }
}