import * as FieldTypes from "../fieldTypes";
import Form from "../Form";
import FormSection from "../FormSection";
import FormField from "../FormField";
import * as Sections from "./values/sections";
import * as IsContactPersonDataSameAs from "./values/isContactPersonDataSameAs";
import * as Entity from "./values/entity";
import * as CareBoxInsuranceType from "../CareBoxForm/values/careBoxInsuranceType";
import * as Relationship from "./values/relationship";
import * as SkillRange from "./values/skillRange";
import * as SpecialAgree from "./values/specialAgree";
import * as LocationRange from "./values/locationRange";
import * as HouseType from "./values/houseType";
import * as MarketingID from "./values/marketingId";
import * as ReasonForDisinterest from "./values/reasonForDisinterest";
import * as CareBoxDeliveryAddress from "./values/careBoxDeliveryAddress";
import * as CareBoxTypes from "./values/careBoxTypes";
import { skipList } from "./values/formKeySkipList";
import { duplicatedIngredients } from "./values/careBoxIngredientsDuplicated";

import { getSectionNumberByName } from "../../_constance/enums/sectionPageList";
import * as CareBoxStatus from "./values/careBoxStatus";

const LAST_SECTION_KEY = 'is_last_section';
const IS_MOST_IMPORTANT_TAKING_CARE_PATIENT = 'is_most_important_taking_care_patient'; 

const SKIP_SAVE_IS_DIET_DIABETES = 'skip_save_is_diet_diabetes'; 
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles'; 
const IS_DIET_DIABETES  = "is_diet_diabetes";

const importantListForSection17 = [
  { key: "is_cooking", type: "BOOLEAN" },
  { key: "is_purchases", type: "BOOLEAN" },
  { key: "is_laundry", type: "BOOLEAN" },
  { key: "is_poted_flower_care", type: "BOOLEAN" },
  { key: "is_ironing", type: "BOOLEAN" },
  { key: "is_accompanying_patient_while_out", type: "BOOLEAN" },
  { key: "is_cleaning_the_house", type: "BOOLEAN" },
  { key: "is_company_during_medical_visits", type: "BOOLEAN" },
  { key: "is_common_trips", type: "BOOLEAN" },
  { key: "is_organization_of_free_time", type: "BOOLEAN" },
  { key: "is_driving", type: "BOOLEAN" },
  { key: "is_driving_automat_gearbox", type: "BOOLEAN" },
]

const importantListForSection4 = [
  { key: "is_allergies_list", type: "BOOLEAN" },
  { key: "is_difficulty_in_walking_determined_by_age", type: "BOOLEAN" },
  { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
  { key: "is_asthma_list", type: "BOOLEAN" },
  { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
  { key: "is_bedsores_list", type: "BOOLEAN" },
  { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
  { key: "is_dementia_list", type: "BOOLEAN" },
  { key: "is_atopic_dermatitis", type: "BOOLEAN" },
  { key: "is_diabetes", type: "BOOLEAN" },
  { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
  { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
  { key: "is_heart_failure_list", type: "BOOLEAN" },
  { key: "is_arrhythmias_list", type: "BOOLEAN" },
  { key: "is_hypertension_list", type: "BOOLEAN" },
  { key: "is_incontinence_list", type: "BOOLEAN" },
  { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
  { key: "is_osteoporosis_list", type: "BOOLEAN" },
  { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
  { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
  { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
  { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
  { key: "is_stoma_list", type: "BOOLEAN" },
  { key: "is_cancer_list", type: "BOOLEAN" },
  { key: "is_other_description_de", type: "STRING" }
]

const importantListForSection7 = [
  { key: "is_fearful", type: "BOOLEAN" },
  { key: "is_apathetic", type: "BOOLEAN" },
  { key: "is_demanding", type: "BOOLEAN" },
  { key: "is_cheerful", type: "BOOLEAN" },
  { key: "is_nervous", type: "BOOLEAN" },
  { key: "is_open", type: "BOOLEAN" },
  { key: "is_nice", type: "BOOLEAN" },
  { key: "is_moody", type: "BOOLEAN" },
  { key: "is_euphoric", type: "BOOLEAN" },
  { key: "is_decisive", type: "BOOLEAN" },
  { key: "is_sensitive", type: "BOOLEAN" },
  { key: "is_positive", type: "BOOLEAN" },
  { key: "is_unstable", type: "BOOLEAN" },
  { key: "is_withdrawn", type: "BOOLEAN" },
  { key: "is_critical", type: "BOOLEAN" },
  { key: "is_negative", type: "BOOLEAN" },
  { key: "is_emotional_condition_others_de", type: "STRING" }
]
const skipListPatient = [
  "is_patient_last_name",
  "is_patient_first_name",
  "is_service_address_street",
  "is_service_address_street_number",
  "is_service_address_house_number",
  "is_service_address_postal_code",
  "is_service_address_city",
  "is_patient_cellphone",
  "is_patient_phone"
];
const flattenFields = fieldsList =>
  fieldsList.reduce(
    (previous, field) =>
      Object.assign(previous, {
        [field.key]: field.value
      }),
    {}
  );
//const flattenFieldsArray = fieldsList => fieldsList.map(field => field.key);
const flattenFieldsArraywithSkiped = fieldsList =>
  fieldsList
    .filter(element => {
      if (skipList.indexOf(element.key) !== -1) {
        return false;
      }
      return true;
    })
    .map(field => field.key)
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    })
   
const flattenFieldsArraySkipedBoth = fieldsList =>
  fieldsList
    .filter(element => {
      if (skipListPatient.indexOf(element.key) !== -1 || skipList.indexOf(element.key) !== -1) {
        return false;
      }
      return true;
    })
    .map(field => field.key)
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    })

const birthdayField = new FormField({
  defaultValue: "",
  key: "is_birthday",
  entity: Entity.PATIENT,
  isRequired: true
});
const weightField = new FormField({
  defaultValue: "",
  key: "is_weight",
  entity: Entity.PATIENT,
  isRequired: true
});
const heightField = new FormField({
  defaultValue: "",
  key: "is_height",
  entity: Entity.PATIENT,
  isRequired: true
});
const homeDoctorField = new FormField({
  defaultValue: "",
  key: "is_home_doctor",
  entity: Entity.PATIENT,
  isRequired: false
});

export default class BBForm extends Form {
  constructor() {
    super({
      sections: [
        new FormSection({
          key: "section-rodo-init",
          sectionName: "form-rodo1-text-akceptieren",
          number: 1,
          fields: [
            new FormField({
              key: "rodo_first_choice",
              entity: Entity.CONTACT,
              isRequired: form => {
                const isLastSection = form.getFieldByKey("is_last_section");
                if(isLastSection){
                  if((getSectionNumberByName(isLastSection.value))>=3){
                    return false;
                  }
                }
                return true;
              }
            }),
            new FormField({
              key: "is_degree_relationship_with_patient_carer",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              defaultValue: false,
              key: "franchise-partner",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false
            }),
          ],
          nextSectionSelector: form => {
            return Sections.RODO1;
          }
        }),
        new FormSection({
          key: "section-rodo-consents",
          sectionName: "form-rodo1-text-akceptieren",
          number: 2,
          fields: [
            new FormField({
              //ip_address
              defaultValue: false,
              key: "is_customer_area_ip_address",
              entity: Entity.CONTACT,
            }),
            new FormField({
              key: "is_patient_last_name",
              entity: Entity.CONTACT
            }),
            new FormField({
              key: "is_patient_first_name",
              entity: Entity.CONTACT
            }),
            new FormField({
              //rodo1
              defaultValue: false,
              key: "einwilligung_zur_unterbeitung",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const isDegreeRelationShipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
                if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              //rodo2
              defaultValue: null,
              key: "einwilligung_in_die_verarbeitung",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const isDegreeRelationShipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
                if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
                  return false;
                }
                return true;
              }
            }),
            new FormField({
              //rodo2
              defaultValue: null,
              key: "die_verwendent_werden",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const isDegreeRelationShipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
                if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
                  return false;
                }
                return true;
              }
            }),
            new FormField({
              defaultValue: false,
              key: "marketing_einwilligungen_post_yes",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const field = form.getFieldByKey("marketing_einwilligungen_post_no");
                if (field.value === false) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: false,
              key: "marketing_einwilligungen_post_no",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const field = form.getFieldByKey("marketing_einwilligungen_post_yes");
                if (field.value === false) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: false,
              key: "marketing_einwilligungen_telephone_yes",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const field = form.getFieldByKey("marketing_einwilligungen_telephone_no");
                if (field.value === false) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: false,
              key: "marketing_einwilligungen_telephone_no",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const field = form.getFieldByKey("marketing_einwilligungen_telephone_yes");
                if (field.value === false) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_email_yes",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const field = form.getFieldByKey("einwilligungen_die_daten_email_no");
                if (field.value === false) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_email_no",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const field = form.getFieldByKey("einwilligungen_die_daten_email_yes");
                if (field.value === false) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_telephone_yes",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const field = form.getFieldByKey("einwilligungen_die_daten_telephone_no");
                if (field.value === false) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_telephone_no",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const field = form.getFieldByKey("einwilligungen_die_daten_telephone_yes");
                if (field.value === false) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_post_yes",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const field = form.getFieldByKey("einwilligungen_die_daten_post_no");
                if (field.value === false) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_post_no",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const field = form.getFieldByKey("einwilligungen_die_daten_post_yes");
                if (field.value === false) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: null,
              key: "marketing_zustimmungen",
              entity: Entity.CONTACT,
              isFalseable: true,
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_1",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_2",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_3",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_4",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_5",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_6",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_7",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_8",
              entity: Entity.CONTACT,
              isFalseable: false
            })
          ],
          nextSectionSelector: form => {
            const isFranchisePartner = form.getFieldByKey("franchise-partner");
            if (isFranchisePartner.value===true) {
              return Sections.CLIENT;
            }
            return Sections.RODOSIGNATURE;
          },
        }),
        new FormSection({
          key: "section-rodo-signature",
          sectionName: "form-rodo1-text-akceptieren",
          number: 3,
          fields: [
            new FormField({
              defaultValue: null,
              key: "rodo_signature",
              entity: Entity.CONTACT,
              isRequired: form => {
                const isLastSection = form.getFieldByKey("is_last_section");
                if(isLastSection){
                  if((getSectionNumberByName(isLastSection.value)+1)>=3){
                    return true;
                  }
                }
                return false;
                
              }
            }),
          ],
          nextSectionSelector: form => {
            return Sections.CLIENT
          },
        }),
        // Section 1
        new FormSection({
          key: "section-0",
          sectionName: "bb_client_data_p_0",
          number: 4,
          fields: [
            new FormField({
              key: "lastname",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "firstname",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_degree_relationship_with_patient_carer",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_street",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_street_number",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_zip_postal_code",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_house_number",
              entity: Entity.CONTACT
            }),
            new FormField({
              key: "is_mobile_phone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_home_phone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_registered_city",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_email",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.EMAIL
              }
            }),
            new FormField({
              key: "is_address_type",
              entity: Entity.CONTACT,
              defaultValue: "717700000"
            }),
            new FormField({
              key: "is_correspondence_name",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isLastName = form.getFieldByKey("lastname");
                const isFirstName = form.getFieldByKey("firstname");
                return isLastName.value + isFirstName.value;
              }
            }),
            new FormField({
              key: "is_correspondence_city",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_city");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_zip_postal_code",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_zip_postal_code");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_house_number",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_house_number");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_street",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_street");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_street_number",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_street_number");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_contact_person_data_same_as",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              },
              defaultValue: IsContactPersonDataSameAs.YES
            }),
            birthdayField,
            weightField,
            heightField,
            homeDoctorField
          ],
          nextSectionSelector: form => {
            const isContactPersonDataSameAsField = form.getFieldByKey("is_contact_person_data_same_as");
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isContactPersonDataSameAsField.value === IsContactPersonDataSameAs.YES) {
              if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8 ) {
                return Sections.PFLEGEGRAD;
              } else {
                return Sections.PATIENT;
              }
            } else {
              return Sections.CONTACT_PERSON;
            }
          },
          prevSectionSelector: form => {
            return Sections.CLIENT;
          },
          validationExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationshipWithPatientCarer.value !== Relationship.TYPE_8) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          },
          payloadExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationshipWithPatientCarer.value !== Relationship.TYPE_8) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          }
        }),
        // Section 2
        new FormSection({
          key: "section-1",
          sectionName: "bb_contact_data_p_1",
          number: 5,
          fields: [
            new FormField({
              key: "is_contact_person_lastname",
              entity: Entity.CONTACT,
              isRequired: true,
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey("is_contact_person_data_same_as");
                if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
                  return form.getFieldByKey("lastname").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_firstname",
              entity: Entity.CONTACT,
              isRequired: true,
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("firstname").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_mobile_phone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              },
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("is_mobile_phone").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_home_phone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              },
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("is_home_phone").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_email",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.EMAIL
              },
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("is_email").value;
                }
              }
            }),
            new FormField({
              key: "is_relationship_with_patient_op",
              entity: Entity.CONTACT,
              isRequired: true,
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey("is_contact_person_data_same_as");
                if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
                  return form.getFieldByKey("is_degree_relationship_with_patient_carer").value;
                }
              }
            })
          ],
          nextSectionSelector: form => {
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8 ) {
              return Sections.PFLEGEGRAD;
            } else {
              return Sections.PATIENT;
            }
          },
        }),
        // Section 3
        new FormSection({
          key: "section-2",
          sectionName: "bb_lead_data_p_2",
          number: 6,
          fields: [
            birthdayField,
            weightField,
            heightField,
            homeDoctorField,
            new FormField({
              key: "is_patient_last_name",
              entity: Entity.CONTACT,
              isRequired: true,
              valueMapper: form => {
                const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
                const lastName = form.getFieldByKey('lastname').value;
                if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
                  return lastName;
                }
              }
            }),
            new FormField({
              key: "is_patient_first_name",
              entity: Entity.CONTACT,
              isRequired: true,
              valueMapper: form => {
                const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
                const firstName = form.getFieldByKey('firstname').value;
                if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
                  return firstName;
                }
              }
            }),
            new FormField({
              key: "is_service_address_street",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_service_address_street_number",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_service_address_house_number",
              entity: Entity.CONTACT
            }),
            new FormField({
              key: "is_service_address_postal_code",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_service_address_city",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_first_name_and_last_name_of_patient",
              entity: Entity.PATIENT,
              // valueMapper: form => {
              //   const isPatientLastName = form.getFieldByKey("is_patient_last_name");
              //   const isPatientFirstName = form.getFieldByKey("is_patient_first_name");
              //   return isPatientFirstName.value + " " + isPatientLastName.value;
              // }
            }),
            new FormField({
              key: "is_patient_cellphone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_patient_phone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_gender",
              defaultValue: "717700000",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: { possibleValues: ['717700000', '717700001'] },
            }),
          ],
          prevSectionSelector: form => {
            const isContactPersonDataSameAs = form.getFieldByKey("is_contact_person_data_same_as");
            if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
              return Sections.CLIENT;
            }
            return Sections.CONTACT_PERSON;
          },
          validationExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          },
          payloadExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (
              isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8
            ) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          }
        }),
        // Section 4
        new FormSection({
          key: "section-3",
          sectionName: "bb_pflegegrad_data_p_3",
          number: 7,
          fields: [
            new FormField({
              key: "is_pflegegrad",
              entity: Entity.PATIENT,
              isRequired: false
            }),
            new FormField({
              key: "is_applied_pflegegrad",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isAppliedLast6Months = form.getFieldByKey("is_applied_last_6_months");
                if(isAppliedLast6Months.value === '717700000'){
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_applied_last_6_months",
              entity: Entity.PATIENT,
              isRequired: true,
              defaultValue: '717700001'
            }),
            new FormField({
              defaultValue: "",
              key: "is_pflegegrad_posting_date",
              entity: Entity.PATIENT,
              isRequired: false
            }),
            new FormField({
              key: "is_insurance_type",
              entity: Entity.PATIENT,
              isRequired: true,
              defaultValue: '717700001',
              valueChangedCallback: (form, oldVal, newVal) => {
                const val = newVal === '717700001' ? '717700000' : '717700001';
                form.getFieldsByEntityAndKey(Entity.CONTACT, "is_private_client").setValueNoCallback(val);
                form.getFieldsByEntityAndKey(Entity.CARE_BOX, "is_private_client").setValueNoCallback(val);
              }
            }),
            new FormField({
              key: "is_kein_pflegegrad",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false
              /*valueMapper: (form) => {
                                const isPflegegrad = form.getFieldByKey('is_pflegegrad');
                                const isAppliedPflegegrad = form.getFieldByKey('is_applied_pflegegrad');
                                if(
                                    (isPflegegrad.value!==null && isPflegegrad.value.toString().trim() !=='') ||
                                    (isAppliedPflegegrad.value!==null && isAppliedPflegegrad.value.toString().trim() !=='') ||
                                    (isKrankenkasse.value!==null && isKrankenkasse.value.toString().trim() !=='')
                                ){
                                    return false
                                }else{
                                    return true
                                }
                            }*/
            })
          ],
          prevSectionSelector: form => {
            const isContactPersonDataSameAs = form.getFieldByKey("is_contact_person_data_same_as");
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationShipWithPatientCarer.value !== Relationship.TYPE_8 ) {
                return Sections.PATIENT;
            } else {
              if (isContactPersonDataSameAs.value !== IsContactPersonDataSameAs.YES){
                return Sections.CONTACT_PERSON;
              }
            }
            return Sections.CLIENT;
          },
          payloadExceptions: () => {
            return ["is_kein_pflegegrad"];
          }
        }),

        // Section 5   //    2.1
        new FormSection({
          key: "section-4",
          sectionName: "bb_ills_data_p_4",
          number: 8,
          fields: [
            new FormField({
              key: "is_patient_smokes",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false
            }),
            new FormField({
              key: "is_allergies_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: null
            }),
            new FormField({
              key: "is_difficulty_in_walking_determined_by_age",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_parkinsons_disease_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_stoma_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_cancer_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_osteoporosis_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_incontinence_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_bedsores_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_hypertension_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_other_description_de",
              entity: Entity.PATIENT,
              isRequired: false,
              fieldImportantList: importantListForSection4,
            }),
            new FormField({
              key: "is_asthma_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_chronic_diarrhea_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_dementia_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_alzheimers_disease_the_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_diabetes",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_diabetes_mellitus_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_atopic_dermatitis",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_heart_failure_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_initial_stages_of_dementia_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_rheumatic_disease_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_multiple_sclerosis_the_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_left_sided_paresis_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_right_sided_paresis_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_myocardial_infarction_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_arrhythmias_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            })
          ]
        }),

        // Section 6 // 2.2
        new FormSection({
          key: "section-5",
          sectionName: "bb_comnunication_data_p_5",
          number: 9,
          fields: [
            new FormField({
              key: "is_communication_hearing",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_communication_vision",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_communication_speech",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            })
          ]
        }),

        // Section 7 2.3
        new FormSection({
          key: "section-6",
          sectionName: "bb_orientation_data_p_6",
          number: 10,
          fields: [
            new FormField({
              key: "is_orientation_temporal",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_orientation_locational",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_orientation_personal",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            })
          ]
        }),

        // SECTION NUMBER 8
        new FormSection({
          key: "section-8", 
          sectionName: "bb_movement_data_p_8",
          number: 11,
          fields: [
            new FormField({
              key: "is_bed_care",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_palliative_care",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_patient_need_help_with_transfer",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: true,
              defaultValue: null,
            }),
            new FormField({
              key: "is_rollator_or_walker_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_lying_patient",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_regular_change_of_position",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_walking_stick",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_specialist_bed_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_chair_lift",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_transfer_lifter",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_wheelchair",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_getting_up",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_patient_is_active_during_transfer",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: true,
              defaultValue: null,
            }),
            new FormField({
              key: "is_carrying_bed_wheelchair",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_move",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_antibedsore_mattress_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_walking_up_stairs",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_moving_with_rollator_alone",
              entity: Entity.PATIENT,
              isRequired: false,
              defaultValue: ''
            }),
            new FormField({
              key: "is_moving_with_rollator_in_flat_alone",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isMovingWRA = form.getFieldByKey("is_moving_with_rollator_alone" );
                if (isMovingWRA.value === IsContactPersonDataSameAs.YES) {
                  return true;
                }
                return false;
              },
              defaultValue: '',
            }),
            new FormField({
              key: "is_moving_rollator_outside_flat",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isMovingWRA = form.getFieldByKey("is_moving_with_rollator_alone" );
                if (isMovingWRA.value === IsContactPersonDataSameAs.YES) {
                  return true;
                }
                return false;
              },
              defaultValue: '',
            }),
            new FormField({
              key: "is_moving_on_wheelchair_alone",
              entity: Entity.PATIENT,
              isRequired: false,
              defaultValue: ''
            }),
            new FormField({
              key: "is_moving_wheelchair_in_apartment_alone",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isMovingOWA = form.getFieldByKey("is_moving_on_wheelchair_alone" );
                if (isMovingOWA.value === IsContactPersonDataSameAs.YES) {
                  return true;
                }
                return false;
              },
              defaultValue: '',
            }),
            new FormField({
              key: "is_moving_wheelchair_outside_alone",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isMovingOWA = form.getFieldByKey("is_moving_on_wheelchair_alone" );
                if (isMovingOWA.value === IsContactPersonDataSameAs.YES) {
                  return true;
                }
                return false;
              },
              defaultValue: ''
            }),
            new FormField({
              key: "is_patient_help_change_position",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            })
          ]
        }),

        // SECTION NUMBER 9
        new FormSection({
          key: "section-pflegedienst-simple", 
          sectionName: "bb_pflegedienst_data_p_14",
          number: 12,
          fields: [
            new FormField({
              key: "is_outpatient_help",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false

            }),
            new FormField({
              key: "is_contract_pflegedienst_services_signed",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            })
          ]
        }),

        // SECTION NUMBER 10
        new FormSection({
          key: "section-16",
          sectionName: "bb_expectations_data_p_16",
          number: 13,
          fields: [
            new FormField({
              key: "is_most_important_taking_care_patient",
              entity: Entity.PATIENT,
              isRequired: false,
            }),
            new FormField({
              key: "is_start_date_contact",
              entity: Entity.CONTACT,
              isRequired: false,
            }),
            new FormField({
              key: "is_end_date_contact",
              entity: Entity.CONTACT,
              isRequired: false,
            }),
            new FormField({
              key: "is_permanent_contract_contact",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
              validationRules: { possibleValues: ['717700000', '717700001'] },
              valueMapper: form => {
                /* hidden due to FP-369
                const is_start_date_contact = form.getFieldByKey("is_start_date_contact");
                const is_end_date_contact = form.getFieldByKey("is_end_date_contact");
                let endDate = new Date(new Date(is_start_date_contact.value * 1000).setFullYear(new Date(is_start_date_contact.value * 1000).getFullYear() + 1));
                endDate.setDate(endDate.getDate()-2);
                endDate = endDate.getTime() / 1000;
                if(endDate === is_end_date_contact.value){
                  return '717700000';
                }else{
                  return '717700001';
                }
                */
                return '717700001';
              }
            }),
            new FormField({
              key: "is_candidate_gender",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            })
          ]
        }),

        // SECTION NUMBER 11
        new FormSection({
          key: "section-17",
          sectionName: "bb_actions_data_p_17",
          number: 14,
          fields: [
            new FormField({
              key: "is_cooking",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_purchases",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_laundry",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_poted_flower_care",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_ironing",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_accompanying_patient_while_out",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_cleaning_the_house",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_company_during_medical_visits",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_common_trips",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_organization_of_free_time",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_driving",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_driving_automat_gearbox",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            })
          ]
        }),

        // SECTION NUMBER 12
        new FormSection({
          key: "section-beschreibung",
          sectionName: "bb_place_data_p_18",
          number: 15,
          fields: [
            new FormField({
              key: "is_patient_lives",
              entity: Entity.PATIENT,
              isRequired: true
            })
          ]
        }),

        // SECTION INITIAL OFFERT  NO NUMBER
        new FormSection({
          key: "section-initial-offer",
          sectionName: "section-initial-offer",
          number: 15,
          isSpecialOffer: true,
          fields: [
            new FormField({
              key: "is_most_important_taking_care_patient",
              entity: Entity.PATIENT,
              isRequired: false,
            }),
            new FormField({
              key: SKIP_SAVE_IS_DIET_DIABETES,
              entity: Entity.PATIENT,
              isRequired: false,
            }),
            new FormField({
              key: SKIP_SAVE_STRING_TILES,
              entity: Entity.PATIENT,
              isRequired: false,
            })
          ]
        }),

        //  SECTION NUMBER 13
        new FormSection({
          key: "section-krankheitsbilder",
          sectionName: "bb_ills_data_p_4",
          number: 16,
          fields: [
            new FormField({
              key: "is_infectious_diseases",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false
            }),
            new FormField({
              key: "is_family_suffer_infectionus_diseases",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false
            }),
            new FormField({
              key: "is_family_suffer_infectionus_diseases_des_de",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isFamilySufferInfectionusDiseases = form.getFieldByKey("is_family_suffer_infectionus_diseases");
                const isFamilySufferInfectionusDiseasesDe = form.getFieldByKey("is_family_suffer_infectionus_diseases_des_de");
                if (isFamilySufferInfectionusDiseases.value === true) {
                  return true;
                } else {
                  isFamilySufferInfectionusDiseasesDe.value = '';
                  return false;
                }
              }
            }),
            new FormField({
              key: "is_infectious_diseases_what_de",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isIndectiousDiseases = form.getFieldByKey("is_infectious_diseases");
                const isIndectiousDiseasesWhatDe = form.getFieldByKey("is_infectious_diseases_what_de");
                if (isIndectiousDiseases.value === true) {
                  return true;
                } else {
                  isIndectiousDiseasesWhatDe.value = '';
                  return false;
                }
              }
            })
          ]
        }),

        //  newUI SECTION NUMBER 14
        new FormSection({
          key: "section-kommunikation-big",
          sectionName: "bb_comnunication_data_p_5",
          number: 17,
          fields: [
            new FormField({
              key: "is_hearing_aid_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: '717700001'
            }),
            new FormField({
              key: "is_glasses_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false
            }),
            new FormField({
              key: "is_hearing_aid_self_contained_use",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: form => form.getFieldByKey("is_hearing_aid_list").value,
              defaultValue: '717700001'
            }),
            new FormField({
              key: "is_glasses_self_use",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: form => form.getFieldByKey("is_glasses_list").value,
              defaultValue: '717700001'
            })
          ]
        }),

        //  newUI SECTION NUMBER 15
        new FormSection({
          key: "section-7",
          sectionName: "bb_characteristic_data_p_7",
          number: 18,
          fields: [
            new FormField({
              key: "is_fearful",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_apathetic",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_demanding",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_cheerful",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_nervous",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_open",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_nice",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_moody",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_euphoric",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_decisive",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_sensitive",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_positive",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_unstable",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_withdrawn",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_critical",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_negative",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_emotional_condition_others_de",
              entity: Entity.PATIENT,
              fieldImportantList: importantListForSection7
            })
          ]
        }),

        //  SECTION NUMBER 16
        new FormSection({
          key: "section-9",
          sectionName: "bb_defecation_data_p_9",
          number: 19,
          fields: [
            new FormField({
              key: "is_inserts_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_nappies_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_urination_control",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_catheterized_through_the_abdominal_wall",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_bedpan_a_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_catheter_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_control_of_defecation",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_use_toilet_or_toilet_chair_alone",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_if_limited_how_often",
              entity: Entity.PATIENT,
              isRequired: false
            }),
            new FormField({
              key: "is_aids_other_de",
              entity: Entity.PATIENT
            })
          ]
        }),

        //  SECTION NUMBER 17
        new FormSection({
          key: "section-10",
          sectionName: "bb_hugiene_data_p_10",
          number: 20,
          fields: [
            new FormField({
              key: "is_shower_chair",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_toilet_chair",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_shaving",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_oral_care_and_dentures",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_hair_care",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_intimate_hygiene",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_skin_care",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_lift_by_the_bath",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_bathing_shower",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_toilet_seat",
              entity: Entity.PATIENT,
              isFalseable: true
            })
          ]
        }),

        //  SECTION NUMBER 18
        new FormSection({
          key: "section-11",
          sectionName: "bb_foods_data_p_11", 
          number: 21,
          fields: [
            new FormField({
              key: IS_DIET_DIABETES,
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_diet_special",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_diet_vegetarian",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_dehydration",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_diet_balanced",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_diet_normal",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_sonda_peg",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_chewing_and_swallowing",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_drinking",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_eating",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_nutritional_status",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_taking_drink",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_favorite_food_drink_de",
              entity: Entity.PATIENT
            })
          ]
        }),

        //  SECTION NUMBER 19
        new FormSection({
          key: "section-12",
          sectionName: "bb_clothes_data_p_12",
          number: 22,
          fields: [
            new FormField({
              key: "is_dressing_upper_part_of_body",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_dressing_underwear",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_dressing_pants_skirt",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_dressing_shoes",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_undress_upper_part_of_body",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_undress_underwear",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_undress_pants_skirt",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_undress_shoes",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            })
          ]
        }),

        // SECTION NUMBER 20
        new FormSection({
          key: "section-13",
          sectionName: "bb_sleep_data_p_13",
          number: 23,
          fields: [
            new FormField({
              key: "is_falling_asleep",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_sleep_over",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_remarks_sleeps_de",
              entity: Entity.PATIENT,
              defaultValue: 0,
              isRequired: form => {
                const isRemarksSleepsDe = form.getFieldByKey("is_remarks_sleeps_de" );
                if(isRemarksSleepsDe.value===0){
                  return false;
                }else{
                  return true;
                }
              }
            }),
            new FormField({
              key: "is_remarks_sleeps_de_helper",
              entity: Entity.PATIENT,
              defaultValue: '',
              isRequired: false
            }),
            new FormField({
              key: "is_activities_performed_by_carer_at_night_de",
              entity: Entity.PATIENT,
              isRequired: false,
            }),
            new FormField({
              key: "is_patient_medicines_hypnotics_de",
              entity: Entity.PATIENT,
            //   fieldImportantList: [{ key: "is_patient_medicines_hypnotics_de", type: "STRING" }]
            }),
            new FormField({
              key: "is_how_often_carer_must_get_up_at_night_de",
              entity: Entity.PATIENT,
              defaultValue: 0,
              isRequired: form => {
                const isRemarksSleepsDe = form.getFieldByKey("is_how_often_carer_must_get_up_at_night_de" );
                if(isRemarksSleepsDe.value===0){
                  return false;
                }else{
                  return true;
                }
              }
            })
          ]
        }),

        // SECTION NUMBER 21
        new FormSection({
        key: "section-14",
        sectionName: "bb_pflegedienst_data_p_14",
        number: 24,
        fields: [
          new FormField({
            key: "is_outpatient_services_list_de",
            entity: Entity.PATIENT,
            isRequired: form => {
              const isOutpatientHelp = form.getFieldByKey("is_outpatient_help");
              if (isOutpatientHelp.value === true) {
                return true;
              }
              return false;
            }
          }),
          new FormField({
            key: "is_medical_care_sgb_v",
            entity: Entity.PATIENT,
            isFalseable: true,
            defaultValue: false,
            fieldImportantList: [{ 
              key: "is_medical_care_sgb_v", 
              type: "BOOLEAN", 
              basedOnField: 'is_outpatient_help', 
              skipCondition: false ,
              skipIfOneOfThisTrue:[
                {key: 'is_benefits_kind_for_sgb_care', value: true},
                {key: 'is_anicillary_services_45b', value: true}
              ]
            }]
          }),
          new FormField({
            key: "is_benefits_kind_for_sgb_care",
            entity: Entity.PATIENT,
            isFalseable: true,
            defaultValue: false,
            fieldImportantList: [{ 
              key: "is_benefits_kind_for_sgb_care", 
              type: "BOOLEAN", 
              basedOnField: 'is_outpatient_help', 
              skipCondition: false,
              skipIfOneOfThisTrue:[
                {key: 'is_medical_care_sgb_v', value: true},
                {key: 'is_anicillary_services_45b', value: true}
              ]
            }]
          }),
          new FormField({
            key: "is_anicillary_services_45b",
            entity: Entity.PATIENT,
            isFalseable: true,
            defaultValue: false,
            fieldImportantList: [{ 
              key: "is_anicillary_services_45b", 
              type: "BOOLEAN", 
              basedOnField: 'is_outpatient_help',
              skipCondition: false,
              skipIfOneOfThisTrue:[
                {key: 'is_medical_care_sgb_v', value: true},
                {key: 'is_benefits_kind_for_sgb_care', value: true}
              ]
            }]
          }),
          new FormField({
            key: "is_emergency_call_system",
            entity: Entity.PATIENT,
            isFalseable: true
          }),
          new FormField({
            key: "is_how_often_outpatient_help_de",
            entity: Entity.PATIENT,
            isRequired: form => {
              const isOutpatientHelp = form.getFieldByKey("is_outpatient_help");
              if (isOutpatientHelp.value === true) {
                return true;
              }
              return false;
            }
          }),
          new FormField({
            key: "is_day_care_home",
            entity: Entity.PATIENT,
            validationRules: {possibleValues: IsContactPersonDataSameAs.LIST},
            defaultValue: IsContactPersonDataSameAs.NO
          }),
          new FormField({
            key: "is_home_care_day_frequency_de",
            entity: Entity.PATIENT
          })
        ],
        validationExceptions: form => {
          const isDayCareHome = form.getFieldByKey("is_day_care_home");
          if (
            isDayCareHome.value === "False" ||
            isDayCareHome.value === false
          ) {
            return ["is_home_care_day_frequency_de"];
          }
        }
        }),

        // SECTION NUMBER 22
        new FormSection({
          key: "section-15",
          sectionName: "bb_therapy_data_p_15",
          number: 25,
          fields: [
            new FormField({
              key: "is_therapeutic_care_how_often_in_week_de",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isTherapeuticCAH = form.getFieldByKey("is_therapeutic_care_at_home" );
                const isTherapeuticCOH = form.getFieldByKey("is_therapeutic_care_outside_home" );
                if (isTherapeuticCAH.value === true && isTherapeuticCOH.value===true) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_physiotherapy",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_speech_therapy",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_therapeutic_care_outside_home",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_therapeutic_care_at_home",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_occupational_theraphy",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_ergotherapy",
              entity: Entity.PATIENT,
              isFalseable: true
            })
          ]
        }),

        // SECTION NUMBER 23
        new FormSection({
          key: "section-18",
          sectionName: "bb_place_data_p_18",
          number: 26,
          fields: [
            new FormField({
              key: "is_internet_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_how_often_carer_free_de",
              entity: Entity.PATIENT,
              isRequired: true
            }),
            new FormField({
              key: "is_pc_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_who_take_care_when_carer_free_de",
              entity: Entity.PATIENT,
              isRequired: true
            }),
            new FormField({
              key: "is_location",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: LocationRange.LIST
              }
            }),
            new FormField({
              key: "is_bed_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_taking_care_of_animals",
              entity: Entity.PATIENT,
              isFalseable: false,
              validationRules: {
                possibleValues: SpecialAgree.LIST
              }
            }),
            new FormField({
              key: "is_separate_toilett",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_separate_shower",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_shopping_on_foot",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: LocationRange.LIST
              }
            }),
            new FormField({
              key: "is_table_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_television_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_housing_conditions",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: HouseType.LIST
              }
            }),
            new FormField({
              key: "is_separate_bathroom_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_shopping_are_made_by_family",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_pets",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_garden",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: true,
              defaultValue: '717700001',
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              }
            }),
            new FormField({
              key: "is_bike",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: true,
              defaultValue: '717700001',
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              }
            }),
            new FormField({
              key: "is_taking_care_of_animals_which_de",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isActivities = form.getFieldByKey("is_pets");
                if (isActivities.value === true) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_wardrobe_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_activities_additional_people",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_activities_regarding_additional_people_de",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isActivities = form.getFieldByKey("is_activities_additional_people");
                if (isActivities.value === true) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_marketingid",
              entity: Entity.CONTACT,
              possibleValues: MarketingID.getValues()
            })
          ]
        }),

        new FormSection({
          key: "section-anwesend-bei",
          sectionName: "form-section-title-anwesend",
          number: 27,
          fields: [
            new FormField({
              key: "is_customer",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_relative",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_carer",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
          ]
        }),
        // SECTION NUMBER 24 - notes
        new FormSection({
          key: "section-notiz",
          sectionName: "form-text-notiz",
          number: 28,
          fields: [
            new FormField({
              key: "is_additional_fp_comments",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                maxLength: 5000
              }
            }),
            new FormField({
              key: "is_comment_for_carer_de",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                maxLength: 5000
              }
            })
          ]
        }),

        // SECTION INITIAL OFFERT
        new FormSection({
          key: "section-final-offer",
          sectionName: "form-text-aufsummierung",
          number: 29,
          isSpecialOffer: false,
          fields: [
            new FormField({
              key: "is_most_important_taking_care_patient",
              entity: Entity.PATIENT,
              isRequired: false
            })
          ]
        }),

        //CARE BOX
        new FormSection({
          key: "section-care-box",
          sectionName: "form-text-care-box",
          number: 30,
          isSpecialOffer: false,
          fields: [
            new FormField({
              key: "is_lead_status_care_box",
              entity: Entity.CONTACT,
              isFalseable: true,
              defaultValue: '717700003'
            }),
            new FormField({
              key: "is_service_type_de",
              entity: Entity.CONTACT,
              defaultValue: "717700001"
            }),
            new FormField({
              key: "is_reason_for_rejection_care_box",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700002') {
                  return true;
                }
                return false;
              },
              validationRules: {
                possibleValues: ReasonForDisinterest.LIST
              },
              valueMapper: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700003') {
                  return null;
                }
              }
            }),
            new FormField({
              key: "is_private_client",
              entity: Entity.CONTACT,
              defaultValue: '717700000',
              valueChangedCallback: (form, oldVal, newVal) => {
                const val = newVal === '717700001' ? '717700000' : '717700001';
                form.getFieldByKey("is_insurance_type").setValueNoCallback(val);
                form.getFieldsByEntityAndKey(Entity.CARE_BOX, "is_private_client").setValueNoCallback(newVal);
              }
            }),
            new FormField({
              key: "is_private_client",
              entity: Entity.CARE_BOX,
              defaultValue: '717700000'
            }),
            new FormField({
              key: "is_health_insurance",
              entity: Entity.CARE_BOX,
              defaultValue: '',
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002') {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_insurance_number",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002') {
                  const isPrivate = form.getFieldsByEntityAndKey(Entity.CARE_BOX, 'is_private_client').value === CareBoxInsuranceType.IS_PRIVATE;
                  return isPrivate === false;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_outpatient_assistance_name",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_street_pa",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_house_number_pa",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_apartement_number_pa",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_pa",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_pa",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_delivery_to_address",
              entity: Entity.CARE_BOX,
              validationRules: {
                possibleValues: CareBoxDeliveryAddress.LIST
              },
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002') {
                  return true;
                }
                return false;
              },
            }),
            new FormField({
              key: "is_last_name_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_first_name_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_street_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_house_number_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_apartment_number_d",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_d",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_care_box_setid",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value === '717700003') {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_bed_protectors",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_o",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_bed_protectors"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_one_time_gloves",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_r",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_one_time_gloves"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_one_time_gloves_type",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: '717700000',
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_one_time_gloves"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_one_time_gloves_size",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: '717700001',
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_one_time_gloves"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_hand_sanitizer",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dr",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_hand_sanitizer"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_surface_disinfectant",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dp",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_surface_disinfectant"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_masks",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_m",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_masks"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_masks_ffp2",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_m_ffp",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_masks_ffp2"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_masks_ffp2_set",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_ffp_set",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_masks_ffp2_set");
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_protective_aprons",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_f",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_protective_aprons"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_reusable_bed_protectors",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_protectors",
              entity: Entity.CARE_BOX,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_reusable_bed_protectors"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_finger_overlay",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_njp",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_finger_overlay"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_surface_disinfectant_1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dp1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_surface_disinfectant_1000"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_hand_sanitizer_1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dr1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_hand_sanitizer_1000"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_reusable_protective_aprons",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_wfo",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_reusable_protective_aprons"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disinfecting_wipes",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_ch",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disinfecting_wipes"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            //automatic filed fields
            new FormField({
              key: "is_contactid",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_patientid",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_status",
              entity: Entity.CARE_BOX,
              value: CareBoxStatus.APP_NEW
            }),
            new FormField({
              key: "is_name",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_name_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_surname_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_street_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_house_number_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_apartment_number_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_home_phone_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_cell_phone_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_email_address_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_birth_date",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_pflegegrad_duplicated",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_name_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_surname_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_street_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_house_number_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_apartment_number_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_home_phone_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_cell_phone_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_email_address_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "ownerid",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_contactid_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_name_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_care_box_appid",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_month",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_year",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_status_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              value: '717700000'
            }),
            new FormField({
              key: "ownerid_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
          ]
        }),

        // SECTION OFFER SUMMARY
        new FormSection({
          key: "section-offer-summary",
          sectionName: "form-text-finales-angebot",
          number: 31,
          isSpecialOffer: true,
          fields: []
        }),

        // SECTION NUMBER 25
        /*new FormSection({
          key: "section-consents",
          sectionName: "consents",
          number: 28,
          fields: [
            new FormField({
              defaultValue: null,
              key: "is_zg_de_2",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_3",
              entity: Entity.CONTACT,
              isFalseable: false,
              isRequired: form => {
                const isDegreeRelationShipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
                if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_4",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_5",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_6",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_7",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_8",
              entity: Entity.CONTACT,
              isFalseable: false
            })
          ]
        }),*/

        // Hidden section (for fields that have constant values)
        new FormSection({
          key: "section-hidden",
          fields: [
            new FormField({
              key: "is_contact_source",
              entity: Entity.CONTACT,
              defaultValue: "717700001"
            }),
            new FormField({
              key: "is_last_section",
              entity: Entity.PATIENT,
              defaultValue: "rodo_section_1"
            })
          ]
        })
      ]
    });
  }

  get payload() {
    const patientFields = this.getFieldsByEntity(Entity.PATIENT);
    const contactFields = this.getFieldsByEntity(Entity.CONTACT);
    const careBoxFields = this.getFieldsByEntity(Entity.CARE_BOX);
    const careBoxIngredientsFields = this.getFieldsByEntity(Entity.CARE_BOX_INGRIDIENTS);
    
    return {
      patient: flattenFields(patientFields),
      contact: flattenFields(contactFields),
      ccareBox: flattenFields(careBoxFields),
      careBoxIngredients: flattenFields(careBoxIngredientsFields),
    };
  }

  getRetiveDataCareBox(id) {
    let retrieveData = [];
    const careBoxFields = this.getFieldsByEntity(Entity.CARE_BOX);
    let flattenFields = flattenFieldsArraywithSkiped(careBoxFields);
    flattenFields = flattenFields.map(item => {
      if (item === 'is_pflegegrad_duplicated' ) {
        return 'is_pflegegrad';
      } else {
        return item;
      }
    });
    retrieveData.push({
      id: id,
      entity: Entity.CARE_BOX,
      fields: flattenFields
    });
    return retrieveData;
  }

  getRetiveDataCareBoxIng(id) {
    let retrieveData = [];
    const careBoxIngFields = this.getFieldsByEntity(Entity.CARE_BOX_INGRIDIENTS);
    let flattenFields = flattenFieldsArraywithSkiped(careBoxIngFields);
    flattenFields = flattenFields.map(item => {
      if (duplicatedIngredients.includes(item)) {
        return item.replace('_duplicated', '');
      } else {
        return item;
      }
    });
    retrieveData.push({
      id: id,
      entity: Entity.CARE_BOX_INGRIDIENTS,
      fields: flattenFields
    });
    return retrieveData;
  }

  getRetiveDataALl(lkId, patietList) {
    let retrieveData = [];
    let patietListWithOutZero = patietList.filter(element => element !== 0);
    if (patietList.length !== "0") {
      const patientFields = this.getFieldsByEntity(Entity.PATIENT);
      const flattenFields = flattenFieldsArraywithSkiped(patientFields);
      patietListWithOutZero.forEach(element => {
        retrieveData.push({
          id: element,
          entity: Entity.PATIENT,
          fields: flattenFields
        });
      });
    }
    if (lkId !== "0") {
      const contactFields = this.getFieldsByEntity(Entity.CONTACT);
      if (patietList.length !== "0") {
        if (patietListWithOutZero.length > 0) {
          retrieveData.push({
            id: lkId,
            entity: Entity.CONTACT,
            fields: flattenFieldsArraywithSkiped(contactFields)
          });
        } else {
          retrieveData.push({
            id: lkId,
            entity: Entity.CONTACT,
            fields: flattenFieldsArraySkipedBoth(contactFields)
          });
        }
      } else {
        retrieveData.push({
          id: lkId,
          entity: Entity.CONTACT,
          fields: flattenFieldsArraySkipedBoth(contactFields)
        });
      }
    }
    return retrieveData;
  }

  getPayloadForSection(sectionKey, lkId, patientId) {
    const patientFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.PATIENT,
      true
    );
    const contactFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CONTACT,
      true
    );
    let patientDataToSave = flattenFields(patientFields);
    if (patientId === "0") {
      if (Object.keys(patientDataToSave).length > 0) {
        patientDataToSave["is_contact_id"] = lkId;
      }
    }
    let returnArray = [];
    if (patientId === "0" || patientId === 0) {
      patientDataToSave["is_contact_id"] = lkId;
    }
    let field = this.getFieldByKey(LAST_SECTION_KEY);
    if(field){
      patientDataToSave[LAST_SECTION_KEY] = field.value
    }

    const shouldSaveTilesString = this.getFieldByKey(SKIP_SAVE_STRING_TILES);
    if(shouldSaveTilesString.value){
      let isTakingCarePatientField = this.getFieldByKey(IS_MOST_IMPORTANT_TAKING_CARE_PATIENT);
      if(isTakingCarePatientField){
        patientDataToSave[IS_MOST_IMPORTANT_TAKING_CARE_PATIENT] = isTakingCarePatientField.value
      }
    }

    const shouldSaveDietDiabetisField = this.getFieldByKey(SKIP_SAVE_IS_DIET_DIABETES);
    if(shouldSaveDietDiabetisField.value){
      let isDietDiabetes = this.getFieldByKey(IS_DIET_DIABETES);
      if(isDietDiabetes){
        patientDataToSave[IS_DIET_DIABETES] = isDietDiabetes.value
      }
    }
    returnArray.push({
      Id: patientId,
      Entity: Entity.PATIENT,
      Fields: patientDataToSave
    });

    let contactFieldsToSave = flattenFields(contactFields);
    if(sectionKey === 'section-care-box') {
      const valueCareBox = this.getFieldByKey("is_lead_status_care_box").value;
      if(valueCareBox == '717700002') {
        delete contactFieldsToSave.is_private_client;
      }
    }
    if (Object.keys(contactFieldsToSave).length > 0) {
      returnArray.push({
        Id: lkId,
        Entity: Entity.CONTACT,
        Fields: contactFieldsToSave
      });
    }
    return returnArray;
  }

  getCareBox(sectionKey, careBoxId) {
    let careBoxFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CARE_BOX,
      true
    );
    
    let returnArray = [];
    
    if (Object.keys(careBoxFields).length > 0) {
      careBoxFields = careBoxFields.map(item => {
        if(item.key.endsWith('_duplicated')) {
            item.key = item.key.replace('_duplicated', '');
        }
        return item;
      });
      let careBoxFieldsToSave = flattenFields(careBoxFields);
      returnArray.push({
        Id: careBoxId,
        Entity: Entity.CARE_BOX,
        Fields: careBoxFieldsToSave
      });
    }

    return returnArray;
  }

  getCareBoxIngredients(sectionKey, ingredientsId, idToSave) {
    let careBoxIngredientsFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CARE_BOX_INGRIDIENTS,
      false
    );
    if (ingredientsId) {
      let careBoxIngredientsIDFields = this.getFieldByKey('is_care_box_appid');
      careBoxIngredientsIDFields.value = ingredientsId;
      careBoxIngredientsFields["is_care_box_appid"] = careBoxIngredientsIDFields;
    }

    let returnArray = [];
    if (Object.keys(careBoxIngredientsFields).length > 0) {
      careBoxIngredientsFields = careBoxIngredientsFields.map(item => {
        if(item.key.endsWith('_duplicated')) {
            item.key = item.key.replace('_duplicated', '');
        }
        return item;
      });
      let careBoxIngredientsFieldsToSave = flattenFields(careBoxIngredientsFields);
      returnArray.push({
        Id: idToSave,
        Entity: Entity.CARE_BOX_INGRIDIENTS,
        Fields: careBoxIngredientsFieldsToSave
      });
    }

    return returnArray;
  }

  isEditableClient(status) {
    if(status === '717700000') {
      return true;
    } else {
      return false;
    }
  }

  isEditableClientCareBox(status) {
    if(status === '717700001') {
      return true;
    } else {
      return false;
    }
  }

  changeLeadStatusAfterEndOfForm(lkId) {
    return [
      {
        "Id": lkId,
        "Entity": Entity.CONTACT,
        "Fields": {'is_customer_status_de': '717700011'}
      }
    ]
  }
}
