import * as React from 'react';

import { connect } from 'react-redux';
import { translateKey, getPromedicaProductsPricesData } from '../../../../_redux/actions/index';

import { formatPrice } from '../../../../_helpers/price_format';
import { PriceType } from '../../../../_constance/enums/priceType';

// key needed to retrieve information about pflegegrad
const IS_PFLEGEGRAD = 'is_pflegegrad';
const IS_KEIN_PFLEGEGRAD = 'is_kein_pflegegrad';

// key needed to retrieve information about Bawaria postalcode
const IS_SERVICE_ADDRESS_POSTAL_CODE = 'is_service_address_postal_code';

//key name to select isBawaria discount 
const bawariaKeyName = 'fee_bayern';

class CartSection extends React.Component {
  getDiscount = (form) => {
    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const isKeinPflegegrad = form.getFieldByKey(IS_KEIN_PFLEGEGRAD);

    if (isKeinPflegegrad.value) {
      return false;
    }

    if(pflegegrad.value.toString() === '717700000') {
      return {
        name: "from-text-pflegegrad1",
        price: "0.00"
      }
    }

    if(pflegegrad.value.toString() === '717700001') {
      return {
        name: "from-text-pflegegrad2",
        price: "347.00"
      }
    }

    if(pflegegrad.value.toString() === '717700002') {
      return {
        name: "from-text-pflegegrad3",
        price: "599.00"
      }
    }

    if(pflegegrad.value.toString() === '717700003') {
      return {
        name: "from-text-pflegegrad4",
        price: "800.00"
      }
    }

    if(pflegegrad.value.toString() === '717700004') {
      return {
        name: "from-text-pflegegrad5",
        price: "990.00"
      }
    }
  }

  isPostalCodeBawaria = () => {
    const { cities, form, secondPatientForm } = this.props;

    const isServiceAddressPostalCode = form.getFieldByKey(IS_SERVICE_ADDRESS_POSTAL_CODE).value;
    if (cities.length > 0) {
      for (let index = 0; index < cities.length; index++) {
        if (cities[index].PostalCode.indexOf(isServiceAddressPostalCode) > -1) {
          if (cities[index].Bawaria === 1) {
            const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
            const pflegegradStringValue = pflegegrad.value.toString();
            const pflegegradSecondPatient = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);
            const pflegegradStringValueSecondPatient = pflegegradSecondPatient.value.toString();
            if(
              pflegegradStringValue === '717700001' ||
              pflegegradStringValue === '717700002' ||
              pflegegradStringValue === '717700003' ||
              pflegegradStringValue === '717700004' ||
              pflegegradStringValueSecondPatient === '717700001' ||
              pflegegradStringValueSecondPatient === '717700002' ||
              pflegegradStringValueSecondPatient === '717700003' ||
              pflegegradStringValueSecondPatient === '717700004'
            ) {
              return true;
            }
          }
        }
      }
      return false;
    } else {
      return false;
    }
  }

  get priceOfLanguagePacket() {
    const { availableLanguagePackets, selectedLanguagePacket } = this.props;

    return availableLanguagePackets.find(item => item.id === selectedLanguagePacket).price;
  }

  get totalPrice() {
    const { selectedAdditionalOptions, availableAdditionalOptions } = this.props;

    let totalPriceOfAdditionalOptions = 0.00;

    selectedAdditionalOptions.forEach(element => {
      totalPriceOfAdditionalOptions = totalPriceOfAdditionalOptions + parseFloat(availableAdditionalOptions.find(item => item.id === element).price)
    });

    return (parseFloat(this.priceOfLanguagePacket) + totalPriceOfAdditionalOptions).toString();
  }

  get bawariaDiscount(){
    const { form, secondPatientForm, promedicaProductsPrices } = this.props;
    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValue = pflegegrad.value.toString();
    const pflegegradSecondPatient = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValueSecondPatient = pflegegradSecondPatient.value.toString();
    if(
      pflegegradStringValue === '717700001' ||
      pflegegradStringValue === '717700002' ||
      pflegegradStringValue === '717700003' ||
      pflegegradStringValue === '717700004' ||
      pflegegradStringValueSecondPatient === '717700001' ||
      pflegegradStringValueSecondPatient === '717700002' ||
      pflegegradStringValueSecondPatient === '717700003' ||
      pflegegradStringValueSecondPatient === '717700004'
    ) {
      const priceDiscount = promedicaProductsPrices.find(item => item.id === bawariaKeyName);
      if(priceDiscount){
        if(
          (pflegegradStringValue === '717700001' ||
          pflegegradStringValue === '717700002' ||
          pflegegradStringValue === '717700003' ||
          pflegegradStringValue === '717700004' ) &&
          (pflegegradStringValueSecondPatient === '717700001' ||
          pflegegradStringValueSecondPatient === '717700002' ||
          pflegegradStringValueSecondPatient === '717700003' ||
          pflegegradStringValueSecondPatient === '717700004')
        ) {
          return (Math.abs(parseFloat(priceDiscount.price))*2);
        } else {
          return Math.abs(parseFloat(priceDiscount.price));
        }
      }
    }

    return 0;
  }
 
  get totalPriceWithDiscount() {
    const { selectedAdditionalOptions, availableAdditionalOptions, form, promedicaProductsPrices, isAditionalPatient, secondPatientForm } = this.props;

    let totalPriceOfAdditionalOptions = 0.00;
    let bawariaDiscount = 0.00;
    let pflegegradDiscount = 0.00;
    let pflegegradDiscountSecondPatient = 0.00;

    selectedAdditionalOptions.forEach(element => {
      totalPriceOfAdditionalOptions = totalPriceOfAdditionalOptions + parseFloat(availableAdditionalOptions.find(item => item.id === element).price)
    });

    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValue = pflegegrad.value.toString();
    const pflegegradSecondPatient = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValueSecondPatient = pflegegradSecondPatient.value.toString();

    if(
      pflegegradStringValue === '717700001' ||
      pflegegradStringValue === '717700002' ||
      pflegegradStringValue === '717700003' ||
      pflegegradStringValue === '717700004' ||
      pflegegradStringValueSecondPatient === '717700001' ||
      pflegegradStringValueSecondPatient === '717700002' ||
      pflegegradStringValueSecondPatient === '717700003' ||
      pflegegradStringValueSecondPatient === '717700004'
    ) {
      if(this.isPostalCodeBawaria()) {
        const priceDiscount = promedicaProductsPrices.find(item => item.id === bawariaKeyName);
        if(priceDiscount){
          bawariaDiscount = parseFloat(priceDiscount.price);

          if(
            (pflegegradStringValue === '717700001' ||
            pflegegradStringValue === '717700002' ||
            pflegegradStringValue === '717700003' ||
            pflegegradStringValue === '717700004' )&&
            (pflegegradStringValueSecondPatient === '717700001' ||
            pflegegradStringValueSecondPatient === '717700002' ||
            pflegegradStringValueSecondPatient === '717700003' ||
            pflegegradStringValueSecondPatient === '717700004')
          ) {
            bawariaDiscount = bawariaDiscount * 2;
          }
        }
      }
    }

    if (this.getDiscount(form)) {
      pflegegradDiscount = parseFloat(this.getDiscount(form).price);
    }

    if (this.getDiscount(secondPatientForm) && isAditionalPatient) {
      pflegegradDiscountSecondPatient = parseFloat(this.getDiscount(secondPatientForm).price);
    }
    return (parseFloat(this.priceOfLanguagePacket) + totalPriceOfAdditionalOptions - pflegegradDiscount - pflegegradDiscountSecondPatient + bawariaDiscount).toString();
  }

  renderCartRow = (label, price, bigPrice = false, boldPrice = false, minus = false) => {
    const { priceType } = this.props;
    
    return (
      <div className="section-item w-100 d-flex align-items-center justify-content-between" key={Math.random().toString(36).substr(2, 9)}>
        <div>{label}</div>
        <div
          className={`
            ${bigPrice && "big-price"} 
            ${boldPrice && "bold-price"}
          `}
        >
          {minus && "-"}{ priceType === PriceType.MONTHLY ? formatPrice(price) : formatPrice(price/30) }
        </div>
      </div>
    )
  }

  render() {
    const { 
      translateKey, 
      selectedLanguagePacket, 
      availableLanguagePackets,
      selectedAdditionalOptions,
      availableAdditionalOptions,
      priceType,
      secondPatientForm,
      isAditionalPatient,
      form
    } = this.props;

    const patientName = form.getFieldByKey("is_first_name_and_last_name_of_patient").value;
    const secondPateintName = secondPatientForm.getFieldByKey("is_first_name_and_last_name_of_patient").value;
    return (
      <div className="container-fluid no-gutters p-0 d-flex flex-column h-100">
        {
          priceType === PriceType.NONE ? 
            <div className="cart-section">
              <p>
                { translateKey("summary-offer-none-price") }
              </p>
            </ div> 
          :
            <div className="cart-section">
              <div className="row w-100 no-gutters d-flex flex-column">
                <div className="section-item font-weight-bold">
                  {translateKey("summary-offer-cart-section-label-1")}
                </div>
                {this.renderCartRow(
                  availableLanguagePackets.find(item => item.id === selectedLanguagePacket).label,
                  this.priceOfLanguagePacket,
                  true
                )}
                {selectedAdditionalOptions.map(id => (
                  this.renderCartRow(
                    translateKey(availableAdditionalOptions.find(item => item.id === id).label),
                    availableAdditionalOptions.find(item => item.id === id).price
                  )
                ))}
              </div>
              <div className="row-separator" />
              {(this.getDiscount(form) || this.isPostalCodeBawaria()) &&
                <React.Fragment>
                  <div className="row w-100 d-flex no-gutters">
                    <div className="section-item font-weight-bold">
                      {translateKey("summary-offer-cart-section-label-2")}
                    </div>
                    {this.getDiscount(form) && this.renderCartRow(
                      `${translateKey(this.getDiscount(form).name)} ${ isAditionalPatient ? `(${ patientName })` : '' }`,
                      this.getDiscount(form).price,
                      false,
                      false,
                      true
                    )}
                    {
                      isAditionalPatient ? (
                        this.getDiscount(secondPatientForm) && this.renderCartRow(
                          `${translateKey(this.getDiscount(secondPatientForm).name)} (${ secondPateintName })`,
                          this.getDiscount(secondPatientForm).price,
                          false,
                          false,
                          true
                        )
                      ) : ''
                    }
                    {this.isPostalCodeBawaria() && this.renderCartRow(
                      translateKey("form-text-landesgeld-bayern"),
                      this.bawariaDiscount,
                      false,
                      false,
                      true
                    )}
                  </div>
                  <div className="row-separator" />
                </React.Fragment>
              }
              <div className="row d-flex no-gutters font-weight-bold justify-content-between">
                <div className="section-item">
                  { priceType === PriceType.MONTHLY ? translateKey("summary-offer-sprache-footer").toUpperCase() : translateKey("summary-offer-sprache-footer-daily-payment") }
                </div>
                <div className="section-item text-align-right">
                  {this.totalPriceWithDiscount!==this.totalPrice &&
                    <div className="summary-offer-with-discount">
                      <div className="discount-price with">
                      { priceType === PriceType.MONTHLY ? formatPrice(this.totalPriceWithDiscount) : formatPrice(this.totalPriceWithDiscount/30) }
                        {/* {formatPrice(this.totalPriceWithDiscount)} */}
                      </div>
                      <div className="discount-text with">
                        {translateKey("summary-offer-with-discount")}
                      </div>
                    </div>
                  }

                  <div className="summary-offer-with-discount">
                    <div className="discount-price without">
                    { priceType === PriceType.MONTHLY ? formatPrice(this.totalPrice) : formatPrice(this.totalPrice/30) }
                      {/* {formatPrice(this.totalPrice)} */}
                    </div>
                    <div className="discount-text without">
                      {translateKey("summary-offer-without-discount")}
                    </div>
                  </div>

                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cities: state.enums.citiesList,
    promedicaProductsPrices: state.enums.promedicaProductsPrices,
    promedicaProductsPricesStatus: state.enums.promedicaProductsPricesStatus,
    priceType: state.priceType.priceType,
  };
}
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  getPromedicaProductsPricesData: () => dispatch(getPromedicaProductsPricesData())
})
export default connect(mapStateToProps, mapDispatchToProps)(CartSection);