import SortingElement from "./sortingElement";
import * as LanguageSelect from "../../../_constance/values/languageSelect";
const SortingClientsList = [
    new SortingElement(
        "DESC",
        'client',
        true,
        "text",
        'client',
        'clients_client',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patientName',
        false,
        "text",
        'patient',
        'clients_patient',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'serviceTypeDe',
        false,
        "text",
        'service_type',
        'clients_service_type_de',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'statusCareBox',
        false,
        "text",
        'care-box-status',
        'clients_status_cb',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'clientStatus',
        false,
        "text",
        'status',
        'clients_client_status',
        true,
        false,
        LanguageSelect.DE
    ),
    new SortingElement(
        "ASC",
        'locationC',
        false,
        "text",
        'client_city',
        'clients_location_client',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'locationP',
        false,
        "text",
        'patient_city',
        'clients_location_patient',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'contractStartDate',
        false,
        "date",
        'client-contract-start-status',
        'clients_contract_start_status',
        true,
        false,
        LanguageSelect.DE
    ),
    new SortingElement(
        "ASC",
        'contractEndDate',
        false,
        "date",
        'client-contract-end-status',
        'clients_contract_end_status',
        true,
        false,
        LanguageSelect.DE
    ),
    new SortingElement(
        "ASC",
        'contractStatus',
        false,
        "text",
        'client-contract-status-text',
        'clients_contract_status',
        true,
        false,
        LanguageSelect.DE
    ),
    new SortingElement(
        "ASC",
        'wzStartDate',
        false,
        "date",
        'client-carer-start-status',
        'clients_carer_status',
        true,
        false,
        LanguageSelect.DE
    ),
    new SortingElement(
        "ASC",
        'wzEndDate',
        false,
        "date",
        'carer_trip_end',
        'clients_carer_trip_end',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'nextWzStartDate',
        false,
        "date",
        'client-carer-next-status',
        'clients_carer_next_start',
        true,
        false,
        LanguageSelect.DE
    ),
    new SortingElement(
        "ASC",
        'cellPhone',
        false,
        "text",
        'client_cellPhone',
        'clients_client_cellPhone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'phone',
        false,
        "text",
        'client_phone',
        'clients_client_phone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'email',
        false,
        "text",
        'client_email_address',
        'clients_client_email',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'address.street',
        false,
        "text",
        'client_street',
        'clients_client_street',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'address.buildingNumber',
        false,
        "text",
        'client_building',
        'clients_client_building',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'address.postalCode',
        false,
        "text",
        'client_postal_code',
        'clients_client_postal_code',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'correspondenceSameAsMain',
        false,
        "text",
        'client_correspondenceSameAsMain',
        'clients_correspondenceSameAsMain',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patient.patientAddress.cellPhone',
        false,
        "text",
        'patient_cellPhone',
        'clients_patient_cellPhone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patient.patientAddress.phone',
        false,
        "text",
        'patient_phone',
        'clients_patient_phone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patient.patientAddress.street',
        false,
        "text",
        'patient_street',
        'clients_patient_street',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patient.patientAddress.postalCode',
        false,
        "text",
        'patient_postal_code',
        'clients_patient_postal_code',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patient.patientAddress.buildingNumber',
        false,
        "text",
        'patient_building',
        'patient_client_building',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patient.birthdate',
        false,
        "date",
        'patient_date_of_birth',
        'clients_patient_birthdate',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'trackingStatus',
        false,
        "text",
        'carer-trip-tracking-status',
        'carer_trip_tracking_status',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'dateAndTimeArrival',
        false,
        "date",
        'carer-trip-date-and-time-arrival',
        'carer_trip_date_and_time_arrival',
        false,
        false,
        null
    ),
];
export default class SortingClients {
    sortingColumns = [];

    constructor() {
        this.sortingColumns = SortingClientsList;
    }

    getColumnByKey(key) {
        return this.sortingColumns.find(column => column.keyName === key);
    }

    getColumnByColumn(columnName) {
        return this.sortingColumns.find(column => column.column === columnName);
    }
    changeColumnByColumn(columnName) {
        this.sortingColumns.forEach(column => {
            if(column.column === columnName){
                if(column.active===true){
                    column.type==='ASC'? column.type="DESC" : column.type='ASC';
                }else{
                    column.type='ASC';
                    column.active = true;
                }
            }else{
                column.type='ASC';
                column.active = false;
            }
        });
    }

    setColumnEnabled (enabled, key){
        for(let i=0; i<this.sortingColumns.length; i++){
            if(this.sortingColumns[i].keyName===key){
                this.sortingColumns[i].enabled = enabled;
                break
            }
        }
    }

    isColumnActive(key){
        return this.sortingColumns.find(column => column.keyName === key && column.acitve === true);
    }

    isColumnVisibleByKey(key, selectedLang) {
        return this.sortingColumns.find(column => {
            if(column.keyName === key && column.blocked === false && column.enabled === true){
                if(column.languageRestrictions!==null){
                    if(selectedLang === column.languageRestrictions){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return true;
                }
            }else{
                return false;
            }
        });
    }
}