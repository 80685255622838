import React from 'react';
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";
import { ReqHelper } from "../../../_helpers/index";
import { MDBBtn  } from 'mdbreact';

//types
const MINUS = "MINUS";
const PLUS = "PLUS";

export default class Counter extends React.Component {
    static defaultProps = {
        valuesList: [],
        validationErrors: [],
        className: '',
        maxValue: null
    };

    setTypeChange = ( key, value ) => {
        if(!ReqHelper.isInteger(value)){value = 0;}
        const { setTypeChangeToParent} = this.props;
        setTypeChangeToParent(key, `${value}`);
    };

    changeCounter = (type)=>{
        const { keyName, setTypeChangeToParent, field, disabled, maxValue } = this.props;
        let prevNumber = field.value;
        if (!disabled) {
            if(!ReqHelper.isInteger(prevNumber)){
                prevNumber = 0;
            }else{
                if(type===PLUS){
                    if(maxValue === null) {
                        prevNumber++;
                    } else {
                        if(prevNumber == maxValue) {
                            prevNumber = maxValue;
                        } else {
                            prevNumber++;
                        }
                    }
                }else{
                    if(prevNumber>0){
                        prevNumber--;
                    }else{
                        prevNumber = 0;
                    }
                }
            }
            setTypeChangeToParent(keyName, `${prevNumber}`);
        }
    }

    render() {
        const {
          keyName,
          validationErrors,
          showValidation,
          label,
          containerClass,
          counterClass,
          disabled,
          contentWrapperClass,
          field
        } = this.props;
        const showErrors = showValidation && validationErrors.length > 0;

        return (
            <div className={`${contentWrapperClass} select-component-wrapper ${showErrors ? 'is-invalid' : ''}`}>
                <label className='form-label-basic newUI'><span className="quick-label-position-fix">{label.toUpperCase()}</span></label>
                
                <div className={`${containerClass}`}>
                    <div className={`${counterClass}`}>

                        <MDBBtn className="btn btn-light btn-class-plus-minus" onClick={()=>this.changeCounter(MINUS)}>-</MDBBtn>
                        <input
                            type="text"
                            disabled={disabled}
                            className={`form-control counter-input newUI ${showErrors ? "is-invalid" : ""}`}
                            onChange={(e) =>this.setTypeChange(keyName, e.target.value)}
                            value={field.value}
                        />
                        <MDBBtn className="btn btn-light btn-class-plus-minus" onClick={()=>this.changeCounter(PLUS)}>+</MDBBtn>
                    </div>
                </div>

                { showErrors && (
                    <ValidationErrorsList keyName={keyName} errors={validationErrors} />
                )}
            </div>
        );
    }
}