import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { TableColumHead, TablePagination, LoaderTable, EmptyTableIcon, TableDateFormat, CarerClientStatus, CarerTableDetails, CarerNameTitle } from "../../index";
import { sortingCarers } from "../../../_constance/classes/sortingClasses/index";
import { Paging,} from "../../../_constance/classes/index";
import { ReqHelper } from "../../../_helpers/index";


class ClientCarersData extends React.Component {
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id: props.id,
            loading: true,
            sorting: sortingCarers,
            pagingItem: Paging,
            retries: 0,
            selectedKey: this.props.selectedKey,
            clientId: this.props.clientId
        };
    }

    componentDidMount() {
        document.body.classList.remove('modal-open');
        this.getInitialDataTime();
    }

    componentWillUnmount() {clearTimeout(this.myTimeout);};

    getInitialDataTime(){
        if(this.props.carerData.length>0){
          clearTimeout(this.myTimeout);
          this.filterOrDataChange();
        }else{
            if(this.state.retries<10){
                this.setState({retries: this.state.retries+1})
                this.myTimeout = setTimeout(() => {
                    this.getInitialDataTime()
                  }, 500);
            }else{
                this.setState({
                    retries: 0,
                    loading: false
                })
            }
        }
    }
    
    filterOrDataChange(){
        let finalDataToShow = this.props.carerData;
        ReqHelper.sortMeData(finalDataToShow, this.state.sorting);
        this.propsPagingData(finalDataToShow);
        setTimeout(() => {
            let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
            this.setState({
                data: finalData,
                loading: false
            })
        }, 50);
    }
    
    propsSortType(type){
        let sortingObj = [];
        for(let i=0; i<this.state.sorting.length; i++){
            if(this.state.sorting[i].column===type){
            if(this.state.sorting[i].active===true){
                let item = this.state.sorting[i];
                item.type==='ASC'? item.type="DESC" : item.type='ASC';
                sortingObj.push(item);
            }else{
                let item = this.state.sorting[i];
                item.type='ASC';
                item.active = true;
                sortingObj.push(item);
            }
            }else{
            let item = this.state.sorting[i];
            item.type='ASC';
            item.active = false;
            sortingObj.push(item);
            }
        }
        this.setState({sorting: sortingObj});
        this.filterOrDataChange();
    }

    propsPagingData(data){
        if(data.length<=25){
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                totalItems: data.length,
                itemsPerPage: prevState.pagingItem.itemsPerPage,
                currentPage: 1,
                visiblePages: 5,
                pageFrom: 1,
                pageTo: 5,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }else{
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                ...prevState.pagingItem,
                totalItems: data.length,
                pageFrom: prevState.pagingItem.pageFrom,
                pageTo: prevState.pagingItem.pageTo,
                currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }
    }

    selectItemToShow(element){
        if(this.props.isBreakRemoved || element.isBreak == false) {
            if (this.state.selectedKey !== element.wzid) {
                this.setState({selectedKey: element.wzid});
            } else {
                this.setState({selectedKey: 0});
            }
        }
    }

    render() {
        let emptyHandler;
        let carersData = [];
        if (this.state.loading) {
            emptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
        }else{
            if (this.state.data.length === 0) {
                emptyHandler = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.RefreshMe()} text={this.props.translateKey("no_data_to_show")} /></div>
            }else{
                for(let j=0; j<this.state.data.length; j++){
                    let style = "table-row-wrapper cursor-pointer";
                    style += this.state.data[j].isBreak ? " carer-break" : "";
                    carersData.push(
                        <tr className={style} key={j}  onClick={()=>{this.selectItemToShow(this.state.data[j])}}>
                            <td className={"table-cell-wrapper " + (this.state.data[j].carer !== null ? "got-data" : 'no-data')}><CarerNameTitle element={this.state.data[j]} title={this.props.title}/></td>
                            <td className={"table-cell-wrapper " + (this.state.data[j].status !== null ? "got-data" : 'no-data')}><CarerClientStatus carer={this.state.data[j]}/></td>
                            <td className={"table-cell-wrapper " + (this.state.data[j].from !== null ? "got-data" : 'no-data')}><TableDateFormat timestamp={this.state.data[j].from}/></td>
                            <td className={"table-cell-wrapper " + (this.state.data[j].to !== null ? "got-data" : 'no-data')}><TableDateFormat timestamp={this.state.data[j].to}/></td>
                        </tr>
                     )
                    if(this.state.selectedKey===this.state.data[j].wzid){
                        carersData.push(
                            <tr key={Math.random().toString(36).substr(2, 9)}>
                                <td colSpan="4" className="no_padding">
                                    <CarerTableDetails element={this.state.data[j]} />
                                </td>
                            </tr>
                        )
                    }
                }
            }
        }
        return (
            <div className="client-data-wrapper-tab">
                <div className="listData">
                    <table className="table">
                        <thead>
                            <tr>
                                <TableColumHead sorting={this.state.sorting} name={this.props.translateKey("carers")} onSortType={(data) => this.propsSortType(data)} type="carer" />
                                <TableColumHead sorting={this.state.sorting} name={this.props.translateKey("status")} onSortType={(data) => this.propsSortType(data)} type="status" />
                                <TableColumHead sorting={this.state.sorting} name={this.props.translateKey("date_from")} onSortType={(data) => this.propsSortType(data)} type="from" />
                                <TableColumHead sorting={this.state.sorting} name={this.props.translateKey("date_to")} onSortType={(data) => this.propsSortType(data)} type="to" />
                            </tr>
                        </thead>
                        <tbody>{carersData}</tbody>
                    </table>
                    {emptyHandler}
                    <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    title: state.auth.title,
    userId: state.auth.userId
}};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientCarersData));