import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey, changeInvoiceStatusToRedux } from "../../_redux/actions/index";
import { FpApprovalComponenet, IncludesComponenet, InvoiceDataComponent, InvoiceCollectionComponent, LoaderModal, AlertMessage} from "../../_components/index";
import { IconsMap } from "../../_helpers/icons_set";
import { acceptInvoice, rejectInvoice } from "../../_redux/actions/auth";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import * as LanguageSelect from "../../_constance/values/languageSelect";
import { baseData } from '../../_constance/base_data';

class InvoiceTableDetails extends React.Component {
    _isMounted = false;
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            paymentStatus: this.props.paymentStatus,
            invoiceStatus: this.props.invoiceStatus,
            rejectComments: '',
            rejectError: '',
            rejectModal: false,
            invoiceHtml: false,
            errorsMessage: null,
            errorsType: "danger",
            loaderModal: false,
            next: this.props.next,
            previous: this.props.previous,
            srcWeb: '../../_assets/invoice/DE/new/index.html?v='+baseData.hash
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.remove('modal-open');
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };

    rejectModalToggle = () => {
        if (this._isMounted) {
            this.setState({rejectModal: !this.state.rejectModal});
        }
    }

    InvoiceHtmlToggle = () => {
        this.prepareMeWebSrc();
        this.state.invoiceHtml?document.body.classList.remove('modal-open'):document.body.classList.add('modal-open');
        if (this._isMounted) {
            this.setState({invoiceHtml: !this.state.invoiceHtml});
        }
    }

    rejectCancel = () =>{
        if (this._isMounted) {
            this.setState({
                rejectComments:'',
                rejectModal: !this.state.rejectModal
            })
        }
    }

    rejectCommentChange(text){
        if (this._isMounted) {
            this.setState({rejectComments:text});
        }
    }

    rejectInvoice(){
        if(this.state.rejectComments.toString().trim().length<=0){
            if (this._isMounted) {
                this.setState({ rejectError: this.props.translateKey('add_comment')});
            }
            this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                    this.setState({ rejectError: ''});
                }
            }, 3000);
        }else{
            if(this.props.element.approval.Status==='717700000'){
                if (this._isMounted) {
                    this.setState({
                        loaderModal: true,
                        rejectModal: false
                    })
                }
                let RejectObj = {
                    "invoiceId" : this.props.element.Id,
                    "comment" : this.state.rejectComments
                }
                this.props.rejectInvoice(RejectObj)
                .then((res)=>{
                    if (this._isMounted) {
                        this.setState({
                            loaderModal: false,
                            rejectModal: false,
                            errorsMessage: null,
                            errorsType: "danger",
                        })
                    }
                    clearTimeout(this.myTimeout);
                    this.props.changeInvoiceStatusToRedux(this.props.element, this.props.element.Id, '717700002', this.state.rejectComments);
                    this.props.rejectInvoiceStatus();
                })
                .catch((err)=>{
                    console.log(err);
                    if (this._isMounted) {
                        this.setState({
                            rejectError: err,
                            loaderModal: false,
                            rejectModal: true
                        }) 
                    }
                    this.myTimeout = setTimeout(() => {
                        if (this._isMounted) {
                            this.setState({
                                rejectError: ''
                            }) 
                        }
                    }, 2000);
                })
            }  
        }
    }

    chevronPrevious = () =>{
        const {previous, previousNextClick, element} = this.props;
        if(previous!==0){previousNextClick(element.Id, "previous");}
    }

    chevronNext = () =>{
        const {next, previousNextClick, element} = this.props;
        if(next!==0){previousNextClick(element.Id, "next");}
    }

    acceptInvoice = ()=>{
        const { element, acceptInvoice, changeInvoiceStatusToRedux, acceptInvoiceStatus} =  this.props;
        if(element.approval.Status==='717700000'){
            if (this._isMounted) {this.setState({loaderModal: true})}
            acceptInvoice(element.Id)
            .then((res)=>{
                if (this._isMounted) {
                    this.setState({
                        loaderModal: false,
                        errorsMessage: null,
                        errorsType: "danger",
                    })
                }
                clearTimeout(this.myTimeout);
                changeInvoiceStatusToRedux(element, element.Id, '717700001', null);
                acceptInvoiceStatus();
            })
            .catch((err)=>{
                if (this._isMounted) {
                    this.setState({
                    errorsMessage: err,
                    errorsType: "danger",
                    loaderModal: false
                    }) 
                }
                this.myTimeout = setTimeout(() => {
                    if (this._isMounted) {
                        this.setState({
                            errorsMessage: null,
                            errorsType: "danger",
                        }) 
                    }
                }, 2000);
            })
        }      
    }

    prepareMeWebSrc(){
        const {element,appLanguage } = this.props;
        if(element.invoice.newTemplate!==null && element.invoice.newTemplate!==undefined){
            if(element.invoice.newTemplate===1 || element.invoice.newTemplate==='1'){
                if(appLanguage === LanguageSelect.DE){
                    if (this._isMounted) {
                        this.setState({srcWeb: `${baseData.base_url}${baseData.subFolders}_assets/invoice/DE/new/index.html?v=${baseData.hash}`})
                    }
                }else{
                    if (this._isMounted) {
                        this.setState({srcWeb: `${baseData.base_url}${baseData.subFolders}_assets/invoice/GB/new/index.html?v=${baseData.hash}`})
                    }
                }
            }else{
                if(appLanguage === LanguageSelect.DE){
                    if (this._isMounted) {
                        this.setState({srcWeb: `${baseData.base_url}${baseData.subFolders}_assets/invoice/DE/old/index.html?v=${baseData.hash}`})
                    }
                }else{
                    if (this._isMounted) {
                        this.setState({srcWeb: `${baseData.base_url}${baseData.subFolders}_assets/invoice/GB/old/index.html?v=${baseData.hash}`})
                    }
                }
            }
        }else{
            if(appLanguage === LanguageSelect.DE){
                if (this._isMounted) {
                    this.setState({srcWeb: `${baseData.base_url}${baseData.subFolders}_assets/invoice/DE/new/index.html?v=${baseData.hash}`})
                }
            }else{
                if (this._isMounted) {
                    this.setState({srcWeb: `${baseData.base_url}${baseData.subFolders}_assets/invoice/GB/new/index.html?v=${baseData.hash}`})
                }
            }
        }
    }

    iframeReady = () =>{
        let myJSONString = JSON.stringify(this.props.element);
        myJSONString =  myJSONString.replace(/\\n/g, '')
            .replace(/\\r/g, '')
            .replace(/\\t/g, '')
            .replace(/\\b/g, '')
            .replace(/\\f/g, '')
            .replace(/\\'/g, "\\'")
            .replace(/\\"/g, '\\"');
        document.getElementById("iframeInvoice").contentWindow.invoiceAddJSON(myJSONString);
    }

    checkIfSpecialSafariIssues(){
        if(navigator.userAgent.match(/(iPod|iPhone|iPad)/)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const { translateKey, element } = this.props;
        const { invoiceHtml, srcWeb, rejectModal, rejectError, loaderModal, rejectComments, errorsMessage, errorsType} = this.state;
        const divStyle = {height: (window.innerHeight-129)+"px"};
        let showHtmlModal = (
            <MDBModal className="invoice-modal-content" isOpen={invoiceHtml} toggle={this.InvoiceHtmlToggle} size="lg">
                <MDBModalHeader toggle={this.InvoiceHtmlToggle}></MDBModalHeader>
                <MDBModalBody className="no_padding">
                    <div className={"border-special-invoice "+(this.checkIfSpecialSafariIssues()?"iFrame-wrapper":"")} style={divStyle}>
                        <iframe 
                            title="iframeInvoice"   
                            src={srcWeb}
                            frameBorder="0"
                            id="iframeInvoice"
                            height={(window.innerHeight-129)+"px"}
                            width="100%"
                            onLoad={this.iframeReady}
                        />
                    </div>
                </MDBModalBody>
                <MDBModalFooter className="no-border-all">
                    <button type="button" className="btn btn-secondary" onClick={this.InvoiceHtmlToggle}>{translateKey('cancel')}</button>
                </MDBModalFooter>
            </MDBModal>
        )
        let filterModal = (
            <MDBModal isOpen={rejectModal} toggle={this.rejectModalToggle} size="lg">
                <MDBModalHeader toggle={this.rejectModalToggle}>{translateKey('invoice_comment_invoice')}</MDBModalHeader>
                    <MDBModalBody>
                        <textarea type="text" className="form-control" rows="3" onChange={e => this.rejectCommentChange(e.target.value)} value={rejectComments}/>
                        {rejectError.length>0 &&
                            <div className="alert alert-danger centeredAlertNoLimit" role="alert">
                                {rejectError}
                            </div>
                        }
                    </MDBModalBody>
                <MDBModalFooter>
                    <button type="button" className="btn btn-secondary" onClick={this.rejectCancel}>{translateKey('cancel')}</button>
                    <button type="button" className="btn btn-primary" onClick={()=>this.rejectInvoice()}>{translateKey('save')}</button>
                </MDBModalFooter>
            </MDBModal>
        )
        return (
            <div className="invoice-table-details-wrapper">
                <LoaderModal show={loaderModal}/>
                <div className="invoice-modal-details">{showHtmlModal}</div>
                {filterModal}
                <div className="invoice-accept-reject-wrapper">
                    {this.props.previous!==0 &&
                        <button className="btn btn-default btn-cancel-save-button" onClick={this.chevronPrevious}>
                            <i className="fas fa-chevron-left fa-2x"></i>
                        </button>
                    }
                    <button className="btn btn-default btn-cancel-save-button" onClick={this.InvoiceHtmlToggle}>
                        <i className="fas fa-search fa-2x"></i>
                        <span>{translateKey("show")}</span>
                    </button>
                    {element.approval.Status!=='717700000'?
                        <button className={"btn btn-default btn-cancel-save-button disabled-button-class"}>
                            <i className="fas fa-check fa-2x"></i>
                            <span>{translateKey("invoice_accept")}</span>
                        </button>
                        :
                        <button className="btn btn-default btn-cancel-save-button" onClick={this.acceptInvoice}>
                            <i className="fas fa-check fa-2x"></i>
                            <span>{translateKey("invoice_accept")}</span>
                        </button>
                    }
                    {element.approval.Status!=='717700000'?
                        <button className={"btn btn-default btn-cancel-save-button disabled-button-class"}>
                            <i className="fas fa-envelope fa-2x"></i>
                            <span>{translateKey("invoice_comment")}</span>
                        </button>
                        :
                        <button className="btn btn-default btn-cancel-save-button" onClick={this.rejectModalToggle}>
                            <i className="fas fa-envelope fa-2x"></i>
                            <span>{translateKey("invoice_comment")}</span>
                        </button>
                    }
                    {this.props.next!==0 &&
                        <button className="btn btn-default btn-cancel-save-button" onClick={this.chevronNext}>
                            <i className="fas fa-chevron-right fa-2x"></i>
                        </button>
                    }
                </div>
                {errorsMessage!==null &&
                    <AlertMessage centeredAlert={true} show={errorsMessage!==null} key="invoiceAlert1" type={errorsType} message={translateKey(errorsMessage)}/>
                }
                <div className="lead-details-client-info">
                        {
                            element.Payer.First_Name!==null && element.Payer.Last_Name!==null &&
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group row flexAlignCenter no-margin-row">
                                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("payer")}</label>
                                        <div className="col-sm-8">
                                            <span className="lead-input-wrapper">
                                                {element.Payer.Last_Name + ' ' + element.Payer.First_Name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            element.Payer.Email!==null &&
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group row flexAlignCenter no-margin-row">
                                        <label className="col-sm-4 col-form-label form-label-basic">
                                            <a href={"mailto:"+element.Payer.Email} target="_top">
                                                <img src={IconsMap.svg.email} alt="Email" className="user-name-icon"/>
                                            </a>
                                            {translateKey("payer_email")}
                                        </label>
                                        <div className="col-sm-8">
                                            {element.Payer.Email}
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            element.Payer.Phone!==null &&
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group row flexAlignCenter no-margin-row">
                                        <label className="col-sm-4 col-form-label form-label-basic">
                                            <a href={"tel:"+element.Payer.Phone}>    
                                                <img src={IconsMap.svg.phone} alt="Phone" className="user-name-icon"/>
                                            </a>
                                            {translateKey("payer_phone")}
                                        </label>
                                        <div className="col-sm-8">
                                            {element.Payer.Phone}
                                        </div>
                                    </div>
                                </div>
                        }
                </div>
                <FpApprovalComponenet element ={element} paymentStatus={this.props.paymentStatus}/>
                <InvoiceDataComponent element ={element} invoiceStatus={this.props.invoiceStatus}/>
                <IncludesComponenet element ={element}/>
                <InvoiceCollectionComponent element ={element}/>
            </div>
        );
    }
}

const mapStateToProps = state => {return {appLanguage: state.lang.appLanguage}};
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    acceptInvoice: (id) => dispatch(acceptInvoice(id)),
    rejectInvoice: (data) => dispatch(rejectInvoice(data)),
    changeInvoiceStatusToRedux: (invoice, id, status, comment) => dispatch(changeInvoiceStatusToRedux(invoice, id, status, comment))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceTableDetails));