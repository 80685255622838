import {
    FINISH_BASIC_DATA_SAVE,
    LOADING_ENUMS,
    PENDING_ACTIVITYTYPE_ENUM,
    PENDING_CALLDIRECTION_ENUM,
    PENDING_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM,
    PENDING_CARE_BOX_INGREDIENTS_STATUS_ENUM,
    PENDING_CARE_BOX_REJECTION_REASON_ENUM,
    PENDING_CARE_BOX_STATUS_ENUM,
    PENDING_CAREDEGREE_ENUM,
    PENDING_CAREDEGREEAPPLICATION_ENUM,
    PENDING_CAREOTHERREQUIREMENTS_ENUM,
    PENDING_CITIES_ENUM,
    PENDING_CONTRACTRATETYPE_ENUM,
    PENDING_CONTRACTSTATUS_ENUM,
    PENDING_DISEASES_ENUM,
    PENDING_DS_TEMPLATES_ENUM,
    PENDING_HEALTH_INCURANCE_ENUM,
    PENDING_HEALTHSTATE_ENUM,
    PENDING_INVOICEAPPROVALSTATUS_ENUM,
    PENDING_INVOICECORRECTION_ENUM,
    PENDING_INVOICEPAYMENT_ENUM,
    PENDING_LEAD_CAREBOX_STATUS_ENUM,
    PENDING_LEADSOURCE_ENUM,
    PENDING_LEADSTATUS_ENUM,
    PENDING_LIVEALONE_ENUM,
    PENDING_MARKETING_ENUM,
    PENDING_MULTIPLIERS_CATEGORIES_ENUM,
    PENDING_PROMEDICA_PRODUCTS_PRICES_ENUM,
    PENDING_RATE_DATA_ENUM,
    PENDING_REJECT_REASON_ENUM,
    PENDING_SERVICE_TYPE_DE_ENUM,
    PENDING_SITTERS_AVAILABILITY_ENUM,
    PENDING_SMOKINGATTITUDE_ENUM,
    PENDING_TASKPRIORITY_ENUM, PENDING_TRIP_TRACKING_STATUS_ENUM,
    REMOVE_ACTIVITYTYPE_ENUM,
    REMOVE_ALL_BASIC_DATA_SAVE,
    REMOVE_CALLDIRECTION_ENUM,
    REMOVE_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM,
    REMOVE_CARE_BOX_INGREDIENTS_STATUS_ENUM, REMOVE_CARE_BOX_REJECTION_REASON_ENUM,
    REMOVE_CARE_BOX_STATUS_ENUM,
    REMOVE_CAREDEGREE_ENUM,
    REMOVE_CAREDEGREEAPPLICATION_ENUM,
    REMOVE_CAREOTHERREQUIREMENTS_ENUM,
    REMOVE_CITIES_ENUM,
    REMOVE_CONTRACTRATETYPE_ENUM,
    REMOVE_CONTRACTSTATUS_ENUM,
    REMOVE_DISEASES_ENUM,
    REMOVE_DS_TEMPLATES_ENUM,
    REMOVE_HEALTH_INCURANCE_ENUM,
    REMOVE_HEALTHSTATE_ENUM,
    REMOVE_INVOICEAPPROVALSTATUS_ENUM,
    REMOVE_INVOICECORRECTION_ENUM,
    REMOVE_INVOICEPAYMENT_ENUM,
    REMOVE_LEAD_CAREBOX_STATUS_ENUM,
    REMOVE_LEADSOURCE_ENUM,
    REMOVE_LEADSTATUS_ENUM,
    REMOVE_LIVEALONE_ENUM,
    REMOVE_MARKETING_ENUM,
    REMOVE_MULTIPLIERS_CATEGORIES_ENUM,
    REMOVE_PROMEDICA_PRODUCTS_PRICES_ENUM,
    REMOVE_RATE_DATA_ENUM,
    REMOVE_REJECT_REASON_ENUM,
    REMOVE_SERVICE_TYPE_DE_ENUM,
    REMOVE_SITTERS_AVAILABILITY_ENUM,
    REMOVE_SMOKINGATTITUDE_ENUM,
    REMOVE_TASKPRIORITY_ENUM, REMOVE_TRIP_TRACKING_STATUS_ENUM,
    SET_ACTIVITYTYPE_ENUM,
    SET_CALLDIRECTION_ENUM,
    SET_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM,
    SET_CARE_BOX_INGREDIENTS_STATUS_ENUM,
    SET_CARE_BOX_REJECTION_REASON_ENUM,
    SET_CARE_BOX_STATUS_ENUM,
    SET_CAREDEGREE_ENUM,
    SET_CAREDEGREEAPPLICATION_ENUM,
    SET_CAREOTHERREQUIREMENTS_ENUM,
    SET_CITIES_ENUM,
    SET_CONTRACTRATETYPE_ENUM,
    SET_CONTRACTSTATUS_ENUM,
    SET_DISEASES_ENUM,
    SET_DS_TEMPLATES_ENUM,
    SET_HEALTH_INCURANCE_ENUM,
    SET_HEALTHSTATE_ENUM,
    SET_INVOICEAPPROVALSTATUS_ENUM,
    SET_INVOICECORRECTION_ENUM,
    SET_INVOICEPAYMENT_ENUM,
    SET_LEAD_CAREBOX_STATUS_ENUM,
    SET_LEADSOURCE_ENUM,
    SET_LEADSTATUS_ENUM,
    SET_LIVEALONE_ENUM,
    SET_MARKETING_ENUM,
    SET_MULTIPLIERS_CATEGORIES_ENUM,
    SET_PROMEDICA_PRODUCTS_PRICES_ENUM,
    SET_RATE_DATA_ENUM,
    SET_REJECT_REASON_ENUM,
    SET_SERVICE_TYPE_DE_ENUM,
    SET_SITTERS_AVAILABILITY_ENUM,
    SET_SMOKINGATTITUDE_ENUM,
    SET_TASKPRIORITY_ENUM, SET_TRIP_TRACKING_STATUS_STATUS_ENUM,
    PENDING_GROUP_CAUSE_FP_ENUM,
    SET_GROUP_CAUSE_FP_ENUM,
    REMOVE_GROUP_CAUSE_FP_ENUM
} from "../actions/action-types";
import {ResponseStatus} from '../../_helpers/response_status'

const initialState = {
    diseases: [],
    diseasesStatus: ResponseStatus.FREE,
    careDegreeApplication: [],
    careDegreeApplicationStatus: ResponseStatus.FREE,
    invoicePayment: [],
    invoicePaymentStatus: ResponseStatus.FREE,
    carerOtherRequirements: [],
    carerOtherRequirementsStatus: ResponseStatus.FREE,
    contractRateType: [],
    contractRateTypeStatus: ResponseStatus.FREE,
    healthState: [],
    healthStateStatus: ResponseStatus.FREE,
    taskPriority: [],
    taskPriorityStatus: ResponseStatus.FREE,
    leadStatus: [],
    leadStatusStatus: ResponseStatus.FREE,
    leadCareBoxStatus: [],
    leadCareBoxStatusStatus: ResponseStatus.FREE,
    invoiceApproval: [],
    invoiceApprovalStatus: ResponseStatus.FREE,
    contractStatus: [],
    contractStatusStatus: ResponseStatus.FREE,
    liveAlone: [],
    liveAloneStatus: ResponseStatus.FREE,
    leadSource: [],
    leadSourceStatus: ResponseStatus.FREE,
    activityType: [],
    activityTypeStatus: ResponseStatus.FREE,
    invoiceCorrection: [],
    invoiceCorrectionStatus: ResponseStatus.FREE,
    careDegree: [],
    careDegreeStatus: ResponseStatus.FREE,
    smokingAttitude: [],
    smokingAttitudeStatus: ResponseStatus.FREE,
    callDirection: [],
    callDirectionStatus: ResponseStatus.FREE,
    citiesList: [],
    citiesListStatus: ResponseStatus.FREE,
    healthIncuranceList: [],
    healthIncuranceListStatus: ResponseStatus.FREE,
    marketingsList: [],
    marketingsListStatus: ResponseStatus.FREE,
    rejectReasonsList: [],
    rejectReasonsListStatus: ResponseStatus.FREE,
    careBoxRejectionReasonsList: [],
    careBoxRejectionReasonsListStatus: ResponseStatus.FREE,
    multipliersCategoryList: [],
    multipliersCategoryStatus: ResponseStatus.FREE,
    sittersAvailability: [],
    sittersAvailabilityStatus: ResponseStatus.FREE,
    promedicaProductsPrices: [],
    promedicaProductsPricesStatus: ResponseStatus.FREE,
    serviceTypeDeList: [],
    serviceTypeDeListStatus: ResponseStatus.FREE,
    careBoxStatusList: [],
    careBoxStatusListStatus: ResponseStatus.FREE,
    careBoxDeliveryToAddressList: [],
    careBoxDeliveryToAddressStatus: ResponseStatus.FREE,
    careBoxIngredientsStatusList: [],
    careBoxIngredientsStatusListStatus: ResponseStatus.FREE,
    tripTrackingStatusList: [],
    tripTrackingStatusListStatus: ResponseStatus.FREE,
    groupCauseFpList: [],
    groupCauseFpStatus: ResponseStatus.FREE,
    dsTemplates: [],
    dsTemplatesStatus: ResponseStatus.FREE,
    rateData: null,
    rateDataStatus: ResponseStatus.FREE,
    loadingEnums: false,
    finishSave: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DISEASES_ENUM:
            return {
                ...state,
                diseases: action.data,
                diseasesStatus: ResponseStatus.READY
            };
        case PENDING_DISEASES_ENUM:
            return {
                ...state,
                diseasesStatus: ResponseStatus.PENDING
            };
        case REMOVE_DISEASES_ENUM:
            return {
                ...state,
                diseases: [],
                diseasesStatus: ResponseStatus.FREE,
            };
        case SET_CAREDEGREEAPPLICATION_ENUM:
            return {
                ...state,
                careDegreeApplication: action.data,
                careDegreeApplicationStatus: ResponseStatus.READY
            };
        case PENDING_CAREDEGREEAPPLICATION_ENUM:
            return {
                ...state,
                careDegreeApplicationStatus: ResponseStatus.PENDING
            };
        case REMOVE_CAREDEGREEAPPLICATION_ENUM:
            return {
                ...state,
                careDegreeApplication: [],
                careDegreeApplicationStatus: ResponseStatus.FREE,
            };
        case SET_INVOICEPAYMENT_ENUM:
            return {
                ...state,
                invoicePayment: action.data,
                invoicePaymentStatus: ResponseStatus.READY
            };
        case PENDING_INVOICEPAYMENT_ENUM:
            return {
                ...state,
                invoicePaymentStatus: ResponseStatus.PENDING
            };
        case REMOVE_INVOICEPAYMENT_ENUM:
            return {
                ...state,
                invoicePayment: [],
                invoicePaymentStatus: ResponseStatus.FREE,
            };
        case SET_CAREOTHERREQUIREMENTS_ENUM:
            return {
                ...state,
                carerOtherRequirements: action.data,
                carerOtherRequirementsStatus: ResponseStatus.READY
            };
        case PENDING_CAREOTHERREQUIREMENTS_ENUM:
            return {
                ...state,
                carerOtherRequirementsStatus: ResponseStatus.PENDING
            };
        case REMOVE_CAREOTHERREQUIREMENTS_ENUM:
            return {
                ...state,
                carerOtherRequirements: [],
                carerOtherRequirementsStatus: ResponseStatus.FREE,
            };
        case SET_CONTRACTRATETYPE_ENUM:
            return {
                ...state,
                contractRateType: action.data,
                contractRateTypeStatus: ResponseStatus.READY
            };
        case PENDING_CONTRACTRATETYPE_ENUM:
            return {
                ...state,
                contractRateTypeStatus: ResponseStatus.PENDING
            };
        case REMOVE_CONTRACTRATETYPE_ENUM:
            return {
                ...state,
                contractRateType: [],
                contractRateTypeStatus: ResponseStatus.FREE,
            };
        case SET_HEALTHSTATE_ENUM:
            return {
                ...state,
                healthState: action.data,
                healthStateStatus: ResponseStatus.READY
            };
        case PENDING_HEALTHSTATE_ENUM:
            return {
                ...state,
                healthStateStatus: ResponseStatus.PENDING
            };
        case REMOVE_HEALTHSTATE_ENUM:
            return {
                ...state,
                healthState: [],
                healthStateStatus: ResponseStatus.FREE,
            };
        case SET_TASKPRIORITY_ENUM:
            return {
                ...state,
                taskPriority: action.data,
                taskPriorityStatus: ResponseStatus.READY
            };
        case PENDING_TASKPRIORITY_ENUM:
            return {
                ...state,
                taskPriorityStatus: ResponseStatus.PENDING
            };
        case REMOVE_TASKPRIORITY_ENUM:
            return {
                ...state,
                taskPriority: [],
                taskPriorityStatus: ResponseStatus.FREE,
            };
        case SET_LEADSTATUS_ENUM:
            return {
                ...state,
                leadStatus: action.data,
                leadStatusStatus: ResponseStatus.READY
            };
        case PENDING_LEADSTATUS_ENUM:
            return {
                ...state,
                leadStatusStatus: ResponseStatus.PENDING
            };
        case REMOVE_LEADSTATUS_ENUM:
            return {
                ...state,
                leadStatus: [],
                leadStatusStatus: ResponseStatus.FREE,
            };
        case SET_LEAD_CAREBOX_STATUS_ENUM:
            return {
                ...state,
                leadCareBoxStatus: action.data,
                leadCareBoxStatusStatus: ResponseStatus.READY
            };
        case PENDING_LEAD_CAREBOX_STATUS_ENUM:
            return {
                ...state,
                leadCareBoxStatusStatus: ResponseStatus.PENDING
            };
        case REMOVE_LEAD_CAREBOX_STATUS_ENUM:
            return {
                ...state,
                leadCareBoxStatus: [],
                leadCareBoxStatusStatus: ResponseStatus.FREE,
            };
        case SET_INVOICEAPPROVALSTATUS_ENUM:
            return {
                ...state,
                invoiceApproval: action.data,
                invoiceApprovalStatus: ResponseStatus.READY
            };
        case PENDING_INVOICEAPPROVALSTATUS_ENUM:
            return {
                ...state,
                invoiceApprovalStatus: ResponseStatus.PENDING
            };
        case REMOVE_INVOICEAPPROVALSTATUS_ENUM:
            return {
                ...state,
                invoiceApproval: [],
                invoiceApprovalStatus: ResponseStatus.FREE,
            };
        case SET_CONTRACTSTATUS_ENUM:
            return {
                ...state,
                contractStatus: action.data,
                contractStatusStatus: ResponseStatus.READY
            };
        case PENDING_CONTRACTSTATUS_ENUM:
            return {
                ...state,
                contractStatusStatus: ResponseStatus.PENDING
            };
        case REMOVE_CONTRACTSTATUS_ENUM:
            return {
                ...state,
                contractStatus: [],
                contractStatusStatus: ResponseStatus.FREE,
            };
        case SET_LIVEALONE_ENUM:
            return {
                ...state,
                liveAlone: action.data,
                liveAloneStatus: ResponseStatus.READY
            };
        case PENDING_LIVEALONE_ENUM:
            return {
                ...state,
                liveAloneStatus: ResponseStatus.PENDING
            };
        case REMOVE_LIVEALONE_ENUM:
            return {
                ...state,
                liveAlone: [],
                liveAloneStatus: ResponseStatus.FREE,
            };
        case SET_LEADSOURCE_ENUM:
            return {
                ...state,
                leadSource: action.data,
                leadSourceStatus: ResponseStatus.READY
            };
        case PENDING_LEADSOURCE_ENUM:
            return {
                ...state,
                leadSourceStatus: ResponseStatus.PENDING
            };
        case REMOVE_LEADSOURCE_ENUM:
            return {
                ...state,
                leadSource: [],
                leadSourceStatus: ResponseStatus.FREE,
            };
        case SET_ACTIVITYTYPE_ENUM:
            return {
                ...state,
                activityType: action.data,
                activityTypeStatus: ResponseStatus.READY
            };
        case PENDING_ACTIVITYTYPE_ENUM:
            return {
                ...state,
                activityTypeStatus: ResponseStatus.PENDING
            };
        case REMOVE_ACTIVITYTYPE_ENUM:
            return {
                ...state,
                activityType: [],
                activityTypeStatus: ResponseStatus.FREE,
            };
        case SET_INVOICECORRECTION_ENUM:
            return {
                ...state,
                invoiceCorrection: action.data,
                invoiceCorrectionStatus: ResponseStatus.READY
            };
        case PENDING_INVOICECORRECTION_ENUM:
            return {
                ...state,
                invoiceCorrectionStatus: ResponseStatus.PENDING
            };
        case REMOVE_INVOICECORRECTION_ENUM:
            return {
                ...state,
                invoiceCorrection: [],
                invoiceCorrectionStatus: ResponseStatus.FREE,
            };
        case SET_CAREDEGREE_ENUM:
            return {
                ...state,
                careDegree: action.data,
                careDegreeStatus: ResponseStatus.READY
            };
        case PENDING_CAREDEGREE_ENUM:
            return {
                ...state,
                careDegreeStatus: ResponseStatus.PENDING
            };
        case REMOVE_CAREDEGREE_ENUM:
            return {
                ...state,
                careDegree: [],
                careDegreeStatus: ResponseStatus.FREE,
            };
        case SET_SMOKINGATTITUDE_ENUM:
            return {
                ...state,
                smokingAttitude: action.data,
                smokingAttitudeStatus: ResponseStatus.READY
            };
        case PENDING_SMOKINGATTITUDE_ENUM:
            return {
                ...state,
                smokingAttitudeStatus: ResponseStatus.PENDING
            };
        case REMOVE_SMOKINGATTITUDE_ENUM:
            return {
                ...state,
                smokingAttitude: [],
                smokingAttitudeStatus: ResponseStatus.FREE,
            };
        case SET_CALLDIRECTION_ENUM:
            return {
                ...state,
                callDirection: action.data,
                callDirectionStatus: ResponseStatus.READY
            };
        case PENDING_CALLDIRECTION_ENUM:
            return {
                ...state,
                callDirectionStatus: ResponseStatus.PENDING
            };
        case REMOVE_CALLDIRECTION_ENUM:
            return {
                ...state,
                callDirection: [],
                callDirectionStatus: ResponseStatus.FREE,
            };
        case SET_CITIES_ENUM:
            return {
                ...state,
                citiesList: action.data,
                citiesListStatus: ResponseStatus.READY
            };
        case PENDING_CITIES_ENUM:
            return {
                ...state,
                citiesListStatus: ResponseStatus.PENDING
            };
        case REMOVE_CITIES_ENUM:
            return {
                ...state,
                citiesList: [],
                citiesListStatus: ResponseStatus.FREE,
            };
        case SET_HEALTH_INCURANCE_ENUM:
            return {
                ...state,
                healthIncuranceList: action.data,
                healthIncuranceListStatus: ResponseStatus.READY
            };
        case PENDING_HEALTH_INCURANCE_ENUM:
            return {
                ...state,
                healthIncuranceListStatus: ResponseStatus.PENDING
            };
        case REMOVE_HEALTH_INCURANCE_ENUM:
            return {
                ...state,
                healthIncuranceList: [],
                healthIncuranceListStatus: ResponseStatus.FREE,
            };
        case SET_MULTIPLIERS_CATEGORIES_ENUM:
            return {
                ...state,
                multipliersCategoryList: action.data,
                multipliersCategoryStatus: ResponseStatus.READY
            };
        case PENDING_MULTIPLIERS_CATEGORIES_ENUM:
            return {
                ...state,
                multipliersCategoryStatus: ResponseStatus.PENDING
            };
        case REMOVE_MULTIPLIERS_CATEGORIES_ENUM:
            return {
                ...state,
                multipliersCategoryList: [],
                multipliersCategoryStatus: ResponseStatus.FREE,
            };
        case SET_MARKETING_ENUM:
            return {
                ...state,
                marketingsList: action.data,
                marketingsListStatus: ResponseStatus.READY
            };
        case PENDING_MARKETING_ENUM:
            return {
                ...state,
                marketingsListStatus: ResponseStatus.PENDING
            };
        case REMOVE_MARKETING_ENUM:
            return {
                ...state,
                marketingsList: [],
                marketingsListStatus: ResponseStatus.FREE,
            };
        case SET_REJECT_REASON_ENUM:
            action.data.unshift({Value: '', Label: 'Kein Grund'})
            return {
                ...state,
                rejectReasonsList: action.data,
                rejectReasonsListStatus: ResponseStatus.READY
            };
        case PENDING_REJECT_REASON_ENUM:
            return {
                ...state,
                rejectReasonsListStatus: ResponseStatus.PENDING
            };
        case REMOVE_REJECT_REASON_ENUM:
            return {
                ...state,
                rejectReasonsList: [],
                rejectReasonsListStatus: ResponseStatus.FREE,
            };
        case SET_CARE_BOX_REJECTION_REASON_ENUM:
            return {
                ...state,
                careBoxRejectionReasonsList: action.data,
                careBoxRejectionReasonsListStatus: ResponseStatus.READY
            };
        case PENDING_CARE_BOX_REJECTION_REASON_ENUM:
            return {
                ...state,
                careBoxRejectionReasonsListStatus: ResponseStatus.PENDING
            };
        case REMOVE_CARE_BOX_REJECTION_REASON_ENUM:
            return {
                ...state,
                careBoxRejectionReasonsList: [],
                careBoxRejectionReasonsListStatus: ResponseStatus.FREE,
            };
        case SET_SERVICE_TYPE_DE_ENUM:
            return {
                ...state,
                serviceTypeDeList: action.data,
                serviceTypeDeListStatus: ResponseStatus.READY
            };
        case PENDING_SERVICE_TYPE_DE_ENUM:
            return {
                ...state,
                serviceTypeDeListStatus: ResponseStatus.PENDING
            };
        case REMOVE_SERVICE_TYPE_DE_ENUM:
            return {
                ...state,
                serviceTypeDeList: [],
                serviceTypeDeListStatus: ResponseStatus.FREE,
            };
        case FINISH_BASIC_DATA_SAVE:
            return {
                ...state,
                finishSave: true
            };
        case LOADING_ENUMS:
            return {
                ...state,
                loadingEnums: !state.loadingEnums
            };
        case REMOVE_ALL_BASIC_DATA_SAVE:
            return {
                ...initialState
            };
        case SET_SITTERS_AVAILABILITY_ENUM:
            return {
                ...state,
                sittersAvailability: action.data,
                sittersAvailabilityStatus: ResponseStatus.READY
            }
        case PENDING_SITTERS_AVAILABILITY_ENUM:
            return {
                ...state,
                sittersAvailabilityStatus: ResponseStatus.PENDING
            }
        case REMOVE_SITTERS_AVAILABILITY_ENUM:
            return {
                ...state,
                sittersAvailability: [],
                sittersAvailabilityStatus: ResponseStatus.FREE
            }
        case SET_PROMEDICA_PRODUCTS_PRICES_ENUM:
            return {
                ...state,
                promedicaProductsPrices: action.data,
                promedicaProductsPricesStatus: ResponseStatus.READY
            }
        case PENDING_PROMEDICA_PRODUCTS_PRICES_ENUM:
            return {
                ...state,
                promedicaProductsPricesStatus: ResponseStatus.PENDING
            }
        case REMOVE_PROMEDICA_PRODUCTS_PRICES_ENUM:
            return {
                ...state,
                promedicaProductsPrices: [],
                promedicaProductsPricesStatus: ResponseStatus.FREE
            }
        case SET_DS_TEMPLATES_ENUM:
            return {
                ...state,
                dsTemplates: action.data,
                dsTemplatesStatus: ResponseStatus.READY
            }
        case PENDING_DS_TEMPLATES_ENUM:
            return {
                ...state,
                dsTemplatesStatus: ResponseStatus.PENDING
            }
        case REMOVE_DS_TEMPLATES_ENUM:
            return {
                ...state,
                dsTemplates: [],
                dsTemplatesStatus: ResponseStatus.FREE
            }
        //
        case SET_RATE_DATA_ENUM:
            return {
                ...state,
                rateData: action.data,
                rateDataStatus: ResponseStatus.READY
            }
        case PENDING_RATE_DATA_ENUM:
            return {
                ...state,
                rateDataStatus: ResponseStatus.PENDING
            }
        case REMOVE_RATE_DATA_ENUM:
            return {
                ...state,
                rateData: null,
                rateDataStatus: ResponseStatus.FREE
            }
        case SET_CARE_BOX_STATUS_ENUM:
            return {
                ...state,
                careBoxStatusList: action.data,
                careBoxStatusListStatus: ResponseStatus.READY
            }
        case PENDING_CARE_BOX_STATUS_ENUM:
            return {
                ...state,
                careBoxStatusListStatus: ResponseStatus.PENDING
            }
        case REMOVE_CARE_BOX_STATUS_ENUM:
            return {
                ...state,
                careBoxStatusList: [],
                careBoxStatusListStatus: ResponseStatus.FREE
            }
        case SET_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM:
            return {
                ...state,
                careBoxDeliveryToAddressList: action.data,
                careBoxDeliveryToAddressStatus: ResponseStatus.READY
            }
        case PENDING_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM:
            return {
                ...state,
                careBoxDeliveryToAddressStatus: ResponseStatus.PENDING
            }
        case REMOVE_CARE_BOX_DELIVERY_TO_ADDRESS_ENUM:
            return {
                ...state,
                careBoxDeliveryToAddressList: [],
                careBoxDeliveryToAddressStatus: ResponseStatus.FREE
            }
        case SET_CARE_BOX_INGREDIENTS_STATUS_ENUM:
            return {
                ...state,
                careBoxIngredientsStatusList: action.data,
                careBoxIngredientsStatusListStatus: ResponseStatus.READY
            }
        case PENDING_CARE_BOX_INGREDIENTS_STATUS_ENUM:
            return {
                ...state,
                careBoxIngredientsStatusListStatus: ResponseStatus.PENDING
            }
        case REMOVE_CARE_BOX_INGREDIENTS_STATUS_ENUM:
            return {
                ...state,
                careBoxIngredientsStatusList: [],
                careBoxIngredientsStatusListStatus: ResponseStatus.FREE
            }
        case SET_TRIP_TRACKING_STATUS_STATUS_ENUM:
            return {
                ...state,
                tripTrackingStatusList: action.data,
                tripTrackingStatusListStatus: ResponseStatus.READY
            }
        case PENDING_TRIP_TRACKING_STATUS_ENUM:
            return {
                ...state,
                tripTrackingStatusListStatus: ResponseStatus.PENDING
            }
        case REMOVE_TRIP_TRACKING_STATUS_ENUM:
            return {
                ...state,
                tripTrackingStatusList: [],
                tripTrackingStatusListStatus: ResponseStatus.FREE
            }
        case SET_GROUP_CAUSE_FP_ENUM:
            return {
                ...state,
                groupCauseFpList: action.data,
                groupCauseFpStatus: ResponseStatus.READY
            }
        case PENDING_GROUP_CAUSE_FP_ENUM:
            return {
                ...state,
                groupCauseFpStatus: ResponseStatus.PENDING
            }
        case REMOVE_GROUP_CAUSE_FP_ENUM:
            return {
                ...state,
                groupCauseFpList: [],
                groupCauseFpStatus: ResponseStatus.FREE
            }
        default:
            return state;
    }
};

export default reducer;