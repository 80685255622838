
import { SET_APP_LANGUAGE, REMOVE_APP_LANGUAGE } from "../actions/action-types";

const transList = {
  EN: require ('../../_translations/en.json'),
  DE: require ('../../_translations/de.json')
};

const initialState = {
  appLanguage: "DE",
  selectedLang: null,
  i18data: transList["DE"]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_LANGUAGE:
      if (transList.hasOwnProperty(action.appLanguage)){
        return {
          ...state,
          appLanguage: action.appLanguage,
          i18data: transList[action.appLanguage]
        };
      }else{
        return {
          ...state,
          appLanguage: 'EN',
          i18data: transList['EN']
        };
      }
    case REMOVE_APP_LANGUAGE:
        return {
          selectedLang: null,
          appLanguage: 'EN',
          i18data: transList['EN']
        };
    default:
      return state;
  }
};

export default reducer;