import * as Relationship from "../_forms/BBForm/values/relationship";
import * as IsContactPersonDataSameAs from "../_forms/BBForm/values/isContactPersonDataSameAs";
import * as SkillRange from "../_forms/BBForm/values/skillRange";
import { IconsMap, ReqHelper} from "../_helpers/index";
import {Tiles} from '../_constance/classes/dndPriorityOffer/TilesIds';
import PriorityOffer from '../_constance/classes/dndPriorityOffer/PriorityOffer';
import * as LocationRange from "../_forms/BBForm/values/locationRange";
import * as HouseType from "../_forms/BBForm/values/houseType";
import * as MarketingID from '../_forms/BBForm/values/marketingId';

export function RodoHtmlHelper(form, translateKey ) {
    return `
        <div class="rodo-pdf-wrapper">
            ${generateFirstPage(form, translateKey)}
            ${generateSecondPage(form, translateKey)}
        </div>`;
}

export function FormHtmlHelper(form, translateKey ) {
    const isContactPersonDataSameAsField = form.getFieldByKey("is_contact_person_data_same_as");
    const isDegreeRelationShipWithPatientCarerField = form.getFieldByKey("is_degree_relationship_with_patient_carer");
    return `
        <div class="rodo-pdf-wrapper">
            ${generateThirdEqualToFourthPage(form, translateKey)}
            ${isContactPersonDataSameAsField.value !== IsContactPersonDataSameAs.YES ?
                generateFourhtEqualToFifthPage(form, translateKey)
                :
                ''
            }
            ${isDegreeRelationShipWithPatientCarerField.value !== Relationship.TYPE_8?
                generateFifthEqualToSixPage(form, translateKey)
                :
                ''
            }
            ${generateSixEqualToSevenPage(form, translateKey)}
            ${generateSevenEqualToEightPage(form, translateKey)}
            ${generateEightEqualToNinePage(form, translateKey)}
            ${generateNineEqualToTenPage(form, translateKey)}
            ${generateTenEqualToElevenPage(form, translateKey)}
            ${generateElevenEqualToTwelvePage(form, translateKey)}
            ${generateTwelveEqualToThirteenPage(form, translateKey)}
            ${generateThirdteenEqualToFoutheenPage(form, translateKey)}
            ${generateFoutheenEqualToFifteenPage(form, translateKey)}
            ${generate16Page(form, translateKey)}
            ${generate17Page(form, translateKey)}
            ${generate18Page(form, translateKey)}
            ${generate19Page(form, translateKey)}
            ${generate20Page(form, translateKey)}
            ${generate21Page(form, translateKey)}
            ${generate22Page(form, translateKey)}
            ${generate23Page(form, translateKey)}
            ${generate24Page(form, translateKey)}
            ${generate25Page(form, translateKey)}
            ${generate26Page(form, translateKey)}
            ${generate27Page(form, translateKey)}
            ${generate29Page(form, translateKey)}
        </div>`;
    /*return `
        <div class="rodo-pdf-wrapper"> 
            ${generateFoutheenEqualToFifteenPage(form, translateKey)}
        </div>`;*/

}
 
function generateFirstPage(form, translateKey){
    const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
    let isRodoFirstChoice = Relationship.TYPE_0;
    if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8 ) {
        isRodoFirstChoice = Relationship.TYPE_8
    }

    const isZgDe1 = form.getFieldByKey("is_zg_de_1").value;
    const changeCompanyTimestamp = '1614297600';
    return `
        <div class="form-section-main">
            <div class="new-section-content-wrapper container form_serializer">
                <table>
                    <tr>
                        <th width="100%">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('form-rodo1-text-akceptieren')}
                            </div>
                        </th>
                    </tr>
                </table>

                <table style="width:700px; margin:0 auto;">
                    <tr>
                        <th width="50%">
                            <div class="rodo-content-wrapper-item ${isRodoFirstChoice===Relationship.TYPE_8?'active':''}">
                                <div class="icon">
                                    <img class="pflegegrad-icon" src="${isRodoFirstChoice===Relationship.TYPE_8?IconsMap.other.rodo_human_white:IconsMap.other.rodo_human_gray}" alt="Icon"/>
                                </div>
                                <div class="text">
                                    ${translateKey('form-rodo1-text-option1')}
                                </div>
                            </div>
                        </th>
                        <th width="50%">
                            <div class="rodo-content-wrapper-item ${isRodoFirstChoice===Relationship.TYPE_0?'active':''}">
                                <div class="icon">
                                    <img class="pflegegrad-icon" src="${isRodoFirstChoice===Relationship.TYPE_0?IconsMap.other.rodo_human_chair_white:IconsMap.other.rodo_human_chair_gray}" alt="Icon"/>
                                </div>
                                <div class="text">
                                    ${translateKey('form-rodo1-text-option2')}
                                </div>
                            </div>
                        </th>
                    </tr>
                </table>
                <table style="margin:0 auto;">
                    <tr>
                        <div class="columnfull rodo-description">
                            <div class="rodo-section">
                                <div>${translateKey('form-rodo1-text-paragraph1')}</div>
                                ${ isZgDe1 < changeCompanyTimestamp ? `<div>- ${translateKey('form-rodo1-text-paragraph2')}</div>` : `<div>- ${translateKey('form-rodo1-text-paragraph2-new-company')}</div>` }
                                <div>- ${translateKey('form-rodo1-text-paragraph3')}</div>
                            </div>
                            <ul class="rodo-itme-list">
                                <li>
                                    ${translateKey('form-rodo1-text-paragraph4')}
                                    <a href="${translateKey('form-rodo1-text-page')}" target="_top">${' ' + translateKey('form-rodo1-text-page')}</a>
                                </li>
                                <li>
                                    ${translateKey('form-rodo1-text-paragraph5')}
                                    <a href="mailto: ${translateKey('form-rodo1-text-email')}" target="_top">${' ' + translateKey('form-rodo1-text-email')}</a>
                                </li>
                                <li>${translateKey('form-rodo1-text-paragraph6')}</li>
                                <li>${translateKey('form-rodo1-text-paragraph7')}</li>
                                <li>
                                    ${translateKey('form-rodo1-text-paragraph8')}
                                    <ul>
                                        <li>${translateKey('form-rodo1-text-paragraph8-option1')}</li>
                                        <li>${translateKey('form-rodo1-text-paragraph8-option2')}</li>
                                        ${ isZgDe1 < changeCompanyTimestamp ? `<li>${translateKey('form-rodo1-text-paragraph8-option3')}</li>` : `<li>${translateKey('form-rodo1-text-paragraph8-option3-new-company')}</li>` }
                                    </ul>
                                </li>
                                <li>${translateKey('form-rodo1-text-paragraph9')}</li>
                                <li>
                                    ${translateKey('form-rodo1-text-paragraph10')}
                                    <ul>
                                        ${ isZgDe1 < changeCompanyTimestamp ? `<li>${translateKey('form-rodo1-text-paragraph10-option1')}</li>` : `<li>${translateKey('form-rodo1-text-paragraph10-option1-new-company')}</li>` }
                                        <li>${translateKey('form-rodo1-text-paragraph10-option2')}</li>
                                    </ul>
                                </li>
                                <li>
                                    ${translateKey('form-rodo1-text-paragraph11')}
                                    <ul>
                                        <li>${translateKey('form-rodo1-text-paragraph11-option1')}</li>
                                        <li>
                                            ${translateKey('form-rodo1-text-paragraph11-option2-part1')}
                                            <a href="mailto: ${translateKey('form-rodo1-text-email')}" target="_top">${' ' + translateKey('form-rodo1-text-email')} </a>
                                            ${translateKey('form-rodo1-text-paragraph11-option2-part2')}
                                            <a href="tel: ${translateKey('form-rodo1-text-phone')}" target="_top">${' ' + translateKey('form-rodo1-text-phone')} </a>
                                            ${translateKey('form-rodo1-text-paragraph11-option2-part3')}
                                        </li>
                                        <li>${translateKey('form-rodo1-text-paragraph11-option3')}</li>
                                    </ul>
                                </li>
                                <li>${translateKey('form-rodo1-text-paragraph12')} </li>
                            </ul>
                        </div>
                    </tr>
                </table>
            </div>
        </div>
    `;
}

function generateSecondPage(form, translateKey){
    const isDegreeRelationship = 'is_degree_relationship_with_patient_carer';
    const isDegreeRelationShipWithPatientCarer = form.getFieldByKey(isDegreeRelationship);
    let showLeft = false;
    if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8 ) {
        showLeft = true;
    }
    //RODO1
    const einwilligungZurUnterbeitung = form.getFieldByKey("einwilligung_zur_unterbeitung");
    //RODO2
    const isZgDe2 = form.getFieldByKey("is_zg_de_2");
    const isZgDe3 = form.getFieldByKey("is_zg_de_2");
    const isZgDe4 = form.getFieldByKey("is_zg_de_4");
    const isZgDe5 = form.getFieldByKey("is_zg_de_5");
    const isZgDe6 = form.getFieldByKey("is_zg_de_6");
    const isZgDe7 = form.getFieldByKey("is_zg_de_7");
    const isZgDe8 = form.getFieldByKey("is_zg_de_8");
    const isZgDe1 = form.getFieldByKey("is_zg_de_8").value;
    const isZgDe2Value = form.getFieldByKey("is_zg_de_2").value;
    const isZgDe3Value = form.getFieldByKey("is_zg_de_3").value;
    const changeCompanyTimestamp = '1614297600';

    let einwilligungInDieVerarbeitung = false;
    if(isZgDe2.value !==null && isZgDe2.value!==0){einwilligungInDieVerarbeitung = true;}

    let dieWerwendentWerden = false;
    if(isZgDe3.value !==null && isZgDe3.value!==0){dieWerwendentWerden = true;}

    let marketingEinwilligungenPostYes = false;
    if(isZgDe4.value !==null && isZgDe4.value!==0){marketingEinwilligungenPostYes = true;}

    let marketingEinwilligungenTelephoneYes = false;
    if(isZgDe5.value !==null && isZgDe5.value!==0){marketingEinwilligungenTelephoneYes = true;}

    let einwilligungenDieDatenTelephoneYes = false;
    if(isZgDe6.value !==null && isZgDe6.value!==0){einwilligungenDieDatenTelephoneYes = true;}

    let einwilligungenDieDatenPostYes = false;
    if(isZgDe7.value !==null && isZgDe7.value!==0){einwilligungenDieDatenPostYes = true;}

    let einwilligungenDieDatenEmailYes = false;
    if(isZgDe8.value !==null && isZgDe8.value!==0){einwilligungenDieDatenEmailYes = true;}

    const patientName = form.getFieldByKey('is_first_name_and_last_name_of_patient').value;

    return `
        <div class="">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('form-rodo1-text-akceptieren')}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull rodo-description">
                            ${showLeft?
                                `<div class="rodo-section">
                                    <div>
                                        ${translateKey('form-rodo2-text-paragraph1-part1')}
                                        <a href="mailto: ${translateKey('form-rodo1-text-email')}" target="_top">${' ' + translateKey('form-rodo1-text-email')} </a>
                                        ${translateKey('form-rodo2-text-paragraph1-part2')}
                                        <a href="tel: ${translateKey('form-rodo1-text-phone')}"" target="_top">${' ' + translateKey('form-rodo1-text-phone')} </a>
                                        ${translateKey('form-rodo2-text-paragraph1-part3')}
                                    </div>
                                </div>
                                <div class="rodo-section">
                                    <div class="title">
                                        ${translateKey('form-rodo2-text-paragraph2').toUpperCase()}
                                    </div>
                                    <div class="row justify-content-md-center">
                                        ${ isZgDe1 < changeCompanyTimestamp ? 
                                            `${getMeCheckBox('col-sm-12 col-lg-12', einwilligungZurUnterbeitung.value, translateKey("form-rodo2-text-paragraph3"), 'einwilligung_zur_unterbeitung')}` 
                                            : `${getMeCheckBox('col-sm-12 col-lg-12', einwilligungZurUnterbeitung.value, translateKey("form-rodo2-text-paragraph3-new-company"), 'einwilligung_zur_unterbeitung')}` }
                                        
                                    </div>
                                </div>`

                                :

                                `<div class="rodo-section">
                                    <div>
                                        ${translateKey('form-rodo3-text-paragraph1-part1')}
                                        <a href="mailto: ${translateKey('form-rodo1-text-email')}" target="_top">${' ' + translateKey('form-rodo1-text-email')} </a>
                                        ${translateKey('form-rodo3-text-paragraph1-part2')}
                                        <a href="tel: ${translateKey('form-rodo1-text-phone')}" target="_top">${' ' + translateKey('form-rodo1-text-phone')} </a>
                                        ${translateKey('form-rodo3-text-paragraph1-part3')}
                                    </div>
                                </div>

                                <div class="rodo-section">
                                    <div class="title">
                                        ${translateKey('form-rodo3-text-paragraph2').toUpperCase()}
                                    </div>
                                    <div class="row justify-content-md-center">
                                        ${ isZgDe2Value < changeCompanyTimestamp ? `${getMeCheckBox('col-sm-12 col-lg-12', einwilligungInDieVerarbeitung, translateKey("form-rodo3-text-paragraph7"), 'einwilligung_in_die_verarbeitung')}` 
                                            : `${getMeCheckBox('col-sm-12 col-lg-12', einwilligungInDieVerarbeitung, translateKey("form-rodo3-text-paragraph7-new-company"), 'einwilligung_in_die_verarbeitung')}` 
                                        }
                                    </div>
                                    <table style="width:100%;text-align:center;">
                                        <tr>
                                            <td>
                                                <input type="text" class='input-basic-class' value='${patientName}'/>
                                            </td>
                                        </tr>
                                    </table>
                                    <div class="rodo-subsection-text-wrapper with-padd">
                                    ${ isZgDe2Value < changeCompanyTimestamp ? `${translateKey('form-rodo3-text-paragraph4')}` : `${translateKey('form-rodo3-text-paragraph4-new-company')}` }
                                    </div>
                                    <div class="rodo-subsection-text-wrapper">
                                        ${translateKey('form-rodo3-text-paragraph5')}
                                    </div>
                                </div>

                                <div class="rodo-section">
                                    <div class="title">
                                        ${translateKey('form-rodo3-text-paragraph6').toUpperCase()}
                                    </div>
                                    <div class="row justify-content-md-center">
                                        ${  isZgDe3Value < changeCompanyTimestamp ? `${getMeCheckBox('col-sm-12 col-lg-12', dieWerwendentWerden, translateKey("form-rodo3-text-paragraph7"), 'die_verwendent_werden')}` 
                                            : `${getMeCheckBox('col-sm-12 col-lg-12', dieWerwendentWerden, translateKey("form-rodo3-text-paragraph7-new-company"), 'die_verwendent_werden')}` 
                                        }
                                    </div>
                                </div>`
                            }





                            <div class="rodo-section">
                                <div class="title">
                                    ${translateKey('form-rodo2-text-marketing-einwilligungen').toUpperCase()}
                                </div>
                                <div class="rodo-options-wrapper-subtitle">
                                    ${translateKey('form-rodo2-text-paragraph5')}
                                </div>

                                <table style="width:700px;margin:0 auto;">
                                    <tr>
                                        <th width="30%">
                                            <span class="rodo-options-span-padding">${translateKey('form-rodo2-text-title-per-elektro-email')}</span>
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', marketingEinwilligungenPostYes, translateKey("form-rodo2-text-paragraph5-option-yes"), 'marketing_einwilligungen_post_no')}
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', !marketingEinwilligungenPostYes, translateKey("form-rodo2-text-paragraph5-option-no"), 'marketing_einwilligungen_post_no')}
                                        </th>
                                    </tr>
                                </table>
                                
                                <table style="width:700px;margin:0 auto;">
                                    <tr>
                                        <th width="30%">
                                            <span class="rodo-options-span-padding">${translateKey('form-rodo2-text-title-per-phone')}</span>
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', marketingEinwilligungenTelephoneYes, translateKey("form-rodo2-text-paragraph5-option-yes"), 'marketing_einwilligungen_telephone_yes')}
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', !marketingEinwilligungenTelephoneYes, translateKey("form-rodo2-text-paragraph5-option-no"), 'marketing_einwilligungen_telephone_no')}
                                        </th>
                                    </tr>
                                </table>
                            </div>

                            <div class="rodo-section">
                                <div class="title">
                                    ${translateKey('form-rodo2-text-einwilligung').toUpperCase()}
                                </div>
                                <div class="rodo-options-wrapper-subtitle">
                                    ${translateKey('form-rodo2-text-paragraph7')}
                                </div>


                                <table style="width:700px;margin:0 auto;">
                                    <tr>
                                        <th width="30%">
                                            <span class="rodo-options-span-padding">${translateKey('form-rodo2-text-title-per-email')}</span>
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', einwilligungenDieDatenEmailYes, translateKey("form-rodo2-text-paragraph7-option-yes"), 'einwilligungen_die_daten_email_no')}
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', !einwilligungenDieDatenEmailYes, translateKey("form-rodo2-text-paragraph7-option-no"), 'einwilligungen_die_daten_email_no')}
                                        </th>
                                    </tr>
                                </table>

                                <table style="width:700px;margin:0 auto;">
                                    <tr>
                                        <th width="30%">
                                            <span class="rodo-options-span-padding">${translateKey('form-rodo2-text-title-per-phone')}</span>
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', einwilligungenDieDatenTelephoneYes, translateKey("form-rodo2-text-paragraph7-option-yes"), 'einwilligungen_die_daten_telephone_yes')}
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', !einwilligungenDieDatenTelephoneYes, translateKey("form-rodo2-text-paragraph7-option-no"), 'einwilligungen_die_daten_telephone_no')}
                                        </th>
                                    </tr>
                                </table>

                                <table style="width:700px; margin:0 auto;">
                                    <tr>
                                        <th width="30%">
                                            <span class="rodo-options-span-padding">${translateKey('form-rodo2-text-title-per-post')}</span>
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', einwilligungenDieDatenPostYes, translateKey("form-rodo2-text-paragraph7-option-yes"), 'einwilligungen_die_daten_post_yes')}
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', !einwilligungenDieDatenPostYes, translateKey("form-rodo2-text-paragraph7-option-no"), 'einwilligungen_die_daten_post_no')}
                                        </th>
                                    </tr>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generateThirdEqualToFourthPage(form, translateKey){
    const isBirthday = form.getFieldByKey('is_birthday');
    const isDegreeRelationshipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
    const isRegisteredZipPostalCode = form.getFieldByKey('is_registered_zip_postal_code');
    const isLastName = form.getFieldByKey('lastname');

    return `
        <div class="form-section-main">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_client_data_p_0')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table style="width:100%;margin:0;padding:0;">
                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("last_name"), isLastName.value)}
                    </td>
                    <td style="width:50%;" colspan="2">
                        ${getMeInput(translateKey("first_name"), form.getFieldByKey('firstname').value)}
                    </td>
                </tr>

                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("street"), form.getFieldByKey('is_registered_street').value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("form-text-section-0-2"), form.getFieldByKey('is_registered_street_number').value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("apartment_number"), form.getFieldByKey('is_registered_house_number').value)}
                    </td>
                </tr>

                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("city"), form.getFieldByKey('is_registered_city').value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("postal_code"), isRegisteredZipPostalCode.value)}
                    </td>
                </tr>

                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("email"), form.getFieldByKey('is_email').value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("new_phone_label"), form.getFieldByKey('is_home_phone').value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("new_cellphone_label"), form.getFieldByKey('is_mobile_phone').value)}
                    </td>
                </tr>

                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("form-text-section-0-1"), prepareMeRelationText(isDegreeRelationshipWithPatientCarer.value, translateKey))}
                    </td>
                    <th style="padding-top:10px;padding-left:15px;width:50%;" colspan="2">
                        ${getMeSwitch('form-text-contact-person-same-data', form.getFieldByKey('is_contact_person_data_same_as'), translateKey)}
                    </th>
                </tr>

                ${isDegreeRelationshipWithPatientCarer.value === '717700008' &&
                    `<tr>
                        <td style="width:50%;">
                            ${getMeInput(translateKey("born"), convertDate(isBirthday.value*1000))}
                        </td>
                        <td style="width:50%;" colspan="2">
                            ${getMeInput(translateKey("height_with_unit"), ReqHelper.simpleValueFormatter(form.getFieldByKey('is_height').value))}
                        </td>
                    </tr>

                    <tr>
                        <td style="width:50%;">
                            ${getMeInput(translateKey("weight_with_unit"), ReqHelper.simpleValueFormatter(form.getFieldByKey('is_weight').value))}
                        </td>
                        <td style="width:50%;" colspan="2">
                            ${getMeInput(translateKey("form-text-home-doctor"), form.getFieldByKey('is_home_doctor').value)}
                        </td>
                    </tr>
                    `
                }
            </table>
        </div>
    `;
}

function generateFourhtEqualToFifthPage(form, translateKey){
    const is_contact_person_lastnameField  = form.getFieldByKey('is_contact_person_lastname');
    const is_contact_person_firstnameField  = form.getFieldByKey('is_contact_person_firstname');
    const is_contact_person_emailField  = form.getFieldByKey('is_contact_person_email');
    const is_contact_person_mobile_phoneField  = form.getFieldByKey('is_contact_person_mobile_phone');
    const is_contact_person_home_phoneField  = form.getFieldByKey('is_contact_person_home_phone');
    const is_relationship_with_patient_opField = form.getFieldByKey('is_relationship_with_patient_op');

    return `
        <div class="form-section-main">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_contact_data_p_1')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table style="width:100%;margin:0;padding:0;">
                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("last_name"), is_contact_person_lastnameField.value)}
                    </td>
                    <td style="width:50%;" colspan="2">
                        ${getMeInput(translateKey("first_name"), is_contact_person_firstnameField.value)}
                    </td>
                </tr>

                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("email"), is_contact_person_emailField.value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("new_phone_label"), is_contact_person_home_phoneField.value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("new_cellphone_label"), is_contact_person_mobile_phoneField.value)}
                    </td>
                </tr>

                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("email"), prepareMeRelationText(is_relationship_with_patient_opField.value, translateKey))}
                    </td>
                </tr>

            </table>
        </div>
    `;
}

function generateFifthEqualToSixPage(form, translateKey){
    const is_service_address_streetField = form.getFieldByKey('is_service_address_street');
    const is_service_address_street_numberField = form.getFieldByKey('is_service_address_street_number');
    const is_service_address_house_numberField = form.getFieldByKey('is_service_address_house_number');
    const is_service_address_cityField = form.getFieldByKey('is_service_address_city');
    const is_service_address_postal_codeField = form.getFieldByKey('is_service_address_postal_code');
    const is_patient_cellphoneField = form.getFieldByKey('is_patient_cellphone');
    const is_patient_phoneField = form.getFieldByKey('is_patient_phone');
    const isBirthday = form.getFieldByKey('is_birthday');
    const isHeight = form.getFieldByKey('is_height');
    const isWeight = form.getFieldByKey('is_weight');
    const isHomeDoctor = form.getFieldByKey('is_home_doctor');
    const pateintName = form.getFieldByKey('is_first_name_and_last_name_of_patient');

    let firstName = '';
    let lastName = '';
    if(pateintName.value) {
        const splitName = pateintName.value.split(' ');
        if (splitName.length > 2) {
            firstName = splitName.slice(1).join(' ');
            lastName = splitName[0];
        } else {
            firstName = splitName[1];
            lastName = splitName[0];
        }
    }

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_lead_data_p_2')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table style="width:100%;margin:0;padding:0;">
                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("last_name"), lastName)}
                        
                    </td>
                    <td style="width:50%;" colspan="2">
                        ${getMeInput(translateKey("first_name"), firstName)}
                    </td>
                </tr>

                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("street"), is_service_address_streetField.value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("form-text-section-0-2"), is_service_address_street_numberField.value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("apartment_number"), is_service_address_house_numberField.value)}
                    </td>
                </tr>

                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("city"), is_service_address_cityField.value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("postal_code"), is_service_address_postal_codeField.value)}
                    </td>
                </tr>
                <tr>
                    <td style="width:25%;">
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("new_phone_label"), is_patient_phoneField.value)}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("new_cellphone_label"), is_patient_cellphoneField.value)}
                    </td>
                </tr>

                <tr>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("born"), convertDate(isBirthday.value*1000))}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("height_with_unit"), ReqHelper.simpleValueFormatter(isHeight.value))}
                    </td>
                    <td style="width:25%;">
                        ${getMeInput(translateKey("weight_with_unit"), ReqHelper.simpleValueFormatter(isWeight.value))}
                    </td>
                </tr>

                <tr>
                    <td style="width:50%;">
                        ${getMeInput(translateKey("form-text-home-doctor"), isHomeDoctor.value)}
                    </td>
                </tr>

            </table>
        </div>
    `;
}

function generateSixEqualToSevenPage(form, translateKey){
    const values = {
        careLevel0: '717700000',
        careLevel1: '717700001',
        careLevel2: '717700002',
        careLevel3: '717700003',
        careLevel4: '717700004',
    }
    const isAppliedPflegegradField = form.getFieldByKey('is_applied_pflegegrad');
    const isAppliedLast6MonthsField = form.getFieldByKey('is_applied_last_6_months');
    const isPflegegrad = form.getFieldByKey("is_pflegegrad");
    const is_insurance_typeField = form.getFieldByKey("is_insurance_type");

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_pflegegrad_data_p_3')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table style="width:100%;margin:0;padding:0;">
                <tr>
                    <th width="16.6%" colspan="6">
                        ${translateKey('form_text_aktueller_pflegegrad').toUpperCase()}
                    </th>
                </tr>
                <tr>
                    <td width="16.6%">
                        ${getMeCheckBox('', isPflegegrad.value === '', translateKey("form-text-kein-pflegegrad"), '')}
                    </td>
                    <td width="16.6%">
                        ${getMeCheckBox('', isPflegegrad.value === values.careLevel0, translateKey("from-text-pflegegrad1"), '')}
                    </td>
                    <td width="16.6%">
                        ${getMeCheckBox('', isPflegegrad.value === values.careLevel1, translateKey("from-text-pflegegrad2"), '')}
                    </td>
                    <td width="16.6%">
                        ${getMeCheckBox('', isPflegegrad.value === values.careLevel2, translateKey("from-text-pflegegrad3"), '')}
                    </td>
                    <td width="16.6%">
                        ${getMeCheckBox('', isPflegegrad.value === values.careLevel3, translateKey("from-text-pflegegrad4"), '')}
                    </td>
                    <td width="16.6%">
                        ${getMeCheckBox('', isPflegegrad.value === values.careLevel4, translateKey("from-text-pflegegrad5"), '')}
                    </td>
                </tr>

                <tr>
                    <th colspan="6">
                        ${getMeSwitch('form_text_beantragt_in_den_letzten', isAppliedLast6MonthsField, translateKey)}
                    </th>
                </tr>

                ${isAppliedLast6MonthsField.value === '717700000'?
                    `<tr>
                        <th colspan="6">
                            ${translateKey("form-text-beantragt")}
                        </th>
                    </tr>
                    <tr>
                        <td>
                            ${getMeCheckBox('', isAppliedPflegegradField.value === '717700008', translateKey("form_text_pflegegrad_beantragt"), '')}
                        </td>
                        <td>
                            ${getMeCheckBox('', isAppliedPflegegradField.value === values.careLevel0, translateKey("from-text-pflegegrad1"), '')}
                        </td>
                        <td>
                            ${getMeCheckBox('', isAppliedPflegegradField.value === values.careLevel1, translateKey("from-text-pflegegrad2"), '')}
                        </td>
                        <td>
                            ${getMeCheckBox('', isAppliedPflegegradField.value === values.careLevel2, translateKey("from-text-pflegegrad3"), '')}
                        </td>
                        <td>
                            ${getMeCheckBox('', isAppliedPflegegradField.value === values.careLevel3, translateKey("from-text-pflegegrad4"), '')}
                        </td>
                        <td>
                            ${getMeCheckBox('', isAppliedPflegegradField.value === values.careLevel4, translateKey("from-text-pflegegrad5"), '')}
                        </td>
                    </tr>`
                    :
                    ''
                }

                <tr>
                    <th colspan="6">
                        ${getMeSwitch('form-text-krankenkasse', is_insurance_typeField, translateKey, undefined, {yes: 'form-text-gesetzlich', no: 'form-text-privat'})}
                    </th>
                </tr>
            </table>
        </div>
    `;
}

function generateSevenEqualToEightPage(form, translateKey){
        //first row fields
        const isAllergiesList = form.getFieldByKey('is_allergies_list')
        const isDifficultyIWDBA = form.getFieldByKey('is_difficulty_in_walking_determined_by_age')
        const isAlzheimersDTL = form.getFieldByKey('is_alzheimers_disease_the_list')
        const isAsthmaList = form.getFieldByKey('is_asthma_list')
        const isChronicDL = form.getFieldByKey('is_chronic_diarrhea_list')
        const isBedsoresL = form.getFieldByKey('is_bedsores_list')
        //second row fields
        const isInitialSODL = form.getFieldByKey('is_initial_stages_of_dementia_list')
        const isDementiaL = form.getFieldByKey('is_dementia_list')
        const isAtopicD = form.getFieldByKey('is_atopic_dermatitis')
        const isDiabets = form.getFieldByKey('is_diabetes')
        const isDiabetsML = form.getFieldByKey('is_diabetes_mellitus_list')
        const isMyocardialIL = form.getFieldByKey('is_myocardial_infarction_list')
        //third row fields
        const isHeartFL = form.getFieldByKey('is_heart_failure_list')
        const isArrhythmiasL = form.getFieldByKey('is_arrhythmias_list')
        const isHyperL = form.getFieldByKey('is_hypertension_list')
        const isIncontinenceL = form.getFieldByKey('is_incontinence_list')
        const isMultipleCTL = form.getFieldByKey('is_multiple_sclerosis_the_list')
        const isOsteoporosisL = form.getFieldByKey('is_osteoporosis_list')
        //fourth row fields
        const isParkinsonDL = form.getFieldByKey('is_parkinsons_disease_list')
        const isRheumaticDL = form.getFieldByKey('is_rheumatic_disease_list')
        const isLSPL = form.getFieldByKey('is_left_sided_paresis_list')
        const isRSPL = form.getFieldByKey('is_right_sided_paresis_list')
        const isStomaL = form.getFieldByKey('is_stoma_list')
        const isCancerL = form.getFieldByKey('is_cancer_list')
        //REST
        const isOtherDD= form.getFieldByKey('is_other_description_de');
        const isPatientSmokes = form.getFieldByKey('is_patient_smokes');

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_ills_data_p_4')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table style="width:100%;margin:0;padding:0;">
                <tr>
                    <th style="padding-top:10px;padding-left:15px;width:100%;">
                        ${translateKey("form-text-choroben")}
                    </th>
                </tr>
            </table>
            <table style="width:100%;margin:0;padding:0;">

                <tr>
                    <td width="25%" style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isAllergiesList.value, translateKey("allergies"), 'is_allergies_list')}
                    </td>
                    <td width="25%" style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isDifficultyIWDBA.value, translateKey("form-text-gehschwache"), 'is_difficulty_in_walking_determined_by_age')}
                    </td>
                    <td width="25%" style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isAlzheimersDTL.value, translateKey('form-text-alzheimer'), 'is_alzheimers_disease_the_list')}
                    </td>
                    <td width="25%" style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isAsthmaList.value, translateKey('form-text-asthma'), 'is_asthma_list')}
                    </td>
                <tr>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isChronicDL.value, translateKey('form-text-chronische'), 'is_chronic_diarrhea_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isBedsoresL.value, translateKey('form-text-dekubitus'), 'is_bedsores_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isInitialSODL.value, translateKey("form-text-demenz-a"), 'is_initial_stages_of_dementia_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isDementiaL.value, translateKey("form-text-demenz-f"), 'is_dementia_list')}
                    </td>
                </tr>
                <tr>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isAtopicD.value, translateKey("form-text-dermatitis"), 'is_atopic_dermatitis')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isDiabets.value, translateKey("form-text-diabetes"), 'is_diabetes')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isDiabetsML.value, translateKey("form-text-diabetes-insulin"), 'is_diabetes_mellitus_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isMyocardialIL.value, translateKey("form-text-herzinfarkt"), 'is_myocardial_infarction_list')}
                    </td>
                <tr>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isHeartFL.value, translateKey("form-text-herzinsuffizienz"), 'is_heart_failure_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isArrhythmiasL.value, translateKey("form-text-herzrhythmusstörung"), 'is_arrhythmias_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isHyperL.value, translateKey("form-text-hypertonie"), 'is_hypertension_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isIncontinenceL.value, translateKey("bb_defecation_data_p_9"), 'is_incontinence_list')}
                    </td>
                </tr>
                <tr>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isMultipleCTL.value, translateKey("form-text-multiple-sklerose"), 'is_multiple_sclerosis_the_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isOsteoporosisL.value, translateKey("form-text-osteoporose"), 'is_osteoporosis_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isParkinsonDL.value, translateKey("form-text-parkinson"), 'is_parkinsons_disease_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isRheumaticDL.value, translateKey("form-text-rheuma"), 'is_rheumatic_disease_list')}
                    </td>
                </tr>
                <tr>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isLSPL.value, translateKey("form-text-schlaganfall-li"), 'is_left_sided_paresis_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isRSPL.value, translateKey("form-text-schlaganfall-re"), 'is_right_sided_paresis_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isStomaL.value, translateKey("form-text-stoma"), 'is_stoma_list')}
                    </td>
                    <td style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isCancerL.value, translateKey("form-text-tumor"), 'is_cancer_list')}
                    </td>
                </tr>
                <tr>
                    <td colspan="4" style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isPatientSmokes.value, translateKey("form-text-betreuende"), 'is_patient_smokes')}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeInput(translateKey("form-text-sonstige"), isOtherDD.value)}
                    </td>
                </tr>
            </table>
        </div>
    `;
}

function generateEightEqualToNinePage(form, translateKey){
    const valuesList={
        '717700002': translateKey("form-text-eingeschrankt"),
        '717700001': translateKey("form-text-teilweise"),
        '717700000': translateKey("form-text-uneingeschrankt"),
    }
    const isCommunicationHearing = form.getFieldByKey('is_communication_hearing');
    const isCommunicationVision = form.getFieldByKey('is_communication_vision');
    const isCommunicationSpeech = form.getFieldByKey('is_communication_speech');
    const horen = IconsMap.other.kommunikation_ear;
    const sehen = IconsMap.other.kommunikation_eye;
    const sprechen = IconsMap.other.kommunikation_hearing;
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_comnunication_data_p_5')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <table style="width:100%;margin:0;padding:0;">
                <tr>
                    <th style="width:33%;">
                        <div class="icon" style="padding-top: 10px;padding-bottom: 20px; height: 100px;">
                            <img class="pflegegrad-icon" src="${horen}" alt="Icon"/>
                        </div>
                        ${getMeRadio('form-text-horen', isCommunicationHearing, valuesList, translateKey)}
                    </th>
                    <th style="width:33%;">
                        <div class="icon" style="padding-top: 10px;padding-bottom: 20px; height: 100px;">
                            <img class="pflegegrad-icon" src="${sehen}" alt="Icon"/>
                        </div>
                        ${getMeRadio('form-text-sehen', isCommunicationVision, valuesList, translateKey)}
                    </th>
                    <th style="width:33%;">
                        <div class="icon" style="padding-top: 10px;padding-bottom: 20px; height: 100px;">
                            <img class="pflegegrad-icon" src="${sprechen}" alt="Icon"/>
                        </div>
                        ${getMeRadio('form-text-sprechen', isCommunicationSpeech, valuesList, translateKey)}
                    </th>
                </tr>
            </table>
        </div>
    `;
}

function generateNineEqualToTenPage(form, translateKey){
    const valuesList={
        '717700002': translateKey("form-text-eingeschrankt"),
        '717700001': translateKey("form-text-teilweise"),
        '717700000': translateKey("form-text-uneingeschrankt"),
    }
    const isOrientationTemporal = form.getFieldByKey("is_orientation_temporal");
    const isOrientationLocational = form.getFieldByKey("is_orientation_locational");
    const isOrientationPersonal = form.getFieldByKey("is_orientation_personal");
    const zeitlich = IconsMap.other.zeitlich;
    const ortlich = IconsMap.other.ortlich;
    const personlich = IconsMap.other.personlich;
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_orientation_data_p_6')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <table style="width:100%;margin:0;padding:0;">
                <tr>
                    <th style="width:33%;">
                        <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                            <img style="width: 100px;" class="pflegegrad-icon" src="${zeitlich}" alt="Icon"/>
                        </div>
                        ${getMeRadio('form-text-zeitlich', isOrientationTemporal, valuesList, translateKey)}
                    </th>
                    <th style="width:33%;">
                        <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                            <img style="width: 100px;" class="pflegegrad-icon" src="${ortlich}" alt="Icon"/>
                        </div>
                        ${getMeRadio('form-text-ortlich', isOrientationLocational, valuesList, translateKey)}
                    </th>
                    <th style="width:33%;">
                        <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                            <img style="width: 100px;" class="pflegegrad-icon" src="${personlich}" alt="Icon"/>
                        </div>
                        ${getMeRadio('form-text-personlich', isOrientationPersonal, valuesList, translateKey)}
                    </th>
                </tr>
            </table>
        </div>
    `;
}

function generateTenEqualToElevenPage(form, translateKey){
    const valuesList={
        '717700000': translateKey("form-text-uneingeschrankt"),
        '717700001': translateKey("form-text-teilweise"),
        '717700002': translateKey("form-text-eingeschrankt"),
    }
    const valuesList2={
        '717700002': translateKey("form-text-uneingeschrankt"),
        '717700001': translateKey("form-text-teilweise"),
        '717700000': translateKey("form-text-eingeschrankt"),
    }
    const isRegularChangeOfPosition = form.getFieldByKey('is_regular_change_of_position');
    const isMove = form.getFieldByKey('is_move');
    const isGettingUp = form.getFieldByKey('is_getting_up');
    const isCarryingBedWheelChar = form.getFieldByKey('is_carrying_bed_wheelchair');
    const isWalkingUpStairs = form.getFieldByKey('is_walking_up_stairs');
    const isMovingWithRollarAlone = form.getFieldByKey('is_moving_with_rollator_alone');
    const isMovingOnWheelChairAlone = form.getFieldByKey('is_moving_on_wheelchair_alone');
    const isMovingWithRollatorInFlatAlone =  form.getFieldByKey('is_moving_with_rollator_in_flat_alone');
    const isMovingRollatorOutsideFlat =  form.getFieldByKey('is_moving_rollator_outside_flat');
    const isMovingWheelCharInApartmentAlone =  form.getFieldByKey('is_moving_wheelchair_in_apartment_alone');
    const isMovingWheelChairOutsideAlone =  form.getFieldByKey('is_moving_wheelchair_outside_alone');
    const isBedCare = form.getFieldByKey('is_bed_care');
    const isLyingPatient = form.getFieldByKey('is_lying_patient');
    const isRollatorOrWalkerList = form.getFieldByKey('is_rollator_or_walker_list');
    const isWheelchair = form.getFieldByKey('is_wheelchair');
    const is_patient_help_change_positionField = form.getFieldByKey("is_patient_help_change_position")
    const isWalkingUpStairsReverse = (e) => {return e.reverse()};
    const gehen = IconsMap.other.bewegen_1;
    const stehen = IconsMap.other.bewegen_2;
    const transfer = IconsMap.other.bewegen_3;
    const treppenSteigen = IconsMap.other.bewegen_4;
    
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_movement_data_p_8')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table style="margin:0;padding:0;">

                <tr>
                    <td style="padding-left:15px;width:50%;">
                        ${getMeCheckBox('', isLyingPatient.value, translateKey("form-text-bettlagerig"), 'is_lying_patient')}
                    </td>
                    ${isLyingPatient.value === true ?
                    `<td style="width:50%;">
                        ${getMeCheckBox('', isBedCare.value, translateKey("form-text-betreuendeperson"), 'is_bed_care')}
                    </td>`:''}
                </tr>
                <tr>
                    <td style="padding-left:15px;width:50%;">
                        ${getMeCheckBox('', isRegularChangeOfPosition.value, translateKey("form-text-gelagert"), 'is_regular_change_of_position')}
                    </td>
                    ${isRegularChangeOfPosition.value === true ?
                        `<td style="width:50%;">
                            ${getMeCheckBox('', is_patient_help_change_positionField.value, translateKey("form-text-mithelfen"), 'is_patient_help_change_position')}
                        </td>`:''}
                </tr>
                <tr>
                    <td style="padding-top: 10px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', form.getFieldByKey("is_patient_need_help_with_transfer").value, translateKey("form-text-braucht"), 'is_patient_need_help_with_transfer')}
                    </td>
                </tr>
                <tr>
                    <td style="padding-left:15px;width:100%;">
                        ${getMeCheckBox('', form.getFieldByKey("is_patient_is_active_during_transfer").value, translateKey("form-text-arbeitet"), 'is_patient_is_active_during_transfer')}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="padding-left:15px;width:100%;">
                        ${getMeCheckBox('', form.getFieldByKey("is_palliative_care").value, translateKey("form-text-palliativ-stadium"), 'is_palliative_care')}
                        <div style="font-size: 0.6em;" >${translateKey("form-text-palliative-popover")}</div>
                    </td>
                </tr>
            </table>

            <table style="width:100%;margin:0;padding-top:20px;padding-left:10px;">
                <tr>
                    <th style="width:25%;">
                        <div class="icon" style="padding-top: 10px;padding-bottom: 20px; height: 100px;">
                            <img class="pflegegrad-icon" src="${gehen}" alt="Icon"/>
                        </div>
                        ${getMeRadio('form-text-gehen', isMove, valuesList, translateKey)}
                    </th>
                    <th style="width:25%;">
                        <div class="icon" style="padding-top: 10px;padding-bottom: 20px; height: 100px;">
                            <img class="pflegegrad-icon" src="${stehen}" alt="Icon"/>
                        </div>
                        ${getMeRadio('form-text-stehen', isGettingUp, valuesList, translateKey)}
                    </th>
                    <th style="width:25%;">
                        <div class="icon" style="padding-top: 10px;padding-bottom: 20px; height: 100px;">
                            <img class="pflegegrad-icon" src="${transfer}" alt="Icon"/>
                        </div>
                        ${getMeRadio('form-text-transfer', isCarryingBedWheelChar, valuesList, translateKey)}
                    </th>
                    <th style="width:25%;">
                        <div class="icon" style="padding-top: 10px;padding-bottom: 20px; height: 100px;">
                            <img class="pflegegrad-icon" src="${treppenSteigen}" alt="Icon"/>
                        </div>
                        ${getMeRadio('form-text-treppen', isWalkingUpStairs, valuesList2, translateKey, isWalkingUpStairsReverse)}
                    </th>
                </tr>
                <tr>
                    <th colspan="4" style="padding-top:10px;padding-left:15px;width:25%;">
                        ${translateKey('form-text-hilfsmittel').toUpperCase()}
                    </th>
                </tr>
                <tr>
                    <td style="width:25%;">
                        ${getMeCheckBox('', form.getFieldByKey("is_antibedsore_mattress_list").value, translateKey("form-text-antidekubitusmatratze"), 'is_antibedsore_mattress_list')}
                    </td>
                    <td style="width:25%;">
                        ${getMeCheckBox('', form.getFieldByKey("is_specialist_bed_list").value, translateKey("form-text-pflegebett"), 'is_specialist_bed_list')}
                    </td>
                    <td style="width:25%;">
                        ${getMeCheckBox('', form.getFieldByKey("is_transfer_lifter").value, translateKey("form-text-transferlifter"), 'is_transfer_lifter')}
                    </td>
                    <td style="width:25%;">
                        ${getMeCheckBox('', form.getFieldByKey("is_chair_lift").value, translateKey("form-text-treppenlifter"), 'is_chair_lift')}
                    </td>
                </tr>
                <tr>
                    <td>
                        ${getMeCheckBox('', isRollatorOrWalkerList.value, translateKey("form-text-rollator"), 'is_rollator_or_walker_list')}
                    </td>
                </tr>
            </table>

            <table style="margin:0;padding:0;">
                ${isRollatorOrWalkerList.value === true ? `<tr>
                    <th style="padding-left:15px;width:100%;">
                        ${getMeSwitch('form-text-fortbewegen-m', isMovingWithRollarAlone, translateKey)}
                    </th>
                </tr>` : ''}
                ${isMovingWithRollarAlone.value === '717700000' ? `<tr>
                    <th style="padding-left:35px;width:100%;">
                        ${getMeSwitchHorizontal('form-text-innerhalb', isMovingWithRollatorInFlatAlone, translateKey)}
                    </th>
                </tr>` : ''}
                ${isMovingWithRollarAlone.value === '717700000' ? `<tr>
                    <th style="padding-left:35px;width:100%;">
                        ${getMeSwitchHorizontal('form-text-auberhalb', isMovingRollatorOutsideFlat, translateKey)}
                    </th>
                </tr>` : ''}
            </table>

            <table style="margin:0;padding:0;">
                <tr>
                    <td style="padding-top:0px;padding-left:15px;width:100%;">
                        ${getMeCheckBox('', isWheelchair.value, translateKey("form-text-rollstuhl"), 'is_wheelchair')}
                    </td>
                </tr>
                ${isWheelchair.value === true ? `<tr>
                    <th style="padding-left:15px;width:100%;">
                        ${getMeSwitch('form-text-fortbewegen-im', isMovingOnWheelChairAlone, translateKey)}
                    </th>
                </tr>` : ''}
                ${isMovingOnWheelChairAlone.value === '717700000' ? `<tr>
                    <th style="padding-left:35px;width:100%;">
                        ${getMeSwitchHorizontal('form-text-innerhalb', isMovingWheelCharInApartmentAlone, translateKey)}
                    </th>
                </tr>` : ''}
                ${isMovingOnWheelChairAlone.value === '717700000' ? `<tr>
                    <th style="padding-left:35px;width:100%;">
                        ${getMeSwitchHorizontal('form-text-auberhalb', isMovingWheelChairOutsideAlone, translateKey)}
                    </th>
                </tr>` : ''}
            </table>
        </div>
    `;
}

function generateElevenEqualToTwelvePage(form, translateKey){
    const isOutpatientHelp = form.getFieldByKey('is_outpatient_help');
    const isContractPflegedienstServicesSigned = form.getFieldByKey('is_contract_pflegedienst_services_signed');

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_pflegedienst_data_p_14')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table style="margin:0;padding:0;">
                <tr>
                    <th style="padding-top:10px;padding-left:15px;width:100%;">
                        ${getMeSwitch('form-text-pflegedienst', isOutpatientHelp, translateKey, {yes:true,no:false})}
                    </th>
                </tr>
                ${isOutpatientHelp.value===false?
                    `<tr>
                        <th style="padding-top:10px;padding-left:15px;width:100%;">
                            ${getMeSwitch('form-text-beauftragt', isContractPflegedienstServicesSigned, translateKey, {yes:true,no:false})}
                        </th>
                    </tr>`
                    :
                    ''
                }
            </table>
        </div>
    `;
}

function generateTwelveEqualToThirteenPage(form, translateKey){
    const isCandidateGender = form.getFieldByKey('is_candidate_gender');
    const isStartDateContact = form.getFieldByKey('is_start_date_contact');
    const values={
        [SkillRange.PARTIAL]: translateKey("form-text-frau"),
        [SkillRange.UNLIMITED]: translateKey("form-text-mann"),
        [SkillRange.LIMITED]: translateKey("form-text-irrelevant"),
    }
    const isCandidateGenderArrayFoo = (arr) => {
        arr.push(arr.shift());
        return arr;
    }
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_expectations_data_p_16')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table style="margin:0;padding:0;">
                <tr>
                    <td style="padding-top:0px;padding-left:15px;width:100%;">
                        <table style="width:100%;">
                            <tr>
                                <th width="100%">
                                    ${getMeRadio('form-text-section-17-01', isCandidateGender, values, translateKey, isCandidateGenderArrayFoo)}
                                </th>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style="padding-top:0px;padding-left:15px;width:100%;">
                        ${getMeInput(translateKey("contract-text-voraussichtlicher-begin"), isStartDateContact.value ? convertDate(isStartDateContact.value*1000) : '')}
                    </td>
                </tr>

            </table>
        </div>
    `;
}

function generateThirdteenEqualToFoutheenPage(form, translateKey){
    const is_cooking = form.getFieldByKey('is_cooking');
    const is_purchases = form.getFieldByKey('is_purchases');
    const is_laundry = form.getFieldByKey('is_laundry');
    const is_poted_flower_care = form.getFieldByKey('is_poted_flower_care');
    const is_ironing = form.getFieldByKey('is_ironing');
    const is_accompanying = form.getFieldByKey('is_accompanying_patient_while_out');
    const is_cleaning_the_house = form.getFieldByKey('is_cleaning_the_house');
    const is_company = form.getFieldByKey('is_company_during_medical_visits');
    const is_common_trips = form.getFieldByKey('is_common_trips');
    const is_organization_of_free_time = form.getFieldByKey('is_organization_of_free_time');
    const is_driving = form.getFieldByKey('is_driving')
    const is_driving_automat_gearbox = form.getFieldByKey('is_driving_automat_gearbox');

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('bb_actions_data_p_17')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table style="width:100%;margin:0;padding:0;">
                <tr>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_cooking.value, translateKey("form-text-kochen"), 'is_cooking')}
                    </td>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_purchases.value, translateKey("form-text-einkaufengehen"), 'is_purchases')}
                    </td>
                </tr>

                <tr>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_laundry.value, translateKey("form-text-waschewaschen"), 'is_laundry')}
                    </td>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_poted_flower_care.value, translateKey("form-text-zimmerpflanzen"), 'is_poted_flower_care')}
                    </td>
                </tr>

                <tr>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_ironing.value, translateKey("form-text-bugeln"), 'is_ironing')}
                    </td>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_accompanying.value, translateKey("form-text-spazierengehen"), 'is_accompanying_patient_while_out')}
                    </td>
                </tr>

                <tr>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_cleaning_the_house.value, translateKey("form-text-arbeitsplatzes"), 'is_cleaning_the_house')}
                    </td>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_company.value, translateKey("form-text-arztbesuche"), 'is_company_during_medical_visits')}
                    </td>
                </tr>

                <tr>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_common_trips.value, translateKey("form-text-gemeinsame"), 'is_common_trips')}
                    </td>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_organization_of_free_time.value, translateKey("form-text-freizeitgestaltung"), 'is_organization_of_free_time')}
                    </td>
                </tr>

                <tr>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_driving.value, translateKey("form-text-schaltgetriebe"), 'is_driving')}
                    </td>
                    <td style="padding-top:0px;padding-left:15px;width:50%;">
                        ${getMeCheckBox('', is_driving_automat_gearbox.value, translateKey("form-text-automatikgetriebe"), 'is_driving_automat_gearbox')}
                    </td>
                </tr>
            </table>
        </div>
    `;
}

function generateFoutheenEqualToFifteenPage(form, translateKey){
    const isPatientLives = form.getFieldByKey('is_patient_lives');
    const valuesObj={
        '717700004': translateKey("form-text-alleine"),
        '717700000': translateKey("form-text-ehefrau"),
        '717700005': translateKey("form-text-enkel-enkelin"),
        '717700003': translateKey("form-text-mehrgenerationenhaushalt"),
        '717700001': translateKey("form-text-sohn-tochter"),
        '717700006': translateKey("form-text-bruder-schwester"),
        '717700002': translateKey("form-text-andre"),
    };
    const valuesArray = [
        '717700004',
        '717700000',
        '717700005',
        '717700003',
        '717700001',
        '717700006',
        '717700002',
    ];
    isPatientLives.validationRules.possibleValues = valuesArray;

    return `
    <div class="form-section-main page_break">
        <div class="new-section-content-wrapper container form_serializer">
            <div class="newUI-section-padding">
                <div class="row">
                    <div class="columnfull">
                        <div class="new-section-name-wrapper container">
                            ${translateKey('bb_place_data_p_18')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table style="width:100%;margin:0;padding:0;">
            <tr>
                <td style="padding-top:0px;padding-left:15px;width:100%;">
                    <table style="width:100%;">
                        <tr>
                            <th width="100%">
                                ${getMeRadio('form-text-section-17-01', isPatientLives, valuesObj, translateKey)}
                            </th>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>
`;
}

function generate16Page(form, translateKey){
    const isFamilySufferInfectionusDiseases = form.getFieldByKey('is_family_suffer_infectionus_diseases');
    const isInfectiousDiseases = form.getFieldByKey('is_infectious_diseases');
    const familySufferInput = form.getFieldByKey('is_family_suffer_infectionus_diseases_des_de');
    const infectiousInput = form.getFieldByKey('is_infectious_diseases_what_de');
    const values = {
        yes: true,
        no: false,
    }
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("bb_ills_data_p_4")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="50%">
                                    ${getMeSwitch('form-text-anstecken', isFamilySufferInfectionusDiseases, translateKey, values)}
                                </th>
                                <th width="50%">
                                    ${getMeSwitch('form-text-alzheimer-krankheiten', isInfectiousDiseases, translateKey, values)}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${isFamilySufferInfectionusDiseases.value ? getMeInput(translateKey("form-text-welche"), infectiousInput.value) : ''}
                                </th>
                                <th>
                                    ${isInfectiousDiseases.value ? getMeInput(translateKey("form-text-welche"), familySufferInput.value) : ''}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate17Page(form, translateKey){
    const isHearingAidList = form.getFieldByKey('is_hearing_aid_list');
    const isGlassList= form.getFieldByKey('is_glasses_list');
    const isHearingAidSelfContainedUse = form.getFieldByKey('is_hearing_aid_self_contained_use');
    const isGlassSelfUse = form.getFieldByKey('is_glasses_self_use');
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("form-text-hilfsmittel")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="50%">
                                    ${getMeCheckBox('', isHearingAidList.value, translateKey('form-text-horgerat'), '')}
                                </th>
                                <th width="50%">
                                    ${getMeCheckBox('', isGlassList.value, translateKey('form-text-brille'), '')}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${isHearingAidList.value ? getMeSwitch('form-text-einsetzen', isHearingAidSelfContainedUse, translateKey) : ''}
                                </th>
                                <th>
                                    ${isGlassList.value ? getMeSwitch('form-text-aufsetzen', isGlassSelfUse, translateKey) : ''}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate18Page(form, translateKey){
    const isFearful = form.getFieldByKey('is_fearful');
    const isApathetic = form.getFieldByKey('is_apathetic');
    const isDemanding = form.getFieldByKey('is_demanding');
    const isCheerful = form.getFieldByKey('is_cheerful');
    const isNervous = form.getFieldByKey('is_nervous');
    const isOpen= form.getFieldByKey('is_open');
    const isNice = form.getFieldByKey('is_nice');
    const isMoody = form.getFieldByKey('is_moody');
    const isEuphoric = form.getFieldByKey('is_euphoric');
    const isDecisive = form.getFieldByKey('is_decisive');
    const isSensitive = form.getFieldByKey('is_sensitive');
    const isPositive = form.getFieldByKey('is_positive');
    const isUnstable = form.getFieldByKey('is_unstable');
    const isWithdrawn = form.getFieldByKey('is_withdrawn');
    const isCritical = form.getFieldByKey('is_critical');
    const isNegative = form.getFieldByKey('is_negative');
    const isEmotionalCOD = form.getFieldByKey('is_emotional_condition_others_de');

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("bb_characteristic_data_p_7")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="25%">
                                    ${translateKey("bb_characteristic_data_p_7").toUpperCase()}
                                </th>
                            </tr>
                            <tr>
                                <td width="25%">
                                    ${getMeCheckBox('', isFearful.value, translateKey("form-text-angstlich"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isApathetic.value, translateKey("form-text-apathisch"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isDemanding.value, translateKey("form-text-fordernd"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isCheerful.value, translateKey("form-text-frohlich"), '')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ${getMeCheckBox('', isNervous.value, translateKey("form-text-gereizt"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isOpen.value, translateKey("form-text-offen"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isNice.value, translateKey("form-text-lieb"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isMoody.value, translateKey("form-text-launisch"), '')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ${getMeCheckBox('', isEuphoric.value, translateKey("form-text-euphorisch"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isDecisive.value, translateKey("form-text-bestimmend"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isSensitive.value, translateKey("form-text-sensibel"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isPositive.value, translateKey("form-text-positiv"), '')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ${getMeCheckBox('', isUnstable.value, translateKey("form-text-labil"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isWithdrawn.value, translateKey("form-text-zuruckhaltend"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isCritical.value, translateKey("form-text-kritisch"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isNegative.value, translateKey("form-text-negativ"), '')}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    ${getMeInput(translateKey("form-text-sonstige-gem"), isEmotionalCOD.value)}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate19Page(form, translateKey){
    const valuesList1 = {
        [SkillRange.UNLIMITED]: translateKey("no"),
        [SkillRange.PARTIAL]: translateKey("form-text-gelegentlich"),
        [SkillRange.LIMITED]: translateKey("yes"),
    };
    const valuesList2 = {
        [SkillRange.UNLIMITED]: translateKey("form-text-uneingeschrankt"),
        [SkillRange.PARTIAL]: translateKey("form-text-teilweise"),
        [SkillRange.LIMITED]: translateKey("form-text-eingeschrankt"),
    };
    const IS_USE_TOILET_OR_TOILET_CHAIR_ALONE = "is_use_toilet_or_toilet_chair_alone";
    const IS_IF_LIMITED_HOW_OFTEN = "is_if_limited_how_often";
    const IS_URINATION_CONTROL = "is_urination_control";
    const IS_CONTROL_OF_DEFECATION = "is_control_of_defecation";
    const IS_CATHETER_LIST  = "is_catheter_list";
    const IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL  = "is_catheterized_through_the_abdominal_wall";
    const IS_BEDPAN_A_LIST  = "is_bedpan_a_list";
    const isUseToiletOrToiletChairAlone = form.getFieldByKey(IS_USE_TOILET_OR_TOILET_CHAIR_ALONE);
    const isIfLimitedHowOften = form.getFieldByKey(IS_IF_LIMITED_HOW_OFTEN);
    const isUrinationControlField = form.getFieldByKey(IS_URINATION_CONTROL);
    const isControlOfDefecationField = form.getFieldByKey(IS_CONTROL_OF_DEFECATION);
    const isCatheterListField = form.getFieldByKey(IS_CATHETER_LIST);
    const isCatheterizedThroughField = form.getFieldByKey(IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL);
    const isBedpanAListField = form.getFieldByKey(IS_BEDPAN_A_LIST);
    const isInsertsList = form.getFieldByKey('is_inserts_list');
    const isNappiesList = form.getFieldByKey('is_nappies_list');
    const isAidsOther = form.getFieldByKey('is_aids_other_de');
    const showLimitedHowOften = SkillRange.UNLIMITED !== isUseToiletOrToiletChairAlone.value;
    const correctValuesOrderFoo = (arr) => {
        [arr[0], arr[1]] = [arr[1], arr[0]];
        return arr;
    }
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("bb_defecation_data_p_9")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="25%">
                                    ${getMeRadio('form-text-urininkontinenz', isUrinationControlField, valuesList1, translateKey, correctValuesOrderFoo)}
                                </th>
                                <th width="25%">
                                    ${getMeRadio('form-text-stuhlinkontinenz', isControlOfDefecationField, valuesList1, translateKey)}
                                </th>
                            </tr>
                        </table>
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="50%">
                                    ${getMeRadio('form-text-toilettenstuhlsmoglich', isUseToiletOrToiletChairAlone, valuesList2, translateKey, correctValuesOrderFoo)}
                                </th>
                            </tr>
                            ${showLimitedHowOften && `<tr>
                                <th width="50%">
                                    ${getMeHorizontalSliders(translateKey, "form-text-wieoft-toilet", isIfLimitedHowOften.value)}
                                </th>
                            </tr>`}
                        </table>
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="25%">
                                    ${translateKey("form-text-hilfsmittel").toUpperCase()}
                                </th>
                            </tr>
                            <tr>
                                <td width="25%">
                                    ${getMeCheckBox('', isCatheterListField.value, translateKey("form-text-blasenkatheter"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isCatheterizedThroughField.value, translateKey("form-text-suprapubischer"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isBedpanAListField.value, translateKey("form-text-urinflasche"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isInsertsList.value, translateKey("form-text-vorlagen"), '')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ${getMeCheckBox('', isNappiesList.value, translateKey("form-text-windeln"), '')}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    ${getMeInput(translateKey("form-text-sonstige-gem"), isAidsOther.value)}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate20Page(form, translateKey){
    const valuesList = {
        [SkillRange.UNLIMITED]: translateKey("form-text-uneingeschrankt"),
        [SkillRange.PARTIAL]: translateKey("form-text-teilweise"),
        [SkillRange.LIMITED]: translateKey("form-text-eingeschrankt"),
    };
    const is_bathing_shower = form.getFieldByKey('is_bathing_shower');
    const is_skin_care = form.getFieldByKey('is_skin_care');
    const is_oral_care_and_dentures = form.getFieldByKey('is_oral_care_and_dentures');
    const is_hair_care = form.getFieldByKey('is_hair_care');
    const is_shaving = form.getFieldByKey('is_shaving');
    const is_intimate_hygiene = form.getFieldByKey('is_intimate_hygiene');
    const toiletChair=form.getFieldByKey('is_toilet_chair');
    const isShowerChair=form.getFieldByKey('is_shower_chair');
    const liftByTheBath=form.getFieldByKey('is_lift_by_the_bath');
    const isToiletSeat=form.getFieldByKey('is_toilet_seat');
    const correctValuesOrderFoo = (arr) => {
        [arr[0], arr[1]] = [arr[1], arr[0]];
        return arr;
    }
    const baden = IconsMap.other.baden;
    const hautpflege = IconsMap.other.hautpflege;
    const mundpflege = IconsMap.other.mundpflege;
    const haarpflege = IconsMap.other.haarpflege;
    const rasieren = IconsMap.other.rasieren;
    const intimpflege = IconsMap.other.intimpflege;
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("bb_hugiene_data_p_10")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="25%">  
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="width: 60px;" class="pflegegrad-icon" src="${baden}" alt="Icon"/>
                                    </div>                                                     
                                    ${getMeRadio('form-text-duschen-baden', is_bathing_shower, valuesList, translateKey, correctValuesOrderFoo)}
                                </th>
                                <th width="25%">
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="width: 60px;" class="pflegegrad-icon" src="${hautpflege}" alt="Icon"/>
                                    </div>  
                                    ${getMeRadio('form-text-hautpflege', is_skin_care, valuesList, translateKey)}
                                </th>
                                <th width="25%">
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="width: 60px;" class="pflegegrad-icon" src="${mundpflege}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-mundpflege', is_oral_care_and_dentures, valuesList, translateKey)}
                                </th>
                                <th width="25%">
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="width: 60px;" class="pflegegrad-icon" src="${haarpflege}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-haarpflege', is_hair_care, valuesList, translateKey)}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="width: 80px;" class="pflegegrad-icon" src="${intimpflege}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-intimpflege', is_shaving, valuesList, translateKey)}
                                </th>
                                <th>
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="width: 60px;" class="pflegegrad-icon" src="${rasieren}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-rasieren', is_intimate_hygiene, valuesList, translateKey)}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${translateKey("form-text-hilfsmittel").toUpperCase()}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    ${getMeCheckBox('', toiletChair.value, translateKey("form-text-nachtstuhl"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isShowerChair.value, translateKey("form-text-duschstuhl"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', liftByTheBath.value, translateKey("form-text-badewannenlift"), '')}
                                </td>
                                <td>
                                    ${getMeCheckBox('', isToiletSeat.value, translateKey("form-text-toilettensitzerhöhung"), '')}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate21Page(form, translateKey){
    const valuesList1 = {
        [SkillRange.UNLIMITED]: translateKey("form-text-uneingeschrankt"),
        [SkillRange.PARTIAL]: translateKey("form-text-teilweise"),
        [SkillRange.LIMITED]: translateKey("form-text-eingeschrankt"),
    };

    const valuesList2 = {
        [SkillRange.UNLIMITED]: translateKey("form-text-normal"),
        [SkillRange.PARTIAL]: translateKey("form-text-unterernahrt"),
        [SkillRange.LIMITED]: translateKey("form-text-fettleibig"),
    };
    const valuesList3 = {
        [SkillRange.UNLIMITED]: translateKey("form-text-normal"),
        [SkillRange.PARTIAL]: translateKey("form-text-wenig"),
        [SkillRange.LIMITED]: translateKey("form-text-viel"),
    };
    const isSondaPeg = form.getFieldByKey('is_sonda_peg');
    const isDehydration = form.getFieldByKey('is_dehydration');
    const is_eating = form.getFieldByKey('is_eating');
    const is_drinking = form.getFieldByKey('is_drinking');
    const is_chewing_and_swallowing = form.getFieldByKey('is_chewing_and_swallowing');
    const is_nutritional_status = form.getFieldByKey('is_nutritional_status');
    const isDietNormal = form.getFieldByKey('is_diet_normal');
    const isDietBalanced = form.getFieldByKey('is_diet_balanced');
    const isDietVegetarian = form.getFieldByKey('is_diet_vegetarian');
    const isDietSpecial = form.getFieldByKey('is_diet_special');
    const isDietDiabetes = form.getFieldByKey('is_diet_diabetes');

    const isTalkingDrink = form.getFieldByKey('is_taking_drink');
    const isFavoriteFoodDrink = form.getFieldByKey('is_favorite_food_drink_de');
    const essen = IconsMap.other.essen;
    const trinken = IconsMap.other.trinken;
    const kauen = IconsMap.other.kauen;

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("bb_foods_data_p_11")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="33%">
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="width: 60px;" class="pflegegrad-icon" src="${essen}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-essen', is_eating, valuesList1, translateKey)}
                                </th>
                                <th width="33%">
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="width: 60px;" class="pflegegrad-icon" src="${trinken}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-trinken', is_drinking, valuesList1, translateKey)}
                                </th>
                                <th width="33%">
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="width: 80px;" class="pflegegrad-icon" src="${kauen}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-schlucken', is_chewing_and_swallowing, valuesList1, translateKey)}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    ${getMeCheckBox('', isSondaPeg.value, translateKey("form-text-pegsonde"), '')}
                                </td>
                            </tr>
                            <tr>
                                <th width="33%">
                                    ${getMeRadio('form-text-ernahrungszustand', is_nutritional_status, valuesList2, translateKey)}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    ${getMeCheckBox('', isDehydration.value, translateKey("form-text-entwassert"), '')}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    ${translateKey("form-text-kostform-diat").toUpperCase()}
                                </th>
                            </tr>
                        </table>
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <td width="25%">
                                    ${getMeCheckBox('', isDietNormal.value, translateKey("form-text-normal"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isDietBalanced.value, translateKey("form-text-vollwert"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isDietVegetarian.value, translateKey("form-text-vegetarisch"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isDietSpecial.value, translateKey("form-text-sonderkost"), '')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ${getMeCheckBox('', isDietDiabetes.value, translateKey("form-text-diabeteskost"), '')}
                                </td>
                            </tr>
                        </table>

                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="100%">
                                    ${getMeRadio('form-text-trinkmenge', isTalkingDrink, valuesList3, translateKey)}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${getMeInput(translateKey("form-text-lieblingsspeisen"), isFavoriteFoodDrink.value)}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate22Page(form, translateKey){
    const valuesList = {
        [SkillRange.UNLIMITED]: translateKey("form-text-selbstandig"),
        [SkillRange.PARTIAL]: translateKey("form-text-mit-unterstutzung"),
        [SkillRange.LIMITED]: translateKey("form-text-mit-voller-unterstutzung"),
    };
    const is_dressing_upper_part_of_body = form.getFieldByKey('is_dressing_upper_part_of_body');
    const is_dressing_pants_skirt = form.getFieldByKey('is_dressing_pants_skirt');
    const is_dressing_underwear = form.getFieldByKey('is_dressing_underwear');
    const is_dressing_shoes = form.getFieldByKey('is_dressing_shoes');
    const is_undress_upper_part_of_body = form.getFieldByKey('is_undress_upper_part_of_body');
    const is_undress_underwear = form.getFieldByKey('is_undress_underwear');
    const is_undress_pants_skirt = form.getFieldByKey('is_undress_pants_skirt');
    const is_undress_shoes = form.getFieldByKey('is_undress_shoes');
    const { 
        ankleiden_hose,
        ankleiden_oberteil,
        ankleiden_schuhe,
        ankleiden_unterhose,
        auskleiden_hose,
        auskleiden_oberteil,
        auskleiden_schuhe,
        auskleiden_unterhose,
    } = IconsMap.other;

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("bb_clothes_data_p_12")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="25%">
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="height: 80px;" class="pflegegrad-icon" src="${ankleiden_hose}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-ankleiden-oberteil', is_dressing_upper_part_of_body, valuesList, translateKey)}
                                </th>
                                <th width="25%">
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="height: 80px;" class="pflegegrad-icon" src="${ankleiden_oberteil}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-ankleiden-hose', is_dressing_pants_skirt, valuesList, translateKey)}
                                </th>
                                <th width="25%">
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="height: 80px;" class="pflegegrad-icon" src="${ankleiden_schuhe}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-ankleiden-unterhose', is_dressing_underwear, valuesList, translateKey)}
                                </th>
                                <th width="25%">
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="height: 80px;" class="pflegegrad-icon" src="${ankleiden_unterhose}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-ankleiden-schuhe', is_dressing_shoes, valuesList, translateKey)}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="height: 80px;" class="pflegegrad-icon" src="${auskleiden_hose}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-auskleiden-oberteil', is_undress_upper_part_of_body, valuesList, translateKey)}
                                </th>
                                <th>
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="height: 80px;" class="pflegegrad-icon" src="${auskleiden_oberteil}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-auskleiden-hose', is_undress_pants_skirt, valuesList, translateKey)} 
                                </th>
                                <th>
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="height: 80px;" class="pflegegrad-icon" src="${auskleiden_schuhe}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-auskleiden-unterhose', is_undress_underwear, valuesList, translateKey)}
                                </th>
                                <th>
                                    <div class="icon" style="padding-top: 10px;padding-bottom: 20px;">
                                        <img style="height: 80px;" class="pflegegrad-icon" src="${auskleiden_unterhose}" alt="Icon"/>
                                    </div>
                                    ${getMeRadio('form-text-auskleiden-schuhe', is_undress_shoes, valuesList, translateKey)}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate23Page(form, translateKey){
    const valuesList = {
        [SkillRange.UNLIMITED]: translateKey("form-text-uneingeschrankt"),
        [SkillRange.PARTIAL]: translateKey("form-text-teilweise"),
        [SkillRange.LIMITED]: translateKey("form-text-eingeschrankt"),
    };
    const einschlafen = form.getFieldByKey("is_falling_asleep");
    const durchschlafen = form.getFieldByKey("is_sleep_over");
    const isRemarksSleepsDe = form.getFieldByKey('is_remarks_sleeps_de');
    const isRemarksSleepsDeHelper = form.getFieldByKey('is_remarks_sleeps_de_helper');
    const isRemarksSleepsDeHelperValue = isRemarksSleepsDe.value && isRemarksSleepsDe.value.split('|')[0];
    const isHowOftenCarerMustGetUpAtNightDe = form.getFieldByKey('is_how_often_carer_must_get_up_at_night_de');
    const isActivitiesPBCAND = form.getFieldByKey('is_activities_performed_by_carer_at_night_de');
    const isPatientMHD = form.getFieldByKey('is_patient_medicines_hypnotics_de');
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("bb_sleep_data_p_13")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto; padding:0;border-spacing:10px;">
                            <tr>
                                <th width="100%">
                                    ${getMeRadio('form-text-einschlafen', einschlafen, valuesList, translateKey)}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${getMeRadio('form-text-durchschlafen', durchschlafen, valuesList, translateKey)}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${getMeInput(translateKey("form-text-schlafmedikation"), isPatientMHD.value)}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${getMeHorizontalSliders(translateKey, "form-text-bemerkungen", isRemarksSleepsDeHelperValue)}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${isRemarksSleepsDeHelperValue && isRemarksSleepsDeHelperValue !== '0' ? getMeInput(translateKey("form-text-aus-welchem"), isRemarksSleepsDeHelper.value) : ''}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${getMeHorizontalSliders(translateKey, "form-text-wieoftmuss", isHowOftenCarerMustGetUpAtNightDe.value)}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${isHowOftenCarerMustGetUpAtNightDe && isHowOftenCarerMustGetUpAtNightDe.value !== '0' ? getMeInput(translateKey("form-text-beitatigkeiten"), isActivitiesPBCAND.value) : ''}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate24Page(form, translateKey){
    const isDayCareHome = form.getFieldByKey('is_day_care_home');
    const isEmergencyCallSystem = form.getFieldByKey('is_emergency_call_system');
    const isHowOftenOutpatientHelpDe = form.getFieldByKey('is_how_often_outpatient_help_de');
    const isHowOftenOutpatientValue = isHowOftenOutpatientHelpDe.value && isHowOftenOutpatientHelpDe.value.split('|')[0];
    const isHowOftenOutpatientLabel = isHowOftenOutpatientHelpDe.value && isHowOftenOutpatientHelpDe.value.split('|')[1];
    const isOutpatientServicesList = form.getFieldByKey('is_outpatient_services_list_de');
    const medicalCare = form.getFieldByKey('is_medical_care_sgb_v');
    const benefitsKind = form.getFieldByKey('is_benefits_kind_for_sgb_care');
    const anicillaryServices = form.getFieldByKey('is_anicillary_services_45b');
    const isHomeCareDayFrequencyDe = form.getFieldByKey('is_home_care_day_frequency_de');
    const isHomeCareDayFrequencyValue = isHomeCareDayFrequencyDe.value && isHomeCareDayFrequencyDe.value.split('|')[0];
    const isHomeCareDayFrequencyLabel = isHomeCareDayFrequencyDe.value && isHomeCareDayFrequencyDe.value.split('|')[1];
    const outset = 5;
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("bb_pflegedienst_data_p_14")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto; padding:0;">
                            <tr>
                                <td width="50%">
                                    ${getMeCheckBox('', isEmergencyCallSystem.value, translateKey("form-text-notrufsystem"), '')}
                                </td>
                                <th width="50%">
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    ${isHowOftenOutpatientHelpDe.value && translateKey("form-text-wie-pflegedienst")}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <table>
                                        <tr>
                                            <td width="10" style="text-align:center;border: 1px solid rgb(170,181,193);border-radius: 20%;padding: ${outset}px;">-</td>
                                            <td width="30" style="text-align:center;border: 1px solid rgb(170,181,193);border-radius: 20%;padding: ${outset*2}px;margin: 0 ${outset}px;">${isHowOftenOutpatientValue}</td>
                                            <td width="10" style="text-align:center;border: 1px solid rgb(170,181,193);border-radius: 20%;padding: ${outset}px;">+</td>
                                            ${isHowOftenOutpatientLabel && `<td width="100%" style="text-align:center;border: 1px solid rgb(170,181,193);border-radius: 20%;padding: ${outset}px;">${isHowOftenOutpatientLabel}</td>`}
                                        </tr>
                                    </table>
                                </th>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <th colspan="3" width="16.6%">
                                    ${getMeInput(translateKey("form-text-tatigkeiten"), isOutpatientServicesList.value)}
                                </th>
                            </tr>
                            <tr>
                                <th colspan="3" width="16.6%">
                                    ${translateKey("form-text-welche-leistungen").toUpperCase()}
                                </th>
                            </tr>
                            <tr>
                                <td colspan="1" width="16.6%">
                                    ${getMeCheckBox('', medicalCare.value, translateKey("form-text-behandlungspflege"), '')}
                                </td>
                                <td colspan="2" width="16.6%">
                                    ${getMeCheckBox('', benefitsKind.value, translateKey("form-text-pflegesachleistung"), '')}
                                </td>
                                <td colspan="2" width="16.6%">
                                    ${getMeCheckBox('', anicillaryServices.value, translateKey("form-text-betreuungsleistungen"), '')}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    ${getMeSwitch('form-text-tagespflege', isDayCareHome, translateKey)}
                                </th>
                            </tr>
                            ${isDayCareHome.value === '717700000' && `<tr>
                                    <th colspan="3">
                                        ${translateKey("form-text-wieoft")}
                                    </th>
                                </tr>
                                <th>
                                    <table>
                                        <tr>
                                            <td width="10" style="text-align:center;border: 1px solid rgb(170,181,193);border-radius: 20%;padding: ${outset}px;">-</td>
                                            <td width="30" style="text-align:center;border: 1px solid rgb(170,181,193);border-radius: 20%;padding: ${outset*2}px;margin: 0 ${outset}px;">${isHomeCareDayFrequencyValue}</td>
                                            <td width="10" style="text-align:center;border: 1px solid rgb(170,181,193);border-radius: 20%;padding: ${outset}px;">+</td>
                                            ${isHomeCareDayFrequencyLabel && `<td width="100%" style="text-align:center;border: 1px solid rgb(170,181,193);border-radius: 20%;padding: ${outset}px;">${isHomeCareDayFrequencyLabel}</td>`}
                                        </tr>
                                    </table>
                                </th>
                            </tr>`}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate25Page(form, translateKey){
    const isSpeechTherapy = form.getFieldByKey('is_speech_therapy');
    const isErgotherapy = form.getFieldByKey('is_ergotherapy');
    const isPhysiotherapy = form.getFieldByKey('is_physiotherapy');
    const isOccupationalTheraphy = form.getFieldByKey('is_occupational_theraphy');

    const isTherapeuticCareAtHome = form.getFieldByKey('is_therapeutic_care_at_home');
    const isTherapeuticCareOutsideHome = form.getFieldByKey('is_therapeutic_care_outside_home');
    const isTherapeuticInput = form.getFieldByKey('is_therapeutic_care_how_often_in_week_de');

    const atLeastOneIsChecked = isSpeechTherapy.value || isErgotherapy.value || isPhysiotherapy.value || isOccupationalTheraphy.value;

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("bb_therapy_data_p_15")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">

                        <table style="width:700px; margin:0 auto; padding:0;">
                            <tr>
                                <th width="25%" colspan="3">
                                    ${translateKey('form-text-durchgeführt')}
                                </th>
                            </tr>
                            <tr>
                                <td width="25%">
                                    ${getMeCheckBox('', isSpeechTherapy.value, translateKey("form-text-logopadie"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isErgotherapy.value, translateKey("form-text-ergotherapie"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isPhysiotherapy.value, translateKey("form-text-krankengymnastik"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isOccupationalTheraphy.value, translateKey("form-text-beschaftigungstherapie"), '')}
                                </td>
                            </tr>
                            ${atLeastOneIsChecked && `<tr>
                                <td>
                                    ${getMeCheckBox('', isTherapeuticCareAtHome.value, translateKey("form-text-therapeutkommt"))}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ${getMeCheckBox('', isTherapeuticCareOutsideHome.value, translateKey("form-text-therapeutextern"))}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="3">
                                    ${getMeInput(translateKey("form-text-prowoche"), isTherapeuticInput.value)}
                                </th>
                            </tr>`}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate26Page(form, translateKey){
    const isLocation = form.getFieldByKey('is_location');
    const isLocationLabelOption = {
        [LocationRange.BIGCITY]: translateKey("form-text-grobstadt-z"),
        [LocationRange.CITY]: translateKey("form-text-grobstadt-v"),
        [LocationRange.SMALLCITY]: translateKey("form-text-kleinstadt"),
        [LocationRange.VILLAGE]: translateKey("form-text-dorf"),
    };
    const isHousingConditions = form.getFieldByKey('is_housing_conditions');
    const isHousingLabelOption = {
        [HouseType.HOUSE]: translateKey("form-text-einfamilienhaus"),
        [HouseType.BIGHOUSE]: translateKey("form-text-mehrfamilienhaus"),
        [HouseType.APARTMENT]: translateKey("form-text-Wohnung"),
        [HouseType.OTHER]: translateKey("form-text-sonstige-gem"),
    };
    const isShopping = form.getFieldByKey('is_shopping_are_made_by_family');
    const isShoppingOnFoot = form.getFieldByKey('is_shopping_on_foot');
    const isShoppingLabelOption = {
        '717700000': translateKey("form-text-ca10min"),
        '717700001': translateKey("form-text-ca20min"),
        '717700002': translateKey("form-text-ca30min"),
        '717700003': translateKey("form-text-more30min"),
    };
    const isActivitiesAdditionalPeople = form.getFieldByKey('is_activities_additional_people');
    const isActivitiesAdditionalPeopleInput = form.getFieldByKey('is_activities_regarding_additional_people_de');
    const isPets = form.getFieldByKey('is_pets');
    const isPetsInput = form.getFieldByKey('is_taking_care_of_animals_which_de');
    const isTakingCareAnimals = form.getFieldByKey('is_taking_care_of_animals');
    const isTakingCareAnimalsLabelOption = {
        '717700000': translateKey("yes"),
        '717700002': translateKey("no"),
        '717700003': translateKey("contract-text-is-identical-patient-data1"),
    };
    const isSeparateBathroomList = form.getFieldByKey('is_separate_bathroom_list');
    const isBedList = form.getFieldByKey('is_bed_list');
    const isTableList = form.getFieldByKey('is_table_list');
    const isWardrobeList = form.getFieldByKey('is_wardrobe_list');
    const isSeparateToilett = form.getFieldByKey('is_separate_toilett');
    const isTelevisionList = form.getFieldByKey('is_television_list');
    const isInternetList = form.getFieldByKey('is_internet_list');
    const isPcList = form.getFieldByKey('is_pc_list');
    const geregelt = form.getFieldByKey('is_how_often_carer_free_de');
    const werubernimmt = form.getFieldByKey('is_who_take_care_when_carer_free_de');
    const isGarden = form.getFieldByKey('is_garden');
    const isBike = form.getFieldByKey('is_bike');
    const isMarketingId = form.getFieldByKey('is_marketingid');
    const isMarketingValue = MarketingID.getOptions(translateKey).find(({value}) => value.toLowerCase() === isMarketingId.value.toLowerCase());

    return `
        <div class="form-section-main page_break">
        <div class="newUI-section-padding">
                <div class="row">
                    <div class="columnfull">
                        <div class="new-section-name-wrapper container">
                            ${translateKey("form-text-question-needed")}                                
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto; padding:0;">
                            <tr>
                                <th width="40%">
                                    ${getMeRadio('form-text-personlebt', isLocation, isLocationLabelOption, translateKey)}
                                </th>
                                <th width="40%">
                                    ${getMeRadio('form-text-question-needed', isHousingConditions, isHousingLabelOption, translateKey)}
                                </th>
                            </tr>
                            <tr>
                                <td width="33%">
                                    ${getMeCheckBox('', isShopping.value, translateKey("form-text-erledigt"), '')}
                                </td>
                            </tr>
                            <tr>
                                <th width="33%">
                                    ${getMeRadio('form-text-einkaufsmöglichkeiten', isShoppingOnFoot, isShoppingLabelOption, translateKey)}
                                </th>
                            </tr>
                            <tr>
                                <td width="33%">
                                    ${getMeCheckBox('', isActivitiesAdditionalPeople.value, translateKey("form-text-mitbewohner"), '')}
                                </td>
                                ${isActivitiesAdditionalPeople.value && `<th width="33%">
                                    ${getMeInput(translateKey("form-text-fallsja"), isActivitiesAdditionalPeopleInput.value)}
                                </th>`}
                            </tr>
                            <tr>
                                <td width="33%">
                                    ${getMeCheckBox('', isPets.value, translateKey("form-text-haustiere"), '')}
                                </td>
                                ${isPets.value && `<th width="33%">
                                    ${getMeInput(translateKey("form-text-fallsja"), isPetsInput.value)}
                                </th>`}
                            </tr>
                            ${isPets.value && `<tr>
                                <th width="33%">
                                    ${getMeRadio('form-text-sollendie', isTakingCareAnimals, isTakingCareAnimalsLabelOption, translateKey)}
                                </th>
                            </tr>`}
                            <tr>
                                <th width="33%" class="rodo-options-span-padding">${translateKey('form-text-zimmerausstattung')}</th>
                            </tr>
                            <tr>
                                <td width="33%">
                                    ${getMeCheckBox('', isSeparateBathroomList.value, translateKey("form-text-eigenesbad"), '')}
                                </td>
                                <td width="33%">
                                    ${getMeCheckBox('', isBedList.value, translateKey("form-text-bett"), '')}
                                </td>
                                <td width="33%">
                                    ${getMeCheckBox('', isTableList.value, translateKey("form-text-tisch"), '')}
                                </td>
                                <td width="33%">
                                    ${getMeCheckBox('', isWardrobeList.value, translateKey("form-text-schrank"), '')}
                                </td>
                            </tr>
                            <tr>
                                <td width="25%">
                                    ${getMeCheckBox('', isSeparateToilett.value, translateKey("form-text-radio"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isTelevisionList.value, translateKey("form-text-tv"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isInternetList.value, translateKey("form-text-internet"), '')}
                                </td>
                                <td width="25%">
                                    ${getMeCheckBox('', isPcList.value, translateKey("form-text-computer"), '')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    ${getMeInput(translateKey("form-text-geregelt"), geregelt.value)}
                                </td>
                                <td>
                                    ${getMeInput(translateKey("form-text-werubernimmt"), werubernimmt.value)}
                                </td>
                                <td colspan="2">
                                    ${getMeInput(translateKey("form-text-from-where-you-know"), isMarketingValue && isMarketingValue.label)}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    ${getMeSwitch('form-text-garten', isGarden, translateKey)}
                                </th>
                                <th>
                                    ${getMeSwitch('form-text-fahrrad', isBike, translateKey)}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function generate27Page(form, translateKey){
    const isCustomer = form.getFieldByKey('is_customer');
    const isRelative = form.getFieldByKey('is_relative');
    const isCarer = form.getFieldByKey('is_carer');

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey("form-section-title-anwesend")}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="width:700px; margin:0 auto;">
                            <tr>
                                <td width="33%">
                                    ${getMeCheckBox('', isCustomer.value, translateKey("client"), '')}
                                </td>
                                <td width="33%">
                                    ${getMeCheckBox('', isRelative.value, translateKey("angehorige"), '')}
                                </td>
                                <td width="33%">
                                    ${getMeCheckBox('', isCarer.value, translateKey("pflegekraft"), '')}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

// function generate28Page(form, translateKey){
//     const moreInformation = form.getFieldByKey("is_additional_fp_comments");

//     return `
//         <div class="form-section-main page_break">
//             <div class="new-section-content-wrapper container form_serializer">
//                 <div class="newUI-section-padding">
//                     <div class="row">
//                         <div class="columnfull">
//                             <div class="new-section-name-wrapper container">
//                                 ${translateKey("form-text-notiz")}                                
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="clearfix"></div>
//                 <div class="newUI-section-padding">
//                     <div class="row">
//                         <div class="row justify-content-md-center">
//                             <table style="width:100%;">
//                                 <tr>
//                                     <th width="100%">
//                                         <input type="text" style="font-size: 1rem;font-weight: 600;line-height: 1.5;color: #495057;width:100%;height:18px;padding: .375rem .75rem;border: 1px solid #ced4da;border-radius: .25rem;"  value='${moreInformation.value}'/>
//                                     </th>
//                                 </tr>
//                             </table>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     `;
// }

function generate29Page(form, translateKey){
    const tileForm = form.getFieldByKey('is_most_important_taking_care_patient');
    const arrayValue = tileForm.value.split(';');
    const priorityOffer = new PriorityOffer();
    const fields = priorityOffer.fields.map((el) => {
        const findKey = Object.values(Tiles).find((tile) => (tile === el.id_deutch || tile === el.id));
        const checked = arrayValue.some((value) => value === findKey)
        return {
            ...el,
            checked,
        }
    });

    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('form-text-aufsummierung')}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <table style="margin:0;padding:0;">
                            <tr>
                                <th style="padding-bottom:15px;">
                                    <span style="font-size: 12px">${translateKey('form-text-aufsummierung-text')}</span>
                                </th>
                            </tr>
                            <tr>
                                ${fields.map((el) => {
                                    return getMeCheckBox('', el.checked, translateKey(el.name), '')
                                })}
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function getMeCheckBox(className, value, text, transKey){
    return `
        <div class="checkbox-button-group-wrapper">
            <div class="custom-control custom-checkbox custom-control-inline newUI-row">
                <input class="form-check-input" ${value===true?"checked":''} type="checkbox" name="${transKey}" id="${transKey}"/>
                ${text && 
                    `<label class'form-check-label newUI-text-color' htmlFor="${transKey}">
                        <div style="padding-left:30px;padding-top:0px">
                            ${text}
                        </div>
                    </label>`
                }
            </div>
        </div>
    `;
}

function getMeInput(label, value){
    return `
        <table style="width:100%;">
            <tr>
                <th width="100%">
                    <span class="rodo-options-span-padding">${label.toUpperCase()}</span>
                </th>
            </tr>
            <tr>
                <th width="100%">
                    <input type="text" style="font-size: 1rem;font-weight: 600;line-height: 1.5;color: #495057;width:80%;height:18px;padding: .375rem .75rem;border: 1px solid #ced4da;border-radius: .25rem;"  value='${value?value:''}'/>
                </th>
            </tr>
        </table>
    `;
}

function getMeRadio(label, element, labelsOption, translateKey, fooValues = (e) => {return e}){
    let values = element.validationRules && element.validationRules.possibleValues;
    values = fooValues(values) || [];

    return `
        <div class="row">
            <div class="columnfull">
                <span class="rodo-options-span-padding">${translateKey(label).toUpperCase()}</span>
            </div>
            <div class="columnfull rodo-description" style="padding-top: 5px;">
                ${values.map((el) => {
                    return `
                        <p class="custom-control-inline newUI-row">
                            <input class="form-check-input" ${element.currentValue===el?"checked":''} type="radio" name="name${el}" id="id${el}"/>
                            ${labelsOption[el] && 
                                `<label class="form-check-label newUI-text-color" style="text-align: center;vertical-align: middle;line-height: 20px;font-weight:400;font-size:10px;padding-left:20px;" htmlFor="${el}">
                                    ${translateKey(labelsOption[el])}
                                </label>`
                            }
                        </p>`
                }).join('')}
            </div>
        </div>

    `;
}

function getMeHorizontalSliders(translateKey, label='', value, minMaxStep={min: 0, max: 5, step: 1}){
    const {min,max,step} = minMaxStep;
    const numbers = Array.from({length: max+1}, (_, i) => i + min).map((e,i) => `${(e+(step-1)*i)}`);
    return `
        <div class="row">
            <div class="columnfull">
                <span class="rodo-options-span-padding">${translateKey(label).toUpperCase()}</span>
            </div>
            <div class="columnfull rodo-description">
                <table style="margin-top: 20px;">
                    <tr>
                        ${numbers.map((el) => {
                            if (el === `${value}`) {
                                return `<th width="40" height="1" style="font-size:4px;text-align: center;border: 1px solid black;background: black;color:black">X</th>`;
                            }
                            return `<th width="40" height="1" style="font-size:4px;text-align: center;border: 1px solid black;color:white;">.</th>`;
                        }).join('')}
                    </tr>
                    <tr>
                        ${numbers.map((el, i) => {
                            if (i+1 === numbers.length) {
                                return `<td style="text-align: center;">${translateKey('pdf-form__more')}</td>`;
                            }
                            return `<td style="text-align: center;">${el}</td>`;
                        }).join('')}
                    </tr> 
                </table>
            </div>
        </div>

    `;
}

function getMeSwitchHorizontal(label, element, translateKey, values, switchLabels){
    let yes, no;
    if (typeof values === 'undefined') {
        yes = '717700000';
        no = '717700001';
    } else {
        yes = values.yes;
        no = values.no;
    }
    let yesLabel, noLabel;
    if (typeof switchLabels === 'undefined') {
        yesLabel = 'yes';
        noLabel = 'no';
    } else {
        yesLabel = switchLabels.yes;
        noLabel = switchLabels.no;
    }

    return `
        <div class="row">
            <div class="columnfull rodo-description">
                <span class="custom-control-inline">
                    <label class="form-check-label newUI-text-color" style="text-align: center;padding: 0 5px;vertical-align: middle;line-height: 20px;font-weight:400;font-size:10px;" htmlFor="${element.value}">
                        ${translateKey(yesLabel)}
                    </label>
                    <input class="form-check-input" ${element.value===yes?"checked":''} type="radio" name="name${element.value}" id="id${element.value}"/>
                </span>
                <span class="custom-control-inline">
                    <input class="form-check-input" ${element.value===no?"checked":''} type="radio" name="name${element.value}" id="id${element.value}"/>
                    <label class="form-check-label newUI-text-color" style="text-align: center;padding: 0 5px;vertical-align: middle;line-height: 20px;font-weight:400;font-size:10px;" htmlFor="${element.value}">
                        ${translateKey(noLabel)}
                    </label>   
                </span>
                <span class="custom-control-inline">
                    <label class="form-check-label newUI-text-color" style="color: black;text-align: center;padding: 0 5px;vertical-align: middle;line-height: 20px;font-weight:600;font-size:10px;">
                        ${translateKey(label).toUpperCase()}
                    </label>  
                </span>
            </div>
        </div>

    `;
}

function getMeSwitch(label, element, translateKey, values, switchLabels){
    let yes, no;
    if (typeof values === 'undefined') {
        yes = '717700000';
        no = '717700001';
    } else {
        yes = values.yes;
        no = values.no;
    }
    let yesLabel, noLabel;
    if (typeof switchLabels === 'undefined') {
        yesLabel = 'yes';
        noLabel = 'no';
    } else {
        yesLabel = switchLabels.yes;
        noLabel = switchLabels.no;
    }

    return `
        <div class="row">
            <div class="columnfull">
                <span class="rodo-options-span-padding">${translateKey(label).toUpperCase()}</span>
            </div>
            <div class="columnfull rodo-description" style="padding-top:5px;">
                <span class="custom-control-inline">
                    <label class="form-check-label newUI-text-color" style="text-align: center;padding: 0 5px;vertical-align: middle;line-height: 20px;font-weight:400;font-size:10px;" htmlFor="${element.value}">
                        ${translateKey(yesLabel)}
                    </label>
                    <input class="form-check-input" ${element.value===yes?"checked":''} type="radio" name="name${element.value}" id="id${element.value}"/>
                </span>
                <span class="custom-control-inline">
                    <input class="form-check-input" ${element.value===no?"checked":''} type="radio" name="name${element.value}" id="id${element.value}"/>
                    <label class="form-check-label newUI-text-color" style="text-align: center;padding: 0 5px;vertical-align: middle;line-height: 20px;font-weight:400;font-size:10px;" htmlFor="${element.value}">
                        ${translateKey(noLabel)}
                    </label>   
                </span>
            </div>
        </div>

    `;
}

function prepareMeRelationText(value, translateKey){
    let text = '';
    switch(value){
        case '717700000': text = translateKey('form-text-relationship-0');break;
        case '717700001': text = translateKey('form-text-relationship-1');break;
        case '717700002': text = translateKey('form-text-relationship-2');break;
        case '717700003': text = translateKey('form-text-relationship-3');break;
        case '717700005': text = translateKey('form-text-relationship-5');break;
        case '717700006': text = translateKey('form-text-relationship-6');break;
        case '717700007': text = translateKey('form-text-relationship-7');break;
        case '717700008': text = translateKey('form-text-relationship-8');break;
        case '717700010': text = translateKey('form-text-relationship-10');break;
        case '717700011': text = translateKey('form-text-relationship-11');break;
        case '717700012': text = translateKey('form-text-relationship-12');break;
        case '717700013': text = translateKey('form-text-relationship-13');break;
        default:
    }
    return text;
}

function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    const d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
}
