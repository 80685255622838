import SortingElement from "./sortingElement";
import * as LanguageSelect from "../../../_constance/values/languageSelect";
const SortingArrivalsList = [
    new SortingElement(
        "DESC",
        'client',
        false,
        "text",
        'client',
        'clients_client',
        true,
        false,
        null
    ),
    new SortingElement(
        "DESC",
        'carerName',
        false,
        "text",
        'client-carer-name',
        'clients_carer',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'trackingStatus',
        false,
        "text",
        'carer-trip-tracking-status',
        'carer_trip_tracking_status',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'dateAndTimeArrival',
        true,
        "date",
        'carer-trip-date-and-time-arrival',
        'carer_trip_date_and_time_arrival',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'tripTrackingLink',
        false,
        "text",
        'arrivals-trip-tracking-link',
        'arrivals_trip_tracking_link',
        false,
        false,
        null
    ),
];
export default class SortingArrivals {
    sortingColumns = [];

    constructor() {
        this.sortingColumns = SortingArrivalsList;
    }

    getColumnByKey(key) {
        return this.sortingColumns.find(column => column.keyName === key);
    }

    getColumnByColumn(columnName) {
        return this.sortingColumns.find(column => column.column === columnName);
    }
    changeColumnByColumn(columnName) {
        this.sortingColumns.forEach(column => {
            if(column.column === columnName){
                if(column.active===true){
                    column.type==='ASC'? column.type="DESC" : column.type='ASC';
                }else{
                    column.type='ASC';
                    column.active = true;
                }
            }else{
                column.type='ASC';
                column.active = false;
            }
        });
    }

    setColumnEnabled (enabled, key){
        for(let i=0; i<this.sortingColumns.length; i++){
            if(this.sortingColumns[i].keyName===key){
                this.sortingColumns[i].enabled = enabled;
                break
            }
        }
    }

    isColumnActive(key){
        return this.sortingColumns.find(column => column.keyName === key && column.acitve === true);
    }

    isColumnVisibleByKey(key, selectedLang) {
        return this.sortingColumns.find(column => {
            if(column.keyName === key && column.blocked === false && column.enabled === true){
                if(column.languageRestrictions!==null){
                    if(selectedLang === column.languageRestrictions){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return true;
                }
            }else{
                return false;
            }
        });
    }
}