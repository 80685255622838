import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { TableColumHead, TablePagination, LoaderTable, EmptyTableIcon, TableDateFormat, ContractStatus} from "../../index";
import { sortingLeadsContracts } from "../../../_constance/classes/sortingClasses/index";
import { Paging,} from "../../../_constance/classes/index";
import { ReqHelper } from "../../../_helpers/index";
import * as FormTypeToShow from '../../../_forms/BBForm/values/formTypeToShow';
import * as LanguageSelect from "../../../_constance/values/languageSelect";
import { baseData } from '../../../_constance/base_data';

class ClientContractData extends React.Component {
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id: props.id,
            loading: true,
            sorting: sortingLeadsContracts,
            pagingItem: Paging,
            retries: 0,
            selectedKey: 0,
            leadId: this.props.leadId
        };
    }

    componentDidMount() {
        document.body.classList.remove('modal-open');
        this.getInitialDataTime();
    }

    componentWillUnmount() {clearTimeout(this.myTimeout);};

    getInitialDataTime(){
        if(this.props.contractsData.length>0){
          clearTimeout(this.myTimeout);
          this.filterOrDataChange();
        }else{
            if(this.state.retries<10){
                this.setState({retries: this.state.retries+1})
                this.myTimeout = setTimeout(() => {
                    this.getInitialDataTime()
                  }, 500);
            }else{
                this.setState({
                    retries: 0,
                    loading: false
                })
            }
        }
    }
    
    filterOrDataChange(){
        let finalDataToShow = this.props.contractsData;
        ReqHelper.sortMeData(finalDataToShow, this.state.sorting);
        this.propsPagingData(finalDataToShow);
        setTimeout(() => {
            let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
            this.setState({
                data: finalData,
                loading: false
            })
        }, 50);
    }
    
    propsSortType(type){
        let sortingObj = [];
        for(let i=0; i<this.state.sorting.length; i++){
            if(this.state.sorting[i].column===type){
            if(this.state.sorting[i].active===true){
                let item = this.state.sorting[i];
                item.type==='ASC'? item.type="DESC" : item.type='ASC';
                sortingObj.push(item);
            }else{
                let item = this.state.sorting[i];
                item.type='ASC';
                item.active = true;
                sortingObj.push(item);
            }
            }else{
            let item = this.state.sorting[i];
            item.type='ASC';
            item.active = false;
            sortingObj.push(item);
            }
        }
        this.setState({sorting: sortingObj});
        this.filterOrDataChange();
    }

    propsPagingData(data){
        if(data.length<=25){
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                totalItems: data.length,
                itemsPerPage: prevState.pagingItem.itemsPerPage,
                currentPage: 1,
                visiblePages: 5,
                pageFrom: 1,
                pageTo: 5,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }else{
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                ...prevState.pagingItem,
                totalItems: data.length,
                pageFrom: prevState.pagingItem.pageFrom,
                pageTo: prevState.pagingItem.pageTo,
                currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }
    }

    getMeFirstPatientID = () =>{
        const { patientLeadData, match: { params } } = this.props;
        let pushedElements = [];
        patientLeadData.filter(element => {
            if(element.leadId === params.leadId){
                if(pushedElements.indexOf(element.id) > -1){
                    return false;
                }else{
                    pushedElements.push(element.id);
                    return element
                }
            }else{
              return false;
            }
        })
        if(pushedElements.length>0){
          return pushedElements[0];
        }else{
          return 0;
        }
    }

    openFullscreen() {
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
          elem.msRequestFullscreen();
        }
    }

    navigateToContractsDetails(element){
        if(this.props.appLang === LanguageSelect.EN){
            // TODO add DETAILS IN ENGLISH VERSION
        }else{
            const { match: { params }, history } = this.props;
            const patientId = this.getMeFirstPatientID();
            if (!document.fullscreenElement) {
                this.openFullscreen();
            }
            history.push(`${baseData.subFolders}bb-form-lead/${params.leadId}/${patientId}/${element.id}/${FormTypeToShow.ENQUIRY}`);
        }
    }

    render() {
        const { data, sorting } = this.state;
        const { translateKey } = this.props;
        let emptyHandler;
        let contractsData = [];
        if (this.state.loading) {
            emptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
        }else{
            if (data.length === 0) {
                emptyHandler = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.RefreshMe()} text={translateKey("no_data_to_show")} /></div>
            }else{
                for(let j=0; j<data.length; j++){
                    contractsData.push(
                        <tr className="table-row-wrapper cursor-pointer" key={data[j].id}  onClick={()=>{this.navigateToContractsDetails(data[j])}}>
                            <td className={"table-cell-wrapper " + (data[j].contract !== null ? "got-data" : 'no-data')}>{data[j].contract}</td>
                            <td className={"table-cell-wrapper " + (data[j].status !== null ? "got-data" : 'no-data')}><ContractStatus element={data[j].status}/></td>
                            <td className={"table-cell-wrapper " + (data[j].product !== null ? "got-data" : 'no-data')}>{data[j].product}</td>
                            <td className={"table-cell-wrapper " + (data[j].rate !== null ? "got-data" : 'no-data')}>{ReqHelper.numberWithSpaces(data[j].rate) + ' ' + data[j].currency}</td>
                            <td className={"table-cell-wrapper " + (data[j].inquiryReceiptDate !== null ? "got-data" : 'no-data')}><TableDateFormat timestamp={data[j].inquiryReceiptDate}/></td>
                        </tr>
                     )
                }
            }
        }
        return (
            <div className="client-data-wrapper-tab">
                <div className="listData">
                    <table className="table">
                        <thead>
                            <tr>
                                <TableColumHead sorting={sorting} name={translateKey("contracts")} onSortType={(data) => this.propsSortType(data)} type="contract" />
                                <TableColumHead sorting={sorting} name={translateKey("status")} onSortType={(data) => this.propsSortType(data)} type="status" />
                                <TableColumHead sorting={sorting} name={translateKey("product")} onSortType={(data) => this.propsSortType(data)} type="product" />
                                <TableColumHead sorting={sorting} name={translateKey("rate")} onSortType={(data) => this.propsSortType(data)} type="rate" />
                                <TableColumHead sorting={sorting} name={translateKey("date")} onSortType={(data) => this.propsSortType(data)} type="inquiryReceiptDate" />
                            </tr>
                        </thead>
                        <tbody>{contractsData}</tbody>
                    </table>
                    {emptyHandler}
                    <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    appLang: state.lang.appLanguage,
    userId: state.auth.userId,
    patientLeadData: state.database.patientLead
}};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientContractData));