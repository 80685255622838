import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { 
  translateKey, 
  authSetActionTimestamp, 
  removeLeadStatusData, 
  getLeadStatusData, 
  addLeadData, 
  getPatientLead,
  needsSaveData, 
  doesNotNeedsSave
} from "../../_redux/actions/index";
import { 
  TableProperties, 
  NavBar, 
  SideBar, 
  LoaderTable, 
  LeadDetailsBasicComponent, 
  LeadClientComponent, 
  LeadPatientComponent, 
  LeadCancelSaveComponent,
  ConsentListComponent,
  AlertModalInfo
} from "../../_components/index";
import { Alert} from "../../_constance/classes/index";
import { IconsMap, ReqHelper } from "../../_helpers/index";
import { leadSave } from "../../_redux/actions/auth";
import { MockLeadData } from "../../_constance/classes/mockLead/mockLeadData";
import { saveDynamicFields } from '../../_services/user_service';
import * as Entity from '../../_forms/BBForm/values/entity';
import * as LanguageSelect from "../../_constance/values/languageSelect";
import { baseData } from '../../_constance/base_data';
import { getRegionsFP } from "../../_redux/actions/bb-form-services";
import { sendErrorLogToBackend } from '../../_services/backend_service';
import { saveStats } from "../../_services/backend_service";
import { StatsType } from "../../_constance/enums/statsType";
const contractCancellationStatus = '717700000';

class NewLeadPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  autoSaveTimeOut;
  constructor(props) {
    super(props);
    this.state = {
      multiId: 0,
      activeTab: "lead",
      leadData: {...MockLeadData},
      savingDataLead: false,
      errorComponent: Alert,
      loadingLeads: true,
      showValidation: false,
      initMeAgain: false,
      consentsReady: false,
      regionsFP: [],
      serviceTypeDe: "717700002",
      showCareBoxTab: false,
      showPatientTab: true,
      dynamics:{ is_degree_relationship_with_patient_carer: null },
      dynamicsTemp:{ is_degree_relationship_with_patient_carer: null },
      breadCrumbs:[
        {
          link: "leads",
          key: "leads",
          name: null
        },
        {
          link: "newlead",
          key: "new_lead",
          name: null
        }
      ]
    };
  }

  componentDidUpdate() {
    if (this.props.needsSave) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.props.doesNotNeedsSave();
    this.getMeRegions()
    if (this._isMounted) {
      this.setState({
        loadingLeads: false,
        leadData: JSON.parse(JSON.stringify(MockLeadData))
      })
    }
    this.autoSaveTimeOut = setInterval(() => {this.checkIfShouldWeSave();}, 15000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.onbeforeunload = null
    if(this.props.needsSave){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              leadData: JSON.parse(JSON.stringify(MockLeadData))
          }
        });
      }
    }
    clearInterval(this.autoSaveTimeOut);
    clearTimeout(this.myTimeout);
  };

  getMeRegions(){
    this.props.getRegions(this.props.appLang).then((data)=>{
      if (this._isMounted) {
        this.setState({regionsFP: data});
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  changeMultiValue = (value) =>{
    if (this._isMounted) {
      this.setState({multiId: value})
    }
    if(value!==0){this.basicEdit();}
  }

  checkIfShouldWeSave = () =>{
    const currentTime = new Date().getTime();
    if(this.props.lastActionTime - currentTime <= 30000){
      this.saveChange(true);
    }
  }

  cancelChange(){
    this.props.doesNotNeedsSave();
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            leadData: JSON.parse(JSON.stringify(MockLeadData)),
            showValidation:false,
            initMeAgain: true
        }
      });
    }
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({initMeAgain: false})
      }
    }, 50);
  }

  checkRequiredFields(){
    const { leadData, multiId, showCareBoxTab } = this.state;
    let allRequiredFilled = true;
    if (showCareBoxTab === true) {
      if (leadData.statusCareBox === "" || leadData.statusCareBox === 0 || leadData.statusCareBox === null) {
        allRequiredFilled = false;
      }
    } else {
      if (leadData.status === "" || leadData.status === 0 || leadData.status === null) {
        allRequiredFilled = false;
      }
    }
    if(!ReqHelper.checkNotNullAndNotEmptyString(leadData.client.firstName) || !ReqHelper.checkNotNullAndNotEmptyString(leadData.client.lastName)){
      allRequiredFilled = false;
    }
    if(!ReqHelper.checkNotNullAndNotEmptyString(leadData.patientObj.firstName) || !ReqHelper.checkNotNullAndNotEmptyString(leadData.patientObj.lastName)){
      allRequiredFilled = false;
    }
    if(showCareBoxTab === false && this.state.consentsReady===false){
      allRequiredFilled = false;
    }
    if (`${leadData.status}` === contractCancellationStatus && !leadData.reasonForRejection) {
      allRequiredFilled = false;
    }
    if ((`${multiId}` === "" || multiId === 0 || multiId===null) && (leadData.source === 'F2557264-4978-E811-80D7-00155D00F809' || leadData.source === '2669ACCE-A798-E911-80D1-00155D140163' )) {
      allRequiredFilled = false;
    }
    return allRequiredFilled;
  }

  tryToSave(leadId, shouldLogout=false){
    const { history } = this.props;
    const { multiId, dynamics,dynamicsTemp, leadData, consentsReady } = this.state
    let field = {"is_zg_de_1": consentsReady}
    if(this.props.appLang === LanguageSelect.EN){
      field = {"is_zg_uk_1": consentsReady}
    }
    if(leadData.source!==null && leadData.source === baseData.multiToLeadSelection){
      if(multiId!==0){
        field['is_contact_recomendedid'] = multiId;
      }
    }
    if(dynamicsTemp.is_degree_relationship_with_patient_carer !== dynamics.is_degree_relationship_with_patient_carer){
      field.is_degree_relationship_with_patient_carer = dynamics.is_degree_relationship_with_patient_carer;
    }
    field.is_contact_source = '717700003';
    if (this.props.appLang === LanguageSelect.DE) {
      field.is_service_type_de = this.state.serviceTypeDe;
      field.is_lead_status_care_box = leadData.statusCareBox;
      field.is_reason_for_rejection_care_box = leadData.careBoxRejectionReason;
    }
    let dataToSend = [
        {
            "Id": leadId,
            "Entity": Entity.CONTACT,
            "Fields": field
        }
    ];
    saveDynamicFields(dataToSend).then(response => {
      this.props.doesNotNeedsSave();
      if(shouldLogout){
        history.push(`${baseData.subFolders}logout`);
      }else{
        this.props.getPatientLeadData(this.props.appLang);
        if (this._isMounted) {
          this.setState({
            savingDataLead: false,
            errorComponent: {
              show: true,
              type: "success",
              message: "lead_successfully_saved"
            },
            showValidation:false
          })     
        } 
        clearTimeout(this.myTimeout);
        this.myTimeout = setTimeout(() => {
          this.hideAlertModalComponent();
          history.push(`${baseData.subFolders}leads/?type=dateOfEntry`);
          history.push(`${baseData.subFolders}leadDetails/${leadId}`);
        }, 3000);
        this.props.addLeadDataFun(leadId,this.state.leadData);
      }
    })
    .catch((err) => {
      if(shouldLogout){
        history.push(`${baseData.subFolders}logout`);
      }else{
        const { fpName, LoginDS } = this.props;
        const errorContent = {
          fpName: fpName,
          loginDS: LoginDS,
          page: "LEAD SAVE DYNAMIC",
          api: "dynamic/send",
          content: dataToSend,
          error: err
        }
        sendErrorLogToBackend(errorContent).then(res => {console.log(res)}).catch((error) => {console.log(error);});
        if (this._isMounted) {
          this.setState(prevState => {
            return {
                ...prevState,
                savingDataLead: false,
                errorComponent: {
                  show: true,
                  type: "danger",
                  message: err.message
                }
            }
          });
        }
        clearTimeout(this.myTimeout);
        this.myTimeout = setTimeout(() => { this.hideAlertModalComponent(); }, 3000);
      }
    });
  }

  hideAlertModalComponent(){
    if (this._isMounted) {
      this.setState({errorComponent: {show: false}})
    }
    document.body.classList.remove('modal-open');
  }

  saveStatistics(leadData) {
    const { fpName, userId, fpFirstMail } = this.props;
    const statsData = {
      type: StatsType.NEW_LEAD,
      data: {
        userId: userId,
        fpName: fpName,
        fpFirstMail: fpFirstMail,
        leadId: leadData.leadId,
        firstName: leadData.client.firstName,
        lastName: leadData.client.lastName,
      }
    }
    saveStats(statsData)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            console.log(res.data.data);
          }
        }else{
          console.log("Unable to save data to Statistics");
        }
      }
    })
    .catch((error) => {
      console.log("Unable to save data to Statistics");
    });
  }

  saveChange(shouldLogout=false){
    const { history, tookAction, needsSave, appLang, saveLeadData} = this.props;
    tookAction();
    if(needsSave){
      if(this.checkRequiredFields()){
        if(this._isMounted) {this.setState({savingDataLead: true})}
        saveLeadData(this.state.leadData, appLang)
        .then((res)=>{
          if (this._isMounted) {
            this.setState(prevState => {
              return {
                  ...prevState,
                  leadData: {
                    ...prevState.leadData,
                    leadId: res
                  }
              }
            });
          }
          this.tryToSave(res, shouldLogout);
          this.saveStatistics(this.state.leadData);
        })
        .catch((err)=>{
          if(shouldLogout){
            history.push(`${baseData.subFolders}logout`);
          }else{
            const { fpName, LoginDS } = this.props;
            const errorContent = {
              fpName: fpName,
              loginDS: LoginDS,
              page: "LEAD SAVE BASIC",
              api: "leads/save",
              content: this.state.leadData,
              error: err
            }
            sendErrorLogToBackend(errorContent).then(res => {console.log(res)}).catch((error) => {console.log(error);});
            if (this._isMounted) {
              this.setState(prevState => {
                return {
                    ...prevState,
                    savingDataLead: false,
                    errorComponent: {
                      show: true,
                      type: "danger",
                      message: err.message
                    }
                }
              });
            }
            clearTimeout(this.myTimeout);
            this.myTimeout = setTimeout(() => { this.hideAlertModalComponent(); }, 3000);
          }
        })
      }else{
        if(shouldLogout){
          history.push(`${baseData.subFolders}logout`);
        }else{
          if (this._isMounted) {
            this.setState(prevState => {
              return {
                  ...prevState,
                  showValidation: true,
                  errorComponent: {
                    show: true,
                    type: "danger",
                    message: 'some-mandatory-data-are-missing'
                  }
              }
            });
          }
          clearTimeout(this.myTimeout);
          this.myTimeout = setTimeout(() => { this.hideAlertModalComponent(); }, 3000);
        }
      }
    }
  }

  setConsentsReady = (value) =>{
    if(value !== null ){
      if (this._isMounted) {
        this.setState({consentsReady: value})
      }
    }else{
      if (this._isMounted) {
        this.setState({consentsReady: false})
      }
    }
  }

  basicEdit = () =>{this.props.needsSaveData();}

  handleServiceTypeDeChange = (value) => {
    this.configureTabs(value)
  }

  configureTabs(serviceType) {
    if (this._isMounted) {
      this.setState({
        serviceTypeDe: serviceType,
        showCareBoxTab: serviceType === '717700000',
        showPatientTab: serviceType !== '717700000'
      });
    }
  }

  render() {
    const { translateKey, needsSave, appLang } = this.props;
    const { dynamics, showValidation, multiId, leadData, showCareBoxTab } = this.state;
    let leadsEmptyHandler;
    if (this.state.loadingLeads) {
      leadsEmptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      let FirstRow =(
        <LeadDetailsBasicComponent 
          dynamics={dynamics} 
          multiId={multiId} 
          editedMulti={this.changeMultiValue} 
          editedData={this.basicEdit} 
          showValidation={showValidation} 
          edited={needsSave} 
          key="newleadDetailsComponent03" 
          element={leadData}
          serviceTypeDe={this.state.serviceTypeDe}
          careBoxRejectionReason={this.state.careBoxRejectionReason}
          isCareBoxEnabled={this.state.showCareBoxTab}
          onServiceTypeDeChange={this.handleServiceTypeDeChange}
        />
      )
      let ClientRow =(
        <LeadClientComponent dynamics={dynamics} editedData={this.basicEdit} showValidation={showValidation} edited={needsSave} key="newleadClientComponent03" element={leadData}/>
      )
      let PatientRow =(
        <LeadPatientComponent regionsFP={this.state.regionsFP} editedData={this.basicEdit} showValidation={showValidation} edited={needsSave} key="newleadPatientComponent03" element={leadData}/>
      )
      let Consents =(
        <ConsentListComponent setConsents={this.setConsentsReady} initMeAgain={this.state.initMeAgain} editedData={this.basicEdit} showValidation={showValidation} isEditable={true}  key="consents-lead-new-03" id={leadData.leadId}/>
      )
      let CancelSave = (
        <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="newleadCancelSaveComponent03" edited={needsSave} savingData={this.state.savingDataLead}/>
      );
      const { errorComponent } = this.state;
      let SaveAlert = (
        <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={this.props.translateKey(errorComponent.message)}/>
      )
      if(showCareBoxTab && appLang === LanguageSelect.DE) {
        Consents = ""
      }
      leadsEmptyHandler = [
        FirstRow,
        ClientRow,
        PatientRow,
        Consents,
        SaveAlert,
        CancelSave
      ]
    }

    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]} filterData={[]} sortData={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper transparentClass">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active nav-link-invoices" id="nav-details-tab" data-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true">
                {translateKey("lead")}
              </a>
            </div>
          </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                <div className="leadSection">
                  {leadsEmptyHandler}
                </div>
              </div>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
        <Prompt
            when={needsSave}
            message={translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    leadsStatus: state.enums.leadStatus,
    leadsData: state.database.leads,
    leadsSourceStatus: state.enums.leadSource,
    needsSave: state.refreshTokenStatus.needsDataSave,
    lastActionTime: state.auth.actionTimestamp,
    fpName: state.auth.contactFpName,
    fpFirstMail: state.auth.contactFpFirstEmail,
    LoginDS: state.auth.LoginDS,
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  getStatusLead: (lang) => dispatch(getLeadStatusData(lang)),
  getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
  removeLeadsStatus: () => dispatch(removeLeadStatusData()),
  saveLeadData: (data, lang) => dispatch(leadSave(data, lang)),
  addLeadDataFun: (leadId, data) => dispatch(addLeadData(leadId, data)),
  needsSaveData: () => dispatch(needsSaveData()),
  doesNotNeedsSave: () => dispatch(doesNotNeedsSave()),
  getRegions: (lang) => dispatch(getRegionsFP(lang))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewLeadPage));