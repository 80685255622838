import axiosAuth from './config/axios-auth';
import ReqHelper from '../_helpers/request_helper';
import * as Entity from "../_forms/BBForm/values/entity";

class BBFormService {
    savePatient(data) {
        // axiosAuth.post('/dynamic/retrieve', [{
        //     id: "0ba28e06-bbae-e911-80da-00155d00f809",
        //     entity: Entity.CONTACT,
        //     fields: [
        //         'firstname',
        //         'lastname',
        //         'is_mobile_phone',
        //     ],
        // }]).then(resp => console.log('r', resp))
        return axiosAuth.post('/dynamic/send', data)
        .catch(error => {
            if(error.code){
              return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
            } else if (typeof error === 'number'){
              return Promise.reject(ReqHelper.responseErrorHelper(error));
            } else {
              return Promise.reject(ReqHelper.responseErrorHelper(801));
            }
        });;
    }

    retrievePatient(data) {
        return axiosAuth.post('/dynamic/retrieve', data);
    }

    assignPatients(data) {
        return axiosAuth.post('contracts/assignPatients', data)
    }

    saveRodoSignature(id, content) {
        let dataToSend = {
          "OwnerId": id,
          "OwnerType": "contact",
          "Note": "Podpis klienta - zgody RODO",
          "Attachment":
          { "Filename": "Podpis.png", "Content": content }
        }
        return axiosAuth.post('notes/addWithAttachment', dataToSend);
    }

    cancelContract(id){
        let dataToSend = [
            {
                Id: id,
                Entity: Entity.CONTACT,
                Fields: { 
                    "is_customer_status_de": "717700005",
                    "is_reasons_for_rejection_details": "Der Kunde hat nach der Vorstellung des Angebots verzichtet- das Fragebogen wurde gesendet.", 
                }
            }
        ]
        return axiosAuth.post('/dynamic/send', dataToSend);
    }

    getCareBoxPflegebox(patientId) {
        return axiosAuth.get('/patient/careBoxApps/' + patientId, null);
    }

    createEmptyIngredients(careBoxId, contactId, userId, name){
        let dataToSend = [
            {
                Id: "",
                Entity: Entity.CARE_BOX_INGRIDIENTS,
                Fields: {
                    "is_contactid": contactId,
                    "is_name": name,
                    "is_care_box_appid": careBoxId,
                    "ownerid": userId,
                    "is_month": this._getMonthCareBox()
                }
            }
        ]
        return axiosAuth.post('/dynamic/send', dataToSend);
    }

    _getMonthCareBox() {
        const date = new Date();
        const month = date.getMonth();
        let monthCase = 717700000;
        return monthCase + month;
    }
}

export default new BBFormService();