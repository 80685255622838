import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey, setPriceType } from '../../../../_redux/actions/index';
import { CheckBoxComponent } from '../../../form-bb-components/checkbox-component/checkbox-component';
import { PriceType } from '../../../../_constance/enums/priceType';

class Section1 extends React.Component {
  constructor(props) {
    super(props);
    this.showComponent = this.showComponent.bind(this);
    this.state = {
      sectionList: this.props.sectionList,
      activeSection: this.props.activeSection,
      idComponent: false,
      priceTypeToSet: this.props.priceType,
    };
  }

  changeSectionPage(number) {
    this.props.changeSection(number);
  }

  showComponent(idComponent) {
    this.props.showComponent(idComponent);
  }

  setTypeChange = (key, value) => {
    this.setState({priceTypeToSet: key});
  }

  setPriceType = () => {
    const { priceTypeToSet } = this.state;

    this.props.setPriceType(priceTypeToSet);
    this.props.toogleMenu();
  }

  render() {
    const { priceTypeToSet } = this.state;
    const { translateKey } = this.props;
    return (
      <div className="newUI-section-padding new-menu__section-row ">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="new-menu__section-title">
                {translateKey("new-hamburger-menu_show-prices-as").toUpperCase()}
            </div>
          </div>
        </div>
        <div className="row">
        <CheckBoxComponent
            newUI={ true }
            checkClassWrapper='newUI-radio-check-box-class'
            showValidation={ false }
            className='col-sm-12 col-lg-12'
            setTypeChangeToParent={ this.setTypeChange }
            value={ priceTypeToSet === PriceType.MONTHLY ? true : false }
            validationErrors={ false }
            text={ translateKey("new-hamburger-menu_monthly-rate") }
            transKey="new-hamburger-menu_monthly-rate"
            keyName={ PriceType.MONTHLY }
          />
          <CheckBoxComponent
            newUI={ true }
            checkClassWrapper='newUI-radio-check-box-class'
            showValidation={ false }
            className='col-sm-12 col-lg-12'
            setTypeChangeToParent={ this.setTypeChange }
            value={ priceTypeToSet === PriceType.DAILY ? true : false }
            validationErrors={ false }
            text={ translateKey("new-hamburger-menu_daily-rate") }
            transKey="new-hamburger-menu_daily-rate"
            keyName={ PriceType.DAILY }
          />
          <CheckBoxComponent
            newUI={ true }
            checkClassWrapper='newUI-radio-check-box-class'
            showValidation={ false }
            className='col-sm-12 col-lg-12'
            setTypeChangeToParent={ this.setTypeChange }
            value={ priceTypeToSet === PriceType.NONE ? true : false }
            validationErrors={ false }
            text={ translateKey("new-hamburger-menu_none-rate") }
            transKey="new-hamburger-menu_none-rate"
            keyName={ PriceType.NONE }
          />
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <button 
                type="button" 
                className="btn btn-success button-order"
                onClick={() => this.setPriceType()} 
                >
                    {translateKey("new-hamburger-menu_save").toUpperCase()}
                <i className="fas fa-chevron-right summary-offer-icon" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    priceType: state.priceType.priceType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  setPriceType: type => dispatch(setPriceType(type)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section1));
