export const UsesApp = [
    {
        Label: 'Ja',
        Value: "717700000",
        Hidden: true,
    },
    {
        Label: 'Nein',
        Value: "717700001",
        Hidden: true,
    },
    {
        Label: 'Kein Interesse an der App',
        Value: "717700002",
        Hidden: false,
    }
]