export const ADDITIONAL_OPTIONS = {
  OPTION_LICENSE: "fee_licence",
  OPTION_DIPLOMA: "fee_diploma",
  OPTION_2NDPAT: "fee_2ndpat",
  OPTION_2NDLANG: "fee_2ndlang",
  OPTION_PLUS: "fee_plus",
  OPTION_ADDPERSON: "fee_addperson",
}

const arrayFromNumber = (number) => [...Array(number).keys()];

export const ADDITIONAL_OPTIONS_DETAILS = {
  [ADDITIONAL_OPTIONS.OPTION_LICENSE]: {
    tooltip: "summary-offer-additional-option-1-tooltip",
    listItemKeys: arrayFromNumber(2).map(i => `summary-offer-additional-option-1-item-${i}`),
  },
  [ADDITIONAL_OPTIONS.OPTION_DIPLOMA]: {
    tooltip: "summary-offer-additional-option-2-tooltip",
    listItemKeys: arrayFromNumber(1).map(i => `summary-offer-additional-option-2-item-${i}`),
  },
  [ADDITIONAL_OPTIONS.OPTION_2NDPAT]: {
    tooltip: "summary-offer-additional-option-3-tooltip",
    listItemKeys: arrayFromNumber(2).map(i => `summary-offer-additional-option-3-item-${i}`),
  },
  [ADDITIONAL_OPTIONS.OPTION_2NDLANG]: {
    tooltip: "summary-offer-additional-option-4-tooltip",
    listItemKeys: arrayFromNumber(1).map(i => `summary-offer-additional-option-4-item-${i}`),
  },
  [ADDITIONAL_OPTIONS.OPTION_PLUS]: {
    tooltip: "summary-offer-additional-option-5-tooltip",
    listItemKeys: arrayFromNumber(5).map(i => `summary-offer-additional-option-5-item-${i}`),
  },
  [ADDITIONAL_OPTIONS.OPTION_ADDPERSON]: {
    tooltip: "summary-offer-additional-option-5-tooltip",
    listItemKeys: arrayFromNumber(1).map(i => `summary-offer-additional-option-6-item-${i}`),
  }
}

export const getAdditionalOptionDetails = (id) => {
  return ADDITIONAL_OPTIONS_DETAILS[id];
}

export const getOptionTooltip = (id) => {
  return ADDITIONAL_OPTIONS_DETAILS[id].tooltip;
}