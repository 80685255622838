import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../_redux/actions/index";

class NotizComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notiz: this.props.category.Note,
    };
  }

  noteChange=(value)=>{
    this.props.changeCateogryNote(value);
    this.setState({notiz: this.props.category.Note})
  }

  render() {
    const { translateKey } = this.props;
    return (
      <div className="multiplikator-notiz">
        <div className="multiplikator-textarea-inner-div">
          <label className='form-check-label newUI-text-color'>
              <div className='quick-label-position-fix'>
                  {translateKey("ko-piloten-notiz-title")}
              </div>
          </label>
          <textarea
            className="form-control multiplikator-notiz-textarea"
            rows="5"
            style={{resize: 'none'}}
            placeholder={translateKey("ko-piloten-notiz-placeholder")}
            onChange={e => this.noteChange(e.target.value)}
            value={this.props.category.Note?this.props.category.Note:''}
          >
          </textarea>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({translateKey: (firstLvl, secondLvl) =>dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(null, mapDispatchToProps)(NotizComponent));