export const SortingBoxes = [
    {
        type: "ASC",
        column: 'is_status',
        active: false,
        format: "text"
    },
    {
        type: "ASC",
        column: 'is_care_box_shipment_date',
        active: false,
        format: "text"
    },
    {
        type: "ASC",
        column: 'is_delivery_date',
        active: false,
        format: "text"
    },
    {
        type: "ASC",
        column: 'is_delivery_to_address',
        active: false,
        format: "text"
    }
];