import * as React from 'react';

export class EmptyTableIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text,
        };
    }

    render() {
        const {text} = this.props;
        return (
            <div className="empty-table-wrapper">
                <i onClick={()=>this.props.RefreshMe()} className="fas fa-sync-alt fa-4x empty-table-icon cursor-pointer"></i>
                <div className="empty-table-text">{text}</div>
            </div>
        );
    }
}