import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";
import { InputTypeTextComponent } from "../../../form-bb-components/input-type-text-component/input-type-text-component";
import produce from "immer";
import ListComponent from "../../../form-bb-components/list-component/list-component";
import "../SectionDieBestellung.css";

class SectionDieBestellungSecond extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      section: props.contractForm.getSectionByKey('contract-section-second'),
    }
  }

  componentDidMount() {  
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  };

  setTypeChange = (key, value) => {
    const nextState = produce(this.state, draftState => {
      const { section } = draftState;
      const field = section.getFieldByKey(key);
      if (field) {
          field.value = value;
      }
      this.setState(nextState);
      this.setState({ rerender: Date.now() });
    });
  };

  setTypeChangeAndSetEmail = (key, value) => {
    const nextState = produce(this.state, draftState => {
      const { section } = draftState;
      const { form } = this.props;
      const field = section.getFieldByKey(key);
      if (field) {
          field.value = value;
      }
      const isEmail = section.getFieldByKey('is_payer_email');
      if (isEmail) {
        switch(value){
          case '717700000':   isEmail.value = '';
                              break;
          case '717700001':   isEmail.value = this.resolvePatientEmail(this.props.clientLeadBaseData);
                              break;
          case '717700002':   isEmail.value = form.getFieldByKey('is_email').value;
                              break;
          default:
        }
      }
      this.setState(nextState);
      this.setState({ rerender: Date.now() });
    });
  };

  resolvePatientEmail(clientData) {
    return clientData.patient !== undefined ? clientData.patient.email : '';
  }

  prepareMeCitySelect = () =>{
    if(this.props.cities.length>0){
        return this.props.cities.map(x => ({
            value: x.PostalCode!==null?x.PostalCode:'', 
            city:  x.City!==null?x.City:'',
            label:  x.City!==null?x.PostalCode+"-"+x.City:''
        }));
    }else{
        return [];
    }
}

  render() {
    const { translateKey, showValidationErrors, form } = this.props;
    const { section } = this.state;
    const isIdenticalPayerData = section.getFieldByKey('is_identical_payer_data');
    const isPayerEmail = section.getFieldByKey('is_payer_email');

    return (
      <form className="new-section-content-wrapper container form_serializer">
        <fieldset disabled={false} className={`${false ? "no-pointer-events" : ""}`}>
          <div className="newUI-section-padding">
            <div className="row">
              <ListComponent
                newUI={true}
                setTypeChangeToParent={this.setTypeChangeAndSetEmail}
                showValidation={showValidationErrors}
                className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                transKey="die-bestellung-form-label-wer-ist-der-rechnungszachler"
                key="is_identical_payer_data"
                keyName="is_identical_payer_data"
                value={isIdenticalPayerData.value}
                validationErrors={isIdenticalPayerData.validationErrors}
                valuesList={[
                  { value: '717700002', label: translateKey("die-bestellung-form-label-kunde") },
                  { value: '717700001', label: translateKey("die-bestellung-form-label-patient") },
                ]}
              />
            </div>
          </div>
          <div className="newUI-section-padding">
            <div className="row">
              <InputTypeTextComponent
                newUI={true}
                showValidation={showValidationErrors}
                className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                setTypeChangeToParent={this.setTypeChange}
                field={isPayerEmail}
                text={translateKey("die-bestellung-form-label-email")}
                transKey="first_name"
                keyName="is_payer_email"
                disabled={false}
              />
            </div>
          </div>
        </fieldset>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) })
export default withRouter(connect(null, mapDispatchToProps)(SectionDieBestellungSecond));