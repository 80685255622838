import * as MultiplierStatus from "../../_constance/enums/MultiplierStatus";
export default class KoPilotenCategory {
    MultiplierRangeId = null;
    Name = '';
    CategoryId = null;
    ClientId = null;
    Note = null;
    MultiCanBeSelected = [];
    MultiplierIds = [];
    SubCategoriesKeyList =[];
    SubCategoriesList=[];
    IsSubCategory = false;

    constructor(MultiplierRangeId, Name, CategoryId, ClientId, Note, MultiCanBeSelected, MultiplierIds, SubCategoriesKeyList, SubCategoriesList, IsSubCategory) {
        this.MultiplierRangeId = MultiplierRangeId;
        this.Name = Name;
        this.CategoryId = CategoryId;
        this.ClientId = ClientId;
        this.Note = Note;
        this.MultiCanBeSelected = MultiCanBeSelected;
        this.MultiplierIds = MultiplierIds;
        this.SubCategoriesKeyList = SubCategoriesKeyList;
        this.SubCategoriesList = SubCategoriesList;
        this.IsSubCategory = IsSubCategory;
    }

    gotMultipliers(multipliersList) {
        if(multipliersList){
            if(multipliersList.length>0){
                if(this.MultiplierIds.length>0){
                    let gotSomeMultiWithGoodStatus = false;
                    for (let index = 0; index < this.MultiplierIds.length; index++) {
                        const selectedMulti = multipliersList.find(multi => multi.id.toUpperCase()=== this.MultiplierIds[index])
                        if(selectedMulti){
                            if(selectedMulti.statusMultiplier){
                                if(selectedMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                                    let exists = this.MultiCanBeSelected.indexOf(selectedMulti.id.toUpperCase())
                                    if(exists>-1){
                                        gotSomeMultiWithGoodStatus = true;
                                        break;
                                    }
                                }
                            }else{
                                let exists = this.MultiCanBeSelected.indexOf(selectedMulti.id.toUpperCase())
                                if(exists>-1){
                                    gotSomeMultiWithGoodStatus = true;
                                    break;
                                }
                            }
                        }
                    }
                    return gotSomeMultiWithGoodStatus;
                }
            }
        }
        return false
    }

    get gotMultipliersToSelect() {
        return this.MultiCanBeSelected.length>0;
    }

    set completeData ({MultiplierRangeId, Name, CategoryId, ClientId, Note, MultiCanBeSelected, MultiplierIds, SubCategoriesKeyList, SubCategoriesList, IsSubCategory}={}){
        this.MultiplierRangeId = MultiplierRangeId;
        this.Name = Name;
        this.CategoryId = CategoryId;
        this.ClientId = ClientId;
        this.Note = Note;
        this.MultiCanBeSelected = MultiCanBeSelected;
        this.MultiplierIds = MultiplierIds;
        this.SubCategoriesKeyList = SubCategoriesKeyList;
        this.SubCategoriesList = SubCategoriesList;
        this.IsSubCategory = IsSubCategory;
    }

    assignSubCategory(SubCategory){
        this.SubCategoriesList.push(SubCategory);
    }

    clearSubCategory(){
        this.SubCategoriesList = [];
    }
}
