import { MockAddress } from './mockAddress'
export const MockMultiplier = {
    address: {...MockAddress},
    birthDate: '',
    cellPhone: '',
    multiplier: '',
    company: '',
    location: '',
    phone: '',
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    nextContact: '',
    profession: '',
    businessPhone: '',
    careAmbasador: false,
    emailAddress2: '',
    gender: '',
    marketingId: '',
    middleName: '',
    phoneOtherRemarks: '',
    salutationAddress: '',
    salutationLetter: '',
    statusMultiplier: '',
    multiplierQuality: '',
    market: 717700000,
};