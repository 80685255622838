import DnDPriorityOffer from './DnDPriorityOffer';
import DnDPriorityOfferElement from './DnDPriorityOfferElement';
import { IconsMap } from "../../../_helpers/icons_set";

export default class PriorityOffer extends DnDPriorityOffer {
    constructor() {
        super({
            fields: [
                new DnDPriorityOfferElement({
                    id: "Szybkie rozpoczęcie opieki",
                    id_deutch: "Den Vertrag sofort starten",
                    posibileKeys: ['Szybkie rozpoczęcie opieki', 'Den Vertrag sofort starten'],
                    name: "from-text-important-starten",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.clock,
                    iconActive: IconsMap.svg.zusammenfassung.clock_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Opieka nad osobą z demencja",
                    id_deutch: "Unterstützung eines Betreuungsbedürftigen mit Demenz",
                    posibileKeys: ['Opieka nad osobą z demencja', 'Unterstützung eines Betreuungsbedürftigen mit Demenz'],
                    name: "from-text-important-demenz",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.demenz_black,
                    iconActive: IconsMap.svg.zusammenfassung.demenz_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Opieka nad osobą ze stwardnieniem rozsianym",
                    id_deutch: "Eine Betreuungskraft, die sich um Menschen, die an Multiple Sklerose leiden, kümmern kann",
                    posibileKeys: ['Opieka nad osobą z sondą PEG', 'Opieka nad osobą ze stwardnieniem rozsianym', 'Eine Betreuungskraft, die sich um Menschen, die an Multiple Sklerose leiden, kümmern kann'],
                    name: "from-text-important-sonde",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.skleroze,
                    iconActive: IconsMap.svg.zusammenfassung.skleroze_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Dobre umiejętności językowe opiekunki",
                    id_deutch: "Eine Betreuungskraft mit kommunikativen Sprachkenntnissen",
                    posibileKeys: ['Dobre umiejętności językowe opiekunki', 'Eine Betreuungskraft mit kommunikativen Sprachkenntnissen'],
                    name: "from-text-important-sprachkenntnisse",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.chat,
                    iconActive:  IconsMap.svg.zusammenfassung.chat_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Opieka z pełnym transferem",
                    id_deutch: "Hilfe bei vollständiger Transferbedürftigkeit",
                    posibileKeys: ['Opieka z pełnym transferem', 'Hilfe bei vollständiger Transferbedürftigkeit'],
                    name: "from-text-important-transferbed",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.transfer_black,
                    iconActive: IconsMap.svg.zusammenfassung.transfer_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Opieka nad osobą z problemami kontynencji",
                    id_deutch: "Inkontinenzversorgung",
                    posibileKeys: ['Opieka nad osobą z problemami kontynencji', 'Inkontinenzversorgung'],
                    name: "from-text-important-inkontinenzversorgung",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.inkontinenz,
                    iconActive: IconsMap.svg.zusammenfassung.inkontinenz_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Wymiany worka stomijnego",
                    id_deutch: "Eine Betreuungskraft die Stomabeutel wechseln kann",
                    posibileKeys: ['Wymiany worka stomijnego', 'Eine Betreuungskraft die Stomabeutel wechseln kann'],
                    name: "from-text-important-stomabeutel",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.stoma,
                    iconActive: IconsMap.svg.zusammenfassung.stoma_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Przygotowywanie posiłków dla diabetyków",
                    id_deutch: "Eine Betreuungskraft die Diätkost für Diabetiker vorbereiten kann",
                    posibileKeys: ['Przygotowywanie posiłków dla diabetyków', 'Eine Betreuungskraft die Diätkost für Diabetiker vorbereiten kann'],
                    name: "from-text-important-diabetiker",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.fork,
                    iconActive: IconsMap.svg.zusammenfassung.fork_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Sonda PEG",
                    id_deutch: "PEG Sonde",
                    posibileKeys: ['Sonda PEG', 'PEG Sonde'],
                    name: "form-text-pegsonde",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.sonda,
                    iconActive: IconsMap.svg.zusammenfassung.sonda_white,
                    show:false
                }),
                new DnDPriorityOfferElement({
                    id: "Wymiany worka na mocz",
                    id_deutch: "Betreuungskraft, die Urinbeutel entleeren und wechseln kann",
                    posibileKeys: ['Wymiany worka na mocz', 'Betreuungskraft, die Urinbeutel entleeren und wechseln kann'],
                    name: "form-text-urinbeautel",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.urin,
                    iconActive: IconsMap.svg.zusammenfassung.urin_white,
                    show:false
                }),
                new DnDPriorityOfferElement({
                    id: "Prawo jazdy",
                    id_deutch: "Führerschein",
                    posibileKeys: ['Prawo jazdy', 'Führerschein'],
                    name: "from-text-important-fuhrerschein",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.car,
                    iconActive: IconsMap.svg.zusammenfassung.car_white,
                    show:true
                }),
            ]
        })
    }
}