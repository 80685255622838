export default class KoPilotenSaveRangeCategory {
    MultiplierRangeId = null;
    CategoryId = null;
    ClientId = null;
    Note = null;
    MultiplierIds = [];

    constructor(MultiplierRangeId, CategoryId, ClientId, Note, MultiplierIds) {
        this.MultiplierRangeId = MultiplierRangeId;
        this.CategoryId = CategoryId;
        this.ClientId = ClientId;
        this.Note = Note;
        this.MultiplierIds = MultiplierIds;
    }
}
