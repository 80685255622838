import * as React from 'react';

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { translateKey, getSittersAvailabilityData, getPromedicaProductsPricesData } from '../../../../_redux/actions/index';
import { formatPrice } from '../../../../_helpers/price_format';
import { getRandomCV, SEX, DRIVING_LICENSE } from '../../../../_helpers/cvs_set';
import { IconsMap } from '../../../../_helpers/icons_set';
import { LoaderModal, InfoModal } from "../../../../_components/index";
import { ResponseStatus } from '../../../../_helpers/response_status';
import ReqHelper from '../../../../_helpers/request_helper';
import PriorityOffer from '../../../../_constance/classes/dndPriorityOffer/PriorityOffer';
//import Priority from '../../../../_constance/classes/dndPriority/Priority';
import * as SkillRange from "../../../../_forms/BBForm/values/skillRange";
/* hiiden due to FP-343
import { DIE_SUCHANfRAGE } from '../../../../_constance/enums/summaryOfferMenuSections';
*/
import { DIE_BESTELLUNG_SECTION, CANCEL_CONTRACT } from '../../../../_constance/enums/summaryOfferMenuSections';
import { GENDER, LICENSE, OPTIONS } from '../../../../_constance/enums/summaryOfferSittersAvailability';
import { ADDITIONAL_OPTIONS } from '../../../../_constance/enums/summaryOfferAdditionalOptions';
import { LANGUAGE_PACKET } from '../../../../_constance/enums/summaryOfferLanguagePackets';
import { Worker } from '@phuocng/react-pdf-viewer';
import ModalExample from "../../../../_components/form-bb-components/react-pdf-viewer/ModalExample.tsx";
import { Alert } from "../../../../_constance/classes/index";
import { baseData } from '../../../../_constance/base_data';
import { MDBPopover, MDBPopoverBody, MDBBtn } from "mdbreact";
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { PriceType } from '../../../../_constance/enums/priceType';

// key needed to retrieve information of 3 the most things for patient from last section in form
const MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME = 'is_most_important_taking_care_patient';

// key needed to retrieve information about candidate gender selected in form
const IS_CANDIDATE_GENDER_KEY_NAME = 'is_candidate_gender';

// key needed to retrieve information about automat gearbox selected in form
//const IS_DRIVING_AUTOMAT_GEARBOX = 'is_driving_automat_gearbox';

// key needed to retrieve information about manual gearbox selected in form
//const IS_DRIVING_MANUAL_GEARBOX = 'is_driving';

// key needed to retrieve information about Bawaria postalcode
const IS_SERVICE_ADDRESS_POSTAL_CODE = 'is_service_address_postal_code';

// key needed to retrieve information about pflegegrad
const IS_PFLEGEGRAD = 'is_pflegegrad';
const IS_KEIN_PFLEGEGRAD = 'is_kein_pflegegrad';

// lead status with active contract or offer so user should not be able to genere new one
const statisContractOffertStatus = '717700010';

//key name to select isBawaria discount 
const bawariaKeyName = 'fee_bayern';

class OfferSection extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      threeTheMostImportantThingsForPatient: [],
      cv: undefined,
      sittersCount: 0,
      waitingDays: 0,
      showCV: false,
      modalLoader: false,
      errorComponent: Alert,
      currentLanguagePacket: '',
      canCreateContract: props.canCreateContract
    }
  }

  componentDidMount() {
    this._isMounted=true;
    const { sittersAvailabilityStatus, getSittersAvailabilityData, promedicaProductsPricesStatus, getPromedicaProductsPricesData } = this.props;

    this.getImportantThingsForPatient();
    this.getCV();

    if (sittersAvailabilityStatus !== ResponseStatus.READY) {
      getSittersAvailabilityData();
    }

    if (promedicaProductsPricesStatus !== ResponseStatus.READY) {
      getPromedicaProductsPricesData();
    }

    const sittersAvailabilityDetails = this.getSittersAvailabilityForPreparedOffer();

    this.setState({
      sittersCount: sittersAvailabilityDetails.Number,
      waitingDays: sittersAvailabilityDetails.Days,
      currentLanguagePacket: this.props.selectedLanguagePacket,
    })
    // initialize popovers
    window.$(document).ready(function(){
      window.$('[data-toggle="popover"]').popover({
        sanitize: false
      });
    });
  }

  componentDidUpdate() {
    if (this.state.currentLanguagePacket !== this.props.selectedLanguagePacket) {
      this.getCV();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.$('[data-toggle="popover"]').popover('hide');
  }

  getSittersAvailabilityForPreparedOffer = () => {
    const { sittersAvailability, selectedLanguagePacket, selectedAdditionalOptions } = this.props;

    const product = selectedLanguagePacket.substr(4);
    const drivingLicense = this.getDrivingLicenseData() ? LICENSE.YES : LICENSE.NO;
    const gender = this.getGenderData() === SEX.WOMAN ? GENDER.WOMAN : GENDER.MAN;
    let option;

    if (selectedAdditionalOptions.length === 0) {
      option = OPTIONS.OPTION_NONE;
    } else if (selectedAdditionalOptions.length === 1 ) {
      const additionalOption = selectedAdditionalOptions[0];
      switch(additionalOption) {
        case ADDITIONAL_OPTIONS.OPTION_LICENSE:
          option = OPTIONS.OPTION_NONE;
          break;
        case ADDITIONAL_OPTIONS.OPTION_DIPLOMA:
          option = OPTIONS.OPTION_DIPLOMA;
          break;
        case ADDITIONAL_OPTIONS.OPTION_2NDPAT:
          option = OPTIONS.OPTION_2NDPAT;
          break;
        case ADDITIONAL_OPTIONS.OPTION_2NDLANG:
          option = OPTIONS.OPTION_2NDLANG;
          break;
        case ADDITIONAL_OPTIONS.OPTION_PLUS:
          option = OPTIONS.OPTION_PLUS;
          break;
        default:
          option = OPTIONS.OPTION_NONE;
      }
    } else{
      if (selectedAdditionalOptions.length > 1) {
        const exitsIndex = selectedAdditionalOptions.indexOf(ADDITIONAL_OPTIONS.OPTION_LICENSE);
        if(exitsIndex>-1){
          if (selectedAdditionalOptions.length > 2){
            option = OPTIONS.OPTION_MIX;
          }else{
            let copyArray = [...selectedAdditionalOptions];
            copyArray.splice(exitsIndex, 1);
            switch(copyArray[0]) {
              case ADDITIONAL_OPTIONS.OPTION_DIPLOMA:
                option = OPTIONS.OPTION_DIPLOMA;
                break;
              case ADDITIONAL_OPTIONS.OPTION_2NDPAT:
                option = OPTIONS.OPTION_2NDPAT;
                break;
              case ADDITIONAL_OPTIONS.OPTION_2NDLANG:
                option = OPTIONS.OPTION_2NDLANG;
                break;
              case ADDITIONAL_OPTIONS.OPTION_PLUS:
                option = OPTIONS.OPTION_PLUS;
                break;
              default:
                option = OPTIONS.OPTION_NONE;
            }
          }
        }else{
          option = OPTIONS.OPTION_MIX;
        }
      }
    }

    const sittersAvailabilityObjects = sittersAvailability.map(array => {
      return array.reduce((obj, item) => Object.assign(obj, { 
        [item.FieldName]: item.FieldValue 
      }), {});
    });

    let filteredArrayPosibilities = [...sittersAvailabilityObjects];
    filteredArrayPosibilities = filteredArrayPosibilities.filter(object => { 
      if (
        object["Sex"] === gender &&
        object["Driving"] === drivingLicense &&
        object["Product"] === product &&
        object["Option"] === option
      ){
        return true;
      } else{
        return false;
      }
    });

    let OPAvailibility= [];
    if(filteredArrayPosibilities.length>1){
      OPAvailibility = ReqHelper.simpleSortByKey(filteredArrayPosibilities, 'Days');
      OPAvailibility = OPAvailibility[0];
    }else{
      OPAvailibility = filteredArrayPosibilities[0];
    }

    if(OPAvailibility){
      if(OPAvailibility.length===0){
        return sittersAvailabilityObjects[0];
      }else{
        return OPAvailibility;
      }
    }else{
      return sittersAvailabilityObjects[0];
    }
  }

  getGenderData = () => {
    const { form } = this.props;
    const gender = form.getFieldByKey(IS_CANDIDATE_GENDER_KEY_NAME);

    if (gender.value.toString() === SkillRange.PARTIAL) {
      return SEX.WOMAN;
    } else if (gender.value.toString() === SkillRange.UNLIMITED) {
      return SEX.MAN;
    } else if (gender.value.toString() === SkillRange.LIMITED) {
      const randomInteger = Math.floor(Math.random() * (1 + 1));
      if (randomInteger === 0) {
        return SEX.WOMAN;
      } else {
        return SEX.MAN;
      }
    }
  }

  getDrivingLicenseData = () => { 
    const { selectedAdditionalOptions } =  this.props;
    //hidden due to FP-352
    //const automatGearbox = form.getFieldByKey(IS_DRIVING_AUTOMAT_GEARBOX);
    //const manualGearbox = form.getFieldByKey(IS_DRIVING_MANUAL_GEARBOX);

    //const field = form.getFieldByKey(MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME);
    //const priorityList = new Priority();

    //priorityList.prepareMeListFromString(field.value);
    //const drivingLicensePriorityIndex = priorityList.getFieldPosition("Prawo jazdy");
  
    /*const DLexists = this.exitsInAdditionalSavedOption(ADDITIONAL_OPTIONS.OPTION_LICENSE);
    if(DLexists!==null){
      if(DLexists===true){
        return true;
      }else{
        return false;
      }
    }else{*/
      /*if(manualGearbox.value || automatGearbox.value){
        return true;
      }else{
        if (drivingLicensePriorityIndex < 3) {
          return true;
        }else{*/
          if (selectedAdditionalOptions.find(item => item === ADDITIONAL_OPTIONS.OPTION_LICENSE)) {
            return true;
          }
          return false;
        //}
     // }
    //}
  }

  getLanguageSkillData = () => {
    const { selectedLanguagePacket } = this.props;
    switch( selectedLanguagePacket ){
      case LANGUAGE_PACKET.PACKET_100: return '100';
      case LANGUAGE_PACKET.PACKET_300: return '300';
      case LANGUAGE_PACKET.PACKET_500: return '500';
      case LANGUAGE_PACKET.PACKET_750: return '750';
      case LANGUAGE_PACKET.PACKET_1000: return '1000';
      default: return '300'
    }
  }

  exitsInAdditionalSavedOption = (name) =>{
    const { savedAdditionalOption } = this.props;
    if(savedAdditionalOption.length>0){
      for (const element in savedAdditionalOption) {
        for (const key in savedAdditionalOption[element]) {
          if(key === name){
            return savedAdditionalOption[element][key];
          }
        }
      }
    }
    return null
  }

  getImportantThingsForPatient = () => {
    const { form } = this.props;
    const field = form.getFieldByKey(MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME);
    const priorityList = new PriorityOffer();
    priorityList.prepareMeListFromString(field.value);
    let activeItems = priorityList.prepareMeListOfActiveItems();
    this.setState({threeTheMostImportantThingsForPatient: activeItems})
  }

  getCV = () => {
    const drivingLicense = this.getDrivingLicenseData();
    const sex = this.getGenderData();
    const languageSkill = this.getLanguageSkillData();
    this.setState({
      cv: getRandomCV(
        sex, 
        drivingLicense ? DRIVING_LICENSE.YES : DRIVING_LICENSE.NO, 
        languageSkill
      ),
      currentLanguagePacket: this.props.selectedLanguagePacket,
    })
  }

  get priceOfLanguagePacket() {
    const { availableLanguagePackets, selectedLanguagePacket } = this.props;
    return availableLanguagePackets.find(item => item.id === selectedLanguagePacket).price;
  }

  findAdditionalObjectDetails = id => {
    const { availableAdditionalOptions } = this.props;
    return availableAdditionalOptions.find(item => item.id === id);
  }

  get totalPrice() {
    const { availableAdditionalOptions, selectedAdditionalOptionsForOfferSection } = this.props;

    let totalPriceOfAdditionalOptions = 0.00;
    selectedAdditionalOptionsForOfferSection.forEach(element => {
      if (element.active) {
        const foundedElement = availableAdditionalOptions.find(item => item.id === element.id);
        totalPriceOfAdditionalOptions = totalPriceOfAdditionalOptions + (foundedElement?parseFloat(foundedElement.price):0);
      }
    });

    return (parseFloat(this.priceOfLanguagePacket) + totalPriceOfAdditionalOptions).toString();
  }

  get totalPriceWithDiscount() {
    const { selectedAdditionalOptions, availableAdditionalOptions, form, isAditionalPatient, secondPatientForm } = this.props;

    let totalPriceOfAdditionalOptions = 0.00;
    let bawariaDiscount = 0.00;
    let pflegegradDiscount = 0.00;
    let pflegegradDiscountSecondPatient = 0.00;

    selectedAdditionalOptions.forEach(element => {
      totalPriceOfAdditionalOptions = totalPriceOfAdditionalOptions + parseFloat(availableAdditionalOptions.find(item => item.id === element).price)
    });

    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValue = pflegegrad.value.toString();
    const pflegegradSecondPatient = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValueSecondPatient = pflegegradSecondPatient.value.toString();

    if(
      pflegegradStringValue === '717700001' ||
      pflegegradStringValue === '717700002' ||
      pflegegradStringValue === '717700003' ||
      pflegegradStringValue === '717700004' ||
      pflegegradStringValueSecondPatient === '717700001' ||
      pflegegradStringValueSecondPatient === '717700002' ||
      pflegegradStringValueSecondPatient === '717700003' ||
      pflegegradStringValueSecondPatient === '717700004'
    ) {
      if(this.isPostalCodeBawaria()) {
        const priceDiscount = this.props.promedicaProductsPrices.find(item => item.id === bawariaKeyName);
        if(priceDiscount){
          bawariaDiscount = parseFloat(priceDiscount.price);

          if(
            (pflegegradStringValue === '717700001' ||
            pflegegradStringValue === '717700002' ||
            pflegegradStringValue === '717700003' ||
            pflegegradStringValue === '717700004' )&&
            (pflegegradStringValueSecondPatient === '717700001' ||
            pflegegradStringValueSecondPatient === '717700002' ||
            pflegegradStringValueSecondPatient === '717700003' ||
            pflegegradStringValueSecondPatient === '717700004')
          ) {
            bawariaDiscount = bawariaDiscount * 2;
          }
        }
      }
    }

    if (this.getDiscount(form)) {
      pflegegradDiscount = parseFloat(this.getDiscount(form).price);
    }
    if (this.getDiscount(secondPatientForm) && isAditionalPatient) {
      pflegegradDiscountSecondPatient = parseFloat(this.getDiscount(secondPatientForm).price);
    }

    return (parseFloat(this.priceOfLanguagePacket) + totalPriceOfAdditionalOptions - pflegegradDiscount - pflegegradDiscountSecondPatient + bawariaDiscount).toString();
  }

  get canCreateContractorOffer(){
    const { clientLeadBaseData } = this.props;
    return clientLeadBaseData.status === null || clientLeadBaseData.status === undefined || clientLeadBaseData.status.toString() !== statisContractOffertStatus;
  }

  getDiscount = (form) => {

    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const isKeinPflegegrad = form.getFieldByKey(IS_KEIN_PFLEGEGRAD);

    if (isKeinPflegegrad.value) {
      return false;
    }

    if(pflegegrad.value.toString() === '717700000') {
      return {
        name: "from-text-pflegegrad1",
        price: "0.00"
      }
    }

    if(pflegegrad.value.toString() === '717700001') {
      return {
        name: "from-text-pflegegrad2",
        price: "347.00"
      }
    }

    if(pflegegrad.value.toString() === '717700002') {
      return {
        name: "from-text-pflegegrad3",
        price: "599.00"
      }
    }

    if(pflegegrad.value.toString() === '717700003') {
      return {
        name: "from-text-pflegegrad4",
        price: "800.00"
      }
    }

    if(pflegegrad.value.toString() === '717700004') {
      return {
        name: "from-text-pflegegrad5",
        price: "990.00"
      }
    }
  }

  isPostalCodeBawaria = () => {
    const { cities, form } = this.props;

    const isServiceAddressPostalCode = form.getFieldByKey(IS_SERVICE_ADDRESS_POSTAL_CODE).value;
    if (cities.length > 0) {
      for (let index = 0; index < cities.length; index++) {
        if (cities[index].PostalCode.indexOf(isServiceAddressPostalCode) > -1) {
          if (cities[index].Bawaria === 1) {
            return true;
          }
        }
      }
      return false;
    } else {
      return false;
    }
  }

  getMeAvailableAdditionalOptionsKey=(item, key)=>{
    const { availableAdditionalOptions } = this.props;
    const element = availableAdditionalOptions.find(option => option.id === item.id);
    if(element){
      if(key==='Label'){
        return element.label;
      }else{
        return element.tooltip;
      }
    }else{
      return ''
    }
  }

  getMeAvailableLanguagePackets=(item)=>{
    const { availableLanguagePackets } = this.props;
    const element = availableLanguagePackets.find(option => option.id === item);
    if(element){
      return element.label;
    }
    return '';
  }

  renderAvailability = (variable) => {
    const { translateKey } = this.props;
    switch(variable){
      case "message_2ndlang_1":
        return translateKey("message_2ndlang_1");
      case "message_2ndlang_2":
        return translateKey("message_2ndlang_2");
      case "message_diploma_1":
        return translateKey("message_diploma_1");
      case "message_mix_1":
        return translateKey("message_mix_1");
      case "message_mix_2":
        return translateKey("message_mix_2");
      default:
        return variable;
    }
  }

  renderLeftColumn = () => {
    const { translateKey, selectedLanguagePacket, selectedAdditionalOptionsForOfferSection } = this.props;
    const { threeTheMostImportantThingsForPatient } = this.state;
    return (
      <div className="offer-left-column">
        <div className="empflohen-badge">
          {translateKey("summary-offer-sprache-empfohlen")}
        </div>
        <div className="offer-section">
          <div className="ihr-angebot">
            {translateKey("summary-offer-header-ihr-angebot")}
          </div>
          <div className="section-title pt-0">
            {this.getMeAvailableLanguagePackets(selectedLanguagePacket)}
          </div>
          {threeTheMostImportantThingsForPatient.filter(item => (item.id !== "Prawo jazdy" && item.id !== "Führerschein")).map(item => (
            <div key={item.id} className="section-item">
              <i className="fas fa-check fa-1x mr-md-2" />
              {translateKey(item.name)}
            </div>
          ))}
          {selectedAdditionalOptionsForOfferSection.map((item, index) => (
            <div key={index} className={`d-flex align-items-baseline section-item ${!item.active && `section-item-disabled`}`}>
              <div 
                role="button" 
                onClick={() => this.props.toggleAdditionalOption(item.id)} 
                className={`default-btn-hover pr-1`}
              >
                <i className={`fas fa-check fa-1x mr-md-2 ${item.active ? 'green-icon' : 'grey-icon'}`} />
                {translateKey(this.getMeAvailableAdditionalOptionsKey(item, "Label"))}
                {` `}
              </div>
              {` `}
              <MDBPopover placement="right" popover clickable id={item.id}>
                <MDBBtn><i className="fas fa-question-circle fa-1x orange-icon default-btn-hover no_padding" /></MDBBtn>
                <div>
                  <MDBPopoverBody>{translateKey(this.getMeAvailableAdditionalOptionsKey(item, 'tooltip'))}</MDBPopoverBody>
                </div>
              </MDBPopover>
            </div>
          ))}
        </div>
      </div>
    )
  }

  toggleMeCV = () =>{
    this.setState(prevState => {
      return {
        ...prevState,
        showCV: !prevState.showCV
      }
    })
  }
  
  hideInfo = () => {
    const { history, paramID } = this.props;
    this.setState({errorComponent: Alert})
    history.push(`${baseData.subFolders}leadDetails/${paramID}`);
  }
  
  renderRightColumn = () => {
    const { translateKey, displayPrice, onSectionClick, userType, priceType } = this.props;

    const sittersCount = this.renderAvailability(this.state.sittersCount);
    const waitingDays =  this.renderAvailability(this.state.waitingDays);

    return (
      <div className="offer-right-column">
        {(displayPrice && priceType !== PriceType.NONE) && (
          <div className="top-price-box">
            <img className="info-section-icon" src={IconsMap.other.euro_mark} alt="euro"/>
            <div className={`info-section-details-count ${this.totalPrice !== this.totalPriceWithDiscount?'withdiscout':''} main-info-price`}>
                {this.totalPrice !== this.totalPriceWithDiscount ?
                  <React.Fragment>
                    { priceType === PriceType.MONTHLY ? formatPrice(`${this.totalPriceWithDiscount}`, false) : formatPrice(`${this.totalPriceWithDiscount/30}`, false) }
                    <span className="just-price-star">*</span>
                  </React.Fragment>
                :
                  <React.Fragment>
                    { priceType === PriceType.MONTHLY ? formatPrice(`${this.totalPrice}.00`, false) : formatPrice(`${this.totalPrice/30}.00`, false) }
                    <span className="just-price-star">*</span>
                  </React.Fragment>
                }
            </div>
          </div>
        )}
        {this.state.cv && (
          <div className="cv-wrapper default-btn-hover" onClick={this.toggleMeCV}>
            <img 
              className="cv-img" 
              src={this.state.cv.img} 
              alt="cv"
            />
          </div>
        )}
        <div className="info-section">
          <div className="info-section-details">
          {waitingDays!=='' && waitingDays!==null &&
            <div className="d-flex">
                {waitingDays.length > 10 ? (
                  <div className="info-section-small-details pt-2 pb-2">
                    {waitingDays}
                  </div>
                ) : 
                  null
                }
            </div>
          }
          {sittersCount!=='' && sittersCount!==null &&
            <div className="d-flex">
                {sittersCount.length > 10 ? (
                  <div className="info-section-small-details">
                    {sittersCount}
                  </div>
                ) : 
                  null
                }
            
            </div>
          }
          </div>
        </div>
          <div className={`info-section ${!(displayPrice && priceType !== PriceType.NONE ) && 'hide-section'}`}>
              <img className="info-section-icon" src={IconsMap.other.euro_mark} alt="euro"/>
              <div className="info-section-details">
                <div className="d-flex">
                  {(this.totalPrice !== this.totalPriceWithDiscount) ?
                    <div className="info-section-details-count details withdiscout main-info-price">
                      { priceType === PriceType.MONTHLY ? formatPrice(`${this.totalPriceWithDiscount}`, false) : formatPrice(`${this.totalPriceWithDiscount/30}`, false) }
                      {/* {formatPrice(`${this.totalPriceWithDiscount}`, false)} */}
                      <span className="just-price-star">*</span>
                    </div>
                  :
                    <div className="info-section-details-count details main-info-price">
                      { priceType === PriceType.MONTHLY ? formatPrice(`${this.totalPrice}.00`, false) : formatPrice(`${this.totalPrice/30}.00`, false) }
                      {/* {formatPrice(`${this.totalPrice}.00`, false)} */}
                      <span className="just-price-star">*</span>
                    </div>
                  }
                </div>
                <div className="info-section-small-details">
                  {(this.totalPrice !== this.totalPriceWithDiscount) ?
                    `* ${translateKey("summary-offer-with-discount")}`
                  :
                    `* ${translateKey("summary-offer-without-discount")}`
                  }
                </div>
              </div>
          </div>
        {(userType !== 'CLIENT' || this.props.canCreateContract === true) &&
          <React.Fragment>
            <button 
              type="button" 
              className="btn btn-success button-order"
              onClick={() => {
                if(this.canCreateContractorOffer){
                  onSectionClick(DIE_BESTELLUNG_SECTION)
                }else{
                  alert(translateKey("form-text-processing-offer"));
                }
              }} 
            >
              {translateKey("summary-offer-offer-section-die-bestellung")}
              <i className="fas fa-chevron-right summary-offer-icon" />
            </button>

              <div className="buttons-wrapper">
                {
                  /* hiiden due to FP-343
                    <div
                      role="button" 
                      className="button-link default-btn-hover"
                      onClick={() => {
                        if(this.canCreateContractorOffer){
                          onSectionClick(DIE_SUCHANfRAGE)
                        }else{
                          alert(translateKey("form-text-processing-offer"));
                        }
                      }} 
                    >
                      {translateKey("summary-offer-offer-section-die-suchanfrage")}
                    </div>
                  */
                }
                <div
                  role="button" 
                  className="button-link default-btn-hover right-column-btn"
                  onClick={() => onSectionClick(CANCEL_CONTRACT)} 
                >
                  {translateKey("summary-offer-offer-section-die-auf-vertrag-werzichten")}
                </div>
              </div>
          </React.Fragment>
        }
      </div>
    )
  }

  render() {
    const { sittersAvailabilityStatus, userType, translateKey } = this.props;
    const { cv, showCV, modalLoader, errorComponent } = this.state;
    return sittersAvailabilityStatus === ResponseStatus.READY ? (
      <div className="container-fluid no-gutters p-0 d-flex">
        <LoaderModal show={modalLoader} />
        <InfoModal okMe={this.hideInfo} show={errorComponent.show} titleMessage="" mainMessage={errorComponent.message} okMessage={translateKey("Ok")} />
        {showCV && cv &&
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <ModalExample toggleCV={this.toggleMeCV} showCV={showCV} cvLink={cv.pdf}/>
          </Worker>
        }
        {this.renderLeftColumn()}
        {(userType !== 'CLIENT' || this.props.canCreateContract) && this.renderRightColumn()}
      </div>
    ) : (
      <LoaderModal show={true} />
    )
  }
}

const mapDispatchToProps = dispatch => ({ 
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  getSittersAvailabilityData: () => dispatch(getSittersAvailabilityData()),
  getPromedicaProductsPricesData: () => dispatch(getPromedicaProductsPricesData())
});

const mapStateToProps = state => {
  return {
    sittersAvailability: state.enums.sittersAvailability,
    sittersAvailabilityStatus: state.enums.sittersAvailabilityStatus,
    cities: state.enums.citiesList,
    promedicaProductsPrices: state.enums.promedicaProductsPrices,
    promedicaProductsPricesStatus: state.enums.promedicaProductsPricesStatus,
    priceType: state.priceType.priceType,
  }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OfferSection));