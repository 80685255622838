import * as React from 'react';
import ValidationErrorsList from '../../_components/validation-errors-list/validation-errors-list';
import * as Validation from '../../_forms/validation';
import * as FieldTypes from '../../_forms/fieldTypes';
import { IconsMap } from "../../_helpers/icons_set";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import * as LanguageSelect from "../../_constance/values/languageSelect";

export default class InputTypeComponent extends React.Component {
    static defaultProps = {
        valueFormatter: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text,
            countryData: {}
        };
    }

    setTypeChange(key, value){
        this.props.setTypeChangeToParent(key, value);
    }

    setPhoneTypeChange(key, value, countryData){
        if(value!==null && value!==''){
            value="+"+value;
        }
        this.setState({
            countryData : countryData
        })
        this.props.setTypeChangeToParent(key, value);
    }

    getPriorLanguage = () =>{
        const { appLang } = this.props;
        if(appLang===LanguageSelect.DE){return ['de'];}
        if(appLang===LanguageSelect.EN){return ['gb'];}
        return ['de', 'gb', 'pl']
    }

    getDefaultLanguage = () =>{
        const { appLang } = this.props;
        if(appLang===LanguageSelect.DE){return 'de';}
        if(appLang===LanguageSelect.EN){return 'gb';}
        return 'de';
    }

    render() {
        const {
          value,
          showValidation,
          keyName,
          valueFormatter,
          disabled,
          type,
          text,
          className,
          newUI
        } = this.props;
        const {
            countryData
        } = this.state
        const realValue = typeof valueFormatter === 'function' ? valueFormatter(value) : value===null?'':value;
        const validationValue = type === FieldTypes.PHONE_NUMBER && countryData !== undefined ? {value: realValue, countryData: countryData} : realValue;
        const validationErrors = Validation.fieldType(validationValue, type).errors;
        const errorsArgs = Validation.fieldType(validationValue, type).args;
        let showErrors = showValidation && validationErrors.length>0 && realValue!==null && realValue!==""
        return (
          <div className={"form-group row flexAlignCenter no-margin-row " + className}>
            <label className="col-sm-4 col-form-label form-label-basic">
              {text}
              {type === FieldTypes.PHONE_NUMBER &&
                Validation.fieldType(validationValue, FieldTypes.PHONE_NUMBER).isValid && (
                  <a className="mail-icon-in-details" href={"tel:" + realValue} target="_top">
                    <img src={IconsMap.svg.phone} alt="Phone" />
                  </a>
                )}
              {type === FieldTypes.EMAIL &&
                Validation.fieldType(realValue, FieldTypes.EMAIL).isValid && (
                  <a className="mail-icon-in-details" href={"mailto:" + realValue} target="_top">
                    <img src={IconsMap.svg.email} alt="Email" />
                  </a>
                )}
            </label>
            <div className={`col-sm-8 ${showErrors ? "is-invalid" : ""}`}>
              {type === FieldTypes.PHONE_NUMBER ? (
                <PhoneInput
                  preferredCountries={this.getPriorLanguage()}
                  preserveOrder={["preferredCountries"]}
                  value={realValue}
                  placeholder=""
                  country={this.getDefaultLanguage()}
                  disabled={disabled}
                  containerClass={"d-flex"}
                  inputClass={`form-control lead-input-wrapper pm-phone-input ${newUI ? "newUI" : ""}`}
                  onChange={(phone, countryData) => this.setPhoneTypeChange(keyName, phone, countryData)}
                />
              ) : (
                <input
                  type="text"
                  disabled={disabled}
                  className={`form-control lead-input-wrapper ${showErrors ? "is-invalid" : ""}`}
                  onChange={(e) => this.setTypeChange(keyName, e.target.value)}
                  value={realValue}
                />
              )}
              {showErrors && (
                <ValidationErrorsList
                  keyName={keyName}
                  errors={validationErrors}
                  args={errorsArgs}
                />
              )}
            </div>
          </div>
        );
    }
}
