import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import {RouteButtonComponent} from "../../_components/index"
import * as Gender from "../../_constance/enums/Gender";
import * as LangSelect from "../../_constance/values/languageSelect";
import {AutoSuggestComponent} from "../../_components/autosuggest-component/AutoSuggestComponent";
import SelectUniversalComponent from "../../_components/select-universal-component/SelectUniversalComponent";
import InputTypeComponent from "../../_components/input-type-component/input-type-component";
import ReqHelper from "../../_helpers/request_helper";
import * as FieldTypes from '../../_forms/fieldTypes';

class LeadClientComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
            edited: this.props.edited,
            dynamics: this.props.dynamics,
            selectedPostal: false,
            openPostalMenu: false,
        };
    }

    handlechangePostalCode = event => {
        if(typeof event === 'object'){
            this.props.element.client.address.postalCode = event.value;
            this.props.element.client.address.city = event.city;
            this.setState(prevState => {
                return {
                    ...prevState,
                    selectedPostal: true,
                    openPostalMenu:false,
                    element: {
                        ...prevState.element,
                        client: {
                            ...prevState.element.client,
                            address: {
                                ...prevState.element.client.address,
                                postalCode: event.value,
                                city: event.city,
                            }
                        }
                    }
                }
            });
        }else{
            this.props.element.client.address.postalCode = event;
            this.setState(prevState => {
                return {
                    ...prevState,
                    openPostalMenu:false,
                    element: {
                        ...prevState.element,
                        client: {
                            ...prevState.element.client,
                            address: {
                                ...prevState.element.client.address,
                                postalCode: event,
                            }
                        }
                    }
                }
            });
        }
        this.props.editedData();
    }

    handleChangeValueKey = (key, value) =>{
        this.props.element.client[key] = value;
        this.setState(prevState => {
          let elem = {...prevState.element.client}
          elem[key] = value;
          return {
            ...prevState,
            element: {
                ...prevState.element,
                client: elem
            }
        }
        });
        this.props.editedData();
    }

    handleChangeValueKeyNotArrow(key, value){
        this.props.element.client[key] = value;
        this.setState(prevState => {
          let elem = {...prevState.element.client}
          elem[key] = value;
          return {
            ...prevState,
            element: {
                ...prevState.element,
                client: elem
            }
        }
        });
        this.props.editedData();
    }

    handleChangeValueAddressKeyNotArrow(key, value){
        this.props.element.client.address[key] = value;
        this.setState(prevState => {
            let elem = {...prevState.element.client.address}
            elem[key] = value;
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    client: {
                        ...prevState.element.client,
                        address: elem
                    }
                }
            }
        });
        this.props.editedData();
    }

    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    prepareMeSelect = () =>{
        if(this.props.cities.length>0){
            return this.props.cities.map(x => ({
                value: x.PostalCode!==null?x.PostalCode:'', 
                city:  x.City!==null?x.City:'',
                label:  x.City!==null?x.PostalCode+"-"+x.City:''
            }));
        }else{
            return [];
        }
    }

    handleChangeDynamics = (key, value) =>{
        this.props.dynamics[key] = value;
        this.setState(prevState => {
            let elem = {...prevState.dynamics}
            elem[key] = value;
            return {
                ...prevState,
                dynamics: elem
            }
        });
        this.props.editedData();
    }

    render() {
        const preparedSelectGroup = this.prepareMeSelect();
        const { translateKey, element, showValidation, appLang, disabled, dynamics} = this.props;
        let clientData;
        if(this.state.isOpen){
            clientData = (
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-5">
                        <fieldset disabled={disabled}>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("last_name")}<span className="red-star-required-field"> *</span></label>
                                <div className="col-sm-8">
                                    <input type="text" className={"form-control lead-input-wrapper "+(!ReqHelper.checkNotNullAndNotEmptyString(element.client.lastName) && showValidation?"is-invalid": '')} onChange={e => this.handleChangeValueKeyNotArrow("lastName", e.target.value)} value={element.client.lastName?element.client.lastName:''}/>
                                    <div className="invalid-feedback">{translateKey("form_contains_errors")}</div>
                                </div>
                            </div>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("first_name")}<span className="red-star-required-field"> *</span></label>
                                <div className="col-sm-8">
                                    <input type="text" className={"form-control lead-input-wrapper "+(!ReqHelper.checkNotNullAndNotEmptyString(element.client.firstName) && showValidation?"is-invalid": '')} onChange={e => this.handleChangeValueKeyNotArrow("firstName", e.target.value)} value={element.client.firstName?element.client.firstName:''}/>
                                    <div className="invalid-feedback">{translateKey("form_contains_errors")}</div>
                                </div>
                            </div>
                            {appLang===LangSelect.DE &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("middle_name")}</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("middleName", e.target.value)} value={element.client.middleName!==null?element.client.middleName:''}/>
                                    </div>
                                </div>
                            }
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("gender")}</label>
                                <div className="col-sm-8">
                                    <SelectUniversalComponent
                                        keyName="gender"
                                        valuesList={[
                                            { value: Gender.MALE, label: translateKey("male") },
                                            { value: Gender.FEMALE, label: translateKey("female") }
                                        ]}
                                        value={element.client.gender!==null?element.client.gender:""}
                                        optionValueKey="value"
                                        optionLabelKey="label"
                                        setTypeChangeToParent={this.handleChangeValueKey}
                                    />
                                </div>
                            </div>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("form-text-section-0-1")}</label>
                                <div className="col-sm-8">
                                    <SelectUniversalComponent
                                        keyName="is_degree_relationship_with_patient_carer"
                                        valuesList={[
                                            { value: '717700000', label: translateKey('form-text-relationship-0') },
                                            { value: '717700001', label: translateKey('form-text-relationship-1') },
                                            { value: '717700002', label: translateKey('form-text-relationship-2') },
                                            { value: '717700003', label: translateKey('form-text-relationship-3') },
                                            { value: '717700005', label: translateKey('form-text-relationship-5') },
                                            { value: '717700006', label: translateKey('form-text-relationship-6') },
                                            { value: '717700007', label: translateKey('form-text-relationship-7') },
                                            { value: '717700008', label: translateKey('form-text-relationship-8') },
                                            { value: '717700010', label: translateKey('form-text-relationship-10') },
                                            { value: '717700011', label: translateKey('form-text-relationship-11') },
                                            { value: '717700012', label: translateKey('form-text-relationship-12') },
                                            { value: '717700013', label: translateKey('form-text-relationship-13') }
                                        ]}
                                        value={dynamics!==null?dynamics.is_degree_relationship_with_patient_carer!==undefined?dynamics.is_degree_relationship_with_patient_carer:"":""}
                                        optionValueKey="value"
                                        optionLabelKey="label"
                                        setTypeChangeToParent={this.handleChangeDynamics}
                                    />
                                </div>
                            </div>
                            <InputTypeComponent appLang={appLang} disabled={false} keyName={"cellPhone"} value={element.client.cellPhone} type={FieldTypes.PHONE_NUMBER} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("mobile_phone")}/>
                            <InputTypeComponent appLang={appLang} disabled={false} keyName={"phone"} value={element.client.phone} type={FieldTypes.PHONE_NUMBER} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("home_phone")}/>
                            {appLang===LangSelect.DE &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("phone_other_remarks")}</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("phoneOtherRemarks", e.target.value)} value={element.client.phoneOtherRemarks!==null?element.client.phoneOtherRemarks:''}/>
                                    </div>
                                </div>
                            }
                            <InputTypeComponent disabled={false} keyName={"email"} value={element.client.email} type={FieldTypes.EMAIL} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("email")}/>
                            {appLang === LangSelect.EN &&
                                <InputTypeComponent disabled={false} keyName={"emailAddress2"} value={element.client.emailAddress2} type={FieldTypes.EMAIL} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("email_2")}/>                            
                            }
                        </fieldset>
                    </div>
                    <div className="col-sm-12 col-lg-5">
                        <fieldset disabled={disabled}>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("street")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("street", e.target.value)} value={element.client.address.street?element.client.address.street:''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("building_number")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("buildingNumber", e.target.value)} value={element.client.address.buildingNumber?element.client.address.buildingNumber:''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("apartment_number")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("apartmentNumber", e.target.value)} value={element.client.address.apartmentNumber?element.client.address.apartmentNumber:''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("postal_code")}</label>
                            <div className="col-sm-8">
                                {!disabled?
                                    preparedSelectGroup.length>0 && <AutoSuggestComponent onChange={this.handlechangePostalCode} value={element.client.address.postalCode} suggestions={preparedSelectGroup}/>
                                :
                                    <input type="text" className="form-control lead-input-wrapper" value={element.client.address.postalCode} readOnly/>
                                }
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("city")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" disabled={appLang === LangSelect.DE} onChange={e => this.handleChangeValueAddressKeyNotArrow("city", e.target.value)} value={element.client.address.city?element.client.address.city:''}/>
                            </div>
                        </div>
                        {appLang===LangSelect.EN &&
                            <React.Fragment>
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("salutation_letter")}</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("salutationLetter", e.target.value)} value={element.client.salutationLetter?element.client.salutationLetter:''}/>
                                    </div>
                                </div>
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("salutation_address")}</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("salutationAddress", e.target.value)} value={element.client.salutationAddress?element.client.salutationAddress:''}/>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        </fieldset>
                    </div>
                    <div className="col-lg-2 text-right lead-route-container">
                        <RouteButtonComponent disabled={disabled} element={element.client.address} text={translateKey("route")}/>
                    </div>
                </div>
            )
        }
        return (
            <div className="lead-client-row">
                <div className="lead-details-header">
                    <div className="filter-modal-option-text">{translateKey("client")}</div>
                    <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                        {this.state.isOpen?translateKey("show_less"):translateKey("show_more")}
                    </div>
                </div>
                {clientData}
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    appLang: state.lang.appLanguage,
    cities: state.enums.citiesList,
    citiesStatus: state.enums.citiesListStatus
}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadClientComponent));