
import { 
  SET_LEADS_DATABASE, REMOVE_LEADS_DATABASE, REMOVE_LEAD_DATA, SET_CLIENTS_DATABASE, REMOVE_CLIENTS_DATABASE, SET_MULTIPLIERS_DATABASE, REMOVE_MULTIPLIERS_DATABASE, SET_INVOICES_DATABASE, 
  REMOVE_INVOICES_DATABASE, SET_TASKS_DATABASE , UPDATE_TASKS_DATABASE, REMOVE_TASKS_DATABASE, PENDING_CLIENTS_DATABASE, PENDING_INVOICES_DATABASE, PENDING_LEADS_DATABASE,
  PENDING_MULTIPLIERS_DATABASE, PENDING_TASKS_DATABASE, CLEAR_ALL_DATABASE, SET_PATIENTCLIENT_DATABASE, SET_WZ_DATABASE, PENDING_PATIENTCLIENT_DATABASE, REMOVE_PATIENTCLIENT_DATABASE,
  SET_PATIENTLEAD_DATABASE, PENDING_PATIENTLEAD_DATABASE,REMOVE_PATIENTLEAD_DATABASE, UPDATE_LEAD_DATA, FINISHED_TASKS_DATABASE, ADD_LEAD_DATA, UPDATE_MULTIPLIER_DATA, ADD_MULTIPLIER_DATA,
  ADD_TASK_DATA, UPDATE_TASK_DATA, ACCEPT_INVOICES_DATABASE, UPDATE_PATIENT_DATA, ADD_PATIENT_DATA, SET_CARE_BOX_ID, SET_CARE_BOX_SETS, SET_CARE_BOX_SETS_LIST, SET_GLOVE_TYPE_LIST, SET_CARE_BOX_INDIVIDUAL_PRICES
} from "../actions/action-types";
import { ResponseStatus } from '../../_helpers/response_status'

const initialState = {
  leads: [],
  leadsState: ResponseStatus.FREE,
  leadsTimeStamp: null,
  clients: [],
  clientsState: ResponseStatus.FREE,
  clientsTimeStamp: null,
  patientClient: [],
  patientClientState: ResponseStatus.FREE,
  patientClientTimeStamp: null,
  wzData: [],
  surveyIds: [],
  wzState: ResponseStatus.FREE,
  wzTimeStamp: null,
  patientLead: [],
  patientLeadState: ResponseStatus.FREE,
  patientLeadTimeStamp: null,
  multipliers: [],
  multipliersState: ResponseStatus.FREE,
  multipliersTimeStamp: null,
  invoices: [],
  invoicesState: ResponseStatus.FREE,
  invoicesTimeStamp: null,
  tasks: [],
  tasksState: ResponseStatus.FREE,
  tasksTimeStamp: null,
  careBoxId: '',
  careBoxIngredientsId: '',
  careBoxSets: null,
  careBoxSetsList: null,
  careBoxIndividualPrices: null,
  gloveType: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEADS_DATABASE:
        return {
          ...state,
          leads: action.data,
          leadsState: ResponseStatus.READY,
          leadsTimeStamp: new Date().getTime()
        };
    case PENDING_LEADS_DATABASE:
        return {
          ...state,
          leadsState: ResponseStatus.PENDING
        };
    case REMOVE_LEADS_DATABASE:
        return {
          ...state,
          leads: [],
          leadsState: ResponseStatus.FREE,
          leadsTimeStamp: 0
        };
    case REMOVE_LEAD_DATA:
        return {
          ...state,
          leads: state.leads.filter(element => element.leadId !== action.leadId),
        };
    case UPDATE_LEAD_DATA:
        let updatedLead = {...action.data};
        if(updatedLead.client){
            if(updatedLead.client.fullName){
              updatedLead.lead = updatedLead.client.fullName;
            }else{
                if(updatedLead.client.lastName){
                    if(updatedLead.client.firstName){
                      updatedLead.lead = updatedLead.client.lastName + ' ' + updatedLead.client.firstName
                    }else{
                      updatedLead.lead = updatedLead.client.lastName
                    }
                }else{
                    if(updatedLead.client.firstName){
                      updatedLead.lead = updatedLead.client.firstName;
                    }
                }
            }
        }
        let patientName = ""
        if(updatedLead.patientObj.lastName){
            if(updatedLead.patientObj.firstName){
              patientName = updatedLead.patientObj.lastName + ' ' + updatedLead.patientObj.firstName
            }else{
              patientName = updatedLead.patientObj.lastName
            }
        }else{
            if(updatedLead.patientObj.firstName){
              patientName = updatedLead.patientObj.firstName;
            }
        }
        updatedLead.patient = patientName;
        updatedLead.location = updatedLead.client !== null ? updatedLead.client.address !== null ? (updatedLead.client.address.location!==null && updatedLead.client.address.location!=='')? updatedLead.client.address.location : updatedLead.client.address.city :'': '';
        updatedLead.phone = updatedLead.client !== null ? updatedLead.client.cellPhone != null ? updatedLead.client.cellPhone : updatedLead.client.phone != null ? updatedLead.client.phone : '' : '';
        updatedLead.email = updatedLead.client !== null ? updatedLead.client.email : '';
        return {
          ...state,
          leads: state.leads.map(element => element.leadId === updatedLead.leadId? 
            updatedLead
            :
            element
          ),
        };
    case ADD_LEAD_DATA:
        let element = {...action.data};
        element.leadId = action.leadId;
        if(element.client){
          if(element.client.fullName){
            element.lead = element.client.fullName;
          }else{
              if(element.client.lastName){
                  if(element.client.firstName){
                    element.lead = element.client.lastName + ' ' + element.client.firstName
                  }else{
                    element.lead = element.client.lastName
                  }
              }else{
                  if(element.client.firstName){
                    element.lead = element.client.firstName;
                  }
              }
          }
        }
        let patientAddName = ""
        if(element.patientObj.lastName){
            if(element.patientObj.firstName){
              patientAddName = element.patientObj.lastName + ' ' + element.patientObj.firstName
            }else{
              patientAddName = element.patientObj.lastName
            }
        }else{
            if(element.patientObj.firstName){
              patientAddName = element.patientObj.firstName;
            }
        }
        element.patient = patientAddName;
        element.location = element.client !== null ? element.client.address !== null ? (element.client.address.location!==null && element.client.address.location!=='') ? element.client.address.location : element.client.address.city :'': '';
        element.phone = element.client !== null ? element.client.cellPhone != null ? element.client.cellPhone : element.client.phone != null ? element.client.phone : '' : '';
        element.email = element.client !== null ? element.client.email : '';
        return {
          ...state,
          leads: [...state.leads, element]
        }
    case SET_PATIENTLEAD_DATABASE:
        return {
          ...state,
          patientLead: action.data,
          patientLeadState: ResponseStatus.READY,
          patientLeadTimeStamp: new Date().getTime()
        }
    case PENDING_PATIENTLEAD_DATABASE:
        return {
          ...state,
          patientLeadState: ResponseStatus.PENDING
        };
    case REMOVE_PATIENTLEAD_DATABASE:
        return {
          ...state,
          patientLead: [],
          patientLeadTimeStamp: 0
        };
    case UPDATE_PATIENT_DATA:
        return {
          ...state,
          patientLead: state.patientLead.map(element => element.id === action.data.id? 
            action.data
            :
            element
          ),
        };
    case ADD_PATIENT_DATA:
        let newPatient = {...action.data};
        newPatient.id = action.id;
        return {
          ...state,
          patientLead: [...state.patientLead, newPatient]
        }
    case SET_PATIENTCLIENT_DATABASE:
        return {
          ...state,
          patientClient: action.data,
          patientClientState: ResponseStatus.READY,
          patientClientTimeStamp: new Date().getTime()
        }
    case SET_WZ_DATABASE:
        return {
          ...state,
          wzData: action.wzData,
          surveyIds: action.surveyIds,
          wzState: ResponseStatus.READY,
          wzTimeStamp: new Date().getTime()
        }
    case PENDING_PATIENTCLIENT_DATABASE:
        return {
          ...state,
          patientClientState: ResponseStatus.PENDING
        };
    case REMOVE_PATIENTCLIENT_DATABASE:
        return {
          ...state,
          patientClient: [],
          patientClientTimeStamp: 0
        };
    case SET_CLIENTS_DATABASE:
        return {
          ...state,
          clients: action.data,
          clientsState: ResponseStatus.READY,
          clientsTimeStamp: new Date().getTime()
        }
    case PENDING_CLIENTS_DATABASE:
        return {
          ...state,
          clientsState: ResponseStatus.PENDING
        };
    case REMOVE_CLIENTS_DATABASE:
        return {
          ...state,
          clients: [],
          clientsTimeStamp: 0
        };
    case SET_MULTIPLIERS_DATABASE:
        return {
          ...state,
          multipliers: action.data,
          multipliersState: ResponseStatus.READY,
          multipliersTimeStamp: new Date().getTime()
        }
    case PENDING_MULTIPLIERS_DATABASE:
        return {
          ...state,
          multipliersState: ResponseStatus.PENDING
        };
    case REMOVE_MULTIPLIERS_DATABASE:
        return {
          ...state,
          multipliers: [],
          multipliersTimeStamp: 0
        };
    case UPDATE_MULTIPLIER_DATA:
        let multiplierUpdate = {...action.data};
        if(multiplierUpdate.lastName){
          if(multiplierUpdate.firstName){
            multiplierUpdate.multiplier = multiplierUpdate.lastName + ' ' + multiplierUpdate.firstName
          }else{
            multiplierUpdate.multiplier = multiplierUpdate.lastName
          }
        }else{
            if(multiplierUpdate.firstName){
              multiplierUpdate.multiplier = multiplierUpdate.firstName;
            }
        }
        return {
          ...state,
          multipliers: state.multipliers.map(element => element.id === multiplierUpdate.id? 
            multiplierUpdate
            :
            element
          ),
        };
    case ADD_MULTIPLIER_DATA:
        let multiplier = {...action.data};
        multiplier.id = action.id;
        multiplier.multiplier = multiplier.lastName + ' ' +  multiplier.firstName;
        return {
          ...state,
          multipliers: [...state.multipliers, multiplier]
        }
    case SET_INVOICES_DATABASE:
        return {
          ...state,
          invoices: action.data,
          invoicesState: ResponseStatus.READY,
          invoicesTimeStamp: new Date().getTime()
        }
    case PENDING_INVOICES_DATABASE:
        return {
          ...state,
          invoicesState: ResponseStatus.PENDING
        };
    case REMOVE_INVOICES_DATABASE:
        return {
          ...state,
          invoices: [],
          invoicesTimeStamp: 0
        };
    case ACCEPT_INVOICES_DATABASE:
        let invoice = {...action.data};
        invoice.status = action.status
        invoice.approval.Status = action.status;
        if(action.comment!==null){
          if(action.comment.toString().trim().length>0){
            invoice.approval.Comment = action.comment
          }
        }
        return {
          ...state,
          invoices: state.invoices.map(element => element.id === action.id? 
            invoice
            :
            element
          ),
        };
    case SET_TASKS_DATABASE:
        return {
          ...state,
          tasks: action.data,
          tasksState: ResponseStatus.READY,
          tasksTimeStamp: new Date().getTime()
        }
    case PENDING_TASKS_DATABASE:
        return {
          ...state,
          tasksState: ResponseStatus.PENDING
        };
    case UPDATE_TASKS_DATABASE:
        return {
          ...state,
          tasks: [...state.tasks, ...action.data],
          tasksTimeStamp: new Date().getTime()
        }
    case REMOVE_TASKS_DATABASE:
        return {
          ...state,
          tasks: [],
          tasksTimeStamp: 0
        };
    case UPDATE_TASK_DATA:
        return {
          ...state,
          tasks: state.tasks.map(element => element.id === action.id? 
            action.data
            :
            element
          ),
        };
    case ADD_TASK_DATA:
        let task = {...action.data};
        task.id = action.id;
        return {
          ...state,
          tasks: [...state.tasks, task]
        }
    case FINISHED_TASKS_DATABASE:
        return {
          ...state,
          tasksState: ResponseStatus.READY,
        };
    case CLEAR_ALL_DATABASE:
        return {
          ...initialState
        };
    case SET_CARE_BOX_ID:
      return {
        ...state,
        careBoxId: action.data.careBoxId,
        careBoxIngredientsId: action.data.ingredientsId,
      }
    case SET_CARE_BOX_SETS:
      return {
        ...state,
        careBoxSets: action.data,
      }
    case SET_CARE_BOX_SETS_LIST:
      return {
        ...state,
        careBoxSetsList: action.data,
      }
    case SET_GLOVE_TYPE_LIST:
      return {
        ...state,
        gloveType: action.data,
      }
      
    case SET_CARE_BOX_INDIVIDUAL_PRICES:
      return {
        ...state,
        careBoxIndividualPrices: action.data,
      }
    default:
      return state;
  }
};

export default reducer;