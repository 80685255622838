import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//kopiloten
import { MultiplierTable, Alert } from "../../../_constance/classes/index";
import axiosAuth from "../../../_services/config/axios-auth";
import { refreshTokenTime } from '../../../_constance/base_data';
import { shouldRefresh, KoPilotenCategoryHelper } from "../../../_helpers/index";
import { setMultipliersData, pendingMultipliersData, removeMultipliersData, setCustomersOptions } from "../../../_redux/actions/index";
import KoPilotenCategoryComponent from '../../../_components/MultipliersCategoryComponents/Ko-Piloten-Category-Component';
import { LoaderTable, EmptyTableIcon, LoaderModal, InfoModal, ConfirmModal, AlertModalInfo, MultiPatientModal } from "../../../_components/index";
// KoPiloten Functions
import { getMultiplierCategoryList, getMultiplierCategoryRange, saveMultiplierCategoryRange } from "../../../_services/kopiloten_service";
import { KoPilotenMainForm, KoPilotenCategory, KoPilotenSaveRangeCategory}  from "../../../_forms/KoPilotenForm/index";
import { CategoriesKeyList } from "../../../_forms/KoPilotenForm/values/categoriesKeyList";
import {
  generatePdfBasedOnMultipliers,
  getOptionData,
  cancelContractSimpleOption,
  saveOptionData,
  getFooterData
} from '../../../_services/backend_service';
import {
  getGreetings
} from '../../../_services/footer_formatter';
// import { generatePdfBasedOnMultipliers, getOptionData, cancelContractSimpleOption } from '../../../_services/backend_service';
import * as Gender from "../../../_constance/enums/Gender";
import { baseData} from '../../../_constance/base_data';
import BBFormService from '../../../_services/bbform';
import { FormHtmlHelper, RodoHtmlHelper} from '../../../_helpers/rodo_html_helper';
import { formatPrice } from '../../../_helpers/price_format';
import { getSectionNumberByName } from "../../../_constance/enums/sectionPageList";
// components
import SummaryMenu from './components/SummaryMenu';
import SectionDieBestellung from '../SectionDieBestellung/SectionDieBestellung';
/* hiiden due to FP-343
import SectionSuchanfrage from '../SectionSuchenfragen/SectionSuchanfrage';
*/
// menu sections components
import { 
  FirstSliderContent,
  SecondSliderContent,
  SpracheSection,
  AdditionalOptionsSection,
  CartSection,
  OfferSection,
  CancelContract,
  SumaryWhySection
} from './components/index';

// consts, utils
import { 
  SUMMARY_OFFER_MENU_SECTIONS,
  MENU_SECTIONS,
  OFFER_SECTION,
  SECTION_OUTSIDE_MENU,
  DIE_BESTELLUNG_SECTION,
  DIE_SUCHANfRAGE,
  CANCEL_CONTRACT
} from '../../../_constance/enums/summaryOfferMenuSections'; 

import { LANGUAGE_PACKET } from '../../../_constance/enums/summaryOfferLanguagePackets';
import {  ADDITIONAL_OPTIONS } from '../../../_constance/enums/summaryOfferAdditionalOptions';
import {  CANCELATION_EMAIL_ENUMS } from '../../../_constance/enums/enumsForCancelationEmail';
import { translateKey } from "../../../_redux/actions/index";
import * as SkillRange from "../../../_forms/BBForm/values/skillRange";
import { getUserProducts, getRegionsFP } from "../../../_redux/actions/bb-form-services";
import * as FormTypeToShow from '../../../_forms/BBForm/values/formTypeToShow';
import * as MultiplierStatus from "../../../_constance/enums/MultiplierStatus";
import CancelationObj from "./classes/CancelationObj";
import produce from "immer";
import { saveStats } from "../../../_services/backend_service";
import { StatsType } from "../../../_constance/enums/statsType";
import { PriceType } from '../../../_constance/enums/priceType';
import * as Entity from '../../../_forms/BBForm/values/entity';

// additional styles for the whole group of final offer
import "./SectionOfferSummary.css";

// key needed to retrieve information of 3 the most things for patient from last section in form
const MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME = 'is_most_important_taking_care_patient';

// key needed to retrieve information about hearing in form
const IS_COMMUNICATION_HEARING = "is_communication_hearing";

// key needed to retrieve information about automat gearbox selected in form
const IS_DRIVING_AUTOMAT_GEARBOX = 'is_driving_automat_gearbox';

// key needed to retrieve information about manual gearbox selected in form
const IS_DRIVING_MANUAL_GEARBOX = 'is_driving';

// key needed to retrieve information about activities additional people
const IS_ACTIVITIES_ADDITIONAL_PEOPLE = "is_activities_additional_people";

// key needed to retrieve information about pflegegrad
const IS_PFLEGEGRAD = 'is_pflegegrad';

// key needed to retrieve information about stoma
const IS_STOMA_LIST = "is_stoma_list";

// key needed to retrieve information about cancer
const IS_CANCER_LIST = "is_cancer_list";

// key needed to retrieve information about bedsores
const IS_BEDSORES_LIST = "is_bedsores_list";

// key needed to retrieve information about patient lying
const IS_LYING_PATIENT = "is_lying_patient";

// key needed to retrieve information about bed care
const IS_BED_CARE = "is_bed_care";

// key needed to retrieve information about regular change of position
const IS_REGULAR_CHANGE_OF_POSITION ="is_regular_change_of_position";

// key needed to retrieve information about pallative care
const IS_PALLIATIVE_CARE = "is_palliative_care";

// key needed to retrieve information about patient help change position
const IS_PATIENT_HELP_CHANGE_POSITION = "is_patient_help_change_position";

// key needed to retrieve information about carrying bed wheelchair
const IS_CARRYING_BED_WHEELCHAIR = "is_carrying_bed_wheelchair";

// key needed to retrieve information about cather 
const IS_CATHER_LIST ="is_catheter_list";

// key needed to retrieve information about catheterized through the abdominal wall
const IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL = "is_catheterized_through_the_abdominal_wall";

// key needed to retrieve information about sonda peg
const IS_SONDA_PEG = "is_sonda_peg";

// key needed to retrieve information about how often carer must get up at night
const IS_HOW_OFTEN_CARER_MUST_GET_UP_AT_NIGHT = "is_how_often_carer_must_get_up_at_night_de";

// key to save option in DB
const SPREACHE = 1;
const ADDITIONAL = 2;

//client gender key
const genderKey=  'is_candidate_gender';

// key needed to retrieve information about pflegegrad
const IS_KEIN_PFLEGEGRAD = 'is_kein_pflegegrad';

// key needed to retrieve information about Bawaria postalcode
const IS_SERVICE_ADDRESS_POSTAL_CODE = 'is_service_address_postal_code';

//key name to select isBawaria discount 
const bawariaKeyName = 'fee_bayern';

//key of two patient checkbox
const FEE_2NDPAT = 'fee_2ndpat';

class SectionOfferSummary extends React.Component {
  _isMounted = false;
  myTimeout;

  constructor(props) {
    super(props);
    this.state = {
      element: props.data,
      analizeOffer: false,
      progressWidth: 0,
      myInterval: null,
      choosenOptionAmount: 0,
      section: props.form.getSectionByKey('section-16'),
      tempSection: props.form.getSectionByKey('section-initial-offer'),
      initialSection: SUMMARY_OFFER_MENU_SECTIONS[0].labelKey,
      activeSection: undefined,
      isVideoActive: false,

      availableLanguagePackets: this.getAvailableLanguagePackets(),
      recommendedLanguagePacket: LANGUAGE_PACKET.PACKET_300,
      selectedLanguagePacket: LANGUAGE_PACKET.PACKET_300,

      availableAdditionalOptions: this.getAvailableAdditionalOptions(),
      recommendedAdditionalOptions: [],
      selectedAdditionalOptions: [],
      selectedAdditionalOptionsForOfferSection: [],
      savedAdditionalOption: [],
      selectedAdditionalOptionToSave: [],
      savedLanguagePacket: null,
      gettingDataFromDbStatusReady: false,

      cannotChangePromPlus: false,
      displayPriceInOffer: false,
      loadingMultipliers: true,
      products: [],
      productsLoaded: false,
      regionsFP: [],
      regionsFPLoaded: false,


      //KOPILOTEN DATA
      KoPilotenEdited: false,
      KoPilotenActiveCategoryId: '0',
      KoPilotenLoadingBasics: true,
      KoPilotenForm: new KoPilotenMainForm(),
      KoPilotenRerender: Date.now(),
      KoPilotenSaving: false,
      KoPilotenModificatedCategoryList:[],
      KoPilotenErrorComponent: Alert,
      infoModalOptions: Alert,
      loaderModal: false,

      confirmCancellation: false,
      cancelationOptions: [],
      errorComponent: Alert,

      isAditionalPatient: false,
      savedLanguagePacketSecondPatient: null,
      selectedAdditionalOptionToSaveSecondPatient: [],
      showMultiPatientModal: false,
      isMultiplePatients: false,
      showRedirection: false,
      rerenderOptions: true,
      rerenderAditionalOptions: true,
      canCreateContract: props.canCreateContract,
      customGreetingsText: null
    };
  }

  componentDidMount() {
    const {match: { params }} = this.props;
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    this.loadCustomGreetings();
    this.inCaseWhereAnalizeNotNeeded();

    // get initial data
    this.getDataSavedInKundeDB(params.patientId, false);
    this.getDataSavedInKundeDB(this.props.secondPatientId, true);
    this.getRecommendedLanguagePacket();
    this.getRecommendedAdditionalOptions();
    this.getProductData();
    this.getRegionsData();
    this.prepareMeBasicKoPilotenFormData();

    if (this.props.isOfferOpened) {
      this.setState({
        activeSection: undefined
      })
    }

    this.getInitialMultipliers();
    this.getAvailableLanguagePackets();

    // const { match: { params } } = this.props;
    const type = params.type;
    if (type === FormTypeToShow.ENQUIRY) {
      this.props.setActiveSection(DIE_SUCHANfRAGE);
    }else{
      if(type === FormTypeToShow.CONTRACT){
        this.props.setActiveSection(DIE_BESTELLUNG_SECTION);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.myInterval);
    clearTimeout(this.myTimeout);

    //Kopiloten
    const { KoPilotenEdited } = this.state;
    if (KoPilotenEdited) {this.saveChangeKoPiloten();}
  };


  loadCustomGreetings = () => {
    getFooterData(this.props.userId).then(response => {
      if (response && this._isMounted) {
        this.setState({
          customGreetingsText: response.data.data[0].wysiwyg
        })
      }
    })
  }


  //KOPILOTEN FUNCTIONS
  saveChangeKoPiloten =(withoutMessage=false) =>{
    const { KoPilotenForm, KoPilotenModificatedCategoryList } = this.state;
    if(KoPilotenModificatedCategoryList.length>0){
      if(this._isMounted){this.setState({KoPilotenSaving: true});}
      KoPilotenModificatedCategoryList.forEach((catIdData, index) => {
        const foundedCat =  KoPilotenForm.getCategoryByKey(catIdData);
        if(foundedCat){
          const dataToSend = new KoPilotenSaveRangeCategory(foundedCat.MultiplierRangeId, foundedCat.CategoryId, foundedCat.ClientId, foundedCat.Note, foundedCat.MultiplierIds);
          saveMultiplierCategoryRange(JSON.parse(JSON.stringify(dataToSend)))
          .then(res => {
            foundedCat.MultiplierRangeId = res;
            if(!withoutMessage){
              if (this._isMounted) {
                this.setState({
                  KoPilotenEdited: false,
                  KoPilotenSaving: false,
                  KoPilotenErrorComponent: {
                    show: true,
                    type: "success",
                    message: "ko-piloten-multiplikator-success-assigned"
                  },
                })
                document.body.classList.remove('overflow-y-scroll');
              }
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({ 
                    KoPilotenErrorComponent: Alert,
                    KoPilotenSaving: false
                  });
                }
              }, 2500);
            }
          })
          .catch(error => {
            if(!withoutMessage){
              if (this._isMounted) {
                this.setState({
                  KoPilotenSaving: false,
                  KoPilotenErrorComponent: {
                    show:true,
                    type: "danger",
                    message: "basic_error_message"
                  }
                });
                document.body.classList.remove('overflow-y-scroll');
              }
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({ 
                    KoPilotenErrorComponent: Alert,
                    KoPilotenSaving: false
                  });
                }
              }, 2500);
            }
          });
        }else{
          if(this._isMounted){this.setState({KoPilotenSaving: false});}
        }
      });
    }else{
      if(!withoutMessage){
        if(this._isMounted){this.setState({KoPilotenSaving: true});}
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ 
              KoPilotenSaving: false,
              KoPilotenEdited: false
            });
          }
        }, 500);
      }
    }
  }

  putNewCategoryKoPiloten(catId, label, multi) {
    const {match: { params } } = this.props;
    const nextState = produce(this.state, (draftState) => {
      const { KoPilotenForm } = draftState;
      KoPilotenForm.categories.push(
        new KoPilotenCategory(
          null,
          KoPilotenCategoryHelper.getMeNameBasedOnLabel(label),
          catId,
          params.id,
          null,
          multi.map((element)=>{return element.toUpperCase()}),
          [],
          KoPilotenCategoryHelper.getMeSubcategoriesBasedOnLabel(label),
          [],
          KoPilotenCategoryHelper.isSubCategory(label)
        )
      );
    });
    this.setState(nextState);
  }

  prepareMeBasicKoPilotenFormData() {
    const { multiCategoryEnum } = this.props;
    const { loadingMultipliers } = this.state;
    if(multiCategoryEnum.length > 0 && !loadingMultipliers ) {
      clearTimeout(this.myTimeouKoPiloten);
      let count = 0;
      multiCategoryEnum.forEach((element, index) => {
        getMultiplierCategoryList(element.Value)
        .then((res) => {
          count++;
          this.putNewCategoryKoPiloten(element.Value, element.Label, res.MultiplierIds); 
          if(count===multiCategoryEnum.length-1){
            this.myTimeouKoPiloten = setTimeout(() => {this.prepareMultiplierRangeKoPiloten()}, 500);
          }
        })
        .catch(error => {
          count++;
          if(count===multiCategoryEnum.length-1){
            this.myTimeouKoPiloten = setTimeout(() => {this.prepareMultiplierRangeKoPiloten()}, 500);
          }
        });
      });
    } else {
      this.myTimeouKoPiloten = setTimeout(() => {this.prepareMeBasicKoPilotenFormData();}, 100);
    }
  }

  prepareMultiplierRangeKoPiloten() {
    const {match: { params } } = this.props;
    const { KoPilotenForm } = this.state;
    getMultiplierCategoryRange(params.id)
      .then((res) => {
        if (res.length > 0) {
          res.forEach((element, index) => {
            let newObj = {
              MultiplierIds: element.MultiplierIds.map((element)=>{return element.toUpperCase()}),
              MultiplierRangeId: element.MultiplierRangeId,
              Note: element.Note,
            };
            KoPilotenForm.setDataToCategory(newObj, element.CategoryId.toUpperCase());
            if (index === res.length - 1) {
              this.finalPreparationKoPiloten();
            }
          });
        } else {
          this.finalPreparationKoPiloten();
        }
      })
      .catch((error) => {
        console.log(error);
        this.finalPreparationKoPiloten();
      });
  }

  finalPreparationKoPiloten(){
    const { KoPilotenForm } = this.state;
    CategoriesKeyList.forEach((element,index) => {
      const category = KoPilotenForm.getCategoryByName(element.key);
      KoPilotenForm.moveCategory(index, category);
    });  
    let activeCat = '0';
    KoPilotenForm.categories.forEach((element, index) => {
      if(activeCat==='0'){
        if(element.gotMultipliersToSelect){
          activeCat = element.CategoryId;
        }
      }
      if (element.IsSubCategory === false) {
        if (element.SubCategoriesKeyList.length > 0) {
          element.SubCategoriesKeyList.forEach((transKey) => {
            let subKey = KoPilotenForm.getCategoryIdByName(transKey);
            if (subKey) {
              KoPilotenForm.assingSubCategorie(element.CategoryId, subKey);
            }
          });
        }
      }
      if (index >= KoPilotenForm.categories.length - 1) {
        this.myTimeouKoPiloten = setTimeout(() => {
          this.setState({
            KoPilotenLoadingBasics: false,
            KoPilotenActiveCategoryId: activeCat
          });
        }, 100);
      }
    });
  }

  changeCategoryActiveKoPiloten = (id) => {
    this.setState({KoPilotenActiveCategoryId: id})
  }

  addMultiToCategory=(multiplicatorId, value)=>{
    const { KoPilotenForm, KoPilotenActiveCategoryId, KoPilotenModificatedCategoryList } = this.state;
    if(!this.props.disabled){
      let add="ADD";
      if(!value){add = "REMOVE";}
      KoPilotenForm.addOrRemoveMultipliersFromToCategory(multiplicatorId, KoPilotenActiveCategoryId, add);
      const exists = KoPilotenModificatedCategoryList.indexOf(KoPilotenActiveCategoryId);
      if(exists===-1){
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenModificatedCategoryList: [...prevState.KoPilotenModificatedCategoryList, KoPilotenActiveCategoryId],
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }else{
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }
    }
  }

  changeNoteDataKopiloten=(key, val)=>{
    if(!this.props.disabled){
      const { KoPilotenForm, KoPilotenModificatedCategoryList} = this.state;
      const editedCategory = KoPilotenForm.getCategoryByKey(key);
      if(editedCategory){editedCategory.Note = val;}
      const exists = KoPilotenModificatedCategoryList.indexOf(key);
      if(exists===-1){
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenModificatedCategoryList: [...prevState.KoPilotenModificatedCategoryList, key],
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }else{
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }
    }
  }

  getMeMultiEmailContent = () =>{
    const { translateKey, fpName, fpFirstMail, form, fpPhone } = this.props;
    const tdStyles = 'border:0;padding:0;';
    const spanClass = 'font-weight:bold;color:#000000;';
    const breakLineTable = `<tr><td style="border:0;padding: 0px 0 20px 0;"></td></tr>`;
    const genderClient = form.getFieldByKey(genderKey);
    let genderText = '';
    if(genderClient.value === parseInt(Gender.MALE)){
      genderText =  translateKey('contract-text-herr');
    }else{
      if(genderClient.value === parseInt(Gender.FEMALE)){
        genderText =  translateKey('form-text-frau');
      }
    }
    const greetings = getGreetings(this.state.customGreetingsText, breakLineTable, tdStyles, fpName, fpPhone, fpFirstMail);
    const lastNameField = form.getFieldByKey('lastname');
    const htmlMailContent = `
    <table style="width:100%;color:black;border:0;outline:0;">
      <tbody>
        <tr><td style="${tdStyles}">Sehr geehrter <span style="${spanClass}">${genderText} ${lastNameField.value},</span></td></tr>
        ${breakLineTable}
        <tr><td style="${tdStyles}">Im Anhang finden Sie die Liste der Ko-Piloten. </td></tr>
        ${breakLineTable}
        <tr><td style="${tdStyles}">Bei Fragen stehe ich Ihnen gerne zur Verfügung. </td></tr>
        ${greetings}
      </tbody>
    </table>
    `;
    return htmlMailContent;
  }

  prepareDataAndSendPDF = () =>{
    const { KoPilotenForm, loadingMultipliers} = this.state;
    const { multipliersData, translateKey, form } = this.props;
    const isEmailField = form.getFieldByKey('is_email');
    if(!loadingMultipliers && multipliersData!==false && multipliersData !== null && multipliersData.length > 0){
      if(isEmailField.value){
        this.setState({loaderModal: true})
        let pdfDataToSend = [];
        const catList = [...KoPilotenForm.categories];
        catList.forEach(cat => {
          if(cat.SubCategoriesList.length===0){
            //main category
            if(cat.gotMultipliers(multipliersData)){
              let multipliersDataToSend = [];
              cat.MultiplierIds.forEach(multiId => {
                let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                if(foundMulti){
                  if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                    let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                    if(exists>-1){
                      multipliersDataToSend.push(foundMulti);
                    }
                  }
                }
              });
              if(multipliersDataToSend.length>0){
                // we got main category and some multipliers with data to send
                pdfDataToSend.push({
                  parentName: translateKey(cat?cat.Name:''),
                  childName: null,
                  categoryId: cat?cat.CategoryId:'',
                  note: cat?cat.Note:'',
                  multipliersData: multipliersDataToSend
                })
              }
            }
          }else{
            //got subcategories
            cat.SubCategoriesList.forEach(subCat => {
              if(subCat.gotMultipliers(multipliersData)){
                let multipliersDataToSend = [];
                subCat.MultiplierIds.forEach(multiId => {
                  let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                  if(foundMulti){
                    if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                      let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                      if(exists>-1){
                        multipliersDataToSend.push(foundMulti);
                      }
                    }
                  }
                });
                if(multipliersDataToSend.length>0){
                  // we got subcategory and some multipliers with data to send
                  pdfDataToSend.push({
                    parentName: translateKey(cat?cat.Name:''),
                    childName: translateKey(subCat?subCat.Name:''),
                    categoryId: subCat?subCat.CategoryId:'',
                    note: subCat?subCat.Note:'',
                    multipliersData: multipliersDataToSend
                  })
                }
              }
            });
          }
        });
        if(pdfDataToSend.length>0){
          //got some checked multipliers so we can generate pdf
          const koPilotenDataToSend = {
            userID: this.props.userId,
            list: pdfDataToSend,
            toEmail: isEmailField.value,
            content: this.getMeMultiEmailContent()
          }
          generatePdfBasedOnMultipliers(koPilotenDataToSend)
          .then(res => {
            if(res){
              if(res.status===200){
                if (this._isMounted) {
                  this.setState({
                    loaderModal: false,
                    infoModalOptions: {
                      show: true,
                      type: "success",
                      message: translateKey('ko-piloten-success-pdf-send')
                    },
                  })
                }
              }else{
                if (this._isMounted) {
                  this.setState({
                    loaderModal: false,
                    infoModalOptions: {
                      show: true,
                      type: "danger",
                      message: translateKey('basic_error_message')
                    }
                  })
                }
              }
            }
          })
          .catch((error) => {
            if (this._isMounted) {
              this.setState({
                loaderModal: false,
                errorComponent: {
                  show: true,
                  type: "danger",
                  message: "basic_error_message"
                },
              })
              document.body.classList.remove('overflow-y-scroll');
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({ 
                    errorComponent: Alert,
                    KoPilotenSaving: false
                  });
                }
              }, 3000);
            }
          });
        }else{
          if (this._isMounted) {
            this.setState({
              loaderModal: false,
              infoModalOptions: {
                show: true,
                type: "danger",
                message: translateKey('ko-piloten-at-least-one-category')
              }
            })
          }
        }
      }else{
        this.setState({
          loaderModal: false,
          infoModalOptions: {
            show: true,
            type: "danger",
            message: translateKey("form-text-err_not_an_email_address")
          },
        })
      }
    }
  }

  hideInfo = () => {
    this.setState({
      infoModalOptions: {
        show: false,
        type: "danger",
        message: this.props.translateKey("basic_error_message")
      },
    })
  }

  getDataSavedInKundeDB = (patientID, isSecondPatient) =>{
    const {match: { params } } = this.props;
    getOptionData(params.id, patientID)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            let savedLanguagePacket = null;
            let savedAdditionOption = [];
            res.data.data.forEach(element => {
              const parsedElement = JSON.parse(element.value);
              if(element.type===SPREACHE.toString()){
                for (const element in parsedElement) {
                  for (const key in parsedElement[element]) {
                    savedLanguagePacket = key;
                    break;
                  }
                }
              }else{
                savedAdditionOption = parsedElement;
                const isMultipatientSelected = parsedElement.map(element => {
                  if(element.hasOwnProperty(FEE_2NDPAT)) {
                    return element.fee_2ndpat
                  } else {
                    return false;
                  }
                }).some(el => el);
                if(isMultipatientSelected) {
                  this.setState(
                    {
                      isAditionalPatient: true,
                    },
                  );
                }
              }
            });
            if(savedLanguagePacket!==null || savedAdditionOption.length>0){
              if(isSecondPatient) {
                this.setState({
                  savedLanguagePacketSecondPatient: savedLanguagePacket!==null?savedLanguagePacket:null,
                  selectedAdditionalOptionToSaveSecondPatient: savedAdditionOption.length>0?savedAdditionOption:[]
                })
              } else {
                this.setState({
                  gettingDataFromDbStatusReady: true,
                  //selectedLanguagePacket: savedLanguagePacket!==null?savedLanguagePacket:this.state.selectedLanguagePacket,
                  savedLanguagePacket: savedLanguagePacket!==null?savedLanguagePacket:null,
                  selectedAdditionalOptionToSave: savedAdditionOption.length>0?savedAdditionOption:[]
                })
              }
            }
            setTimeout(() => {
              this.getRecommendedLanguagePacket();
              this.getRecommendedAdditionalOptions();
            }, 150);
          }
        }else{
          if(!isSecondPatient) {
            this.setState({gettingDataFromDbStatusReady: true})
          }
          this.getRecommendedLanguagePacket();
          this.getRecommendedAdditionalOptions();
          console.log("Unable to get data from DB");
        }
      }
    })
    .catch((error) => {
      if(!isSecondPatient) {
        this.setState({gettingDataFromDbStatusReady: true})
      }
      this.getRecommendedLanguagePacket();
      this.getRecommendedAdditionalOptions();
      console.log("Unable to get data from DB");
    });
  }

  getProductData = () => {
    this.props.getProducts().then((data) => {
      if (this._isMounted) {
        if (data.length > 1) {
          data.sort((a, b) => {
            const nameA = a.ProductName;
            const nameB = b.ProductName;
            if (nameA === null || nameA === undefined) { return -1; }
            else if (nameB === null || nameB === undefined) { return 1; }
            else if (nameA === nameB) { return 0; }
            if (nameA.localeCompare(nameB) === -1) { return -1; }
            else { return 1; }
          })
        }
        this.setState({
          products: data,
          productsLoaded: true
        });
      }
    })
    .catch((error) => {
        console.log(error);
    })
  }
  
  getRegionsData = () => {
    this.props.getRegions().then((data) => {
      if (this._isMounted) {
        this.setState({
          regionsFP: data,
          regionsFPLoaded: true
        });
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  getAvailableAdditionalOptions = () => {
    return this.props.promedicaProductsPrices.filter(item => item.category === "Dodatek" && item.id !== 'fee_2ndlang');
  }

  getAvailableLanguagePackets = () => {
    return this.props.promedicaProductsPrices.filter(item => item.category === "Produkt");
  }

  static getDerivedStateFromProps(props, state) {
    if(props.isOfferOpened) {
      return {activeSection: undefined}
    }
    return null;
  }

  getInitialMultipliers(){
    const lastUpdate = this.props.multipliersTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getMultipliersList();
    } else {
      if (this._isMounted) {
        this.setState({
          loadingMultipliers: false,
        });
      }
    }
  }
  
  getRecommendedLanguagePacket = () => {
    const { form, secondPatientForm } = this.props;
    const { savedLanguagePacket, savedLanguagePacketSecondPatient, isAditionalPatient } = this.state;
    if(savedLanguagePacket || savedLanguagePacketSecondPatient){
      if (isAditionalPatient) {
        let higherPacket = '';
        const firstPatientPacketValue = savedLanguagePacket ? parseInt(savedLanguagePacket.replace('fee_', '')) : 100;
        const secondPatientPacketValue = savedLanguagePacketSecondPatient ? parseInt(savedLanguagePacketSecondPatient.replace('fee_', '')) : 100;
  
        if(firstPatientPacketValue >= secondPatientPacketValue) {
          higherPacket = savedLanguagePacket;
        } else {
          higherPacket = savedLanguagePacketSecondPatient;
        }
        for (const key in LANGUAGE_PACKET) {
          if(LANGUAGE_PACKET[key] === higherPacket){
            this.setState({
              // recommendedLanguagePacket: savedLanguagePacket,
              selectedLanguagePacket: higherPacket
            })
          }
        }
      }else {
        if(savedLanguagePacket) {
          for (const key in LANGUAGE_PACKET) {
            if(LANGUAGE_PACKET[key] === savedLanguagePacket){
              this.setCustomersData(savedLanguagePacket, this.state.selectedAdditionalOptions);
              this.setState({
                // recommendedLanguagePacket: savedLanguagePacket,
                selectedLanguagePacket: savedLanguagePacket
              })
            }
          }
        } else {
          this.setState({
            selectedLanguagePacket: this.state.recommendedLanguagePacket,
          })
        }
      }
    }else{
      if (isAditionalPatient) {
        const hearing = form.getFieldByKey(IS_COMMUNICATION_HEARING);
        const field = form.getFieldByKey(MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME);
        const hearingSecondPatient = secondPatientForm.getFieldByKey(IS_COMMUNICATION_HEARING);
        const fieldSecondPatient = secondPatientForm.getFieldByKey(MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME);
  
        if(field.value.indexOf("Dobre umiejętności językowe opiekunki")>-1 ||
          field.value.indexOf("Eine Betreuungskraft mit kommunikativen Sprachkenntnissen")>-1 ||
          fieldSecondPatient.value.indexOf("Dobre umiejętności językowe opiekunki")>-1 ||
          fieldSecondPatient.value.indexOf("Eine Betreuungskraft mit kommunikativen Sprachkenntnissen")>-1
        ){
          this.setCustomersData(LANGUAGE_PACKET.PACKET_500, this.state.selectedAdditionalOptions);
          this.setState({
            recommendedLanguagePacket: LANGUAGE_PACKET.PACKET_500,
            selectedLanguagePacket: LANGUAGE_PACKET.PACKET_500
          })
        } else {
          if (hearing.value === SkillRange.LIMITED || hearingSecondPatient.value === SkillRange.LIMITED) {
            this.setCustomersData(LANGUAGE_PACKET.PACKET_100, this.state.selectedAdditionalOptions);
            this.setState({
              recommendedLanguagePacket: LANGUAGE_PACKET.PACKET_100,
              selectedLanguagePacket: LANGUAGE_PACKET.PACKET_100
            })
          } else {
            this.setCustomersData(LANGUAGE_PACKET.PACKET_300, this.state.selectedAdditionalOptions);
            this.setState({
              recommendedLanguagePacket: LANGUAGE_PACKET.PACKET_300,
              selectedLanguagePacket: LANGUAGE_PACKET.PACKET_300
            })
          }
        }
      } else {
        const hearing = form.getFieldByKey(IS_COMMUNICATION_HEARING);
        const field = form.getFieldByKey(MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME);

        if(field.value.indexOf("Dobre umiejętności językowe opiekunki")>-1 ||
          field.value.indexOf("Eine Betreuungskraft mit kommunikativen Sprachkenntnissen")>-1
        ){
          this.setCustomersData(LANGUAGE_PACKET.PACKET_500, this.state.selectedAdditionalOptions);
          this.setState({
            recommendedLanguagePacket: LANGUAGE_PACKET.PACKET_500,
            selectedLanguagePacket: LANGUAGE_PACKET.PACKET_500
          })
        } else {
          if (hearing.value === SkillRange.LIMITED) {
            this.setCustomersData(LANGUAGE_PACKET.PACKET_100, this.state.selectedAdditionalOptions);
            this.setState({
              recommendedLanguagePacket: LANGUAGE_PACKET.PACKET_100,
              selectedLanguagePacket: LANGUAGE_PACKET.PACKET_100
            })
          } else {
            this.setCustomersData(LANGUAGE_PACKET.PACKET_300, this.state.selectedAdditionalOptions);
            this.setState({
              recommendedLanguagePacket: LANGUAGE_PACKET.PACKET_300,
              selectedLanguagePacket: LANGUAGE_PACKET.PACKET_300
            })
          }
        }
      }
    }
  }

  getMultipliersList = () => {
    if (this._isMounted) {this.setState({ loadingMultipliers: true })}
    this.props.pendingMultiplikatorenRequest();
    axiosAuth.get("multipliers/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new MultiplierTable(element);});
        this.props.setMultiplikatorenData(mapedRes);
        if (this._isMounted) {
          this.setState({
            loadingMultipliers: false,
          });
        }
      })
      .catch((error) => {
        this.props.clearMultiplikatorenData();
        this.setState({
          loadingMultipliers: false,
        });
      });
  }

  exitsInAdditionalSavedOption = (name) =>{
    const { savedAdditionalOption } = this.state;
    if(savedAdditionalOption.length>0){
      for (const element in savedAdditionalOption) {
        for (const key in savedAdditionalOption[element]) {
          if(key === name){
            return savedAdditionalOption[element][key];
          }
        }
      }
    }
    return null
  }

  getRecommendedAdditionalOptions = () => {
    const { form, secondPatientForm } = this.props;
    const { isAditionalPatient } = this.state;
    const additionalOptions = [];
    const recommendedAdditionalOptions = [];

    // get data from form fields
    const automatGearbox = form.getFieldByKey(IS_DRIVING_AUTOMAT_GEARBOX);
    const manualGearbox = form.getFieldByKey(IS_DRIVING_MANUAL_GEARBOX);
    const activitiesAdditionalPeople = form.getFieldByKey(IS_ACTIVITIES_ADDITIONAL_PEOPLE);
    const automatGearboxSecondPatient = secondPatientForm.getFieldByKey(IS_DRIVING_AUTOMAT_GEARBOX);
    const manualGearboxSecondPatient = secondPatientForm.getFieldByKey(IS_DRIVING_MANUAL_GEARBOX);

    // get priority list
    const field = form.getFieldByKey(MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME);
    const fieldSecondPatient = secondPatientForm.getFieldByKey(MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME);
    let drivingLicense = false;
    if (isAditionalPatient) {
      if(field.value.indexOf("Prawo jazdy")>-1 ||
        field.value.indexOf("Führerschein")>-1 ||
        fieldSecondPatient.value.indexOf("Prawo jazdy")>-1 ||
        fieldSecondPatient.value.indexOf("Führerschein")>-1
      ) {
        drivingLicense = true;
      }
    } else {
      if(field.value.indexOf("Prawo jazdy")>-1 ||
        field.value.indexOf("Führerschein")>-1
      ) {
        drivingLicense = true;
      }
    }
    
    if (drivingLicense) {
      recommendedAdditionalOptions.push(ADDITIONAL_OPTIONS.OPTION_LICENSE);
      const DLexists = this.exitsInAdditionalSavedOption(ADDITIONAL_OPTIONS.OPTION_LICENSE);
      if(DLexists!==null){
        if(DLexists===true){
          additionalOptions.push(ADDITIONAL_OPTIONS.OPTION_LICENSE);
        }
      }else{
        additionalOptions.push(ADDITIONAL_OPTIONS.OPTION_LICENSE);
      }
    }else{
      let exists = this.exitsInAdditionalSavedOption(ADDITIONAL_OPTIONS.OPTION_LICENSE);
      if(exists!==null){
        if(exists===true){
          additionalOptions.push(ADDITIONAL_OPTIONS.OPTION_LICENSE);
        }
      }
    }

    let exists = this.exitsInAdditionalSavedOption(ADDITIONAL_OPTIONS.OPTION_2NDLANG);
    if(exists!==null){
      if(exists===true){
        additionalOptions.push(ADDITIONAL_OPTIONS.OPTION_2NDLANG);
      }
    }

    exists = this.exitsInAdditionalSavedOption(ADDITIONAL_OPTIONS.OPTION_DIPLOMA);
    if(exists!==null){
      if(exists===true){
        additionalOptions.push(ADDITIONAL_OPTIONS.OPTION_DIPLOMA);
      }
    }

    let promedicaPlus = [];

    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradSecondPateint = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);

    if (isAditionalPatient) {
      if (pflegegrad.value.toString() === '717700003' || 
      pflegegrad.value.toString() === '717700004' ||
      pflegegradSecondPateint.value.toString() === '717700003' || 
      pflegegradSecondPateint.value.toString() === '717700004'
      ) {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false);
      }
    } else {
      if (pflegegrad.value.toString() === '717700003' || pflegegrad.value.toString() === '717700004') {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false);
      }
    }
    
    const stoma = form.getFieldByKey(IS_STOMA_LIST);
    const cancer = form.getFieldByKey(IS_CANCER_LIST);
    const bedsores = form.getFieldByKey(IS_BEDSORES_LIST);

    const stomaSecondPateint = secondPatientForm.getFieldByKey(IS_STOMA_LIST);
    const cancerSecondPateint = secondPatientForm.getFieldByKey(IS_CANCER_LIST);
    const bedsoresSecondPateint = secondPatientForm.getFieldByKey(IS_BEDSORES_LIST);

    if (isAditionalPatient) {
      if (stoma.value || cancer.value || bedsores.value || 
        stomaSecondPateint.value || cancerSecondPateint.value || bedsoresSecondPateint.value
      ) {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false)
      }
    } else {
      if (stoma.value || cancer.value || bedsores.value) {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false)
      }
    }

    const isLyingPatient = form.getFieldByKey(IS_LYING_PATIENT);
    const isBedCare = form.getFieldByKey(IS_BED_CARE);
    const isRegularChangeOfPosition = form.getFieldByKey(IS_REGULAR_CHANGE_OF_POSITION);
    const isPalliativeCare = form.getFieldByKey(IS_PALLIATIVE_CARE);
    const isPatientHelpChangePosition = form.getFieldByKey(IS_PATIENT_HELP_CHANGE_POSITION);
    const isCarryingBedWheelChair = form.getFieldByKey(IS_CARRYING_BED_WHEELCHAIR);

    const isLyingPatientSecondPateint = secondPatientForm.getFieldByKey(IS_LYING_PATIENT);
    const isBedCareSecondPateint = secondPatientForm.getFieldByKey(IS_BED_CARE);
    const isRegularChangeOfPositionSecondPateint = secondPatientForm.getFieldByKey(IS_REGULAR_CHANGE_OF_POSITION);
    const isPalliativeCareSecondPateint = secondPatientForm.getFieldByKey(IS_PALLIATIVE_CARE);
    const isPatientHelpChangePositionSecondPateint = secondPatientForm.getFieldByKey(IS_PATIENT_HELP_CHANGE_POSITION);
    const isCarryingBedWheelChairSecondPateint = secondPatientForm.getFieldByKey(IS_CARRYING_BED_WHEELCHAIR);

    if (isAditionalPatient) {
      if (
        isLyingPatient.value || 
        isBedCare.value || 
        isRegularChangeOfPosition.value || 
        isPalliativeCare.value || 
        isPatientHelpChangePosition.value || 
        isCarryingBedWheelChair.value.toString() === "717700002" ||
        isLyingPatientSecondPateint.value || 
        isBedCareSecondPateint.value || 
        isRegularChangeOfPositionSecondPateint.value || 
        isPalliativeCareSecondPateint.value || 
        isPatientHelpChangePositionSecondPateint.value || 
        isCarryingBedWheelChairSecondPateint.value.toString() === "717700002"
      ) {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false);
      }
    } else {
      if (
        isLyingPatient.value || 
        isBedCare.value || 
        isRegularChangeOfPosition.value || 
        isPalliativeCare.value || 
        isPatientHelpChangePosition.value || 
        isCarryingBedWheelChair.value.toString() === "717700002"
      ) {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false);
      }
    }

    const isCatherList = form.getFieldByKey(IS_CATHER_LIST);
    const isCatheterizedThroughTheAbdominalWall = form.getFieldByKey(IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL);

    const isCatherListSecondPateint = secondPatientForm.getFieldByKey(IS_CATHER_LIST);
    const isCatheterizedThroughTheAbdominalWallSecondPateint = secondPatientForm.getFieldByKey(IS_CATHETERIZED_THROUGH_THE_ABDOMINAL_WALL);

    if (isAditionalPatient) {
      if (isCatherList.value || 
        isCatheterizedThroughTheAbdominalWall.value || 
        isCatherListSecondPateint.value || 
        isCatheterizedThroughTheAbdominalWallSecondPateint.value
      ) {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false);
      }
    } else {
      if (isCatherList.value || isCatheterizedThroughTheAbdominalWall.value) {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false);
      }
    }

    const isSondaPeg = form.getFieldByKey(IS_SONDA_PEG);

    const isSondaPegSecondPateint = secondPatientForm.getFieldByKey(IS_SONDA_PEG);
    if (isAditionalPatient) {
      if (isSondaPeg.value || isSondaPegSecondPateint.value) {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false);
      }
    } else {
      if (isSondaPeg.value) {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false);
      }
    }

    const isHowOftenCarerMustGetUpAtNight = form.getFieldByKey(IS_HOW_OFTEN_CARER_MUST_GET_UP_AT_NIGHT);

    const isHowOftenCarerMustGetUpAtNightSecondPateint = secondPatientForm.getFieldByKey(IS_HOW_OFTEN_CARER_MUST_GET_UP_AT_NIGHT);

    if (isAditionalPatient) {
        if (parseInt(isHowOftenCarerMustGetUpAtNight.value) >= 3 ||
          parseInt(isHowOftenCarerMustGetUpAtNightSecondPateint.value) >= 3
        ) {
            promedicaPlus.push(true);
        } else {
            promedicaPlus.push(false);
        }
    } else {
      if (parseInt(isHowOftenCarerMustGetUpAtNight.value) >= 3) {
        promedicaPlus.push(true);
      } else {
        promedicaPlus.push(false);
      }
    }


    // let cannotChangePlus= false;
    if (promedicaPlus.find(item => item === true)) {
      recommendedAdditionalOptions.push(ADDITIONAL_OPTIONS.OPTION_PLUS);
      // cannotChangePlus = true;
      additionalOptions.push(ADDITIONAL_OPTIONS.OPTION_PLUS);
      // const selectedAdditionalOptionToSave = this.prepareAdditionalOptionToAddToDB(ADDITIONAL_OPTIONS.OPTION_PLUS, false)
      // this.saveOptionToDB(ADDITIONAL, JSON.stringify(selectedAdditionalOptionToSave))
    }else{
      const exists = this.exitsInAdditionalSavedOption(ADDITIONAL_OPTIONS.OPTION_PLUS);
      if(exists!==null){
        if(exists===true){
          additionalOptions.push(ADDITIONAL_OPTIONS.OPTION_PLUS);
        }
      }
    }

    let isSecondPatientSelectedInDB = true;

    if(this.state.selectedAdditionalOptionToSave.length>0){
      const optionSelection = this.state.selectedAdditionalOptionToSave;
      optionSelection.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];

        if(key === FEE_2NDPAT){
          isSecondPatientSelectedInDB = value
        }
      });
    }

    const fieldLastSection = form.getFieldByKey("is_last_section").value;
    const fieldLastSectionSecondPatient = secondPatientForm.getFieldByKey("is_last_section").value;

    const patientLastSection = getSectionNumberByName(fieldLastSection);
    const secondPatientLastSection = getSectionNumberByName(fieldLastSectionSecondPatient);

    if(patientLastSection > 29 && secondPatientLastSection > 29 && isSecondPatientSelectedInDB) {
      additionalOptions.push(ADDITIONAL_OPTIONS.OPTION_2NDPAT);
      if(this.state.rerenderOptions) {
        this.setState({
          isAditionalPatient: true,
          rerenderOptions: false,
        },
        this.rerenderOptions
        );
      } else {
        this.setState({
          isAditionalPatient: true,
        });
      }
      this.props.changeAditionalPateint(true);
    }
    if(this.state.selectedAdditionalOptionToSave.length>0){
      const optionSelection = this.state.selectedAdditionalOptionToSave;
      optionSelection.map(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        const indexValue = additionalOptions.indexOf(key);

        if(indexValue>-1 && !value) {
          additionalOptions.splice(indexValue, 1);
        } else if (indexValue===-1 && value) {
          additionalOptions.push(key);
        }
        if(key === FEE_2NDPAT && !value){
          this.setState({
            isAditionalPatient: false,
          });
          this.props.changeAditionalPateint(false);
        }
      });
    }

    this.setCustomersData(this.state.selectedLanguagePacket, additionalOptions);
    this.setState({
      // cannotChangePromPlus: cannotChangePlus?cannotChangePlus:this.state.cannotChangePromPlus,
      recommendedAdditionalOptions: recommendedAdditionalOptions,
      selectedAdditionalOptions: additionalOptions,
      selectedAdditionalOptionsForOfferSection: additionalOptions.map((id) => ({
        active: true,
        id
      }))
    })
  }

  toggleAdditionalOption = (value) => {
    const index = this.state.selectedAdditionalOptionsForOfferSection.findIndex(item => item.id === value);

    if (index >= 0) {
      const array = [...this.state.selectedAdditionalOptionsForOfferSection];
      array[index].active = !array[index].active
      this.setState({
        selectedAdditionalOptionsForOfferSection: array
      });
    }
  }

  setTypeChange = (key, value) => {
    const nextState = produce(this.state, draftState => {
      const { section, tempSection } = draftState;
      const field = section.getFieldByKey(key);
      if (field) {
        field.value = value;
      }
      const fieldT = tempSection.getFieldByKey(key);
      if (fieldT) {
        fieldT.value = value;
      }
    });
    this.setState(nextState);
  };

  analizeComplete = () => {
    this.props.toggleAnalizeLoader();
    this.setState({ analizeOffer: false })
  }

  increaseProgress = () => {
    if (this._isMounted) {
      if (this.state.progressWidth >= 100) {
        clearInterval(this.state.myInterval);
        this.myTimeout = setTimeout(() => {
          this.analizeComplete()
        }, 1000);
      } else {
        this.setState(previousState => ({
          ...previousState,
          progressWidth: previousState.progressWidth + 2
        }));
      }
    }
  }

  startProgress = () => {
    if (this.props.disabled) {
      this.props.toggleOffAnalizer();
      this.setState({ analizeOffer: false })
    } else {
      if ((this.props.prevPage < 12 || !this.props.prevPage) && this.state.analizeOffer === true) {
        this.props.toggleAnalizeLoader();
        let intervalId = setInterval(() => {
          this.increaseProgress();
        }, 100);
        this.setState({ myInterval: intervalId });
      } else {
        this.props.toggleOffAnalizer();
        this.setState({ analizeOffer: false })
      }
    }
  }

  setLanguagePacket = (value) => {
    if(!this.props.disabled){
      this.saveOptionToDB(SPREACHE, JSON.stringify([{[value.toString()]: true}]));
      if(this.state.isAditionalPatient) {
        this.saveOptionToDBSecondPatient(SPREACHE, JSON.stringify([{[value.toString()]: true}]));
      }
      this.setCustomersData(value, this.state.selectedAdditionalOptions);
      this.setState({
        selectedLanguagePacket: value,
        savedLanguagePacket: value
      })
    }
  }

  saveOptionToDB = (type, data) =>{
    const {match: { params } } = this.props;
    const dataToSend = {
      client_id:params.id,
      patient_id:params.patientId,
      type: type,
      value: data
    }
    saveOptionData(dataToSend)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            //seems ok
            //console.log(res);
          }
        }
      }
    })
    .catch((error) => {
      console.log("Unable to save data to DB");
    });
  }

  saveOptionToDBSecondPatient = (type, data) =>{
    const {match: { params }, secondPatientId } = this.props;
    const dataToSend = {
      client_id:params.id,
      patient_id:secondPatientId,
      type: type,
      value: data
    }
    saveOptionData(dataToSend)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            //seems ok
            //console.log(res);
          }
        }
      }
    })
    .catch((error) => {
      console.log("Unable to save data to DB");
    });
  }

  setCustomersData = (lang, additionalOptions) => {
    const {match: { params } } = this.props;
    const customersData = this.props.customersOptions;
    const clientData = {
      id: params.patientId,
      langOption: lang,
      aditionalOptions: additionalOptions,
    };
    if(customersData.length > 0) {
      customersData.map(item => {
        if(item.id === params.patientId) {
          item.aditionalOptions = additionalOptions;
          item.langOption = lang;
        }
        return item;
      });
    } else {
      customersData.push(clientData);
    }
    this.props.setCustomersOptions(customersData);
  }

  setMultipleClientsForCheckbox = (key, value) => {
    const { secondPatientId } = this.props;
    if(!this.props.disabled){
      const fieldLastSection = this.props.secondPatientForm.getFieldByKey("is_last_section").value;
      const secondPatientLastSection = getSectionNumberByName(fieldLastSection);
      
      if (secondPatientId !== null) {
        if(secondPatientLastSection > 29) {
          this.setState({
            showRedirection: false,
          });
          this.setAdditionalOption(key);
          if (value) {
            this.mergeOptionsInDB();
          }
          this.props.changeAditionalPateint(true);
        } else {
          this.setState({
            showMultiPatientModal: true,
            showRedirection: true,
          });
        }
      } else {
        this.setState({
          showMultiPatientModal: true,
          showRedirection: false,
        });
      }
    }
  }

  redirectToSecondPatient = () => {
    const { match: { params }, history, secondPatientId } = this.props;

    this.setState({
      showMultiPatientModal: false,
    });

    this.props.setActiveSection(SECTION_OUTSIDE_MENU);
    history.push(`${baseData.subFolders}bb-form-lead/${params.id}/${secondPatientId}`);
    this.props.rerenderPage(false);
  }

  acceptMultiplePatients = async (firstName, lastName) => {
    const { match: { params }, history } = this.props;
    const dataToSend = [{
            "Id": "0",
            "Entity": "is_patient",
            "Fields": {
                "is_contact_id": params.id,
                "is_last_section": "rodo_section_1",
                "is_first_name_and_last_name_of_patient": `${firstName} ${lastName}`
            }
        },
        {
            "Id": params.id,
            "Entity": "contact",
            "Fields": {
                "is_patient_last_name": lastName,
                "is_patient_first_name": firstName,
            }
        }];

    try{
      const response = await BBFormService.savePatient(dataToSend);
      let lkid = params.id?params.id:'0';
      let patientid = params.patientId?params.patientId:'0';
      const { data } = response;
      data.forEach(element => {
        if (element.entity === Entity.PATIENT) {
          patientid = element.id;
        } else {
          if (element.entity === Entity.CONTACT) {
            lkid = element.id;
          }
        }
      });
      this.props.setActiveSection(SECTION_OUTSIDE_MENU);
      history.push(`${baseData.subFolders}bb-form-lead/${lkid}/${patientid}`);
      this.props.rerenderPage(true);
    } catch (e) {
      console.log(e);
    }
  }

  cancelMultiplePatients = () => { 
    this.setState({
      showMultiPatientModal: false,
    });
  }

  mergeOptionsInDB = () => {
    const { selectedAdditionalOptionToSave, selectedAdditionalOptionToSaveSecondPatient } = this.state;
    const result = [];
    const object = {};
    const mergedOptions = selectedAdditionalOptionToSave.concat(selectedAdditionalOptionToSaveSecondPatient);

    mergedOptions.forEach( item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      if(key in object) {
        if(object[key] !== value) {
          object[key] = true;
        } else {
          object[key] = value;
        }
      } else {
        object[key] = value;
      }
    });

    for (const key in object) {
      result.push({[key] : object[key]});
    }

    this.setState({
      selectedAdditionalOptionToSave: result,
      selectedAdditionalOptionToSaveSecondPatient: result,
    });
    this.saveOptionToDB(ADDITIONAL, JSON.stringify(result));
    this.saveOptionToDBSecondPatient(ADDITIONAL, JSON.stringify(result));
    this.getRecommendedAdditionalOptions();
  }

  setAdditionalOption = (value) => {
    if(!this.props.disabled){
      let changeNotNeeded = false;
      if(value===ADDITIONAL_OPTIONS.OPTION_PLUS){
        const {cannotChangePromPlus}=this.state;
        if(cannotChangePromPlus){
          changeNotNeeded = true;
        }
      }
      if(!changeNotNeeded){
        const index = this.state.selectedAdditionalOptions.findIndex(id => id === value);
        let valueOfSecondPateint = false;
        if (index >= 0) {
          //set unactive
          const selectedAdditionalOptionToSave = this.prepareAdditionalOptionToAddToDB(value, false)
          const array = [...this.state.selectedAdditionalOptions];
          array.splice(index, 1);

          this.setCustomersData(this.state.selectedLanguagePacket, array);
          this.setState({
            selectedAdditionalOptions: array,
            selectedAdditionalOptionToSave: selectedAdditionalOptionToSave,
            selectedAdditionalOptionsForOfferSection: array.map((id) => ({
              active: true,
              id
            }))
          });
          const isMultipatientSelected = selectedAdditionalOptionToSave.map(element => {
            if(element.hasOwnProperty(FEE_2NDPAT)) {
              return element.fee_2ndpat
            } else {
              return false;
            }
          }).some(el => el);
          this.saveOptionToDB(ADDITIONAL, JSON.stringify(selectedAdditionalOptionToSave));
          if(this.state.isAditionalPatient || isMultipatientSelected) {
            this.saveOptionToDBSecondPatient(ADDITIONAL, JSON.stringify(selectedAdditionalOptionToSave));
          }
        } else {
          valueOfSecondPateint = true;
          const selectedAdditionalOptionToSave = this.prepareAdditionalOptionToAddToDB(value, true);
          this.setCustomersData(this.state.selectedLanguagePacket, [...this.state.selectedAdditionalOptions, value]);
          this.setState(state => {
            const selectedAdditionalOptions = [...state.selectedAdditionalOptions, value];
            return {
              selectedAdditionalOptions,
              selectedAdditionalOptionToSave: selectedAdditionalOptionToSave,
              selectedAdditionalOptionsForOfferSection: selectedAdditionalOptions.map((id) => ({
                active: true,
                id
              }))
            };
          });
          const isMultipatientSelected = selectedAdditionalOptionToSave.map(element => {
            if(element.hasOwnProperty(FEE_2NDPAT)) {
              return element.fee_2ndpat
            } else {
              return false;
            }
          }).some(el => el);

          this.saveOptionToDB(ADDITIONAL, JSON.stringify(selectedAdditionalOptionToSave));
          if(this.state.isAditionalPatient || isMultipatientSelected) {
            this.saveOptionToDBSecondPatient(ADDITIONAL, JSON.stringify(selectedAdditionalOptionToSave));
          }
        }
        if(value === FEE_2NDPAT) {
          const fieldLastSection = this.props.secondPatientForm.getFieldByKey("is_last_section").value;
          if(fieldLastSection) {
            const isSecondPatient = getSectionNumberByName(fieldLastSection);
            if (isSecondPatient >= 29) {
              this.setState(
                {
                  isAditionalPatient: valueOfSecondPateint,
                },
                this.rerenderOptions,
              );
              this.props.changeAditionalPateint(valueOfSecondPateint);
              // this.getDataSavedInKundeDB(this.props.secondPatientId, true);
            }
          }
        }
      }
    }
  }

  rerenderOptions = () => {
    this.getRecommendedLanguagePacket();
    this.getRecommendedAdditionalOptions();
  }

  prepareAdditionalOptionToAddToDB = (optionKey, value)=>{
    const {selectedAdditionalOptionToSave} = this.state;
    let exits = false;
    for (const iter in selectedAdditionalOptionToSave) {
      for (const key in selectedAdditionalOptionToSave[iter]) {
        if(key === optionKey){
          exits = true;
          selectedAdditionalOptionToSave[iter][key] = value;
        }
      }
    }
    if(!exits){
        return [...selectedAdditionalOptionToSave, {[optionKey]: value}];
    }else{
      return selectedAdditionalOptionToSave;
    }
  }

  inCaseWhereAnalizeNotNeeded = () => {
    this.props.toggleOffAnalizer();
    this.setState({ analizeOffer: false })
  }

  onSectionClick = (section) => {
    if (section === MENU_SECTIONS.SECTION_SUMMARY) {
      this.setState({
        activeSection: section,
        displayPriceInOffer: true
      })
    } else {
      this.setState({
        activeSection: section
      })
    }
    this.props.setActiveSection(section);
  }

  toggleVideoPlayer = () => {
    this.setState({
      isVideoActive: !this.state.isVideoActive
    })
  }

  renderCarouselButton = (direction) => {
    const iconDirection = direction === 'prev' ? 'left' : 'right';
    return (
      <a 
        role="button" 
        data-slide={direction}
        href="#carouselExampleInterval" 
        className={`carousel-control-${direction} carousel-control-area`}
      >
        <i className={`fas fa-chevron-${iconDirection} fa-2x navigation_icon_color ${direction}-navi`} />
      </a>
    )
  }

  showMeContract = () => {
    this.props.setActiveSection(DIE_BESTELLUNG_SECTION);
  }

  getMeKoPilotenSection = () =>{
    const { multipliersData, disabled } = this.props;
    const {
      loadingMultipliers,
      KoPilotenForm,
      KoPilotenRerender,
      KoPilotenErrorComponent,
      KoPilotenEdited,
      KoPilotenActiveCategoryId,
      KoPilotenSaving,
      KoPilotenLoadingBasics
    } = this.state;
    let content;
    if (loadingMultipliers) {
      content = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(multipliersData===false){
        content = <EmptyTableIcon RefreshMe={() => this.props.getInitialMultipliers()} text={translateKey("no_data")} />
      }else{
        if (multipliersData === null) {
          content = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.getInitialMultipliers()} text={translateKey("no_data_to_show")} /></div>
        } else {
          if (multipliersData.length === 0) {
            content = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.getInitialMultipliers()} text={translateKey("no_data_to_show")} /></div>
          }else{
            content = <KoPilotenCategoryComponent
              generatePdf={true}
              generatePdfFunction={this.prepareDataAndSendPDF}
              saving={KoPilotenSaving}
              activeCategoryId= {KoPilotenActiveCategoryId}
              edited = {KoPilotenEdited}
              errorComponent={KoPilotenErrorComponent}
              form={KoPilotenForm}
              rerender={KoPilotenRerender}
              loadingBasics = {KoPilotenLoadingBasics}
              loadingMultipliers={loadingMultipliers}
              refreshData={() => this.getInitialMultipliers()}
              disabled={disabled}
              saveChange={this.saveChangeKoPiloten}
              changeCategoryActiveKoPiloten={this.changeCategoryActiveKoPiloten}
              addMultiToCategory={this.addMultiToCategory}
              changeNoteData={this.changeNoteDataKopiloten}
            />
          }
        }
      }
    }
    return content;
  }

  getDiscount = (form) => {
    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const isKeinPflegegrad = form.getFieldByKey(IS_KEIN_PFLEGEGRAD);
    if (isKeinPflegegrad.value) {return false;}
    if(pflegegrad.value.toString() === '717700000') {
      return {
        name: "from-text-pflegegrad1",
        price: "0"
      }
    }

    if(pflegegrad.value.toString() === '717700001') {
      return {
        name: "from-text-pflegegrad2",
        price: "347"
      }
    }

    if(pflegegrad.value.toString() === '717700002') {
      return {
        name: "from-text-pflegegrad3",
        price: "599"
      }
    }

    if(pflegegrad.value.toString() === '717700003') {
      return {
        name: "from-text-pflegegrad4",
        price: "800"
      }
    }

    if(pflegegrad.value.toString() === '717700004') {
      return {
        name: "from-text-pflegegrad5",
        price: "990"
      }
    }
  }

  isPostalCodeBawaria = () => {
    const { cities, form, secondPatientForm } = this.props;
    const isServiceAddressPostalCode = form.getFieldByKey(IS_SERVICE_ADDRESS_POSTAL_CODE).value;
    if (cities.length > 0) {
      for (let index = 0; index < cities.length; index++) {
        if (cities[index].PostalCode.indexOf(isServiceAddressPostalCode) > -1) {
          if (cities[index].Bawaria === 1) {
            const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
            const pflegegradStringValue = pflegegrad.value.toString();
            const pflegegradSecondPatient = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);
            const pflegegradStringValueSecondPatient = pflegegradSecondPatient.value.toString();
            if(
              pflegegradStringValue === '717700001' ||
              pflegegradStringValue === '717700002' ||
              pflegegradStringValue === '717700003' ||
              pflegegradStringValue === '717700004' ||
              pflegegradStringValueSecondPatient === '717700001' ||
              pflegegradStringValueSecondPatient === '717700002' ||
              pflegegradStringValueSecondPatient === '717700003' ||
              pflegegradStringValueSecondPatient === '717700004'
            ) {
              return true;
            }
          }
        }
      }
      return false;
    } else {
      return false;
    }
  }

  get priceOfLanguagePacket() {
    const { availableLanguagePackets, selectedLanguagePacket } = this.state;
    return availableLanguagePackets.find(item => item.id === selectedLanguagePacket).price;
  }

  get totalPrice() {
    const { selectedAdditionalOptions, availableAdditionalOptions } = this.state;
    let totalPriceOfAdditionalOptions = 0.00;
    selectedAdditionalOptions.forEach(element => {totalPriceOfAdditionalOptions = totalPriceOfAdditionalOptions + parseFloat(availableAdditionalOptions.find(item => item.id === element).price)});
    return (parseFloat(this.priceOfLanguagePacket) + totalPriceOfAdditionalOptions).toString();
  }

  get bawariaDiscount(){
    const { form, secondPatientForm, promedicaProductsPrices } = this.props;
    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValue = pflegegrad.value.toString();
    const pflegegradSecondPatient = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValueSecondPatient = pflegegradSecondPatient.value.toString();
    if(
      pflegegradStringValue === '717700001' ||
      pflegegradStringValue === '717700002' ||
      pflegegradStringValue === '717700003' ||
      pflegegradStringValue === '717700004' ||
      pflegegradStringValueSecondPatient === '717700001' ||
      pflegegradStringValueSecondPatient === '717700002' ||
      pflegegradStringValueSecondPatient === '717700003' ||
      pflegegradStringValueSecondPatient === '717700004'
    ) {
      const priceDiscount = promedicaProductsPrices.find(item => item.id === bawariaKeyName);
      if(priceDiscount){
        if(
          (pflegegradStringValue === '717700001' ||
          pflegegradStringValue === '717700002' ||
          pflegegradStringValue === '717700003' ||
          pflegegradStringValue === '717700004' ) &&
          (pflegegradStringValueSecondPatient === '717700001' ||
          pflegegradStringValueSecondPatient === '717700002' ||
          pflegegradStringValueSecondPatient === '717700003' ||
          pflegegradStringValueSecondPatient === '717700004')
        ) {
          return (Math.abs(parseFloat(priceDiscount.price))*2);
        } else {
          return Math.abs(parseFloat(priceDiscount.price));
        }
      }
    }
    return 0;
  }
 
  get totalPriceWithDiscount() {
    const { form, promedicaProductsPrices, secondPatientForm } = this.props;
    const { selectedAdditionalOptions, availableAdditionalOptions, isAditionalPatient} = this.state;
    let totalPriceOfAdditionalOptions = 0.00;
    let bawariaDiscount = 0.00;
    let pflegegradDiscount = 0.00;
    let pflegegradDiscountSecondPatient = 0.00;
    selectedAdditionalOptions.forEach(element => {totalPriceOfAdditionalOptions = totalPriceOfAdditionalOptions + parseFloat(availableAdditionalOptions.find(item => item.id === element).price)});
    const pflegegrad = form.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValue = pflegegrad.value.toString();
    const pflegegradSecondPatient = secondPatientForm.getFieldByKey(IS_PFLEGEGRAD);
    const pflegegradStringValueSecondPatient = pflegegradSecondPatient.value.toString();

    if(
      pflegegradStringValue === '717700001' ||
      pflegegradStringValue === '717700002' ||
      pflegegradStringValue === '717700003' ||
      pflegegradStringValue === '717700004' ||
      pflegegradStringValueSecondPatient === '717700001' ||
      pflegegradStringValueSecondPatient === '717700002' ||
      pflegegradStringValueSecondPatient === '717700003' ||
      pflegegradStringValueSecondPatient === '717700004'
    ) {
      if(this.isPostalCodeBawaria()) {
        const priceDiscount = promedicaProductsPrices.find(item => item.id === bawariaKeyName);
        if(priceDiscount){
          bawariaDiscount = parseFloat(priceDiscount.price);

          if(
            (pflegegradStringValue === '717700001' ||
            pflegegradStringValue === '717700002' ||
            pflegegradStringValue === '717700003' ||
            pflegegradStringValue === '717700004' )&&
            (pflegegradStringValueSecondPatient === '717700001' ||
            pflegegradStringValueSecondPatient === '717700002' ||
            pflegegradStringValueSecondPatient === '717700003' ||
            pflegegradStringValueSecondPatient === '717700004')
          ) {
            bawariaDiscount = bawariaDiscount * 2;
          }
        }
      }
    }
    if (this.getDiscount(form)) {pflegegradDiscount = parseFloat(this.getDiscount(form).price);}
    if (this.getDiscount(secondPatientForm) && isAditionalPatient) {pflegegradDiscountSecondPatient = parseFloat(this.getDiscount(secondPatientForm).price);}
    return (parseFloat(this.priceOfLanguagePacket) + totalPriceOfAdditionalOptions - pflegegradDiscount - pflegegradDiscountSecondPatient + bawariaDiscount).toString();
  }

  toggleCancelation = () =>{
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            confirmCancellation: !prevState.confirmCancellation
        }
      });
    };
  }

  saveStatistics() {
    const { userId, fpName, fpFirstMail, clientLeadBaseData } = this.props;
    const statsData = {
      type: StatsType.CANCELLATION_CONTRACT,
      data: {
        userId: userId,
        fpName: fpName,
        fpFirstMail: fpFirstMail,
        leadId: clientLeadBaseData.leadId,
        firstName: clientLeadBaseData.client.firstName,
        lastName: clientLeadBaseData.client.lastName
      }
    }
    saveStats(statsData)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            console.log(res.data.data);
          }
        }else{
          console.log("Unable to save data to Statistics");
        }
      }
    })
    .catch((error) => {
      console.log("Unable to save data to Statistics");
    });
  }

  confirmCancellationYes = (options) =>{
    if(this.state.confirmCancellation){this.toggleCancelation();}
    this.setState({loaderModal: true});
    if(!options){
      const { match: { params } } = this.props;
      BBFormService.cancelContract(params.id)
      .then(() => {
        this.sendRejectSimpleMail(options);
        this.saveStatistics();
      }).catch(() => {
        if (this._isMounted) {
          this.setState({
            loaderModal: false,
            infoModalOptions: {
              show: true,
              type: "danger",
              message: translateKey('basic_error_message')
            }
          })
        }
      })
    }else{
      this.sendRejectSimpleMail(options);
    }
  }

  onCancelOfferClick = (buttonNumber, options) =>{
    if(buttonNumber === 1){
      this.confirmCancellationYes(options);
    }else{
      this.setState({
        confirmCancellation: true,
        cancelationOptions: options
      })
    }
  }

  sendRejectSimpleMail = (options) =>{
    const { translateKey, form, fpName, fpFirstMail, fpPhone, history, priceType, match: { params }, secondPatientForm } = this.props;
    const { cancelationOptions, selectedAdditionalOptions, selectedLanguagePacket, availableLanguagePackets, availableAdditionalOptions, isAditionalPatient} = this.state;
    const foundedLang = availableLanguagePackets.find(item => item.id === selectedLanguagePacket);
    const patientName = form.getFieldByKey("is_first_name_and_last_name_of_patient").value;
    const secondPateintName = secondPatientForm.getFieldByKey("is_first_name_and_last_name_of_patient").value;
    let dataToTableSummary = {
      priceTypeText: priceType === PriceType.DAILY ? translateKey('summary-offer-sprache-footer-daily-payment') : translateKey('summary-offer-sprache-footer'),
      [CANCELATION_EMAIL_ENUMS.LANGTYPE]: new CancelationObj(
        CANCELATION_EMAIL_ENUMS.LANGTYPE,
        foundedLang.label,
        priceType === PriceType.DAILY ? formatPrice(foundedLang.price/30) : formatPrice(foundedLang.price),
      ), 
      [CANCELATION_EMAIL_ENUMS.ADDITIONALTYPE]: selectedAdditionalOptions.map(id => {
        let foundedItem = availableAdditionalOptions.find(item => item.id === id);
        if(foundedItem){
          return new CancelationObj(
            CANCELATION_EMAIL_ENUMS.ADDITIONALTYPE,
            translateKey(foundedItem.label),
            priceType === PriceType.DAILY ? formatPrice(foundedItem.price/30) : formatPrice(foundedItem.price),
          )
        }else{
          return '';
        }
      }).filter(el => el !=='')
    }
    let discountsAll = [];
    const discountBasedOnPflegegrad = this.getDiscount(form);
    const discountBasedOnPflegegradSecondPatient = this.getDiscount(secondPatientForm);
    if(discountBasedOnPflegegrad){
      discountsAll.push(new CancelationObj(
        CANCELATION_EMAIL_ENUMS.DISCOUNTTYPE,
        `${translateKey(discountBasedOnPflegegrad.name)} ${(discountBasedOnPflegegradSecondPatient && isAditionalPatient) ? `(${ patientName })` : ''} `,
        `-${priceType === PriceType.DAILY ? formatPrice(discountBasedOnPflegegrad.price/30) : formatPrice(discountBasedOnPflegegrad.price)}`,
        translateKey("summary-offer-cart-section-label-2")
      ))
    }
    
    if(discountBasedOnPflegegradSecondPatient && isAditionalPatient){
      discountsAll.push(new CancelationObj(
        CANCELATION_EMAIL_ENUMS.DISCOUNTTYPE,
        `${translateKey(discountBasedOnPflegegradSecondPatient.name)} (${ secondPateintName }) `,
        `-${priceType === PriceType.DAILY ? formatPrice(discountBasedOnPflegegradSecondPatient.price/30) : formatPrice(discountBasedOnPflegegradSecondPatient.price)}`,
        translateKey("summary-offer-cart-section-label-2")
      ))
    }

    const discountBawaria = this.isPostalCodeBawaria()
    if(discountBawaria){
      discountsAll.push(new CancelationObj(
        CANCELATION_EMAIL_ENUMS.DISCOUNTTYPE,
        translateKey("form-text-landesgeld-bayern"),
        `-${priceType === PriceType.DAILY ? formatPrice(this.bawariaDiscount/30) : formatPrice(this.bawariaDiscount)}`,
        translateKey("summary-offer-cart-section-label-2")
      ))
    }
    dataToTableSummary = {
      ...dataToTableSummary, 
      [CANCELATION_EMAIL_ENUMS.DISCOUNTTYPE]: discountsAll,
      [CANCELATION_EMAIL_ENUMS.SUMMARYWITHDISCOUNT]: new CancelationObj(
        CANCELATION_EMAIL_ENUMS.SUMMARYWITHDISCOUNT,
        translateKey("summary-offer-with-discount"),
        priceType === PriceType.DAILY ? formatPrice(this.totalPriceWithDiscount/30) : formatPrice(this.totalPriceWithDiscount),
      ),
      [CANCELATION_EMAIL_ENUMS.SUMMARYPRICE]: new CancelationObj(
        CANCELATION_EMAIL_ENUMS.SUMMARYPRICE,
        translateKey("summary-offer-without-discount"),
        priceType === PriceType.DAILY ? formatPrice(this.totalPrice/30) : formatPrice(this.totalPrice),
      ),
    };
    if(this._isMounted) {
      const isEmailField = form.getFieldByKey('is_email'); 
      if(!options){options = cancelationOptions;}
      let FPphoneFormated = '';
      if(fpPhone) {
        FPphoneFormated = `${fpPhone.slice(0, 3)} ${fpPhone.slice(3, fpPhone.length)}`;
      }
      let fields = form.getUniqueFlatFieldsList().map(f => ({
          key: f.entity + "_" + f.key,
          value: f.currentValue
        }));
      let rejectDataToSend = {
        userID: this.props.userId,
        toEmail: isEmailField.value,
        fpName: fpName,
        fpEmail: fpFirstMail,
        rawForm : fields,
        fpPhone: FPphoneFormated,
        restData: dataToTableSummary
      }
      if(options.indexOf('contract_option_summary')>-1){
        rejectDataToSend = {...rejectDataToSend, formPdfContent: FormHtmlHelper(form, translateKey)}
      }
      if(options.indexOf('contract_option_rodo')>-1){
        rejectDataToSend = {...rejectDataToSend, rodoPdfContent: RodoHtmlHelper(form, translateKey)}
      }
      if(options.indexOf('contract_option_promdoc')>-1){
        rejectDataToSend = {...rejectDataToSend, prasentationPM24: true}
      }
      if(options.indexOf('contract_option_kopiloten')>-1){
        rejectDataToSend = {...rejectDataToSend, koPilotenData: this.generateKoPilotenData()}
      }
      if(options.indexOf('contract_option_summary_second_pateint')>-1){
        let secondPatientFields = secondPatientForm.getUniqueFlatFieldsList().map(f => ({
          key: f.entity + "_" + f.key,
          value: f.currentValue
        }));
        rejectDataToSend = {...rejectDataToSend, formPdfContentSecondPatient: FormHtmlHelper(secondPatientForm, translateKey), rawFormSecondPatient : secondPatientFields}
      }
      if(options.indexOf('contract_option_rodo_second_pateint')>-1){
        rejectDataToSend = {...rejectDataToSend, rodoPdfContentSecondPatient: RodoHtmlHelper(secondPatientForm, translateKey)}
      }
      cancelContractSimpleOption(rejectDataToSend)
      .then(res => {
        if(res){
          if(res.status===200){
            if (this._isMounted) {
              this.setState(prevState => {
                return {
                  ...prevState,
                  loaderModal: false,
                  errorComponent: {
                    ...prevState.errorComponent,
                    show: true,
                    type: "success",
                    message: translateKey("reject-offer-on-offer-form-text")
                  }
                }
              })
              document.body.classList.remove('overflow-y-scroll');
              this.myTimeout = setTimeout(() => {history.push(`${baseData.subFolders}leadDetails/${params.id}`);}, 3000);
            }
          }else{
            if (this._isMounted) {
              this.setState({
                loaderModal: false,
                infoModalOptions: {
                  show: true,
                  type: "danger",
                  message: translateKey('basic_error_message')
                }
              })
            }
          }
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loaderModal: false,
            infoModalOptions: {
              show: true,
              type: "danger",
              message: translateKey('basic_error_message')
            }
          })
          document.body.classList.remove('overflow-y-scroll');
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({ 
                errorComponent: Alert,
              });
            }
          }, 3000);
        }
      });
    }
  }

  generateKoPilotenData = () =>{
    const { KoPilotenForm, loadingMultipliers} = this.state;
    const { multipliersData, translateKey, form } = this.props;
    const isEmailField = form.getFieldByKey('is_email');
    if(!loadingMultipliers && multipliersData!==false && multipliersData !== null && multipliersData.length > 0){
      if(isEmailField.value){
        let pdfDataToSend = [];
        const catList = [...KoPilotenForm.categories];
        catList.forEach(cat => {
          if(cat.IsSubCategory===false){
            if(cat.SubCategoriesList.length===0){
              //main category
              if(cat.gotMultipliers(multipliersData)){
                let multipliersDataToSend = [];
                cat.MultiplierIds.forEach(multiId => {
                  let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                  if(foundMulti){
                    if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                      let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                      if(exists>-1){
                        multipliersDataToSend.push(foundMulti);
                      }
                    }
                  }
                });
                if(multipliersDataToSend.length>0){
                  // we got main category and some multipliers with data to send
                  pdfDataToSend.push({
                    parentName: translateKey(cat?cat.Name:''),
                    childName: null,
                    categoryId: cat?cat.CategoryId:'',
                    note: cat?cat.Note:'',
                    multipliersData: multipliersDataToSend
                  })
                }
              }
            }else{
              //got subcategories
              cat.SubCategoriesList.forEach(subCat => {
                if(subCat.gotMultipliers(multipliersData)){
                  let multipliersDataToSend = [];
                  subCat.MultiplierIds.forEach(multiId => {
                    let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                    if(foundMulti){
                      if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                        let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                        if(exists>-1){
                          multipliersDataToSend.push(foundMulti);
                        }
                      }
                    }
                  });
                  if(multipliersDataToSend.length>0){
                    // we got subcategory and some multipliers with data to send
                    pdfDataToSend.push({
                      parentName: translateKey(cat?cat.Name:''),
                      childName: translateKey(subCat?subCat.Name:''),
                      categoryId: subCat?subCat.CategoryId:'',
                      note: subCat?subCat.Note:'',
                      multipliersData: multipliersDataToSend
                    })
                  }
                }
              });
            }
          }
        });
        if(pdfDataToSend.length>0){
          //got some checked multipliers so we can generate pdf
          return pdfDataToSend;
        }
      }
    }
    return [];
  }

  render() {
    const { 
      activeSection, 
      form,
      previousPageClick,
      clientLeadBaseData,
      formbbStatus,
      disabled,
      userType,
      match: { params },
      wholeResponse,
      translateKey,
      secondPatientForm,
      secondPatientId,
    } = this.props;

    const { 
      availableLanguagePackets,
      recommendedLanguagePacket,
      selectedLanguagePacket,
      availableAdditionalOptions,
      selectedAdditionalOptions,
      selectedAdditionalOptionsForOfferSection,
      displayPriceInOffer,
      products,
      productsLoaded,
      regionsFP,
      regionsFPLoaded,
      recommendedAdditionalOptions,
      savedAdditionalOption,
      savedLanguagePacket,
      gettingDataFromDbStatusReady,
      infoModalOptions,
      loaderModal,
      confirmCancellation,
      errorComponent,
      cannotChangePromPlus,
      isAditionalPatient
    } = this.state;
    let content = '';
    const secondPatientName = this.props.secondPatientForm.getFieldByKey("is_first_name_and_last_name_of_patient").value;

    if (activeSection === DIE_BESTELLUNG_SECTION) {
      /*<ContractComponent
        wholeResponse={this.state.wholeResponse}
        showMessage={this.showAlertMessage}
        hideSaveModal={this.hideSaveModal}
        showSaveModal={this.showSaveModal}
        leadPatients={this.state.leadPatients}
        showButtons={true}
        setNewLeadStatus={this.setBBstatus}
        bbStatus={this.state.formbbStatus}
        newOne={this.state.newOne}
        products={this.state.products}
        regionsFP={this.state.regionsFP}
        disabled={false}
        bbform={form}
        contractForm={contractForm}
      />;*/
      content = (
        <div className="d-flex w-100">
          <SectionDieBestellung 
            sectionName="die-bestellung-title"
            form={form}
            wholeResponse={wholeResponse}
            disabled={disabled}
            formbbStatus={formbbStatus}
            onClick={this.onSectionClick}
            selectedLanguagePacket={selectedLanguagePacket}
            selectedAdditionalOptions={selectedAdditionalOptions}
            availableAdditionalOptions={availableAdditionalOptions}
            availableLanguagePackets={availableLanguagePackets}
            previousPageClick={previousPageClick}
            clientLeadBaseData={clientLeadBaseData}
            products={products}
            productsLoaded={productsLoaded}
            regionsFP={regionsFP}
            regionsFPLoaded={regionsFPLoaded}
            secondPatientForm={secondPatientForm}
            isAditionalPatient={isAditionalPatient}
          />
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }

    /*if (activeSection === DIE_SUCHANfRAGE) {
      return (
        <div className="d-flex w-100">
          <SectionSuchanfrage 
            disabled={disabled}
            showButtons={true}
            wholeResponse={wholeResponse}
            sectionName="suchanfrage-form-suchanfrage"
            form={form}
            formbbStatus={formbbStatus}
            onClick={this.onSectionClick}
            convertToContrat={this.showMeContract}
            selectedLanguagePacket={selectedLanguagePacket}
            selectedAdditionalOptions={selectedAdditionalOptions}
            availableAdditionalOptions={availableAdditionalOptions}
            availableLanguagePackets={availableLanguagePackets}
            previousPageClick={previousPageClick}
            clientLeadBaseData={clientLeadBaseData}
            products={products}
            regionsFP={regionsFP}

          />
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }*/

    if (activeSection === SECTION_OUTSIDE_MENU) {
      content = (
        <div className="d-flex">
          <div 
            id="carouselExampleInterval" 
            className="carousel slide w-100" 
            data-ride="carousel"
            data-interval="false"
          >
            <FirstSliderContent />
            {this.renderCarouselButton('prev')}
            {this.renderCarouselButton('next')}
          </div>
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={true}
          />
        </div>
      )
    }

    if (activeSection === OFFER_SECTION) {
      content = (
        <div className="d-flex">
          <OfferSection
            form={form}
            disabled={disabled}
            availableLanguagePackets={availableLanguagePackets}
            selectedLanguagePacket={selectedLanguagePacket} 
            savedLanguagePacket={savedLanguagePacket}
            availableAdditionalOptions={availableAdditionalOptions}
            savedAdditionalOption={savedAdditionalOption}
            toggleAdditionalOption={this.toggleAdditionalOption}
            selectedAdditionalOptions={selectedAdditionalOptions}
            selectedAdditionalOptionsForOfferSection={selectedAdditionalOptionsForOfferSection}
            gettingDataFromDbStatusReady={gettingDataFromDbStatusReady}
            displayPrice={displayPriceInOffer}
            onSectionClick={this.onSectionClick}
            clientLeadBaseData={clientLeadBaseData}
            userType={userType}
            paramID={params.id}
            secondPatientForm={secondPatientForm}
            secondPatientId={secondPatientId}
            isAditionalPatient={isAditionalPatient}
            canCreateContract={this.props.canCreateContract}
          />
          <SummaryMenu  
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }

    if (activeSection === MENU_SECTIONS.SECTION_WHY_PM24) {
      content = (
        <div className="d-flex">
          <SumaryWhySection 
            translateKey={ item => translateKey(item) }
          />
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }

    if (activeSection === MENU_SECTIONS.SECTION_ABOUT_OFFER) {
      content = (
        <div className="d-flex">
          <div 
            id="carouselExampleInterval" 
            className="carousel slide w-100" 
            data-ride="carousel"
            data-interval="false"
          >
            <SecondSliderContent />
            {this.renderCarouselButton('prev')}
            {this.renderCarouselButton('next')}
          </div>
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }
  
    if (activeSection === MENU_SECTIONS.SECTION_LANGUAGE) {
      content = (
        <div className="d-flex">
          <SpracheSection 
            disabled={disabled}
            availableLanguagePackets={availableLanguagePackets}
            setLanguagePacket={this.setLanguagePacket} 
            recommendedLanguagePacket={recommendedLanguagePacket}
            selectedLanguagePacket={selectedLanguagePacket}
          />
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }
  
    if (activeSection === MENU_SECTIONS.SECTION_ADDITIONAL_OPTIONS) {
      content = (
        <div className="d-flex">
          <AdditionalOptionsSection
            // cannotChangePromPlus = {false}
            cannotChangePromPlus = {cannotChangePromPlus}
            disabled={disabled}
            availableAdditionalOptions={availableAdditionalOptions}
            setAdditionalOption={this.setAdditionalOption}
            recommendedAdditionalOptions={recommendedAdditionalOptions}
            selectedAdditionalOptions={selectedAdditionalOptions}
            secondPatientName={ secondPatientName }
            setMultipleClientsForCheckbox={ this.setMultipleClientsForCheckbox }
          />
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }
  
    if (activeSection === MENU_SECTIONS.SECTION_PEOPLE) {
      content = (
        <div className="d-flex justify-content-center">
          <div className="container-fluid no-gutters p-0 d-flex flex-column h-100">
            {this.getMeKoPilotenSection()}
          </div>
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }
 
    if (activeSection === MENU_SECTIONS.SECTION_SUMMARY) {
      content = (
        <div className="d-flex justify-content-center">
          <CartSection 
            form={form}
            availableLanguagePackets={availableLanguagePackets}
            availableAdditionalOptions={availableAdditionalOptions}
            selectedLanguagePacket={selectedLanguagePacket} 
            selectedAdditionalOptions={selectedAdditionalOptions}
            secondPatientForm={secondPatientForm}
            secondPatientId={secondPatientId}
            isAditionalPatient={isAditionalPatient}
          />
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }
  
    if (activeSection === CANCEL_CONTRACT) {
      content = (
        <div className="d-flex justify-content-center">
          <CancelContract 
            form={form}
            cancelContract={this.onCancelOfferClick}
            secondPatientForm={secondPatientForm}
            isAditionalPatient={isAditionalPatient}
            setActiveSection={this.props.setActiveSection}
          />
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }
    return (
      <React.Fragment>
        <LoaderModal show={loaderModal}/>
        <MultiPatientModal 
          show={ this.state.showMultiPatientModal } 
          accept={ this.acceptMultiplePatients } 
          cancel={ this.cancelMultiplePatients } 
          redirect={ this.redirectToSecondPatient }
          translateKey={ item => translateKey(item) }
          secondPatientName={ secondPatientName }
          showRedirection={ this.state.showRedirection }
        />
        <ConfirmModal 
          rejectMe={this.toggleCancelation} 
          acceptMe={this.confirmCancellationYes} 
          show={confirmCancellation} 
          titleMessage="" 
          mainMessage={translateKey("confirm_cancel_contract")} 
          messageYes={translateKey("yes")} 
          messageNo={translateKey("no")}
        />
        <InfoModal okMe={this.hideInfo} show={infoModalOptions.show} titleMessage="" mainMessage={infoModalOptions.message} okMessage={translateKey("Ok")} />
        {content?content:<div className="d-flex" />}
        <AlertModalInfo size="lg" centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message} />
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    multipliersData: state.database.multipliers,
    multipliersTimeStamp: state.database.multipliersTimeStamp,
    promedicaProductsPrices: state.enums.promedicaProductsPrices,
    multiCategoryEnum: state.enums.multipliersCategoryList,
    multiCategoryEnumStatus: state.enums.multipliersCategoryStatus,
    fpFirstMail: state.auth.contactFpFirstEmail,
    fpName: state.auth.contactFpName,
    fpPhone: state.auth.FPphone,
    cities: state.enums.citiesList,
    userId: state.auth.userId,
    customersOptions: state.customersOptions.customersOptions,
    priceType: state.priceType.priceType,
  };
};

const mapDispatchToProps = dispatch => ({ 
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  setMultiplikatorenData: (data) => dispatch(setMultipliersData(data)),
  pendingMultiplikatorenRequest: () => dispatch(pendingMultipliersData()),
  clearMultiplikatorenData: () => dispatch(removeMultipliersData()),
  getProducts: () => dispatch(getUserProducts()),
  getRegions: () => dispatch(getRegionsFP()),
  setCustomersOptions: options => dispatch(setCustomersOptions(options)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionOfferSummary));