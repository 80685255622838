import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../_redux/actions/index";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody
} from "mdbreact";
import { CheckBoxComponent } from "../form-bb-components/checkbox-component/checkbox-component";

class SideBarCollapseMultiplicators extends Component {
  _isMounted = false;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      collapseIsOpen: false
    };
  }

  toggleCollapse = () =>{
    if(!this.props.category.IsSubCategory){
      this.setState(prevState => {
        return {
          ...prevState,
          collapseIsOpen: !prevState.collapseIsOpen,
        }
      });
    }
  }

  changeMeActiveCategory = (cat, value)=>{
    this.props.changeActive(cat)
  }

  getMeCategoryChildren=()=>{
    const { category, activeCategories, translateKey, multipliersData } = this.props;
    let newList = [];
    category.SubCategoriesList.forEach(SubCategory => {
      if(SubCategory.IsSubCategory){
        const isActive = activeCategories.includes(SubCategory.CategoryId.toUpperCase());
        newList.push(
          <div key={SubCategory.CategoryId} className={`${isActive?'multiplicator-active subc':''}`}>
            <CheckBoxComponent
              className={`sub-multiplicator-checkbox multiplicator-checkbox-icon ${isActive?'active-cat':''}`}
              newUI={true}
              disabled={false}
              checkClassWrapper={'newUI-radio-check-box-class'}
              showValidation={false}
              setTypeChangeToParent={this.changeMeActiveCategory}
              value={isActive}
              text={translateKey(SubCategory.Name)}
              transKey={SubCategory.Name}
              keyName={SubCategory.CategoryId}
              labelClass={`check-full-label-width sub-cat-text-font ${isActive?'active':''}`}
            />
            {
              isActive &&
              <div className="triangle-left"></div>
            }
          </div>
        );
      }
    });
    return newList;
  }

  isActive(id) {
    const { activeCategories} = this.props;
    return activeCategories.includes(id.toUpperCase());
  }

  render() {
    const { collapseIsOpen } = this.state;
    const { category, name, multipliersData } = this.props;
    return (
      <MDBContainer className="multiplicator-sidebar-container">
        <MDBContainer className="multiplicator-sidebar-container">
            {category.SubCategoriesList.length>0?
                <MDBCard className="multiplicator-mdb-card-modif2 card-header-multiplikator with-collapsed">
                  <div className="card-header-multiplikator" onClick={()=>this.toggleCollapse()}>
                    <div className="multiplicator-subcontainer-with-subcategory kopiloten-parent-rem">
                      <span className="ko-piloten-category-text newUI-text-color">{name}</span>
                      <i className={collapseIsOpen? "fa fa-angle-up":"fa fa-angle-down"}/>
                    </div>
                  </div>
                  <MDBCollapse isOpen={collapseIsOpen}>
                    <MDBCardBody className="card-body-multiplikator">
                      <div className="lead-ko-piloten-sub-categories-wrapper">
                        {this.getMeCategoryChildren()}
                      </div>
                    </MDBCardBody>
                  </MDBCollapse>
                </MDBCard>
              :
                <MDBCard className={`multiplicator-mdb-card-modif ${this.isActive(category.CategoryId)?'multiplicator-active':''}`}>
                  <div className={`card-header-multiplikator without ${this.isActive(category.CategoryId)?'active':''}`} onClick={()=>this.toggleCollapse()}>
                    <CheckBoxComponent
                      className={`multiplicator-checkbox-main-category ${this.isActive(category.CategoryId)?'active':''} multiplicator-checkbox-icon`}
                      newUI={true}
                      checkClassWrapper={'newUI-radio-check-box-class'}
                      showValidation={false}
                      setTypeChangeToParent={this.changeMeActiveCategory}
                      value={this.isActive(category.CategoryId)}
                      text={name}
                      transKey={category.Name}
                      keyName={category.CategoryId}
                      labelClass={`check-full-label-width sub-cat-text-font ${this.isActive(category.CategoryId)?'active':''}`}
                    />
                    {
                      this.isActive(category.CategoryId) &&
                      <div className="triangle-left"></div>
                    }
                  </div>
                </MDBCard>
            }
        </MDBContainer>
      </MDBContainer>
    );
  }
}

const mapStateToProps = state => {return {multipliersData: state.database.multipliers};};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) =>  dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBarCollapseMultiplicators));