import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from '../../../../_redux/actions/index';
import { KoPilotenCategoryHelper } from "../../../../_helpers/index";
import { getMultiplierCategoryList, getMultiplierCategoryRange } from "../../../../_services/kopiloten_service";
import { KoPilotenMainForm, KoPilotenCategory}  from "../../../../_forms/KoPilotenForm/index";
import { CategoriesKeyList } from "../../../../_forms/KoPilotenForm/values/categoriesKeyList";
import { Alert } from "../../../../_constance/classes/index";
import { InfoModal, LoaderModal, AlertModalInfo } from "../../../../_components/index";
import {generatePdfBasedOnMultipliers, getFooterData} from '../../../../_services/backend_service';
import ListComponent from "../../list-component/list-component";
import * as MultiplierStatus from "../../../../_constance/enums/MultiplierStatus";
import * as Gender from "../../../../_constance/enums/Gender";
import produce from "immer";
import * as RecordType from "../../../../_constance/values/recordType";
import {
  getGreetings
} from '../../../../_services/footer_formatter';

const genderKey = 'is_candidate_gender';

class Section3 extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.showComponent = this.showComponent.bind(this);
    this.state = {
      sectionList: this.props.sectionList,
      activeSection: this.props.activeSection,
      idComponent: false,
      emailTosendKopiloten: this.propstype === RecordType.LEAD ? this.props.clientLeadBaseData.client.email : this.props.form.getFieldByKey("is_email").value,
      showValidation: false,
      KoPilotenForm: new KoPilotenMainForm(),
      loaderModal: false,
      errorComponent: Alert,
      infoModalOptions: Alert,
      chosedEmail: '717700002',
      customGreetingsText: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadCustomGreetings();
    this.prepareMeBasicKoPilotenFormData();
  }

  changeSectionPage(number) {
    this.props.changeSection(number);
  }

  showComponent(idComponent) {
    this.props.showComponent(idComponent);
  }

  emailHendler(value) {
    this.setState({
      ...this.state,
      emailTosendKopiloten: value,
    });
  }

  sendKopiloten() {
    const isEmailValid = this.validate();

    if(isEmailValid){
      this.prepareDataAndSendPDF();
    }
  }

  validate(){
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if(!this.state.emailTosendKopiloten || reg.test(this.state.emailTosendKopiloten) === false){
        this.setState({showValidation: true});
        return false;
    } else {
      this.setState({showValidation: false});
      return true;
    }
  }

  hideInfo = () => {
    this.setState({
      infoModalOptions: {
        show: false,
        type: "danger",
        message: this.props.translateKey("basic_error_message")
      },
    });
    this.props.toogleMenu();
  }

  setTypeChangeAndSetEmail = (key, value) => {
      let setEmail = '';
      switch(value){
        case '717700000':   setEmail = '';
                            break;
        case '717700001':   
                            if(this.props.clientLeadBaseData.patient.email !== null){
                              setEmail = this.props.clientLeadBaseData.patient.email;
                            } else {
                              setEmail = '';
                            }
                            break;
        case '717700002':   
                            if (this.propstype === RecordType.LEAD) {
                              if(this.props.clientLeadBaseData.client.email !== null){
                                setEmail = this.props.clientLeadBaseData.client.email;
                              } else {
                                setEmail = '';
                              }
                            } else {
                              if(this.props.form.getFieldByKey("is_email").value !== null){
                                setEmail = this.props.form.getFieldByKey("is_email").value;
                              } else {
                                setEmail = '';
                              }
                            }
        case '717700003':   
                            if(this.props.fpFirstMail !== null){
                              setEmail = this.props.fpFirstMail;
                            } else {
                              setEmail = '';
                            }
                            break;
        default:
      }
      
      this.setState({
        emailTosendKopiloten: setEmail,
        chosedEmail: value,
      })

  }

  putNewCategoryKoPiloten(catId, label, multi) {
    const {match: { params } } = this.props;
    const nextState = produce(this.state, (draftState) => {
      const { KoPilotenForm } = draftState;
      KoPilotenForm.categories.push(
        new KoPilotenCategory(
          null,
          KoPilotenCategoryHelper.getMeNameBasedOnLabel(label),
          catId,
          params.id,
          null,
          multi.map((element)=>{return element.toUpperCase()}),
          [],
          KoPilotenCategoryHelper.getMeSubcategoriesBasedOnLabel(label),
          [],
          KoPilotenCategoryHelper.isSubCategory(label)
        )
      );
    });
    this.setState(nextState);
  }

  prepareMultiplierRangeKoPiloten() {
    const {match: { params } } = this.props;
    const { KoPilotenForm } = this.state;
    getMultiplierCategoryRange(params.id)
      .then((res) => {
        if (res.length > 0) {
          res.forEach((element, index) => {
            let newObj = {
              MultiplierIds: element.MultiplierIds.map((element)=>{return element.toUpperCase()}),
              MultiplierRangeId: element.MultiplierRangeId,
              Note: element.Note,
            };
            KoPilotenForm.setDataToCategory(newObj, element.CategoryId.toUpperCase());
            if (index === res.length - 1) {
              this.finalPreparationKoPiloten();
            }
          });
        } else {
          this.finalPreparationKoPiloten();
        }
      })
      .catch((error) => {
        console.log(error);
        this.finalPreparationKoPiloten();
      });
  }

  finalPreparationKoPiloten(){
    const { KoPilotenForm } = this.state;
    CategoriesKeyList.forEach((element,index) => {
      const category = KoPilotenForm.getCategoryByName(element.key);
      KoPilotenForm.moveCategory(index, category);
    });  
    let activeCat = '0';
    KoPilotenForm.categories.forEach((element, index) => {
      if(activeCat==='0'){
        if(element.gotMultipliersToSelect){
          activeCat = element.CategoryId;
        }
      }
      if (element.IsSubCategory === false) {
        if (element.SubCategoriesKeyList.length > 0) {
          element.SubCategoriesKeyList.forEach((transKey) => {
            let subKey = KoPilotenForm.getCategoryIdByName(transKey);
            if (subKey) {
              KoPilotenForm.assingSubCategorie(element.CategoryId, subKey);
            }
          });
        }
      }
      if (index >= KoPilotenForm.categories.length - 1) {
        this.myTimeouKoPiloten = setTimeout(() => {
          this.setState({
            KoPilotenLoadingBasics: false,
            KoPilotenActiveCategoryId: activeCat
          });
        }, 100);
      }
    });
  }

  prepareMeBasicKoPilotenFormData() {
    const { multiCategoryEnum } = this.props;
    const { loadingMultipliers } = this.state;
    if(multiCategoryEnum.length > 0 && !loadingMultipliers ) {
      clearTimeout(this.myTimeouKoPiloten);
      let count = 0;
      multiCategoryEnum.forEach((element, index) => {
        getMultiplierCategoryList(element.Value)
        .then((res) => {
          count++;
          this.putNewCategoryKoPiloten(element.Value, element.Label, res.MultiplierIds); 
          if(count===multiCategoryEnum.length-1){
            this.myTimeouKoPiloten = setTimeout(() => {this.prepareMultiplierRangeKoPiloten()}, 500);
          }
        })
        .catch(error => {
          count++;
          if(count===multiCategoryEnum.length-1){
            this.myTimeouKoPiloten = setTimeout(() => {this.prepareMultiplierRangeKoPiloten()}, 500);
          }
        });
      });
    } else {
      this.myTimeouKoPiloten = setTimeout(() => {this.prepareMeBasicKoPilotenFormData();}, 100);
    }
  }

  prepareDataAndSendPDF = () =>{
    const { KoPilotenForm, loadingMultipliers} = this.state;
    const { multipliersData, translateKey } = this.props;
    const isEmailField = this.state.emailTosendKopiloten;
    if(!loadingMultipliers && multipliersData!==false && multipliersData !== null && multipliersData.length > 0){
      if(isEmailField){
        this.setState({loaderModal: true})
        let pdfDataToSend = [];
        const catList = [...KoPilotenForm.categories];
        catList.forEach(cat => {
          if(cat.SubCategoriesList.length===0){
            //main category
            if(cat.gotMultipliers(multipliersData)){
              let multipliersDataToSend = [];
              cat.MultiplierIds.forEach(multiId => {
                let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                if(foundMulti){
                  if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                    let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                    if(exists>-1){
                      multipliersDataToSend.push(foundMulti);
                    }
                  }
                }
              });
              if(multipliersDataToSend.length>0){
                // we got main category and some multipliers with data to send
                pdfDataToSend.push({
                  parentName: translateKey(cat?cat.Name:''),
                  childName: null,
                  categoryId: cat?cat.CategoryId:'',
                  note: cat?cat.Note:'',
                  multipliersData: multipliersDataToSend
                })
              }
            }
          }else{
            //got subcategories
            cat.SubCategoriesList.forEach(subCat => {
              if(subCat.gotMultipliers(multipliersData)){
                let multipliersDataToSend = [];
                subCat.MultiplierIds.forEach(multiId => {
                  let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                  if(foundMulti){
                    if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                      let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                      if(exists>-1){
                        multipliersDataToSend.push(foundMulti);
                      }
                    }
                  }
                });
                if(multipliersDataToSend.length>0){
                  // we got subcategory and some multipliers with data to send
                  pdfDataToSend.push({
                    parentName: translateKey(cat?cat.Name:''),
                    childName: translateKey(subCat?subCat.Name:''),
                    categoryId: subCat?subCat.CategoryId:'',
                    note: subCat?subCat.Note:'',
                    multipliersData: multipliersDataToSend
                  })
                }
              }
            });
          }
        });
        if(pdfDataToSend.length>0){
          //got some checked multipliers so we can generate pdf
          const koPilotenDataToSend = {
            userID: this.props.userId,
            list: pdfDataToSend,
            toEmail: isEmailField,
            content: this.getMeMultiEmailContent()
          }
          generatePdfBasedOnMultipliers(koPilotenDataToSend)
          .then(res => {
            if(res){
              if(res.status===200){
                if (this._isMounted) {
                  this.setState({
                    loaderModal: false,
                    infoModalOptions: {
                      show: true,
                      type: "success",
                      message: translateKey('ko-piloten-success-pdf-send')
                    },
                  })
                }
              }else{
                if (this._isMounted) {
                  this.setState({
                    loaderModal: false,
                    infoModalOptions: {
                      show: true,
                      type: "danger",
                      message: translateKey('basic_error_message')
                    }
                  })
                }
              }
            }
          })
          .catch((error) => {
            if (this._isMounted) {
              this.setState({
                loaderModal: false,
                errorComponent: {
                  show: true,
                  type: "danger",
                  message: "basic_error_message"
                },
              })
              document.body.classList.remove('overflow-y-scroll');
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({ 
                    errorComponent: Alert,
                    KoPilotenSaving: false
                  });
                }
              }, 3000);
            }
          });
        }else{
          if (this._isMounted) {
            this.setState({
              loaderModal: false,
              infoModalOptions: {
                show: true,
                type: "danger",
                message: translateKey('ko-piloten-at-least-one-category')
              }
            })
          }
        }
      }else{
        this.setState({
          loaderModal: false,
          infoModalOptions: {
            show: true,
            type: "danger",
            message: translateKey("form-text-err_not_an_email_address")
          },
        })
      }
    }
  }

  loadCustomGreetings = () => {
    getFooterData(this.props.userId).then(response => {
      if (response && this._isMounted) {
        this.setState({
          customGreetingsText: response.data.data[0].wysiwyg
        })
      }
    })
  }

  getMeMultiEmailContent = () =>{
    const { translateKey, fpName, fpFirstMail, form, fpPhone } = this.props;
    const tdStyles = 'border:0;padding:0;';
    const spanClass = 'font-weight:bold;color:#000000;';
    const breakLineTable = `<tr><td style="border:0;padding: 0px 0 20px 0;"></td></tr>`;
    const genderClient = form.getFieldByKey(genderKey);
    let genderText = '';
    if(genderClient.value === parseInt(Gender.MALE)){
      genderText =  translateKey('contract-text-herr');
    }else{
      if(genderClient.value === parseInt(Gender.FEMALE)){
        genderText =  translateKey('form-text-frau');
      }
    }
    const greetings = getGreetings(this.state.customGreetingsText, breakLineTable, tdStyles, fpName, fpPhone, fpFirstMail);
    const lastNameField = form.getFieldByKey('lastname');
    const htmlMailContent = `
    <table style="width:100%;color:black;border:0;outline:0;">
      <tbody>
        <tr><td style="${tdStyles}">Sehr geehrter <span style="${spanClass}">${genderText} ${lastNameField.value},</span></td></tr>
        ${breakLineTable}
        <tr><td style="${tdStyles}">Im Anhang finden Sie die Liste der Ko-Piloten. </td></tr>
        ${breakLineTable}
        <tr><td style="${tdStyles}">Bei Fragen stehe ich Ihnen gerne zur Verfügung. </td></tr>
        ${greetings}
      </tbody>
    </table>
    `;
    return htmlMailContent;
  }

  render() {
    const { translateKey, type } = this.props;
    const { showValidation, errorComponent, infoModalOptions, loaderModal, chosedEmail } = this.state;
    const buttons = [
      { value: '717700003', label: translateKey("die-bestellung-form-label-fp") },
      { value: '717700002', label: translateKey("die-bestellung-form-label-kunde") },
      { value: '717700001', label: translateKey("die-bestellung-form-label-patient") },
      { value: '717700000', label: translateKey("other") },
    ];

    if (type !== RecordType.LEAD) {
      buttons.splice(2, 1);
    }

    return (
      <div className="newUI-section-padding new-menu__section-row ">
        <LoaderModal show={loaderModal}/>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="new-menu__section-title">
                {translateKey("new-hamburger-menu_send-list-withko-pilot").toUpperCase()}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <ListComponent
              newUI={true}
              setTypeChangeToParent={this.setTypeChangeAndSetEmail}
              showValidation={false}
              className="form-margin-bottom-fix"
              transKey="new-hamburger-menu_email-check"
              key="is_identical_email"
              keyName="is_identical_email"
              value={chosedEmail}
              validationErrors={false}
              valuesList={buttons}
            />
            <p>{translateKey("new-hamburger-menu_email-address-ko-piloten-send")}</p>
            <input
              style={{maxWidth: '400px'}}
              type='text'
              onChange={e => this.emailHendler(e.target.value)}
              value={this.state.emailTosendKopiloten}
              className={`form-control input-flex-firefox-fix`}
              placeholder='m.mustermann@promedica24.pl'
            />
            <div className={`invalid-feedback ${showValidation ? "showError" : ""}`}>
              {translateKey("form-text-err_not_an_email_address")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <button 
                type="button" 
                className="btn btn-success button-order"
                onClick={() => {this.sendKopiloten()}} 
                >
                    {translateKey("new-hamburger-menu_send").toUpperCase()}
                <i className="fas fa-chevron-right summary-offer-icon" />
            </button>
          </div>
        </div>
        <InfoModal okMe={this.hideInfo} show={infoModalOptions.show} titleMessage="" mainMessage={infoModalOptions.message} okMessage={translateKey("Ok")} />
        <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={translateKey(errorComponent.message)}/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    multipliersData: state.database.multipliers,
    multiCategoryEnum: state.enums.multipliersCategoryList,
    fpFirstMail: state.auth.contactFpFirstEmail,
    fpName: state.auth.contactFpName,
    fpPhone: state.auth.FPphone,
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section3));
