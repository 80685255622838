import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";

class LeadCancelSaveComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edited: this.props.edited,
            savingData: this.props.savingData
        };
    }

    render() {
        const {savingData, edited, onlySave, translateKey, cancelChange, saveChange, generatePdfFunction, generatePdf, back} = this.props;
        return (
            <div className="lead-cancel-save-row">
            {
                savingData &&
                    <div className="modal fade show modalShowFull" id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="filterModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm modalShowLoaderDialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="empty-table-wrapper loader-as-modal-wrapper">
                                        <i className="fas fa-spinner fa-spin fa-4x empty-table-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
            }
            {
                (edited || generatePdf || !onlySave) &&
                    <div className="cancel-save-button-wrapper">
                        {!edited &&
                            <button className="btn btn-default btn-cancel-save-button" onClick={()=>back()}>
                                <i className="fas fa-arrow-left fa-2x"></i>
                                <span>{translateKey("back")}</span>
                            </button>
                        }
                        {(!onlySave && edited) &&
                            <button className="btn btn-default btn-cancel-save-button" onClick={()=>cancelChange()}>
                                <i className="fas fa-times fa-2x"></i>
                                <span>{translateKey("cancel")}</span>
                            </button>
                        }
                        {edited &&
                        <button className="btn btn-default btn-cancel-save-button" onClick={()=>saveChange()}>
                            <i className="fas fa-save fa-2x"></i>
                            <span>{translateKey("save")}</span>
                        </button>
                        }
                        {generatePdf &&
                            <button className="btn btn-default btn-cancel-save-button" onClick={()=>generatePdfFunction()}>
                                 <i className="fas fa-save fa-2x"></i>
                                <span>{translateKey('kopiloten-sende-text')}</span>
                            </button>
                        }
                    </div>
            }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(LeadCancelSaveComponent));