import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import SectionNavigationNewMenuElement from "../sectionNavigationBar/SectionNavigationNewMenuElement";
import Section1 from "./SectionNavigationBarComponents/Section1";
import Section2 from "./SectionNavigationBarComponents/Section2";
import Section3 from "./SectionNavigationBarComponents/Section3";
import Section4 from "./SectionNavigationBarComponents/Section4";

class SectionNavigationBar extends React.Component {
_NEW_MENU_COMPONENT_LIST = [
    {
        idComponent: '0',
        icon: <i className="fas fa-dollar-sign"></i>,
        name: this.props.translateKey('new-hamburger-menu_change-display-daily'),
        component: '1',
    },
    {
        idComponent: '1',
        icon: <i className="far fa-file-pdf"></i>,
        name: this.props.translateKey('new-hamburger-menu_send-list-withko-pilot'),
        component: '3',
    },
    {
      idComponent: '2',
      icon: <i className="far fa-file-pdf"></i>,
      name: this.props.translateKey('new-hamburger-menu_send-zusammenfassung'),
      component: '4',
    },
    {
        idComponent: '3',
        icon: <i className="fas fa-font"></i>,
        name: this.props.translateKey('new-hamburger-menu_change-text-size'),
        component: '2',
    },
];

  constructor(props) {
    super(props);
    this.showComponent = this.showComponent.bind(this)
    this.state = {
      sectionList: this.props.sectionList,
      activeSection: this.props.activeSection,
      idComponent: false,
    };
  }

  changeSectionPage(number) {
    this.props.changeSection(number);
  }

  showComponent(idComponent) {
      this.props.showComponent(idComponent);
  }

  render() {
    const { translateKey, idComponent, toogleMenu, secondPatientForm, isAditionalPatient, type } = this.props;
    if (idComponent) {
        const findComponent = this._NEW_MENU_COMPONENT_LIST.find((element) => element.idComponent === idComponent) || {};
        // return findComponent.component;
        switch(findComponent.component){
          case '1': return <Section1 toogleMenu={toogleMenu} />
          case '2': return <Section2 toogleMenu={toogleMenu} />
          case '3': return <Section3 
                      form={this.props.form}
                      clientLeadBaseData={this.props.clientLeadBaseData}
                      toogleMenu={toogleMenu}
                      type={type}
                    />
          case '4': return <Section4
            form={this.props.form}
            clientLeadBaseData={this.props.clientLeadBaseData}
            toogleMenu={toogleMenu}
            secondPatientForm={secondPatientForm}
            isAditionalPatient={isAditionalPatient}
            type={type}
          />
          default:  
        }
    }
    return (
      <div className="sectionNavigationBarWrapper container">
        {
          this._NEW_MENU_COMPONENT_LIST.map((element) => (
            <SectionNavigationNewMenuElement
              isDisabled={false}
              elementNumber={element.icon}
              sectionName={translateKey(element.name)}
              component={element.component}
              idComponent={element.idComponent}
              showComponent={this.showComponent}
              key={ element.idComponent }
            />
          ))
        }
      </div>
    )

  }
}
const mapDispatchToProps = dispatch => ({ 
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) 
});
export default withRouter(connect(null, mapDispatchToProps)(SectionNavigationBar));