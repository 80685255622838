export const SEX = {
  MAN: 'man',
  WOMAN: 'woman'
}

export const DRIVING_LICENSE = {
  YES: 'drivingLicense',
  NO: 'noDrivingLicense'
}

export const CVS_MAP = {
  man: {
    drivingLicense: {
      "100": {
        cv1: {
          img: require("../_assets/cvs/man/CV_M_AB100_DL.jpg"),
          pdf: require("../_assets/cvs/man/CV_M_AB100_DL.pdf"),
        }
      },
      "300": {
        cv1: {
          img: require("../_assets/cvs/man/CV_M_AB300_DL.jpg"),
          pdf: require("../_assets/cvs/man/CV_M_AB300_DL.pdf"),
        }
      }, 
      "500": {
        cv1: {
          img: require("../_assets/cvs/man/CV_M_AB500_DL.jpg"),
          pdf: require("../_assets/cvs/man/CV_M_AB500_DL.pdf"),
        }
      }, 
      "750": {
        cv1: {
          img: require("../_assets/cvs/man/CV_M_AB500_DL.jpg"),
          pdf: require("../_assets/cvs/man/CV_M_AB500_DL.pdf"),
        }
      }, 
      "1000": {
        cv1: {
          img: require("../_assets/cvs/man/CV_M_AB1000_DL.jpg"),
          pdf: require("../_assets/cvs/man/CV_M_AB1000_DL.pdf"),
        }
      },
    },
    noDrivingLicense: {
      "100": {
        cv1: {
          img: require("../_assets/cvs/man/CV_M_AB100_NDL.jpg"),
          pdf: require("../_assets/cvs/man/CV_M_AB100_NDL.pdf"),
        }
      },
      "300": {
        cv1: {
          img: require("../_assets/cvs/man/CV_M_AB300_NDL.jpg"),
          pdf: require("../_assets/cvs/man/CV_M_AB300_NDL.pdf"),
        }
      },
      "500": {
        cv1: {
          img: require("../_assets/cvs/man/CV_M_AB500_NDL.jpg"),
          pdf: require("../_assets/cvs/man/CV_M_AB500_NDL.pdf"),
        }
      },
      "750": {
        cv1: {
          img: require("../_assets/cvs/man/CV_M_AB500_NDL.jpg"),
          pdf: require("../_assets/cvs/man/CV_M_AB500_NDL.pdf"),
        }
      },
      "1000":{
        cv1: {
          img: require("../_assets/cvs/man/CV_M_AB1000_NDL.jpg"),
          pdf: require("../_assets/cvs/man/CV_M_AB1000_NDL.pdf"),
        },
      },
    }
  },
  woman: {
    drivingLicense: {
      "100": {
        cv1: {
          img: require("../_assets/cvs/woman/CV_W_AB100_DR.jpg"),
          pdf: require("../_assets/cvs/woman/CV_W_AB100_DR.pdf"),
        }
      },
      "300": {
        cv1: {
          img: require("../_assets/cvs/woman/CV_W_AB300_DR.jpg"),
          pdf: require("../_assets/cvs/woman/CV_W_AB300_DR.pdf"),
        },
      }, 
      "500": {
        cv1: {
          img: require("../_assets/cvs/woman/CV_W_AB500_DR.jpg"),
          pdf: require("../_assets/cvs/woman/CV_W_AB500_DR.pdf"),
        }
      },
      "750": {
        cv1: {
          img: require("../_assets/cvs/woman/CV_W_AB500_DR.jpg"),
          pdf: require("../_assets/cvs/woman/CV_W_AB500_DR.pdf"),
        }
      }, 
      "1000": {
        cv1: {
          img: require("../_assets/cvs/woman/CV_W_AB1000_DR.jpg"),
          pdf: require("../_assets/cvs/woman/CV_W_AB1000_DR.pdf"),
        }
      },
    },
    noDrivingLicense: {
      "100": {
        cv1: {
          img: require("../_assets/cvs/woman/CV_W_AB100_NDR.jpg"),
          pdf: require("../_assets/cvs/woman/CV_W_AB100_NDR.pdf"),
        }
      },
      "300": {
        cv1: {
          img: require("../_assets/cvs/woman/CV_W_AB300_NDR.jpg"),
          pdf: require("../_assets/cvs/woman/CV_W_AB300_NDR.pdf"),
        }
      },
      "500": {
        cv1: {
          img: require("../_assets/cvs/woman/CV_W_AB500_NDR.jpg"),
          pdf: require("../_assets/cvs/woman/CV_W_AB500_NDR.pdf"),
        }
      },
      "750": {
        cv1: {
          img: require("../_assets/cvs/woman/CV_W_AB500_NDR.jpg"),
          pdf: require("../_assets/cvs/woman/CV_W_AB500_NDR.pdf"),
        }
      },
      "1000":{
        cv1: {
          img: require("../_assets/cvs/woman/CV_W_AB1000_NDR.jpg"),
          pdf: require("../_assets/cvs/woman/CV_W_AB1000_NDR.pdf"),
        }
      },
    }
  }
}

export function getRandomCV(sex = SEX.WOMAN, drivingLicense = DRIVING_LICENSE.YES, languageSKill = "300") {
  const cvsCount = Object.keys(CVS_MAP[sex][drivingLicense][languageSKill]).length;
  return CVS_MAP[sex][drivingLicense][languageSKill][`cv${Math.floor(Math.random() * cvsCount) + 1}`];
}