export const getGreetings = (custom, breakLineTable, tdStyles, fpName, fpPhone, fpFirstMail) => {
    if (custom !== undefined && custom !== null && custom.length > 0) {
        const formatted = formatFooter(custom);
        const greetings = `
    ${breakLineTable}
      <tr><td style="${tdStyles}">Mit freundlichen Grüßen, </td></tr>
      <tr><td style="${tdStyles}">${formatted}</td></tr>`;
        return greetings;
    } else {
        const greetings = `
    ${breakLineTable}
      <tr><td style="${tdStyles}">Mit freundlichen Grüßen, </td></tr>
      <tr><td style="${tdStyles}">${fpName}</td></tr>
      ${
            fpPhone ? `
        <tr><td style="${tdStyles}">${fpPhone.slice(0, 3)} ${fpPhone.slice(3, fpPhone.length)}</td></tr>
        ` : ``
        }
      ${fpFirstMail !== null ? '<tr><td style="' + tdStyles + '"><a style="color:#0056a4; text-decoration: none;" href="mailto:' + fpFirstMail + '">' + fpFirstMail + '</a></td></tr>' : ''}
    `;
        return greetings;
    }
}

const formatFooter = (footer) => {
    let formatted = footer.replaceAll('<h1>', '<h1 style="margin: 0px; padding: 0px;">');
    formatted = formatted.replaceAll('<h2>', '<h2 style="margin: 0px; padding: 0px;">');
    formatted = formatted.replaceAll('<h3>', '<h3 style="margin: 0px; padding: 0px;">');
    formatted = formatted.replaceAll('<p>', '');
    formatted = formatted.replaceAll('</p>', '<br>');
    formatted = formatted.replaceAll('<br><br>', '<br>');
    return formatted;
}