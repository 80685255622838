import React from 'react';
import { connect } from 'react-redux';
import { translateKey } from "../../_redux/actions/index";

class SelectOptionsComponent extends React.Component {
    static defaultProps = {
        valuesList: []
    };


    setTypeChange = event => {
        const { target: { value } } = event;
        const { keyName, setTypeChangeToParent } = this.props;
        setTypeChangeToParent(keyName, value);
    };

    labelStyle() {
        return (this.props.colClassLabel || "col-sm-4") + " col-form-label form-label-basic"
    }

    inputStyle() {
        return this.props.colClassInput || "col-sm-8"
    }

    render() {
        const { valuesList, value, mainText, disabled, chooseText, isRequired, showStar, colClassLabel, colClassInput} = this.props;

        return ( 
            <div className="form-group row flexAlignCenter no-margin-row">
                <label className={this.labelStyle()}>{mainText} {showStar && <span className="red-star-required-field"> * </span>}</label>
                <div className={this.inputStyle()}>
                    <select disabled={disabled} className="custom-select col-sm-10 lead-input-wrapper" onChange={this.setTypeChange} value={value}>
                        <option hidden>{chooseText}</option>
                        {valuesList.map((element, index) => {
                            let convertedValue = value;
                            if(convertedValue){
                                convertedValue.toString().toLowerCase()
                            }
                            if(element.Value.toString().toLowerCase() === convertedValue){
                                return <option hidden={element.Hidden?element.Hidden===true?true:false:false} defaultValue key={element.Value} value={element.Value}>{element.Label}</option>
                            }else{
                                return <option hidden={element.Hidden?element.Hidden===true?true:false:false} key={element.Value} value={element.Value}>{element.Label}</option>
                            }
                        })}
                    </select>
                    <div className="col-sm-12 is-invalid">
                        {isRequired && <div className="invalid-feedback">{this.props.translateKey("form_contains_errors")}</div>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default connect(null, mapDispatchToProps)(SelectOptionsComponent);