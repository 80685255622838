import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import {
  setLeadsData,
  pendingLeadsData,
  removeLeadsData,
  translateKey,
  authSetActionTimestamp,
  removeLeadStatusData,
  getLeadStatusData,
  updateLeadData,
  getTasks,
  setTasksData,
  needsSaveData,
  doesNotNeedsSave,
  setMultipliersData,
  pendingMultipliersData,
  removeMultipliersData
} from "../../_redux/actions/index";
import {
  ConfirmModal,
  LoaderModal,
  TableProperties,
  TableColumHead,
  TablePagination,
  NavBar,
  SideBar,
  LoaderTable,
  EmptyTableIcon,
  LeadDetailsBasicComponent,
  LeadClientComponent,
  LeadPatientComponent,
  LeadCancelSaveComponent,
  TableDateFormat,
  TaskStatusHandler,
  ConsentListComponent,
  AlertModalInfo,
  InfoModal,
  TaskPriorityConverter,
  TaskStatusLang
} from "../../_components/index";
import { Alert, LeadTable, Paging, ContractTable } from "../../_constance/classes/index";
import { IconsMap, ReqHelper, shouldRefresh, ResponseStatus, KoPilotenCategoryHelper } from "../../_helpers/index";
import { addNoteData, removeNoteData, leadSave } from "../../_redux/actions/auth";
import axiosAuth from "../../_services/config/axios-auth";
import { SortingTasks, SortingNotes, SortingPatient } from "../../_constance/classes/sortingClasses/index";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { refreshTokenTime } from '../../_constance/base_data';
/* hiiden due to FP-343
import { LeadContractData}  from '../../_components/LeadDetailsComponents/index';
*/
import { LeadDetailsPatientsDataComponent }  from '../../_components/LeadDetailsComponents/index';
import CareBoxDataComponent from '../../_components/CareBoxDetails/CareBoxDataComponent';
import KoPilotenCategoryComponent from '../../_components/MultipliersCategoryComponents/Ko-Piloten-Category-Component';
import { TableEnums } from "../../_constance";
import * as RecordType from "../../_constance/values/recordType";
import * as LanguageSelect from "../../_constance/values/languageSelect";
import * as LeadTabList from "../../_constance/values/leadTabList";
import * as FormTypeToShow from '../../_forms/BBForm/values/formTypeToShow';
import * as Entity from '../../_forms/BBForm/values/entity';
import { getDynamicFields, saveDynamicFields, getLeadsDataCall } from '../../_services/user_service';
import { baseData } from '../../_constance/base_data';
import { MultiplierTable } from "../../_constance/classes/index";
import { generatePdfBasedOnMultipliers } from '../../_services/backend_service';
import * as Gender from "../../_constance/enums/Gender";
import { getRegionsFP } from "../../_redux/actions/bb-form-services";
import * as MultiplierStatus from "../../_constance/enums/MultiplierStatus";
import { getFooterData } from '../../_services/backend_service';
import {
  getGreetings
} from '../../_services/footer_formatter';
import NewNoteModal from '../../_components/new-note-modal/NewNoteModal';

// KoPiloten
import { getMultiplierCategoryList, getMultiplierCategoryRange, saveMultiplierCategoryRange } from "../../_services/kopiloten_service";
import { KoPilotenMainForm, KoPilotenCategory, KoPilotenSaveRangeCategory}  from "../../_forms/KoPilotenForm/index";
import { CategoriesKeyList } from "../../_forms/KoPilotenForm/values/categoriesKeyList";
import produce from "immer";
import { saveStats } from "../../_services/backend_service";
import { StatsType } from "../../_constance/enums/statsType";
import { KoPilotenSaveType } from "../../_constance/enums/koPilotenSaveType";
const statisContractOffertStatus = '717700010';
const contractCancellationStatus = '717700000';
const defaultServiceTypeDe = '717700001';

class LeadsDetailsPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  myTimeouKoPiloten;
  autoSaveTimeOut;
  constructor(props) {
    super(props);
    this.state = {
      multiId: 0,
      activeTab: LeadTabList.LEAD,
      leadId: 0,
      leadData: false,
      leadBackup: false,
      savingDataLead: false,
      errorComponent: Alert,
      loadingLeads: true,
      loadingPatients: true,
      loadingNotes: true,
      leadNotesData: [],
      loadingTasks: true,
      leadTaskData:[],
      contractsData: [],
      loadingContracts: false,
      patientPaging: Paging,
      notesPaging: Paging,
      tasksPaging: Paging,
      sortingPatients: JSON.parse(JSON.stringify(SortingPatient)),
      sortingNotes: JSON.parse(JSON.stringify(SortingNotes)),
      sortingTasks: new SortingTasks(),
      noteData: {Id:0, Note: ''},
      noteTemp: {Id:0, Note: ''},
      saveErrorMessage: '',
      showEdit: false,
      loaderModal: false,
      type: "EDIT",
      errorModal: false,
      canAddContract: false,
      confirmDeleteNotes: false,
      noteToDelete: {},
      showValidation: false,
      dynamics:{
        is_lead_description: null,
        is_degree_relationship_with_patient_carer: null
      },
      dynamicsTemp:{
        is_lead_description: null,
        is_degree_relationship_with_patient_carer: null
      },
      breadCrumbs:[
        {
          link: "leads",
          key: "leads",
          name: null
        }
      ],
      loadingMultipliers: true,
      infoModalOptions: Alert,
      regionsFP: [],
      isUsesApp: null,

      //Kopiloten Data
      KoPilotenEdited: false,
      KoPilotenActiveCategoryId: '0',
      KoPilotenLoadingBasics: true,
      KoPilotenForm: new KoPilotenMainForm(),
      KoPilotenRerender: Date.now(),
      KoPilotenSaving: false,
      KoPilotenModificatedCategoryList:[],
      KoPilotenErrorComponent: Alert,
      disabled: false,
      serviceTypeDe: defaultServiceTypeDe,
      showCareBoxTab: false,
      showPatientTab: true,
      customGreetingsText: null,

      showConfirmationModal: false,
      showNoteModal: false,
      modalMessage: '',
      noteModalType: 'Event',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.props.doesNotNeedsSave();
    this.getMeRegions();
    this.getInitIsLeadDescription();
    this.prepareMeBasicKoPilotenFormData();
    this.myTimeout = setTimeout(() => {this.getInitialMultipliers();this.getInitialDataTime();}, 200);
    this.autoSaveTimeOut = setInterval(() => {this.checkIfShouldWeSave(); }, 15000);
  }

  componentDidUpdate() {
    if (this.props.needsSave) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.onbeforeunload = null
    if(this.props.needsSave){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              leadData: {...prevState.leadBackup}
          }
        });
      }
    }
    const { KoPilotenEdited } = this.state;

    if (KoPilotenEdited) {this.saveChangeKoPiloten(true);}
    clearInterval(this.autoSaveTimeOut);
    clearTimeout(this.myTimeout);
    clearTimeout(this.myTimeouKoPiloten);
  };

  saveStatistics(type) {
    const { userId, fpName, fpFirstMail } = this.props;
    const { leadData } =  this.state;
    const statsData = {
      type: StatsType.MULTIPLIER_RANGE,
      data: {
        userId: userId,
        fpName: fpName,
        fpFirstMail: fpFirstMail,
        leadId: leadData.leadId,
        action: type,
      }
    }
    saveStats(statsData)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            console.log(res.data.data);
          }
        }else{
          console.log("Unable to save data to Statistics");
        }
      }
    })
    .catch((error) => {
      console.log("Unable to save data to Statistics");
    });
  }

  loadCustomGreetings = () => {
    getFooterData(this.props.userId).then(response => {
      if (response && this._isMounted) {
        this.setState({
          customGreetingsText: response.data.data[0].wysiwyg
        })
      }
    })
  }

  getServiceData(){
    const { leadData } = this.state;
    let serviceType = leadData.serviceTypeDe;
    if (serviceType === null || serviceType === undefined) {
      serviceType = defaultServiceTypeDe;
      leadData.serviceTypeDe = serviceType;
    }
    this.configureTabs(serviceType);
  }

  configureTabs(serviceType) {
    if (this._isMounted) {
      this.setState({
        serviceTypeDe: serviceType,
        showCareBoxTab: serviceType === '717700000',
        showPatientTab: serviceType !== '717700000'
      });
    }
  }

  handleUsesSelectChange = (key, value) =>{
    this.setState(prevState => {
      return {
        ...prevState,
        isUsesApp: value,
      }
    });
    this.basicEdit();
  }

  //KOPILOTEN FUNCTIONS
  saveChangeKoPiloten =(withoutMessage=false) =>{
    const { KoPilotenForm, KoPilotenModificatedCategoryList } = this.state;
    let sendStatistics = true;
    if(KoPilotenModificatedCategoryList.length>0){
      if(this._isMounted){this.setState({KoPilotenSaving: true});}
      KoPilotenModificatedCategoryList.forEach((catIdData, index) => {
        const foundedCat =  KoPilotenForm.getCategoryByKey(catIdData);
        if(foundedCat){
          const dataToSend = new KoPilotenSaveRangeCategory(foundedCat.MultiplierRangeId, foundedCat.CategoryId, foundedCat.ClientId, foundedCat.Note, foundedCat.MultiplierIds);
          saveMultiplierCategoryRange(JSON.parse(JSON.stringify(dataToSend)))
          .then(res => {
            foundedCat.MultiplierRangeId = res;
            if(!withoutMessage){
              if (this._isMounted) {
                this.setState({
                  KoPilotenEdited: false,
                  KoPilotenSaving: false,
                  KoPilotenErrorComponent: {
                    show: true,
                    type: "success",
                    message: "ko-piloten-multiplikator-success-assigned"
                  },
                })
                document.body.classList.remove('overflow-y-scroll');
              }
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({
                    KoPilotenErrorComponent: Alert,
                    KoPilotenSaving: false
                  });
                }
              }, 2500);
            }
            if (sendStatistics) {
              this.saveStatistics(KoPilotenSaveType.SAVEKOPILOTEN);
              sendStatistics = false;
            }
          })
          .catch(error => {
            if(!withoutMessage){
              if (this._isMounted) {
                this.setState({
                  KoPilotenSaving: false,
                  KoPilotenErrorComponent: {
                    show:true,
                    type: "danger",
                    message: "basic_error_message"
                  }
                });
                document.body.classList.remove('overflow-y-scroll');
              }
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({
                    KoPilotenErrorComponent: Alert,
                    KoPilotenSaving: false
                  });
                }
              }, 2500);
            }
          });
        }else{
          if(this._isMounted){this.setState({KoPilotenSaving: false});}
        }
      });
    }else{
      if(!withoutMessage){
        if(this._isMounted){this.setState({KoPilotenSaving: true});}
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              KoPilotenSaving: false,
              KoPilotenEdited: false
            });
          }
        }, 500);
      }
    }
  }

  putNewCategoryKoPiloten(catId, label, multi) {
    const nextState = produce(this.state, (draftState) => {
      const { KoPilotenForm, leadData } = draftState;
      KoPilotenForm.categories.push(
        new KoPilotenCategory(
          null,
          KoPilotenCategoryHelper.getMeNameBasedOnLabel(label),
          catId,
          leadData.leadId,
          null,
          multi.map((element)=>{return element.toUpperCase()}),
          [],
          KoPilotenCategoryHelper.getMeSubcategoriesBasedOnLabel(label),
          [],
          KoPilotenCategoryHelper.isSubCategory(label)
        )
      );
    });
    this.setState(nextState);
  }

  prepareMeBasicKoPilotenFormData() {
    const { multiCategoryEnum } = this.props;
    const { loadingMultipliers } = this.state;
    if(multiCategoryEnum.length > 0 && !loadingMultipliers ) {
      clearTimeout(this.myTimeouKoPiloten);
      let count = 0;
      multiCategoryEnum.forEach((element, index) => {
        getMultiplierCategoryList(element.Value)
        .then((res) => {
          count++;
          this.putNewCategoryKoPiloten(element.Value, element.Label, res.MultiplierIds);
          if(count===multiCategoryEnum.length-1){
            this.myTimeouKoPiloten = setTimeout(() => {this.prepareMultiplierRangeKoPiloten()}, 500);
          }
        })
        .catch(error => {
          count++;
          if(count===multiCategoryEnum.length-1){
            this.myTimeouKoPiloten = setTimeout(() => {this.prepareMultiplierRangeKoPiloten()}, 500);
          }
        });
      });
    } else {
      this.myTimeouKoPiloten = setTimeout(() => {this.prepareMeBasicKoPilotenFormData();}, 100);
    }
  }

  prepareMultiplierRangeKoPiloten() {
    const { KoPilotenForm, leadData } = this.state;
    getMultiplierCategoryRange(leadData.leadId)
      .then((res) => {
        if (res.length > 0) {
          res.forEach((element, index) => {
            let newObj = {
              MultiplierIds: element.MultiplierIds.map((element)=>{return element.toUpperCase()}),
              MultiplierRangeId: element.MultiplierRangeId,
              Note: element.Note,
            };
            KoPilotenForm.setDataToCategory(newObj, element.CategoryId.toUpperCase());
            if (index === res.length - 1) {
              this.finalPreparationKoPiloten();
            }
          });
        } else {
          this.finalPreparationKoPiloten();
        }
      })
      .catch((error) => {
        console.log(error);
        this.finalPreparationKoPiloten();
      });
  }

  finalPreparationKoPiloten(){
    const { KoPilotenForm } = this.state;
    CategoriesKeyList.forEach((element,index) => {
      const category = KoPilotenForm.getCategoryByName(element.key);
      KoPilotenForm.moveCategory(index, category);
    });
    let activeCat = '0';
    KoPilotenForm.categories.forEach((element, index) => {
      if(activeCat==='0'){
        if(element.gotMultipliersToSelect){
          activeCat = element.CategoryId;
        }
      }
      if (element.IsSubCategory === false) {
        if (element.SubCategoriesKeyList.length > 0) {
          element.SubCategoriesKeyList.forEach((transKey) => {
            let subKey = KoPilotenForm.getCategoryIdByName(transKey);
            if (subKey) {
              KoPilotenForm.assingSubCategorie(element.CategoryId, subKey);
            }
          });
        }
      }
      if (index >= KoPilotenForm.categories.length - 1) {
        this.myTimeouKoPiloten = setTimeout(() => {
          this.setState({
            KoPilotenLoadingBasics: false,
            KoPilotenActiveCategoryId: activeCat
          });
        }, 100);
      }
    });
  }

  changeCategoryActiveKoPiloten = (id) => {
    this.setState({KoPilotenActiveCategoryId: id})
  }

  addMultiToCategory=(multiplicatorId, value)=>{
    const { KoPilotenForm, KoPilotenActiveCategoryId, KoPilotenModificatedCategoryList, disabled} = this.state;
    if(!disabled){
      let add="ADD";
      if(!value){add = "REMOVE";}
      KoPilotenForm.addOrRemoveMultipliersFromToCategory(multiplicatorId, KoPilotenActiveCategoryId, add);
      const exists = KoPilotenModificatedCategoryList.indexOf(KoPilotenActiveCategoryId);
      if(exists===-1){
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenModificatedCategoryList: [...prevState.KoPilotenModificatedCategoryList, KoPilotenActiveCategoryId],
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }else{
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }
    }
  }

  changeNoteDataKopiloten=(key, val)=>{
    if(!this.state.disabled){
      const { KoPilotenForm, KoPilotenModificatedCategoryList} = this.state;
      const editedCategory = KoPilotenForm.getCategoryByKey(key);
      if(editedCategory){editedCategory.Note = val;}
      const exists = KoPilotenModificatedCategoryList.indexOf(key);
      if(exists===-1){
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenModificatedCategoryList: [...prevState.KoPilotenModificatedCategoryList, key],
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }else{
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }
    }
  }

  getMeMultiEmailContent = () =>{
    const { leadData } = this.state;
    const { translateKey, fpName, fpFirstMail, fpPhone } = this.props;
    const tdStyles = 'border:0;padding:0;';
    const spanClass = 'font-weight:bold;color:#000000;';
    const breakLineTable = `<tr><td style="border:0;padding: 0px 0 20px 0;"></td></tr>`;
    let genderText = '';
    if(leadData.client.gender === parseInt(Gender.MALE)){
      genderText =  translateKey('contract-text-herr');
    }else{
      if(leadData.client.gender === parseInt(Gender.FEMALE)){
        genderText =  translateKey('form-text-frau');
      }
    }
    const greetings = getGreetings(this.state.customGreetingsText, breakLineTable, tdStyles, fpName, fpPhone, fpFirstMail);
    const htmlMailContent = `
    <table style="width:100%;color:black;border:0;outline:0;">
      <tbody>
        <tr><td style="${tdStyles}">Sehr geehrter <span style="${spanClass}">${genderText} ${leadData.client.lastName},</span></td></tr>
        ${breakLineTable}
        <tr><td style="${tdStyles}">Im Anhang finden Sie die Liste der Ko-Piloten. </td></tr>
        ${breakLineTable}
        <tr><td style="${tdStyles}">Bei Fragen stehe ich Ihnen gerne zur Verfügung. </td></tr>
        ${greetings}
      </tbody>
    </table>
    `;
    return htmlMailContent;
  }

  prepareDataAndSendPDF = () =>{
    const { KoPilotenForm, leadData, loadingMultipliers} = this.state;
    const { multipliersData, translateKey } = this.props;
    if(!loadingMultipliers && multipliersData!==false && multipliersData !== null && multipliersData.length > 0){
      if(leadData.client.email){
        this.setState({loaderModal: true})
        let pdfDataToSend = [];
        const catList = [...KoPilotenForm.categories];
        catList.forEach(cat => {
          if(cat.SubCategoriesList.length===0){
            //main category
            if(cat.gotMultipliers(multipliersData)){
              let multipliersDataToSend = [];
              cat.MultiplierIds.forEach(multiId => {
                let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                if(foundMulti){
                  if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                    let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                    if(exists>-1){
                      multipliersDataToSend.push(foundMulti);
                    }
                  }
                }
              });
              if(multipliersDataToSend.length>0){
                // we got main category and some multipliers with data to send
                pdfDataToSend.push({
                  parentName: translateKey(cat?cat.Name:''),
                  childName: null,
                  categoryId: cat?cat.CategoryId:'',
                  note: cat?cat.Note:'',
                  multipliersData: multipliersDataToSend
                })
              }
            }
          }else{
            //got subcategories
            cat.SubCategoriesList.forEach(subCat => {
              if(subCat.gotMultipliers(multipliersData)){
                let multipliersDataToSend = [];
                subCat.MultiplierIds.forEach(multiId => {
                  let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                  if(foundMulti){
                    if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                      let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                      if(exists>-1){
                        multipliersDataToSend.push(foundMulti);
                      }
                    }
                  }
                });
                if(multipliersDataToSend.length>0){
                  // we got subcategory and some multipliers with data to send
                  pdfDataToSend.push({
                    parentName: translateKey(cat?cat.Name:''),
                    childName: translateKey(subCat?subCat.Name:''),
                    categoryId: subCat?subCat.CategoryId:'',
                    note: subCat?subCat.Note:'',
                    multipliersData: multipliersDataToSend
                  })
                }
              }
            });
          }
        });
        if(pdfDataToSend.length>0){
          //got some checked multipliers so we can generate pdf
          const koPilotenDataToSend = {
            userID: this.props.userId,
            list: pdfDataToSend,
            toEmail: leadData.client.email,
            content: this.getMeMultiEmailContent()
          }
          generatePdfBasedOnMultipliers(koPilotenDataToSend)
          .then(res => {
            if(res){
              if(res.status===200){
                if (this._isMounted) {
                  this.setState({
                    loaderModal: false,
                    infoModalOptions: {
                      show: true,
                      type: "success",
                      message: translateKey('ko-piloten-success-pdf-send')
                    },
                  });
                  this.saveStatistics(KoPilotenSaveType.GENERATE_KOPILOTEN_PDF);
                }
              }else{
                if (this._isMounted) {
                  this.setState({
                    loaderModal: false,
                    infoModalOptions: {
                      show: true,
                      type: "danger",
                      message: translateKey('basic_error_message')
                    }
                  })
                }
              }
            }
          })
          .catch((error) => {
            if (this._isMounted) {
              this.setState({
                loaderModal: false,
                errorComponent: {
                  show: true,
                  type: "danger",
                  message: "basic_error_message"
                },
              })
              document.body.classList.remove('overflow-y-scroll');
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({
                    errorComponent: Alert,
                    KoPilotenSaving: false
                  });
                }
              }, 6000);
            }
          });
        }else{
          if (this._isMounted) {
            this.setState({
              loaderModal: false,
              infoModalOptions: {
                show: true,
                type: "danger",
                message: translateKey('ko-piloten-at-least-one-category')
              }
            })
          }
        }
      }else{
        this.setState({
          loaderModal: false,
          infoModalOptions: {
            show: true,
            type: "danger",
            message: translateKey("form-text-err_not_an_email_address")
          },
        })
      }
    }
  }

  hideInfo = () => {
    this.setState({
      infoModalOptions: {
        show: false,
        type: "danger",
        message: this.props.translateKey("basic_error_message")
      },
    })
  }



  getMeRegions(){
    this.props.getRegions(this.props.appLang).then((data)=>{
      if (this._isMounted) {
        this.setState({regionsFP: data});
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  getInitialMultipliers(){
    const lastUpdate = this.props.multipliersTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getMultipliersList();
    } else {
      if (this._isMounted) {
        const query = new URLSearchParams(this.props.location.search);
        this.setState({
          loadingMultipliers: false,
          activeTab: query.get('type')==="kopiloten"?LeadTabList.KOPILOTEN:this.state.activeTab
        })
      }
    }
  }

  getMultipliersList = () => {
    if (this._isMounted) {this.setState({ loadingMultipliers: true })}
    this.props.pendingMultiplikatorenRequest();
    axiosAuth.get("multipliers/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new MultiplierTable(element);});
        this.props.setMultiplikatorenData(mapedRes);
        if (this._isMounted) {
          const query = new URLSearchParams(this.props.location.search);
          this.setState({
            loadingMultipliers: false,
            activeTab: query.get('type')==="kopiloten"?LeadTabList.KOPILOTEN:this.state.activeTab
          })
        }
      })
      .catch((error) => {
        this.props.clearMultiplikatorenData();
        const query = new URLSearchParams(this.props.location.search);
        this.setState({
          loadingMultipliers: false,
          activeTab: query.get('type')==="kopiloten"?LeadTabList.KOPILOTEN:this.state.activeTab
        })
      });
  }

  getInitIsLeadDescription(){
    const { match: { params } } = this.props;
    let dataToSend = [
      {
          "Id": params.leadId,
          "Entity": Entity.CONTACT,
          "Fields": [
            "is_lead_description",
            "is_degree_relationship_with_patient_carer"
          ]
      }
    ];
    getDynamicFields(dataToSend).then(res => {
      if(res.length>0){
        if (this._isMounted) {
          this.setState({
            dynamics:{
              is_lead_description: res[0].fields["is_lead_description"],
              is_degree_relationship_with_patient_carer: res[0].fields["is_degree_relationship_with_patient_carer"]
            },
            dynamicsTemp:{
              is_lead_description: res[0].fields["is_lead_description"],
              is_degree_relationship_with_patient_carer: res[0].fields["is_degree_relationship_with_patient_carer"]
            }
          })
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  setActiveTab(tabName){
    if (this._isMounted) {
      this.setState({activeTab: tabName})
    }
  }

  checkIfShouldWeSave = () =>{
    const currentTime = new Date().getTime();
    if(this.props.lastActionTime - currentTime <= 30000){
      this.saveChange(true);
    }
  }

  getInitialDataTime(){
    if(this.props.leadsStatus.length>0){
      clearTimeout(this.myTimeout);
      this.getLeadsDataByTimestamp();
    }else{
      this.props.getStatusLead(this.props.appLang);
      this.myTimeout = setTimeout(() => {
        this.getInitialDataTime()
      }, 1000);
    }
  }

  getMeStatusText(status){
    let text = '';
    let statuses = this.props.leadsStatus;
    for(let i=0; i<statuses.length; i++){
      if(statuses[i].Value===status.toString()){
        text = statuses[i].Label;
        break;
      }
    }
    return text;
  }

  getMeTextStatus(text){
    let status = '';
    let statuses = this.props.leadsStatus;
    for(let i=0; i<statuses.length; i++){
      if(statuses[i].Label===text){
        status = statuses[i].Value;
        break;
      }
    }
    return status;
  }

  getLeadsList = () => {
    this.props.pendingRequestLeads();
    getLeadsDataCall(this.props.appLang)
      .then(res => {
        let mapedRes = res.map((element)=>{
          element.typeText = this.getMeStatusText(element.status);
          return new LeadTable(element);
        });
        this.props.setLeadsData(mapedRes);
        this.getDataBasic();
      })
      .catch((error) => {
        this.props.clearDataLeads();
        this.getDataBasic();
      });
  }

  getLeadsDataByTimestamp(){
    const lastUpdate = this.props.leadsTimestamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getLeadsList();
    } else {
      if(this.props.leadsData.length>0){
        this.getDataBasic();
      }else{
        this.getLeadsList();
      }
    }
  }

  ///// Dynamic

  getDynamicMulti(){
    const { match: { params } } = this.props;
    let dataToSend = [
      {
          "Id": params.leadId,
          "Entity": Entity.CONTACT,
          "Fields": ["is_contact_recomendedid", "is_uses_app"]
      }
    ];
    getDynamicFields(dataToSend).then(res => {
      if(res.length>0){
        if(res[0].fields["is_contact_recomendedid"] !==null && res[0].fields["is_contact_recomendedid"] !== undefined ){
          if (this._isMounted) {
            this.setState({
              multiId: res[0].fields["is_contact_recomendedid"]
            })
          }
        }
        if(res[0].fields["is_uses_app"] !==null && res[0].fields["is_uses_app"] !== undefined ){
          if (this._isMounted) {
            this.setState({
              isUsesApp: res[0].fields["is_uses_app"]
            })
          }
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  changeMultiValue = (value) =>{
    if (this._isMounted) {
      this.setState({multiId: value})
    }
    if(value!==0){this.basicEdit();}
  }
  ///// BASIC CONTRACT TAB DATA LOAD

  getBasicContractsData(){
    if (this._isMounted) {this.setState({loadingContracts: true})}
    const { match: { params } } = this.props;
    axiosAuth.get("/contracts/list/"+params.leadId+"/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new ContractTable(element);});
        if (this._isMounted) {
          this.setState({
            contractsData: mapedRes,
            loadingContracts: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ loadingContracts: false })
        }
      });
  }

  ///// BASIC NOTES

  getBasicNotes(){
    if (this._isMounted) {
      this.setState({loadingNotes: true})
    }
    const { match: { params } } = this.props;
    axiosAuth.get("/notes/LEAD/"+params.leadId+"/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        if (this._isMounted) {
          this.setState({
            leadNotesData: res,
            loadingNotes: false
          })
        }
        this.sortDataByColumnActiveNotes();
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ loadingNotes: false })
        }
      });
  }

  preparePagingDataNotes(){
    if(this.state.leadNotesData.length<=25){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            notesPaging: {
              totalItems: this.state.leadNotesData.length,
              itemsPerPage: prevState.notesPaging.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            notesPaging: {
              ...prevState.notesPaging,
              totalItems: this.state.leadNotesData.length,
              pageFrom: 1,
              pageTo: 5,
              currentPage: prevState.notesPaging.currentPage>Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage)? Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage) : prevState.notesPaging.currentPage,
              totalPages: Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage)
            }
          }
        });
      }
    }
  }

  sortTypeNotes(type){
    let sortingObj = [];
    for(let i=0; i<this.state.sortingNotes.length; i++){
      if(this.state.sortingNotes[i].column===type){
        if(this.state.sortingNotes[i].active===true){
          let item = this.state.sortingNotes[i];
          item.type==='ASC'? item.type="DESC" : item.type='ASC';
          sortingObj.push(item);
        }else{
          let item = this.state.sortingNotes[i];
          item.type='ASC';
          item.active = true;
          sortingObj.push(item);
        }
      }else{
        let item = this.state.sortingNotes[i];
        item.type='ASC';
        item.active = false;
        sortingObj.push(item);
      }
    }
    if (this._isMounted) {
      this.setState({sortingNotes: sortingObj});
    }
    this.sortDataByColumnActiveNotes();
  }

  sortDataByColumnActiveNotes(){
    let finalDataToShow = this.state.leadNotesData;
    ReqHelper.sortMeData(finalDataToShow, this.state.sortingNotes);
    if (this._isMounted) {
      this.setState({leadNotesData: finalDataToShow})
      this.preparePagingDataNotes();
    }
  }

  openModalWithNoteData(element){
    if (this._isMounted) {
      this.setState({
          noteData: JSON.parse(JSON.stringify(element)),
          noteTemp: JSON.parse(JSON.stringify(element)),
          showEdit: true,
          type: "EDIT"
      })
    }
  }

  noteChange(text){
    if (this._isMounted) {
      this.setState({
        noteData:{
            ...this.state.noteData,
            Note: text
        }
      });
    }
  }

  cancelEdit = () =>{
    if (this._isMounted) {
      this.setState({
          noteData: this.state.noteTemp,
          showEdit: false
      })
    }
  }

  saveNoteData = ()=>{
      if (this._isMounted) {
        this.setState({loaderModal: true})
      }
      this.props.addNote(this.state.noteData, this.state.leadId, "LEAD")
      .then((res)=>{
          let newObj={
              Id: res,
              Note: this.state.noteData.Note,
              Created: Math.floor(new Date().getTime() / 1000),
              CreatedBy: ReqHelper.prepareMeLoginName(this.props.loginName)
          }
          if (this._isMounted) {
            this.setState(previousState => ({
                ...previousState,
                loaderModal: false,
                showEdit: false,
                leadNotesData: [...previousState.leadNotesData, newObj]
            }));
          }
      })
      .catch((err)=>{
          console.log(err);
          if (this._isMounted) {
            this.setState({
                loaderModal: false,
                saveErrorMessage: this.props.translateKey("fail_to_add_note")
            })
          }
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({saveErrorMessage: ''});
            }
          }, 2000);
      })
  }

  addClick = () =>{
    if (this._isMounted) {
      this.setState({
          noteData: {
              Id: 0,
              Note: ''
          },
          noteTemp:  {
              Id: 0,
              Note: ''
          },
          showEdit: true,
          type: "ADD"
      })
    }
  }

  removeItem(item){
    if (this._isMounted) {
      this.setState({
        confirmDeleteNotes: true,
        noteToDelete: item
      })
    }
  }

  errorModal = () => {
    if (this._isMounted) {
      this.setState({errorModal: !this.state.errorModal});
    }
  }

  getBasicTasksData(){
    if(this.props.tasksStatus === ResponseStatus.READY){
      clearTimeout(this.myTimeout);
      this.getLeadTasksData();
    }else{
      if(this.props.tasksStatus === ResponseStatus.FREE){
        this.props.getGlobalTasksData();
        this.myTimeout = setTimeout(() => {
          this.getBasicTasksData()
        }, 1000);
      }else{
        if(this.props.tasksStatus === ResponseStatus.ERROR){
          clearTimeout(this.myTimeout);
          if (this._isMounted) {
            this.setState({ loadingTasks: false })
          }
        }else{
          this.myTimeout = setTimeout(() => {
            this.getBasicTasksData()
          }, 1000);
        }
      }
    }
  }

  getLeadTasksData() {
    const { match: { params } } = this.props;
    let newList = [];
    for(let i=0;i<this.props.tasksData.length; i++){
      if(this.props.tasksData[i].concerns===params.leadId){
        newList.push(this.props.tasksData[i])
      }
    }
    if (this._isMounted) {
      this.setState({
        leadTaskData: newList,
        loadingTasks: false,
      })
    }
    this.preparePagingDataTasks();
  }

  getDataBasic() {
    const { match: { params } } = this.props;
    let found = false;
    for(let i=0;i<this.props.leadsData.length; i++){
      if(this.props.leadsData[i].leadId===params.leadId){
        found=true;
        let name='---';
        if(this.props.leadsData[i].client.lastName!==null){
          name = this.props.leadsData[i].client.lastName;
          if(this.props.leadsData[i].client.firstName!==null){
            name += ' '+this.props.leadsData[i].client.firstName;
          }
        }else{
          if(this.props.leadsData[i].client.firstName!==null){
            name = this.props.leadsData[i].client.firstName;
          }
        }
        if (this._isMounted) {
          this.setState({
            leadData: JSON.parse(JSON.stringify(this.props.leadsData[i])),
            leadBackup: JSON.parse(JSON.stringify(this.props.leadsData[i])),
            loadingLeads: false,
            leadId: params.leadId,
            breadCrumbs:[
              {
                link: "leads",
                key: "leads",
                name: null
              },
              {
                link: "",
                key: "",
                name: name
              }
            ],
            canAddContract: this.props.leadsData[i].status.toString()!==statisContractOffertStatus
          })
        }
        this.loadCustomGreetings();
        this.getDynamicMulti();
        this.getBasicNotes();
        this.getBasicTasksData();
        this.getBasicContractsData();
        this.getServiceData();
        break;
      }
    }
    if(!found){
      if (this._isMounted) {
        this.setState({
          leadData: false,
          loadingLeads: false,
          loadingPatients: false,
          loadingNotes: false,
          loadingTasks: false,
        })
      }
    }
  }

  cancelChange=()=>{
    this.props.doesNotNeedsSave();
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            leadData: JSON.parse(JSON.stringify(prevState.leadBackup)),
            dynamics: JSON.parse(JSON.stringify(prevState.dynamicsTemp)),
            showValidation: false,
        }
      });
    }
  }

  back=()=>{
    const { history } = this.props;
    history.push(`${baseData.subFolders}leads`);
  }

  tryToSave(leadId, shouldLogout=false){
    const {multiId, dynamics,dynamicsTemp, leadData} = this.state
    let fields = {};
    if(leadData.source!==null && leadData.source === baseData.multiToLeadSelection){
      if(multiId!==0){
        fields.is_contact_recomendedid = multiId;
      }else{
        if (this._isMounted) {
          this.setState({multiId:0})
        }
        fields.is_contact_recomendedid = 0;
      }
    }else{
      if (this._isMounted) {
        this.setState({multiId:0})
      }
      fields.is_contact_recomendedid = 0;
    }
    if(dynamicsTemp.is_degree_relationship_with_patient_carer !== dynamics.is_degree_relationship_with_patient_carer){
      fields.is_degree_relationship_with_patient_carer = dynamics.is_degree_relationship_with_patient_carer;
    }
    if (this.props.appLang === LanguageSelect.DE) {
      fields.is_service_type_de = this.state.serviceTypeDe;
      fields.is_lead_status_care_box = leadData.statusCareBox;
      fields.is_reason_for_rejection_care_box = leadData.careBoxRejectionReason;
    }
    if (this.state.isUsesApp !== null) {
      fields.is_uses_app = this.state.isUsesApp;
    }
    if(fields !== {}){
      let dataToSend = [
        {
            "Id": leadId,
            "Entity": Entity.CONTACT,
            "Fields": fields
        }
      ];
      saveDynamicFields(dataToSend).then(response => {
        this.props.doesNotNeedsSave();
        if(shouldLogout){
          this.props.history.push(`${baseData.subFolders}logout`);
        }else{
          this.saveMessage(leadId, shouldLogout);
        }
      })
      .catch((err) => {
        this.saveMessage(leadId, shouldLogout);
      });
    }else{
      this.saveMessage(leadId, shouldLogout);
    }
  }

  saveMessage(leadId, shouldLogout=false){
    if(shouldLogout){
      this.props.history.push(`${baseData.subFolders}logout`);
    }else{
      if (this._isMounted) {
        this.setState({
          savingDataLead: false,
          errorComponent: {
            show: true,
            type: "success",
            message: this.props.translateKey("lead_successfully_saved")
          },
          showValidation:false
        })
      }
      clearTimeout(this.myTimeout);
      this.myTimeout = setTimeout(() => {this.hideAlertModalComponent();}, 3000);
      this.props.updateLeadDataFun(leadId,this.state.leadData);
    }
  }

  hideAlertModalComponent(){
    if (this._isMounted) {
      this.setState({errorComponent: {show: false}})
    }
    document.body.classList.remove('modal-open');
  }

  saveChange=(shouldLogout=false)=>{
    this.props.tookAction();
    if(this.props.needsSave){
      if(this.checkRequiredFields()){
        if (this._isMounted) {
          this.setState({savingDataLead: true})
        }
        this.props.saveLeadData(this.state.leadData, this.props.appLang)
        .then((res)=>{
          this.props.doesNotNeedsSave();
          this.tryToSave(res, shouldLogout)
        })
        .catch((err)=>{
          if(shouldLogout){
            this.props.history.push(`${baseData.subFolders}logout`);
          }else{
            if (this._isMounted) {
              if (err.code === 802) {
                this.setState({
                  savingDataLead: false,
                  showConfirmationModal: true,
                  modalMessage: 'modal_phone_call_missing',
                  noteModalType: 'Call',
                });
              } else if (err.code === 803) {
                this.setState({
                  savingDataLead: false,
                  showConfirmationModal: true,
                  modalMessage: 'modal_metting_missing',
                  noteModalType: 'Event',
                });
              } else {
                this.setState(prevState => {
                  return {
                      ...prevState,
                      savingDataLead: false,
                      errorComponent: {
                        show: true,
                        type: "danger",
                        message: this.props.translateKey(err.message)
                      }
                  }
                });
              }
            }
            clearTimeout(this.myTimeout);
            this.myTimeout = setTimeout(() => {this.hideAlertModalComponent();}, 6000);
          }
        })
      }else{
        if(shouldLogout){
          this.props.history.push(`${baseData.subFolders}logout`);
        }else{
          if (this._isMounted) {
            this.setState(prevState => {
              return {
                ...prevState,
                showValidation: true,
                errorComponent: {
                  show: true,
                  type: "danger",
                  message: this.props.translateKey("some-mandatory-data-are-missing")
                }
              }
            });
          }
          clearTimeout(this.myTimeout);
          this.myTimeout = setTimeout(() => {this.hideAlertModalComponent();}, 3000);
        }
      }
    }
  }

  checkRequiredFields(){
    const { leadData, multiId, showCareBox, serviceTypeDe } = this.state;
    let allRequiredFilled = true;
    if (serviceTypeDe !== '717700000') {
      if (leadData.status === "" || leadData.status === 0 || leadData.status === null) {
        allRequiredFilled = false;
      }
    }
    if(!ReqHelper.checkNotNullAndNotEmptyString(leadData.client.firstName) || !ReqHelper.checkNotNullAndNotEmptyString(leadData.client.lastName)){
      allRequiredFilled = false;
    }
    if(!ReqHelper.checkNotNullAndNotEmptyString(leadData.patientObj.firstName) || !ReqHelper.checkNotNullAndNotEmptyString(leadData.patientObj.lastName)){
      allRequiredFilled = false;
    }
    if(!ReqHelper.checkNotNullAndNotEmptyString(leadData.patientObj.address.postalCode)){
      allRequiredFilled = false;
    }
    if (`${leadData.status}` === contractCancellationStatus && !leadData.reasonForRejection) {
        allRequiredFilled = false;
    }
    if ((`${multiId}` === "" || multiId === 0 || multiId===null) && (leadData.source === 'F2557264-4978-E811-80D7-00155D00F809' || leadData.source === '2669ACCE-A798-E911-80D1-00155D140163' )) {
      allRequiredFilled = false;
    }
    return allRequiredFilled;
  }

  basicEdit = () =>{this.props.needsSaveData();}

  preparePagingDataTasks(){
    if(this.state.leadTaskData.length<=25){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            tasksPaging: {
              totalItems: this.state.leadTaskData.length,
              itemsPerPage: prevState.tasksPaging.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            tasksPaging: {
              ...prevState.tasksPaging,
              totalItems: this.state.leadTaskData.length,
              pageFrom: 1,
              pageTo: 5,
              currentPage: prevState.tasksPaging.currentPage>Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage)? Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage) : prevState.tasksPaging.currentPage,
              totalPages: Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage)
            }
          }
        });
      }
    }
  }

  sortTypeTasks(type){
    this.state.sortingTasks.changeColumnByColumn(type);
    this.sortDataByColumnActiveTasks();
  }

  sortDataByColumnActiveTasks(){
    let finalData = this.state.leadTaskData;
    ReqHelper.sortMeData(finalData, this.state.sortingTasks.sortingColumns);
    this.props.setGlobalTasksData(finalData);
    if (this._isMounted) {
      this.setState({leadTaskData: finalData})
    }
  }

  _prepareFilterObjTasks() {
    let filterData = [];
    let Options = [];
    filterData.push({
      filterName: "status",
      filterOptions: [
        {
          column: this.props.translateKey('open'),
          active: false,
          type: "text",

        },
        {
          column: this.props.translateKey('closed'),
          active: false,
          type: "text",
        }
      ]
    });
    for (let i = 0; i < this.props.tasksPriority.length; i++) {
      let filtering = {
        column: this.props.taskPriority[i].Label,
        active: false,
        type: "text"
      }
      Options.push(filtering);
    }
    filterData.push({
      filterName: "priority",
      filterOptions: Options
    });
    filterData.push({
      filterName: "type",
      filterOptions: [
        {
          column: this.props.translateKey('task_call'),
          active: false,
          type: "text",

        },
        {
          column: this.props.translateKey('task_event'),
          active: false,
          type: "text",
        },
        {
          column: this.props.translateKey('task_task'),
          active: false,
          type: "text",
        }
      ]
    });
    filterData.push({
      filterName: "new_task",
      filterOptions: [
        {
          column: this.props.translateKey('task_youger7'),
          active: false,
          type: "text",

        },
        {
          column: this.props.translateKey('task_youger14'),
          active: false,
          type: "text",
        },
        {
          column: this.props.translateKey('task_youger21'),
          active: false,
          type: "text",
        }
      ]
    });
    return filterData;
  }

  navigateToDetailsTasks(element){
    this.props.history.push(`${baseData.subFolders}taskDetails/${element.id}`);
  }

  removeNote = () =>{
    if (this._isMounted) {
      this.setState({loaderModal: true})
    }
    this.props.removeNote(this.state.noteToDelete.Id)
    .then((res)=>{
        if (this._isMounted) {
          this.setState({
              confirmDeleteNotes: false,
              loaderModal: false,
              leadNotesData: this.state.leadNotesData.filter(value => value.Id !== this.state.noteToDelete.Id)
          })
        }
    })
    .catch((err)=>{
      if (this._isMounted) {
        this.setState({
            confirmDeleteNotes: true,
            loaderModal: false,
            errorModal: true,
            errorComponent: {
                ...this.state.errorComponent,
                message: this.props.translateKey("fail_to_remove_note")
            }
        })
      }
    })
  }

  rejectMeNote = () =>{
    if (this._isMounted) {
      this.setState({
        confirmDeleteNotes: false,
        loaderModal: false
      })
    }
  }

  getMeFirstPatientID = () =>{
    const { patientLeadData, match: { params } } = this.props;
    let pushedElements = [];
    patientLeadData.filter(element => {
        if(element.leadId === params.leadId){
            if(pushedElements.indexOf(element.id) > -1){
                return false;
            }else{
                pushedElements.push(element.id);
                return element
            }
        }else{
          return false;
        }
    })
    if(pushedElements.length>0){
      return pushedElements[0];
    }else{
      return 0;
    }
  }

  openFullscreen() {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  showMeQuatation =() => {
    if(this.state.canAddContract===true && this.props.appLang === LanguageSelect.DE){
      const { match: { params } } = this.props;
      const patientId = this.getMeFirstPatientID();
      if (!document.fullscreenElement) {
        this.openFullscreen();
      }
      this.props.history.push(`${baseData.subFolders}bb-form-lead/${params.leadId}/${patientId}/0/${FormTypeToShow.ENQUIRY}`);
    }else{
      alert(this.props.translateKey("form-text-processing-offer"));
    }
  }

  showMeContract =() => {
    if(this.state.canAddContract===true && this.props.appLang === LanguageSelect.DE){
      const { match: { params } } = this.props;
      const patientId = this.getMeFirstPatientID();
      this.props.history.push(`${baseData.subFolders}bb-form-lead/${params.leadId}/${patientId}/0/${FormTypeToShow.CONTRACT}`);
    }else{
      alert(this.props.translateKey("form-text-processing-offer"));
    }
  }

  addClickButton=()=>{
    const { match: { params }, history, appLang } = this.props;
    const { activeTab, loadingNotes, leadData } = this.state;
    switch(activeTab){
      case LeadTabList.LEAD:      if(appLang === LanguageSelect.DE){
                                    history.push(`${baseData.subFolders}leadArrangeMeeting/${params.leadId}/${RecordType.LEAD}`);
                                  }
                                  break;
      case LeadTabList.PATIENTS:  if(appLang === LanguageSelect.EN){
                                    history.push(`${baseData.subFolders}leadPatient/${params.leadId}/0`);
                                  }else{
                                    history.push(`${baseData.subFolders}bb-form-lead/${params.leadId}/0`);
                                  }
                                  break;
      case LeadTabList.NOTES:     if(!loadingNotes){
                                    if(leadData!==false){
                                      this.addClick();
                                    }
                                  }
                                  break;
      case LeadTabList.TASKS:     history.push(`${baseData.subFolders}newtask/${params.leadId}/${RecordType.LEAD}`);
                                  break;
      case LeadTabList.KOPILOTEN: this.prepareDataAndSendPDF()
                                  break;
      case LeadTabList.CARE_BOX:  if(appLang === LanguageSelect.EN){
                                    //TO DO new page for care box form
                                    history.push(`${baseData.subFolders}leadPatient/${params.leadId}/0`);
                                  }else{
                                    //TO DO new page for care box form
                                    history.push(`${baseData.subFolders}bb-form-lead/${params.leadId}/0`);
                                  }
                                  break;
      default:
    }
  }

  prepareMeCaAdd(){
    const { appLang, } = this.props;
    const {leadData, canAddContract, loadingMultipliers, activeTab } = this.state;
    let showAddPateint = this.pateintCount();
    let options = [];
    switch(activeTab){
      case LeadTabList.LEAD:      if( appLang === LanguageSelect.DE && leadData!==false){
                                    options = [TableEnums.ADDMEETING]
                                  };
                                  break;
      case LeadTabList.PATIENTS:  if (showAddPateint) {
                                    if( appLang === LanguageSelect.DE && leadData!==false){
                                      if(canAddContract===true){
                                        options = [TableEnums.ADD];
                                      }
                                    }else{
                                      options = [TableEnums.ADD];
                                    }
                                  }
                                  break;
      case LeadTabList.CONTRACTS: if( appLang === LanguageSelect.DE && leadData!==false){
                                    options = [TableEnums.CREATECONTRACT, TableEnums.CREATEQUATATION];
                                  }
                                  break;
      case LeadTabList.NOTES:     if(leadData!==false){
                                    options = [TableEnums.ADD]
                                  };
                                  break;
      case LeadTabList.TASKS:     if(leadData!==false){
                                    options = [TableEnums.ADD]
                                  };
                                  break;
      case LeadTabList.KOPILOTEN: if(loadingMultipliers===false){
                                    options = [TableEnums.SENDKOPILOTEN]
                                  };
                                  break;
     case LeadTabList.CARE_BOX:  if (showAddPateint) {
                                    if( appLang === LanguageSelect.DE && leadData!==false){
                                      if(canAddContract===true){
                                        //TO DO change button to create new carebox pateient
                                        options = [TableEnums.ADD];
                                      }
                                    }else{
                                      options = [TableEnums.ADD];
                                    }
                                  }
                                  break;
      default:
    }
    return options;
  }

  pateintCount = () => {
    const { patientLeadData, match: { params } } = this.props;
    let pushedElements = [];
    let finalDataToShow = patientLeadData.filter(element => {
        if(element.leadId === params.leadId){
            if(pushedElements.indexOf(element.id) > -1){
                return false;
            }else{
                pushedElements.push(element.id);
                return element
            }
        }else{
          return false;
        }
    });

    if (finalDataToShow.length > 1) {
      return false;
    } else {
      return true;
    }
  }

  getMeKoPilotenSection = () =>{
    const { multipliersData, translateKey } = this.props;
    const {
      loadingLeads,
      loadingMultipliers,
      KoPilotenForm,
      KoPilotenRerender,
      KoPilotenErrorComponent,
      KoPilotenEdited,
      KoPilotenActiveCategoryId,
      KoPilotenSaving,
      KoPilotenLoadingBasics
    } = this.state;
    let content;
    if (loadingMultipliers) {
      content = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(multipliersData===false){
        content = <EmptyTableIcon RefreshMe={() => this.props.getInitialMultipliers()} text={translateKey("no_data")} />
      }else{
        if (multipliersData === null) {
          content = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.getInitialMultipliers()} text={translateKey("no_data_to_show")} /></div>
        } else {
          if (multipliersData.length === 0) {
            content = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.getInitialMultipliers()} text={translateKey("no_data_to_show")} /></div>
          }else{
            content = (
              <KoPilotenCategoryComponent
                saving={KoPilotenSaving}
                activeCategoryId= {KoPilotenActiveCategoryId}
                edited = {KoPilotenEdited}
                errorComponent={KoPilotenErrorComponent}
                form={KoPilotenForm}
                rerender={KoPilotenRerender}
                loadingBasics = {KoPilotenLoadingBasics}
                loadingMultipliers={loadingMultipliers || loadingLeads}
                refreshData={() => this.getInitialMultipliers()}
                disabled={false}
                saveChange={this.saveChangeKoPiloten}
                changeCategoryActiveKoPiloten={this.changeCategoryActiveKoPiloten}
                addMultiToCategory={this.addMultiToCategory}
                changeNoteData={this.changeNoteDataKopiloten}
              />
            );
          }
        }
      }
    }
    return content;
  }

  handleServiceTypeDeChange = (value) => {
    this.configureTabs(value);
  }

  closeNoteConfirmationModal = () => {
    this.setState({showConfirmationModal: false});
  }

  openNoteModal = () => {
    this.setState({
      showConfirmationModal: false,
      showNoteModal: true,
    });
  }

  closeTaskModal = () => {
    this.setState({showNoteModal: false});
  }

  render() {
    const {translateKey, needsSave, appLang, match: { params }} = this.props;
    const {sortingNotes, sortingTasks, dynamics, activeTab, showValidation, multiId, canAddContract, leadData } = this.state;
    let leadsEmptyHandler, patientsEmptyHandler, notesEmptyHandler, tasksEmptyHandler, careBoxEmptyHandler;
    if (this.state.loadingLeads) {
      leadsEmptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(leadData===false){
        leadsEmptyHandler = <EmptyTableIcon RefreshMe={() => this.getInitialDataTime()} text={translateKey("lead_does_not_exist")} />
      }else{
        let FirstRow =(
          <LeadDetailsBasicComponent
            dynamics={dynamics}
            multiId={multiId}
            editedMulti={this.changeMultiValue}
            disabled={!canAddContract || leadData.status == '717700021'}
            disabledRejection={!canAddContract}
            editedData={this.basicEdit}
            showValidation={showValidation}
            edited={needsSave}
            key="leadDetailsComponent02"
            element={leadData}
            serviceTypeDe={this.state.serviceTypeDe}
            isCareBoxEnabled={this.state.showCareBoxTab}
            onServiceTypeDeChange={this.handleServiceTypeDeChange}
            handleUsesSelectChange={this.handleUsesSelectChange}
            isUsesApp={this.state.isUsesApp}
          />
        )
        let ClientRow =(
          <LeadClientComponent 
            dynamics={dynamics} 
            disabled={!canAddContract || leadData.status == '717700021'} 
            editedData={this.basicEdit} 
            showValidation={showValidation} 
            edited={needsSave} 
            key="leadClientComponent02" 
            element={leadData}
          />
        )
        let PatientRow =(
          <LeadPatientComponent 
            regionsFP={this.state.regionsFP}
            disabled={!canAddContract || leadData.status == '717700021'}
            editedData={this.basicEdit}
            showValidation={showValidation}
            edited={needsSave}
            key="leadPatientComponent02"
            element={leadData}
          />
        )
        let Consents =(
          <ConsentListComponent isEditable={false}  key="consents-lead-03" id={leadData.leadId}/>
        )
        let CancelSave = '';
        if(canAddContract){
          CancelSave = (
            <LeadCancelSaveComponent saveChange={this.saveChange} cancelChange={this.cancelChange} back={this.back} key="leadCancelSaveComponent02" edited={needsSave} savingData={this.state.savingDataLead}/>
          );
        }
        const { errorComponent } = this.state;
        let SaveAlert = (
          <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message}/>
        )
        leadsEmptyHandler = [
          FirstRow,
          ClientRow,
          PatientRow,
          Consents,
          SaveAlert,
          CancelSave
        ]
      }
    }

    patientsEmptyHandler = (
      <LeadDetailsPatientsDataComponent mainLoader={this.state.loadingLeads} gotLead={leadData!==false} GetLeadData={() => this.getInitialDataTime()} RefreshMe={() => this.getBasicPatientLead()} id={this.state.leadId}/>
    );

    careBoxEmptyHandler = (
      //TO DO new page for care box patients
      <CareBoxDataComponent mainLoader={this.state.loadingLeads} gotLead={leadData!==false} GetLeadData={() => this.getInitialDataTime()} RefreshMe={() => this.getBasicPatientLead()} id={this.state.leadId}/>
    );

    //// NOTES DATA
    let notesContent,emptyNoteData;
    if (this.state.loadingNotes) {
      emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(leadData===false){
        emptyNoteData = <EmptyTableIcon RefreshMe={() => this.getInitialDataTime()} text={translateKey("lead_does_not_exist")} />
      }else{
        if (this.state.leadNotesData === null) {
          emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicNotes()} text={translateKey("no_data_to_show")} /></div>
        } else {
          if (this.state.leadNotesData.length === 0) {
            emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicNotes()} text={translateKey("no_data_to_show")} /></div>
          }else{
            let finalData = this.state.leadNotesData.slice((this.state.notesPaging.currentPage-1)*this.state.notesPaging.itemsPerPage, this.state.notesPaging.currentPage*this.state.notesPaging.itemsPerPage);
            if(finalData.length>0){
              notesContent = finalData.map(element => (
                <tr className="table-row-wrapper cursor-pointer" key={element.Id}>
                  <td className={"table-cell-wrapper " + (element.Note !== null ? "got-data" : 'no-data')} onClick={()=>this.openModalWithNoteData(element)}>{element.Note}</td>
                  <td className={"table-cell-wrapper " + (element.CreatedBy !== null ? "got-data" : 'no-data')} onClick={()=>this.openModalWithNoteData(element)}>{element.CreatedBy}</td>
                  <td className={"table-cell-wrapper " + (element.Created !== null ? "got-data" : 'no-data')} onClick={()=>this.openModalWithNoteData(element)}><TableDateFormat timestamp={element.Created}/></td>
                  <td className="table-cell-wrapper remove-icon-item-table" onClick={()=> this.removeItem(element)}><i className="fas fa-trash-alt fa-2x empty-table-icon cursor-pointer remove-icon-item"></i></td>
                </tr>
              ))
            }else{
              emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicNotes()} text={translateKey("no_data_to_show")} /></div>
            }
          }
        }
      }
    }
    notesEmptyHandler = (
      <div className="listData">
        <ConfirmModal rejectMe={this.rejectMeNote} acceptMe={this.removeNote} show={this.state.confirmDeleteNotes} titleMessage="" mainMessage={translateKey("delete")+"?"} messageYes={translateKey("yes")} messageNo={translateKey("no")}/>
        <table className="table">
          <thead>
            <tr>
              <TableColumHead sorting={sortingNotes} name={translateKey("notes")} onSortType={(data) => this.sortTypeNotes(data)} type="Note" />
              <TableColumHead sorting={sortingNotes} name={translateKey("author")} onSortType={(data) => this.sortTypeNotes(data)} type="CreatedBy" />
              <TableColumHead sorting={sortingNotes} name={translateKey("created")} onSortType={(data) => this.sortTypeNotes(data)} type="Created" />
              <th scope="col" className="table-add-data no_editable"></th>
            </tr>
          </thead>
          <tbody>{notesContent}</tbody>
        </table>
        {emptyNoteData}
        <TablePagination recalculateAll={()=>this.preparePagingDataNotes()} simpleDataUpdate={()=>this.preparePagingDataNotes()} loading={this.state.loadingNotes} paginationObj={this.state.notesPaging} totalItems={this.state.leadNotesData.length}/>
      </div>
    )


    /* hiiden due to FP-343
      let ContractsData;
      if (this.state.loadingCarers) {
        ContractsData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
      }else{
        const { match: { params } } = this.props;
        ContractsData = <LeadContractData RefreshMe={() => this.getBasicContractsData()} id={this.state.id} leadId={params.leadId} contractsData={this.state.contractsData}/>
      }
    */

    let taskContent, emptyTaskData;
    if(this.state.loadingTasks) {
      emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(leadData===false){
        emptyTaskData = <EmptyTableIcon RefreshMe={() => this.getInitialDataTime()} text={translateKey("lead_does_not_exist")} />
      }else{
        if (this.state.leadTaskData === null) {
          emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicTasksData()} text={translateKey("no_data_to_show")} /></div>
        } else {
          if (this.state.leadTaskData.length === 0) {
            emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicTasksData()} text={translateKey("no_data_to_show")} /></div>
          }else{
            let finalData = this.state.leadTaskData.slice((this.state.tasksPaging.currentPage-1)*this.state.tasksPaging.itemsPerPage, this.state.tasksPaging.currentPage*this.state.tasksPaging.itemsPerPage);
            if(finalData.length>0){
              taskContent = finalData.map(element => (
                <tr className={"table-row-wrapper cursor-pointer "+(element.ViewedInApplication===false?"not-readed-record":"")} key={Math.random().toString(36).substr(2, 5)} onClick={()=>{this.navigateToDetailsTasks(element)}}>
                  {
                    sortingTasks.isColumnVisibleByKey('tasks_status', appLang) &&
                      <td className="table-cell-wrapper"><TaskStatusHandler element={element}/></td>
                  }
                  {
                    sortingTasks.isColumnVisibleByKey('tasks_title', appLang) &&
                      <td className="table-cell-wrapper">{element.title}</td>
                  }
                  {
                    sortingTasks.isColumnVisibleByKey('tasks_status', appLang) &&
                      <td className="table-cell-wrapper"><TaskStatusLang element={element.status}/></td>
                  }
                  {
                    sortingTasks.isColumnVisibleByKey('tasks_concern', appLang) &&
                      <td className="table-cell-wrapper">{element.concernName}</td>
                  }
                  {
                    sortingTasks.isColumnVisibleByKey('tasks_priority', appLang) &&
                      <td className="table-cell-wrapper"><TaskPriorityConverter priority={element.priority}/></td>
                  }
                  {
                    sortingTasks.isColumnVisibleByKey('tasks_createdDate', appLang) &&
                      <td className="table-cell-wrapper"><TableDateFormat timestamp={element.createdDate}/></td>
                  }
                  {
                    sortingTasks.isColumnVisibleByKey('tasks_dueDate', appLang) &&
                      <td className="table-cell-wrapper"><TableDateFormat timestamp={element.dueDay}/></td>
                  }
                </tr>
              ))
            }else{
              emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicTasksData()} text={translateKey("no_data_to_show")} /></div>
            }
          }
        }
      }
    }
    tasksEmptyHandler = (
      <div className="listData">
        <table className="table">
          <thead>
            <tr>
              {
                  sortingTasks.sortingColumns.map(column => {
                    if(sortingTasks.isColumnVisibleByKey(column.keyName, appLang)){
                      return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sortingTasks.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.sortTypeTasks(data)} type={column.column} />
                    }else{
                      return null
                    }
                  }).filter(element => element !==null )
              }
            </tr>
          </thead>
          <tbody>{taskContent}</tbody>
        </table>
        {emptyTaskData}
        <TablePagination recalculateAll={()=>this.preparePagingDataTasks()} simpleDataUpdate={()=>this.preparePagingDataTasks()} loading={this.state.loadingTasks} paginationObj={this.state.tasksPaging} totalItems={this.state.leadTaskData.length}/>
      </div>
    )

    let alertModal = (
      <MDBModal isOpen={this.state.errorModal} toggle={this.errorModal} size="lg">
          <MDBModalBody>
              <div className="alert alert-danger centeredAlertNoLimit" role="alert">
                  {this.state.errorComponent.message}
              </div>
          </MDBModalBody>
          <MDBModalFooter>
              <button type="button" className="btn btn-primary" onClick={this.errorModal}>{translateKey('ok')}</button>
          </MDBModalFooter>
      </MDBModal>
    )
    let noteEditModal = (
        <MDBModal isOpen={this.state.showEdit} toggle={this.editToggle} size="lg">
            <MDBModalHeader toggle={this.editToggle}>{translateKey('note')}</MDBModalHeader>
                <MDBModalBody>
                    {this.state.type!=="EDIT"?
                        <div>
                            <textarea type="text" className="form-control" rows="3" onChange={e => this.noteChange(e.target.value)} value={this.state.noteData.Note}/>
                            {this.state.saveErrorMessage.length>0 &&
                                <div className="alert alert-danger centeredAlertNoLimit" role="alert">
                                    {this.state.saveErrorMessage}
                                </div>
                            }
                        </div>
                        :
                        <span>{this.state.noteData.Note}</span>
                    }
                </MDBModalBody>
                {this.state.type!=="EDIT"?
                    <MDBModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={this.cancelEdit}>{translateKey('cancel')}</button>
                        <button type="button" className="btn btn-primary" onClick={()=>this.saveNoteData()}>{translateKey('save')}</button>
                    </MDBModalFooter>
                    :
                    <MDBModalFooter>
                      <button type="button" className="btn btn-primary" onClick={this.cancelEdit}>{translateKey('Ok')}</button>
                    </MDBModalFooter>
                }
        </MDBModal>
    )

    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <ConfirmModal 
          acceptMe={this.closeNoteConfirmationModal} 
          rejectMe={this.openNoteModal} 
          show={this.state.showConfirmationModal} 
          titleMessage="" 
          mainMessage={translateKey(this.state.modalMessage)} 
          messageYes={translateKey("no")} 
          messageNo={translateKey("yes")}
        />
        <NewNoteModal
          show={this.state.showNoteModal}
          type={this.state.noteModalType}
          onClose={this.closeTaskModal}
          translateKey={translateKey}
          leadId={params.leadId}
        />
        <TableProperties showMeQuatation={this.showMeQuatation} showMeContract={this.showMeContract} addClick={this.addClickButton} options={this.prepareMeCaAdd()} filterData={[]}/>
        <LoaderModal show={this.state.loaderModal}/>
        <InfoModal okMe={this.hideInfo} show={this.state.infoModalOptions.show} titleMessage="" mainMessage={this.state.infoModalOptions.message} okMessage={translateKey("Ok")} />
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper transparentClass">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className={`nav-item nav-link  nav-link-invoices ${activeTab===LeadTabList.LEAD?'active':''}`} onClick={()=>{this.setActiveTab(LeadTabList.LEAD)}} id="nav-details-tab" data-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true">
                  {translateKey("lead")}
                </a>
                {this.state.showPatientTab &&
                    <a className={`nav-item nav-link  nav-link-invoices ${activeTab === LeadTabList.PATIENTS ? 'active' : ''}`}
                       onClick={() => {
                         this.setActiveTab(LeadTabList.PATIENTS)
                       }} id="nav-patient-tab" data-toggle="tab" href="#nav-patient" role="tab"
                       aria-controls="nav-patient" aria-selected="false">
                      {translateKey("patients")}
                    </a>
                }
                {
                /* hiiden due to FP-343
                  <a className={`nav-item nav-link  nav-link-invoices ${activeTab===LeadTabList.CONTRACTS?'active':''}`} onClick={()=>{this.setActiveTab(LeadTabList.CONTRACTS)}} id="nav-contracts-tab" data-toggle="tab" href="#nav-contracts" role="tab" aria-controls="nav-contracts" aria-selected="false">
                    {translateKey("offers")}
                  </a>
                */
                }
                <a className={`nav-item nav-link  nav-link-invoices ${activeTab===LeadTabList.NOTES?'active':''}`} onClick={()=>{this.setActiveTab(LeadTabList.NOTES)}} id="nav-notes-tab" data-toggle="tab" href="#nav-notes" role="tab" aria-controls="nav-notes" aria-selected="false">
                  {translateKey("notes")}
                </a>
                <a className={`nav-item nav-link  nav-link-invoices ${activeTab===LeadTabList.TASKS?'active':''}`} onClick={()=>{this.setActiveTab(LeadTabList.TASKS)}} id="nav-tasks-tab" data-toggle="tab" href="#nav-tasks" role="tab" aria-controls="nav-tasks" aria-selected="false">
                  {translateKey("tasks")}
                </a>
                <a className={`nav-item nav-link  nav-link-invoices ${activeTab===LeadTabList.KOPILOTEN?'active':''}`} onClick={()=>{this.setActiveTab(LeadTabList.KOPILOTEN)}} id="nav-kopiloten-tab" data-toggle="tab" href="#nav-kopiloten" role="tab" aria-controls="nav-kopiloten" aria-selected="false">
                  {translateKey("ko_piloten_cateogry")}
                </a>
                {this.state.showCareBoxTab &&
                    <a className={`nav-item nav-link  nav-link-invoices ${activeTab === LeadTabList.CARE_BOX ? 'active' : ''}`}
                       onClick={() => {
                         this.setActiveTab(LeadTabList.CARE_BOX)
                       }} id="nav-care-box-tab" data-toggle="tab" href="#nav-care-box" role="tab"
                       aria-controls="nav-care-box" aria-selected="false">
                      {translateKey("section-care-box")}
                    </a>
                }
              </div>
            </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <div className={`tab-pane fade ${activeTab===LeadTabList.LEAD?'show active':''}`} id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                <div className="leadSection">
                  {leadsEmptyHandler}
                </div>
              </div>
              <div className={`tab-pane fade ${activeTab===LeadTabList.PATIENTS?'show active':''}`} id="nav-patient" role="tabpanel" aria-labelledby="nav-patient-tab">
                <div className="leadSection">
                  {patientsEmptyHandler}
                </div>
              </div>

              {
                /* hiiden due to FP-343
                  <div className={`tab-pane fade ${activeTab===LeadTabList.CONTRACTS?'show active':''}`} id="nav-contracts" role="tabpanel" aria-labelledby="nav-contracts-tab">
                    <div className="leadSection">
                      {ContractsData}
                    </div>
                  </div>
                */
              }
              <div className={`tab-pane fade ${activeTab===LeadTabList.NOTES?'show active':''}`} id="nav-notes" role="tabpanel" aria-labelledby="nav-notes-tab">
                <div className="leadSection">
                  {noteEditModal}
                  {alertModal}
                  {notesEmptyHandler}
                </div>
              </div>
              <div className={`tab-pane fade ${activeTab===LeadTabList.TASKS?'show active':''}`} id="nav-tasks" role="tabpanel" aria-labelledby="nav-tasks-tab">
                <div className="leadSection">
                  {tasksEmptyHandler}
                </div>
              </div>

              <div className={`tab-pane fade ${activeTab===LeadTabList.KOPILOTEN?'show active':''}`} id="nav-kopiloten" role="tabpanel" aria-labelledby="nav-kopiloten-tab">
                <div className="leadSection">
                  {this.getMeKoPilotenSection()}
                </div>
              </div>
              <div className={`tab-pane fade ${activeTab===LeadTabList.CARE_BOX?'show active':''}`} id="nav-care-box" role="tabpanel" aria-labelledby="nav-care-box-tab">
                <div className="leadSection">
                  {careBoxEmptyHandler}
                </div>
              </div>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
        <Prompt
            when={needsSave}
            message={translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginName: state.auth.appLogin,
    appLang: state.lang.appLanguage,
    lastActionTime: state.auth.actionTimestamp,
    leadsStatus: state.enums.leadStatus,
    leadsData: state.database.leads,
    leadsSourceStatus: state.enums.leadSource,
    leadsTimestamp: state.database.leadsTimeStamp,
    tasksPriority: state.enums.taskPriority,
    tasksData: state.database.tasks,
    tasksStatus: state.database.tasksState,
    needsSave: state.refreshTokenStatus.needsDataSave,
    multipliersData: state.database.multipliers,
    multipliersTimeStamp: state.database.multipliersTimeStamp,
    patientLeadData: state.database.patientLead,
    fpFirstMail: state.auth.contactFpFirstEmail,
    fpName: state.auth.contactFpName,
    fpPhone: state.auth.FPphone,
    multiCategoryEnum: state.enums.multipliersCategoryList,
    multiCategoryEnumStatus: state.enums.multipliersCategoryStatus,
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  getStatusLead: (lang) => dispatch(getLeadStatusData(lang)),
  removeLeadsStatus: () => dispatch(removeLeadStatusData()),
  saveLeadData: (data,lang) => dispatch(leadSave(data, lang)),
  updateLeadDataFun: (leadId, data) => dispatch(updateLeadData(leadId, data)),
  getGlobalTasksData: () => dispatch(getTasks()),
  setGlobalTasksData: (data) => dispatch(setTasksData(data)),
  addNote: (note, userid, type) => dispatch(addNoteData(note, userid, type)),
  removeNote: (id) => dispatch(removeNoteData(id)),
  setLeadsData: (data) => dispatch(setLeadsData(data)),
  pendingRequestLeads: () => dispatch(pendingLeadsData()),
  clearDataLeads: () => dispatch(removeLeadsData()),
  needsSaveData: () => dispatch(needsSaveData()),
  doesNotNeedsSave: () => dispatch(doesNotNeedsSave()),
  setMultiplikatorenData: (data) => dispatch(setMultipliersData(data)),
  pendingMultiplikatorenRequest: () => dispatch(pendingMultipliersData()),
  clearMultiplikatorenData: () => dispatch(removeMultipliersData()),
  getRegions: (lang) => dispatch(getRegionsFP(lang))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadsDetailsPage));