import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {translateKey} from "../../../_redux/actions/index";
import WrappedAutoSuggestComponent from "../../wrapped-autosuggest-component/WrappedAutoSuggestComponent";
import {InputTypeTextComponent} from '../../form-bb-components/input-type-text-component/input-type-text-component';
import {
    CheckBoxSmallMultipleComponent
} from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import Select2 from "../../select2/select2";
import SwitchComponent from "../../form-bb-components/switch-component/switch-component";
import ListComponent from "../../form-bb-components/list-component/list-component";
import Counter from "../../form-bb-components/counter/Counter";
import SectionNavigationButtonsComponent
    from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import produce from "immer";
import * as Entity from "../../../_forms/BBForm/values/entity";
import * as CareBoxStatus from "../../../_forms/BBForm/values/careBoxStatus";
import {baseData} from "../../../_constance";
import {sendDocuSignCarebox} from '../../../_services/docuSign_service';
import BBFormService from "../../../_services/bbform";
import {LoaderModal} from "../../loader-modal/LoaderModal";
import {InfoModal} from "../../info-modal/InfoModal";

const limitAmountLower = 34;
const limitAmountUpper = 43.5;

class SectionCareBox extends React.Component {
    constructor(props) {
        super(props);
        const additionalData = props.disableAdditionalData !== undefined ? props.disableAdditionalData : false;
        const hideNavigation = props.hideNavigation !== undefined ? props.hideNavigation : false;
        const resetCareBoxStatus = props.resetCareBoxStatus !== undefined ? props.resetCareBoxStatus : false;
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-care-box'),
            showValidation: props.showValidationErrors,
            disabledPflegeboxFields: false,
            disableAdditionalData: additionalData,
            hideNavigation: hideNavigation,
            resetCareBoxStatus : resetCareBoxStatus,
        };
    }

    componentDidMount() {
        const {resetCareBoxStatus, disableAdditionalData} = this.state;
        if (!disableAdditionalData) {
            this.preperaAditionalDataForCarBox();
        }
        this.disablePflegebox();
        if(!resetCareBoxStatus) {
            this.setDefaultCareBoxStatus();
        }
    }

    setDefaultCareBoxStatus() {
        const {section} = this.state;
        let status = section.getFieldByKey('is_lead_status_care_box').value;
        if(status !== CareBoxStatus.CARE_BOX_REJECTED && this.state.resetCareBoxStatus) {
            section.getFieldByKey('is_lead_status_care_box').value = CareBoxStatus.LEAD_NEW;
        }
    }

    componentDidUpdate(prevProps) {
        const {showValidationErrors} = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({showValidation: showValidationErrors});
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const {section} = draftState;
            const field = section.getFieldByKey(key);

            if (key === 'is_lead_status_care_box') {
                const isCareBoxValue = this.getCareBoxStatusValue(section);
                if (isCareBoxValue === CareBoxStatus.CARE_BOX_INTERESTED_IN) {
                    const careBoxRejection = section.getFieldByKey('is_reason_for_rejection_care_box');
                    careBoxRejection.value = false;
                    this.clearCareBox();
                }
            }
            if(key === 'is_private_client') {
                this.forceUpdate();
            }
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
        if (key === 'is_care_box_setid') {
            this.setPflegeboxSets(value);
        }
        if (key === 'is_lead_status_care_box') {
            this.setServiceStatusDe(value);
        }
    };

    getCareBoxStatusValue(section) {
        let value = section.getFieldByKey('is_lead_status_care_box').value;
        return CareBoxStatus.resolveIsLeadStatusCareBox(value);
    }

    clearCareBox() {
        //const {section} = this.state;
        //section.getFieldByKey('is_care_box_setid').value = null;
        const {form} = this.props;
        this.clearBoxSet(form)
    };

    setTypeChangeWithCheckboxReset = (key, value) => {
        const mapedFields = {
            is_bed_protectors: 'is_number_o',
            is_one_time_gloves: 'is_number_r',
            is_hand_sanitizer: 'is_number_dr',
            is_surface_disinfectant: 'is_number_dp',
            is_disposable_masks: 'is_number_m',
            is_disposable_masks_ffp2: 'is_number_m_ffp',
            is_disposable_masks_ffp2_set: 'is_number_ffp_set',
            is_protective_aprons: 'is_number_f',
            is_reusable_bed_protectors: 'is_number_protectors',
            is_disposable_finger_overlay: 'is_number_njp',
            is_surface_disinfectant_1000: 'is_number_dp1000',
            is_hand_sanitizer_1000: 'is_number_dr1000',
            is_reusable_protective_aprons: 'is_number_wfo',
            is_disinfecting_wipes: 'is_number_ch'
        };
        if (!value) {
            this.setTypeChange(mapedFields[key], 0);
        }
        this.setTypeChange(key, value);
    }

    setTypeChangePostalCode = (key, suggestion) => {
        if (typeof suggestion === 'object' && suggestion !== null) {
            const nextState = produce(this.state, draftState => {
                const {section} = draftState;
                const field = section.getFieldByKey(key);
                const deliveryAdress = section.getFieldByKey('is_delivery_to_address').value;
                if (field) {
                    field.value = suggestion.value;
                }
                const city = section.getFieldByKey('is_city_pa');
                if (city) {
                    city.value = suggestion.city;
                }
                if (deliveryAdress === '717700002') {
                    section.getFieldByKey('is_postal_code_d').value = suggestion.value;
                    section.getFieldByKey('is_city_d').value = suggestion.city;
                }
            });
            this.setState(nextState);
        } else {
            const nextState = produce(this.state, draftState => {
                const {section} = draftState;
                const field = section.getFieldByKey(key);
                const deliveryAdress = section.getFieldByKey('is_delivery_to_address').value;
                if (field) {
                    field.value = suggestion;
                }
                if (deliveryAdress === '717700002') {
                    section.getFieldByKey('is_postal_code_d').value = suggestion;
                }
            });
            this.setState(nextState);
        }
    };

    handlechangeHealthIncurance = event => {
        if (typeof event === 'object') {
            this.setTypeChange('is_health_insurance', event.value);
        } else {
            this.setTypeChange('is_health_insurance', event);
        }
    }

    prepareMeCitySelect = () => {
        if (this.props.cities.length > 0) {
            return this.props.cities.map(x => ({
                value: x.PostalCode !== null ? x.PostalCode : '',
                city: x.City !== null ? x.City : '',
                label: x.City !== null ? x.PostalCode + "-" + x.City : ''
            }));
        } else {
            return [];
        }
    }

    prepareMeHealthIncuranceSelect = () => {
        if (this.props.healthIncuranceList.length > 0) {
            return this.props.healthIncuranceList.map(x => ({
                value: x.Id !== null ? x.Id : '',
                city: x.Name !== null ? x.Name : '',
                label: x.Name !== null ? x.Name : ''
            }));
        } else {
            return [];
        }
    }

    handleDeliveryData = (key, value) => {
        const {section} = this.state;
        const {form} = this.props;
        let firstname = '';
        let lastname = '';
        let street = '';
        let houseNumber = '';
        let apartmentNumber = '';
        let postalCode = '';
        let city = '';

        if (value === '717700000') {
            firstname = form.getFieldByKey('is_patient_first_name').value;
            lastname = form.getFieldByKey('is_patient_last_name').value;
            street = form.getFieldByKey('is_service_address_street').value;
            houseNumber = form.getFieldByKey('is_service_address_street_number').value;
            apartmentNumber = form.getFieldByKey('is_service_address_house_number').value;
            postalCode = form.getFieldByKey('is_service_address_postal_code').value;
            city = form.getFieldByKey('is_service_address_city').value;
        } else if (value === '717700001') {
            firstname = form.getFieldByKey('firstname').value;
            lastname = form.getFieldByKey('lastname').value;
            street = form.getFieldByKey('is_registered_street').value;
            houseNumber = form.getFieldByKey('is_registered_street_number').value;
            apartmentNumber = form.getFieldByKey('is_registered_house_number').value;
            postalCode = form.getFieldByKey('is_registered_zip_postal_code').value;
            city = form.getFieldByKey('is_registered_city').value;
        } else {
            firstname = section.getFieldByKey('is_outpatient_assistance_name').value;
            lastname = '';
            street = section.getFieldByKey('is_street_pa').value;
            houseNumber = section.getFieldByKey('is_house_number_pa').value;
            apartmentNumber = section.getFieldByKey('is_apartement_number_pa').value;
            postalCode = section.getFieldByKey('is_postal_code_pa').value;
            city = section.getFieldByKey('is_city_pa').value;
        }
        this.setTypeChange('is_first_name_d', firstname);
        this.setTypeChange('is_last_name_d', lastname);
        this.setTypeChange('is_street_d', street);
        this.setTypeChange('is_house_number_d', houseNumber);
        this.setTypeChange('is_apartment_number_d', apartmentNumber);
        this.setTypeChange('is_postal_code_d', postalCode);
        this.setTypeChange('is_city_d', city);
        this.setTypeChange(key, value);
    }

    setTypeChangeWithAddress = (key, value) => {
        const {section} = this.state;
        const keyList = [
            {
                changedValue: 'is_outpatient_assistance_name',
                toChange: 'is_last_name_d'
            },
            {
                changedValue: 'is_street_pa',
                toChange: 'is_street_d'
            },
            {
                changedValue: 'is_house_number_pa',
                toChange: 'is_house_number_d'
            },
            {
                changedValue: 'is_apartement_number_pa',
                toChange: 'is_apartment_number_d'
            },
            {
                changedValue: 'is_postal_code_pa',
                toChange: 'is_postal_code_d'
            },
            {
                changedValue: 'is_city_pa',
                toChange: 'is_city_d'
            },
        ];
        this.setTypeChange(key, value);
        if (section.getFieldByKey('is_delivery_to_address').value === '717700002') {
            const keyToChange = keyList.filter(item => item.changedValue === key);
            this.setTypeChange(keyToChange[0].toChange, value);
        }
    }

    prepareMePflegeboxSelect = () => {
        if (this.props.careBoxSetsList != null && this.props.careBoxSetsList.length > 0) {
            return this.props.careBoxSetsList.map(x => ({
                value: x.Id !== null ? x.Id : '',
                city: x.Name !== null ? x.Name : '',
                label: x.Name !== null ? x.Name : ''
            }));
        } else {
            return [];
        }
    }

    prepareMeGloveTypeSelect = () => {
        const gloveType = this.props.gloveType;
        if (gloveType) {
            return Object.keys(gloveType).map(item => ({
                value: item !== null ? item : '',
                city: gloveType[item] !== null ? gloveType[item] : '',
                label: gloveType[item] !== null ? gloveType[item] : ''
            }));
        } else {
            return [];
        }
    }

    disablePflegebox = () => {
        const {form} = this.props;
        const pflegeboxValue = form.getFieldByKey('is_care_box_setid').value;

        if (pflegeboxValue !== baseData.individualCareBoxId) {
            this.setState({disabledPflegeboxFields: true});
        } else {
            this.setState({disabledPflegeboxFields: false});
        }
    }

    setPflegeboxSets = (value) => {
        const {form, careBoxSets} = this.props;
        const setValues = careBoxSets.filter(item => item.id === value)[0];
        if (value !== baseData.individualCareBoxId) {
            if (setValues.is_bed_protectors_number !== null) {
                form.getFieldByKey('is_number_o').value = parseInt(setValues.is_bed_protectors_number);
                form.getFieldByKey('is_bed_protectors').value = true;
            } else {
                form.getFieldByKey('is_number_o').value = 0;
                form.getFieldByKey('is_bed_protectors').value = false;
            }

            if (setValues.is_disposable_gloves_number !== null) {
                form.getFieldByKey('is_number_r').value = parseInt(setValues.is_disposable_gloves_number);
                form.getFieldByKey('is_one_time_gloves').value = true;
            } else {
                form.getFieldByKey('is_number_r').value = 0;
                form.getFieldByKey('is_one_time_gloves_size').value = '717700001';
                form.getFieldByKey('is_one_time_gloves_type').value = '717700000';
                form.getFieldByKey('is_one_time_gloves').value = false;
            }

            if (setValues.is_hand_sanitizer_number !== null) {
                form.getFieldByKey('is_number_dr').value = parseInt(setValues.is_hand_sanitizer_number);
                form.getFieldByKey('is_hand_sanitizer').value = true;
            } else {
                form.getFieldByKey('is_number_dr').value = 0;
                form.getFieldByKey('is_hand_sanitizer').value = false;
            }

            if (setValues.is_surface_disinfectant_number !== null) {
                form.getFieldByKey('is_number_dp').value = parseInt(setValues.is_surface_disinfectant_number);
                form.getFieldByKey('is_surface_disinfectant').value = true;
            } else {
                form.getFieldByKey('is_number_dp').value = 0;
                form.getFieldByKey('is_surface_disinfectant').value = false;
            }

            if (setValues.is_disposable_masks_number !== null) {
                form.getFieldByKey('is_number_m').value = parseInt(setValues.is_disposable_masks_number);
                form.getFieldByKey('is_disposable_masks').value = true;
            } else {
                form.getFieldByKey('is_number_m').value = 0;
                form.getFieldByKey('is_disposable_masks').value = false;
            }

            if (setValues.is_disposable_ffp2_masks_number !== null) {
                form.getFieldByKey('is_number_m_ffp').value = parseInt(setValues.is_disposable_ffp2_masks_number);
                form.getFieldByKey('is_disposable_masks_ffp2').value = true;
            } else {
                form.getFieldByKey('is_number_m_ffp').value = 0;
                form.getFieldByKey('is_disposable_masks_ffp2').value = false;
            }

            if (setValues.is_disposable_masks_ffp2_set_number !== null) {
                form.getFieldByKey('is_number_ffp_set').value = parseInt(setValues.is_disposable_masks_ffp2_set_number);
                form.getFieldByKey('is_disposable_masks_ffp2_set').value = true;
            } else {
                form.getFieldByKey('is_number_ffp_set').value = 0;
                form.getFieldByKey('is_disposable_masks_ffp2_set').value = false;
            }

            if (setValues.is_protective_aprons_number !== null) {
                form.getFieldByKey('is_number_f').value = parseInt(setValues.is_protective_aprons_number);
                form.getFieldByKey('is_protective_aprons').value = true;
            } else {
                form.getFieldByKey('is_number_f').value = 0;
                form.getFieldByKey('is_protective_aprons').value = false;
            }

            if (setValues.is_reusable_bed_protectors_number !== null) {
                form.getFieldByKey('is_number_protectors').value = parseInt(setValues.is_reusable_bed_protectors_number);
                form.getFieldByKey('is_reusable_bed_protectors').value = true;
            } else {
                form.getFieldByKey('is_number_protectors').value = 0;
                form.getFieldByKey('is_reusable_bed_protectors').value = false;
            }
            if (setValues.is_disposable_finger_overlay_number !== null) {
                form.getFieldByKey('is_number_njp').value = parseInt(setValues.is_disposable_finger_overlay_number);
                form.getFieldByKey('is_disposable_finger_overlay').value = true;
            } else {
                form.getFieldByKey('is_number_njp').value = 0;
                form.getFieldByKey('is_disposable_finger_overlay').value = false;
            }
            if (setValues.is_surface_disinfectant_1000_number !== null) {
                form.getFieldByKey('is_number_dp1000').value = parseInt(setValues.is_surface_disinfectant_1000_number);
                form.getFieldByKey('is_surface_disinfectant_1000').value = true;
            } else {
                form.getFieldByKey('is_number_dp1000').value = 0;
                form.getFieldByKey('is_surface_disinfectant_1000').value = false;
            }
            if (setValues.is_hand_sanitizer_1000_number !== null) {
                form.getFieldByKey('is_number_dr1000').value = parseInt(setValues.is_hand_sanitizer_1000_number);
                form.getFieldByKey('is_hand_sanitizer_1000').value = true;
            } else {
                form.getFieldByKey('is_number_dr1000').value = 0;
                form.getFieldByKey('is_hand_sanitizer_1000').value = false;
            }
            if (setValues.is_reusable_protective_aprons_number !== null) {
                form.getFieldByKey('is_number_wfo').value = parseInt(setValues.is_reusable_protective_aprons_number);
                form.getFieldByKey('is_reusable_protective_aprons').value = true;
            } else {
                form.getFieldByKey('is_number_wfo').value = 0;
                form.getFieldByKey('is_reusable_protective_aprons').value = false;
            }
            if (setValues.is_disinfecting_wipes_number !== null) {
                form.getFieldByKey('is_number_ch').value = parseInt(setValues.is_disinfecting_wipes_number);
                form.getFieldByKey('is_disinfecting_wipes').value = true;
            } else {
                form.getFieldByKey('is_number_ch').value = 0;
                form.getFieldByKey('is_disinfecting_wipes').value = false;
            }
            this.setState({disabledPflegeboxFields: true});
        } else {
            this.clearBoxSet(form)
            this.setState({disabledPflegeboxFields: false});
        }
    }

    clearBoxSet(form) {
        form.getFieldByKey('is_number_o').value = 0;
        form.getFieldByKey('is_bed_protectors').value = false;
        form.getFieldByKey('is_number_r').value = 0;
        form.getFieldByKey('is_one_time_gloves_size').value = '717700001';
        form.getFieldByKey('is_one_time_gloves_type').value = '717700000';
        form.getFieldByKey('is_one_time_gloves').value = false;
        form.getFieldByKey('is_number_dr').value = 0;
        form.getFieldByKey('is_hand_sanitizer').value = false;
        form.getFieldByKey('is_number_dp').value = 0;
        form.getFieldByKey('is_surface_disinfectant').value = false;
        form.getFieldByKey('is_number_m').value = 0;
        form.getFieldByKey('is_disposable_masks').value = false;
        form.getFieldByKey('is_number_m_ffp').value = 0;
        form.getFieldByKey('is_disposable_masks_ffp2').value = false;
        form.getFieldByKey('is_number_ffp_set').value = 0;
        form.getFieldByKey('is_disposable_masks_ffp2_set').value = false;
        form.getFieldByKey('is_number_f').value = 0;
        form.getFieldByKey('is_protective_aprons').value = false;
        form.getFieldByKey('is_number_protectors').value = 0;
        form.getFieldByKey('is_reusable_bed_protectors').value = false;
        form.getFieldByKey('is_number_njp').value = 0;
        form.getFieldByKey('is_disposable_finger_overlay').value = false;
        form.getFieldByKey('is_number_dp1000').value = 0;
        form.getFieldByKey('is_surface_disinfectant_1000').value = false;
        form.getFieldByKey('is_number_dr1000').value = 0;
        form.getFieldByKey('is_hand_sanitizer_1000').value = false;
        form.getFieldByKey('is_number_wfo').value = 0;
        form.getFieldByKey('is_reusable_protective_aprons').value = false;
    }

    preperaAditionalDataForCarBox = () => {
        const {match: {params}, form, userId} = this.props;
        const {section} = this.state;
        const date = new Date();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const leadsData = this.props.leadsData.filter(item => item.leadId === params.id);
        let monthCase = 717700000;

        switch (month) {
            case 1:
                monthCase = 717700000
                break;
            case 2:
                monthCase = 717700001
                break;
            case 3:
                monthCase = 717700002
                break;
            case 4:
                monthCase = 717700003
                break;
            case 5:
                monthCase = 717700004
                break;
            case 6:
                monthCase = 717700005
                break;
            case 7:
                monthCase = 717700006
                break;
            case 8:
                monthCase = 717700007
                break;
            case 9:
                monthCase = 717700008
                break;
            case 10:
                monthCase = 717700009
                break;
            case 11:
                monthCase = 717700010
                break;
            case 12:
                monthCase = 717700011
                break;
        }
        if (this.getCareBoxStatusValue(section) == '717700003') {
            form.getFieldByKey('is_reason_for_rejection_care_box').value = false;
        }
        this.setServiceStatusDe(this.getCareBoxStatusValue(section));
        form.getFieldByKey('is_status').value = CareBoxStatus.APP_NEW;
        if (form.getFieldByKey('is_status_duplicated')) {
            form.getFieldByKey('is_status_duplicated').value = '717700000';
        }

        form.getFieldByKey('is_contactid').value = params.id;
        if (form.getFieldByKey('is_contactid_duplicated')) {
            form.getFieldByKey('is_contactid_duplicated').value = params.id;
        }
        form.getFieldByKey('is_patientid').value = params.patientId;
        // Patient data
        form.getFieldByKey('is_name').value = form.getFieldByKey('is_first_name_and_last_name_of_patient').value;
        if (form.getFieldByKey('is_name_duplicated')) {
            form.getFieldByKey('is_name_duplicated').value = form.getFieldByKey('is_first_name_and_last_name_of_patient').value;
        }
        form.getFieldByKey('is_name_p').value = form.getFieldByKey('is_patient_first_name').value;
        ;
        form.getFieldByKey('is_surname_p').value = form.getFieldByKey('is_patient_last_name').value;
        form.getFieldByKey('is_street_p').value = form.getFieldByKey('is_service_address_street').value;
        form.getFieldByKey('is_house_number_p').value = form.getFieldByKey('is_service_address_street_number').value;
        form.getFieldByKey('is_apartment_number_p').value = form.getFieldByKey('is_service_address_house_number').value;
        form.getFieldByKey('is_postal_code_p').value = form.getFieldByKey('is_service_address_postal_code').value;
        form.getFieldByKey('is_city_p').value = form.getFieldByKey('is_service_address_city').value;
        form.getFieldByKey('is_home_phone_p').value = form.getFieldByKey('is_patient_phone').value;
        form.getFieldByKey('is_cell_phone_p').value = form.getFieldByKey('is_patient_cellphone').value;
        if (leadsData[0] !== undefined && leadsData[0].patientObj != null) {
            form.getFieldByKey('is_email_address_p').value = leadsData[0].patientObj.email;
        }
        form.getFieldByKey('is_birth_date').value = form.getFieldByKey('is_birthday').value;
        if (form.getFieldByKey('is_pflegegrad_duplicated')) {
            form.getFieldByKey('is_pflegegrad_duplicated').value = form.getFieldByKey('is_pflegegrad').value;
        }
        // Client data
        form.getFieldByKey('is_name_k').value = form.getFieldByKey('firstname').value;
        form.getFieldByKey('is_surname_k').value = form.getFieldByKey('lastname').value;
        form.getFieldByKey('is_street_k').value = form.getFieldByKey('is_registered_street').value;
        form.getFieldByKey('is_house_number_k').value = form.getFieldByKey('is_registered_street_number').value;
        form.getFieldByKey('is_apartment_number_k').value = form.getFieldByKey('is_registered_house_number').value;
        form.getFieldByKey('is_postal_code_k').value = form.getFieldByKey('is_registered_zip_postal_code').value;
        form.getFieldByKey('is_city_k').value = form.getFieldByKey('is_registered_city').value;
        form.getFieldByKey('is_home_phone_k').value = form.getFieldByKey('is_home_phone').value;
        form.getFieldByKey('is_cell_phone_k').value = form.getFieldByKey('is_mobile_phone').value;
        form.getFieldByKey('is_email_address_k').value = form.getFieldByKey('is_email').value;
        //rest of data
        form.getFieldByKey('ownerid').value = userId;
        if (form.getFieldByKey('ownerid_duplicated')) {
            form.getFieldByKey('ownerid_duplicated').value = userId;
        }
        form.getFieldByKey('is_month').value = monthCase;
        form.getFieldByKey('is_year').value = year;
    }

    setServiceStatusDe = (value) => {
        let newVal = value === '717700003' ? '717700002' : '717700001';
        const {form} = this.props;
        const oldVal = form.getFieldByKey('is_service_type_de').value;
        if (oldVal === '717700002') {
            newVal = value === '717700003' ? '717700002' : '717700001';
        } else if (oldVal === '717700000') {
            newVal = value === '717700003' ? '717700000' : '717700001';
        }
        this.setTypeChange('is_service_type_de', newVal);
    }

    isFormValid(fullPrice) {
        const {section} = this.state;
        const isCareBoxValue = this.getCareBoxStatusValue(section);
        const pflegeboxType = section.getFieldByKey('is_care_box_setid');
        if (isCareBoxValue === '717700003') {
            return ((pflegeboxType.value === baseData.individualCareBoxId && fullPrice > limitAmountLower && fullPrice <= limitAmountUpper) || pflegeboxType.value !== baseData.individualCareBoxId)
        } else {
            return true;
        }
    }

    render() {
        const {
            isImportant,
            disabled,
            sectionNumber,
            subsectionName,
            analizeFinished,
            currentSection,
            showValidationErrors,
            rerender,
            previousPageClick,
            nextPageClick,
            finishMe,
            form,
            translateKey,
            careBoxIndividualPrices
        } = this.props;
        const {section, showValidation, disabledPflegeboxFields} = this.state;
        const isNewUI = true;
        const preparedSelectGroup = this.prepareMeCitySelect();
        const preparedHealthIncuranceSelectGroup = this.prepareMeHealthIncuranceSelect();
        const preparedPflegeboxSelectGroup = this.prepareMePflegeboxSelect();
        const preparedProductTwoSizesSelectGroup = [
            {value: '717700000', city: 'S', label: 'S'},
            {value: '717700001', city: 'M', label: 'M'},
            {value: '717700002', city: 'L', label: 'L'},
            {value: '717700003', city: 'XL', label: 'XL'},
        ];
        const preparedProductTwoTypesSelectGroup = this.prepareMeGloveTypeSelect();
        const isCareBox = section.getFieldByKey('is_lead_status_care_box');
        const isReasonForRejection = section.getFieldByKey('is_reason_for_rejection_care_box');
        const isPrivatClient = section.getFieldsByEntityAndKey(Entity.CONTACT, 'is_private_client');
        const isHealthInsurance = section.getFieldByKey('is_health_insurance');
        //   const isPflegegradFrom = section.getFieldByKey('is_pflegegrad_from');
        const isPostalCodePa = section.getFieldByKey('is_postal_code_pa');
        const isDeliveryAddress = section.getFieldByKey('is_delivery_to_address');
        const pflegeboxType = section.getFieldByKey('is_care_box_setid');
        // Care box products
        const productOne = section.getFieldByKey('is_bed_protectors');
        const quantityOfProductOne = section.getFieldByKey('is_number_o');
        const productTwo = section.getFieldByKey('is_one_time_gloves');
        const quantityOfProductTwo = section.getFieldByKey('is_number_r');
        const sizeOfProductTwo = section.getFieldByKey('is_one_time_gloves_size');
        const typeOfProductTwo = section.getFieldByKey('is_one_time_gloves_type');
        const productThree = section.getFieldByKey('is_hand_sanitizer');
        const quantityOfProductThree = section.getFieldByKey('is_number_dr');
        const productFour = section.getFieldByKey('is_surface_disinfectant');
        const quantityOfProductFour = section.getFieldByKey('is_number_dp');
        const productFive = section.getFieldByKey('is_disposable_masks');
        const quantityOfProductFive = section.getFieldByKey('is_number_m');
        const productSix = section.getFieldByKey('is_disposable_masks_ffp2');
        const quantityOfProductSix = section.getFieldByKey('is_number_m_ffp');
        const productFFP2Set = section.getFieldByKey('is_disposable_masks_ffp2_set');
        const quantityOfProductFFP2Set = section.getFieldByKey('is_number_ffp_set');
        const productSeven = section.getFieldByKey('is_protective_aprons');
        const quantityOfProductSeven = section.getFieldByKey('is_number_f');
        const productEight = section.getFieldByKey('is_reusable_bed_protectors');
        const quantityOfProductEight = section.getFieldByKey('is_number_protectors');
        const productNine = section.getFieldByKey('is_disposable_finger_overlay');
        const quantityOfProductNine = section.getFieldByKey('is_number_njp');
        const productTen = section.getFieldByKey('is_surface_disinfectant_1000');
        const quantityOfProductTen = section.getFieldByKey('is_number_dp1000');
        const productEleven = section.getFieldByKey('is_hand_sanitizer_1000');
        const quantityOfProductEleven = section.getFieldByKey('is_number_dr1000');
        const productTwelve = section.getFieldByKey('is_reusable_protective_aprons');
        const quantityOfProductTwelve = section.getFieldByKey('is_number_wfo');
        const productThirteen = section.getFieldByKey('is_disinfecting_wipes');
        const quantityOfProductThirteen = section.getFieldByKey('is_number_ch');
        const classForCheckBox = "custom-control-inline illness-wrapper newUI-radio-check-box-class cursor-pointer";
        // prices for pflegebox items
        const quantityOfProductOnePrice = quantityOfProductOne.value * parseInt(careBoxIndividualPrices.is_bed_protectors_return);
        const quantityOfProductTwoPrice = quantityOfProductTwo.value * parseInt(careBoxIndividualPrices.is_one_time_gloves_return);
        const quantityOfProductThreePrice = quantityOfProductThree.value * parseInt(careBoxIndividualPrices.is_hand_sanitizer_500_return);
        const quantityOfProductFourPrice = quantityOfProductFour.value * parseInt(careBoxIndividualPrices.is_surface_disinfectant_500_return);
        const quantityOfProductFivePrice = quantityOfProductFive.value * parseInt(careBoxIndividualPrices.is_disposable_masks_return);
        const quantityOfProductSixPrice = quantityOfProductSix.value * parseInt(careBoxIndividualPrices.is_disposable_masks_ffp2_return);
        const quantityOfProductFFP2SetPrice = quantityOfProductFFP2Set.value * parseInt(careBoxIndividualPrices.is_disposable_masks_ffp2_set_return);
        const quantityOfProductSevenPrice = quantityOfProductSeven.value * parseInt(careBoxIndividualPrices.is_protective_aprons_return);
        const quantityOfProductNinePrice = quantityOfProductNine.value * parseInt(careBoxIndividualPrices.is_disposable_finger_overlay_return);
        const quantityOfProductTenPrice = quantityOfProductTen.value * parseInt(careBoxIndividualPrices.is_surface_disinfectant_1000_return);
        const quantityOfProductElevenPrice = quantityOfProductEleven.value * parseInt(careBoxIndividualPrices.is_hand_sanitizer_1000_return);
        const quantityOfProductTwelvePrice = quantityOfProductTwelve.value * parseInt(careBoxIndividualPrices.is_reusable_protective_aprons_return);
        const quantityOfProductThirteenPrice = quantityOfProductThirteen.value * parseInt(careBoxIndividualPrices.is_disinfecting_wipes_return);

        const fullPrice = quantityOfProductOnePrice + quantityOfProductTwoPrice + quantityOfProductThreePrice +
            quantityOfProductFourPrice + quantityOfProductFivePrice + quantityOfProductSixPrice + quantityOfProductFFP2SetPrice +
            quantityOfProductSevenPrice + quantityOfProductNinePrice +
            quantityOfProductTenPrice + quantityOfProductElevenPrice + quantityOfProductTwelvePrice +
            quantityOfProductThirteenPrice;
        const isCareBoxValue = this.getCareBoxStatusValue(section);
        return (
            <div className="form-section-main w-100">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled ? 'no-pointer-events' : ''}`}>
                        <div className="newUI-section-padding">
                            <div className="row justify-content-md-center">
                                <SwitchComponent
                                    labelClassName="no_margin"
                                    setTypeChangeToParent={this.setTypeChange}
                                    isImportant={isImportant && isCareBox.checkImportant}
                                    showValidation={showValidationErrors}
                                    className='col-sm-12 col-lg-6 d-flex flex-column align-items-center'
                                    transKey="form-text-is-lead-status-care-box"
                                    key="is_lead_status_care_box"
                                    keyName="is_lead_status_care_box"
                                    value={isCareBoxValue}
                                    validationErrors={isCareBox.validationErrors}
                                    valueYes={CareBoxStatus.CARE_BOX_INTERESTED_IN}
                                    valueNo={CareBoxStatus.CARE_BOX_REJECTED}
                                />
                            </div>
                            {isCareBoxValue === CareBoxStatus.CARE_BOX_REJECTED &&
                                <div className="row justify-content-md-center">
                                    <div className="col-12">
                                        <div className="newUI-section-padding">
                                            <div className="checkbox-button-group-wrapper row">
                                                <div className="col-sm-12 col-lg-12">
                                                    <ListComponent
                                                        disabled={disabled}
                                                        newUI={isNewUI}
                                                        showValidation={showValidationErrors}
                                                        setTypeChangeToParent={this.setTypeChange}
                                                        className='col-sm-12 col-lg-12 form-margin-bottom-fix'
                                                        transKey="form-text-reason-for-disinterest"
                                                        key="is_reason_for_rejection_care_box"
                                                        keyName="is_reason_for_rejection_care_box"
                                                        field={isReasonForRejection}
                                                        type="column"
                                                        value={isReasonForRejection.value}
                                                        validationErrors={isReasonForRejection.validationErrors}
                                                        valuesList={[
                                                            {
                                                                value: '717700000',
                                                                label: translateKey("form-text-not-need-now")
                                                            },
                                                            {
                                                                value: '717700001',
                                                                label: translateKey("form-text-cooperation-with-another-provider")
                                                            },
                                                            {
                                                                value: '717700002',
                                                                label: translateKey("form-text-bad-quality")
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {isCareBoxValue === CareBoxStatus.CARE_BOX_INTERESTED_IN  &&
                                <>
                                    <div className="row newUI-section-padding">
                                        <SwitchComponent
                                            labelClassName="no_margin"
                                            setTypeChangeToParent={this.setTypeChange}
                                            isImportant={isImportant && isPrivatClient.checkImportant}
                                            showValidation={showValidationErrors}
                                            className='col-sm-12 col-lg-12 d-flex flex-column align-items-center'
                                            transKey="form-text-privat-client"
                                            key="is_private_client"
                                            keyName="is_private_client"
                                            value={isPrivatClient.value}
                                            validationErrors={isPrivatClient.validationErrors}
                                            valueYes='717700000'
                                            valueNo='717700001'
                                            labelYes={translateKey("form-text-privat-client-yes")}
                                            labelNo={translateKey("form-text-privat-client-no")}
                                        />
                                        {/* { isPrivatClient.value == '717700000' && */}
                                        <>
                                            <div
                                                className={`col-sm-12 col-lg-6 form-margin-bottom-fix meeting-autosuggestion-wrapper ${!isHealthInsurance.isValid && showValidationErrors ? 'is-invalid' : ''}`}>
                                                <label className="form-label-basic newUI">
                                                    <span className="quick-label-position-fix">
                                                        {translateKey("form-text-health-incurance").toUpperCase()}
                                                    </span>
                                                </label>
                                                {/* <AutoSuggestComponent 
                                                    onChange={this.handlechangeHealthIncurance} 
                                                    value={isHealthInsurance.value} 
                                                    suggestions={preparedHealthIncuranceSelectGroup}
                                                /> */}
                                                <React.Fragment>
                                                    <Select2
                                                        disabled={disabled}
                                                        value={isHealthInsurance.value}
                                                        onChange={(val) => this.setTypeChange('is_health_insurance', val.value)}
                                                        options={preparedHealthIncuranceSelectGroup}
                                                        aditionalStyles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                height: '54px'
                                                            })
                                                        }}
                                                    />
                                                    <div
                                                        className="invalid-feedback">{translateKey("form_contains_errors")}</div>
                                                </React.Fragment>
                                            </div>
                                            <InputTypeTextComponent
                                                newUI={isNewUI}
                                                showValidation={showValidationErrors}
                                                className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={section.getFieldByKey('is_insurance_number')}
                                                text={translateKey("form-text-insurance-number")}
                                                transKey="form-text-insurance-number"
                                                keyName="is_insurance_number"
                                            />
                                            {/* <WrappedDatePickerComponent
                                                withCalendarIcon={true}
                                                newUI={isNewUI} 
                                                showValidation={showValidationErrors}
                                                keyName="is_pflegegrad_from"
                                                value={isPflegegradFrom.value}
                                                validationErrors={isPflegegradFrom.validationErrors}
                                                text={translateKey("form-text-pflegegrad-from")}
                                                setTypeChangeToParent={this.setTypeChange}
                                                disabled={disabled}
                                                className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                isRequired={true}
                                            /> */}
                                        </>
                                        {/* } */}
                                    </div>
                                    <div className="row newUI-section-padding">
                                        <ListComponent
                                            disabled={disabled}
                                            newUI={isNewUI}
                                            showValidation={showValidationErrors}
                                            setTypeChangeToParent={this.handleDeliveryData}
                                            className='col-sm-12 col-lg-12 form-margin-bottom-fix'
                                            transKey="form-text-delivery-address-care-box"
                                            key="is_delivery_to_address"
                                            keyName="is_delivery_to_address"
                                            field={isDeliveryAddress}
                                            type="column"
                                            value={isDeliveryAddress.value}
                                            validationErrors={isDeliveryAddress.validationErrors}
                                            valuesList={[
                                                {value: '717700000', label: translateKey("form-text-patient-care-box")},
                                                {
                                                    value: '717700001',
                                                    label: translateKey("form-text-customer-care-box")
                                                },
                                                {
                                                    value: '717700002',
                                                    label: translateKey("form-text-nursing-service-care-box")
                                                },
                                            ]}
                                        />
                                        {(isDeliveryAddress.value !== '717700002' && isDeliveryAddress.value !== '') &&
                                            <>
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChange}
                                                    field={section.getFieldByKey('is_last_name_d')}
                                                    text={translateKey("last_name")}
                                                    transKey="last_name"
                                                    keyName="is_last_name_d"
                                                    disabled={true}
                                                />
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChange}
                                                    field={section.getFieldByKey('is_first_name_d')}
                                                    text={translateKey("first_name")}
                                                    transKey="first_name"
                                                    keyName="is_first_name_d"
                                                    disabled={true}
                                                />
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChange}
                                                    field={section.getFieldByKey('is_street_d')}
                                                    text={translateKey("street")}
                                                    transKey="street"
                                                    keyName="is_street_d"
                                                    disabled={true}
                                                />
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChange}
                                                    field={section.getFieldByKey('is_house_number_d')}
                                                    text={translateKey("form-text-section-0-2")}
                                                    transKey="form-text-section-0-2"
                                                    keyName="is_house_number_d"
                                                    disabled={true}
                                                />
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChange}
                                                    field={section.getFieldByKey('is_apartment_number_d')}
                                                    text={translateKey("apartment_number")}
                                                    transKey="apartment_number"
                                                    keyName="is_apartment_number_d"
                                                    disabled={true}
                                                />
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChange}
                                                    field={section.getFieldByKey('is_city_d')}
                                                    text={translateKey("city")}
                                                    transKey="city"
                                                    keyName="is_city_d"
                                                    disabled={true}
                                                />
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChange}
                                                    field={section.getFieldByKey('is_postal_code_d')}
                                                    text={translateKey("postal_code")}
                                                    transKey="postal_code"
                                                    keyName="is_postal_code_d"
                                                    disabled={true}
                                                />
                                            </>
                                        }
                                        {(isDeliveryAddress.value === '717700002' && isDeliveryAddress.value !== '') &&
                                            <>
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChangeWithAddress}
                                                    field={section.getFieldByKey('is_outpatient_assistance_name')}
                                                    text={translateKey("form-text-care-box-name")}
                                                    transKey="form-text-care-box-name"
                                                    keyName="is_outpatient_assistance_name"
                                                />
                                                <div className="col-sm-12 col-lg-6"></div>
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChangeWithAddress}
                                                    field={section.getFieldByKey('is_street_pa')}
                                                    text={translateKey("street")}
                                                    transKey="street"
                                                    keyName="is_street_pa"
                                                />
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChangeWithAddress}
                                                    field={section.getFieldByKey('is_house_number_pa')}
                                                    text={translateKey("form-text-section-0-2")}
                                                    transKey="form-text-section-0-2"
                                                    keyName="is_house_number_pa"
                                                />
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChangeWithAddress}
                                                    field={section.getFieldByKey('is_apartement_number_pa')}
                                                    text={translateKey("apartment_number")}
                                                    transKey="apartment_number"
                                                    keyName="is_apartement_number_pa"
                                                />
                                                <InputTypeTextComponent
                                                    newUI={isNewUI}
                                                    showValidation={showValidationErrors}
                                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                    setTypeChangeToParent={this.setTypeChangeWithAddress}
                                                    field={section.getFieldByKey('is_city_pa')}
                                                    text={translateKey("city")}
                                                    transKey="city"
                                                    keyName="is_city_pa"
                                                />
                                                <WrappedAutoSuggestComponent
                                                    newUI={isNewUI}
                                                    value={isPostalCodePa.value}
                                                    suggestions={preparedSelectGroup}
                                                    showValidation={this.state.showValidation}
                                                    keyName="is_postal_code_pa"
                                                    field={isPostalCodePa}
                                                    validationErrors={isPostalCodePa.validationErrors}
                                                    text={translateKey("postal_code")}
                                                    setTypeChangeToParent={this.setTypeChangePostalCode}
                                                    disabled={disabled}
                                                    className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                                                />
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding">
                                        <div
                                            className={`col-sm-12 col-md-6 form-margin-bottom-fix meeting-autosuggestion-wrapper ${(pflegeboxType.value === null || pflegeboxType.value === '') ? 'is-invalid' : ''}`}>
                                            <label className="form-label-basic newUI">
                                            <span className="quick-label-position-fix">
                                                {translateKey("form-text-care-box-types").toUpperCase()}
                                            </span><span className="red-star-required-field"> * </span>
                                            </label>
                                            <React.Fragment>
                                                <Select2
                                                    disabled={disabled}
                                                    value={pflegeboxType.value}
                                                    onChange={(val) => this.setTypeChange('is_care_box_setid', val.value)}
                                                    options={preparedPflegeboxSelectGroup}
                                                    aditionalStyles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            height: '54px'
                                                        })
                                                    }}
                                                />
                                                {showValidationErrors &&
                                                    <div
                                                        className="invalid-feedback">{translateKey("form_contains_errors")}</div>
                                                }
                                            </React.Fragment>
                                        </div>
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-md-2 col-lg-3 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-two"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productTwo}
                                                text={translateKey("form-text-care-box-content-two")}
                                                transKey="form-text-care-box-content-two"
                                                keyName="is_one_time_gloves"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productTwo.value &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                    <label className="form-label-basic newUI">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-content-type").toUpperCase()}
                                                </span>
                                                    </label>
                                                    <Select2
                                                        disabled={disabled}
                                                        value={typeOfProductTwo.value}
                                                        onChange={(val) => this.setTypeChange('is_one_time_gloves_type', val.value)}
                                                        options={preparedProductTwoTypesSelectGroup}
                                                        aditionalStyles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                height: '38px'
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-1 no-padding">
                                                    <label className="form-label-basic newUI">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-content-size").toUpperCase()}
                                                </span>
                                                    </label>
                                                    <Select2
                                                        disabled={disabled}
                                                        value={sizeOfProductTwo.value}
                                                        onChange={(val) => this.setTypeChange('is_one_time_gloves_size', val.value)}
                                                        options={preparedProductTwoSizesSelectGroup}
                                                        aditionalStyles={{
                                                            control: (provided, state) => ({
                                                                ...provided,
                                                                height: '38px'
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <Counter
                                                    keyName="is_number_r"
                                                    validationErrors={quantityOfProductTwo.validationErrors}
                                                    showValidation={showValidation}
                                                    label={translateKey("form-text-care-box-content-quantity")}
                                                    containerClass="counter-container-class"
                                                    counterClass="counter-class"
                                                    disabled={disabledPflegeboxFields}
                                                    contentWrapperClass="col-12 col-md-2"
                                                    setTypeChangeToParent={this.setTypeChange}
                                                    field={quantityOfProductTwo}
                                                />
                                            </>
                                        }
                                        {productTwo.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_one_time_gloves_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductTwoPrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-five"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productFive}
                                                text={translateKey("form-text-care-box-content-five")}
                                                transKey="form-text-care-box-content-five"
                                                keyName="is_disposable_masks"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productFive.value &&
                                            <Counter
                                                keyName="is_number_m"
                                                validationErrors={quantityOfProductFive.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductFive}
                                            />
                                        }
                                        {productFive.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_disposable_masks_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductFivePrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-six"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productSix}
                                                text={translateKey("form-text-care-box-content-six")}
                                                transKey="form-text-care-box-content-six"
                                                keyName="is_disposable_masks_ffp2"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productSix.value &&
                                            <Counter
                                                keyName="is_number_m_ffp"
                                                validationErrors={quantityOfProductSix.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductSix}
                                            />
                                        }
                                        {productSix.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_disposable_masks_ffp2_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductSixPrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-ffp2-set"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productFFP2Set}
                                                text={translateKey("form-text-care-box-content-ffp2-set")}
                                                transKey="form-text-care-box-content-ffp2-set"
                                                keyName="is_disposable_masks_ffp2_set"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productFFP2Set.value &&
                                            <Counter
                                                keyName="is_number_ffp_set"
                                                validationErrors={quantityOfProductFFP2Set.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductFFP2Set}
                                            />
                                        }
                                        {productFFP2Set.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_disposable_masks_ffp2_set_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductFFP2SetPrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-three"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productThree}
                                                text={translateKey("form-text-care-box-content-three")}
                                                transKey="form-text-care-box-content-three"
                                                keyName="is_hand_sanitizer"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productThree.value &&
                                            <Counter
                                                keyName="is_number_dr"
                                                validationErrors={quantityOfProductThree.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductThree}
                                            />
                                        }
                                        {productThree.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_hand_sanitizer_500_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductThreePrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-eleven"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productEleven}
                                                text={translateKey("form-text-care-box-content-eleven")}
                                                transKey="form-text-care-box-content-eleven"
                                                keyName="is_hand_sanitizer_1000"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productEleven.value &&
                                            <Counter
                                                keyName="is_number_dr1000"
                                                validationErrors={quantityOfProductEleven.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductEleven}
                                            />
                                        }
                                        {productEleven.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_hand_sanitizer_1000_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductElevenPrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-four"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productFour}
                                                text={translateKey("form-text-care-box-content-four")}
                                                transKey="form-text-care-box-content-four"
                                                keyName="is_surface_disinfectant"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productFour.value &&
                                            <Counter
                                                keyName="is_number_dp"
                                                validationErrors={quantityOfProductFour.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductFour}
                                            />
                                        }
                                        {productFour.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_surface_disinfectant_500_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductFourPrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-ten"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productTen}
                                                text={translateKey("form-text-care-box-content-ten")}
                                                transKey="form-text-care-box-content-ten"
                                                keyName="is_surface_disinfectant_1000"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productTen.value &&
                                            <Counter
                                                keyName="is_number_dp1000"
                                                validationErrors={quantityOfProductTen.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductTen}
                                            />
                                        }
                                        {productTen.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_surface_disinfectant_1000_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductTenPrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-thirteen"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productThirteen}
                                                text={translateKey("form-text-care-box-content-thirteen")}
                                                transKey="form-text-care-box-content-thirteen"
                                                keyName="is_disinfecting_wipes"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productThirteen.value &&
                                            <Counter
                                                keyName="is_number_ch"
                                                validationErrors={quantityOfProductThirteen.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductThirteen}
                                            />
                                        }
                                        {productThirteen.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_disinfecting_wipes_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductThirteenPrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-seven"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productSeven}
                                                text={translateKey("form-text-care-box-content-seven")}
                                                transKey="form-text-care-box-content-seven"
                                                keyName="is_protective_aprons"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productSeven.value &&
                                            <Counter
                                                keyName="is_number_f"
                                                validationErrors={quantityOfProductSeven.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductSeven}
                                            />
                                        }
                                        {productSeven.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_protective_aprons_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductSevenPrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-twelve"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productTwelve}
                                                text={translateKey("form-text-care-box-content-twelve")}
                                                transKey="form-text-care-box-content-twelve"
                                                keyName="is_reusable_protective_aprons"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productTwelve.value &&
                                            <Counter
                                                keyName="is_number_wfo"
                                                validationErrors={quantityOfProductTwelve.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductTwelve}
                                            />
                                        }
                                        {productTwelve.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_reusable_protective_aprons_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductTwelvePrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-one"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productOne}
                                                text={translateKey("form-text-care-box-content-one")}
                                                transKey="form-text-care-box-content-one"
                                                keyName="is_bed_protectors"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productOne.value &&
                                            <Counter
                                                keyName="is_number_o"
                                                validationErrors={quantityOfProductOne.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductOne}
                                            />
                                        }
                                        {productOne.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_bed_protectors_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductOnePrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-nine"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productNine}
                                                text={translateKey("form-text-care-box-content-nine")}
                                                transKey="form-text-care-box-content-nine"
                                                keyName="is_disposable_finger_overlay"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productNine.value &&
                                            <Counter
                                                keyName="is_number_njp"
                                                validationErrors={quantityOfProductNine.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductNine}
                                            />
                                        }
                                        {productNine.value && pflegeboxType.value === baseData.individualCareBoxId &&
                                            <>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-price")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {parseInt(careBoxIndividualPrices.is_disposable_finger_overlay_return)} EUR
                                                    </strong>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                <span className="quick-label-position-fix">
                                                    {translateKey("form-text-care-box-summ")}
                                                </span><br/>
                                                    <strong className="care-box-price">
                                                        {quantityOfProductNinePrice} EUR
                                                    </strong>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {pflegeboxType.value === baseData.individualCareBoxId &&
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-10"></div>
                                            <div className="col-12 col-md-2">
                                            <span className="quick-label-position-fix">
                                                {translateKey("form-text-care-box-summ")}
                                            </span><br/>
                                                <strong
                                                    className={`care-box-price ${(fullPrice <= limitAmountLower || fullPrice > limitAmountUpper) ? 'to-low' : 'correct'}`}>
                                                    {fullPrice} EUR
                                                </strong>
                                            </div>
                                            <div className="col-12">
                                                {fullPrice <= limitAmountLower &&
                                                    <div className="care-box-error">
                                                        {translateKey("form-text-care-box-sum-lower-error")}
                                                    </div>
                                                }
                                                {fullPrice > limitAmountUpper &&
                                                    <div className="care-box-error">
                                                        {translateKey("form-text-care-box-sum-upper-error")}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="row newUI-section-padding min-height-100">
                                        <div className="col-sm-12 col-lg-6 d-flex">
                                            <CheckBoxSmallMultipleComponent
                                                newUI={isNewUI}
                                                className={classForCheckBox}
                                                fieldName={"form-text-care-box-content-eight"}
                                                setTypeChangeToParent={this.setTypeChangeWithCheckboxReset}
                                                field={productEight}
                                                text={translateKey("form-text-care-box-content-eight")}
                                                transKey="form-text-care-box-content-eight"
                                                keyName="is_reusable_bed_protectors"
                                                disabled={disabledPflegeboxFields}
                                            />
                                        </div>
                                        {productEight.value &&
                                            <Counter
                                                keyName="is_number_protectors"
                                                validationErrors={quantityOfProductEight.validationErrors}
                                                showValidation={showValidation}
                                                label={translateKey("form-text-care-box-content-quantity")}
                                                containerClass="counter-container-class"
                                                counterClass="counter-class"
                                                disabled={disabledPflegeboxFields}
                                                contentWrapperClass="col-12 col-md-2"
                                                setTypeChangeToParent={this.setTypeChange}
                                                field={quantityOfProductEight}
                                                maxValue={4}
                                            />
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </fieldset>
                </form>
                {!this.state.hideNavigation && this.isFormValid(fullPrice) &&
                    <SectionNavigationButtonsComponent
                        analizeFinished={analizeFinished}
                        currentSection={currentSection}
                        rerender={rerender}
                        previousPageClick={previousPageClick}
                        nextPageClick={nextPageClick}
                        finishMe={finishMe}
                        form={form}
                        showNotePop={false}
                    />
                }
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        cities: state.enums.citiesList,
        citiesStatus: state.enums.citiesListStatus,
        healthIncuranceList: state.enums.healthIncuranceList,
        healthIncuranceListStatus: state.enums.healthIncuranceListStatus,
        leadsData: state.database.leads,
        careBoxSets: state.database.careBoxSets,
        careBoxSetsList: state.database.careBoxSetsList,
        gloveType: state.database.gloveType,
        careBoxIndividualPrices: state.database.careBoxIndividualPrices
    };
}

const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionCareBox));