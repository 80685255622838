
import { SET_FONT_SIZE } from "../actions/font-types";

const initialState = {
  fontSize: 'small',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FONT_SIZE:
      return {
        ...state,
        fontSize: action.fontSize,
      };
    default:
      return state;
  }
};

export default reducer;