import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import produce from "immer";
import SectionNavigationButtonsComponent from "../../form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
const isRodoFirstChoiceKey = "rodo_first_choice";

class CareBoxRodoSection extends React.Component {
    _isMounted = false;
    timeOutHelper;
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-care-box-rodo'),
            checkAll: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.waitForDataToBeReady()
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };

    waitForDataToBeReady = () =>{
        const { firstInit, toggleFirstInit, formLoaded } = this.props;
        if(formLoaded && firstInit){
            toggleFirstInit();
            this.getInitialData();
        }else{
            this.timeOutHelper = setTimeout(() => {
                this.waitForDataToBeReady();
            }, 100);
        }
    }

    getInitialData (){
        const { section } = this.state;
        const { form } = this.props;
        const contactId = this.props.contactId;
        const patientId = this.props.patientId;
        const patientName = form.getFieldByKey('is_first_name_and_last_name_of_patient').value;
        section.getFieldByKey('is_contactid').value = contactId;
        section.getFieldByKey('is_name').value = 'Care box dla ' + patientName;
        section.getFieldByKey('is_patientid').value = patientId;

        let valueOfField =  null;
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(isRodoFirstChoiceKey);
            if (field) {
                field.value = valueOfField;
            }
        });
        if (this._isMounted) {
            this.setState(nextState);
        }
    }

    setTypeChange = (key, value) => {
        if(!this.props.disabled){
            let nextState
            if (key === 'marketing_zustimmungen_care_box') {
                nextState = produce(this.state, draftState => {
                    const { section } = draftState;
                    const rodo1 = section.getFieldByKey('is_data_processing_agreement'); //TO DO change
                    const rodo2 = section.getFieldByKey('is_marketing_agreement'); //TO DO change
                    if (rodo1 && rodo2) {
                        this.state.checkAll = value;
                        rodo1.value = value;
                        rodo2.value = value;
                    }
                });
            } else {
                nextState = produce(this.state, draftState => {
                    const { section } = draftState;
                    const field = section.getFieldByKey(key);
                    if (field) {
                        field.value = value;
                    }
                });
            }
            this.setState(nextState);
        }
    };

    render() {
        const {
          disabled,
          subsectionName,
          translateKey,
          rodoDisabled,
          showValidationErrors,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const isDataProcessingAgreement = section.getFieldByKey("is_data_processing_agreement");
        const isMarketingAgreement = section.getFieldByKey("is_marketing_agreement");
        const disabledField = disabled || rodoDisabled;
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper container">
                    {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabledField}>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <div className="col-sm-12 rodo-description">
                                    <div className="rodo-section">
                                        <div>{translateKey('form-text-care-box-rodo-paragraph1')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="newUI-section-padding">
                            <div className="row">
                                <CheckBoxComponent 
                                    newUI={true} 
                                    disabled={disabledField}
                                    checkClassWrapper={'newUI-radio-check-box-class'} 
                                    className="col-sm-12 col-lg-12" 
                                    showValidation={false} 
                                    setTypeChangeToParent={this.setTypeChange} 
                                    value={this.state.checkAll} 
                                    validationErrors={false} 
                                    text={translateKey("form-text-care-box-rodo-checkbox1")}
                                    transKey="form-text-care-box-rodo-checkbox1" 
                                    keyName="marketing_zustimmungen_care_box"
                                />
                            </div>
                            <div className="row">
                            <div className="col-sm-1 col-lg-1"></div>
                                <CheckBoxComponent 
                                    newUI={true} 
                                    disabled={disabledField}
                                    checkClassWrapper={'newUI-radio-check-box-class'} 
                                    className="col-sm-11 col-lg-11"
                                    showValidation={showValidationErrors} 
                                    setTypeChangeToParent={this.setTypeChange} 
                                    value={isDataProcessingAgreement.value?isDataProcessingAgreement.value:false} 
                                    validationErrors={isDataProcessingAgreement.validationErrors} 
                                    text={translateKey("form-text-care-box-rodo-checkbox2")}
                                    transKey="form-text-care-box-rodo-checkbox2" 
                                    keyName="is_data_processing_agreement"
                                    field={isDataProcessingAgreement}
                                />
                            </div>
                            <div className="row">
                                <div className="col-sm-1 col-lg-1"></div>
                                <CheckBoxComponent 
                                    newUI={true} 
                                    disabled={disabledField}
                                    checkClassWrapper={'newUI-radio-check-box-class'} 
                                    className="col-sm-11 col-lg-11" 
                                    showValidation={showValidationErrors} 
                                    setTypeChangeToParent={this.setTypeChange} 
                                    value={isMarketingAgreement.value?isMarketingAgreement.value:false} 
                                    validationErrors={isMarketingAgreement.validationErrors} 
                                    text={translateKey("form-text-care-box-rodo-checkbox3")}
                                    transKey="form-text-care-box-rodo-checkbox3" 
                                    keyName="is_marketing_agreement"
                                    field={isMarketingAgreement}
                                />
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <div className="col-sm-12 rodo-description">
                                    <div className="rodo-section">
                                        <div>{translateKey('form-text-care-box-rodo-paragraph2')}</div>
                                        <div>{translateKey('form-text-care-box-rodo-paragraph3')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={false}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(CareBoxRodoSection));