import React from "react";

class TimePickerMeetingComponent extends React.Component {
  getMeHours() {
    let myHours = new Date(this.props.value).getHours();
    if (myHours < 10) {
      myHours = "0" + myHours;
    }
    return myHours;
  }

  getMeMinutes() {
    let myMinutes = new Date(this.props.timestamp).getMinutes();
    let intervals = Math.floor(myMinutes / 15);
    if (myMinutes % 15 !== 0) intervals++;
    if (intervals === 4) {
      intervals = 0;
    }
    myMinutes = intervals * 15;
    if (myMinutes < 10) {
      myMinutes = "0" + myMinutes;
    }
    return myMinutes;
  }

  getMyMinutes = (min) => {
    let minutes = min;
    let oldDate = new Date(this.props.timestamp);
    const newDateTime = new Date(
      oldDate.getFullYear(),
      oldDate.getMonth(),
      oldDate.getDate(),
      oldDate.getHours(),
      minutes,
      0,
      0
    );
    this.props.changePropsDT(newDateTime);
  };

  getMyHours = (hour) => {
    let hours = hour;
    let oldDate = new Date(this.props.timestamp);
    const newDateTime = new Date(
      oldDate.getFullYear(),
      oldDate.getMonth(),
      oldDate.getDate(),
      hours,
      oldDate.getMinutes(),
      0,
      0
    );
    this.props.changePropsDT(newDateTime);
  };

  getMeHoursUI() {
    let tableUI = [];
    for (let iteration = 1; iteration <= 12; iteration++) {
      tableUI.push(
        <span
          key={`hours-key-prop-${iteration}`}
          className="dropdown-item myDropdown-item-meetings "
          onClick={() => this.getMyHours(iteration)}
        >
          {iteration}
        </span>
      );
    }
    return tableUI;
  }
  getMeHoursUI2() {
    let tableUI = [];
    for (let iteration = 13; iteration <= 24; iteration++) {
      tableUI.push(
        <span
          key={`hours-key-prop-${iteration}`}
          className="dropdown-item myDropdown-item-meetings "
          onClick={() => this.getMyHours(iteration)}
        >
          {iteration}
        </span>
      );
    }
    return tableUI;
  }

  getMeMinutesUI() {
    let tableUI = [];
    for (
      let minutesIteration = 0;
      minutesIteration <= 45;
      minutesIteration += 15
    ) {
      tableUI.push(
        <span
          key={`minutes-key-prop-${minutesIteration}`}
          className="dropdown-item myDropdown-item-meetings "
          onClick={() => this.getMyMinutes(minutesIteration)}
        >
          {minutesIteration === 0 ? "00" : minutesIteration}
        </span>
      );
    }
    return tableUI;
  }

  render() {
    return (
      <React.Fragment>
        <div className="col-sm-24 col-lg-12 justify-content-center stunde">
          {this.props.transStunde}
        </div>
        <div className="col-sm-24 col-lg-12 justify-content-center stunde">
          <div className="h-m">
            <button
              className="btn btn-secondary dropdown-toggle myBtnDpDwn-meetings mybtnSecodaryToggle-meetings"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {this.getMeHours()}
            </button>
            <div
              className="dropdown-menu dropdown-menu1-meetings newUI-text-color "
              aria-labelledby="dropdownMenuButton"
            >
              <div className="meetings-hour-outer">
                <div className="meetings-hour-inner">

                {this.getMeHoursUI()}
                </div>
                <div className="meetings-hour-inner">
                  {this.getMeHoursUI2()}
                </div>
              </div>
            </div>
          </div>
          <span className="beetwen-h-m">:</span>
          <div className="h-m">
            <button
              className="btn btn-secondary dropdown-toggle myBtnDpDwn-meetings mybtnSecodaryToggle-meetings"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {this.getMeMinutes()}
            </button>
            <div
              className="dropdown-menu dropdown-menu2-meetings newUI-text-color "
              aria-labelledby="dropdownMenuButton"
            >
              {this.getMeMinutesUI()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default TimePickerMeetingComponent;