import * as React from 'react';
import { MDBModal, MDBModalHeader, MDBModalBody} from 'mdbreact';

export class SurveyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
        };
    }

    render() {
        const { show, size, centeredVertical, survey} = this.props;
        return (  
            <MDBModal isOpen={show} fullHeight size={size} toggle={()=> {}} centered={centeredVertical}>
                 <MDBModalHeader toggle={this.editToggle} className="modal-survey-header">
                    <div className="close-modal-survey" onClick={() => this.props.closeModal()}></div>
                 </MDBModalHeader>
                <MDBModalBody>
                    <div className="surevey-modal-question-wrapper">
                        <div className="surevey-modal-question">{survey.Question1}</div>
                        <div className="surevey-modal-answer">
                            <div className={`survey-star-box survey-star-box-${survey.Answer1 ? survey.Answer1.charAt() : '0'}`}>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="surevey-modal-question-wrapper">
                        <div className="surevey-modal-question">{survey.Question2}</div>
                        <div className="surevey-modal-answer">
                            <div className={`survey-star-box survey-star-box-${survey.Answer2 ? survey.Answer2.charAt() : '0'}`}>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="surevey-modal-question-wrapper">
                        <div className="surevey-modal-question">{survey.Question3}</div>
                        <div className="surevey-modal-answer">
                            <div className={`survey-star-box survey-star-box-${survey.Answer3 ? survey.Answer3.charAt() : '0'}`}>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="surevey-modal-question-wrapper">
                        <div className="surevey-modal-question">{survey.Question4}</div>
                        <div className="surevey-modal-answer">
                            <div className={`survey-star-box survey-star-box-${survey.Answer4 ? survey.Answer4.charAt() : '0'}`}>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="surevey-modal-question-wrapper">
                        <div className="surevey-modal-question">{survey.Question5}</div>
                        <div className="surevey-modal-answer">{survey.Answer5}</div>
                    </div>
                    <div className="surevey-modal-question-wrapper">
                        <div className="surevey-modal-question">{survey.Question6}</div>
                        <div className="surevey-modal-answer">
                            <div className={`survey-star-box survey-star-box-${survey.Answer6 ? survey.Answer6.charAt() : '0'}`}>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="surevey-modal-question-wrapper">
                        <div className="surevey-modal-question">{survey.Question7}</div>
                        <div className="surevey-modal-answer">{survey.Answer7}</div>
                    </div>
                    <div className="surevey-modal-question-wrapper">
                        <div className="surevey-modal-question">{survey.Question8}</div>
                        <div className="surevey-modal-answer">
                            <div className={`survey-star-box survey-star-box-${survey.Answer8 ? survey.Answer8.charAt() : '0'}`}>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="surevey-modal-question-wrapper">
                        <div className="surevey-modal-question">{survey.Question9}</div>
                        <div className="surevey-modal-answer">{survey.Answer9}</div>
                    </div>
                    <div className="surevey-modal-question-wrapper">
                        <div className="surevey-modal-question">{survey.Question10}</div>
                        <div className="surevey-modal-answer">
                            <div className={`survey-star-box survey-star-box-${survey.Answer10 ? survey.Answer10 : '0'}`}>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                                <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </MDBModalBody>
            </MDBModal>
        )
    }
}