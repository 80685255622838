import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import MultiplePhonesComponent from '../../multiple-phones-component/MultiplePhonesComponent';
import SelectComponent from "../../form-bb-components/select-component/SelectComponent";
import * as IsContactPersonDataSameAs from '../../../_forms/BBForm/values/isContactPersonDataSameAs';
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import produce from "immer";

class SectionAngabenZurOrt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-1'),
            showValidation: props.showValidationErrors
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: this.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        if(!this.props.disabled){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });

            this.setState(nextState);
        }
    };

    render() {
        const {
          disabled,
          translateKey,
          form,
          sectionNumber,
          subsectionName,
          showValidationErrors,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          appLang
        } = this.props;
        const { section } = this.state;
        let isdisabledfields = disabled;
        const isRelationshipWithPatientOp = section.getFieldByKey('is_relationship_with_patient_op');
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        const isContactPersonMobilePhone = form.getFieldByKey('is_contact_person_mobile_phone');
        const isContactPersonHomePhone = form.getFieldByKey('is_contact_person_home_phone');
        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {isdisabledfields = true}
        const isNewUI = true;
        const phones = [
            {
                appLang: appLang,
                newUI: isNewUI,
                showValidation: showValidationErrors,
                className: 'input-wrapper form-margin-bottom-fix',
                setTypeChangeToParent: this.setTypeChange,
                field: isContactPersonHomePhone, 
                text: translateKey("new_phone_label"),
                transKey: 'new_phone_label',
                keyName: 'is_contact_person_home_phone'
            },
            {
                appLang: appLang,
                newUI: isNewUI,
                showValidation: showValidationErrors,
                className: 'input-wrapper form-margin-bottom-fix',
                setTypeChangeToParent: this.setTypeChange,
                field: isContactPersonMobilePhone, 
                text: translateKey("new_cellphone_label"),
                transKey: 'new_cellphone_label',
                keyName: 'is_contact_person_mobile_phone'
            }
        ];
        return (
          <div className="form-section-main">
            <div className="new-section-name-wrapper">
              {sectionNumber}. {subsectionName}
            </div>
            <form className="new-section-content-wrapper container form_serializer">
              <fieldset disabled={isdisabledfields}>
                <div className="row">
                  <InputTypeTextComponent
                    newUI={isNewUI}
                    showValidation={showValidationErrors}
                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                    setTypeChangeToParent={this.setTypeChange}
                    field={section.getFieldByKey("is_contact_person_lastname")}
                    text={translateKey("last_name")}
                    transKey="last_name"
                    keyName="is_contact_person_lastname"
                  />
                  <InputTypeTextComponent
                    newUI={isNewUI}
                    showValidation={showValidationErrors}
                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                    setTypeChangeToParent={this.setTypeChange}
                    field={section.getFieldByKey("is_contact_person_firstname")}
                    text={translateKey("first_name")}
                    transKey="first_name"
                    keyName="is_contact_person_firstname"
                  />
                  <InputTypeTextComponent
                    newUI={isNewUI}
                    showValidation={showValidationErrors}
                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                    setTypeChangeToParent={this.setTypeChange}
                    field={section.getFieldByKey("is_contact_person_email")}
                    text={translateKey("email")}
                    transKey="email"
                    keyName="is_contact_person_email"
                  />

                  <div className="col-sm-12 col-lg-6">
                      <MultiplePhonesComponent 
                          phones={phones}
                          showValidation={showValidationErrors}
                          errorMessage={translateKey("error_for_multiple_fields")}
                      />
                  </div>
                  <SelectComponent
                    newUI={isNewUI}
                    showValidation={showValidationErrors}
                    keyName="is_relationship_with_patient_op"
                    valuesList={[
                      {
                        value: "717700000",
                        label: translateKey("form-text-relationship-0"),
                      },
                      {
                        value: "717700001",
                        label: translateKey("form-text-relationship-1"),
                      },
                      {
                        value: "717700002",
                        label: translateKey("form-text-relationship-2"),
                      },
                      {
                        value: "717700003",
                        label: translateKey("form-text-relationship-3"),
                      },
                      {
                        value: "717700005",
                        label: translateKey("form-text-relationship-5"),
                      },
                      {
                        value: "717700006",
                        label: translateKey("form-text-relationship-6"),
                      },
                      {
                        value: "717700007",
                        label: translateKey("form-text-relationship-7"),
                      },
                      {
                        value: "717700008",
                        label: translateKey("form-text-relationship-9"),
                      },
                      { 
                        value: '717700010', 
                        label: translateKey('form-text-relationship-10')
                      },
                      {
                         value: '717700011',
                         label: translateKey('form-text-relationship-11')
                      },
                      {
                         value: '717700012',
                         label: translateKey('form-text-relationship-12')
                      },
                      {
                         value: '717700013',
                         label: translateKey('form-text-relationship-13')
                      }
                    ]}
                    value={isRelationshipWithPatientOp.value}
                    validationErrors={
                      isRelationshipWithPatientOp.validationErrors
                    }
                    label={translateKey("form-text-section-0-1")}
                    setTypeChangeToParent={this.setTypeChange}
                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                    isRequired={true}
                  />
                </div>
              </fieldset>
            </form>
            <SectionNavigationButtonsComponent
              form={form}
              analizeFinished={analizeFinished}
              currentSection={currentSection}
              rerender={rerender}
              previousPageClick={previousPageClick}
              nextPageClick={nextPageClick}
              finishMe={finishMe}
              showNotePop={disabled?false:true}
            />
          </div>
        );
    }
}

const mapStateToProps = state => {return {appLang: state.lang.appLanguage}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionAngabenZurOrt));