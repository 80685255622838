import * as React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { translateKey } from "../../_redux/actions/index"
import NotizComponent from './NotizComponent'
import {EmptyTableIcon} from '../../_components/index'
import * as MultiplierStatus from "../../_constance/enums/MultiplierStatus";

class MainPanelMultiplicators extends React.Component {
  _isMounted = false;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      edited: false,
    };
  }
  
  addMultiplierToCategory=(item)=>{
    const { form, activeCategoryId } = this.props;
    const activeCategoryData = form.getCategoryByKey(activeCategoryId);
    const exists = activeCategoryData.MultiplierIds.indexOf(item.id.toUpperCase());
    if(exists>-1){
      //add
      this.props.addMultiToCategory(item.id.toUpperCase(), false)
    }else{
      //remove
      this.props.addMultiToCategory(item.id.toUpperCase(), true)
    }
  }

  checkedMulti=(id)=>{
    const { form, activeCategoryId } = this.props;
    const activeCategoryData = form.getCategoryByKey(activeCategoryId);
    const exists = activeCategoryData.MultiplierIds.indexOf(id);
    if(exists>-1){
      return true;
    }else{
      return false;
    }
  }
  
  changeCategoryNote=(val)=>{
    this.props.changeNote(this.props.activeCategoryId, val);
  }

  getMeNameFullName(item){
    let fullName = '';
    if(item.firstName){
      fullName+=item.firstName;
      if(item.lastName){
        fullName+=' '+item.lastName;
      }
    }else{
      if(item.lastName){
        fullName+=item.lastName;
      }
    }
    return fullName;
  }
  
  getAllListElements(){
    const { form, activeCategoryId, multipliersData, translateKey } = this.props;
    if(activeCategoryId!=='0'){
      const activeCategoryData = form.getCategoryByKey(activeCategoryId);
      const multiToSelect = multipliersData.filter(element => {
        if(element.statusMultiplier){
          if(element.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
            let exsits = activeCategoryData.MultiCanBeSelected.indexOf(element.id.toUpperCase())
            if(exsits>-1){
              return true;
            }
          }
        }else{
          let exsits = activeCategoryData.MultiCanBeSelected.indexOf(element.id.toUpperCase())
          if(exsits>-1){
            return true;
          }
        }
        return false;
      });
      return (
        <div className="multiplikator-category-container">
          {
            multiToSelect.map((item,index)=>(
              <div key={item.id} className={`multiplikator-details-data ${index===0?"first":''}`} onClick={()=>this.addMultiplierToCategory(item)}>
                <div className="part-of-multiplicator-category checkbox">

                  <div className={`custom-control custom-checkbox custom-control-inline`}>
                    <i className={`fas fa-2x fa-check check-fa-checkbox-icon ${this.checkedMulti(item.id.toUpperCase())?'check-fa-active':'check-fa-not-active'}`}></i>
                    <input className="form-check-input" hidden type="checkbox" value={this.checkedMulti(item.id.toUpperCase())} />
                  </div>

                </div>

                <div className="part-of-multiplicator-category">
                  <div className="content-wrapper">
                    <i className="far fa-2x fa-user multiplier-details-icon-style"></i>
                    <div>
                      <span className="multiplikator-name-main-panel">{translateKey("ko-piloten-multiplikatorname").toUpperCase()}</span>
                      <div>{this.getMeNameFullName(item)}</div>
                      <div>{item.company?item.company:''}</div>
                    </div>
                  </div>
                </div>
                
                <div className="part-of-multiplicator-category">
                  <div className="content-wrapper">
                    <i className="fas fa-2x fa-map-marker-alt multiplier-details-icon-style"></i>
                    <div>
                      <span className="multiplikator-name-main-panel">{translateKey("address").toUpperCase()}</span>
                      <div>
                        {`${item.address.street?item.address.street:''} 
                        ${item.address.buildingNumber ? 
                          item.address.apartmentNumber?
                            item.address.buildingNumber+'/'+item.address.apartmentNumber
                            :
                            item.address.buildingNumber
                          : 
                          ''
                        }
                        `}
                      </div>
                      <div>
                        {`${item.address.postalCode?item.address.postalCode:''} ${item.address.city?item.address.city:''}`}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="part-of-multiplicator-category">
                  <div className="content-wrapper">
                    <i className="far fa-2x fa-address-card multiplier-details-icon-style"></i>
                    <div>
                      <span className="multiplikator-name-main-panel">{translateKey("ko-piloten-kontaktdaten").toUpperCase()}</span>
                      
                      {item.phone &&
                        <div>
                          {translateKey("ko-piloten-tel")+' '}
                          <a href={"tel:"+item.phone} target="_top">
                            {item.phone}
                          </a>
                        </div>
                      }

                      {item.cellPhone &&
                        <div>
                          {translateKey("ko-piloten-tel")+' '}
                          <a href={"tel:"+item.cellPhone} target="_top">
                            {item.cellPhone}
                          </a>
                        </div>
                      }

                      {item.email &&
                        <div>
                          {translateKey("ko-piloten-mail")+' '}
                          <a href={"mailto:"+item.email} target="_top">
                            {item.email}
                          </a>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      )
    }else{
     return (<div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => {}} text={translateKey("no_data_to_show")} /></div>)
    }
  };

  render() {
    const { form, activeCategoryId } = this.props;
    return (
      <div className="multi-tab-multi-select-list-wrapper">
        {activeCategoryId!=='0' &&
          <NotizComponent changeCateogryNote={(e)=>this.changeCategoryNote(e)} category={activeCategoryId!=='0'?form.getCategoryByKey(activeCategoryId):null}/>
        }
        {this.getAllListElements()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {return { multipliersData: state.database.multipliers }};
const mapDispatchToProps = (dispatch) => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPanelMultiplicators));