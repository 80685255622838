import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';

class TextAreaForMeetingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.html
    }
  }

  handleEditorChange = (content, editor) => {
    console.log(content);
    this.props.changeEditorData('meeting_email_content', content);
  }

  render() {
    return (
      <Editor
        apiKey="aw40i9eij2ffnwjrzimmzx7b6eycy6jtooanuw6nc0pfu8pw"
        initialValue={this.props.html}
        init={{
          height: 500,
          menubar: false,
          plugins: "autoresize",
          autoresize_bottom_margin: 10,
          object_resizing : "img",
          min_height: 500,
          max_height: 1500,
          statusbar: false,
          toolbar:'',
          resize: false,
          allow_script_urls: true,
          convert_urls: false,
          allow_unsafe_link_target: true
        }}
        tagName = 'p'
        resize = {false}
        onEditorChange={this.handleEditorChange}
      />
    );
  }
}
export default TextAreaForMeetingSection