import * as ValidationError from "../_forms/errors";
const ValidateWithErrorMessage = (val, rules) => {
  let isValidList = [];
  for (let rule in rules) {
    switch (rule) {
      case "isEmail":
        if (!emailValidator(val)) {
          isValidList.push(ValidationError.ERR_NOT_AN_EMAIL_ADDRESS);
        }
        break;
      case "minLength":
        if (!minLengthValidator(val, rules[rule])) {
          isValidList.push(ValidationError.ERR_TEXT_MIN_LENGTH);
        }
        break;
      case "maxLength":
        if (!maxLenghtValidator(val, rules[rule])) {
          isValidList.push(ValidationError.ERR_TEXT_MAX_LENGTH);
        }
        break;
      case "containsSpecialCharacters":
        if (!containsSpecialCharacters(val)) {
          isValidList.push(ValidationError.ERR_REQUIRED);
        }
        break;
      default:
    }
  }
  return isValidList;
};

const emailValidator = (val) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
};

const minLengthValidator = (val, minLength) => {
  if (val == null || val.trim().length === 0) {
    return false;
  } else {
    return val.trim().length >= minLength;
  }
};

const maxLenghtValidator = (val, maxLength) => {
  if (val === null || val === undefined) {
    return false;
  } else {
    return val.trim().length <= maxLength;
  }
};

const containsSpecialCharacters = (val) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,32}$/.test(
    val
  );
};

export default ValidateWithErrorMessage;
