export class FormNavigationActions{
    currentSection;
    savingData;
    showValidationErrors;
    isImportant;
    prevPageNumber;
    constructor(currentSec, saving, showValid, isImportant, prevPage){
        this.currentSection = currentSec;
        this.savingData = saving;
        this.showValidationErrors = showValid;
        this.isImportant = isImportant;
        this.prevPageNumber = prevPage;
    }
};