import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import produce from "immer";

class SectionAnwesendBei extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-anwesend-bei'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({showValidation: showValidationErrors});
        }
    }

    setTypeChange = (key, value) => {
        if(!this.props.disabled){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });
    
            this.setState(nextState);
        }
    };

    render() {
        const {
          disabled,
          translateKey,
          showValidationErrors,
          isImportant,
          subsectionName,
          sectionNumber,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const isCustomer = section.getFieldByKey('is_customer');
        const isRelative = section.getFieldByKey('is_relative');
        const isCarer = section.getFieldByKey('is_carer');
        const checboxClass="col-sm-12 col-lg-3";
        const classForCheckBox = "newUI-radio-check-box-class centered";
        const isNewUI = true; 

        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                    <div className="newUI-section-padding"> 
                            <div className="checkboxes_wrapper row justify-content-center">

                                <CheckBoxComponent 
                                    newUI={isNewUI} 
                                    isImportant={isImportant&&isCustomer.checkImportant} 
                                    checkClassWrapper={classForCheckBox} 
                                    showValidation={showValidationErrors} 
                                    className={checboxClass} setTypeChangeToParent={this.setTypeChange} 
                                    value={isCustomer.value} 
                                    validationErrors={isCustomer.validationErrors} 
                                    text={translateKey("client")} 
                                    transKey="is_customer" 
                                    keyName="is_customer"
                                />

                                <CheckBoxComponent 
                                    newUI={isNewUI} 
                                    isImportant={isImportant&&isRelative.checkImportant} 
                                    checkClassWrapper={classForCheckBox} 
                                    showValidation={showValidationErrors} 
                                    className={checboxClass} 
                                    setTypeChangeToParent={this.setTypeChange} 
                                    value={isRelative.value} 
                                    validationErrors={isRelative.validationErrors} 
                                    text={translateKey("angehorige")} 
                                    transKey="is_relative" 
                                    keyName="is_relative"
                                />
                                
                                <CheckBoxComponent 
                                    newUI={isNewUI} 
                                    isImportant={isImportant&&isCarer.checkImportant} 
                                    checkClassWrapper={classForCheckBox} 
                                    showValidation={showValidationErrors} 
                                    className={checboxClass} 
                                    setTypeChangeToParent={this.setTypeChange} 
                                    value={isCarer.value} 
                                    validationErrors={isCarer.validationErrors} 
                                    text={translateKey("pflegekraft")} 
                                    transKey="is_carer" 
                                    keyName="is_carer"
                                />
                            
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(SectionAnwesendBei));