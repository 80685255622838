import SortingElement from "./sortingElement";
const SortingLeadsList = [
    new SortingElement(
        "ASC",
        'lead',
        false,
        "text",
        'lead',
        'leads_lead',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patient',
        false,
        "text",
        'patient',
        'leads_patient',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'dateOfEntry',
        true,
        "date",
        'date_of_lead',
        'leads_date_of_lead',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'serviceTypeDe',
        false,
        "text",
        'service_type',
        'leads_service_type_de',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'typeText',
        false,
        "status",
        'status',
        'leads_status',
        true,
        false,
        null
    ),

    new SortingElement(
        "ASC",
        'statusCareBox',
        false,
        "text",
        'care-box-status',
        'leads_status_cb',
        true,
        false,
        null
    ),

    new SortingElement( 
        "ASC",
        'source',
        false,
        "text",
        'source_of_acquisition',
        'leads_source_of_acquisition',
        true,
        false,
        null
    ),

    new SortingElement( 
        "ASC",
        'sourceFp',
        false,
        "text",
        'source_of_acquisition_fp',
        'leads_source_of_acquisition_fp',
        true,
        false,
        null
    ),


    new SortingElement(
        "ASC",
        'client.cellPhone',
        false,
        "text",
        'client_cellPhone',
        'leads_client_cellPhone',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'client.phone',
        false,
        "text",
        'client_phone',
        'leads_client_phone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'phone',
        false,
        "text",
        'client_telephone',
        'leads_client_telephone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'client.email',
        false,
        "text",
        'client_email_address',
        'leads_client_email',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'client.address.city',
        false,
        "text",
        'client_city',
        'leads_client_city',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'client.address.postalCode',
        false,
        "text",
        'client_postal_code',
        'leads_client_postal_code',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'client.address.street',
        false,
        "text",
        'client_street',
        'leads_client_street',
        false,
        false,
        null
    ),


    new SortingElement(
        "ASC",
        'patientObj.cellPhone',
        false,
        "text",
        'patient_cellPhone',
        'leads_patient_cellPhone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patientObj.phone',
        false,
        "text",
        'patient_phone',
        'leads_patient_phone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patientPhone',
        false,
        "text",
        'patient_telephone',
        'leads_patient_telephone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patientObj.email',
        false,
        "text",
        'patient_email_address',
        'leads_patient_email',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patientObj.address.city',
        false,
        "text",
        'patient_city',
        'leads_patient_city',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patientObj.address.postalCode',
        false,
        "text",
        'patient_postal_code',
        'leads_patient_postal_code',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'patientObj.address.street',
        false,
        "text",
        'patient_street',
        'leads_patient_street',
        false,
        false,
        null
    ),

    new SortingElement(
        "ASC",
        'leadExclusiveForPm24',
        false,
        "text",
        'lead_exclusive',
        'leads_exclusive',
        false,
        false,
        null
    )
];

export default class SortingLeads {
    sortingColumns = [];

    constructor() {
        this.sortingColumns = SortingLeadsList;
    }

    getColumnByKey(key) {
        return this.sortingColumns.find(column => column.keyName === key);
    }

    getColumnByColumn(columnName) {
        return this.sortingColumns.find(column => column.column === columnName);
    }

    changeColumnByColumn(columnName) {
        this.sortingColumns.forEach(column => {
            if(column.column === columnName){
                if(column.active===true){
                    column.type==='ASC'? column.type="DESC" : column.type='ASC';
                }else{
                    column.type='ASC';
                    column.active = true;
                }
            }else{
                column.type='ASC';
                column.active = false;
            }
        });
    }

    changeColumnByColumnSortType(columnName, type) {
        this.sortingColumns.forEach(column => {
            if(column.column === columnName){
                if(column.active===true){
                    column.type=type;
                }else{
                    column.type=type;
                    column.active = true;
                }
            }
        });
    }

    isColumnActive(key){
        return this.sortingColumns.find(column => column.keyName === key && column.acitve === true);
    }

    setColumnEnabled (enabled, key){
        for(let i=0; i<this.sortingColumns.length; i++){
            if(this.sortingColumns[i].keyName===key){
                this.sortingColumns[i].enabled = enabled;
                break
            }
        }
    }

    isColumnVisibleByKey(key, selectedLang) {
        return this.sortingColumns.find(column => {
            if(column.keyName === key && column.blocked === false && column.enabled === true){
                if(column.languageRestrictions!==null){
                    if(selectedLang === column.languageRestrictions){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return true;
                }
            }else{
                return false;
            }
        });
    }
}