class Fee {
  id;
  category;
  label;
  label_crm;
  price;
  subtitleKeys;
  listItemKeys;
  video;

  constructor ({
    id,
    category,
    label,
    label_crm, 
    price,
    subtitleKeys,
    listItemKeys,
    video
  }) {
    this.id = id;
    this.category = category;
    this.label = label;
    this.label_crm = label_crm;
    this.price = price;
    this.subtitleKeys = subtitleKeys;
    this.listItemKeys = listItemKeys;
    this.video = video;
  }
}

export default Fee;