import * as React from "react";
import {connect} from 'react-redux';
import {Prompt, withRouter} from 'react-router-dom';
import {
    authSetActionTimestamp,
    getInvoices,
    getPatientClient,
    getTasks,
    pendingClientsData,
    pendingPatientClientData,
    removeClientsData,
    removePatientClientData,
    setClientsData,
    setPatientClientData,
    setTasksData,
    translateKey
} from "../../_redux/actions/index";
import {EmptyTableIcon, LoaderTable, NavBar, SideBar, TableProperties} from "../../_components/index";
import {Alert, CarerTable, ClientTable, ContractTable} from "../../_constance/classes/index";
import {IconsMap} from "../../_helpers/index";
import {ResponseStatus} from '../../_helpers/response_status'
import axiosAuth from "../../_services/config/axios-auth";
import {
    ClientCarersData,
    ClientClientData,
    ClientContractsData,
    ClientInvoicesData,
    ClientNotesData,
    ClientPatientCareBoxData,
    ClientPatientCareBoxIngredientsData,
    ClientPatientData,
    ClientTasksData
} from "../../_components/ClientDetailsComponenets/index";
import {TableEnums} from "../../_constance";
import * as RecordType from "../../_constance/values/recordType";
import * as LanguageSelect from "../../_constance/values/languageSelect";
import {baseData} from '../../_constance/base_data';
import * as Entity from '../../_forms/BBForm/values/entity';
import {getDynamicFields} from '../../_services/user_service';

class ClientDetailsPage extends React.Component {
    _isMounted = false;
    alertD = Alert;
    myTimout;
    taskTimeOut;
    invoiceTimeOut;
    patientTimeout;

    constructor(props) {
        super(props);
        this.state = {
            activeTab: "client",
            clientData: null,
            clientDataBackup: null,
            errorComponent: Alert,
            errorsMessage: null,
            errorsType: "danger",
            loadingBasics: true,
            clientRetries: 0,
            loadingPatients: true,
            loadingCareBoxClient: true,
            loadingCareBoxClientBoxes: true,
            loadingCareBoxClientBoxIngredients: true,
            careBoxesData: [],
            careBoxIngredients: null,
            selectedPatient: 0,
            selectedClient: 0,
            notesData: [],
            loadingNotes: true,
            loadingTasks: true,
            loadingInvoices: true,
            carerData: [],
            loadingCarers: true,
            id: 0,
            contractsData: [],
            loadingContracts: false,
            showAddNoteModal: false,
            selectedCarerKey: 0,
            isServiceType: null,
            isUsesApp: null,
            breadCrumbs: [
                {
                    link: "clients",
                    key: "clients",
                    name: null
                }
            ],
            aditionalData: {
                isPrimaryProduct: null,
                isDrivingLicence: null,
                isSecondOoo: null,
                isAdditionalForeignLanguage: null,
                isTransfer: null,
                isCarerWithDiploma: null,
                isAdditionalPersonInHouse: null,
            }
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.remove('modal-open');
        window.scrollTo(0, 0);
        this.myTimeout = setTimeout(() => {
            this.getDataBasic()
        }, 200);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
        clearTimeout(this.invoiceTimeOut);
        clearTimeout(this.notesTimeout);
        clearTimeout(this.taskTimeOut);
        clearTimeout(this.patientTimeout);
    };

    setActiveTab(tabName) {
        if (this._isMounted) {
            this.setState({activeTab: tabName})
        }
    }

    setSelectedPatient(clientId, patientId) {
        if (this._isMounted) {
            this.setState({
                selectedClient: clientId,
                selectedPatient: patientId
            })
        }
    }

    ////// BASIC CLIENT TAB DATA LOAD

    getInitialDataTime() {
        if (this.props.clientsData.length > 0) {
            clearTimeout(this.myTimeout);
            this.getClientDataBasic();
        } else {
            if (this.state.clientRetries < 10) {
                this.getClientsList();
                if (this._isMounted) {
                    this.setState({clientRetries: this.state.clientRetries + 1})
                }
                this.myTimeout = setTimeout(() => {
                    this.getInitialDataTime()
                }, 300);
            } else {
                if (this._isMounted) {
                    this.setState({
                        clientRetries: 0,
                        loadingBasics: false
                    })
                }
            }
        }
    }

    getClientDataBasic() {
        const {match: {params}} = this.props;
        let found = false;
        for (let i = 0; i < this.props.clientsData.length; i++) {
            if (this.props.clientsData[i].id === params.id) {
                found = true;
                if (this._isMounted) {
                    this.setState({
                        loadingBasics: false,
                        clientRetries: 0,
                        clientData: JSON.parse(JSON.stringify(this.props.clientsData[i])),
                        id: params.id,
                        breadCrumbs: [
                            {
                                link: "clients",
                                key: "clients",
                                name: null
                            },
                            {
                                link: "",
                                key: "",
                                name: this.props.clientsData[i].client
                            }
                        ]
                    })
                }
                this.getInitialPatient();
                this.getBasicNotes();
                this.getBasicTasksData();
                this.getBasicInvoiceData();
                this.getBasicCarersData();
                this.getBasicContractsData();
                this.getServiceData();
                this.getAditionalData();
                break;
            }
        }
        if (!found) {
            if (this._isMounted) {
                this.setState({
                    clientData: null,
                    loadingBasics: false,
                    loadingContracts: false,
                    loadingCarers: false,
                    loadingInvoices: false,
                    loadingPatients: false,
                    loadingNotes: false,
                    loadingTasks: false,
                    loadingCareBoxClient: false,
                })
            }
        }
    }

    getClientsList = () => {
        if (this.props.clientsState !== ResponseStatus.PENDING) {
            this.props.pendingRequestClient();
            axiosAuth.get("clients/list/0")
                .then(res => {
                    if (res.status === 200) {
                        return res;
                    } else {
                        return Promise.reject(res.status);
                    }
                })
                .then(json => {
                    return json.data
                })
                .then(res => {
                    let mapedRes = res.map((element) => {
                            for (let i = 0; i < this.props.patientData.length; i++) {
                                if (this.props.patientData[i].clientId === element.id) {
                                    element.locationP = this.props.patientData[i].patientAddress ? this.props.patientData[i].patientAddress.city : '';
                                    element.patientName = this.props.patientData[i].name;
                                    element.patient = this.props.patientData[i]
                                }
                            }
                            return new ClientTable(element);
                        }
                    );
                    this.props.setClientData(mapedRes);
                })
                .catch((error) => {
                    this.props.clearDataClient();
                });
        }
    }

    getPatientList() {
        this.props.pendingPatientData();
        axiosAuth.get("patients/list/CLIENT/0/" + this.props.appLang)
            .then(res => {
                if (res.status === 200) {
                    return res;
                } else {
                    return Promise.reject(res.status);
                }
            })
            .then(json => {
                return json.data
            })
            .then(res => {
                this.props.setPatientData(res);
                this.getInitialDataTime();
            })
            .catch((error) => {
                this.props.clearPatientData();
                this.getInitialDataTime();
            });
    }

    getDataBasic() {
        if (this._isMounted) {
            this.setState({loadingBasics: true});
        }
        if (this.props.patientData.length === 0) {
            if (this.props.patientData.length !== 0 && this.props.patientData !== undefined) {
                this.getInitialDataTime();
            } else {
                this.getPatientList();
            }
        } else {
            this.getInitialDataTime();
        }
    }


    ///// BASIC PATIENT TAB DATA LOAD

    getInitialPatient() {
        if (this.props.patientClientStatus === ResponseStatus.READY) {
            clearTimeout(this.patientTimeout);
            if (this._isMounted) {
                this.setState({
                    loadingPatients: false,
                    loadingCareBoxClient: false,
                })
            }
        } else {
            if (this.props.patientClientStatus === ResponseStatus.FREE) {
                this.props.getPatientClientData(this.props.appLang);
                this.patientTimeout = setTimeout(() => {
                    this.getInitialPatient()
                }, 500);
            } else {
                if (this.props.patientClientStatus === ResponseStatus.ERROR) {
                    clearTimeout(this.patientTimeout);
                    if (this._isMounted) {
                        this.setState({
                            loadingPatients: false,
                            loadingCareBoxClient: false,
                        })
                    }
                } else {
                    this.patientTimeout = setTimeout(() => {
                        this.getInitialPatient()
                    }, 500);
                }
            }
        }
    }

    getPatientCareBoxes(clientId, patientId) {
        this.setSelectedPatient(clientId, patientId);
        this.setActiveTab("careBoxClientBoxes")
        if (this._isMounted) {
            this.setState({loadingCareBoxClientBoxes: true})
        }
        axiosAuth.get("patient/careBoxApps/" + patientId)
            .then(res => {
                return res.data;
            })
            .then(careBoxesRes => {
                if(careBoxesRes.length > 0) {
                    let dynamicRequest = this.buildCareBoxesRequest(careBoxesRes);
                    getDynamicFields(dynamicRequest)
                        .then(dynamicRes => {
                            this.finishCareBoxes(careBoxesRes, dynamicRes);
                        })
                        .catch(error => {
                            this.finishCareBoxesWithError(error);
                        });
                } else {
                    this.finishCareBoxes([], []);
                }
            }).catch((error) => {
            this.finishCareBoxesWithError(error);
        });
    }

    buildCareBoxesRequest(res) {
        let dynamicRequest = [];
        res.forEach(v => {
            dynamicRequest.push({
                Id: v.Id,
                Entity: Entity.CARE_BOX,
                Fields: [
                    "is_status",
                    "is_date_of_acceptance",
                    "is_delivery_to_address",
                ]
            });
            dynamicRequest.push({
                Id: v.CareBoxIngridientId,
                Entity: Entity.CARE_BOX_INGRIDIENTS,
                Fields: [
                    "is_status",
                    "is_package_number",
                    "is_lading_number",
                    "is_care_box_setid",
                    "is_delivery_date",
                    "is_care_box_shipment_date"
                ]
            });
        });
        return dynamicRequest;
    }

    finishCareBoxes(items, dynamicRes) {
        let data = [];
        let ingredientsResMap = Object.fromEntries(dynamicRes.filter(e => e.entity === Entity.CARE_BOX_INGRIDIENTS).map(e => [e.id, e.fields]));
        let careBoxResMap = Object.fromEntries(dynamicRes.filter(e => e.entity === Entity.CARE_BOX).map(e => [e.id, e.fields]));

        items.forEach(value => {
            let ingredients = ingredientsResMap[value.CareBoxIngridientId];
            let careBox = careBoxResMap[value.Id];
            let result = value;
            result['ingredients'] = [ingredients];
            if(ingredients !== undefined && ingredients !== null) {
                careBox.is_care_box_shipment_date = ingredients.is_care_box_shipment_date;
                careBox.is_delivery_date = ingredients.is_delivery_date;
            } else {
                careBox.is_care_box_shipment_date = null;
                careBox.is_delivery_date = null;
            }
            result['careBox'] = careBox;
            data.push(result);
        });
        if (this._isMounted) {
            this.setState({
                careBoxesData: data,
                loadingCareBoxClientBoxes: false
            })
        }
    }

    finishCareBoxesWithError(error) {
        console.log(error);
        if (this._isMounted) {
            this.setState({
                careBoxesData: [],
                loadingCareBoxClientBoxes: false
            })
        }
    }

    ///// BASIC NOTES TAB DATA LOAD

    getBasicNotes() {
        if (this._isMounted) {
            this.setState({loadingNotes: true})
        }
        const {match: {params}} = this.props;
        axiosAuth.get("/notes/CLIENT/" + params.id + "/0")
            .then(res => {
                if (res.status === 200) {
                    return res;
                } else {
                    return Promise.reject(res.status);
                }
            })
            .then(json => {
                return json.data
            })
            .then(res => {
                if (this._isMounted) {
                    this.setState({
                        notesData: res,
                        loadingNotes: false
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                if (this._isMounted) {
                    this.setState({loadingNotes: false})
                }
            });
    }

    removeElementFromNotes(element) {
        if (this._isMounted) {
            this.setState({notesData: this.state.notesData.filter(value => value.Id !== element.Id)})
        }
    }

    addElementToNotes(element) {
        if (this._isMounted) {
            this.setState(previousState => ({
                ...previousState,
                notesData: [...previousState.notesData, element]
            }));
        }
    }


    ///// BASIC TASKS TAB DATA LOAD

    getBasicTasksData() {
        if (this.props.tasksStatus === ResponseStatus.READY) {
            clearTimeout(this.taskTimeOut);
            if (this._isMounted) {
                this.setState({loadingTasks: false})
            }
        } else {
            if (this.props.tasksStatus === ResponseStatus.FREE) {
                this.props.getGlobalTasksData();
                this.taskTimeOut = setTimeout(() => {
                    this.getBasicTasksData()
                }, 500);
            } else {
                if (this.props.tasksStatus === ResponseStatus.ERROR) {
                    clearTimeout(this.taskTimeOut);
                    if (this._isMounted) {
                        this.setState({loadingTasks: false})
                    }
                } else {
                    this.taskTimeOut = setTimeout(() => {
                        this.getBasicTasksData()
                    }, 500);
                }
            }
        }
    }


    ///// BASIC INVOICE TAB DATA LOAD

    getBasicInvoiceData() {
        if (this.props.invoiceStatus === ResponseStatus.READY) {
            clearTimeout(this.invoiceTimeOut);
            if (this._isMounted) {
                this.setState({loadingInvoices: false})
            }
        } else {
            if (this.props.invoiceStatus === ResponseStatus.FREE) {
                this.props.getInvoicesData();
                this.invoiceTimeOut = setTimeout(() => {
                    this.getBasicInvoiceData()
                }, 500);
            } else {
                if (this.props.invoiceStatus === ResponseStatus.ERROR) {
                    clearTimeout(this.invoiceTimeOut);
                    if (this._isMounted) {
                        this.setState({loadingInvoices: false})
                    }
                } else {
                    this.invoiceTimeOut = setTimeout(() => {
                        this.getBasicInvoiceData()
                    }, 500);
                }
            }
        }
    }

    async getSurvey(element) {
        const response = await Promise.all(element.referenzeformularSurveys.map(async survey => {
            let responseWithSurvey = await axiosAuth.get(`/survey/${survey}`).then(surveyDetails => {
                return new CarerTable(element, surveyDetails.data);
            });
            return responseWithSurvey;
        }));

        return response;
    }


    ///// BASIC CARER TAB DATA LOAD

    getBasicCarersData() {
        const {match: {params}} = this.props;
        const carerId = params.contractId;

        const resolvePromisesSeq = async (items) => {
            const results = [];
            for (const item of items) {
                results.push(await item);
            }
            return results.flat();
        };

        if (carerId && carerId !== '0' && carerId !== 0) {
            if (this._isMounted) {
                this.setState({
                    loadingCarers: true,
                    activeTab: "carers",
                    selectedCarerKey: params.contractId
                })
            }
        } else {
            if (this._isMounted) {
                this.setState({loadingCarers: true})
            }
        }
        axiosAuth.get("/carers/list/" + params.id + "/0/" + this.props.appLang)
            .then(res => {
                if (res.status === 200) {
                    return res;
                } else {
                    return Promise.reject(res.status);
                }
            })
            .then(json => {
                return json.data
            })
            .then(async response => {
                const res = this.buildList(response);
                let mapedRes = res.map(async (element) => {
                    if(element.referenzeformularSurveys != undefined) {
                        if(element.referenzeformularSurveys.length > 0) {
                            const survey = this.getSurvey(element).then(survey => new CarerTable(element, survey));
                            return survey
                        } else {
                            return new CarerTable(element);
                        }
                    } else {
                        return new CarerTable(element);
                    }
                });
                mapedRes = await resolvePromisesSeq(mapedRes);
                if (this._isMounted) {
                    this.setState({
                        carerData: mapedRes,
                        loadingCarers: false
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                if (this._isMounted) {
                    this.setState({loadingCarers: false})
                }
            });
    }

    buildList(response) {
        const {translateKey} = this.props;
        if(response.Carers !== undefined) {
            const carers =  response.Carers;
            response.Breaks.forEach((element, index) => {
                const breakItem = {
                    wzid: element.breakId,
                    firstName: translateKey("carer-break"),
                    status: null,
                    from: element.from,
                    to: element.to,
                    isBreak: true
                }
                carers.push(breakItem);
            });
            return carers;
        } else {
            return response;
        }
    }


    ///// BASIC CONTRACT TAB DATA LOAD

    getBasicContractsData() {
        if (this._isMounted) {
            this.setState({loadingContracts: true})
        }
        const {match: {params}} = this.props;
        axiosAuth.get("/contracts/list/" + params.id + "/0")
            .then(res => {
                if (res.status === 200) {
                    return res;
                } else {
                    return Promise.reject(res.status);
                }
            })
            .then(json => {
                return json.data
            })
            .then(res => {
                let mapedRes = res.map((element) => {
                    return new ContractTable(element);
                });
                if (this._isMounted) {
                    this.setState({
                        contractsData: mapedRes,
                        loadingContracts: false
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                if (this._isMounted) {
                    this.setState({loadingContracts: false})
                }
            });
    }

    getServiceData() {
        const {
            match: {params}
        } = this.props;
        let dataToSend = [
            {
                Id: params.id,
                Entity: Entity.CONTACT,
                Fields: ["is_service_type_de", "is_uses_app"]
            }
        ];
        getDynamicFields(dataToSend)
            .then(res => {
                if (res.length > 0) {
                  if (res[0].fields["is_service_type_de"]) {
                    let serviceType = res[0].fields["is_service_type_de"];
                    if (serviceType === null || serviceType === undefined) {
                        serviceType = '717700001'
                    }
                  }
                  if (this._isMounted) {
                      this.setState({
                          isServiceType: res[0].fields["is_service_type_de"],
                          isUsesApp: res[0].fields["is_uses_app"],
                      });
                  }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    getAditionalData() {
        const {match: {params}} = this.props;

        const currentClient = this.props.patientData.filter(item => item.clientId === params.id);
        let dataToSend = [
            {
                Id: currentClient[0].contractId,
                Entity: Entity.CONTRACT,
                Fields: [
                    "is_primary_product",
                    "is_driving_licence",
                    "is_second_ooo",
                    "is_additional_foreign_language",
                    "is_transfer",
                    "is_carer_with_diploma",
                    "is_additional_person_in_house"
                ]
            }
        ];
        getDynamicFields(dataToSend)
            .then(res => {
                if (res.length > 0) {
                    if (this._isMounted) {
                        this.setState({
                            aditionalData: {
                                isPrimaryProduct: res[0].fields["is_primary_product"],
                                isDrivingLicence: res[0].fields["is_driving_licence"],
                                isSecondOoo: res[0].fields["is_second_ooo"],
                                isAdditionalForeignLanguage: res[0].fields["is_additional_foreign_language"],
                                isTransfer: res[0].fields["is_transfer"],
                                isCarerWithDiploma: res[0].fields["is_carer_with_diploma"],
                                isAdditionalPersonInHouse: res[0].fields["is_additional_person_in_house"],
                            }
                        });
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    closeAddModal = () => {
        if (this._isMounted) {
            this.setState({showAddNoteModal: false})
        }
    }

    addClickButton = () => {
        const {match: {params}, history} = this.props;
        switch (this.state.activeTab) {
            case "patients":
                if (this.props.appLang === LanguageSelect.EN) {
                    history.push(`${baseData.subFolders}clientPatient/${params.id}/0`);
                } else {
                    history.push(`${baseData.subFolders}bb-form-client/${params.id}/0`);
                }
                break;
            case "notes":
                if (!this.state.loadingNotes) {
                    if (this.state.clientData !== null) {
                        if (this._isMounted) {
                            this.setState({showAddNoteModal: true})
                        }
                    }
                }
                break;
            case "tasks":
                history.push(`${baseData.subFolders}newtask/${params.id}/${RecordType.CLIENT}`);
                break;
            default:
        }
    }

    prepareMeCaAdd() {
        let options = [];
        switch (this.state.activeTab) {
            case "notes":
                if (this.state.clientData !== null) {
                    options = [TableEnums.ADD]
                }
                ;
                break;
            case "tasks":
                if (this.state.clientData !== null) {
                    options = [TableEnums.ADD]
                }
                ;
                break;
            default:
        }
        return options;
    }

    openIngredients(careBox) {
        this.setActiveTab("careBoxClientBoxIngredients")
        if (this._isMounted) {
            this.setState({
                loadingCareBoxClientBoxIngredients: false,
                careBoxIngredients: careBox
            })
        }
    }

    render() {
        const {activeTab} = this.state;
        const {translateKey} = this.props;
        let ClientData;
        if (this.state.loadingBasics) {
            ClientData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable/></div>
        } else {
            if (this.state.clientData === null) {
                ClientData =
                    <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")}/>
            } else {
                ClientData = <ClientClientData clientData={this.state.clientData} isUsesApp={this.state.isUsesApp}/>
            }
        }

        let PatientData;
        if (this.state.loadingPatients) {
            PatientData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable/></div>
        } else {
            if (this.state.clientData === null) {
                PatientData =
                    <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")}/>
            } else {
                if (this.props.patientData === []) {
                    PatientData = <EmptyTableIcon RefreshMe={() => this.getInitialPatient()}
                                                  text={translateKey("no_data_to_show")}/>
                } else {
                    PatientData = <ClientPatientData RefreshMe={() => this.getInitialPatient()} id={this.state.id}
                                                     titleKey={"client-patient-title"}
                    />
                }
            }
        }

        let CareBoxClientData;
        if (this.state.loadingCareBoxClient) {
            CareBoxClientData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable/></div>
        } else {
            if (this.state.clientData === null) {
                CareBoxClientData =
                    <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")}/>
            } else {
                if (this.props.patientData === []) {
                    CareBoxClientData = <EmptyTableIcon RefreshMe={() => this.getInitialPatient()}
                                                        text={translateKey("no_data_to_show")}/>
                } else {
                    CareBoxClientData = <ClientPatientData RefreshMe={() => this.getInitialPatient()} id={this.state.id}
                                                           titleKey={"client-care-box-patient-title"}
                                                           isCareBox={true}
                                                           onReload={() => this.getPatientList()}
                                                           onPatientSelected={(clientId, patientId) => this.getPatientCareBoxes(clientId, patientId)}/>
                }
            }
        }

        let ClientPatientCareBox;
        if (this.state.loadingCareBoxClientBoxes) {
            ClientPatientCareBox = <div className="flexCenterItems emptyLoaderMin"><LoaderTable/></div>
        } else {
            if (this.state.clientData === null) {
                ClientPatientCareBox =
                    <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")}/>
            } else {
                if (this.state.careBoxesData === []) {
                    ClientPatientCareBox =
                        <EmptyTableIcon RefreshMe={() => this.patientClientCareBoxDatagetInitialPatient()}
                                        text={translateKey("no_data_to_show")}/>
                } else {
                    ClientPatientCareBox = <ClientPatientCareBoxData
                        RefreshMe={() => this.getPatientCareBoxes(this.state.selectedClient, this.state.selectedPatient)}
                        id={this.state.id}
                        careBoxes={this.state.careBoxesData}
                        clientId={this.state.selectedClient}
                        patientId={this.state.selectedPatient}
                        onIngredientsSelected={(careBox) => this.openIngredients(careBox)}
                    />
                }
            }
        }

        let ClientPatientCareBoxIngredients;
        if (this.state.loadingCareBoxClientBoxIngredients) {
            ClientPatientCareBoxIngredients = <div className="flexCenterItems emptyLoaderMin"><LoaderTable/></div>
        } else {
            if (this.state.clientData === null) {
                ClientPatientCareBoxIngredients =
                    <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")}/>
            } else {
                if (this.state.careBoxesData === null) {
                    ClientPatientCareBoxIngredients =
                        <EmptyTableIcon RefreshMe={() => this.patientClientCareBoxDatagetInitialPatient()}
                                        text={translateKey("no_data_to_show")}/>
                } else {
                    ClientPatientCareBoxIngredients =
                        <ClientPatientCareBoxIngredientsData RefreshMe={() => this.patientClientCareBoxDatagetInitialPatient()}
                                                             id={this.state.id}
                                                             careBoxIngredients={this.state.careBoxIngredients}
                                                             clientId={this.state.selectedClient}
                                                             patientId={this.state.selectedPatient}
                        />
                }
            }
        }

        let NotesData;
        if (this.state.loadingNotes) {
            NotesData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable/></div>
        } else {
            if (this.state.clientData === null) {
                NotesData =
                    <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")}/>
            } else {
                const {match: {params}} = this.props;
                NotesData =
                    <ClientNotesData closeAdd={this.closeAddModal} add={(element) => this.addElementToNotes(element)}
                                     remove={(element) => this.removeElementFromNotes(element)}
                                     showAddModal={this.state.showAddNoteModal} RefreshMe={() => this.getBasicNotes()}
                                     clientId={params.id} notesData={this.state.notesData} id={this.state.id}/>
            }
        }

        let TaskData;
        if (this.state.loadingTasks) {
            TaskData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable/></div>
        } else {
            if (this.state.clientData === null) {
                TaskData =
                    <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")}/>
            } else {
                if (this.props.tasksData === []) {
                    TaskData = <EmptyTableIcon RefreshMe={() => this.getBasicTasksData()}
                                               text={translateKey("no_data_to_show")}/>
                } else {
                    TaskData = <ClientTasksData RefreshMe={() => this.getBasicTasksData()} id={this.state.id}/>
                }
            }
        }

        let InvoiceData;
        if (this.state.loadingInvoices) {
            InvoiceData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable/></div>
        } else {
            if (this.state.clientData === null) {
                InvoiceData =
                    <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")}/>
            } else {
                if (this.props.invoicesData === []) {
                    InvoiceData = <EmptyTableIcon RefreshMe={() => this.getBasicInvoiceData()}
                                                  text={translateKey("no_data_to_show")}/>
                } else {
                    InvoiceData = <ClientInvoicesData RefreshMe={() => this.getBasicInvoiceData()} id={this.state.id}/>
                }
            }
        }

        let CarersData;
        if (this.state.loadingCarers) {
            CarersData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable/></div>
        } else {
            if (this.state.clientData === null) {
                InvoiceData =
                    <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")}/>
            } else {
                const {match: {params}} = this.props;
                CarersData = <ClientCarersData RefreshMe={() => this.getBasicCarersData()}
                                               selectedKey={this.state.selectedCarerKey} id={this.state.id}
                                               clientId={params.id} carerData={this.state.carerData}/>
            }
        }

        let ContractsData;
        if (this.state.loadingCarers) {
            ContractsData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable/></div>
        } else {
            if (this.state.clientData === null) {
                InvoiceData =
                    <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")}/>
            } else {
                const {match: {params}} = this.props;
                ContractsData = <ClientContractsData 
                                    RefreshMe={() => this.getBasicContractsData()} 
                                    id={this.state.id}
                                    clientId={params.id} 
                                    contractsData={this.state.contractsData}
                                    aditionalData={this.state.aditionalData}
                                />
            }
        }

        return (
            <div className="mainContentWrapper" onClick={() => {
                this.props.tookAction()
            }}>
                <NavBar breadCrumbs={this.state.breadCrumbs}/>
                <TableProperties showMeQuatation={this.showMeQuatation} showMeContract={this.showMeContract}
                                 addClick={this.addClickButton} options={this.prepareMeCaAdd()} filterData={[]}/>
                <div className="mainContent">
                    <SideBar/>
                    <div className="listDataWrapper transparentClass">
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <a className={"nav-item nav-link nav-link-invoices " + (activeTab === "client" ? "active" : "")}
                                   onClick={() => {
                                       this.setActiveTab("client")
                                   }} id="nav-details-tab" data-toggle="tab" href="#nav-details" role="tab"
                                   aria-controls="nav-details" aria-selected="true">
                                    {translateKey("client")}
                                </a>
                                <a className={"nav-item nav-link nav-link-invoices " + (activeTab === "tasks" ? "active" : "")}
                                   onClick={() => {
                                       this.setActiveTab("tasks")
                                   }} id="nav-tasks-tab" data-toggle="tab" href="#nav-tasks" role="tab"
                                   aria-controls="nav-tasks" aria-selected="false">
                                    {translateKey("tasks")}
                                </a>
                                <a className={"nav-item nav-link nav-link-invoices " + (activeTab === "carers" ? "active" : "")}
                                   onClick={() => {
                                       this.setActiveTab("carers")
                                   }} id="nav-carers-tab" data-toggle="tab" href="#nav-carers" role="tab"
                                   aria-controls="nav-carers" aria-selected="false">
                                    {translateKey("carers")}
                                </a>
                                <a className={"nav-item nav-link nav-link-invoices " + (activeTab === "contracts" ? "active" : "")}
                                   onClick={() => {
                                       this.setActiveTab("contracts")
                                   }} id="nav-contracts-tab" data-toggle="tab" href="#nav-contracts" role="tab"
                                   aria-controls="nav-contracts" aria-selected="false">
                                    {translateKey("contracts")}
                                </a>
                                <a className={"nav-item nav-link nav-link-invoices " + (activeTab === "invoices" ? "active" : "")}
                                   onClick={() => {
                                       this.setActiveTab("invoices")
                                   }} id="nav-invoices-tab" data-toggle="tab" href="#nav-invoices" role="tab"
                                   aria-controls="nav-invoices" aria-selected="false">
                                    {translateKey("invoices")}
                                </a>
                                <a className={"nav-item nav-link nav-link-invoices " + (activeTab === "notes" ? "active" : "")}
                                   onClick={() => {
                                       this.setActiveTab("notes")
                                   }} id="nav-notes-tab" data-toggle="tab" href="#nav-notes" role="tab"
                                   aria-controls="nav-notes" aria-selected="false">
                                    {translateKey("notes")}
                                </a>
                                <a className={"nav-item nav-link nav-link-invoices " + (activeTab === "patients" ? "active" : "")}
                                   onClick={() => {
                                       this.setActiveTab("patients")
                                   }} id="nav-patients-tab" data-toggle="tab" href="#nav-patients" role="tab"
                                   aria-controls="nav-patients" aria-selected="false">
                                    {translateKey("patients")}
                                </a>
                                <a className={"nav-item nav-link nav-link-invoices " + ((activeTab === "careBoxClient" || activeTab === 'careBoxClientBoxes' || activeTab === 'careBoxClientBoxIngredients') ? "active" : "")}
                                   onClick={() => {
                                       this.setActiveTab("careBoxClient")
                                   }} id="nav-care-box-client-tab" data-toggle="tab" href="#nav-care-box-client-tab"
                                   role="tab" aria-controls="nav-care-box-client-tab" aria-selected="false">
                                    {translateKey("section-care-box")}
                                </a>
                            </div>
                        </nav>
                        <div className="tab-content position-relative" id="nav-tabContent">
                            <div className={"tab-pane fade " + (activeTab === "client" ? " show active" : "")}
                                 id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                                <div className="leadSection">
                                    {ClientData}
                                </div>
                            </div>
                            <div className={"tab-pane fade " + (activeTab === "tasks" ? " show active" : "")}
                                 id="nav-tasks" role="tabpanel" aria-labelledby="nav-tasks-tab">
                                <div className="leadSection">
                                    {TaskData}
                                </div>
                            </div>
                            <div className={"tab-pane fade " + (activeTab === "carers" ? " show active" : "")}
                                 id="nav-carers" role="tabpanel" aria-labelledby="nav-careres-tab">
                                <div className="leadSection">
                                    {CarersData}
                                </div>
                            </div>
                            <div className={"tab-pane fade " + (activeTab === "contracts" ? " show active" : "")}
                                 id="nav-contracts" role="tabpanel" aria-labelledby="nav-contracts-tab">
                                <div className="leadSection">
                                    {ContractsData}
                                </div>
                            </div>
                            <div className={"tab-pane fade " + (activeTab === "invoices" ? " show active" : "")}
                                 id="nav-invoices" role="tabpanel" aria-labelledby="nav-invoices-tab">
                                <div className="leadSection">
                                    {InvoiceData}
                                </div>
                            </div>
                            <div className={"tab-pane fade " + (activeTab === "notes" ? " show active" : "")}
                                 id="nav-notes" role="tabpanel" aria-labelledby="nav-notes-tab">
                                <div className="leadSection">
                                    {NotesData}
                                </div>
                            </div>
                            <div className={"tab-pane fade " + (activeTab === "patients" ? " show active" : "")}
                                 id="nav-patients" role="tabpanel" aria-labelledby="nav-patients-tab">
                                <div className="leadSection">
                                    {PatientData}
                                </div>
                            </div>
                            <div className={"tab-pane fade " + (activeTab === "careBoxClient" ? " show active" : "")}
                                 id="nav-care-box-client-tab" role="tabpanel" aria-labelledby="nav-care-box-client-tab">
                                <div className="leadSection">
                                    {CareBoxClientData}
                                </div>
                            </div>
                            <div
                                className={"tab-pane fade " + (activeTab === "careBoxClientBoxes" ? " show active" : "")}
                                id="nav-care-box-client-boxes-tab" role="tabpanel"
                                aria-labelledby="nav-care-box-client-boxes-tab">
                                <div className="leadSection">
                                    {ClientPatientCareBox}
                                </div>
                            </div>
                            <div
                                className={"tab-pane fade " + (activeTab === "careBoxClientBoxIngredients" ? " show active" : "")}
                                id="nav-care-box-client-box-ingredients-tab" role="tabpanel"
                                aria-labelledby="nav-care-box-client-box-ingredients-tab">
                                <div className="leadSection">
                                    {ClientPatientCareBoxIngredients}
                                </div>
                            </div>
                            <div className="basic-bottom-section-line"
                                 style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
                        </div>
                    </div>
                </div>
                <Prompt
                    when={false}
                    message={translateKey("unsaved_data_lost")}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        clientsData: state.database.clients,
        clientsState: state.database.clientsState,
        patientData: state.database.patientClient,
        patientClientStatus: state.database.patientClientState,
        patientClientCareBoxData: state.database.patientClientCareBox,
        patientClientCareBoxStatus: state.database.patientClientCareBoxState,
        tasksData: state.database.tasks,
        tasksStatus: state.database.tasksState,
        invoicesData: state.database.invoices,
        invoiceStatus: state.database.invoicesState,
        wzData:  state.database.wzData,
    };
};
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    setClientData: (data) => dispatch(setClientsData(data)),
    pendingRequestClient: () => dispatch(pendingClientsData()),
    clearDataClient: () => dispatch(removeClientsData()),
    getPatientClientData: (lang) => dispatch(getPatientClient(lang)),
    setPatientData: (data) => dispatch(setPatientClientData(data)),
    pendingPatientData: () => dispatch(pendingPatientClientData()),
    clearPatientData: () => dispatch(removePatientClientData()),
    tookAction: () => dispatch(authSetActionTimestamp()),
    getGlobalTasksData: () => dispatch(getTasks()),
    setGlobalTasksData: (data) => dispatch(setTasksData(data)),
    getInvoicesData: () => dispatch(getInvoices())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientDetailsPage));