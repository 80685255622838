import { skipList } from "./BBForm/values/formKeySkipList";
import { allowEmptyList } from "./BBForm/values/allowEmptyList";
export default class Form {
    sections = [];
    flatFieldsList = [];

    constructor({ sections = [] } = {}) {
        this.sections = sections;
        this.sections.forEach((section, index) => {
            section.assignToForm(this, index);
            this.flatFieldsList = [...this.flatFieldsList, ...section.fields];
        });
    }
    

    getSectionByKey(key) {
        return this.sections.find(section => section.key === key);
    }

    getSectionByOrder(order) {
        return this.sections.find(section => section.order === order);
    }

    getFieldByKey(key) {
        return this.flatFieldsList.find(field => field.key === key);
    }

    getFieldByKeyMulti(key) {
        return this.flatFieldsList.filter(field => field.key === key);
    }

    getFieldsByEntity(entity) {
        return this.uniqueFlatFieldsList.filter(field => field.entity === entity);
    }

    getFieldsByEntityAndKey(entity, key) {
        return this.flatFieldsList.find(field => field.key === key && field.entity === entity);
    }

    get uniqueFlatFieldsList() {
        return this.flatFieldsList.filter((field, index, self) => self.indexOf(field) === index);
    }

    getUniqueFlatFieldsList(){
        return this.uniqueFlatFieldsList;
    }

    setFields(data) {
        Object.keys(data).forEach(key => {
            const fields = this.getFieldByKeyMulti(key);
            if(fields.length>0){
                fields.forEach(element => {
                    element.value = data[key];
                });
            }
        });
    }

    setFieldsKunde(data) {
       data.forEach(item => {
            const field = this.getFieldByKeyMulti(item.web_key);
            if(field.length>0){
                field.forEach(element => {
                    if(element){
                        element.value = item.value;
                    }
                });
            }
        });
    }

    setFieldsByKeys(keyList, newValue) {
        keyList.forEach(key => {
            const field = this.getFieldByKeyMulti(key);
            if(field.length>0){
                field.forEach(element => {
                    if(element){
                        element.value = newValue;
                    }
                });
            }
        });
    }

    getFieldsBySectionAndEntity(key, entity, payload = false){
        const section = this.getSectionByKey(key);
        const fields = payload ? section.payloadFields : section.fields;
        return fields.filter(field => {
            if(field && field.entity === entity){
                if (skipList.indexOf(field.key) !== -1) {
                    return false;
                }else{
                    if (allowEmptyList.indexOf(field.key) !== -1) {
                        return true;
                    }else{
                        if(field.value!==null){
                            if(field.validationRules.fieldType === "phone_number") {
                                if(field.value.toString().length < 4) {
                                    field.value = "";
                                }
                                return true;
                            }
                            if (field.key === "is_family_suffer_infectionus_diseases_des_de" || field.key === "is_infectious_diseases_what_de") {
                                return true;
                            }
                            if(field.value.toString().trim()!==""){
                                return true
                            }else{
                                return false;
                            }
                        }else{
                            return false;
                        }
                    }
                }
            }else{
                return false;
            }
        });
    }

    get isValid() {
        return this.sections.reduce((previous, section) => section.isValid && previous, true);
    }
}
