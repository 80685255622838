import * as React from "react";
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";
import {
  translateKey,
  authSetActionTimestamp,
  setClientsData,
  pendingClientsData,
  removeClientsData,
  setPatientClientData,
  pendingPatientClientData,
  removePatientClientData,
  getPatientClient,
  setCareBoxIndividualPrices, setCareBoxIds, setCareBoxSets, setCareBoxSetsList, setGloveType
} from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, LoaderModal } from "../../_components/index";
import { ClientTable, Alert } from "../../_constance/classes/index";
import {
  SectionAngabenZurKontakt,
  SectionAngabenZurOrt,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8,
  Section9,
  Section10,
  Section11,
  Section12,
  SectionRuhenUndSchlafen,
  SectionPflegedienstSimple,
  SectionTherapien,
  Section16,
  Section17,
  SectionBeschreibungDerUnterkunft,
  SectionNotiz,
  //SectionConsents,
  SectionAnwesendBei,
  SectionKrankheitsbilder,
  SectionKommunikation,
  SectionBeschreibung,
  SectionPflegedienst,
  SectionInitialOffer,
  SectionFinalOffer,
  SectionCareBox,
  SectionOfferSummary,
} from "../../_components/form-bb-sections/index";
import SectionNavigationToolbarComponent from "../../_components/form-bb-components/section-navigation-toolbar/section-navigation-toolbar";
import axiosAuth from "../../_services/config/axios-auth";
import { ResponseStatus } from '../../_helpers/response_status'
import { getUserProducts, getRegionsFP } from "../../_redux/actions/bb-form-services";
import BBFormService from '../../_services/bbform';
import * as FormTypeToShow from '../../_forms/BBForm/values/formTypeToShow';
import * as Relationship from '../../_forms/BBForm/values/relationship';
import * as IsContactPersonDataSameAs from '../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as Sections from '../../_forms/BBForm/values/sections';
import BBForm from '../../_forms/BBForm/BBForm';
import { FormConfig } from '../../_forms/BBForm/formConfig';
import * as RecordType from "../../_constance/values/recordType";
import { SECTION_OUTSIDE_MENU,  OFFER_SECTION } from "../../_constance/enums/summaryOfferMenuSections";
import {getCareBoxSets, getDynamicFields, getPicklist} from "../../_services/user_service";
import {toDuplicatIngredients} from "../../_forms/BBForm/values/careBoxIngredientsDuplicated";
import * as Entity from "../../_forms/BBForm/values/entity";
import {getSectionNameByNumber, getSectionNumberByName} from "../../_constance/enums/sectionPageList";
import {baseData} from "../../_constance";

const LAST_SECTION_KEY = 'is_last_section';
const SKIP_SAVE_IS_DIET_DIABETES = 'skip_save_is_diet_diabetes';
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles';

class MainClientBBWrapper extends React.Component {
  _isMounted = false;
  myTimout;
  patientTimeout;
  constructor(props) {
    super(props);
    this.state = {
      editedForm: false,
      currentSection: FormConfig.firstPageAfterRodo,
      loader: null,
      modalLoader: true,
      savingData: false,
      errorsMessage: null,
      errorsType: "danger",
      LKId: 0,
      patientId: 0,
      products: [],
      regionsFP: [],
      formbbStatus: "0",
      errorComponent: Alert,
      type: FormTypeToShow.FORM,
      toPage: null,
      hideBar: true,
      breadCrumbs:[
        {
          link: "clients",
          key: "clients",
          name: null
        }
      ],
      clientRetries: 0,
      showValidationErrors: false,
      form: new BBForm(),
      rerender: Date.now(),
      newOne: false,
      leadPatients: [],
      wholeResponse: null,
      sectionMenuOpen: false,
      analizeFinished: true,
      prevPageNumber: 0,
      rodoDisabled: true,

      // final offer variables
      activeSection: SECTION_OUTSIDE_MENU,

      isOfferLoaded: false,
      isOfferOpened: false,

      hideMeAll: false,

      clientLeadBaseData: null,

      secondPatientForm: new BBForm(),
      secondPatientId: null,
      multiplePatients: false,
      otherPatient: [],
      showMultiPatientModal: false,
      isAditionalPatient: false,
      isCareBoxDisabled: true,
      isFormDisabled: true,
      isServiceType: '0'
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { match: { params } } = this.props;
    let type = params.type;
    if(type!==FormTypeToShow.CONTRACT && type!==FormTypeToShow.ENQUIRY){
      type=FormTypeToShow.FORM
    }
    if (this._isMounted) {
      this.setState({
        LKId: params.id,
        patientId: params.patientId,
        type: type,
        hideBar: true,
        hideMeAll: true
      })
    }
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.myTimeout = setTimeout(() => {this.getDataBasicClient();}, 100);
    this.getDataForPflegbox();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
    clearTimeout(this.patientTimeout);
  };

  toggleFullScreen = () =>{
    const { hideMeAll } = this.state;
    if(hideMeAll){
      this.setState({hideMeAll: false})
      if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      }
    }else{
      this.setState({hideMeAll: true})
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      }
    }
  }

  toggleSectionMenu = () =>{
    this.setState(prevState => {
      return {
          ...prevState,
          sectionMenuOpen: !prevState.sectionMenuOpen,
          prevPageNumber: prevState.currentSection===12?13:prevState.currentSection
      }
    })
  }

  hideSectionMenu = () =>{
    this.setState(prevState => {
      return {
          ...prevState,
          sectionMenuOpen: false,
          prevPageNumber: prevState.currentSection===12?13:prevState.currentSection
      }
    })
  }

  toggleAnalizeLoader = () => {
    this.setState(prevState => {
      return {
          ...prevState,
          analizeFinished: !prevState.analizeFinished
      }
    })
  }

  toggleOffAnalizer = () => {
    this.setState(prevState => {
      return {
          ...prevState,
          analizeFinished: true
      }
    })
  }

  giveMeSpecialToGoSection(){
    const { currentSection, form } = this.state;
    let toPageNumber = parseInt(currentSection) - 1;
    if(toPageNumber === Sections.PATIENT){
      const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
      if(isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8){
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
          toPageNumber = Sections.CLIENT
        }else{
          toPageNumber =  Sections.CONTACT_PERSON
        }
      }
    }else{
      if(toPageNumber === Sections.CONTACT_PERSON){
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
          toPageNumber = Sections.CLIENT
        }
      }
    }
    if(toPageNumber <= Sections.RODOSIGNATURE){
      toPageNumber = Sections.CLIENT
    }
    return toPageNumber;
  }

  changePage = async number => {
    if(this._isMounted){
      this.setState({
        currentSection: number <= Sections.RODOSIGNATURE?Sections.CLIENT:number,
        savingData: false,
        showValidationErrors: false
      });
    }
  }

    previousPageClick = async () => {
        const {form, currentSection, isServiceType} = this.state;
        let isFormDisabled = isServiceType !== '717700000';
        const section = form.getSectionByOrder(currentSection);
        if (!isFormDisabled && this.shouldValidateSection(section)) {
            const [isSaved, LKId, patientId] = await this.savePatient();
            if (isSaved) {
                const {match: {params}} = this.props;
                if (params.patientId === "0" || params.patientId === 0) {
                    this.props.getPatientLeadData(this.props.appLang);
                    const {history} = this.props;
                    history.push(`${baseData.subFolders}bb-form-lead/${LKId}/${patientId}`);
                }
            }
            if (currentSection > FormConfig.initPage) {
                if (this._isMounted) {
                    const section = form.getSectionByOrder(currentSection);
                    this.setState({
                        currentSection: section.prevSection,
                        LKId: LKId,
                        patientId: patientId,
                        isImportant: false,
                        prevPageNumber: currentSection
                    });
                }
                window.scrollTo(0, 0);
            }
        } else {
            if (this._isMounted) {
                this.setState({currentSection: this.giveMeSpecialToGoSection()});
            }
        }
    }

    nextPageClick = async () => {
        const {form, currentSection, isServiceType} = this.state;
        let isFormDisabled = isServiceType !== '717700000';
        const section = form.getSectionByOrder(currentSection);
        if (!isFormDisabled && this.shouldValidateSection(section)) {
            let lastSectionValue = form.getFieldByKey(LAST_SECTION_KEY);
            let lastSectionNumber = getSectionNumberByName(lastSectionValue.value);
            if (lastSectionNumber < section.nextSection) {
                lastSectionValue.value = getSectionNameByNumber(section.nextSection);
            }
            const [isSaved, LKId, patientId] = await this.savePatient();
            if (isSaved) {
                const {match: {params}} = this.props;
                if (params.patientId === "0" || params.patientId === 0) {
                    this.props.getPatientLeadData(this.props.appLang);
                    const {history} = this.props;
                    history.push(`${baseData.subFolders}bb-form-lead/${LKId}/${patientId}`);
                }
                if (this._isMounted) {
                    this.setState({
                        currentSection: section.nextSection,
                        LKId,
                        patientId,
                        prevPageNumber: currentSection
                    });
                }
                window.scrollTo(0, 0);
            }
        } else {
            const section = this.state.form.getSectionByOrder(this.state.currentSection);
            if (this._isMounted) {
                this.setState({currentSection: section.nextSection});
            }
        }
    }

  shouldValidateSection(section) {
    if (section.key === 'section-care-box') {
      return !this.state.isCareBoxDisabled
    } else {
      return true;
    }
  }

  savePatient = async () => {
    const { form, currentSection, isImportant, isServiceType } = this.state;
    const { match: { params } } = this.props;
    const section = form.getSectionByOrder(currentSection);
    let isFormDisabled = isServiceType !== '717700000';
    if (!isFormDisabled) {
      if (this._isMounted) {this.setState({ savingData: true });}
      let allImportantFieldsFilled = true;
      if (isImportant === false && !section.allImportantsFilled) {
        allImportantFieldsFilled = false;
      }
      if(currentSection === FormConfig.signaturePage){
        const rodoSignatureBase64 = section.getFieldByKey('rodo_signature');
        if(rodoSignatureBase64 && rodoSignatureBase64.value!==null){
          try {
            await BBFormService.saveRodoSignature(params.id, rodoSignatureBase64.value);
            await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, params.patientId));
            if (this._isMounted) {
              this.setState({
                savingData: false,
                showValidationErrors: false,
                isImportant: false,
              });
            }
            this.disableSupportFields();
            return [true, params.id, params.patientId];
          } catch (e) {
            console.log(e);
            if (this._isMounted) {
              this.setState({
                savingData: false,
                showValidationErrors: false,
                isImportant: false
              });
            }
            return [false];
          }
        }else{
          if (this._isMounted) {
            this.setState({
              savingData: false,
              showValidationErrors: true,
              isImportant: false
            });
          }
          return [false];
        }
      }else{
        if (section.isValid && allImportantFieldsFilled) {
          try {
            const response = await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, params.patientId));
            const isCareBoxSet = form.getFieldByKey('is_lead_status_care_box').value;
            if(isCareBoxSet == '717700003' && section.key === 'section-care-box') {
              const responseFromCareBox = await BBFormService.savePatient(form.getCareBox(section.key, this.props.careBoxId));
              const { data : dataCareBox } = responseFromCareBox;
              await BBFormService.savePatient(form.getCareBoxIngredients(section.key, dataCareBox[0].id, this.props.careBoxIngredientsId));
            }
            let lkid = params.id?params.id:0;
            let patientid = params.patientId?params.patientId:0;
            const { data } = response;
            data.forEach(element => {
              if (element.entity === Entity.PATIENT) {
                patientid = element.id;
              } else {
                if (element.entity === Entity.CONTACT) {
                  lkid = element.id;
                }
              }
            });
            if (this._isMounted) {
              this.setState({
                savingData: false,
                showValidationErrors: false,
                isImportant: false,
              });
            }
            this.disableSupportFields();
            if (currentSection === FormConfig.firstPageAfterRodo) {
              const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
              const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
              if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
                /// pass fields from 1 to second section
                form.getFieldByKey('is_contact_person_firstname').value = form.getFieldByKey('firstname').value;
                form.getFieldByKey('is_contact_person_lastname').value = form.getFieldByKey('lastname').value;
                form.getFieldByKey('is_contact_person_mobile_phone').value = form.getFieldByKey('is_mobile_phone').value;
                form.getFieldByKey('is_contact_person_home_phone').value = form.getFieldByKey('is_home_phone').value;
                form.getFieldByKey('is_relationship_with_patient_op').value = isDegreeRelationShipWithPatientCarer.value;
                this.simpleSaveWithoutErrors(Sections.CONTACT_PERSON, patientid);
              }
              if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
                /// pass fields from 1 to third section
                const firstNameValue = form.getFieldByKey('firstname').value;
                const lastNameValue = form.getFieldByKey('lastname').value;
                form.getFieldByKey('is_patient_first_name').value = firstNameValue;
                form.getFieldByKey('is_patient_last_name').value = lastNameValue;
                form.getFieldByKey('is_first_name_and_last_name_of_patient').value = firstNameValue + " " + lastNameValue;
                form.getFieldByKey('is_service_address_city').value = form.getFieldByKey('is_registered_city').value;
                form.getFieldByKey('is_service_address_house_number').value = form.getFieldByKey('is_registered_house_number').value;
                form.getFieldByKey('is_patient_cellphone').value = form.getFieldByKey('is_mobile_phone').value;
                form.getFieldByKey('is_patient_phone').value = form.getFieldByKey('is_home_phone').value;
                form.getFieldByKey('is_service_address_postal_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
                form.getFieldByKey('is_service_address_street_number').value = form.getFieldByKey('is_registered_street_number').value;
                form.getFieldByKey('is_service_address_street').value = form.getFieldByKey('is_registered_street').value;
                this.simpleSaveWithoutErrors(Sections.PATIENT, patientid);
              }
            }
            return [true, lkid, patientid];
          } catch (e) {
            console.log(e);
            if (this._isMounted) {
              this.setState({
                savingData: false,
                showValidationErrors: false,
                isImportant: false
              });
            }
            return [false];
          }
        } else {
          if (this._isMounted) {
            this.setState({
              savingData: false,
              showValidationErrors: true,
              isImportant: true,
            });
            setTimeout(() => {
              const scrollToField = section.fields.find(element => !element.isValid);
              if(scrollToField){
                const elmnt = document.getElementById(`${scrollToField.key}_error_id`);
                if(elmnt){
                  elmnt.parentNode.scrollIntoView({ behavior: 'smooth'});
                }
              }else{
                const importantMessageList = document.getElementsByClassName('important-message-class');
                if(importantMessageList.length>0){
                  importantMessageList[0].parentNode.scrollIntoView({ behavior: 'smooth'});
                }
              }
            }, 50);
          }
          return [false];
        }
      }
    } else {
      if (this._isMounted) {
        this.setState({
          savingData: false,
          showValidationErrors: false,
          isImportant: false
        });
      }
      return [true, params.id, params.patientId]
    }
  };

  disableSupportFields = () =>{
    const saveDiabetesField = this.state.form.getFieldByKey(SKIP_SAVE_IS_DIET_DIABETES);
    if(saveDiabetesField){saveDiabetesField.value = false}
    const saveDiabetesTileField = this.state.form.getFieldByKey(SKIP_SAVE_STRING_TILES);
    if(saveDiabetesTileField){saveDiabetesTileField.value = false}
  }

  simpleSaveWithoutErrors = async (sectionNumber, patientid) => {
    const { match: { params } } = this.props;
    const { form, formbbStatus } = this.state;
    let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if (!isFormDisabled) {
      const section = form.getSectionByOrder(sectionNumber);
      if (section.isValid) {
        let newPatient = params.patientId;
        if (patientid !== 0 || patientid !== "0") {newPatient = patientid;}
        await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, newPatient));
        this.props.getPatientLeadData(this.props.appLang);
      }
    }
  };

  prepareMeForm(){
    const { match: { params }, clientsPatientData} = this.props;
    const { form, secondPatientForm } = this.state;
    let patientIdList = [];
    if(params.patientId!==0 && params.patientId!=="0"){
      patientIdList.push(params.patientId);
    }
    const allPatientsforLead = clientsPatientData.filter(element => ( element.clientId === params.id) );
    const isSameClient = allPatientsforLead.every(element =>  element.id == params.patientId);
    if (allPatientsforLead.length > 1 && !isSameClient) {
      const otherPatients = allPatientsforLead.filter(element => ( element.id !== params.patientId) ).flat();
      console.log('other patient: ', otherPatients);
      BBFormService.retrievePatient(secondPatientForm.getRetiveDataALl(params.id, [otherPatients[0].id])).then(response => {
        const { data } = response;
        data.forEach(entity => secondPatientForm.setFields(entity.fields));
      });

      this.setState({
        multiplePatients: true,
        otherPatient: otherPatients,
        secondPatientId: otherPatients[0].id,
      });
    } else {
      this.setState({
        multiplePatients: false,
      });
    }
    BBFormService.retrievePatient(form.getRetiveDataALl(params.id, patientIdList)).then(response => {
      const { data } = response;
      data.forEach(entity => form.setFields(entity.fields));
      if(params.patientId==="0" || params.patientId===0){
        if(this._isMounted){
          this.setState({
            showValidationErrors: false,
            currentSection: FormConfig.firstPageAfterRodo,
            savingData: false,
            modalLoader: false
          })
        }
      }else{
        let lastSection = form.getFieldByKey(LAST_SECTION_KEY);
        let currentSection = lastSection.value!==null ? getSectionNumberByName(lastSection.value) : FormConfig.firstPageAfterRodo;
        if(this._isMounted){
          this.setState({
            showValidationErrors: false,
            currentSection: currentSection,
            savingData: false,
            modalLoader: false
          })
        }
      }
    }).then(() => {
      this.getCareBoxData();
    }).then(()=>{
      if(this._isMounted){
        this.setState({rerender: Date.now()});
      }
    })
  }

  getClientDataBasic() {
    const { match: { params }, clientsData } = this.props;
    for(let i=0;i<clientsData.length; i++){
      if(clientsData[i].id===params.id){
        if(this._isMounted){
          this.setState({
            LKId: params.id,
            clientLeadBaseData: clientsData[i],
            breadCrumbs:[...this.state.breadCrumbs, {
              link: "clientDetails/"+params.id,
              key: "",
              name: clientsData[i].client
            },
            {
              link: "",
              key: "",
              name: "BB"
            }],
          })
        }
        this.prepareMeForm();
        break;
      }
    }
  }

  getInitialDataTime(){
    if(this.props.clientsData.length>0){
      clearTimeout(this.myTimeout);
      this.getClientDataBasic();
    }else{
      if(this.state.clientRetries<10){
        this.getClientsList();
        if(this._isMounted){
          this.setState({clientRetries: this.state.clientRetries+1})
        }
        this.myTimeout = setTimeout(() => {this.getInitialDataTime()}, 500);
      }else{
        if(this._isMounted){
          this.setState({clientRetries: 0})
        }
      }
    }
  }

  getClientsList = () => {
    if(this.props.clientsState !== ResponseStatus.PENDING){
      this.props.pendingRequestClient();
      axiosAuth.get("clients/list/0")
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
          let mapedRes = res.map((element)=>{
            for(let i=0; i<this.props.clientsPatientData.length; i++){
              if(this.props.clientsPatientData[i].id===element.id){
                element.locationP = this.props.clientsPatientData[i].patientAddress ? this.props.clientsPatientData[i].patientAddress.city:'';
                element.patientName = this.props.clientsPatientData[i].name;
                element.patient = this.props.clientsPatientData[i]
              }
            }
            return new ClientTable(element);}
          );
          this.props.setClientData(mapedRes);
        })
        .catch((error) => {this.props.clearDataClient();});
    }
  }

  getPatientList(){
    this.props.pendingPatientData();
    axiosAuth.get("patients/list/CLIENT/0/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        this.props.setPatientData(res);
        this.getInitialDataTime();
      })
      .catch((error) => {
        this.props.clearPatientData();
        this.getInitialDataTime();
      });
  }

  getDataBasicClient() {
    if(this._isMounted){
      this.setState({loadingBasics: true});
    }
    if (this.props.clientsPatientData.length === 0) {
      if (this.props.clientsPatientData.length !== 0 && this.props.clientsPatientData !== undefined) {
        this.getInitialDataTime();
      } else {
        this.getPatientList();
      }
    } else {
      this.getInitialDataTime();
    }
    this.getServiceData();
  }

  setActiveSection = (section) => {
    this.setState({
      activeSection: section
    });
  }

  get isOfferButtonVisibleInToolbar() {
    return this.state.activeSection === SECTION_OUTSIDE_MENU;
  }

  get isAnwendedButtonVisibleInToolbar() {
    return this.state.activeSection !== SECTION_OUTSIDE_MENU && this.state.activeSection !== OFFER_SECTION
  }

  getDataBasic(isFormDisabled, isCareBoxDisabled){
    const { translateKey } = this.props;
    const {
      form,
      rerender,
      showValidationErrors,
      isImportant,
      currentSection,
      sectionMenuOpen,
      loader,
      isOfferOpened,
      isOfferLoaded,
      activeSection,
      clientLeadBaseData,
      analizeFinished,
      formbbStatus,
      wholeResponse,
      multiplePatients,
      secondPatientForm,
      secondPatientId,
      isAditionalPatient
    } = this.state;
    let sectionData = (
      <LoaderTable />
    );

    if(!loader){
      switch (currentSection) {
        case 3:
          sectionData = (
            <div className="section-class-container">
              <SectionAngabenZurKontakt
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_client_data_p_0")}
                sectionNumber={4}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                activeSectionNumber={currentSection}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionAngabenZurKontakt>
            </div>
          );
          break;
        case 4:
          sectionData = (
            <div className="section-class-container">
              <SectionAngabenZurOrt
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_contact_data_p_1")}
                sectionNumber={5}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionAngabenZurOrt>
            </div>
          );
          break;
        case 5:
          sectionData = (
            <div className="section-class-container">
              <Section2
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_lead_data_p_2")}
                sectionNumber={6}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section2>
            </div>
          );
          break;
        case 6:
          sectionData = (
            <div className="section-class-container">
              <Section3
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_pflegegrad_data_p_3")}
                sectionNumber={7}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section3>
            </div>
          );
          break;
        case 7:
          sectionData = (
            <div className="section-class-container" >
              <Section4
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_ills_data_p_4")}
                sectionNumber={8}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section4>
            </div>
          );
          break;
        case 8:
          sectionData = (
            <div className="section-class-container">
              <Section5
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_comnunication_data_p_5")}
                sectionNumber={9}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section5>
            </div>
          );
          break;
        case 9:
          sectionData = (
            <div className="section-class-container">
              <Section6
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_orientation_data_p_6")}
                sectionNumber={10}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section6>
            </div>
          );
          break;
        case 10:
          sectionData = (
            <div className="section-class-container">
              <Section8
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_movement_data_p_8")}
                sectionNumber={11}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section8>
            </div>
          );
          break;
        case 11:
          sectionData = (
            <div className="section-class-container" >
              <SectionPflegedienstSimple
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_pflegedienst_data_p_14")}
                sectionNumber={12}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionPflegedienstSimple>
            </div>
          );
          break;
        case 12:
          sectionData = (
            <div className="section-class-container">
              <Section16
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_expectations_data_p_16")}
                sectionNumber={13}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section16>
            </div>
          );
          break;
        case 13:
          sectionData = (
            <div className="section-class-container" >
              <Section17
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_actions_data_p_17")}
                sectionNumber={14}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section17>
            </div>
          );
          break;
        case 14:
          sectionData = (
            <div className="section-class-container">
              <SectionBeschreibung
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_place_data_p_18")}
                sectionNumber={15}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionBeschreibung>
            </div>
          );
          break;
        case 15:
          sectionData = (
            <div className="section-class-container">
              <SectionInitialOffer
                form={form}
                rerender={rerender}
                toggleAnalizeLoader={this.toggleAnalizeLoader}
                prevPage={this.state.prevPageNumber}
                toggleOffAnalizer={this.toggleOffAnalizer}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionInitialOffer>
            </div>
          );
          break;
        case 16:
          sectionData = (
            <div className="section-class-container" >
              <SectionKrankheitsbilder
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_ills_data_p_4")}
                sectionNumber={16}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionKrankheitsbilder>
            </div>
          );
          break;
        case 17:
          sectionData = (
            <div className="section-class-container">
              <SectionKommunikation
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_comnunication_data_p_5")}
                sectionNumber={17}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionKommunikation>
            </div>
          );
          break;
        case 18:
          sectionData = (
            <div className="section-class-container" >
              <Section7
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_characteristic_data_p_7")}
                sectionNumber={18}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section7>
            </div>
          );
          break;
        case 19:
          sectionData = (
            <div className="section-class-container">
              <Section9
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_defecation_data_p_9")}
                sectionNumber={19}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section9>
            </div>
          );
          break;
        case 20:
          sectionData = (
            <div className="section-class-container">
              <Section10
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_hugiene_data_p_10")}
                sectionNumber={20}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section10>
            </div>
          );
          break;
        case 21:
          sectionData = (
            <div className="section-class-container">
              <Section11
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_foods_data_p_11")}
                sectionNumber={21}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section11>
            </div>
          );
          break;
        case 22:
          sectionData = (
            <div className="section-class-container">
              <Section12
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_clothes_data_p_12")}
                sectionNumber={22}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section12>
            </div>
          );
          break;
        case 23:
          sectionData = (
            <div className="section-class-container">
              <SectionRuhenUndSchlafen
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_sleep_data_p_13")}
                sectionNumber={23}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionRuhenUndSchlafen>
            </div>
          );
          break;
        case 24:
          sectionData = (
            <div className="section-class-container">
              <SectionPflegedienst
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_pflegedienst_data_p_14")}
                sectionNumber={24}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionPflegedienst>
            </div>
          );
          break;
        case 25:
          sectionData = (
            <div className="section-class-container">
              <SectionTherapien
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_therapy_data_p_15")}
                sectionNumber={25}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionTherapien>
            </div>
          );
          break;
        case 26:
          sectionData = (
            <div className="section-class-container">
              <SectionBeschreibungDerUnterkunft
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_place_data_p_18")}
                sectionNumber={26}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionBeschreibungDerUnterkunft>
            </div>
          );
          break;
        case 27:
          sectionData = (
            <div className="section-class-container">
              <SectionAnwesendBei
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("form-section-title-anwesend")}
                sectionNumber={27}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionAnwesendBei>
            </div>
          );
          break;
        case 28:
          sectionData = (
            <div className="section-class-container">
              <SectionNotiz
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("form-text-notiz")}
                sectionNumber={28}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionNotiz>
            </div>
          );
          break;
        case 29:
          sectionData = (
            <div className="section-class-container">
              <SectionFinalOffer
                form={form}
                rerender={rerender}
                toggleAnalizeLoader={this.toggleAnalizeLoader}
                prevPage={this.state.prevPageNumber}
                toggleOffAnalizer={this.toggleOffAnalizer}
                disabled={isFormDisabled}
                sectionNumber={29}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              />
            </div>
          );
          break;
        case 30:
          sectionData = (
            <div className="section-class-container">
              <SectionCareBox
                subsectionName={translateKey("section-care-box")}
                sectionNumber={30}
                form={form}
                showValidationErrors={showValidationErrors}
                disabled={isCareBoxDisabled}
                rerender={rerender}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              />
            </div>
          );
          break;
        case 31:
          sectionData = (
            <div className="section-class-container">
              <SectionOfferSummary
                formbbStatus={formbbStatus}
                form={form}
                rerender={rerender}
                toggleAnalizeLoader={this.toggleAnalizeLoader}
                prevPage={this.state.prevPageNumber}
                toggleOffAnalizer={this.toggleOffAnalizer}
                disabled={isFormDisabled}
                sectionNumber={30}
                isOfferOpened={isOfferOpened}
                isOfferLoaded={isOfferLoaded}
                activeSection={activeSection}
                setActiveSection={this.setActiveSection}
                previousPageClick={this.previousPageClick}
                clientLeadBaseData={clientLeadBaseData}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                wholeResponse={wholeResponse}
                userType="CLIENT"
                secondPatientForm={secondPatientForm}
                secondPatientId={secondPatientId}
                changeAditionalPateint={this.changeAditionalPateint}
                multiplePatients={multiplePatients}
                canCreateContract={!this.state.isFormDisabled}
              />
            </div>
          );
          break;
        default:
          sectionData = (
            <div className="section-class-container">
              <SectionAngabenZurKontakt
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_client_data_p_0")}
                sectionNumber={4}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                activeSectionNumber={currentSection}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionAngabenZurKontakt>
            </div>
          );
      }
    }
    const content = (
      <React.Fragment>
        <SectionNavigationToolbarComponent
          rerender={rerender}
          showSectionValidation = {true}
          changeSection={(number)=>this.changePage(number)}
          toggleSectionMenu={this.toggleSectionMenu}
          hideSectionMenu={this.hideSectionMenu}
          form={form}
          currentSection={currentSection}
          sectionMax={FormConfig.finalPageNumber}
          sectionMenuOpen={sectionMenuOpen}
          isUnderAnalize={this.state.analizeFinished}
          additionalToolbar={currentSection === 31}
          onOpenOffer={this.toggleOffer}
          setOfferLoaded={this.setOfferLoaded}
          isOfferButtonVisibleInToolbar={this.isOfferButtonVisibleInToolbar}
          isAnwendedButtonVisibleInToolbar={this.isAnwendedButtonVisibleInToolbar}
          setActiveSection={this.setActiveSection}
          toggleFullScreen={this.toggleFullScreen}
          type={RecordType.CLIENT}
          clientLeadBaseData={clientLeadBaseData}
          secondPatientForm={secondPatientForm}
          isAditionalPatient={isAditionalPatient}
        />
        {!sectionMenuOpen && sectionData }
      </React.Fragment>
    )
    return content;
  }

  toggleOffer = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        isOfferOpened: !prevState.isOfferOpened
      }
    })
  }

  setOfferLoaded = () => {
    this.setState({
      isOfferLoaded: true
    });
  }

  setActiveSection = (section) => {
    this.setState({
      activeSection: section
    });
  }

  getDataForPflegbox = () => {
    getCareBoxSets()
        .then(async res => {
          this.props.setCareBoxSetsList(res);
          const definedBoxesId = res.filter(item => item !== baseData.individualCareBoxId);
          const careBoxSets = await Promise.all(definedBoxesId.map(async item => {
            let dataToSend = [
              {
                Id: item.Id,
                Entity: 'is_care_box_set',
                Fields: [
                  "is_protective_aprons_number",
                  "is_bed_protectors_number",
                  "is_disposable_masks_number",
                  "is_disposable_ffp2_masks_number",
                  "is_disposable_gloves_number",
                  "is_surface_disinfectant_number",
                  "is_hand_sanitizer_number",
                  "is_reusable_bed_protectors_number",
                  "is_disposable_finger_overlay_number",
                  "is_surface_disinfectant_1000_number",
                  "is_hand_sanitizer_1000_number",
                  "is_reusable_protective_aprons_number",
                  "is_disinfecting_wipes_number",
                  "is_disposable_masks_ffp2_set_number"
                ]
              }
            ];
            return await getDynamicFields(dataToSend)
                .then(res => {
                  res[0].fields.id = item.Id;
                  return  res[0].fields
                })
                .catch(error => {
                  console.log(error);
                });
          }));

          this.props.setCareBoxSets(careBoxSets);
        })
        .then(async () => {
          let dataToSend = [
            {
              Id: baseData.individualCareBoxId,
              Entity: 'is_care_box_set',
              Fields: [
                "is_protective_aprons_return",
                "is_bed_protectors_return",
                "is_disposable_masks_return",
                "is_disposable_masks_ffp2_return",
                "is_one_time_gloves_return",
                "is_surface_disinfectant_500_return",
                "is_hand_sanitizer_500_return",
                "is_reusable_bed_protectors_return",
                "is_disposable_finger_overlay_return",
                "is_surface_disinfectant_1000_return",
                "is_hand_sanitizer_1000_return",
                "is_reusable_protective_aprons_return",
                "is_disinfecting_wipes_return",
                "is_disposable_masks_ffp2_set_return"
              ]
            }
          ];
          const careBoxPricesForIndividualSet =  await getDynamicFields(dataToSend)
              .then(res => {
                return  res[0].fields
              })
              .catch(error => {
                console.log(error);
              });
          this.props.setCareBoxIndividualPrices(careBoxPricesForIndividualSet);
        }).then(() => {
          const gloveTypeJson = {
            "EntityName": "is_care_box_ingridients",
            "AttributeName": "is_one_time_gloves_type"
          };
          getPicklist(gloveTypeJson).then(res => {
            this.props.setGloveType(res.Translations[0].Values);
          }).catch(error => {
            console.log(error)
          })
        }

    )
        .catch(error => {
          console.log(error);
        });
  }

  getCareBoxData = async () => {
    const { match: { params } } = this.props;
    const { form } = this.state;

    const response = await BBFormService.getCareBoxPflegebox(params.patientId);
    const filteredCraeboxes = response.data.filter(item => item.Status == '717700000').sort((a, b) => b.CreatedOn - a.CreatedOn);
    if(filteredCraeboxes.length > 0) {
      const filteredItem = filteredCraeboxes[0];
      this.props.setCareBoxIds({careBoxId: filteredItem.Id, ingredientsId: filteredItem.CareBoxIngridientId});

      await BBFormService.retrievePatient(form.getRetiveDataCareBox(filteredItem.Id)).then(resCareBox => {
        const { data } = resCareBox;
        data.forEach(entity => {
          let fields = entity.fields
          for(const element in fields) {
            if(element === 'is_pflegegrad') {
              fields.is_pflegegrad_duplicated = fields[element];
              delete fields['is_pflegegrad'];
            }
          }
          form.setFields(fields)
        });
      });

      await BBFormService.retrievePatient(form.getRetiveDataCareBoxIng(filteredItem.CareBoxIngridientId)).then(resCareBoxIng => {
        const { data } = resCareBoxIng;
        data.forEach(entity => {
          let fields = entity.fields;
          for(const element in fields) {
            if(toDuplicatIngredients.includes(element)) {
              fields[element.concat('_duplicated')] = fields[element];
              delete fields[element];
            }
          }

          form.setFields(fields)
        });
      });
    }
  }

  getMeInitLeadPatientsList(){
    const { match: { params }, clientsPatientData } = this.props;
    let pushedElements = [];
    let leadPatientsList = clientsPatientData.filter(element => {
        if(element.clientId === params.id){
            if(pushedElements.indexOf(element.id) > -1){
                return false;
            }else{
                pushedElements.push(element.id);
                return element
            }
        }else{
          return false;
        }
    })
    if(this._isMounted){
      this.setState({leadPatients: leadPatientsList})
    }
    return pushedElements;
  }

  showAlertMessage = (type, message) =>{
    if(this._isMounted){
      this.setState(prevState => {
        return {
            ...prevState,
            errorComponent: {
              ...prevState.errorComponent,
              show: true,
              type: type,
              message: message
            }
        }
      })
    }
    this.clearAlertComponent(true);
  }

  showSaveModal = () =>{
    if(this._isMounted){
      this.setState({savingData: !this.state.savingData})
    }
  }

  hideSaveModal = () =>{
    if(this._isMounted){
      this.setState({savingData: false})
    }
  }


  getServiceData() {
    const {
      match: {params}
    } = this.props;
    const { form } = this.state;
    let dataToSend = [
      {
        Id: params.id,
        Entity: Entity.CONTACT,
        Fields: ["is_service_type_de"]
      }
    ];
    getDynamicFields(dataToSend)
        .then(res => {
          if (res.length > 0) {
            let status = res[0].fields["is_service_type_de"]
            if (status === null || status === undefined) {
              status = '717700001'
            }
            if (this._isMounted) {
              this.setState({
                isServiceType: status,
                isCareBoxDisabled : !form.isEditableClientCareBox(status),
                isFormDisabled: !form.isEditableClient(status)
              });
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
  }

  getMeClassesToMainContainer=()=>{
    const {  hideMeAll, currentSection, sectionMenuOpen} = this.state;
    let classesForContainer = '';
    if(hideMeAll){
      classesForContainer+='fullScreenClass allow-overflow';
    }else{
      if(currentSection===5 || currentSection===3 || sectionMenuOpen || currentSection===12){
        classesForContainer+='allow-overflow';
      }
    }
    return classesForContainer;
  }

  render() {
    const { hideBar, hideMeAll, modalLoader, savingData, breadCrumbs  } = this.state;
    const { translateKey } = this.props;
    return (
      <div className={`mainContentWrapper ${hideMeAll?'white':''}`} onClick={() => { this.props.tookAction() }}>
        <NavBar breadCrumbs={breadCrumbs} className={`${hideMeAll?'hideMeAllClass':''}`}/>
        <TableProperties className={`${hideMeAll?'hideMeAllClass':''}`} hideBar={hideBar} options={[]} filterData={[]}/>
        <div className="mainContent">
          <SideBar className={`${hideMeAll?'hideMeAllClass':''}`}/>
          <LoaderModal show={savingData || modalLoader} text={savingData?translateKey("saving_with_dots"):''}/>
          <div className={`listDataWrapper ${this.getMeClassesToMainContainer()}`}>
            {this.getDataBasic(this.state.isFormDisabled, this.state.isCareBoxDisabled)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    clientsData: state.database.clients,
    clientsState: state.database.clientsState,
    clientsPatientData: state.database.patientClient,
    clientsPatientDataStatus: state.database.patientClientState,
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  getProducts: () => dispatch(getUserProducts()),
  getRegions: () => dispatch(getRegionsFP()),
  setClientData: (data) => dispatch(setClientsData(data)),
  pendingRequestClient: () => dispatch(pendingClientsData()),
  clearDataClient: () => dispatch(removeClientsData()),
  getPatientClientData: (lang) => dispatch(getPatientClient(lang)),
  setPatientData: (data) => dispatch(setPatientClientData(data)),
  pendingPatientData: () => dispatch(pendingPatientClientData()),
  clearPatientData: () => dispatch(removePatientClientData()),
  setCareBoxIds: (data) => dispatch(setCareBoxIds(data)),
  setCareBoxSets: (data) => dispatch(setCareBoxSets(data)),
  setCareBoxSetsList: (data) => dispatch(setCareBoxSetsList(data)),
  setGloveType: (data) => dispatch(setGloveType(data)),
  setCareBoxIndividualPrices: (data) => dispatch(setCareBoxIndividualPrices(data)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainClientBBWrapper));