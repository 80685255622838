// enums related to /lists/dictionary/OPAvailibility

export const GENDER = {
  WOMAN: "F",
  MAN: "M"
}

export const LICENSE = {
  YES: "Yes",
  NO: "No"
}

export const PRODUCT = {
  PRODUCT_100: '100',
  PRODUCT_300: '300',
  PRODUCT_500: '500',
  PRODUCT_1000: '1000'
}

export const OPTIONS = {
  OPTION_NONE: "none",
  OPTION_DIPLOMA: "diploma",
  OPTION_2NDPAT: "2ndpat",
  OPTION_2NDLANG: "2ndlang",
  OPTION_PLUS: "plus",
  OPTION_MIX: "mix"
}
