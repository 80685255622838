import { SET_CUSTOMERS_OPTIONS } from "../actions/customers-options-types";

const initialState = {
  customersOptions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMERS_OPTIONS:
      return {
        ...state,
        customersOptions: action.customersOptions,
      };
    default:
      return state;
  }
};

export default reducer;