import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from '../../../../_redux/actions/index';
import { InputTypeTextComponent } from '../../../form-bb-components/input-type-text-component/input-type-text-component';
import { CheckBoxComponent } from "../../../form-bb-components/checkbox-component/checkbox-component";
import produce from 'immer';
import SelectComponent from '../../../form-bb-components/select-component/SelectComponent';
import WrappedDatePickerComponent from '../../../form-bb-components/wrapped-date-picker-component/wrapped-date-picker-component';
import * as Relationship from '../../../../_forms/BBForm/values/relationship';
import '../SectionDieBestellung.css';

//keys to contract begin and end
const startDayKey = 'is_start_date';
const endDateKey = 'is_end_date';
const dateValidKey = 'skip_date_valid';
const isDegreeRelationship = 'is_degree_relationship_with_patient_carer';

class SectionDieBestellungFirst extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      section: props.contractForm.getSectionByKey('contract-section-first'),
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.setInitDlv();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setInitDlv = () => {
    const { form } = this.props;
    const isDegreeRelationshipField = form.getFieldByKey(isDegreeRelationship);
    if (isDegreeRelationshipField.value === Relationship.TYPE_8) {
      this.setTypeChange('skip_is_dlv_docusign', '717700002');
    }
  };

  setTypeChange = (key, value) => {
    const nextState = produce(this.state, (draftState) => {
      const { section } = draftState;
      const field = section.getFieldByKey(key);
      if (field) {
        if(field.key === 'is_special_arrangements') {
          if(value){
            field.value = '717700014';
          } else {
            field.value = '';
          } 
        } else {
          field.value = value;
        }
      }
      this.setState(nextState);
      this.setState({ rerender: Date.now() });
    });
    if (key === startDayKey || key === endDateKey) {
      this.props.updateContractDateOptions();
    }
  };

  setTypeChangeDates = (key, value) => {
    const nextState = produce(this.state, (draftState) => {
      const { section } = draftState;
      const field = section.getFieldByKey(key);
      // const date = new Date(value * 1000)
      // const utcMilllisecondsSinceEpoch = date.getTime() - (date.getTimezoneOffset() * 60 * 1000);
      // const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000);
      if (field) {
        // field.value = utcSecondsSinceEpoch;
        field.value = value;
      }
      if (key === startDayKey) {
        let endDate = new Date(
          // new Date(utcSecondsSinceEpoch * 1000).setFullYear(
          //   new Date(utcSecondsSinceEpoch * 1000).getFullYear() + 1
            new Date(value * 1000).setFullYear(
              new Date(value * 1000).getFullYear() + 1
          )
        );
        endDate.setDate(endDate.getDate() - 2);
        const field = section.getFieldByKey(endDateKey);
        if (field) {
          field.value = Math.floor(endDate.getTime() / 1000);
        }
      }
    });
    this.setState(nextState);
  };

  getMeMaxDate = () => {
    const { section } = this.state;
    const isStartDateContact = section.getFieldByKey(startDayKey);
    let endDate = new Date(
      new Date(isStartDateContact.value * 1000).setFullYear(
        new Date(isStartDateContact.value * 1000).getFullYear() + 1
      )
    );
    endDate.setDate(endDate.getDate() - 2);
    return endDate;
  };

  getMeMaxDateBeginContract = () => {
    const { section } = this.state;
    const isStartDateContact = section.getFieldByKey(startDayKey);
    return new Date(isStartDateContact.value * 1000);
  };

  renderProductItem = (label) => {
    return (
      <div
        className="product-item"
        key={Math.random().toString(36).substr(2, 9)}>
        {label}
      </div>
    );
  };

  findAvaliablePack = (itemToFound) => {
    const { availableLanguagePackets } = this.props;
    const ItemFound = availableLanguagePackets.find(
      (item) => item.id === itemToFound
    );
    if (ItemFound) {
      return ItemFound.label;
    }
    return '';
  };

  findAvaliableAdditionPack = (itemToFound) => {
    const { availableAdditionalOptions } = this.props;
    const ItemFound = availableAdditionalOptions.find(
      (item) => item.id === itemToFound
    );
    if (ItemFound) {
      return ItemFound.label;
    }
    return '';
  };

  render() {
    const {
      translateKey,
      selectedLanguagePacket,
      selectedAdditionalOptions,
      showValidationErrors,
      isFranchiseRegionText,
      form,
      isAditionalPatient
    } = this.props;
    const { section } = this.state;

    const isStartDate = section.getFieldByKey(startDayKey);
    const isEndDate = section.getFieldByKey(endDateKey);
    const isSkipDateValid = section.getFieldByKey(dateValidKey);
    const isContractDate = section.getFieldByKey('is_contract_date');
    const isSkipPatientFullName = section.getFieldByKey(
      'skip_patient_full_name'
    );
    const isSkipPcmNameContract = section.getFieldByKey(
      'skip_pcm_name_contract'
    );
    const isSkipPcmEmailContract = section.getFieldByKey(
      'skip_pcm_email_contract'
    );
    const isSkipCityDocusign = section.getFieldByKey('skip_is_city_docusign');
    const isSkipDlvDocusign = section.getFieldByKey('skip_is_dlv_docusign');
    const signatureMethodDocusign = section.getFieldByKey('signature_method_docusign');
    const isSpecialArrangements = section.getFieldByKey('is_special_arrangements');

    const isDegreeRelationshipField = form.getFieldByKey(isDegreeRelationship);

    const startDate = new Date(isStartDate.value * 1000);
    const endDate = new Date(isEndDate.value * 1000);
    const startDatePlusMounths = new Date(
      startDate.setMonth(startDate.getMonth() + 2)
    );
    const isLessThan2Months =
      startDate && endDate && startDatePlusMounths > endDate;

    isSkipDateValid.value = isLessThan2Months ? '' : 'valid';

    let DlvList = [];
    const SignatureMethodeList = [
      {
        value: 'VorOrt',
          label: translateKey(
            'die-bestellung-form-label-signature-method-option1'
          ),
      },
      {
        value: 'Email',
          label: translateKey(
            'die-bestellung-form-label-signature-method-option2'
          ),
      }
    ];

    if(isAditionalPatient) {
      DlvList = [
        {
          value: '717700000',
          label: translateKey(
            'die-bestellung-form-label-dlv-option1'
          ),
        },
        {
          value: '717700001',
          label: translateKey(
            'die-bestellung-form-label-dlv-option2'
          ),
        },
        {
          value: '717700002',
          label: translateKey(
            'die-bestellung-form-label-dlv-option3'
          ),
        },
      ];
    } else {
      DlvList = [
        {
          value: '717700000',
          label: translateKey(
            'die-bestellung-form-label-dlv-option1'
          ),
        },
        {
          value: '717700001',
          label: translateKey(
            'die-bestellung-form-label-dlv-option2'
          ),
        },
      ];
    }

    return (
      <React.Fragment>
        <form className="new-section-content-wrapper container form_serializer">
          <fieldset
            disabled={false}
            className={`${false ? 'no-pointer-events' : ''}`}>
            <div className="newUI-section-padding">
              <div className="row">
                <InputTypeTextComponent
                  newUI={true}
                  showValidation={showValidationErrors}
                  className="col-sm-12 col-lg-8 form-margin-bottom-fix"
                  setTypeChangeToParent={this.setTypeChange}
                  field={isSkipPatientFullName}
                  text={translateKey(
                    'die-bestellung-form-label-pflegebedurftiger-den-die-suchanfrage-betrifft'
                  )}
                  keyName="skip_patient_full_name"
                />
              </div>
            </div>
            <div className="newUI-section-padding">
              <div className="row">
                <div className="col-sm-12 col-lg-4 form-margin-bottom-fix">
                    <WrappedDatePickerComponent
                        newUI={true}
                        withCalendarIcon={true}
                        showValidation={showValidationErrors}
                        keyName={startDayKey}
                        value={isStartDate.value}
                        validationErrors={isStartDate.validationErrors}
                        text={translateKey('contract-text-voraussichtlicher-begin')}
                        setTypeChangeToParent={this.setTypeChangeDates}
                        disabled={false}
                        withYear={true}
                        withMin={new Date()}
                        letWrite
                    />
                </div>
                <div className="col-sm-12 col-lg-4 form-margin-bottom-fix">
                    <WrappedDatePickerComponent
                        newUI={true}
                        withCalendarIcon={true}
                        showValidation={showValidationErrors}
                        keyName={endDateKey}
                        value={isEndDate.value}
                        validationErrors={isEndDate.validationErrors}
                        text={translateKey('contract-text-voraussichtlicher-dauer')}
                        setTypeChangeToParent={this.setTypeChangeDates}
                        disabled={false}
                        withYear={true}
                        withMin={
                            isStartDate.value
                            ? startDatePlusMounths
                            : new Date()
                        }
                        withMax={this.getMeMaxDate()}
                        letWrite
                    />
                    {isLessThan2Months && (
                        <div className="is-invalid">
                        <div className="invalid-feedback">
                            {translateKey('contract-text-voraussichtlicher-difference')}
                        </div>
                        </div>
                    )}
                </div>
              </div>
              <div style={{display: 'none'}}>
                <InputTypeTextComponent
                    newUI={true}
                    showValidation={showValidationErrors}
                    className="col-sm-12 col-lg-8 form-margin-bottom-fix"
                    setTypeChangeToParent={this.setTypeChange}
                    field={isSkipDateValid}
                    text={''}
                    keyName={dateValidKey}
                />
              </div>
            </div>
            <div className="newUI-section-padding">
              <div className="row">
                <div className="col-sm-12 col-lg-12">
                  <div className="form-label-basic newUI quick-label-position-fix">
                    {translateKey('die-bestellunf-form-label-produkt')}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-lg-12 d-flex flex-wrap">
                  {this.renderProductItem(
                    translateKey(this.findAvaliablePack(selectedLanguagePacket))
                  )}
                  {selectedAdditionalOptions.map((id) =>
                    this.renderProductItem(
                      translateKey(this.findAvaliableAdditionPack(id))
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="newUI-section-padding">
              <div className="row">
                <InputTypeTextComponent
                  newUI={true}
                  showValidation={showValidationErrors}
                  className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                  setTypeChangeToParent={this.setTypeChange}
                  field={isSkipCityDocusign}
                  text={translateKey(
                    'die-bestellung-form-label-ort-der-vertragsunterzeichnung'
                  )}
                  transKey="die-bestellung-form-label-ort-der-vertragsunterzeichnung"
                  keyName="skip_is_city_docusign"
                />
                <div className="col-sm-12 col-lg-4 form-margin-bottom-fix">
                  <label className="form-label-basic newUI">
                    <span className="quick-label-position-fix">
                      {translateKey(
                        'die-bestellung-form-label-region-franchise'
                      )}
                    </span>
                  </label>
                  <input
                    type="text"
                    disabled={true}
                    className="form-control newUI"
                    value={isFranchiseRegionText ? isFranchiseRegionText : ''}
                  />
                </div>
                <WrappedDatePickerComponent
                  newUI={true}
                  className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                  showValidation={showValidationErrors}
                  keyName="is_contract_date"
                  value={isContractDate.value}
                  validationErrors={isContractDate.validationErrors}
                  text={translateKey('contract-text-start-date')}
                  setTypeChangeToParent={this.setTypeChange}
                  disabled={false}
                  withMax={this.getMeMaxDateBeginContract()}
                />
              </div>
            </div>
              <div className="newUI-section-padding">
                <div className="row">
                  <SelectComponent
                    newUI={true}
                    className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                    showValidation={showValidationErrors}
                    keyName="signature_method_docusign"
                    valuesList={SignatureMethodeList}
                    value={signatureMethodDocusign.value}
                    validationErrors={signatureMethodDocusign.validationErrors}
                    label={translateKey('die-bestellung-form-label-signature-method')}
                    setTypeChangeToParent={this.setTypeChange}
                    chooseText={translateKey('select')}
                  />
                  {((isDegreeRelationshipField.value !== Relationship.TYPE_8) || isAditionalPatient) && (
                    <SelectComponent
                      newUI={true}
                      className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                      showValidation={showValidationErrors}
                      keyName="skip_is_dlv_docusign"
                      valuesList={DlvList}
                      value={isSkipDlvDocusign.value}
                      validationErrors={isSkipDlvDocusign.validationErrors}
                      label={translateKey('die-bestellung-form-label-dlv')}
                      setTypeChangeToParent={this.setTypeChange}
                      chooseText={translateKey('select')}
                    />
                  )}
                  <div className="col-sm-12 col-lg-2">
                    <label className="form-label-basic newUI">
                      <span className="quick-label-position-fix">
                        {translateKey(
                          'express_service'
                        ).toUpperCase()}
                      </span>
                    </label>
                    <CheckBoxComponent
                      newUI={true}
                      checkClassWrapper='newUI-radio-check-box-class'
                      showValidation={showValidationErrors}
                      className='form-margin-bottom-fix'
                      setTypeChangeToParent={this.setTypeChange}
                      value={isSpecialArrangements.value}
                      validationErrors={isSpecialArrangements.validationErrors}
                      text={translateKey("express_service")}
                      transKey="is_special_arrangements"
                      keyName="is_special_arrangements"
                    />
                  </div>
                </div>
              </div>
            
            <div className="newUI-section-padding">
              <div className="row">
                <InputTypeTextComponent
                  newUI={true}
                  showValidation={showValidationErrors}
                  className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                  setTypeChangeToParent={this.setTypeChange}
                  field={isSkipPcmNameContract}
                  text={translateKey('die-bestellung-form-pcm-name')}
                  transKey="die-bestellung-form-pcm-name"
                  keyName="skip_pcm_name_contract"
                />
                <InputTypeTextComponent
                  newUI={true}
                  showValidation={showValidationErrors}
                  className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                  setTypeChangeToParent={this.setTypeChange}
                  field={isSkipPcmEmailContract}
                  text={translateKey('die-bestellung-form-pcm-email')}
                  transKey="die-bestellung-form-pcm-email"
                  keyName="skip_pcm_email_contract"
                />
              </div>
            </div>
          </fieldset>
        </form>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  translateKey: (firstLvl, secondLvl) =>
    dispatch(translateKey(firstLvl, secondLvl)),
});
export default withRouter(
  connect(null, mapDispatchToProps)(SectionDieBestellungFirst)
);
