import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import PriorityOffer from '../../../_constance/classes/dndPriorityOffer/PriorityOffer';
import TileWrapperComponent from "../../form-bb-components/init-offer-elements/tile-wrapper-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import produce from "immer";

// key needed to retrieve information of 3 the most things for patient from last section in form
const MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME = 'is_most_important_taking_care_patient';

class SectionInitialOffer extends React.Component {
  _isMounted = false;
  myTimeout
  constructor(props) {
    super(props);
    this.state = {
      element: props.data,
      analizeOffer: true,
      progressWidth: 0,
      myInterval: null,
      choosenOptionAmount: 0,
      priorityOffer: new PriorityOffer(),
      section: props.form.getSectionByKey('section-16'),
      tempSection: props.form.getSectionByKey('section-initial-offer'),
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.startProgress();
    this.setInitialOfferToTempSection();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.myInterval);
    clearTimeout(this.myTimeout);
  };

  setInitialOfferToTempSection = () => {
    const { form } = this.props;
    const { tempSection } = this.state;

    const sourceDataField = form.getFieldByKey(MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME);
    const tempField = tempSection.getFieldByKey(MOST_IMPORTANT_THINGS_OF_TAKING_CARE_PATIENT_KEY_NAME);

    tempField.value = sourceDataField.value;
  }

  setTypeChange = (key, value) => {
    const nextState = produce(this.state, draftState => {
      const { section, tempSection } = draftState;
      const field = section.getFieldByKey(key);
      if (field) {
        field.value = value;
      }
      const fieldT = tempSection.getFieldByKey(key);
      if (fieldT) {
        fieldT.value = value;
      }
    });
    this.setState(nextState);
  };

  analizeComplete = () => {
    this.props.toggleAnalizeLoader();
    this.setState({ analizeOffer: false })
  }

  increaseProgress = () => {
    if(this._isMounted) {
      if (this.state.progressWidth >= 100) {
        clearInterval(this.state.myInterval);
        this.myTimeout = setTimeout(() => {
          this.analizeComplete()
        }, 1000);
      } else {
        this.setState(previousState => ({
          ...previousState,
          progressWidth: previousState.progressWidth + 2
        }));
      }
    }
  }

  startProgress = () => {
    const { disabled, prevPage, sectionNumber, toggleAnalizeLoader, toggleOffAnalizer } = this.props;
    if (disabled) {
      toggleOffAnalizer();
      this.setState({ analizeOffer: false })
    } else {
      if ((prevPage < sectionNumber || !prevPage) && this.state.analizeOffer === true) {
        toggleAnalizeLoader();
        let intervalId = setInterval(() => {
          this.increaseProgress();
        }, 100);
        this.setState({ myInterval: intervalId });
      } else {
        toggleOffAnalizer();
        this.setState({ analizeOffer: false })
      }
    }
  }

  render() {
    const {
      translateKey,
      analizeFinished,
      currentSection,
      rerender,
      previousPageClick,
      nextPageClick,
      finishMe,
      form,
      disabled
    } = this.props;
    const { analizeOffer, progressWidth, section } = this.state;
    return (
      <div className="form-section-main container">
        {analizeOffer ?
          <React.Fragment>
            <div className="offer-analize-wrapper">
              <div className="progres-title-wrapper">
                {translateKey("section-analize-offer")}
              </div>
              <div className="progress special-progress">
                <div className="progress-bar prom-progress-color" role="progressbar" style={{ width: progressWidth + "%" }}></div>
              </div>
            </div>
          </React.Fragment>
          :
          <React.Fragment>
            <div className="new-section-name-wrapper">
              {translateKey("section-initial-offer")}
            </div>
            <div className="init-offer-subtitle-wrapper text-align-center">
              {translateKey("form-text-erstangebot-subsection")}
            </div>
            <div className="init-offer-subtitle-wrapper text-align-center">
              {translateKey("form-text-erstangebot-subsection2")}
            </div>
            <div className="prior-section-wrapper">
              <TileWrapperComponent 
                className=''
                setTypeChangeToParent={this.setTypeChange}
                section={section}
                showAll={false}
                form={form}
              ></TileWrapperComponent>
            </div>
            <SectionNavigationButtonsComponent
              form={form}
              analizeFinished={analizeFinished}
              currentSection={currentSection}
              rerender={rerender}
              previousPageClick={previousPageClick}
              nextPageClick={nextPageClick}
              finishMe={finishMe}
              showNotePop={disabled?false:true}
            />
          </React.Fragment>
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) })
export default withRouter(connect(null, mapDispatchToProps)(SectionInitialOffer));