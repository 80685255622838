export const NEW_LEAD = "717700000"
export const FIRST_CONTACT_MADE = "717700001"
export const MEETING_PLANNED = "717700002"
export const MEETING_HELD = "717700003"
export const CONTRACT_SIGNED = "717700004"
export const REJECTED = "717700005"
export const ON_HOLD = "717700006"
export const MEETING_PLANNED_DE = "717700008"
export const CONTACT_ATTEMPTED = "717700009"
export const RECONTACT_INFOFP = "717700010"
export const INITIAL_MEETING = "717700011"
export const RENEWED_CONTACT = "717700012"
export const INTERESTS_NOT_ACHIVED = "717700013"
export const CARE_ASSESSMENT_COMPLETED = "717700014"
export const CONTACT_AWAITING = "717700015"
export const CONTACT_REJECTED = "717700016"
export const CONTACT_INQUIRY = "717700017"
export const CONTACT_TO_BE_ACTIVATED = "717700018"
export const WEITERGELEITET_AN_FRANCHISEPARTNER = "717700016"
export const ABSAGE_VERFAHRENSGRUNDE = "717700017"
export const INFOMATERIAL_TERMINEINLADUG_VERSCHICK = "717700015"
export const REAKTIVIERT_DURCH_QUALITATSMANAGEMENT = "717700019"

export const DE_LIST_DASHBOARD = [
    FIRST_CONTACT_MADE, 
    MEETING_PLANNED_DE, 
    MEETING_HELD, 
    MEETING_PLANNED, 
    CONTRACT_SIGNED, 
    RECONTACT_INFOFP, 
    CONTACT_ATTEMPTED, 
    INITIAL_MEETING, 
    RENEWED_CONTACT, 
    REJECTED, 
    NEW_LEAD, 
    INTERESTS_NOT_ACHIVED,
    INFOMATERIAL_TERMINEINLADUG_VERSCHICK,
    WEITERGELEITET_AN_FRANCHISEPARTNER,
    ABSAGE_VERFAHRENSGRUNDE,
    REAKTIVIERT_DURCH_QUALITATSMANAGEMENT,
]
export const EN_LIST_DASHBOARD = [
    NEW_LEAD,
    CONTACT_ATTEMPTED,
    FIRST_CONTACT_MADE,
    RECONTACT_INFOFP,
    MEETING_PLANNED,
    MEETING_HELD,
    INITIAL_MEETING, 
    RENEWED_CONTACT,
    INTERESTS_NOT_ACHIVED,
    CARE_ASSESSMENT_COMPLETED,
    CONTACT_AWAITING,
    CONTACT_REJECTED,
    CONTACT_INQUIRY,
    CONTACT_TO_BE_ACTIVATED,
    REJECTED,
    MEETING_PLANNED_DE,
    ON_HOLD
]