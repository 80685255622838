import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey, getPatientLead, addPatientData, updatePatientData  } from "../../_redux/actions/index";
import { savePatientData } from "../../_redux/actions/auth";
import { TableColumHead, TablePagination, LoaderTable, EmptyTableIcon, LoaderModal, AlertMessage } from "../index";
import { SortingPatient } from "../../_constance/classes/sortingClasses/index";
import { Alert, Paging,} from "../../_constance/classes/index";
import { ReqHelper } from "../../_helpers/index";
import { ResponseStatus } from '../../_helpers/response_status'
import * as LanguageSelect from "../../_constance/values/languageSelect";
import { baseData } from '../../_constance/base_data';

class CareBoxDataComponent extends React.Component {
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id: props.id,
            sorting: SortingPatient,
            errorComponent: Alert,
            pagingItem: Paging,
            showDetails: false,
            patientToShow: '',
            backUpPatient: '',
            element: this.props.element,
            loading: this.props.mainLoader,
            gotAnyLeadData: this.props.gotLead,
            loadingPatients: true,
            loadModal: false,
            edited: false,
            errorsMessage: null,
            errorsType: "danger",
        };
    }

    async componentDidMount() {
        document.body.classList.remove('modal-open');
        await this.props.getPatientLeadData(this.props.appLang);
        this.getInitialPatient();
    }

    componentWillUnmount() {clearTimeout(this.myTimeout);};

    getInitialPatient(){
        if(this.props.patientLeadStatus === ResponseStatus.READY){
          clearTimeout(this.myTimeout);
          this.setState({loadingPatients: false})
          this.filterOrDataChange();
        }else{
          if(this.props.patientLeadStatus === ResponseStatus.FREE){
            this.props.getPatientLeadData(this.props.appLang);
            this.myTimeout = setTimeout(() => {
              this.getInitialPatient()
            }, 500);
          }else{
            if(this.props.patientLeadStatus === ResponseStatus.ERROR){
              clearTimeout(this.myTimeout);
              this.setState({ loadingPatients: false })
            }else{
              this.myTimeout = setTimeout(() => {
                this.getInitialPatient()
              }, 500);
            }
          }
        }
    }

    getBasicPatientLead(){
        this.setState({loadingPatients: true})
        this.props.getPatientLeadData(this.props.appLang);
        this.getInitialPatient();
    }

    openFullscreen() {
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
          elem.msRequestFullscreen();
        }
    }

    openModalWithData(element){
        const { match: { params }, history } = this.props;
        if(this.props.appLang === LanguageSelect.EN){
            history.push(`${baseData.subFolders}leadPatient/${params.leadId}/${element.id}`);
        }else{
            if (!document.fullscreenElement) {
                this.openFullscreen();
            } 
            history.push(`${baseData.subFolders}care-box-form/${params.leadId}/${element.id}`);
        }
    }

    filterOrDataChange(){
        this.setState({loadingPatients: true });
        const { match: { params } } = this.props;
        let pushedElements = [];
        let finalDataToShow = this.props.patientLeadData.filter(element => {
            if(element.leadId === params.leadId){
                if(pushedElements.indexOf(element.id) > -1){
                    return false;
                }else{
                    pushedElements.push(element.id);
                    return element
                }
            }else{
              return false;
            }
        })
        this.propsSortingData(finalDataToShow);
        this.propsPagingData(finalDataToShow);
        setTimeout(() => {
            let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
            this.setState({
                data: finalData,
                loadingPatients: false
            })
        }, 50);
    }
    
    propsSortType(type){
        let sortingObj = [];
        for(let i=0; i<this.state.sorting.length; i++){
            if(this.state.sorting[i].column===type){
            if(this.state.sorting[i].active===true){
                let item = this.state.sorting[i];
                item.type==='ASC'? item.type="DESC" : item.type='ASC';
                sortingObj.push(item);
            }else{
                let item = this.state.sorting[i];
                item.type='ASC';
                item.active = true;
                sortingObj.push(item);
            }
            }else{
            let item = this.state.sorting[i];
            item.type='ASC';
            item.active = false;
            sortingObj.push(item);
            }
        }
        this.setState({sorting: sortingObj});
        this.filterOrDataChange();
    }
    
    propsSortingData(data){
        let sortMeBy;
        for(let i=0; i<this.state.sorting.length; i++){
            if(this.state.sorting[i].active===true){
                sortMeBy = this.state.sorting[i];
            }
        }
        if(sortMeBy){
            if(sortMeBy.format === "text"){
            data.sort((a,b)=>{
                let nameA=a[sortMeBy.column], nameB=b[sortMeBy.column];
                if(!ReqHelper.isNumber(parseInt(a[sortMeBy.column]))){
                if(a[sortMeBy.column]){
                    nameA=a[sortMeBy.column].toString().toLowerCase()
                }
                if(nameB){
                    nameB=b[sortMeBy.column].toString().toLowerCase()
                }
                }
                if(sortMeBy.type!=="ASC"){
                    if (nameA === null || nameA === undefined){return -1;}
                    else if (nameB === null || nameB === undefined) {return 1;}
                    else if (nameA === nameB) {return 0;}
                    if(ReqHelper.isNumber(parseInt(a[sortMeBy.column]))){
                        if (nameA < nameB){ return -1; }
                        else{ return 1; }
                    }else{
                        if (nameA.localeCompare(nameB)===-1){ return -1; }
                        else{ return 1; }
                    }
                }else{
                    if (nameA === null || nameA === undefined) {return 1;}
                    else if (nameB === null|| nameB === undefined) {return -1;}
                    else if (nameA === nameB) {return 0;}
                    if(ReqHelper.isNumber(parseInt(a[sortMeBy.column]))){
                        if (nameA > nameB){return -1;}
                        else{ return 1; }
                    }else{
                        if (nameB.localeCompare(nameA)===-1){return -1;}
                        else{ return 1; }
                    }
                }
            })
            }else{
            if(sortMeBy.format === "date"){
                data.sort((a,b)=>{
                let nameA=a[sortMeBy.column]*1000, nameB=b[sortMeBy.column]*1000;
                if(sortMeBy.type!=="ASC"){
                    if (nameA === null || nameA === undefined) {return -1;}
                    else if (nameB === null || nameB === undefined) {return 1;}
                    else if (nameA === nameB) {return 0;}
                    if (nameA < nameB){ return -1; }
                    else{ return 1; }
                }else{
                    if (nameA === null || nameA === undefined) {return 1;}
                    else if (nameB === null || nameB === undefined) {return -1;}
                    else if (nameA === nameB) {return 0;}
                    if (nameA > nameB){return -1;}
                    else{ return 1; }
                }
                })
            }
            }
        }
        return data;
    }

    propsPagingData(data){
        if(data.length<=25){
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                    totalItems: data.length,
                    itemsPerPage: prevState.pagingItem.itemsPerPage,
                    currentPage: 1,
                    visiblePages: 5,
                    pageFrom: 1,
                    pageTo: 5,
                    totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }else{
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                    ...prevState.pagingItem,
                    totalItems: data.length,
                    pageFrom: prevState.pagingItem.pageFrom,
                    pageTo: prevState.pagingItem.pageTo,
                    currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                    totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }
    }

    editToggle = () => {
        if(this.state.showDetails){
            document.body.classList.remove('modal-open');
        }
        this.setState({
            showDetails: !this.state.showDetails,
            patientToShow: JSON.parse(JSON.stringify(this.state.backUpPatient))
        });
    }

    addClick = () =>{
        const { match: { params }, history } = this.props;
        history.push(`${baseData.subFolders}leadPatient/${params.leadId}/0`);
    }

    save(){
        if(this.state.edited){
            this.setState({loaderModal:true})
            let elementToSave = this.state.patientToShow;
            if(this.state.patientToShow.id===0){
                const { match: { params } } = this.props;
                elementToSave.leadId =  params.leadId
            }
            this.props.savePatientData(elementToSave)
            .then((res)=>{
              this.setState({
                loaderModal: false,
                errorsMessage: "patient_successfully_saved",
                errorsType: "success",
                edited: false,
              })      
              clearTimeout(this.myTimeout);
              this.myTimeout = setTimeout(() => {
                this.setState({
                  errorsMessage: null,
                  errorsType: "danger",
                  showDetails: false
                }) 
              }, 1500);
              if(elementToSave.id===0){
                this.props.addPatient(res, elementToSave);
                this.filterOrDataChange();
              }else{
                this.props.updatePatient(elementToSave.id, elementToSave);
                this.filterOrDataChange();
              }
            })
            .catch((err)=>{
                this.setState(prevState => {
                  return {
                      ...prevState,
                      loaderModal: false,
                      errorsMessage: "patient_not_saved",
                      errorsType: "danger"
                  }
                });
                clearTimeout(this.myTimeout);
                this.myTimeout = setTimeout(() => {
                  this.setState({
                    errorsMessage: null,
                    errorsType: "danger"
                  }) 
                }, 3000);
            })
        }
    }

    editedData(){
        if(!this.state.editBasic){
            this.setState({
              edited: true
            })
        }
    }

    render() {
        let emptyHandler;
        let patientData;
        if (this.props.mainLoader) {
            emptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
        }else{
            if (this.props.gotAnyLeadData) {
                emptyHandler = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.GetLeadData()} text={this.props.translateKey("lead_does_not_exist")} /></div>
            }else{
                if(this.state.data.length === 0) {
                    emptyHandler = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicPatientLead()} text={this.props.translateKey("no_data_to_show")} /></div>
                }else{
                    patientData = this.state.data.map(element => (
                    <tr className="table-row-wrapper cursor-pointer" key={element.id} onClick={()=>{this.openModalWithData(element)} }>
                        <td className={"table-cell-wrapper " + (element.name && element.name.trim() ? "got-data" : "no-data")}>{element.name && element.name.trim() ? element.name : this.props.translateKey("lead-details_name_unknown")}</td>
                        <td className="table-cell-wrapper"></td>
                    </tr>
                    ))
                }
            }
        }
        let SaveAlert;
        if(this.state.errorsMessage!==null){
          SaveAlert = (
            <AlertMessage centeredAlert={true} show={this.state.errorsMessage!==null} key="leadAlertComponent02" type={this.state.errorsType} message={this.props.translateKey(this.state.errorsMessage)}/>
          )
        }
        return (
            <div className="client-data-wrapper-tab">
                <LoaderModal show={this.state.loaderModal}/>
                <div className="listData">
                    <table className="table">
                        <thead>
                            <tr>
                                <TableColumHead sorting={this.state.sorting} name={this.props.translateKey("client-care-box-patient-title")} onSortType={(data) => this.propsSortType(data)} type="name" />
                                <th scope="col" className="table-add-data no_editable"></th>
                            </tr>
                        </thead>
                        <tbody>{patientData}</tbody>
                    </table>
                    {emptyHandler}
                    <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.props.mainLoader} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        patientLeadData: state.database.patientLead,
        patientLeadStatus: state.database.patientLeadState,
        liveAlone: state.enums.liveAlone
    };
}
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
    savePatientData: (data) => dispatch(savePatientData(data)),
    addPatient: (id, data) => dispatch(addPatientData(id, data)),
    updatePatient: (id, data) => dispatch(updatePatientData(id, data))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CareBoxDataComponent));