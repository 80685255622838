import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { ContractStatus, TableDateFormat, ContractRateType} from "../../index";
import { ReqHelper } from "../../../_helpers/index";
import * as LanguageSelect from "../../../_constance/values/languageSelect";
import { getDynamicFields } from '../../../_services/user_service';
import * as Entity from '../../../_forms/BBForm/values/entity';

class ContractBasicsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            primaryProduct: null,
        };
    }    

    componentDidMount() {
        this._isMounted = true;
        this.getDynamic();
    }

    getDynamic() {
        const {
          match: { params }
        } = this.props;
        let dataToSend = [
          {
            Id: params.contractId,
            Entity: Entity.CONTRACT,
            Fields: ["is_primary_product"]
          }
        ];
        getDynamicFields(dataToSend)
          .then(res => {
            if (res.length > 0) {
              if (
                res[0].fields["is_primary_product"] !== null &&
                res[0].fields["is_primary_product"] !== undefined
              ) {
                if (this._isMounted) {
                  this.setState({
                    primaryProduct: res[0].fields["is_primary_product"]
                  });
                }
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
      }

    render() {        
        const { appLang, translateKey, element, optionList } = this.props;       
        return (
            <div className="lead-details-client-info">
                <div className="col-sm-12 col-lg-5">
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("patient")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                {element.contract}
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("status")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                <ContractStatus element={element.status}/>
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_aggreement")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                <TableDateFormat timestamp={element.agreementDate}/>
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_beginning")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                <TableDateFormat timestamp={element.dateOfBeginning}/>
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_end")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                <TableDateFormat timestamp={element.dateOfEnd}/>
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("product_name")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">{element.product}</span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("original_product")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                            { this.state.primaryProduct ? translateKey(`original_product_${this.state.primaryProduct}`) : '----' }
                            </span>
                        </div>
                    </div>
                    <div className="form-group row no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("aditional_options")}</label>
                        <div className="col-sm-8">
                            {
                                optionList.map(item => (
                                    <span className="lead-input-wrapper list" key={item}>{item}</span>
                                ))
                            }
                            
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("rate_type")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                <ContractRateType element={element.rateType}/>
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("rate")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                {ReqHelper.numberWithSpaces(element.rate) + ' ' + element.currency}
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("business_partner")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                {element.businessPartner}
                            </span>
                        </div>
                    </div>
                    {element.franchiseRegion &&
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("franchise-region")}</label>
                            <div className="col-sm-8">
                                <span className="lead-input-wrapper">
                                    {element.franchiseRegion}
                                </span>
                            </div>
                        </div>
                    }
                    {appLang === LanguageSelect.EN &&
                        <React.Fragment>
                            {element.inquiryReceiptDate!==0 && element.inquiryReceiptDate!==null &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("inquiry_receipt_date")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            <TableDateFormat timestamp={element.inquiryReceiptDate}/>
                                        </span>
                                    </div>
                                </div>
                            }
                            {element.careManagerName &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("care_manager")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">{element.careManagerName}</span>
                                    </div>
                                </div>
                            }
                            {element.registerManagerName &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("register_manager")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">{element.registerManagerName}</span>
                                    </div>
                                </div>
                            }
                            {element.companyName &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("company_name")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">{element.companyName}</span>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {return {appLang: state.lang.appLanguage}};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractBasicsComponent));