import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  translateKey,
  getMarketingData,
  getRejectReasonData,
  getServiceTypeDeData,
  setMultipliersData,
  pendingMultipliersData,
  removeMultipliersData
} from "../../_redux/actions/index";
import DatePickerComponent from "../../_components/date-picker-component/DatePickerComponent";
import SelectOptionsComponent from "../../_components/select-options-component/SelectOptionsComponent";
import { Rating } from "../../_constance/enums/ratingLead";
import { UsesApp } from "../../_constance/enums/usesApp";
import { MultiplierTable } from "../../_constance/classes/index";
import * as LanguageSelect from "../../_constance/values/languageSelect";
import * as CareBoxRejectionReasonExceptionsList from "../../_constance/values/CareBoxRejectionReasonExceptions";
import { LeadSourceBlocked } from '../../_constance/LeadSourceBlocked';
import * as LeadStatusExceptions from "../../_constance/values/LeadStatusExceptions";
import * as LeadCareBoxStatusExceptions from "../../_constance/values/LeadCareBoxStatusExceptions";
import SwitchComponent from "../../_components/form-bb-components/switch-component/switch-component";
import { refreshTokenTime, baseData } from '../../_constance/base_data';
import { RejectionReasonOrder } from '../../_constance/enums/rejectionReasonOrder';
import { shouldRefresh } from "../../_helpers/index";
import { NewMultiplierModal } from "../../_components/index";
import axiosAuth from "../../_services/config/axios-auth";
import Select2 from "../select2/select2";
const contractCancellationStatus = '717700021';

class LeadDetailsBasicComponent extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          element: this.props.element,
          edited: false,
          hiddenStatusElement: 0,
          isCareBoxEnabled: this.props.isCareBoxEnabled,
          onServiceTypeDeChange: this.props.onServiceTypeDeChange,
          isNewMultiplierModalOpen: false
      };
  }

  componentDidMount() {
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.getMultiDataBasic();
    this.isHiddenStatusElemetChecked();
    this.setState({
      isCareBoxEnabled: this.props.isCareBoxEnabled,
      careBoxRejectionReason: this.props.careBoxRejectionReason
    })
  }

  getMultipliersList = () => {
    this.props.pendingRequest();
    axiosAuth.get("multipliers/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new MultiplierTable(element);});
        this.props.setData(mapedRes);
      })
      .catch((error) => {
        this.props.clearData();
      });
  }

  getMultiDataBasic() {
    const lastUpdate = this.props.multipliersTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getMultipliersList();
    }
  }

  changeStatusSelect = (value) => {
    this.handleChangeSelect('status', value);
    
    if(value == contractCancellationStatus) {

      this.handleChangeSelect('reasonForRejection', 717700020);
    }
  }

  handleChangeSelect = (key, value) =>{
    this.props.element[key] = value;
    this.setState(prevState => {
        let elem = {...prevState.element}
        elem[key] = value;
        return {
            ...prevState,
            element: elem
        }
    });
    this.props.editedData();
  }

  handleServiceTypeDeChanged = (key, value) => {
    this.handleChangeSelect("serviceTypeDe", value)
    this.props.onServiceTypeDeChange(value)
  }

  handleChangeMulti = (value) =>{
    this.props.editedMulti(value);
    this.handleChangeSelect('referralContactId', value);
  }

  handleDateChange(key, event) {
    let timestamp = new Date(event).getTime();
    timestamp = timestamp/1000;
    this.props.element[key] = timestamp;
    this.setState(prevState => {
      let elem = {...prevState.element}
      elem[key] = timestamp;
      return {
        ...prevState,
        element: elem
      }
    });
    this.props.editedData();
  }

  handleChangeValueKeyNotArrow(key, value){
    console.log('key: ', key, value);
    this.props.element[key] = value;
    this.setState(prevState => {
      let elem = {...prevState.element}
      elem[key] = value;
      return {
        ...prevState,
        element: elem
      }
    });
    this.props.editedData();
  }

  isHiddenStatusElemetChecked() {
    this.props.leadsStatus.map((value) => {
      if(this.props.appLang===LanguageSelect.DE){
        const { fpTitle } = this.props;
        let existsInException = [];
        if( fpTitle === 'CC_DE' ) {
          existsInException = LeadStatusExceptions.DE_LIST_CC_DE.filter(element=>element===value.Value);
        } else {
          existsInException = LeadStatusExceptions.DE_LIST.filter(element=>element===value.Value);
        }

        if(existsInException.length===0 && value.Value == this.props.element.status && !this.state.hiddenStatusElement) {
          this.setState({
            hiddenStatusElement: this.props.element.status
          });
        }
      }else{
        let existsInException = LeadStatusExceptions.EN_LIST.filter(element=>element===value.Value);
        if(existsInException.length===0 && value.Value == this.props.element.status && !this.state.hiddenStatusElement) {
          this.setState({
            hiddenStatusElement: this.props.element.status
          });
        }
      }
    });
  }

  createStatusCareBoxSelect() {
    const {translateKey, disabled, fpTitle} = this.props;
    const data = this.props.leadStatusCareBox;
    let allowedStatuses;
    if (this.props.appLang === LanguageSelect.DE) {
      if (fpTitle === 'CC_DE') {
        allowedStatuses = LeadCareBoxStatusExceptions.DE_LIST_CC_DE;
      } else {
        allowedStatuses = LeadCareBoxStatusExceptions.DE_LIST;
      }
    } else {
      allowedStatuses = LeadCareBoxStatusExceptions.EN_LIST;
    }
    const options = Object.keys(data).filter(key => allowedStatuses.indexOf(key) > -1).map(key => ({
      value: key,
      label: data[key]
    }));
    return (
        <React.Fragment>
          <Select2
              disabled={disabled}
              value={this.props.element.statusCareBox}
              onChange={(val)=>this.handleChangeSelect('statusCareBox', val.value)}
              options={options}
          />
          <div className="invalid-feedback">{translateKey("form_contains_errors")}</div>
        </React.Fragment>
    )
  }

  createCareBoxRejectionReasonBoxSelect() {
    const {translateKey, disabled, element} = this.props;
    const data = this.props.careBoxRejectionReasonsList;
    let allowedStatuses = CareBoxRejectionReasonExceptionsList.LIST;
    const options = data.filter(item => allowedStatuses.indexOf(item.Value) > -1).map(item => ({
      value: item.Value,
      label: item.Label,
    }));
    return (
        <React.Fragment>
          <Select2
              disabled={disabled}
              value={element.careBoxRejectionReason}
              onChange={(val)=>this.handleChangeSelect('careBoxRejectionReason', val.value)}
              options={options}
          />
          <div className="invalid-feedback">{translateKey("form_contains_errors")}</div>
        </React.Fragment>
    )
  }

  sortObjectByValue(arr, order){
    arr.sort((a, b) => {
      const indexA = order.indexOf(a.value);
      const indexB = order.indexOf(b.value);
      return indexA - indexB;
    });
  };
  
  createStatusSelect(){
      const {translateKey, disabled, fpTitle } = this.props;
      let sortedOptions = [];
      let options = this.props.leadsStatus.map((value) => {
          if(this.props.appLang===LanguageSelect.DE){
            let existsInException = [];
            let existInHidden = [];
            if( fpTitle === 'CC_DE' ) {
              existsInException = LeadStatusExceptions.DE_LIST_CC_DE.filter(element=>element===value.Value);
            } else {
              existsInException = LeadStatusExceptions.DE_LIST.filter(element=>element===value.Value);
            }

            existInHidden = LeadStatusExceptions.HIDDEN_ELEMENTS.filter(element=>element===value.Value);
            
            if(existsInException.length!==0 || value.Value == this.props.element.status || value.Value == this.state.hiddenStatusElement) {
              return { value: value.Value, label: value.Label , isDisabled: existInHidden.length!==0}
            } else {
              return false
            }
          }else{
            let existsInException = LeadStatusExceptions.EN_LIST.filter(element=>element===value.Value)
            if(existsInException.length!==0 || value.Value == this.props.element.status || value.Value == this.state.hiddenStatusElement) {
              return { value: value.Value, label: value.Label }
            } else {
              return false
            }
          }
        }).filter(element=>element!==false);

        if( fpTitle === 'CC_DE' ) {
          sortedOptions = this.sortObjectByValue(options, LeadStatusExceptions.DE_LIST_CC_DE);
        } else {
          sortedOptions = this.sortObjectByValue(options, LeadStatusExceptions.DE_LIST);
        }
        const customStyles = {
          option: (provided, state) => ({
            ...provided,
            display: state.data.isDisabled ? "none" : "block",
          }),
        };

      return (
          <React.Fragment>
            <Select2 
              disabled={disabled} 
              value={this.props.element.status}
              onChange={(val)=>this.changeStatusSelect(val.value)} 
              options={options}
              aditionalStyles={customStyles}
            />
            <div className="invalid-feedback">{translateKey("form_contains_errors")}</div>
          </React.Fragment>
      )
  } 

  createMultiSelect(){
    const { element, translateKey, disabled } = this.props;
    return (
      <select 
        disabled={disabled} 
        className={"custom-select col-sm-10 lead-input-wrapper multiplier-select"} 
        onChange={(e)=>this.handleChangeMulti(e.target.value)} 
        value={element.referralContactId!==null?element.referralContactId:''}
      >
        <option hidden>{translateKey("choose_select")}</option>
        {this.props.multipliersData.sort((a, b) => a.multiplier.localeCompare(b.multiplier)).map((value) => {
            return <option defaultValue={this.props.multiId===value.id} key={value.id} value={value.id}>{value.multiplier}{value.company ? ` / ${value.company}` : ''}</option>
        })}
      </select>
    )
  }

  prepareMeMarketingBasedOnLanguage(){
    const { marketingsList, appLang } = this.props;
    return marketingsList.filter(element=> {
      if(appLang==="EN"){
        return element.Market==="UK" || element.Market===appLang
      }else{
        return element.Market===appLang
      }
    })
  }

  prepareMeRejectionReasonList() {
    const { rejectReasonsList } = this.props;
    
    return RejectionReasonOrder.map(
        orderValue => rejectReasonsList.find(rejectionReason => rejectionReason.Value === orderValue)
      );
  }

  toggleThisElement = () =>{
    this.props.element.leadExclusiveForPm24 = !this.props.element.leadExclusiveForPm24
    this.setState(prevState => {
      return {
        ...prevState,
        element: {
          ...prevState.element,
          leadExclusiveForPm24: !this.props.element.leadExclusiveForPm24
        }
      }
    });
    this.props.editedData();
  }

  getMeComment(){
    let comment = this.props.dynamics.is_lead_description;
    let result = comment.split("##");
    if(result.length===0){
      return '';
    }else{
      return result[result.length-1];
    }
  }

  prepareMeLeadSource(){
    const srcL = this.props.leadsSourceStatus.filter(item => item.FP == '717700000').map(element=>{
      if(element.Value!==null){
        element.Hidden = false;
        return element;
      }else{
        element.Hidden = true;
        return element;
      }
    })
    return srcL;
  }

  closePopup = () => {
    this.setState(prevState => {
        return {
            ...prevState,
            isNewMultiplierModalOpen: false,
            }
        }
    );
  }

  openPopup = () => {
    this.setState(prevState => {
        return {
            ...prevState,
            isNewMultiplierModalOpen: true,
            }
        }
    );
  }

  selectedMultiplierFromModal = (multiplier) => {
    this.closePopup();
    this.handleChangeMulti(multiplier);
  }

  render() {
    const { translateKey, appLang, leadsSourceStatus, marketingsList, rejectReasonsList, serviceTypeDeList, careBoxRejectionReasonsList, element, disabled, disabledRejection, dynamics, match: { params }, showValidation, isUsesApp, handleUsesSelectChange} = this.props;
    const leadSourceList = this.prepareMeLeadSource();
    const rejectReasonsListRequired = `${element.status}` === contractCancellationStatus && !element.reasonForRejection && showValidation;
    let leadSourceListDisabled = false;

    if(LeadSourceBlocked.indexOf(this.state.element.source.toString().toLowerCase())>-1){
      leadSourceListDisabled = true;
    }
    const isCareBoxIncluded = element.serviceTypeDe === '717700000' || element.serviceTypeDe === '717700002';
    return (
      <div className="lead-first-row">
        <div className="col-sm-12 col-lg-10">
          <fieldset disabled={disabled}>
            <div className="form-group row flexAlignCenter no-margin-row">
              <label className="col-sm-2 col-form-label form-label-basic">{translateKey("date_of_entry")}</label>
              <div className="col-sm-3 lead-input-wrapper">
                <DatePickerComponent readOnly = {disabled} key="date_of_entry_01" timestamp={element.dateOfEntry!==null?element.dateOfEntry:new Date()} withTime={false} onDateChange={(e)=>this.handleDateChange("dateOfEntry", e)}/>
              </div>
            </div>
            {appLang === LanguageSelect.DE && serviceTypeDeList.length > 0 &&
                <SelectOptionsComponent showStar={true} disabled={false} chooseText={translateKey("choose_select")} setTypeChangeToParent={this.handleServiceTypeDeChanged}
                                        valuesList = {serviceTypeDeList}
                                        keyName="serviceTypeDe"
                                        value={element.serviceTypeDe !== null ? element.serviceTypeDe : ''}
                                        mainText={translateKey("service_type")}
                                        colClassLabel="col-sm-2"
                                        colClassInput="col-sm-3"
                />
            }
            {!this.props.isCareBoxEnabled && this.props.leadsStatus.length>0 &&
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-2 col-form-label form-label-basic">{translateKey("status")}<span className="red-star-required-field"> *</span></label>
                <div className="col-sm-3">
                  {this.createStatusSelect()}
                </div>
              </div>
            }
            {!this.props.isCareBoxEnabled && leadsSourceStatus.length>0 &&
              <>
                <SelectOptionsComponent 
                  disabled={true} 
                  chooseText={translateKey("choose_select")} 
                  setTypeChangeToParent={this.handleChangeSelect} 
                  valuesList = {leadSourceList} 
                  keyName="source" 
                  value={element.source!==null?element.source.toUpperCase():''} 
                  mainText={translateKey("source_of_acquisition")}
                  colClassLabel="col-sm-2"
                  colClassInput="col-sm-3"
                />
                <SelectOptionsComponent 
                  disabled={false} 
                  chooseText={translateKey("choose_select")} 
                  setTypeChangeToParent={this.handleChangeSelect} 
                  valuesList = {leadSourceList} 
                  keyName="sourceFp" 
                  value={element.sourceFp?element.sourceFp.toUpperCase():''} 
                  mainText={translateKey("source_of_acquisition_fp")}
                  colClassLabel="col-sm-2"
                  colClassInput="col-sm-3"
                />
              </>
            }
            {element.sourceFp && element.sourceFp.toUpperCase() === baseData.multiToLeadSelection &&
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-2 col-form-label form-label-basic">{translateKey("multiplier")}<span className="red-star-required-field"> *</span></label>
                <div className="col-sm-3">
                  {this.createMultiSelect()}
                </div>
                <div className="col-sm-3">
                  <button type="button" onClick={this.openPopup} className="btn btn-success">
                      {translateKey("new_multiplier")}
                  </button>
                </div>
              </div>
            }    
            {appLang === LanguageSelect.EN && Rating.length>0 &&
              <SelectOptionsComponent
                  disabled={disabled}
                  chooseText={translateKey("choose_select")}
                  setTypeChangeToParent={this.handleChangeSelect}
                  valuesList = {Rating}
                  keyName="rating"
                  value={element.rating!==null?element.rating:''}
                  mainText={translateKey("rating")}
                  colClassLabel="col-sm-2"
                  colClassInput="col-sm-3"
              />
            }       
            {appLang === LanguageSelect.EN && marketingsList.length>0 &&
              <SelectOptionsComponent
                  disabled={disabled}
                  chooseText={translateKey("choose_select")}
                  setTypeChangeToParent={this.handleChangeSelect}
                  valuesList = {this.prepareMeMarketingBasedOnLanguage()}
                  keyName="marketingId"
                  value={element.marketingId!==null?element.marketingId.toUpperCase():''}
                  mainText={translateKey("marketing_type")}
                  colClassLabel="col-sm-2"
                  colClassInput="col-sm-3"
              />
            }
            </fieldset>
            <fieldset disabled={disabledRejection}>
            {!this.props.isCareBoxEnabled && rejectReasonsList.length>0 &&
              <SelectOptionsComponent
                  showStar={`${element.status}` === contractCancellationStatus}
                  isRequired={rejectReasonsListRequired}
                  disabled={disabledRejection} 
                  chooseText={translateKey("choose_select")}
                  setTypeChangeToParent={this.handleChangeSelect}
                  valuesList = {this.prepareMeRejectionReasonList()}
                  keyName="reasonForRejection"
                  value={element.reasonForRejection!==null?element.reasonForRejection:''}
                  mainText={translateKey("rejection_reason")}
                  colClassLabel="col-sm-2"
                  colClassInput="col-sm-3"
              />
            }  
            {appLang === LanguageSelect.DE &&
              <React.Fragment>
                {!this.props.isCareBoxEnabled &&
                    <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-2 col-form-label form-label-basic">{translateKey("rejection_details")}</label>
                    <div className="col-sm-3">
                      <textarea 
                        disabled={disabledRejection}
                        type="text"
                        className="form-control lead-input-wrapper textarea-no-resize"
                        rows="5"
                        onChange={e => this.handleChangeValueKeyNotArrow("reasonsForRejectionDetails", e.target.value)}
                        value={element.reasonsForRejectionDetails?element.reasonsForRejectionDetails:''}
                      />
                    </div>
                </div>
                }
                {params.leadId && params.leadId!==0 &&
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-2 col-form-label form-label-basic">{translateKey("lead_exclusive")}</label>
                    <SwitchComponent
                      hideLabel={true}
                      disabled={true}
                      setTypeChangeToParent={()=>{}}
                      showValidation={false}
                      className="col-sm-3 text-center no-pointer-events"
                      transKey=""
                      key="is_contact_person_data_same_as"
                      keyName="is_contact_person_data_same_as"
                      value={element.leadExclusiveForPm24}
                      validationErrors={[]}
                      valueYes = {true}
                      valueNo = {false}
                    />
                  </div>
                }
              </React.Fragment>
            }  
            </fieldset>
            <fieldset disabled={disabled}>
            {appLang === LanguageSelect.EN &&
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-2 col-form-label form-label-basic">{translateKey("date_of_first_interview")}</label>
                <div className="col-sm-3 lead-input-wrapper">
                  <DatePickerComponent readOnly = {disabled} key="date_of_entry_01" timestamp={element.dateFirstInterview!==null?element.dateFirstInterview:''} withTime={false} onDateChange={(e)=>this.handleDateChange('dateFirstInterview', e)}/>
                </div>
              </div>
            }
            {dynamics.is_lead_description &&
              <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-2 col-form-label form-label-basic">{translateKey("comments_from_internet_form")}</label>
                  <div className="col-sm-9">
                    <textarea rows="8" disabled = {true} type="text" className="form-control lead-input-wrapper-full-width" value={this.getMeComment()} style={{resize: 'none'}}/>
                  </div>
              </div>
            }
            {appLang === LanguageSelect.DE && isCareBoxIncluded && this.props.leadStatusCareBox !== undefined &&
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-2 col-form-label form-label-basic">{translateKey("care-box-status")}<span className="red-star-required-field"> *</span></label>
                  <div className="col-sm-3">
                    {this.createStatusCareBoxSelect()}
                  </div>
                </div>
            }
            {appLang === LanguageSelect.DE && isCareBoxIncluded && careBoxRejectionReasonsList.length > 0 &&
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-2 col-form-label form-label-basic">{translateKey("care-box-rejection-reason")}</label>
                  <div className="col-sm-3">
                    {this.createCareBoxRejectionReasonBoxSelect()}
                  </div>
                </div>
            }
            {
              <SelectOptionsComponent
                disabled={disabled}
                chooseText={translateKey("choose_select")}
                setTypeChangeToParent={handleUsesSelectChange}
                valuesList = {UsesApp}
                keyName="isUsesApp"
                value={isUsesApp!==null?isUsesApp:''}
                mainText={translateKey("uses_app")}
                colClassLabel="col-sm-2"
                colClassInput="col-sm-3"
            />
            }
        </fieldset>
      </div>
      <NewMultiplierModal 
          show={ this.state.isNewMultiplierModalOpen } 
          onClose={ this.closePopup }
          selectedMultiplier={ this.selectedMultiplierFromModal }
      />
    </div>
  );
  }
}

const mapStateToProps = state => {
    const careBoxRejectionReasons = state.enums.careBoxRejectionReasonsList
    return {
        appLang: state.lang.appLanguage,
        leadsStatus: state.enums.leadStatus,
        leadsSourceStatus: state.enums.leadSource,
        leadStatusCareBox: state.enums.leadCareBoxStatus,
        marketingsList: state.enums.marketingsList,
        marketingsListStatus: state.enums.marketingsListStatus,
        rejectReasonsList: state.enums.rejectReasonsList,
        rejectReasonsListStatus: state.enums.rejectReasonsListStatus,
        serviceTypeDeList: state.enums.serviceTypeDeList,
        serviceTypeDeListStatus: state.enums.serviceTypeDeListStatus,
        multipliersData: state.database.multipliers,
        multipliersTimeStamp: state.database.multipliersTimeStamp,
        fpTitle: state.auth.title,
        careBoxRejectionReasonsList: careBoxRejectionReasons
    };
  }
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  getMarketingData: () => dispatch(getMarketingData()),
  getRejectReasonData: (lang) => dispatch(getRejectReasonData(lang)),
  getServiceTypeDeData: () => dispatch(getServiceTypeDeData()),
  setData: (data) => dispatch(setMultipliersData(data)),
  pendingRequest: () => dispatch(pendingMultipliersData()),
  clearData: () => dispatch(removeMultipliersData()),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadDetailsBasicComponent));