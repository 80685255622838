import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../../_redux/actions/index";
import produce from "immer";
import ReqHelper from "../../../_helpers/request_helper";
import { baseData} from '../../../_constance/base_data';
import * as TaskType from "../../../_constance/values/taskType";

class MeetingSection3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arrangeForm: props.arrangeForm,
      section: props.arrangeForm.getSectionByKey("section-meeting"),
    };
  }

  setTypeChange = (key, value) => {
    const nextState = produce(this.state, (draftState) => {
      const { section } = draftState;
      const field = section.getFieldByKey(key);
      if (field) {
        field.value = value;
      }
    });
    this.setState(nextState);
  };

  nextPageClick = async () => {
    this.props.history.push(`${baseData.subFolders}leads`);
  }

  goToKoPiloten = async () => {
    this.props.history.push(`${baseData.subFolders}leadDetails/${this.props.leadData.leadId}/?type=kopiloten`);
  }

  getClientAddressFormat=()=>{
    const { translateKey, arrangeForm } = this.props;
    const city = arrangeForm.sections[0].getFieldByKey("meeting_stadt").value;
    const street = arrangeForm.sections[0].getFieldByKey("meeting_strasse").value;
    const buildingNumber = arrangeForm.sections[0].getFieldByKey("meeting_strasse_nr").value;
    const apartmentNumber = arrangeForm.sections[0].getFieldByKey("meeting_wohungsnummer").value;


    const meetingNurDieMaterialen = arrangeForm.sections[0].getFieldByKey("meeting_nur_die_materialen").value;
    const meetingArtDesGesprachs = arrangeForm.sections[0].getFieldByKey("meeting_art_des_gesprachs");

    let finalString = '';
    if(meetingArtDesGesprachs.value === TaskType.EVENT && meetingNurDieMaterialen === true){
      if(city && city!==''){finalString = ` ${city}, `}
      if(street && street!==''){finalString += `${street} `}
      if(buildingNumber && buildingNumber!==''){
        finalString += `${buildingNumber} `;
        if(apartmentNumber && apartmentNumber!==''){finalString += `/${apartmentNumber} `}
      }else{
        if(apartmentNumber && apartmentNumber!==''){finalString += `${apartmentNumber} `}
      }
    }
    if(finalString.length>0){
      finalString = ` ${translateKey('arrange-meeting-herzlichen3-in')} ${finalString}`;
    }
    return finalString
  }

  render() {
    const { translateKey, emlPath } = this.props;
    const { section } = this.state;
    const meetingTimeStamp = section.getFieldByKey("meeting_timestamp");
    const meetingNurDieMaterialen = section.getFieldByKey("meeting_nur_die_materialen").value;
    const meetingArtDesGesprachs = section.getFieldByKey("meeting_art_des_gesprachs");
    return (
      <div className="form-section-main">
        { meetingNurDieMaterialen ?
            <>
              <div className="new-section-name-wrapper">{translateKey('arrange-meeting-title3')}</div>
              <form className="new-section-content-wrapper container form_serializer">
                <div className="newUI-section-padding">
                  <div className="row">
                    <div className="col-sm-12 col-lg-12 text-align-center">
                      <div className="newUI-text-color">
                        { meetingArtDesGesprachs.value === TaskType.EVENT ?
                          translateKey("arrange-meeting-herzlichen1")+' '
                        : translateKey("arrange-meeting-herzlichen1-call")+' '}
                        {ReqHelper.dateConversion(meetingTimeStamp.value, false)}
                        {' '+translateKey("arrange-meeting-herzlichen2")+' '}
                        {ReqHelper.dateConversionTime(meetingTimeStamp.value, false)}
                        {' '+translateKey("arrange-meeting-herzlichen3")}
                        {this.getClientAddressFormat()}
                        {' '+translateKey("arrange-meeting-herzlichen4")}
                      </div>
                      <div className="newUI-text-color mt-20">
                        {translateKey("eml_text")}
                      </div>
                      <div className="newUI-text-color mt-20">
                        <a class="btn btn-primary cursor-pointer eml-button" href={emlPath} download>
                          <i class="fa fa-envelope norbsoft-filter-icon" aria-hidden="true"></i>
                          {translateKey("eml_button")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          : <>
                <div className="new-section-name-wrapper">{translateKey('arrange-meeting-title4')}</div>
                <div className="newUI-section-padding">
                  <div className="row">
                    <div className="col-sm-12 col-lg-12 text-align-center">
                      <div className="newUI-text-color mt-20">
                        <a class="btn btn-primary cursor-pointer eml-button" href={emlPath} download>
                          <i class="fa fa-envelope norbsoft-filter-icon" aria-hidden="true"></i>
                          {translateKey("eml_button")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
        }
        
        <div className='zone-form-navigation-buttons'>
          <div className="previous-next-wrapper">
            <button type="button" className="btn btn-primary cursor-pointer next-previous-class prev-next-normal-button special-width" onClick={this.nextPageClick}>
              <div className="section-navigation-button-text special-width">{translateKey("ko-piloten-startseite").toUpperCase()}</div>
              <i className="fas fa-chevron-right fa-1x section-navigation-icon"></i>
            </button>
            <button type="button" className="btn btn-primary cursor-pointer next-previous-class prev-next-active-button special-width" onClick={this.goToKoPiloten}>
              <div className="section-navigation-button-text special-width">{translateKey("ko-piloten-page").toUpperCase()}</div>
              <i className="fas fa-chevron-right fa-1x section-navigation-icon"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({translateKey: (firstLvl, secondLvl) =>dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(null, mapDispatchToProps)(MeetingSection3));