import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { translateKey, authSetActionTimestamp, setPatientClientData, pendingPatientClientData, removePatientClientData, getPatientClient, getInvoices, setClientsData, pendingClientsData, removeClientsData } from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, EmptyTableIcon} from "../../_components/index";
import { Alert, CarerTable, ContractTable, ClientTable } from "../../_constance/classes/index";
import { IconsMap } from "../../_helpers/index";
import { ResponseStatus } from '../../_helpers/response_status'
import axiosAuth from "../../_services/config/axios-auth";
import { ClientPatientData, ClientInvoicesData, ClientCarersData }  from '../../_components/ClientDetailsComponenets/index';
import { ContractData }  from '../../_components/contract-data-component/index';
import BBFormService from '../../_services/bbform';

class ContractPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  invoiceTimeOut;
  patientTimeout;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "contract",
      contractData: null,
      errorComponent: Alert,
      errorsMessage: null,
      errorsType: "danger",
      loadingBasics: true,
      clientRetries: 0,
      loadingPatients: true,
      loadingInvoices: true,
      carerData: [],
      loadingCarers: true,
      clientId: 0,
      contractId: 0,
      clientData: null,
      breadCrumbs:[
        {
          link: "clients",
          key: "clients",
          name: null
        }
      ],
      contractOptionsList: [],
    };
  }

  setActiveTab(tabName){this.setState({activeTab: tabName})}

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.myTimeout = setTimeout(() => {
      this.getDataBasic()
    }, 200);
  } 

  componentWillUnmount() {
      this._isMounted = false;
      clearTimeout(this.myTimeout);
      clearTimeout(this.invoiceTimeOut);
      clearTimeout(this.patientTimeout);
  };

  ////// BASIC CLIENT TAB DATA LOAD

  getPatientList(){
    this.props.pendingPatientData();
    axiosAuth.get("patients/list/CLIENT/0/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        this.props.setPatientData(res);
        this.getInitialDataTime();
      })
      .catch((error) => {
        this.props.clearPatientData();
        this.getInitialDataTime();
      });
  }

  getDataBasic() {
    if (this._isMounted) {
      this.setState({loadingBasics: true});
    }
    if (this.props.patientData.length === 0) {
      if (this.props.patientData.length !== 0 && this.props.patientData !== undefined) {
        this.getInitialDataTime();
      } else {
        this.getPatientList();
      }
    } else {
      this.getInitialDataTime();
    }
  }

  getInitialDataTime(){
    if(this.props.clientsData.length>0){
      clearTimeout(this.myTimeout);
      const { match: { params } } = this.props;
      let name = '---';
      for(let ij=0; ij<this.props.clientsData.length;ij++){
        if(this.props.clientsData[ij].id === params.id){
          name = this.props.clientsData[ij].client;
          break;
        }
      }
      if (this._isMounted) {
        this.setState({
          breadCrumbs:[...this.state.breadCrumbs, {
            link: "clientDetails/"+params.id,
            key: "",
            name: name
          }],
        })
      }
      this.getBasicContractsData();
    }else{
      if(this.state.clientRetries<10){
        this.getClientsList();
        if (this._isMounted) {
          this.setState({clientRetries: this.state.clientRetries+1})
        }
        this.myTimeout = setTimeout(() => {this.getInitialDataTime()}, 300);
      }else{
        if (this._isMounted) {
          this.setState({
            clientRetries: 0,
            loadingBasics: false
          })
        }
      }
    }
  }

  getClientsList = () => {
    if(this.props.clientsState !== ResponseStatus.PENDING){
      this.props.pendingRequestClient();
      axiosAuth.get("clients/list/0")
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
          let mapedRes = res.map((element)=>{
            for(let i=0; i<this.props.patientData.length; i++){
              if(this.props.patientData[i].clientId===element.id){
                element.locationP = this.props.patientData[i].patientAddress ? this.props.patientData[i].patientAddress.city:'';
                element.patientName = this.props.patientData[i].name;
                element.patient = this.props.patientData[i]
              }
            }
            return new ClientTable(element);}
          );
          this.props.setClientData(mapedRes);
        })
        .catch((error) => {this.props.clearDataClient();});
    }
  }

  getBasicContractsData(){
    if (this._isMounted) {
      this.setState({loadingBasics: true})
    }
    const { match: { params } } = this.props;
    axiosAuth.get("/contracts/list/"+params.id+"/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        for(let i=0; i<res.length; i++){
          if(res[i].id === params.contractId){
            let name='---';
            if(res[i].lastName!==null){
              name = res[i].lastName;
              if(res[i].firstName!==null){
                name += ' '+res[i].firstName;
              }
            }else{
              if(res[i].firstName!==null){
                name = res[i].firstName;
              }
            }
            if (this._isMounted) {
              this.setState({
                contractData: new ContractTable(res[i]),
                loadingBasics: false,
                clientId: params.id,
                contractId: params.contractId,
                breadCrumbs:[...this.state.breadCrumbs, 
                  {
                    link: "",
                    key: "",
                    name: name
                  }
                ]
              })
            }
            this.getInitialPatient();
            this.getBasicInvoiceData();
            this.getBasicCarersData();
            break;
          }
        }
      }).then(() => {
        this.prepereOptionList();
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ loadingBasics: false })
        }
      });
  }

  prepereOptionList(){
    const { translateKey } = this.props;
    const dataToSend = [{
      "id": this.state.contractData.id,
      "entity": "is_contract",
      "fields": [
          "is_driving_licence",
          "is_date_from_1",
          "is_date_to_1",
          "is_second_ooo",
          "is_date_from_2",
          "is_date_to_2",
          "is_additional_foreign_language",
          "is_date_from_3",
          "is_date_to_3",
          "is_transfer",
          "is_date_from_4",
          "is_date_to_4",
          "is_carer_with_diploma",
          "is_date_from_5",
          "is_date_to_5",
          "is_additional_person_in_house",
          "is_date_from_6",
          "is_date_to_6"
      ]
    }];
    BBFormService.retrievePatient(dataToSend).then(response => {
      const { data } = response;
      const optionList = [];
      const actualDate = Math.floor(Date.now() / 1000);

      if(
        data[0].fields.is_driving_licence === '717700000' && 
        (data[0].fields.is_date_from_1 === null || data[0].fields.is_date_from_1 < actualDate) &&
        (data[0].fields.is_date_to_1 === null || data[0].fields.is_date_to_1 > actualDate)
      ) {
        optionList.push(`${ translateKey('fee_licence') }`);
      }

      if(
        data[0].fields.is_second_ooo === '717700000' && 
        (data[0].fields.is_date_from_2 === null || data[0].fields.is_date_from_2 < actualDate) &&
        (data[0].fields.is_date_to_2 === null || data[0].fields.is_date_to_2 > actualDate)
      ) {
        optionList.push(`${ translateKey('fee_2ndpat') }`);
      }

      if(
        data[0].fields.is_additional_foreign_language === '717700000' && 
        (data[0].fields.is_date_from_3 === null || data[0].fields.is_date_from_3 < actualDate) &&
        (data[0].fields.is_date_to_3 === null || data[0].fields.is_date_to_3 > actualDate)
      ) {
        optionList.push(`${ translateKey('fee_2ndlang') }`);
      }

      if(
        data[0].fields.is_transfer === '717700000' && 
        (data[0].fields.is_date_from_4 === null || data[0].fields.is_date_from_4 < actualDate) &&
        (data[0].fields.is_date_to_4 === null || data[0].fields.is_date_to_4 > actualDate)
      ) {
        optionList.push(`${ translateKey('fee_plus') }`);
      }

      if(
        data[0].fields.is_carer_with_diploma === '717700000' && 
        (data[0].fields.is_date_from_5 === null || data[0].fields.is_date_from_5 < actualDate) &&
        (data[0].fields.is_date_to_5 === null || data[0].fields.is_date_to_5 > actualDate)
      ) {
        optionList.push(`${ translateKey('fee_diploma') }`);
      }

      if(
        data[0].fields.is_additional_person_in_house === '717700000' &&
        (data[0].fields.is_date_from_6 === null || data[0].fields.is_date_from_6 < actualDate) &&
        (data[0].fields.is_date_to_6 === null || data[0].fields.is_date_to_6 > actualDate)
      ) {
        optionList.push(`${ translateKey('fee_addperson') }`);
      }
      
      this.setState({
        contractOptionsList: optionList,
      });
    }).catch((error)=>{
      console.log('error: ', error);
    })
  }

  ///// BASIC PATIENT TAB DATA LOAD

  getInitialPatient(){
    if(this.props.patientClientStatus === ResponseStatus.READY){
      clearTimeout(this.patientTimeout);
      if (this._isMounted) {
        this.setState({loadingPatients: false})
      }
    }else{
      if(this.props.patientClientStatus === ResponseStatus.FREE){
        this.props.getPatientClientData(this.props.appLang);
        this.patientTimeout = setTimeout(() => {
          this.getInitialPatient()
        }, 500);
      }else{
        if(this.props.patientClientStatus === ResponseStatus.ERROR){
          clearTimeout(this.patientTimeout);
          if (this._isMounted) {
            this.setState({ loadingPatients: false })
          }
        }else{
          this.patientTimeout = setTimeout(() => {
            this.getInitialPatient()
          }, 500);
        }
      }
    }
  }



  ///// BASIC INVOICE TAB DATA LOAD

  getBasicInvoiceData(){
    if(this.props.invoiceStatus === ResponseStatus.READY){
      clearTimeout(this.invoiceTimeOut);
      if (this._isMounted) {
        this.setState({loadingInvoices: false})
      }
    }else{
      if(this.props.invoiceStatus === ResponseStatus.FREE){
        this.props.getInvoicesData();
        this.invoiceTimeOut = setTimeout(() => {
          this.getBasicInvoiceData()
        }, 500);
      }else{
        if(this.props.invoiceStatus === ResponseStatus.ERROR){
          clearTimeout(this.invoiceTimeOut);
          if (this._isMounted) {
            this.setState({ loadingInvoices: false })
          }
        }else{
          this.invoiceTimeOut = setTimeout(() => {
            this.getBasicInvoiceData()
          }, 500);
        }
      }
    }
  }









  ///// BASIC CARER TAB DATA LOAD

  getBasicCarersData(){
    if (this._isMounted) {
      this.setState({loadingCarers: true})
    }
    const { match: { params }, translateKey } = this.props;
    axiosAuth.get("/carers/list/"+params.id+"/0/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let response = [];

        if(res.Carers !== undefined) {
            const carers =  res.Carers;
            res.Breaks.forEach((element, index) => {
                const breakItem = {
                    wzid: element.breakId,
                    firstName: translateKey("carer-break"),
                    status: null,
                    from: element.from,
                    to: element.to,
                    isBreak: true
                }
                carers.push(breakItem);
            });
            response = carers;
        } else {
          response = res;
        }

        let mapedRes = response.map((element)=>{return new CarerTable(element);});
        if (this._isMounted) {
          this.setState({
            carerData: mapedRes,
            loadingCarers: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ loadingCarers: false })
        }
      });
  }


  render() {
    const { translateKey } = this.props;
    let ContractDataX;
    if (this.state.loadingBasics) {
      ContractDataX = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.state.contractData===null){
        ContractDataX = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")} />
      }else{
        ContractDataX = <ContractData contractData={this.state.contractData} contractOptionList={this.state.contractOptionsList} />
      }
    }

    let PatientData;
    if (this.state.loadingPatients) {
      PatientData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.props.patientData===[]){
        PatientData = <EmptyTableIcon RefreshMe={() => this.getInitialPatient()} text={translateKey("no_data_to_show")} />
      }else{
        PatientData = <ClientPatientData type="contract" RefreshMe={() => this.getInitialPatient()} id={this.state.contractId}/>
      }
    }

    let InvoiceData;
    if (this.state.loadingInvoices) {
      InvoiceData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.props.invoicesData===[]){
        InvoiceData = <EmptyTableIcon RefreshMe={() => this.getBasicInvoiceData()} text={translateKey("no_data_to_show")} />
      }else{
        InvoiceData = <ClientInvoicesData RefreshMe={() => this.getBasicInvoiceData()} id={this.state.clientId}/>
      }
    }

    let CarersData;
    if (this.state.loadingCarers) {
      CarersData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      const { match: { params } } = this.props;
      CarersData = <ClientCarersData RefreshMe={() => this.getBasicCarersData()} id={this.state.id} clientId={params.id} carerData={this.state.carerData}/>
    }

    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]} filterData={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper transparentClass">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className="nav-item nav-link active nav-link-invoices" onClick={()=>{this.setActiveTab("client")}} id="nav-details-tab" data-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true">
                  {translateKey("contract")}
                </a>
                <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("carers")}} id="nav-carers-tab" data-toggle="tab" href="#nav-carers" role="tab" aria-controls="nav-carers" aria-selected="false">
                  {translateKey("carers")}
                </a>
                <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("patients")}} id="nav-patients-tab" data-toggle="tab" href="#nav-patients" role="tab" aria-controls="nav-patients" aria-selected="false">
                  {translateKey("patients")}
                </a>
                <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("invoices")}} id="nav-invoices-tab" data-toggle="tab" href="#nav-invoices" role="tab" aria-controls="nav-invoices" aria-selected="false">
                  {translateKey("invoices")}
                </a>
              </div>
            </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                <div className="leadSection">
                  {ContractDataX}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-carers" role="tabpanel" aria-labelledby="nav-careres-tab">
                <div className="leadSection">
                  {CarersData}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-patients" role="tabpanel" aria-labelledby="nav-patients-tab">
                <div className="leadSection">
                  {PatientData}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-invoices" role="tabpanel" aria-labelledby="nav-invoices-tab">
                <div className="leadSection">
                  {InvoiceData}
                </div>
              </div>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
        <Prompt
            when={false}
            message={translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    clientsData: state.database.clients,
    clientsState: state.database.clientsState,
    patientData: state.database.patientClient,
    patientClientStatus: state.database.patientClientState,
    invoicesData: state.database.invoices,
    invoiceStatus: state.database.invoicesState,
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),

  setClientData: (data) => dispatch(setClientsData(data)),
  pendingRequestClient: () => dispatch(pendingClientsData()),
  clearDataClient: () => dispatch(removeClientsData()),

  getPatientClientData: (lang) => dispatch(getPatientClient(lang)),
  setPatientData: (data) => dispatch(setPatientClientData(data)),
  pendingPatientData: () => dispatch(pendingPatientClientData()),
  clearPatientData: () => dispatch(removePatientClientData()),

  tookAction: () => dispatch(authSetActionTimestamp()),

  getInvoicesData: () => dispatch(getInvoices()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPage));