import * as React from 'react';

import { connect } from 'react-redux';
import { translateKey } from '../../../../_redux/actions/index';

import { 
  SUMMARY_OFFER_MENU_SECTIONS 
} from '../../../../_constance/enums/summaryOfferMenuSections'; 

class SummaryMenu extends React.PureComponent {

  menuClickHandler = (labelKey) =>{
    const { disabled, onClick } = this.props;
    if(!disabled){
      onClick(labelKey);
    }
  }
  
  renderMenuItem = (item, index) => {
    const { activeSection, translateKey, disabled } = this.props;
    let isActive;
    if (disabled) {
      isActive = false;
    } else {
      isActive = activeSection === item.labelKey;
    }
    return (
      <div 
        role="button"
        key={index+item.labelKey}
        className={`summary-menu-item d-flex flex-column justify-content-center align-items-center ${isActive && 'active-menu-button'}`}
        onClick={() => this.menuClickHandler(item.labelKey)}
      >
        <i className={`fas fa-${item.icon} fa-2x mb-2 ${isActive ? 'white-icon' : 'blue-icon'}`} />
        <div className={`summary-menu-label ${isActive && 'active-menu-button'}`}>
          {translateKey(item.labelKey)}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="summary-menu d-flex flex-column">
        {SUMMARY_OFFER_MENU_SECTIONS.map(item => 
          this.renderMenuItem(item)
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });

export default connect(null, mapDispatchToProps)(SummaryMenu);