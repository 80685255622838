import * as React from "react";
import {connect} from "react-redux";
import {withRouter, Prompt} from "react-router-dom";
import {
    setLeadsData,
    pendingLeadsData,
    removeLeadsData,
    translateKey,
    addNewTaskData,
    authSetActionTimestamp,
    removeLeadStatusData,
    getLeadStatusData,
    getTasks,
    setTasksData,
    needsSaveData
} from "../../_redux/actions/index";
import {leadSave} from "../../_redux/actions/auth";
import {TableProperties, NavBar, SideBar, LoaderTable, LoaderModal, AlertModalInfo} from "../../_components/index";
import {MeetingSection1, MeetingSection2, MeetingSection3} from "../../_components/arrangeSections/index";
import {shouldRefresh} from "../../_helpers/index";
import axiosAuth from "../../_services/config/axios-auth";
import {refreshTokenTime, baseData} from '../../_constance/base_data';
import {Alert} from "../../_constance/classes/index";
import {ResponseStatus} from '../../_helpers/response_status'
import ArrangeMeetingForm from '../../_forms/ArrangeMeeting/ArrangeMeetingForm';
import {sendMeetingEmail} from '../../_services/backend_service';
import ReqHelper from "../../_helpers/request_helper";
import {taskSaveTaskData} from "../../_redux/actions/auth";
import {MockTaskData} from "../../_constance/classes/mockTask/mockTaskData";
import * as Gender from "../../_constance/enums/Gender";
import {getPatientLead} from "../../_redux/actions/index";
import * as AddressType from "../../_constance/values/addressType";
import * as TaskType from "../../_constance/values/taskType";
import {saveStats, getFooterData} from "../../_services/backend_service";
import {StatsType} from "../../_constance/enums/statsType";

const textToRemove = 'An unserem Termin werden <span style="font-weight:bold;color:rgb(200,200,200);">...(Geben Sie die Personen ein, die an der Besprechung teilnehmen)... </span>teilnehmen.';
const hoursOffset = 2;

class ArrangeMeetingComponent extends React.Component {
    _isMounted = false;
    myTimout;
    patientTimeout;
    taskTimeOut;
    leadStatusTimeOut;

    constructor(props) {
        super(props);
        this.state = {
            currentSection: 1,
            loader: null,
            modalLoader: true,
            savingData: false,
            errorComponent: Alert,
            leadId: 0,
            leadData: {},
            hideBar: false,
            editedMeeting: false,
            breadCrumbs: [
                {
                    link: "",
                    key: "arrange-meeting",
                    name: null
                }
            ],
            arrangeForm: new ArrangeMeetingForm(),
            rerender: Date.now(),
            pass: ReqHelper.simple_password_generator(),
            customGreetingsText: null,
            emlPath: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const {match: {params}} = this.props;
        let type = params.type;
        if (this._isMounted) {
            this.setState({
                leadId: params.leadId,
                type: type,
                hideBar: true,
                pass: ReqHelper.simple_password_generator()
            })
        }
        document.body.classList.remove('modal-open');
        window.scrollTo(0, 0);
        this.loadCustomGreetings();
        this.getInitialPatient();
        this.myTimeout = setTimeout(() => {
            this.getInitialDataTime();
            this.getBasicTasksData();
        }, 200);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
        clearTimeout(this.patientTimeout);
        clearTimeout(this.taskTimeOut);
        clearTimeout(this.leadStatusTimeOut);
    };

    componentDidUpdate() {
        this._promptUnsavedChange(this.state.editedMeeting)
    }

    _promptUnsavedChange(isUnsaved = false) {
        window.onbeforeunload = isUnsaved;
    }

    getInitialPatient = () => {
        const {appLang, getPatientLeadData, patientLeadStatus} = this.props;
        if (patientLeadStatus !== ResponseStatus.READY) {
            getPatientLeadData(appLang);
            this.patientTimeout = setTimeout(() => {
                this.getInitialPatient()
            }, 1000);
        }
    }

    saveStatistics = () => {
        const {fpFirstMail, fpName, userId} = this.props;
        const statsData = {
            type: StatsType.MEETING,
            data: {
                userId: userId,
                fpName: fpName,
                fpFirstMail: fpFirstMail,
            }
        }
        saveStats(statsData)
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        if (this._isMounted) {
                            console.log(res.data.data);
                        }
                    } else {
                        console.log("Unable to save data to Statistics");
                    }
                }
            })
            .catch((error) => {
                console.log("Unable to save data to Statistics");
            });
    }

    createIcs() {
        const ics = require('ics');
        const {arrangeForm} = this.state;
        const timestamp = arrangeForm.getFieldByKey('meeting_timestamp').value;
        const meetingStrasse = arrangeForm.getFieldByKey("meeting_strasse").value;
        const meetingStrasseNr = arrangeForm.getFieldByKey("meeting_strasse_nr").value;
        const meetingWohungsnummer = arrangeForm.getFieldByKey("meeting_wohungsnummer").value;
        const meetingPlz = arrangeForm.getFieldByKey("meeting_plz").value;
        const meetingStadt = arrangeForm.getFieldByKey("meeting_stadt").value;
        const meetingFirstName = arrangeForm.getFieldByKey('meeting_first_name').value;
        const meetingLastName = arrangeForm.getFieldByKey('meeting_last_name').value;
        const meetingEmail = arrangeForm.getFieldByKey('meeting_email').value;
        const {fpFirstMail, fpName, FPphone} = this.props;
        const event = {
            start: [timestamp.getFullYear(), timestamp.getMonth() + 1, timestamp.getDate(), timestamp.getHours(), timestamp.getMinutes()],
            duration: {hours: 1},
            title: 'Ihr Termin mit Promedica',
            description: `Der Termin mit Ihrem Ansprechpartner von Promedica24 findet um ${timestamp.getHours()}:${timestamp.getMinutes() === 0 ? '00' : timestamp.getMinutes()} Uhr statt.\n
      Sollten Sie Ihren Termin absagen müssen, melden Sie sich bitte bei Ihrem Ansprechpartner unter der ${FPphone ? FPphone : ''}.`,
            location: `${meetingStrasse} ${meetingStrasseNr}${meetingWohungsnummer ? `/${meetingWohungsnummer}` : ''}, ${meetingPlz} ${meetingStadt}, statt.`,
            status: 'CONFIRMED',
            organizer: {name: `${fpName}`, email: `${fpFirstMail}`},
            attendees: [
                {
                    name: `${meetingFirstName} ${meetingLastName}`,
                    email: `${meetingEmail}`,
                    rsvp: true,
                    partstat: 'ACCEPTED',
                    role: 'REQ-PARTICIPANT'
                },
            ]
        }
        let icsContent = '';

        ics.createEvent(event, (error, value) => {
            if (error) {
                console.log('error:', error);
                return;
            }
            icsContent = value;
        });

        return icsContent
    }

    loadCustomGreetings = () => {
        getFooterData(this.props.userId).then(response => {
            if (response && this._isMounted) {
                this.setState({
                    customGreetingsText: response.data.data[0].wysiwyg
                })
            }
        })
    }

    previousPageClick = async () => {
        if (this._isMounted) {
            this.setState({
                currentSection: this.state.currentSection > 1 ? parseInt(this.state.currentSection) - 1 : 0,
                savingData: false,
            })
        }
        window.scrollTo(0, 0);
    };

    nextPageClick = async () => {
        if (this.state.currentSection === 3) {
            this.props.history.push(`${baseData.subFolders}leadDetails/${this.state.leadId}`);
        } else {
            if (this.state.currentSection === 2) {
                let documents_to_send = [];
                this.state.arrangeForm.flatFieldsList.forEach(element => {
                    if (element.value === true) {
                        documents_to_send.push(element.key)
                    }
                });
                const meetingEmailContent = this.state.arrangeForm.getFieldByKey("meeting_email_content");
                let content = meetingEmailContent.value;
                content = content.replace(textToRemove, "");
                const emailAddress = this.state.leadData.client.email;
                const {translateKey, fpFirstMail, fpName, patientLeadData, userId} = this.props;
                if (ReqHelper.isEmail(emailAddress)) {
                    const {leadId, pass} = this.state;
                    const patientData = patientLeadData.find(patient => patient.leadId === leadId);
                    const allPatientsforLead = patientLeadData.filter(element => (element.leadId === leadId));
                    let otherPatients = [];

                    if (allPatientsforLead.length > 1) {
                        otherPatients = allPatientsforLead.filter(element => (element.id !== patientData.id)).flat();
                    }

                    if (patientData) {
                        this.setState({savingData: true})
                        const {arrangeForm} = this.state;
                        const meetingFirstName = arrangeForm.getFieldByKey('meeting_first_name');
                        const meetingLastName = arrangeForm.getFieldByKey('meeting_last_name');
                        const meetingEmail = arrangeForm.getFieldByKey('meeting_email');
                        const meetingTimeStamp = arrangeForm.getFieldByKey("meeting_timestamp");
                        const meetingGender = arrangeForm.getFieldByKey("meeting_gender");
                        const meetingCopyToFP = arrangeForm.getFieldByKey("meeting_copy_to_fp");
                        const mailTitle = arrangeForm.getFieldByKey("mail_title");
                        const sendMeetingLink = arrangeForm.getFieldByKey('meeting_fugen_sie_den_link').value;
                        const isSecondPatient = arrangeForm.getFieldByKey("meeting_multiple_patients").value;

                        let genderText = translateKey('contract-text-herr') + '/' + translateKey('form-text-frau');
                        if (meetingGender.value === parseInt(Gender.MALE)) {
                            genderText = translateKey('contract-text-herr');
                        } else {
                            if (meetingGender.value === parseInt(Gender.FEMALE)) {
                                genderText = translateKey('form-text-frau');
                            }
                        }
                        const fpFirstEmailValue = fpFirstMail ? fpFirstMail : '';
                        const fpNameValue = fpName ? fpName : '';
                        let restData = {
                            password: pass,
                            name: meetingFirstName.value,
                            surname: meetingLastName.value,
                            email: meetingEmail.value,
                            userID: userId,
                            leadID: leadId,
                            copyToFp: meetingCopyToFP.value,
                            title: mailTitle.value,
                            fpName: fpNameValue,
                            fpFirstMail: fpFirstEmailValue,
                            fpSecondMail: null,
                            patientID: patientData.id,
                            genderText: genderText,
                            sendMeetingLink: sendMeetingLink,
                            isSecondPatient: isSecondPatient,
                            secondPatientId: otherPatients.length > 0 ? otherPatients[0].id : '0',
                            meetingTimeStamp: new Date(meetingTimeStamp.value).getTime() / 1000
                        }
                        if (this._isMounted) {
                            this.setState({
                                currentSection: this.state.currentSection < 3 ? parseInt(this.state.currentSection) + 1 : 3,
                                savingData: false,
                            })
                        }
                        const icsContent = this.createIcs();
                        sendMeetingEmail(documents_to_send, content, emailAddress, restData, icsContent)
                            .then(res => {
                                if (res) {
                                    if (res.status === 200) {
                                        if (this._isMounted) {
                                            this.setState({
                                                emlPath: res.data.data,
                                                currentSection: this.state.currentSection < 3 ? parseInt(this.state.currentSection) + 1 : 3,
                                                savingData: false,
                                            })
                                        }
                                        window.scrollTo(0, 0);
                                        const meetingNurDieMaterialen = this.state.arrangeForm.getFieldByKey("meeting_nur_die_materialen");
                                        if (meetingNurDieMaterialen.value) {
                                            this.createNewEventTask();
                                            this.saveStatistics();
                                        }
                                        let newLeadData = this.state.leadData;
                                        newLeadData.status = 717700015;
                                        this.setState({
                                            leadData: {
                                                ...this.state.leadData,
                                                status: 717700015
                                            }
                                        });
                                        this.props.saveLeadData(newLeadData, this.props.appLang);
                                    } else {
                                        if (this._isMounted) {
                                            this.setState({
                                                savingData: false,
                                                errorComponent: {
                                                    show: true,
                                                    type: "danger",
                                                    message: translateKey("basic_error_message")
                                                }
                                            })
                                        }
                                        this.myTimeout = setTimeout(() => {
                                            if (this._isMounted) {
                                                this.setState({errorComponent: Alert});
                                            }
                                        }, 3000);
                                    }
                                } else {
                                    if (this._isMounted) {
                                        this.setState({
                                            savingData: false,
                                            errorComponent: {
                                                show: true,
                                                type: "danger",
                                                message: translateKey("basic_error_message")
                                            }
                                        })
                                    }
                                    this.myTimeout = setTimeout(() => {
                                        if (this._isMounted) {
                                            this.setState({errorComponent: Alert});
                                        }
                                    }, 3000);
                                }
                            })
                            .catch((error) => {
                                if (this._isMounted) {
                                    this.setState({
                                        savingData: false,
                                        errorComponent: {
                                            show: true,
                                            type: "danger",
                                            message: translateKey("basic_error_message")
                                        }
                                    })
                                }
                                this.myTimeout = setTimeout(() => {
                                    if (this._isMounted) {
                                        this.setState({errorComponent: Alert});
                                    }
                                }, 3000);
                            });
                    } else {
                        if (this._isMounted) {
                            this.setState({
                                savingData: false,
                                errorComponent: {
                                    show: true,
                                    type: "danger",
                                    message: translateKey("meeting_empty_patient_list")
                                }
                            })
                        }
                        this.myTimeout = setTimeout(() => {
                            if (this._isMounted) {
                                this.setState({errorComponent: Alert});
                            }
                        }, 3000);
                    }
                } else {
                    if (this._isMounted) {
                        this.setState({
                            savingData: false,
                            errorComponent: {
                                show: true,
                                type: "danger",
                                message: translateKey("email_validation_error")
                            }
                        })
                    }
                    this.myTimeout = setTimeout(() => {
                        if (this._isMounted) {
                            this.setState({errorComponent: Alert});
                        }
                    }, 3000);
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        currentSection: this.state.currentSection < 3 ? parseInt(this.state.currentSection) + 1 : 3,
                        savingData: false,
                    })
                }
                window.scrollTo(0, 0);
                const {arrangeForm, leadData} = this.state;
                const meetingPlaceType = arrangeForm.getFieldByKey("meeting_place_type");
                const meetingStrasse = arrangeForm.getFieldByKey("meeting_strasse");
                const meetingStrasseNr = arrangeForm.getFieldByKey("meeting_strasse_nr");
                const meetingWohungsnummer = arrangeForm.getFieldByKey("meeting_wohungsnummer");
                const meetingPlz = arrangeForm.getFieldByKey("meeting_plz");
                const meetingStadt = arrangeForm.getFieldByKey("meeting_stadt");
                const meeting_art_des_gesprachs_field = arrangeForm.getFieldByKey("meeting_art_des_gesprachs");

                if (meeting_art_des_gesprachs_field.value === TaskType.EVENT) {
                    switch (meetingPlaceType.value) {
                        case AddressType.CLIENT:
                            if (
                                meetingStrasse.value !== leadData.client.address.street ||
                                meetingStrasseNr.value !== leadData.client.address.buildingNumber ||
                                meetingWohungsnummer.value !== leadData.client.address.apartmentNumber ||
                                meetingPlz.value !== leadData.client.address.postalCode
                            ) {
                                let newLeadData = leadData;

                                newLeadData.client.address.street = meetingStrasse.value;
                                newLeadData.client.address.buildingNumber = meetingStrasseNr.value;
                                newLeadData.client.address.apartmentNumber = meetingWohungsnummer.value;
                                newLeadData.client.address.postalCode = meetingPlz.value;
                                newLeadData.client.address.city = meetingStadt.value;
                                newLeadData.patientObj = newLeadData.patient;

                                this.props.saveLeadData(newLeadData, this.props.appLang);
                            }
                            break;
                        case AddressType.PATIENT:
                            if (
                                meetingStrasse.value !== leadData.patient.address.street ||
                                meetingStrasseNr.value !== leadData.patient.address.buildingNumber ||
                                meetingWohungsnummer.value !== leadData.patient.address.apartmentNumber ||
                                meetingPlz.value !== leadData.patient.address.postalCode
                            ) {
                                let newLeadData = leadData;
                                newLeadData.patient.address.street = meetingStrasse.value;
                                newLeadData.patient.address.buildingNumber = meetingStrasseNr.value;
                                newLeadData.patient.address.apartmentNumber = meetingWohungsnummer.value;
                                newLeadData.patient.address.postalCode = meetingPlz.value;
                                newLeadData.patient.address.city = meetingStadt.value;
                                newLeadData.patientObj = newLeadData.patient;

                                this.props.saveLeadData(newLeadData, this.props.appLang);
                            }
                            break;
                        default:
                    }
                }
            }
        }
    };

    createNewEventTask = () => {
        const {match: {params}, translateKey, userId, appLang} = this.props;
        const {leadData, arrangeForm} = this.state;
        let tempTaskData = {...MockTaskData};
        const meetingTimeStamp = arrangeForm.getFieldByKey("meeting_timestamp");
        const meeting_art_des_gesprachs_field = arrangeForm.getFieldByKey("meeting_art_des_gesprachs");
        const meetingPlaceType = arrangeForm.getFieldByKey("meeting_place_type");
        const meetingStrasse = arrangeForm.getFieldByKey("meeting_strasse").value;
        const meetingStrasseNr = arrangeForm.getFieldByKey("meeting_strasse_nr").value;
        const meetingWohungsnummer = arrangeForm.getFieldByKey("meeting_wohungsnummer").value;
        const meetingPlz = arrangeForm.getFieldByKey("meeting_plz").value;
        const meetingStadt = arrangeForm.getFieldByKey("meeting_stadt").value;
        //atachments
        const addDocumentsToEmail = arrangeForm.getFieldByKey("meeting_fugt_dokumente_hinzu").value; 
        const meetingBetreuungsfragebogen = arrangeForm.getFieldByKey("meeting_betreuungsfragebogen").value;
        const meetingIsoZertifikat = arrangeForm.getFieldByKey("meeting_iso_zertifikat").value;
        const meetingSicher = arrangeForm.getFieldByKey("meeting_sicher_und_gut_versorgt").value;
        const meetingPflegeratgeber = arrangeForm.getFieldByKey("meeting_pflegeratgeber").value;
        const meetingPrasentationPm24 = arrangeForm.getFieldByKey("meeting_prasentation_pm24").value;
        const meetingTuvZertifikat = arrangeForm.getFieldByKey("meeting_tuv_zertifikat").value;
        const meetingVollmacht = arrangeForm.getFieldByKey("meeting_vollmacht").value;
        const meetingCareBoxLeaflet = arrangeForm.getFieldByKey("meeting_carebox_leaflet").value;
        const meetingKundeportal = arrangeForm.getFieldByKey("meeting_kundeportal").value;

        let atachmentList = '';

        if(meetingBetreuungsfragebogen){ atachmentList += `${translateKey("meeting_betreuungsfragebogen")} \n`};
        if(meetingIsoZertifikat){ atachmentList += `${translateKey("docs-iso-cert")} \n`};
        if(meetingSicher){ atachmentList += `${translateKey("meeting_sicher")} \n`};
        if(meetingPflegeratgeber){ atachmentList += `${translateKey("docs-advisor")} \n`};
        if(meetingPrasentationPm24){ atachmentList += `${translateKey("docs-presentation")} \n`};
        if(meetingTuvZertifikat){ atachmentList += `${translateKey("docs-tuv-cert")} \n`};
        if(meetingVollmacht){ atachmentList += `${translateKey("docs-fullpower")} \n`};
        if(meetingCareBoxLeaflet){ atachmentList += `${translateKey("docs-care-box-leaflet")} \n`};
        if(meetingKundeportal){ atachmentList += `${translateKey("docs-kundeportal")} \n`};
        

        tempTaskData.StartTime = new Date(meetingTimeStamp.value).getTime() / 1000;
        let nextHours = new Date(meetingTimeStamp.value);
        nextHours.setHours(nextHours.getHours() + hoursOffset);
        tempTaskData.FinishTime = new Date(nextHours).getTime() / 1000;
        tempTaskData.concerns = params.leadId;
        tempTaskData.concernName = `${leadData.client.lastName} ${leadData.client.firstName}`;
        tempTaskData.concernType = "lead";
        // tempTaskData.taskType = meeting_art_des_gesprachs_field.value;
        tempTaskData.taskType = TaskType.EVENT;
        tempTaskData.owner = userId;
        tempTaskData.title = translateKey("arrange-meeting-task-title");
        if (meeting_art_des_gesprachs_field.value === TaskType.EVENT) {
            switch (meetingPlaceType.value) {
                case AddressType.CLIENT:
                    tempTaskData.description = `${translateKey("meeting-event-note-client")} ${meetingStrasse} ${meetingStrasseNr}${meetingWohungsnummer ? `/${meetingWohungsnummer}` : ''}, ${meetingPlz} ${meetingStadt} \n`;
                    tempTaskData.description += `${translateKey("leads")}: ${leadData.client.lastName} ${leadData.client.firstName} \n`;
                    tempTaskData.description += `${translateKey("email")}: ${leadData.client.email ? leadData.client.email : ''} \n`;
                    tempTaskData.description += `${translateKey("mobile_phone")}: ${leadData.client.phone ? leadData.client.phone : ''} \n`;
                    tempTaskData.description += `${translateKey("home_phone")}: ${leadData.client.cellPhone ? leadData.client.cellPhone : ''} \n`;
                    if(addDocumentsToEmail){ tempTaskData.description += `${translateKey("add-documents-to-email")}: \n${atachmentList}`};
                    break;
                case AddressType.PATIENT:
                    tempTaskData.description = `${translateKey("meeting-event-note-patient")} ${meetingStrasse} ${meetingStrasseNr}${meetingWohungsnummer ? `/${meetingWohungsnummer}` : ''}, ${meetingPlz} ${meetingStadt} \n`;
                    tempTaskData.description += `${translateKey("leads")}: ${leadData.client.lastName} ${leadData.client.firstName} \n`;
                    tempTaskData.description += `${translateKey("email")}: ${leadData.client.email ? leadData.client.email : ''} \n`;
                    tempTaskData.description += `${translateKey("mobile_phone")}: ${leadData.client.phone ? leadData.client.phone : ''} \n`;
                    tempTaskData.description += `${translateKey("home_phone")}: ${leadData.client.cellPhone ? leadData.client.cellPhone : ''} \n`;
                    if(addDocumentsToEmail){ tempTaskData.description += `${translateKey("add-documents-to-email")}: \n${atachmentList}`};
                    break;
                default:
                    tempTaskData.description = `${translateKey("meeting-event-note-other")} ${meetingStrasse} ${meetingStrasseNr} ${meetingWohungsnummer ? `/${meetingWohungsnummer}` : ''}, ${meetingPlz} ${meetingStadt} \n`;
                    tempTaskData.description += `${translateKey("leads")}: ${leadData.client.lastName} ${leadData.client.firstName} \n`;
                    tempTaskData.description += `${translateKey("email")}: ${leadData.client.email ? leadData.client.email : ''} \n`;
                    tempTaskData.description += `${translateKey("mobile_phone")}: ${leadData.client.phone ? leadData.client.phone : ''} \n`;
                    tempTaskData.description += `${translateKey("home_phone")}: ${leadData.client.cellPhone ? leadData.client.cellPhone : ''} \n`;
                    if(addDocumentsToEmail){ tempTaskData.description += `${translateKey("add-documents-to-email")}: \n${atachmentList}`};
            }
        } else {
            // tempTaskData.description = `${translateKey("leads")}: ${leadData.client.lastName} ${leadData.client.firstName} \n${translateKey("email")}: ${leadData.client.email ? leadData.client.email : ''} \n${translateKey("mobile_phone")}: ${leadData.client.phone ? leadData.client.phone : ''} \n${translateKey("home_phone")}: ${leadData.client.cellPhone ? leadData.client.cellPhone : ''}`;
            tempTaskData.description = `${translateKey("meeting-call-note")} \n`;
            tempTaskData.description += `${translateKey("leads")}: ${leadData.client.lastName} ${leadData.client.firstName} \n`;
            tempTaskData.description += `${translateKey("email")}: ${leadData.client.email ? leadData.client.email : ''} \n`;
            tempTaskData.description += `${translateKey("mobile_phone")}: ${leadData.client.phone ? leadData.client.phone : ''} \n`;
            tempTaskData.description += `${translateKey("home_phone")}: ${leadData.client.cellPhone ? leadData.client.cellPhone : ''} \n`;
            if(addDocumentsToEmail){ tempTaskData.description += `${translateKey("add-documents-to-email")}: \n${atachmentList}`};
        }
        this.props.saveTaskData(tempTaskData, appLang)
            .then((res) => {
                this.props.addTaskDataFun(res, tempTaskData);
            })
            .catch((err) => {
                if (this._isMounted) {
                    this.setState({
                        savingData: false,
                        errorComponent: {
                            show: true,
                            type: "danger",
                            message: translateKey("basic_error_message")
                        }
                    })
                }
                this.myTimeout = setTimeout(() => {
                    if (this._isMounted) {
                        this.setState({errorComponent: Alert});
                    }
                }, 3000);
            })
    }

    clearAlertComponent = (withTimeOut = false) => {
        let timeOutTime = 0;
        if (withTimeOut) {
            timeOutTime = 2000;
        }
        setTimeout(() => {
            if (this._isMounted) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        errorComponent: {
                            ...prevState.errorComponent,
                            show: false,
                            type: "danger",
                            message: null
                        }
                    }
                })
            }
        }, timeOutTime);
    }

    showAlertMessage = (type, message) => {
        if (this._isMounted) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    errorComponent: {
                        ...prevState.errorComponent,
                        show: true,
                        type: type,
                        message: message
                    }
                }
            })
        }
        this.clearAlertComponent(true);
    }

    getInitialDataTime() {
        if (this.props.leadsStatus.length > 0) {
            clearTimeout(this.myTimeout);
            this.getLeadsDataByTimestamp();
        } else {
            const {leadsStatus} = this.props
            if (leadsStatus === ResponseStatus.FREE) {
                this.props.getStatusLead(this.props.appLang);
                this.myTimeout = setTimeout(() => {
                    this.getInitialDataTime()
                }, 500);
            } else {
                if (leadsStatus === ResponseStatus.ERROR) {
                    clearTimeout(this.taskTimeOut);
                } else {
                    this.myTimeout = setTimeout(() => {
                        this.getInitialDataTime()
                    }, 500);
                }
            }
        }
    }

    getLeadsList = () => {
        this.props.pendingRequestLeads();
        axiosAuth.get("leads/list/0/" + this.props.appLang)
            .then(res => {
                if (res.status === 200) {
                    return res;
                } else {
                    return Promise.reject(res.status);
                }
            })
            .then(json => {
                return json.data
            })
            .then(res => {
                const {match: {params}} = this.props;
                for (let i = 0; i < res.length; i++) {
                    if (res[i].leadId === params.leadId) {
                        const {arrangeForm} = this.state;
                        const meetingFirstName = arrangeForm.getFieldByKey('meeting_first_name');
                        const meetingLastName = arrangeForm.getFieldByKey('meeting_last_name');
                        const meetingEmail = arrangeForm.getFieldByKey('meeting_email');
                        const meetingGender = arrangeForm.getFieldByKey('meeting_gender');
                        meetingFirstName.value = res[i].client.firstName;
                        meetingLastName.value = res[i].client.lastName;
                        meetingEmail.value = res[i].client.email;
                        meetingGender.value = res[i].client.gender;
                        let name = '---';
                        if (res[i].client.lastName !== null) {
                            name = res[i].client.lastName;
                            if (res[i].client.firstName !== null) {
                                name += ' ' + res[i].client.firstName;
                            }
                        } else {
                            if (res[i].client.firstName !== null) {
                                name = res[i].client.firstName;
                            }
                        }
                        if (this._isMounted) {
                            this.setState({
                                leadId: params.leadId,
                                leadData: res[i],
                                savingData: false,
                                modalLoader: false,
                                rerender: Date.now(),
                                breadCrumbs: [
                                    ...this.state.breadCrumbs,
                                    {
                                        link: "",
                                        key: "",
                                        name: name
                                    },
                                ]
                            })
                        }
                        break;
                    }
                }
            })
            .catch((error) => {
                this.props.clearDataLeads();
                this.getBreadCrumb();
            });
    }

    getLeadsDataByTimestamp() {
        const lastUpdate = this.props.leadsTimestamp;
        const actualTime = new Date().getTime();
        if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
            this.getLeadsList();
        } else {
            if (this.props.leadsData.length > 0) {
                this.getBreadCrumb();
            } else {
                this.getLeadsList();
            }
        }
    }

    getBreadCrumb() {
        const {match: {params}, leadsData} = this.props;
        for (let i = 0; i < leadsData.length; i++) {
            if (leadsData[i].id === params.leadId) {
                const {arrangeForm} = this.state;
                const meetingFirstName = arrangeForm.getFieldByKey('meeting_first_name');
                const meetingLastName = arrangeForm.getFieldByKey('meeting_last_name');
                const meetingEmail = arrangeForm.getFieldByKey('meeting_email');
                const meetingGender = arrangeForm.getFieldByKey('meeting_gender');
                const leadD = leadsData[i];
                meetingFirstName.value = leadD.client.firstName;
                meetingLastName.value = leadD.client.lastName;
                meetingEmail.value = leadD.client.email;
                meetingGender.value = leadD.client.gender;
                let name = '---';
                if (leadsData[i].client.lastName !== null) {
                    name = leadsData[i].client.lastName;
                    if (leadsData[i].client.firstName !== null) {
                        name += ' ' + leadsData[i].client.firstName;
                    }
                } else {
                    if (leadsData[i].client.firstName !== null) {
                        name = leadsData[i].client.firstName;
                    }
                }
                if (this._isMounted) {
                    this.setState({
                        leadId: params.leadId,
                        leadData: leadD,
                        savingData: false,
                        modalLoader: false,
                        rerender: Date.now(),
                        breadCrumbs: [
                            ...this.state.breadCrumbs,
                            {
                                link: "",
                                key: "",
                                name: name
                            },
                        ]
                    })
                }
                break;
            }
        }
    }

    getArrangeMeetingSections() {
        const {arrangeForm, rerender, currentSection, loader, leadData, pass, emlPath} = this.state;
        const {translateKey} = this.props;
        let sectionData = (
            <LoaderTable/>
        );

        if (!loader) {
            switch (currentSection) {
                case 1:
                    sectionData = (
                        <div className="section-class-container" rerender={rerender}>
                            <MeetingSection1
                                title={translateKey('arrange-meeting-title')}
                                rerender={rerender}
                                arrangeForm={arrangeForm}
                                leadData={leadData}
                                tasks={this.getCurrentTaskList()}
                                nextPageClick={this.nextPageClick}
                            />
                        </div>
                    );
                    break;
                case 2:
                    sectionData = (
                        <div className="section-class-container">
                            <MeetingSection2
                                title={translateKey('arrange-meeting-title2')}
                                rerender={rerender}
                                arrangeForm={arrangeForm}
                                leadData={leadData}
                                pass={pass}
                                nextPageClick={this.nextPageClick}
                                previousPageClick={this.previousPageClick}
                                textToRemove={textToRemove}
                                customGreetingsText={this.state.customGreetingsText}
                            />
                        </div>
                    );
                    break;
                case 3:
                    sectionData = (
                        <div className="section-class-container">
                            <MeetingSection3
                                rerender={rerender}
                                arrangeForm={arrangeForm}
                                leadData={leadData}
                                emlPath={emlPath}
                            />
                        </div>
                    );
                    break;
                default:
                    sectionData = (
                        <div className="section-class-container">
                            <div className="section-class-container">
                                <MeetingSection1
                                    title={translateKey('arrange-meeting-title')}
                                    rerender={rerender}
                                    arrangeForm={arrangeForm}
                                    leadData={leadData}
                                    tasks={this.getCurrentTaskList()}
                                />
                            </div>
                        </div>
                    );
            }
        }
        return sectionData;
    }

    showSaveModal = () => {
        if (this._isMounted) {
            this.setState({savingData: !this.state.savingData})
        }
    }

    hideSaveModal = () => {
        if (this._isMounted) {
            this.setState({savingData: false})
        }
    }

    getBasicTasksData() {
        const {tasksStatus} = this.props
        if (tasksStatus === ResponseStatus.READY) {
            clearTimeout(this.taskTimeOut);
        } else {
            if (tasksStatus === ResponseStatus.FREE) {
                this.props.getGlobalTasksData();
                this.taskTimeOut = setTimeout(() => {
                    this.getBasicTasksData()
                }, 500);
            } else {
                if (tasksStatus === ResponseStatus.ERROR) {
                    clearTimeout(this.taskTimeOut);
                } else {
                    this.taskTimeOut = setTimeout(() => {
                        this.getBasicTasksData()
                    }, 500);
                }
            }
        }
    }

    getCurrentTaskList() {
        const {tasksData} = this.props;
        const arrayOfTasksEvent = tasksData.filter((task => task.taskType === 'Event'))
        const dates = arrayOfTasksEvent.map(task => task.createdDate * 1000)
        const convertedDates = dates.map(date => new Date(date))
        const finalDates = convertedDates.map(date => new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()))
        return finalDates;
    }

    render() {
        const {errorComponent} = this.state;
        const {translateKey} = this.props;
        return (
            <div className="mainContentWrapper" onClick={() => {
                this.props.tookAction()
            }}>
                <NavBar breadCrumbs={this.state.breadCrumbs}/>
                <TableProperties hideBar={true} options={[]} filterData={[]}/>
                <div className="mainContent">
                    <SideBar/>
                    <LoaderModal show={this.state.savingData || this.state.modalLoader}
                                 text={this.state.savingData ? translateKey("saving_with_dots") : ''}/>
                    <div className="listDataWrapper allow-overflow">
                        {this.getArrangeMeetingSections()}
                        <AlertModalInfo size="lg" centeredAlert={true} show={errorComponent.show}
                                        key="save-success-fail-message" type={errorComponent.type}
                                        message={errorComponent.message}/>
                    </div>
                </div>
                <Prompt
                    when={this.state.editedMeeting}
                    message={translateKey("unsaved_data_lost")}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        leadsData: state.database.leads,
        leadsStatus: state.enums.leadStatus,
        leadsStatusStatus: state.enums.leadStatusStatus,
        tasksData: state.database.tasks,
        tasksStatus: state.database.tasksState,
        userId: state.auth.userId,
        fpFirstMail: state.auth.contactFpFirstEmail,
        fpName: state.auth.contactFpName,
        patientLeadData: state.database.patientLead,
        patientLeadStatus: state.database.patientLeadState,
        FPphone: state.auth.FPphone,
    };
};
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    tookAction: () => dispatch(authSetActionTimestamp()),
    setLeadsData: (data) => dispatch(setLeadsData(data)),
    pendingRequestLeads: () => dispatch(pendingLeadsData()),
    clearDataLeads: () => dispatch(removeLeadsData()),
    getStatusLead: (lang) => dispatch(getLeadStatusData(lang)),
    removeLeadsStatus: () => dispatch(removeLeadStatusData()),
    getGlobalTasksData: () => dispatch(getTasks()),
    setGlobalTasksData: (data) => dispatch(setTasksData(data)),
    saveTaskData: (data) => dispatch(taskSaveTaskData(data)),
    addTaskDataFun: (id, data) => dispatch(addNewTaskData(id, data)),
    getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
    saveLeadData: (data, lang) => dispatch(leadSave(data, lang)),
    needsSaveData: () => dispatch(needsSaveData()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArrangeMeetingComponent));