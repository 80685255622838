export const PHONE_PREFIXES = [
  {
    value: "+49",
    label: "DE"
  },
  {
    value: "+44",
    label: "UK"
  },
  {
    value: "+48",
    label: "PL"
  }
];