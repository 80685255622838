
import axiosBackend from './config/axios-backend';

export const sendMeetingEmail = function (docs, content, email, restData, icsContent) {
    let dataS={
        "documents_to_send": docs,
        "content": content,
        "emailAddress": email,
        "restData": restData,
        "icsContent": icsContent,
    }
    dataS = JSON.stringify(dataS);
    return axiosBackend.post('Api/sendMeetingMail', dataS)
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

export const getIp = function () {
    return axiosBackend.get('Api/getMyIp')
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

export const generatePdfBasedOnMultipliers = function (data) {
    return axiosBackend.post('Api/generateMultiPdf', JSON.stringify(data))
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

export const cancelContractSimpleOption = function (data) {
    return axiosBackend.post('Api/cancelContractSimpleOption', JSON.stringify(data))
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

export const sendZusammenfasungMail = function (data) {
    return axiosBackend.post('Api/sendZusammenfasung', JSON.stringify(data))
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

export const sendErrorLogToBackend = function (data) {
    return axiosBackend.post('Api/saveErrorLog', JSON.stringify(data))
        .then((data) => {return data;})
        .catch(error => {return error;});
}


//CONTRACT OR ENQUIRY SAVE/GET
export const saveEnquiryOrContractData = function (data) {
    return axiosBackend.post('Api/saveEnquiryOrContractData', JSON.stringify(data))
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

export const getEnquiryOrContractData = function (id) {
    return axiosBackend.get(`Api/getEnquiryOrContractData/${id}`)
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

// save offert data
export const saveOptionData = function (data) {
    return axiosBackend.post('Api/saveOptionData', JSON.stringify(data))
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

// get offert data
export const getOptionData = function (client_id, patient_id) {
    return axiosBackend.get(`Api/getOptionData/${client_id}/${patient_id}`)
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

export const generateRodoPdf = function (data) {
    return axiosBackend.post('Api/generateRodoPdf', JSON.stringify(data))
        .then((data) => {return data;})
        .catch(error => {return error;});
}

//save statistics
export const saveStats = function (data) {
    return axiosBackend.post('Api/saveStats', JSON.stringify(data)).then((data) => {
        return data;
    })
    .catch(error => {
        return error;
    });
}

export const getFooterData = function (userId) {
    return axiosBackend.get(`Api/getFooterData/${userId}`)
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

export const sendFooterData = function (userId, wysiwyg) {
    let dataS={
        "userId": userId,
        "wysiwyg": wysiwyg,
    }
    dataS = JSON.stringify(dataS);
    return axiosBackend.post('Api/sendFooterData', dataS)
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

export const generateCarerCvPdf = function (content, cvProduct, image) {
    let dataS={
        "content": content,
        "cvProduct": cvProduct,
        "image": image,
    }
    dataS = JSON.stringify(dataS);
    return axiosBackend.post('Api/generateCarerCvPdf', dataS)
    .then((data) => {
        return data;
    })
    .catch(error => {
        return error;
    });
}

export const sendTaskInfoEmail = function (item) {
    let dataS={
        "concernName": item.ConcernName,
        "title": item.Title,
        "fpComments": item.FpComments,
        "groupCauseFp": item.GroupCauseFp,
        "reasonForTerminationDetails": item.ReasonForTerminationDetails,
        "id": item.Id,
        "description": item.Description,
        "status": item.Status
    }
    dataS = JSON.stringify(dataS);
    return axiosBackend.post('Api/sendTaskInfoEmail', dataS)
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}
