import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../_redux/actions/index";
import { LoaderTable } from "../index";
import { getMultiplierCategory, saveMultiplierCategory } from "../../_services/kopiloten_service";
import { KoPilotenMainForm, KoPilotenCategory } from "../../_forms/KoPilotenForm/index";
import { CategoriesKeyList } from "../../_forms/KoPilotenForm/values/categoriesKeyList";
import KoPilotenMultiCategories from "./ko-piloten-multiplier-components/ko-piloten-multi-categories";
import { LeadCancelSaveComponent, AlertModalInfo } from "../../_components/index";
import { Alert} from "../../_constance/classes/index";
import { KoPilotenCategoryHelper } from "../../_helpers/index";
import produce from "immer";
 
class KoPilotenMultiplierCategoryComponent extends React.Component {
  _isMounted = false;
  myTimeout;
  constructor(props) {
    super(props);
    this.state = {
      loadingBasics: false,
      form: new KoPilotenMainForm(),
      multiId: this.props.multiId,
      categoryIds: [],
      editedKopiloten: false,
      saving: false,
      errorComponent: Alert,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    this.prepareMeBasicFormData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  putNewCategory(catId, label) {
    const nextState = produce(this.state, (draftState) => {
      const { form } = draftState;
      form.categories.push(
        new KoPilotenCategory(
          null,
          KoPilotenCategoryHelper.getMeNameBasedOnLabel(label),
          catId,
          draftState.multiId,
          null,
          [],
          [],
          KoPilotenCategoryHelper.getMeSubcategoriesBasedOnLabel(label),
          [],
          KoPilotenCategoryHelper.isSubCategory(label)
        )
      );
    });
    this.setState(nextState);
  }

  prepareMeBasicFormData() {
    getMultiplierCategory(this.props.multiId)
    .then((res) => {
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              categoryIds: res.CategoryIds.map((element)=>element.toUpperCase())
            }
        });
        this.prepareMeBasicCategory();
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  prepareMeBasicCategory() {
    if(this.props.multiCategoryEnum.length > 0) {
      clearTimeout(this.myTimeout);
      let count = 0;
      this.props.multiCategoryEnum.forEach((element, index) => {
        count++;
        this.putNewCategory(element.Value, element.Label); 
        if(count===this.props.multiCategoryEnum.length-1){
          setTimeout(() => {
            this.finalPreparation()
          }, 500);
        }
      });
    } else {
      this.myTimeout = setTimeout(() => {
        this.prepareMeBasicCategory();
      }, 200);
    }
  }

  finalPreparation(){
    const {form} = this.state;
    CategoriesKeyList.forEach((element,index) => {
      const category = form.getCategoryByName(element.key);
      form.moveCategory(index, category);
    });    
    form.categories.forEach((element, index) => {
      if (element.IsSubCategory === false) {
        if (element.SubCategoriesKeyList.length > 0) {
          element.SubCategoriesKeyList.forEach((transKey) => {
            let subKey = form.getCategoryIdByName(transKey);
            if (subKey) {
              form.assingSubCategorie(element.CategoryId, subKey);
            }
          });
        }
      }
      if (index >= form.categories.length - 1) {
        setTimeout(() => {
          this.setState({loadingBasics: false});
        }, 500);
      }
    });
  }

  editMeParentCategory=(key,value)=>{
    const { categoryIds } = this.state;
    let exists = categoryIds.indexOf(key.toUpperCase());
    if (exists === -1){
      if(value===true){
        if (this._isMounted) {
          this.setState(prevState => {
            return {
                ...prevState,
                editedKopiloten: true,
                categoryIds:[...prevState.categoryIds, key.toUpperCase()]
                }
            }
          );
        }
      }
    }else{
      if(value===false){
        let newCatList = [...this.state.categoryIds];
        newCatList.splice(exists, 1);
        this.setState({
          categoryIds: newCatList,
          editedKopiloten: true,
        });
      }
    }
  }

  saveChange = () =>{
    if(this._isMounted){
      this.setState({saving: true});
    }
    let dataToSend = {
      "MultiplierId": this.props.multiId,
      "CategoryIds": this.state.categoryIds
    }
    saveMultiplierCategory(dataToSend)
    .then(res => {
      console.log(res);
      if (this._isMounted) {
        this.setState({
          saving: false,
          errorComponent: {
            show: true,
            type: "success",
            message: "multiplier_successfully_saved"
          },
        })
        document.body.classList.remove('overflow-y-scroll');
      }
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({ 
            errorComponent: Alert,
            saving: false
          });
        }
      }, 2500);
    })
    .catch(error => {
      if (this._isMounted) {
        this.setState({
          saving: false,
          errorComponent: {
            show:true,
            type: "danger",
            message: "basic_error_message"
          }
        });
        document.body.classList.remove('overflow-y-scroll');
      }
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({ 
            errorComponent: Alert,
            saving: false
          });
        }
      }, 2500);
    });
  }

  getMeContent = () => {
    const { loadingBasics, form, categoryIds } = this.state;
    let content;
    if (loadingBasics) {
      content = (
        <div className="flexCenterItems emptyLoaderMin">
          <LoaderTable />
        </div>
      );
    } else {
      content = (
          <KoPilotenMultiCategories form={form} categoryIds={categoryIds} editMeCategory={(key,value)=>this.editMeParentCategory(key,value)}/>
      );
    }
    return content;
  };

  render() {
    const { editedKopiloten, saving, errorComponent} = this.state;
    const { modificationButtons, translateKey } = this.props;
    return(
      <div className="lead-client-row">
        <div className="lead-details-client-info multiplier-padding-bottom">
          {this.getMeContent()}
          {modificationButtons &&
            <LeadCancelSaveComponent saveChange={()=> this.saveChange()} key="cancelSaveMultiplier01" edited={editedKopiloten} savingData={saving} onlySave={true}/>
          }
          <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={translateKey(errorComponent.message)}/>       
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appLang: state.lang.appLanguage,
    multiCategoryEnum: state.enums.multipliersCategoryList,
    multiCategoryEnumStatus: state.enums.multipliersCategoryStatus,
  };
};
const mapDispatchToProps = (dispatch) => ({translateKey: (firstLvl, secondLvl) =>dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KoPilotenMultiplierCategoryComponent));
