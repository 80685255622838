import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getWz } from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar } from "../../_components/index";
import { ClientCarersData } from "../../_components/ClientDetailsComponenets/index";
import axiosAuth from "../../_services/config/axios-auth";
import { CarerTable } from "../../_constance/classes/index";
import { IconsMap } from "../../_helpers/index";

class SurveyPage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      breadCrumbs:[
        {
          link: "clients",
          key: "clients",
          name: null
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.getBasicData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };

  getBasicData() {
    axiosAuth.get("patients/list/CLIENT/0/" + this.props.appLang)
    .then(async res => {
        const wzData = await this.props.getWzData(res.data, this.props.appLang);
        
        const resolvePromisesSeq = async (items) => {
            const results = [];
            for (const item of items) {
                results.push(await item);
            }
            return results.flat();
        };

        let mapedRes = wzData.map(async (element) => {
            if(element.referenzeformularSurveys != undefined) {
                if(element.referenzeformularSurveys.length > 0) {
                    const survey = this.getSurvey(element).then(survey => new CarerTable(element, survey));
                    return survey
                } else {
                    return new CarerTable(element);
                }
            } else {
                return new CarerTable(element);
            }
        });

        mapedRes = await resolvePromisesSeq(mapedRes);

        this.setState({
            data: mapedRes,
        });
    });
  }

    async getSurvey(element) {
        const response = await Promise.all(element.referenzeformularSurveys.map(async survey => {
            let responseWithSurvey = await axiosAuth.get(`/survey/${survey}`).then(surveyDetails => {
                return new CarerTable(element, surveyDetails.data);
            });
            return responseWithSurvey;
        }));

        return response;
    }

  selectItemToShow(element){
    if (this.state.selectedKey !== element.wzid) {
        this.setState({selectedKey: element.wzid});
    } else {
        this.setState({selectedKey: 0});
    }
}

  render() {
    return (
      <div className="mainContentWrapper">
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper">
            <ClientCarersData 
                RefreshMe={() => this.getBasicData()}
                selectedKey={0} 
                id={0}
                clientId={0} 
                carerData={this.state.data}
                isBreakRemoved={true}
            />
            <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
  };
};

const mapDispatchToProps = dispatch => ({
  getWzData: (clients, lang) => dispatch(getWz(clients, lang)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyPage));