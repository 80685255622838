import React from 'react';
import Select from 'react-select';

function setValue(value, filterTable) {
    const itemValue = filterTable.filter(item => {
        return item.value == value;
    });

    return itemValue;
}
const Select2 = ({ options, value, disabled, onChange, aditionalStyles }) => {
    setValue(value, options);
    return (
        <Select
            disabled={disabled} 
            name="form-field-name"
            value={ setValue(value, options) }
            options={options}
            isDisabled={disabled}
            onChange={onChange}
            styles={aditionalStyles}
        />
    )
}

export default Select2