import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import SectionNavigationBar from "../../../_components/form-bb-components/sectionNavigationBar/SectionNavigationBar";
import * as Relationship from '../../../_forms/BBForm/values/relationship';
import * as IsContactPersonDataSameAs from '../../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as Sections from '../../../_forms/BBForm/values/sections';
import { IconsMap } from '../../../_helpers/icons_set'
import { SummaryOfferToolbar } from '../../form-bb-sections/index';
import SectionNavigationNewMenu from "../sectionNavigationBar/SectionNavigationNewMenu";

class SectionNavigationToolbarComponent extends React.Component {
  _isMounted = false;
  leadsTimeout;
  taskTimeout;
  constructor(props) {
    super(props);
    this.state = {
      form: props.form,
      newMenuOpen: false,
      idComponent: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  changeSectionIfUCan = (number) => {
    if (this.props.isUnderAnalize) {
      if (number >= 0 && number <= this.props.sectionMax) {
        const { form } = this.props;
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        if (number === Sections.CONTACT_PERSON) {
          if (isContactPersonDataSameAs.value !== IsContactPersonDataSameAs.YES) {
            this.props.changeSection(number)
            this.props.hideSectionMenu();
          }
        } else {
          if (number === Sections.PATIENT) {
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
            if (isDegreeRelationShipWithPatientCarer.value !== Relationship.TYPE_8) {
              this.props.changeSection(number)
              this.props.hideSectionMenu();
            }
          } else {
            this.props.changeSection(number)
            this.props.hideSectionMenu();
          }
        }
      }
    }
  }

  toogleIfUCan = () => {
    if (this.props.isUnderAnalize) {
      this.props.toggleSectionMenu();
    }
  }

  toggleFull = () =>{
    this.props.toggleFullScreen();
  }

  toogleNewOpenMenu = () => {
      this.setState({
          ...this.state,
          newMenuOpen: !this.state.newMenuOpen,
          idComponent: this.state.newMenuOpen ? this.state.idComponent : null,
      })
  }

  showComponent(idComponent) {
    this.setState(previousState => ({
        ...previousState,
        idComponent: idComponent,
      }));
  }

  render() {
    const { 
      form, 
      currentSection, 
      sectionMax, 
      sectionMenuOpen, 
      translateKey, 
      rerender, 
      showSectionValidation, 
      isUnderAnalize, 
      additionalToolbar, 
      onOpenOffer, 
      setOfferLoaded,
      isOfferButtonVisibleInToolbar,
      isAnwendedButtonVisibleInToolbar,
      setActiveSection,
      type,
      clientLeadBaseData,
      secondPatientForm,
      isAditionalPatient,
      isCareBoxForm
    } = this.props;
    
    const patientName = form.getFieldByKey('is_first_name_and_last_name_of_patient').value;

    return (
      <React.Fragment>
        <div className={`main-menu-toolabr  ${ this.state.newMenuOpen ? 'menu-open' : '' }`}>
          <img onClick={this.toggleFull} className="prom-logo-to-fullscreen" src={IconsMap.svg.promo_logo} alt={"Icon"}/>
          <div className={`main-menu-list ${!isUnderAnalize ? 'no-pointer-events' : ''}`}>
            <i className="fas fa-chevron-left fa-2x navigation_icon_color prev-navi" onClick={() => !this.state.newMenuOpen && this.changeSectionIfUCan(form.sections[currentSection].prevSection)}></i>
            <div className="page-list-container">
              <div className="form-navi-section-wrapper">{translateKey(form.sections[currentSection].sectionName)}</div>
              <div className="page-number-wrapper">
                <span className="navigation-actual-page-number">
                  {form.sections[currentSection].number}
                </span>
                <span className="navigation-rest-page-number">/{sectionMax}</span>
              </div>
            </div>
            <i className={`fas fa-caret-down fa-2x navigation_icon_color carpet-navi ${sectionMenuOpen ? 'active' : ''}`} onClick={() => !this.state.newMenuOpen &&  this.toogleIfUCan()}></i>
            <i className="fas fa-chevron-right fa-2x navigation_icon_color next-navi" onClick={() => !this.state.newMenuOpen && this.changeSectionIfUCan(form.sections[currentSection].nextSection)}></i>
          </div>
          <div className="main-menu-toolabr__name">
            <p className="name__item">
              { patientName ? patientName : '' }
            </p>
          </div>
          {additionalToolbar && !sectionMenuOpen && !this.state.newMenuOpen && (
            <SummaryOfferToolbar 
              onOpenOffer={onOpenOffer} 
              anwendedButton={false} 
              setOfferLoaded={setOfferLoaded} 
              isOfferButtonVisibleInToolbar={isOfferButtonVisibleInToolbar}
              isAnwendedButtonVisibleInToolbar={isAnwendedButtonVisibleInToolbar}
              setActiveSection={setActiveSection}
            />
          )}
          <div className={`main-menu-list new-menu-wrapper ${this.state.newMenuOpen ? 'new-menu-wrapper__times ': 'new-menu-wrapper__hamburger-menu'}`} onClick={this.toogleNewOpenMenu}>
            {!this.state.newMenuOpen && (<i className="fas fa-ellipsis-v" ></i>)}
            {this.state.newMenuOpen && (<i className="fas fa-times" ></i>)}
          </div>
        </div>
        <div className={`section-menu-navigation-section ${sectionMenuOpen?'show-navigation-menu':'hideMe'}`}>
          <SectionNavigationBar 
            showSectionValidation={showSectionValidation} 
            rerender={rerender} 
            sectionMax={sectionMax}
            changeSection={(number) => this.changeSectionIfUCan(number)} 
            sectionList={form} 
            activeSection={currentSection} 
            type={type}
            isCareBoxForm={isCareBoxForm}
            />
        </div>
        <div className={`section-menu-navigation-section ${this.state.newMenuOpen?'show-navigation-menu':'hideMe'}`}>
          <SectionNavigationNewMenu 
            form={form}
            newMenuOpen={this.state.newMenuOpen} 
            showComponent={(e) => this.showComponent(e)} 
            idComponent={this.state.idComponent}
            clientLeadBaseData={clientLeadBaseData}
            toogleMenu={() => this.toogleNewOpenMenu()}
            secondPatientForm={secondPatientForm}
            isAditionalPatient={isAditionalPatient}
            type={type}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) })
export default withRouter(connect(null, mapDispatchToProps)(SectionNavigationToolbarComponent));