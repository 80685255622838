import React from 'react';

const SumaryWhySection = ({ translateKey }) => (
  <div className="container-fluid no-gutters h-100 why-section">
    <div className="row no-gutters w-100">
      <div className="col no-gutters">
        <h1 className="why-title">
          {translateKey("summary-offer-section-why-title")}
        </h1>
      </div>
    </div>
    <div className="row no-gutters w-100">
      <div className="col-xl-8 col-sm-12 no-gutters h-100">
        <div className="section-title">
          {translateKey("summary-offer-section-why-1-title-1")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-1-item-1")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-1-item-2")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-1-item-3")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-1-item-4")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-1-item-5")}
        </div>
      </div>
    </div>
    <div className="row no-gutters w-100">
      <div className="col-xl-8 col-sm-12 no-gutters h-100">
        <div className="section-title">
          {translateKey("summary-offer-section-why-2-title-1")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-2-item-1")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-2-item-2")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-2-item-3")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-2-item-4")}
        </div>
      </div>
    </div>
    <div className="row no-gutters w-100">
      <div className="col-xl-8 col-sm-12 no-gutters h-100">
        <div className="section-title">
          {translateKey("summary-offer-section-why-3-title-1")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-3-item-1")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-3-item-2")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-3-item-3")}
        </div>
        <div className="section-item">
          <i className="fas fa-check fa-1x mr-md-2" />
          {translateKey("summary-offer-section-why-3-item-4")}
        </div>
      </div>
    </div>
  </div>
);

export default SumaryWhySection;