import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../_redux/actions/index";
import SideBarCollapseMultiplicators from "./SideBarCollapseMultiplicators";

class mutliplicatorSidebarFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edited: false,
    };
  }

  getMeContent = () => {
    const { form, activeCategories, translateKey } = this.props;
    let newList = [];
    form.categories.forEach(category => {
      if(!category.IsSubCategory){
        if(category.MultiCanBeSelected.length>0){
          newList.push(
            <SideBarCollapseMultiplicators
              key={category.CategoryId}
              category={category}
              name={translateKey(category.Name)}
              activeCategories={activeCategories}
              changeActive={(id)=>this.props.setActiveCategories(id)}
            />
          );
        }else{
          if(category.SubCategoriesKeyList.length>0){
            newList.push(
              <SideBarCollapseMultiplicators
                key={category.CategoryId}
                category={category}
                name={translateKey(category.Name)}
                activeCategories={activeCategories}
                changeActive={(id)=>this.props.setActiveCategories(id)}
              />
            );
          }
        }
      }
    });
    return newList;
  };

  render() {
    return (
      <React.Fragment>
        <div className='ko-pilonte-sidebar-wrapper'>
          {this.getMeContent()}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({translateKey: (firstLvl, secondLvl) =>dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(null, mapDispatchToProps)(mutliplicatorSidebarFilter));