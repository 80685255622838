
import axiosAuth from '../_services/config/axios-auth';
import * as Entity from "../_forms/BBForm/values/entity";

export const markAsViewed = function (type, id) {
    let data={
        "Id": id,
        "Viewed": true,
        "EntityName": type
    }
    return axiosAuth.post('activity/markAsViewed', data)
        .then(() => {
        })
        .catch(error => {
            console.log(error);
        });
}

export const getLeadsDataCall = function (lang) {
    return axiosAuth.get(`leads/list/0/${lang}`)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => { return getLeadsStatuses(res) })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}

export const getLeadsStatuses = function (leads) {
    const ids = leads.map((c) => c.leadId);
    const fields = ids.map((id) => ({
        Id: id,
        Entity: Entity.CONTACT,
        Fields: ["is_service_type_de", "is_reason_for_rejection_care_box"]
    }));
    return getDynamicFields(fields)
        .then(res => {
            if (res.length > 0) {
                const fields = res.map((item) => ({id: item.id, fields: item.fields})).reduce(function(map, obj) {
                    map[obj.id] = obj.fields;
                    return map;
                }, {});
                const leadsMap = leads.reduce(function(map, obj) {
                    if(obj.leadId in fields){
                        obj.serviceTypeDe = fields[obj.leadId].is_service_type_de;
                        obj.careBoxRejectionReason = fields[obj.leadId].is_reason_for_rejection_care_box;
                    } else {
                        obj.serviceTypeDe = null;
                        obj.careBoxRejectionReason = null;
                    }
                    map[obj.leadId] = obj;
                    return map;
                }, {});
                return Object.values(leadsMap);
            }
        })
        .catch(error => {
            console.log(error);
            return leads;
        });

}

export const getLeadsStatusCall = function (lang) {
    return axiosAuth.get(`lists/leadStatus/${lang}`)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}

export const getDynamicFields = function (data) {
    return axiosAuth.post('dynamic/retrieve', data)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}

export const saveDynamicFields = function (data) {
    return axiosAuth.post('dynamic/send', data)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}


export const assignPatients = function (data) {
    return axiosAuth.post('contracts/assignPatients', data)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}

export const getCareBoxSets = function () {
    return axiosAuth.get('/careBox/sets/currentlyOffered')
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}

export const getPicklist = function (data) {
    return axiosAuth.post('/dynamic/picklist', data)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}