
import axiosAuth from './config/axios-auth';

export const getEnumMultiplierCategory = function () {
    return axiosAuth.get('/lists/multiplierCategories/0')
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}


export const getMultiplierCategory = function (multiplierId) {
    return axiosAuth.get(`/multipliers/categories/${multiplierId}`)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}


export const saveMultiplierCategory = function (data) {
    return axiosAuth.post('/multipliers/categories/save', data)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}






export const getMultiplierCategoryList = function (categoryId) {
    return axiosAuth.get(`/multipliers/categories/list/${categoryId}`)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}


export const getMultiplierCategoryRange = function (clientId) {
    return axiosAuth.get(`/multiplierRange/list/${clientId}/0`)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}


export const saveMultiplierCategoryRange = function (data) {
    return axiosAuth.post('/multiplierRange/save', data)
        .then(res => { 
            if (res.status === 200) { 
                return res;
            } else {
                return Promise.reject(res); 
            }
        })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
}