import React from 'react';
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";
import { ReqHelper } from "../../../_helpers/index";
import { MDBBtn  } from 'mdbreact';

//types
const COUNTER = "COUNTER";
const MINUS = "MINUS";
const PLUS = "PLUS";

export default class CounterWithPeriod extends React.Component {
    static defaultProps = {
        valuesList: [],
        validationErrors: [],
        optionValueKey: 'value',
        optionLabelKey: 'label',
        className: '',
    };

    setTypeChange = ( key, value ) => {
        if(!ReqHelper.isInteger(value)){value = 0;}
        const { keyName, setTypeChangeToParent, field} = this.props;
        const restString = this._formatHelperToSeparateSliderValue(field.value)
        setTypeChangeToParent(keyName, `${value}|${restString}`);
    };

    setSelectTypeChange = event => {
        const { target: { value } } = event;
        const { keyName, setTypeChangeToParent, field} = this.props;
        const counterString = this._formatHelperToSeparateSliderValue(field.value, COUNTER)
        setTypeChangeToParent(keyName, `${counterString}|${value}`);
    };

    changeCounter= (type)=>{
        const { keyName, setTypeChangeToParent, field } = this.props;
        let prevNumber = this._formatHelperToSeparateSliderValue(field.value, COUNTER);
        if(!ReqHelper.isInteger(prevNumber)){
            prevNumber = 0;
        }else{
            if(type===PLUS){
                prevNumber++;
            }else{
                if(prevNumber>0){
                    prevNumber--;
                }else{
                    prevNumber = 0;
                }
            }
        }
        const restString = this._formatHelperToSeparateSliderValue(field.value)
        setTypeChangeToParent(keyName, `${prevNumber}|${restString}`);
    }

    prepareMeSelectValue=()=>{
        return this._formatHelperToSeparateSliderValue(this.props.field.value);
    }

    prepareMeCounterValue=()=>{
        const cValue = this._formatHelperToSeparateSliderValue(this.props.field.value, COUNTER);
        if(!ReqHelper.isInteger(cValue)){
            return 0;
        }
        return cValue;
    }

    _formatHelperToSeparateSliderValue =(value, type) =>{
        if(type===COUNTER){
          if(value){
            if(value.length>0){
              const valueArray = value.split('|');
              if(valueArray.length>0){
                return valueArray[0];
              }
            }
          }
          return 0;
        }else{
          if(value){
            if(value.length>0){
              const valueArray = value.split('|');
              if(valueArray.length>1){
                return valueArray[1]
              }
            }
          }
          return '';
        }
    }

    render() {
        const {
          periodList,
          keyName,
          validationErrors,
          showValidation,
          label,
          optionValueKey,
          optionLabelKey,
          chooseText,
          containerClass,
          counterClass,
          disabled,
          contentWrapperClass
        } = this.props;
        const showErrors = showValidation && validationErrors.length > 0;

        return (
            <div className={`${contentWrapperClass} select-component-wrapper ${showErrors ? 'is-invalid' : ''}`}>
                <label className='form-label-basic newUI'><span className="quick-label-position-fix">{label.toUpperCase()}</span></label>
                
                <div className={`${containerClass}`}>

                    <div className={`${counterClass}`}>
                        <MDBBtn className="btn btn-light btn-class-plus-minus" onClick={()=>this.changeCounter(MINUS)}>-</MDBBtn>
                        <input
                            type="text"
                            disabled={disabled}
                            className={`form-control counter-input newUI ${showErrors ? "is-invalid" : ""}`}
                            onChange={(e) =>this.setTypeChange(keyName, e.target.value)}
                            value={this.prepareMeCounterValue()}
                        />
                        <MDBBtn className="btn btn-light btn-class-plus-minus" onClick={()=>this.changeCounter(PLUS)}>+</MDBBtn>
                    </div>
                    
                    
                    
                    <select className={`form-control counter-select-class newUI ${showErrors ? 'is-invalid' : ''}`} disabled={disabled} onChange={this.setSelectTypeChange} value={this.prepareMeSelectValue()}>
                        <option hidden>{chooseText?chooseText:''}</option>
                        { periodList.map(possibleValue => {
                            const id = `${keyName}+${possibleValue[optionValueKey]}`;
                            return (
                                <option key={id} value={possibleValue[optionValueKey]}>{possibleValue[optionLabelKey]}</option>
                            );
                        })}
                    </select>
                </div>


                { showErrors && (
                    <ValidationErrorsList keyName={keyName} errors={validationErrors} />
                )}
            </div>
        );
    }
}
