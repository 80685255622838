import * as React from 'react';

class SectionNavigationNewMenuElement extends React.Component {
    showComponent = (idComponent) =>{
        this.props.showComponent(idComponent);
    }

    render() {
        const {isDisabled, sectionName, elementNumber, idComponent} = this.props;
        return (
            <div className={"section-navigation-wrapper section-navigation-new-map-element"+ (isDisabled===true ? ' hideMe' : '')}>
                <div onClick={() => this.showComponent(idComponent)} className={
                    `cursor-pointer section-navigation-tile`
                }>
                    <div className="navigation-section-text">
                        <span className={`section-number-in-new-navigation-menu`}>
                            {elementNumber}
                        </span>
                        <span className={`section-text-in-new-navigation-menu`}>{sectionName}</span>
                    </div>
                </div>
            </div>
        );
    }
}
export default SectionNavigationNewMenuElement;