import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MDBModal, MDBModalBody, MDBNav, MDBCloseIcon } from 'mdbreact';
import { TaskDetailsBasicComponent } from "../../_components/index";
import { addNewTaskData } from "../../_redux/actions/index";
import { MockTaskData } from "../../_constance/classes/mockTask/mockTaskData";
import { AlertModalInfo } from '../../_components/index';
import { taskSaveTaskData } from "../../_redux/actions/auth";

const NewNoteModal = ({show, type, onClose, translateKey, appLang, saveTaskData, addTaskDataFun, leadId}) =>  {
    const [taskData, setTaskData] = useState(JSON.parse(JSON.stringify(MockTaskData)));
    const [editedEvent, setEditedEvent] = useState(false);
    const [savingData, setSavingData] = useState(false);
    const [errorComponent, setErrorComponent] = useState({
        show: false,
        type: "danger",
        message: translateKey("basic_error_message")
      });

    useEffect(() => {
        setTaskData({
            ...taskData,
            concerns: leadId,
            taskType: type,
        });
    }, [type]);

    const saveChange = () =>{
        if(editedEvent){
            setSavingData(true);
            setEditedEvent(false);
            saveTaskData(taskData, appLang)
                .then((res)=>{
                    setSavingData(false);
                    setErrorComponent({ 
                        show: true,
                        type: "success",
                        message: translateKey("task_successfully_saved")
                    });
                    setTimeout(() => {
                        setErrorComponent({ 
                            show: false,
                            type: "success",
                            message: null
                        });
                        onClose();
                    }, 1500);
                    addTaskDataFun(res, taskData);
                })
                .catch((err)=>{
                    setSavingData(false);
                    setErrorComponent({ 
                        show: true,
                        type: "danger",
                        message: err.message
                    });
                    setTimeout(() => {
                        setErrorComponent({ 
                            show: false,
                            type: "danger",
                            message: null
                        });
                    }, 3000);
                })
        };
    }

    return (  
        <MDBModal isOpen={show} fullHeight size="lg" centered={true} className="new-task-modal">
            <MDBNav className="new-task-modal-nav">
                <MDBCloseIcon onClick={onClose}></MDBCloseIcon>
            </MDBNav>
            <MDBModalBody className="new-task-modal-body">
                {
                    savingData &&
                        <div className="modal fade show modalShowFull" id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="filterModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-sm modalShowLoaderDialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="empty-table-wrapper loader-as-modal-wrapper">
                                            <i className="fas fa-spinner fa-spin fa-4x empty-table-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                }
                <AlertModalInfo 
                    size="lg" 
                    centeredVertical={true} 
                    centeredAlert={true} 
                    show={errorComponent.show} 
                    key="save-success-fail-message" 
                    type={errorComponent.type} 
                    message={errorComponent.message}
                />
                {type === 'Call' &&
                    <TaskDetailsBasicComponent 
                        newOne={true} 
                        editedData={() => setEditedEvent(true)} 
                        edited={editedEvent} 
                        key="taskDetails"
                        element={taskData}
                    />
                }
                {type === 'Event' &&
                    <TaskDetailsBasicComponent 
                        newOne={true} 
                        editedData={()=> setEditedEvent(true)} 
                        edited={editedEvent}
                        key="taskDetails2" 
                        element={taskData}
                    />
                }
                <button type="button" className="btn btn-primary" onClick={() => saveChange()}>{translateKey('save')}</button>
            </MDBModalBody>
        </MDBModal>
    )
};

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveTaskData: (data) => dispatch(taskSaveTaskData(data)),
        addTaskDataFun: (id, data) => dispatch(addNewTaskData(id, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewNoteModal);
