import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import {CheckBoxSmallMultipleComponent} from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import {CheckBoxComponent} from "../../form-bb-components/checkbox-component/checkbox-component";
import {InputTypeTextComponent} from "../../form-bb-components/input-type-text-component/input-type-text-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import { ReqHelper } from "../../../_helpers/index";
import produce from "immer";

class SectionTherapien extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-15'),
            showValidation: props.showValidationErrors
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({showValidation: showValidationErrors});
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    setTypeIfNumber = (key, value) => {
        if(ReqHelper.isInteger(value)){
            this.setTypeChange(key, value);
        }else{
            if(value.trim()===''){
                this.setTypeChange(key, value);
            }else{
                const { section } = this.state;
                const field = section.getFieldByKey(key);
                if(field){
                    if(field.value.length>value.length){
                        this.setTypeChange(key, '');
                    }
                }
            }
        }
    } 

    render() {
        const {
          disabled,
          translateKey,
          showValidationErrors,
          subsectionName,
          sectionNumber,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const isSpeechTherapy = section.getFieldByKey('is_speech_therapy');
        const isErgotherapy = section.getFieldByKey('is_ergotherapy');
        const isPhysiotherapy = section.getFieldByKey('is_physiotherapy');
        const isOccupationalTheraphy = section.getFieldByKey('is_occupational_theraphy');

        const isTherapeuticCareAtHome = section.getFieldByKey('is_therapeutic_care_at_home');
        const isTherapeuticCareOutsideHome = section.getFieldByKey('is_therapeutic_care_outside_home');

        const smallCheckboxClass = "col-sm-6 col-lg-3";
        const inputClassName = "col-sm-12 col-lg-6 form-margin-bottom-fix";
        const labelClass = "col-sm-12";
        const isNewUI = true; 
        const classForCheckBox = "custom-control-inline illness-wrapper newUI-radio-check-box-class cursor-pointer";
        const classForCheckBoxSimple = "newUI-radio-check-box-class";

        const showTherapeuticInWeekTextField = isSpeechTherapy.value || isErgotherapy.value || isPhysiotherapy.value || isOccupationalTheraphy.value;

        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                        <div className="newUI-section-padding"> 
                            <div className="form-label-basic newUI cursor-pointer quick-label-position-fix">{translateKey("form-text-durchgeführt").toUpperCase()}</div>
                            <div className="checkboxes_wrapper row">
                                <div className={smallCheckboxClass}>
                                    <CheckBoxSmallMultipleComponent value={isSpeechTherapy.value} newUI={isNewUI} className={classForCheckBox} fieldName={"is_speech_therapy"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_speech_therapy')} text={translateKey("form-text-logopadie")} transKey="form-text-logopadie" keyName="is_speech_therapy" />
                                </div>
                                <div className={smallCheckboxClass}>
                                    <CheckBoxSmallMultipleComponent value={isErgotherapy.value} newUI={isNewUI} className={classForCheckBox} fieldName={"is_ergotherapy"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_ergotherapy')} text={translateKey("form-text-ergotherapie")} transKey="form-text-ergotherapie" keyName="is_ergotherapy" />
                                </div>
                                <div className={smallCheckboxClass}>
                                    <CheckBoxSmallMultipleComponent value={isPhysiotherapy.value} newUI={isNewUI} className={classForCheckBox} fieldName={"is_physiotherapy"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_physiotherapy')} text={translateKey("form-text-krankengymnastik")} transKey="form-text-krankengymnastik" keyName="is_physiotherapy" />
                                </div>
                                <div className={smallCheckboxClass}>
                                    <CheckBoxSmallMultipleComponent value={isOccupationalTheraphy.value} newUI={isNewUI} className={classForCheckBox} fieldName={"is_occupational_theraphy"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_occupational_theraphy')} text={translateKey("form-text-beschaftigungstherapie")} transKey="form-text-beschaftigungstherapie" keyName="is_occupational_theraphy" />
                                </div>
                            </div>
                        </div>
                        {(showTherapeuticInWeekTextField) && (
                            <>
                                <div className="row check-box-padding-newUI">
                                    <CheckBoxComponent checkClassWrapper={classForCheckBoxSimple} newUI={isNewUI} showValidation={showValidationErrors} className="col-lg-6" setTypeChangeToParent={this.setTypeChange} value={isTherapeuticCareAtHome.value} validationErrors={isTherapeuticCareAtHome.validationErrors} text={translateKey("form-text-therapeutkommt")} transKey="form-text-therapeutkommt" keyName="is_therapeutic_care_at_home" />
                                </div>
                                <div className="row check-box-padding-newUI">
                                    <CheckBoxComponent checkClassWrapper={classForCheckBoxSimple} newUI={isNewUI} showValidation={showValidationErrors} className="col-lg-6" setTypeChangeToParent={this.setTypeChange} value={isTherapeuticCareOutsideHome.value} validationErrors={isTherapeuticCareOutsideHome.validationErrors} text={translateKey("form-text-therapeutextern")} transKey="form-text-therapeutextern" keyName="is_therapeutic_care_outside_home" />
                                </div>
                                
                                <div className="newUI-section-padding"> 
                                    <div className="row">
                                        <InputTypeTextComponent newUI={isNewUI} labelClass={labelClass} showValidation={this.state.showValidation} className={inputClassName} setTypeChangeToParent={this.setTypeIfNumber} field={section.getFieldByKey('is_therapeutic_care_how_often_in_week_de')} text={translateKey("form-text-prowoche")} transKey="form-text-prowoche" keyName="is_therapeutic_care_how_often_in_week_de" />
                                    </div>
                                </div>
                            </>
                        )}
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(SectionTherapien));