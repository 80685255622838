export const Tiles = {
    TILEFIRSTCLOCK: "Den Vertrag sofort starten",
    TILESECONDBRAIN: "Unterstützung eines Betreuungsbedürftigen mit Demenz",
    TILETHIRDQUESTION: "Eine Betreuungskraft, die sich um Menschen, die an Multiple Sklerose leiden, kümmern kann",
    TILEFOURTHLANG: "Eine Betreuungskraft mit kommunikativen Sprachkenntnissen",
    TILEFIFTHBED: "Hilfe bei vollständiger Transferbedürftigkeit",
    TILESIXPANTIES: "Inkontinenzversorgung",
    TILESEVENSTOMA: "Eine Betreuungskraft die Stomabeutel wechseln kann",
    TILEEIGHTMEAL: "Eine Betreuungskraft die Diätkost für Diabetiker vorbereiten kann",
    TILENINESONDA: "PEG Sonde",
    TILETENBAG: "Betreuungskraft, die Urinbeutel entleeren und wechseln kann",
    TILEELEVENCAR: "Führerschein"
}