import * as React from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter} from 'mdbreact';

export class InfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: this.props.show,
        };
    }

    okMe = () => {
        this.props.okMe();
    }

    openDocuSighLink = () =>{
        window.open(this.props.linkToDocuSign, '_blank');
        this.props.okMe();
    }

    render() {
        const {titleMessage, mainMessage, okMessage, errorClass, linkToDocuSign, linkMessage, consentUrl = false, consentUrlMessage} = this.props;
        return (  
            <MDBModal isOpen={this.props.show} toggle={this.reject} fullHeight size="lg">
                <MDBModalHeader toggle={this.reject}>{titleMessage}</MDBModalHeader>
                <MDBModalBody>
                    <div className={`confirm-modal-main-message ${errorClass}`} dangerouslySetInnerHTML={{__html: mainMessage}} />
                </MDBModalBody>
                <MDBModalFooter>
                    {consentUrl && <a href={consentUrl} target="_blank" className="btn btn-primary">{consentUrlMessage}</a>}
                    {linkToDocuSign && <button type="button" className="btn btn-success" onClick={this.openDocuSighLink}>{linkMessage}</button>}
                    {okMessage && <button type="button" className="btn btn-primary" onClick={this.okMe}>{okMessage}</button>}
                </MDBModalFooter>
            </MDBModal>
        )
    }
}