import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import produce from 'immer';
import { translateKey } from "../../../_redux/actions/index";
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import MultiplePhonesComponent from '../../multiple-phones-component/MultiplePhonesComponent';
import SwitchComponent from "../../form-bb-components/switch-component/switch-component";
import WrappedDatePickerComponent from "../../form-bb-components/wrapped-date-picker-component/wrapped-date-picker-component";
import SelectComponent from "../../form-bb-components/select-component/SelectComponent";
import WrappedAutoSuggestComponent from "../../wrapped-autosuggest-component/WrappedAutoSuggestComponent";
import ReqHelper from '../../../_helpers/request_helper';
import * as Relationship from "../../../_forms/BBForm/values/relationship";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";

class SectionAngabenZurKontakt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-0'),
            showValidation: props.showValidationErrors,
            valueListForBetreienden: []
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    componentDidMount() {
        const { translateKey, form, isCareBox } = this.props;
        const isRodoFirstChoice = form.getFieldByKey("rodo_first_choice");
        let valueListForBetreienden = [
            { value: '717700000', label: translateKey('form-text-relationship-0') },
            { value: '717700001', label: translateKey('form-text-relationship-1') },
            { value: '717700002', label: translateKey('form-text-relationship-2') },
            { value: '717700003', label: translateKey('form-text-relationship-3') },
            { value: '717700005', label: translateKey('form-text-relationship-5') },
            { value: '717700006', label: translateKey('form-text-relationship-6') },
            { value: '717700007', label: translateKey('form-text-relationship-7') },
            { value: '717700008', label: translateKey('form-text-relationship-8') },
            { value: '717700010', label: translateKey('form-text-relationship-10') },
            { value: '717700011', label: translateKey('form-text-relationship-11') },
            { value: '717700012', label: translateKey('form-text-relationship-12') },
            { value: '717700013', label: translateKey('form-text-relationship-13') },
        ]
        if(!isCareBox) {
            if(isRodoFirstChoice.value===Relationship.TYPE_8){
                this.setTypeChange('is_degree_relationship_with_patient_carer', '717700008');
                valueListForBetreienden = [
                    { value: '717700008', label: translateKey('form-text-relationship-8') }
                ]
            }
            if(isRodoFirstChoice.value===Relationship.TYPE_0){
                valueListForBetreienden = [
                    { value: '717700000', label: translateKey('form-text-relationship-0') },
                    { value: '717700001', label: translateKey('form-text-relationship-1') },
                    { value: '717700002', label: translateKey('form-text-relationship-2') },
                    { value: '717700003', label: translateKey('form-text-relationship-3') },
                    { value: '717700005', label: translateKey('form-text-relationship-5') },
                    { value: '717700006', label: translateKey('form-text-relationship-6') },
                    { value: '717700007', label: translateKey('form-text-relationship-7') },
                    { value: '717700010', label: translateKey('form-text-relationship-10') },
                    { value: '717700011', label: translateKey('form-text-relationship-11') },
                    { value: '717700012', label: translateKey('form-text-relationship-12') },
                    { value: '717700013', label: translateKey('form-text-relationship-13') },
                ];
            }
        }
        this.setState({
            valueListForBetreienden: valueListForBetreienden
        })
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    setTypeChangeDegree = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { form } = this.props;
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
            form.setFieldsByKeys([key], value)
        });
        this.setState(nextState);
    };

    setTypeChangePostalCode = (key, suggestion) => {
        if(typeof suggestion === 'object' && suggestion !== null){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = suggestion.value;
                }
                const city = section.getFieldByKey('is_registered_city');
                if (city) {
                    city.value = suggestion.city;
                }
            });
            this.setState(nextState);
        }else{
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = suggestion;
                }
            });
            this.setState(nextState);
        }
    };

    setTypeChangeNumber = (key, value) => {
        if(value.trim()===''){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });
            this.setState(nextState);
        }else{
            if(ReqHelper.isNumber(parseInt(value.trim()))){
                const nextState = produce(this.state, draftState => {
                    const { section } = draftState;
                    const field = section.getFieldByKey(key);
                    if (field) {
                        field.value = value;
                    }
                });
                this.setState(nextState);
            }
        }
    };

    prepareMeCitySelect = () =>{
        if(this.props.cities.length>0){
            return this.props.cities.map(x => ({
                value: x.PostalCode!==null?x.PostalCode:'', 
                city:  x.City!==null?x.City:'',
                label:  x.City!==null?x.PostalCode+"-"+x.City:''
            }));
        }else{
            return [];
        }
    }

    render() {
        const {
          disabled,
          translateKey,
          sectionNumber,
          subsectionName,
          showValidationErrors,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          appLang,
          form,
          isCareBox
        } = this.props;
        const { section, valueListForBetreienden } = this.state;
        const isBirthday = section.getFieldByKey('is_birthday');
        const isDegreeRelationshipWithPatientCarer = section.getFieldByKey('is_degree_relationship_with_patient_carer');
        const isRegisteredZipPostalCode =  section.getFieldByKey('is_registered_zip_postal_code')
        const preparedSelectGroup = this.prepareMeCitySelect();
        const isNewUI = true;
        const phones = [
            {
                appLang: appLang,
                newUI: isNewUI,
                showValidation: this.state.showValidation,
                className: 'input-wrapper form-margin-bottom-fix',
                setTypeChangeToParent: this.setTypeChange,
                field: section.getFieldByKey('is_home_phone'), 
                text: translateKey("new_phone_label"),
                transKey: 'new_phone_label',
                keyName: 'is_home_phone'
            },
            {
                appLang: appLang,
                newUI: isNewUI,
                showValidation: this.state.showValidation,
                className: 'input-wrapper form-margin-bottom-fix',
                setTypeChangeToParent: this.setTypeChange,
                field: section.getFieldByKey('is_mobile_phone'), 
                text: translateKey("new_cellphone_label"),
                transKey: 'new_cellphone_label',
                keyName: 'is_mobile_phone'
            }
        ];

        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled}>
                        <div className="row">
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('lastname')} text={translateKey("last_name")} transKey="last_name" keyName="lastname"/>
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('firstname')} text={translateKey("first_name")} transKey="first_name" keyName="firstname"/>
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_registered_street')} text={translateKey("street")} transKey="street" keyName="is_registered_street"/>
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-6 col-lg-3 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_registered_street_number')} text={translateKey("form-text-section-0-2")} transKey="form-text-section-0-2" keyName="is_registered_street_number"/>
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-6 col-lg-3 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_registered_house_number')} text={translateKey("apartment_number")} transKey="apartment_number" keyName="is_registered_house_number"/>
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_registered_city')} text={translateKey("city")} transKey="city" keyName="is_registered_city"/>
                            <WrappedAutoSuggestComponent 
                                newUI={isNewUI} 
                                value={isRegisteredZipPostalCode.value} 
                                suggestions={preparedSelectGroup}
                                showValidation={this.state.showValidation}
                                keyName="is_registered_zip_postal_code"
                                field={isRegisteredZipPostalCode}
                                validationErrors={isRegisteredZipPostalCode.validationErrors}
                                text={translateKey("postal_code")}
                                setTypeChangeToParent={this.setTypeChangePostalCode}
                                disabled={disabled}
                                className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                            /> 

                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_email')} text={translateKey("email")} transKey="email" keyName="is_email"/>
                            <div className="col-sm-12 col-lg-6">
                                <MultiplePhonesComponent 
                                    phones={phones}
                                    showValidation={showValidationErrors}
                                    errorMessage={translateKey("error_for_multiple_fields")}
                                />
                            </div>

                            <SelectComponent
                                newUI={isNewUI} 
                                showValidation={this.state.showValidation}
                                keyName="is_degree_relationship_with_patient_carer"
                                valuesList={valueListForBetreienden}
                                value={isDegreeRelationshipWithPatientCarer.value}
                                validationErrors={isDegreeRelationshipWithPatientCarer.validationErrors}
                                isRequired={true}
                                label={translateKey("form-text-section-0-1")}
                                setTypeChangeToParent={this.setTypeChangeDegree}
                                className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                            />
                            {!isCareBox &&
                                <SwitchComponent
                                    disabled={disabled}
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                    transKey="form-text-contact-person-same-data"
                                    key="is_contact_person_data_same_as"
                                    keyName="is_contact_person_data_same_as"
                                    value={section.getFieldByKey('is_contact_person_data_same_as').value}
                                    validationErrors={section.getFieldByKey('is_contact_person_data_same_as').validationErrors}
                                    valueYes = '717700000'
                                    valueNo = '717700001'
                                />
                            }

                            { isDegreeRelationshipWithPatientCarer.value === '717700008' && (
                                <React.Fragment>
                                    <WrappedDatePickerComponent
                                        withCalendarIcon={true}
                                        newUI={isNewUI} 
                                        showValidation={showValidationErrors}
                                        keyName="is_birthday"
                                        value={isBirthday.value}
                                        validationErrors={isBirthday.validationErrors}
                                        text={translateKey("born")}
                                        setTypeChangeToParent={this.setTypeChange}
                                        disabled={disabled}
                                        className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                        withYear={true}
                                        birthDay={true}
                                        isRequired={true}
                                    />
                                    {section.getFieldByKey('is_height') &&
                                        <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors}
                                                                valueFormatter={ReqHelper.simpleValueFormatter}
                                                                className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                                setTypeChangeToParent={this.setTypeChangeNumber}
                                                                field={section.getFieldByKey('is_height')}
                                                                text={translateKey("height_with_unit")}
                                                                transKey="height_with_unit" keyName="is_height"/>
                                    }
                                    {section.getFieldByKey('is_weight') &&
                                        <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors}
                                                                valueFormatter={ReqHelper.simpleValueFormatter}
                                                                className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                                setTypeChangeToParent={this.setTypeChangeNumber}
                                                                field={section.getFieldByKey('is_weight')}
                                                                text={translateKey("weight_with_unit")}
                                                                transKey="weight_with_unit" keyName="is_weight"/>
                                    }
                                    {section.getFieldByKey('is_home_doctor') &&
                                        <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors}
                                                                className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                                                setTypeChangeToParent={this.setTypeChange}
                                                                field={section.getFieldByKey('is_home_doctor')}
                                                                text={translateKey("form-text-home-doctor")}
                                                                transKey="form-text-home-doctor"
                                                                keyName="is_home_doctor"/>
                                    }
                                </React.Fragment>
                            )}
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {return { 
    appLang: state.lang.appLanguage,
    cities: state.enums.citiesList,
    citiesStatus: state.enums.citiesListStatus
}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionAngabenZurKontakt));