export function formatPrice(x, mark = true) {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  if(parts[1]) {
    parts[1] = parts[1].slice(0, 2);
  }
  if (mark) {
    return parts.join(".") + " €";
  } else {
    return parts.join(".");
  }
}