import simpleAxios from './axios-no-auth';
import axios from 'axios';
import { baseData } from '../../_constance/base_data';
import { REFRESHED_TOKEN, REFRESHING_TOKEN, AUTH_LOGOUT, CLEAR_ALL_DATABASE } from '../../_redux/actions/action-types';
import ReqHelper from "../../_helpers/request_helper"
import store from '../../_redux/store';

const request = axios.create({
  baseURL: baseData.base_api
});

request.interceptors.request.use(config => {
  const token = localStorage.getItem('ap:appToken');
  config.headers = { 'X-Auth-Token': token };
  const currentTime = new Date().getTime();
  const actionTime = localStorage.getItem('ap:appActionTime');
  if (actionTime < currentTime) {
    logout();
    return Promise.reject("session expired");
  } else {
    return config;
  }
}, err => {
  return Promise.reject(err);
});

let isRefreshing = false;

let subscribers = [];

request.interceptors.response.use(config => {
  return config;
}, error => {
  const { config, response: { status } } = error;
  const originalRequest = config;

  const rsaToken = localStorage.getItem('ap:rsaToken');
  const login = localStorage.getItem('ap:appLogin');

  if (status === 401 && rsaToken && login) {
    if (!isRefreshing) {
      isRefreshing = true;
      store.dispatch({
        type: REFRESHING_TOKEN
      });
      simpleAxios.post('login', null, {
        headers: {
          'X-Auth-Login': login,
          'X-Auth-Password': rsaToken,
          'X-Device-Id': "ce4e1ce8cb2c9a9f",
          'X-Device-Os': "WEB",
          'Content-Type': 'application/json'
        }
      }).then(result => {
        localStorage.setItem("ap:appToken", result.data.Token);
        store.dispatch({
          type: REFRESHED_TOKEN,
          appToken: result.data.Token
        });
        onRrefreshed(result.data.Token);
        isRefreshing = false;
      }).catch(err => {
        console.log('Błąd odświeżania');
        logout();
        isRefreshing = false;
      });
      subscribers = [];
    }
    const requestSubscribers = new Promise(resolve => {
      subscribeTokenRefresh(token => {
        originalRequest.headers = { ...originalRequest.headers, 'X-Auth-Token': token }
        resolve(axios(originalRequest));
      });
    });
    return requestSubscribers;
  } else if (status === 401 && !isRefreshing) {
    console.log('401 i brak możliwości refresh');
    logout();
    return Promise.reject(ReqHelper.responseErrorHelper(401));
  } else if (error.response.config.url === '/docuSign/contract/send') {
    console.log('from docu sign error');
    return error.response;
  } else {
    let customStatus = status;

    if (error.response.request.response.includes('Nie można zmienić statusu bez utworzenia rozmowy telefonicznej dla kontaktu')) {
      customStatus = 802;
    }
    
    if (error.response.request.response.includes('Nie można zmienić statusu bez utworzenia spotkania dla kontaktu')) {
      customStatus = 803;
    }

    if (error.response.request.response.includes('Contact already exists.') || error.response.request.response.includes('A contact with email')) {
      customStatus = 804;
    }

    console.log(error);
    console.log('każdy inny przypadek');
    return Promise.reject(customStatus);
  }
});


function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRrefreshed(token) {
  subscribers.map(cb => cb(token));
}


function logout() {
  localStorage.removeItem("ap:appToken")
  localStorage.removeItem("ap:appLogin")
  localStorage.removeItem("ap:rsaToken")
  localStorage.removeItem("ap:appTokenTime");
  localStorage.removeItem("ap:appActionTime");
  localStorage.removeItem("ap:ContactFpFirstEmail");
  localStorage.removeItem("ap:ContactFpName");
  localStorage.removeItem("ap:ContactFpSecondEmail");
  store.dispatch({ type: AUTH_LOGOUT });
  store.dispatch({type: CLEAR_ALL_DATABASE});
}

export default request;