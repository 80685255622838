import * as React from 'react';

import { connect } from 'react-redux';
import { translateKey, getPromedicaProductsPricesData } from '../../../../_redux/actions/index';
import { CheckBoxComponent } from "../../../form-bb-components/checkbox-component/checkbox-component";
import { IconsMap } from "../../../../_helpers/icons_set";
import { formatPrice } from "../../../../_helpers/price_format";
import { ResponseStatus } from '../../../../_helpers/response_status';
import { PriceType } from '../../../../_constance/enums/priceType';

class SpracheSection extends React.Component {
  state = {
    isVideoActive: false,
    isDetailsActive: false,
    packetDetailsId: undefined,
    videoSrc: undefined
  }

  componentDidMount() {
    const { promedicaProductsPricesStatus, getPromedicaProductsPricesData } = this.props;

    if (promedicaProductsPricesStatus !== ResponseStatus.READY) {
      getPromedicaProductsPricesData();
    }
  }

  toggleVideo = (value) => {
    this.setState({
      isVideoActive: !this.state.isVideoActive,
      videoSrc: value
    })
  }

  toggleDetails = (value) => {
    this.setState({
      isDetailsActive: !this.state.isDetailsActive,
      packetDetailsId: value
    })
  }

  renderLanguagePackBox = (packet,index) => {
    const { 
      translateKey, 
      selectedLanguagePacket, 
      recommendedLanguagePacket, 
      setLanguagePacket,
      priceType
    } = this.props;

    const isPacketSelected = selectedLanguagePacket === packet.id;
    const isRecommendedPacket = packet.id === recommendedLanguagePacket;
    let price = '';
    if(priceType === PriceType.MONTHLY) {
      price = packet.price
    } else if (priceType === PriceType.DAILY){
      price = packet.price/30;
    } else {
      price = '-'
    }

    return (
      <div className={`language-pack-box ${isPacketSelected && 'language-pack-box-selected'}`} key={`sprache-container-${index}`}>
        {isRecommendedPacket && <div className="language-pack-box-selected-label">
          {translateKey("summary-offer-sprache-empfohlen")}
        </div>}
        <CheckBoxComponent
          keyName={packet.id}
          className="language-pack-check-box" 
          disabled={false}
          value={selectedLanguagePacket === packet.id} 
          setTypeChangeToParent={setLanguagePacket}
        />
        <div className={`language-pack-box-label pt-3 ${isPacketSelected && 'blue-icon'}`}>
          {packet.label}
        </div>
        <div className={`language-pack-box-price pt-5 pb-5 ${isPacketSelected && 'blue-icon'}`}>
        { priceType !== PriceType.NONE ?
            <>
              {formatPrice(price?price:0)}
              {' *'}
            </>
          : ''
        }
        </div>
        <div className="d-flex justify-content-center align-items-center w-100" >   
          <div role="button" onClick={() => this.toggleDetails(packet.id)} >
            <i className={`far fa-file fa-3x default-btn-hover ${isPacketSelected ? 'green-icon' : 'grey-icon'}`} />
          </div>
          <div className={`vertical ${isPacketSelected ? 'blue-icon' : 'grey-icon'}`} />
          <div role="button" onClick={() => this.toggleVideo(packet.video)} >
            <i className={`fab fa-youtube fa-3x default-btn-hover ${isPacketSelected ? 'green-icon' : 'grey-icon'}`} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { translateKey, availableLanguagePackets, priceType } = this.props;
    const { isDetailsActive, isVideoActive, packetDetailsId } = this.state;

    if (isVideoActive) {
      return (
        <div className="video-wrapper d-flex flex-column w-100 m-6">
          <div 
            role="button"
            className="d-flex justify-content-end default-btn-hover" 
            onClick={() => this.toggleVideo(undefined)}
          >
            <img src={IconsMap.other.close_icon} alt="x" />
          </div>
          <iframe 
            width="100%" 
            height="100%" 
            src={this.state.videoSrc}
            frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen 
            title={this.state.videoSrc}
          />
        </div>
      );
    }

    if (isDetailsActive) {
      return (
        <div className="video-wrapper d-flex flex-column w-100 m-6">
          <div 
            role="button" 
            className="d-flex justify-content-end default-btn-hover" 
            onClick={() => this.toggleDetails(undefined)}
          >
            <img src={IconsMap.other.close_icon} alt="x" />
          </div>
          <div className="d-flex flex-column">
            <div className="section-title">
              {availableLanguagePackets.find(item => item.id === packetDetailsId).label}
            </div>
            {availableLanguagePackets.find(item => item.id === packetDetailsId).subtitleKeys.map(((subtitle, index) => (
              <div key={index} className="section-item">
                {translateKey(`${subtitle}`)}
              </div>
            )))}
            {availableLanguagePackets.find(item => item.id === packetDetailsId).listItemKeys.map(((item, index) => (
              <div key={index} className="section-item">
                <i className="fas fa-check fa-1x mr-md-2" />
                {translateKey(item)}
              </div>
            )))}
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid no-gutters p-0 d-flex flex-column h-100">
        <div className="row w-100 no-gutters">
          <div className="sprache-container col-12 d-flex justify-content-center flex-wrap">
            {availableLanguagePackets.map((packet, index) => this.renderLanguagePackBox(packet, index))}
          </div>
        </div>
        <div className="row w-100 d-flex justify-content-center no-gutters">
        { priceType !== PriceType.NONE ?
          <div className="section-item">
            {`* `}
            { priceType === PriceType.MONTHLY ? translateKey("summary-offer-sprache-footer") : translateKey("summary-offer-sprache-footer-daily-payment") }
          </div>
          : ''
        }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ 
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  getPromedicaProductsPricesData: () => dispatch(getPromedicaProductsPricesData())
});
const mapStateToProps = state => {
  return {
    promedicaProductsPrices: state.enums.promedicaProductsPrices,
    promedicaProductsPricesStatus: state.enums.promedicaProductsPricesStatus,
    priceType: state.priceType.priceType,
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(SpracheSection);