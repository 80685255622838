import * as FieldTypes from "../fieldTypes";
import Form from "../Form";
import FormSection from "../FormSection";
import FormField from "../FormField";
import * as CareBoxDeliveryAddress from "./values/careBoxDeliveryAddress";
import { duplicatedIngredients } from "./values/careBoxIngredientsDuplicated";
import * as CareBoxTypes from "./values/careBoxTypes";
import * as Entity from "./values/entity";
import * as CareBoxInsuranceType from "./values/careBoxInsuranceType";
import { skipList } from "./values/formKeySkipList";
import * as IsContactPersonDataSameAs from "./values/isContactPersonDataSameAs";
import * as ReasonForDisinterest from "./values/reasonForDisinterest";
import * as Relationship from "./values/relationship";
import * as Sections from "./values/sections";

import { getSectionNumberByName } from "../../_constance/enums/sectionPageListCareBox";

const LAST_SECTION_KEY = 'is_last_section';

const skipListPatient = [
  "is_patient_last_name",
  "is_patient_first_name",
  "is_service_address_street",
  "is_service_address_street_number",
  "is_service_address_house_number",
  "is_service_address_postal_code",
  "is_service_address_city",
  "is_patient_cellphone",
  "is_patient_phone"
];
const flattenFields = fieldsList =>
  fieldsList.reduce(
    (previous, field) =>
      Object.assign(previous, {
        [field.key]: field.value
      }),
    {}
  );
const flattenFieldsArraywithSkiped = fieldsList =>
  fieldsList
    .filter(element => {
      if (skipList.indexOf(element.key) !== -1) {
        return false;
      }
      return true;
    })
    .map(field => field.key)
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    })
   
const flattenFieldsArraySkipedBoth = fieldsList =>
  fieldsList
    .filter(element => {
      if (skipListPatient.indexOf(element.key) !== -1 || skipList.indexOf(element.key) !== -1) {
        return false;
      }
      return true;
    })
    .map(field => field.key)
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    })

const birthdayField = new FormField({
  defaultValue: "",
  key: "is_birthday",
  entity: Entity.PATIENT,
  isRequired: true
});
// const weightField = new FormField({
//   defaultValue: "",
//   key: "is_weight",
//   entity: Entity.PATIENT,
//   isRequired: true
// });
// const heightField = new FormField({
//   defaultValue: "",
//   key: "is_height",
//   entity: Entity.PATIENT,
//   isRequired: true
// });
// const homeDoctorField = new FormField({
//   defaultValue: "",
//   key: "is_home_doctor",
//   entity: Entity.PATIENT,
//   isRequired: false
// });

export default class CareBoxForm extends Form {
  constructor() {
    super({
      sections: [
        new FormSection({
          key: "section-care-box-rodo",
          sectionName: "care-box-rodo",
          number: 1,
          fields: [
            new FormField({
              key: "is_data_processing_agreement",
              entity: Entity.CARE_BOX,
              defaultValue: null,
              isRequired: true
            }),
            new FormField({
              key: "is_marketing_agreement",
              entity: Entity.CARE_BOX,
              defaultValue: null,
              isRequired: false
            }),
            new FormField({
              key: "is_contactid",
              entity: Entity.CARE_BOX,
              isRequired: false
            }),
            new FormField({
              key: "is_name",
              entity: Entity.CARE_BOX,
              isRequired: false
            }),
            new FormField({
              key: "is_patientid",
              entity: Entity.CARE_BOX,
              isRequired: false
            })
          ]
        }),
        // Section 2
        new FormSection({
          key: "section-0",
          sectionName: "form-text-care-box-ection1-title",
          number: 2,
          fields: [
            new FormField({
              key: "lastname",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            // new FormField({
            //   key: "rodo_signature",
            //   entity: Entity.CONTACT,
            //   isRequired: true
            // }),
            new FormField({
              key: "firstname",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_degree_relationship_with_patient_carer",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_street",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_street_number",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_zip_postal_code",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_house_number",
              entity: Entity.CONTACT
            }),
            new FormField({
              key: "is_mobile_phone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_home_phone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_registered_city",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_email",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.EMAIL
              }
            }),
            new FormField({
              key: "is_address_type",
              entity: Entity.CONTACT,
              defaultValue: "717700000"
            }),
            new FormField({
              key: "is_correspondence_name",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isLastName = form.getFieldByKey("lastname");
                const isFirstName = form.getFieldByKey("firstname");
                return isLastName.value + isFirstName.value;
              }
            }),
            new FormField({
              key: "is_correspondence_city",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_city");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_zip_postal_code",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_zip_postal_code");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_house_number",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_house_number");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_street",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_street");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_street_number",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_street_number");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_contact_person_data_same_as",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              },
              defaultValue: IsContactPersonDataSameAs.YES
            }),
            birthdayField,
            // weightField,
            // heightField,
            // homeDoctorField
          ],
          validationExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationshipWithPatientCarer.value !== Relationship.TYPE_8) {
              return [
                "is_birthday",
                // "is_weight",
                // "is_height",
                // "is_home_doctor"
              ];
            }
          },
          payloadExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationshipWithPatientCarer.value !== Relationship.TYPE_8) {
              return [
                "is_birthday",
                // "is_weight",
                // "is_height",
                // "is_home_doctor"
              ];
            }
          }
        }),
        // Section 3
        new FormSection({
          key: "section-2",
          sectionName: "form-text-care-box-ection2-title",
          number: 3,
          fields: [
            birthdayField,
            // weightField,
            // heightField,
            // homeDoctorField,
            new FormField({
              key: "is_patient_last_name",
              entity: Entity.CONTACT,
              isRequired: true,
              valueMapper: form => {
                const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
                const lastName = form.getFieldByKey('lastname').value;
                if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
                  return lastName;
                }
              }
            }),
            new FormField({
              key: "is_patient_first_name",
              entity: Entity.CONTACT,
              isRequired: true,
              valueMapper: form => {
                const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
                const firstName = form.getFieldByKey('firstname').value;
                if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
                  return firstName;
                }
              }
            }),
            new FormField({
              key: "is_service_address_street",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_service_address_street_number",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_service_address_house_number",
              entity: Entity.CONTACT
            }),
            new FormField({
              key: "is_service_address_postal_code",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_service_address_city",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_first_name_and_last_name_of_patient",
              entity: Entity.PATIENT,
            }),
            new FormField({
              key: "is_patient_cellphone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_patient_phone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_gender",
              defaultValue: "717700000",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: { possibleValues: ['717700000', '717700001'] },
            }),
          ],
          validationExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
              return [
                "is_birthday",
                // "is_weight",
                // "is_height",
                // "is_home_doctor"
              ];
            }
          },
          payloadExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (
              isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8
            ) {
              return [
                "is_birthday",
                // "is_weight",
                // "is_height",
                // "is_home_doctor"
              ];
            }
          }
        }),
        // Section 4
        new FormSection({
          key: "section-3",
          sectionName: "bb_pflegegrad_data_p_3",
          number: 4,
          fields: [
            new FormField({
              key: "is_pflegegrad",
              entity: Entity.PATIENT,
              isRequired: false
            }),
            new FormField({
              key: "is_applied_pflegegrad",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isAppliedLast6Months = form.getFieldByKey("is_applied_last_6_months");
                if(isAppliedLast6Months.value === '717700000'){
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_applied_last_6_months",
              entity: Entity.PATIENT,
              isRequired: true,
              defaultValue: '717700001'
            }),
            new FormField({
              defaultValue: "",
              key: "is_pflegegrad_posting_date",
              entity: Entity.PATIENT,
              isRequired: false
            }),
            new FormField({
              key: "is_insurance_type",
              entity: Entity.PATIENT,
              isRequired: true,
              defaultValue: '717700001',
              valueChangedCallback: (form, oldVal, newVal) => {
                const val = newVal === '717700001' ? '717700000' : '717700001';
                form.getFieldsByEntityAndKey(Entity.CONTACT, "is_private_client").setValueNoCallback(val);
                form.getFieldsByEntityAndKey(Entity.CARE_BOX, "is_private_client").setValueNoCallback(val);
              }
            }),
            new FormField({
              key: "is_kein_pflegegrad",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false
              /*valueMapper: (form) => {
                                const isPflegegrad = form.getFieldByKey('is_pflegegrad');
                                const isAppliedPflegegrad = form.getFieldByKey('is_applied_pflegegrad');
                                if(
                                    (isPflegegrad.value!==null && isPflegegrad.value.toString().trim() !=='') ||
                                    (isAppliedPflegegrad.value!==null && isAppliedPflegegrad.value.toString().trim() !=='') ||
                                    (isKrankenkasse.value!==null && isKrankenkasse.value.toString().trim() !=='')
                                ){
                                    return false
                                }else{
                                    return true
                                }
                            }*/
            })
          ],
          payloadExceptions: () => {
            return ["is_kein_pflegegrad"];
          }
        }),
        //CARE BOX
        new FormSection({
          key: "section-care-box",
          sectionName: "form-text-care-box",
          number: 5,
          isSpecialOffer: false,
          fields: [
            new FormField({
              key: "is_lead_status_care_box",
              entity: Entity.CONTACT,
              isFalseable: true,
              defaultValue: '717700003'
            }),
            new FormField({
              key: "is_service_type_de",
              entity: Entity.CONTACT,
              defaultValue: "717700000"
            }),
            new FormField({
              key: "is_reason_for_rejection_care_box",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700002') {
                  return true;
                }
                return false;
              },
              validationRules: {
                possibleValues: ReasonForDisinterest.LIST
              },
              valueMapper: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value == '717700003') {
                  return null;
                }
              }
            }),
            new FormField({
              key: "is_private_client",
              entity: Entity.CONTACT,
              defaultValue: '717700000',
              valueChangedCallback: (form, oldVal, newVal) => {
                const val = newVal === '717700001' ? '717700000' : '717700001';
                form.getFieldByKey("is_insurance_type").setValueNoCallback(val);
                form.getFieldsByEntityAndKey(Entity.CARE_BOX, "is_private_client").setValueNoCallback(newVal);
              }
            }),
            new FormField({
              key: "is_private_client",
              entity: Entity.CARE_BOX,
              defaultValue: '717700000'
            }),
            new FormField({
              key: "is_health_insurance",
              entity: Entity.CARE_BOX,
              defaultValue: '',
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002') {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_insurance_number",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002') {
                  const isPrivate = form.getFieldsByEntityAndKey(Entity.CARE_BOX, 'is_private_client').value === CareBoxInsuranceType.IS_PRIVATE;
                  return isPrivate === false;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_outpatient_assistance_name",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_street_pa",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_house_number_pa",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_apartement_number_pa",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_pa",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_pa",
              entity: Entity.CARE_BOX,
              isRequired: form => {
                const deliveryAdress = form.getFieldByKey('is_delivery_to_address');
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002' && deliveryAdress == '717700002') {
                  return true;
                }
                  return false;
              },
            }),
            new FormField({
              key: "is_delivery_to_address",
              entity: Entity.CARE_BOX,
              validationRules: {
                possibleValues: CareBoxDeliveryAddress.LIST
              },
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value !== '717700002') {
                  return true;
                }
                return false;
              },
            }),
            new FormField({
              key: "is_last_name_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_first_name_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_street_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_house_number_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_apartment_number_d",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_d",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_d",
              entity: Entity.CARE_BOX,
            }),
            new FormField({
              key: "is_care_box_setid",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isRequired: form => {
                const isCareBox = form.getFieldByKey("is_lead_status_care_box");
                if (isCareBox.value === '717700003') {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_bed_protectors",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_o",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_bed_protectors"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_one_time_gloves",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_r",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_one_time_gloves"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_one_time_gloves_type",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: '717700000',
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_one_time_gloves"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_one_time_gloves_size",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: '717700001',
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_one_time_gloves"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_hand_sanitizer",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dr",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_hand_sanitizer"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_surface_disinfectant",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dp",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_surface_disinfectant"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_masks",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_m",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_masks"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_masks_ffp2",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_m_ffp",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_masks_ffp2"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_masks_ffp2_set",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_ffp_set",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_masks_ffp2_set");
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_protective_aprons",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_f",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_protective_aprons"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_reusable_bed_protectors",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_protectors",
              entity: Entity.CARE_BOX,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_reusable_bed_protectors"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disposable_finger_overlay",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_njp",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disposable_finger_overlay"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_surface_disinfectant_1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dp1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_surface_disinfectant_1000"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_hand_sanitizer_1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_dr1000",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_hand_sanitizer_1000"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_reusable_protective_aprons",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_wfo",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_reusable_protective_aprons"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            new FormField({
              key: "is_disinfecting_wipes",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              isFalseable: true
            }),
            new FormField({
              key: "is_number_ch",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              defaultValue: 0,
              isRequired: form => {
                const checkbox = form.getFieldByKey("is_disinfecting_wipes"); 
                if (checkbox.value) {
                  return true
                } else {
                  return false
                }
              }
            }),
            //automatic filed fields
            new FormField({
              key: "is_contactid",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_patientid",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_status",
              entity: Entity.CARE_BOX,
              value: '717700000'
            }),
            new FormField({
              key: "is_name",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_name_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_surname_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_street_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_house_number_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_apartment_number_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_home_phone_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_cell_phone_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_email_address_p",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_birth_date",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_pflegegrad_duplicated",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_name_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_surname_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_street_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_house_number_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_apartment_number_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_postal_code_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_city_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_home_phone_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_cell_phone_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_email_address_k",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "ownerid",
              entity: Entity.CARE_BOX
            }),
            new FormField({
              key: "is_contactid_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_name_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_care_box_appid",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_month",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_year",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
            new FormField({
              key: "is_status_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS,
              value: '717700000'
            }),
            new FormField({
              key: "ownerid_duplicated",
              entity: Entity.CARE_BOX_INGRIDIENTS
            }),
          ]
        }),

        // SECTION notes
        new FormSection({
          key: "section-notiz",
          sectionName: "form-text-notiz",
          number: 6,
          fields: [
            new FormField({
              key: "is_additional_fp_comments",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                maxLength: 5000
              }
            }),
            new FormField({
              key: "is_comment_for_carer_de",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                maxLength: 5000
              }
            })
          ]
        }),

        // Hidden section (for fields that have constant values)
        new FormSection({
          key: "section-hidden",
          fields: [
            new FormField({
              key: "is_contact_source",
              entity: Entity.CONTACT,
              defaultValue: "717700001"
            }),
            new FormField({
              key: "is_last_section",
              entity: Entity.PATIENT,
              defaultValue: "section-care-box-rodo"
            })
          ]
        })
      ]
    });
  }

  get payload() {
    const patientFields = this.getFieldsByEntity(Entity.PATIENT);
    const contactFields = this.getFieldsByEntity(Entity.CONTACT);
    const careBoxFields = this.getFieldsByEntity(Entity.CARE_BOX);
    const careBoxIngredientsFields = this.getFieldsByEntity(Entity.CARE_BOX_INGRIDIENTS);
    
    return {
      patient: flattenFields(patientFields),
      contact: flattenFields(contactFields),
      ccareBox: flattenFields(careBoxFields),
      careBoxIngredients: flattenFields(careBoxIngredientsFields),
    };
  }

  getRetiveDataCareBox(id) {
    let retrieveData = [];
    const careBoxFields = this.getFieldsByEntity(Entity.CARE_BOX);
    let flattenFields = flattenFieldsArraywithSkiped(careBoxFields);
    flattenFields = flattenFields.map(item => {
      if (item === 'is_pflegegrad_duplicated' ) {
        return 'is_pflegegrad';
      } else {
        return item;
      }
    });
    retrieveData.push({
      id: id,
      entity: Entity.CARE_BOX,
      fields: flattenFields
    });
    return retrieveData;
  }

  getRetiveDataCareBoxIng(id) {
    let retrieveData = [];
    const careBoxIngFields = this.getFieldsByEntity(Entity.CARE_BOX_INGRIDIENTS);
    let flattenFields = flattenFieldsArraywithSkiped(careBoxIngFields);
    flattenFields = flattenFields.map(item => {
      if (duplicatedIngredients.includes(item)) {
        return item.replace('_duplicated', '');
      } else {
        return item;
      }
    });
    retrieveData.push({
      id: id,
      entity: Entity.CARE_BOX_INGRIDIENTS,
      fields: flattenFields
    });
    return retrieveData;
  }

  getRetiveDataALl(lkId, patietList) {
    let retrieveData = [];
    let patietListWithOutZero = patietList.filter(element => element !== 0);
    if (patietList.length !== "0") {
      const patientFields = this.getFieldsByEntity(Entity.PATIENT);
      const flattenFields = flattenFieldsArraywithSkiped(patientFields);
      patietListWithOutZero.forEach(element => {
        retrieveData.push({
          id: element,
          entity: Entity.PATIENT,
          fields: flattenFields
        });
      });
    }
    if (lkId !== "0") {
      const contactFields = this.getFieldsByEntity(Entity.CONTACT);
      if (patietList.length !== "0") {
        if (patietListWithOutZero.length > 0) {
          retrieveData.push({
            id: lkId,
            entity: Entity.CONTACT,
            fields: flattenFieldsArraywithSkiped(contactFields)
          });
        } else {
          retrieveData.push({
            id: lkId,
            entity: Entity.CONTACT,
            fields: flattenFieldsArraySkipedBoth(contactFields)
          });
        }
      } else {
        retrieveData.push({
          id: lkId,
          entity: Entity.CONTACT,
          fields: flattenFieldsArraySkipedBoth(contactFields)
        });
      }
    }
    return retrieveData;
  }

  getPayloadForSection(sectionKey, lkId, patientId, careBoxId) {
    const patientFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.PATIENT,
      true
    );
    const contactFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CONTACT,
      true
    );
    let careBoxFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CARE_BOX,
      true
    );

    let patientDataToSave = flattenFields(patientFields);
    if (patientId === "0") {
      if (Object.keys(patientDataToSave).length > 0) {
        patientDataToSave["is_contact_id"] = lkId;
      }
    }
    let returnArray = [];
    if (patientId === "0" || patientId === 0) {
      patientDataToSave["is_contact_id"] = lkId;
    }
    let field = this.getFieldByKey(LAST_SECTION_KEY);
    if(field){
      patientDataToSave[LAST_SECTION_KEY] = field.value
    }
    
    returnArray.push({
      Id: patientId,
      Entity: Entity.PATIENT,
      Fields: patientDataToSave
    });
    let contactFieldsToSave = flattenFields(contactFields);
    if(sectionKey === 'section-care-box') {
      const valueCareBox = this.getFieldByKey("is_lead_status_care_box").value;
      if(valueCareBox == '717700002') {
        delete contactFieldsToSave.is_private_client;
      }
    }
    if (Object.keys(contactFieldsToSave).length > 0) {
      returnArray.push({
        Id: lkId,
        Entity: Entity.CONTACT,
        Fields: contactFieldsToSave
      });
    }

    if (Object.keys(careBoxFields).length > 0) {
      careBoxFields = careBoxFields.map(item => {
        if(item.key.endsWith('_duplicated')) {
            item.key = item.key.replace('_duplicated', '');
        }
        return item;
      });
      let careBoxFieldsToSave = flattenFields(careBoxFields);
      returnArray.push({
        Id: careBoxId,
        Entity: Entity.CARE_BOX,
        Fields: careBoxFieldsToSave
      });
    }
    return returnArray;
  }

  getCareBox(sectionKey, careBoxId) {
    let careBoxFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CARE_BOX,
      true
    );
    
    let returnArray = [];
    
    if (Object.keys(careBoxFields).length > 0) {
      careBoxFields = careBoxFields.map(item => {
        if(item.key.endsWith('_duplicated')) {
            item.key = item.key.replace('_duplicated', '');
        }
        return item;
      });
      let careBoxFieldsToSave = flattenFields(careBoxFields);
      returnArray.push({
        Id: careBoxId,
        Entity: Entity.CARE_BOX,
        Fields: careBoxFieldsToSave
      });
    }

    return returnArray;
  }

  getCareBoxIngredients(sectionKey, ingredientsId, idToSave) {
    let careBoxIngredientsFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CARE_BOX_INGRIDIENTS,
      false
    );
    if (ingredientsId) {
      let careBoxIngredientsIDFields = this.getFieldByKey('is_care_box_appid');
      careBoxIngredientsIDFields.value = ingredientsId;
      careBoxIngredientsFields["is_care_box_appid"] = careBoxIngredientsIDFields;
    }

    let returnArray = [];
    if (Object.keys(careBoxIngredientsFields).length > 0) {
      careBoxIngredientsFields = careBoxIngredientsFields.map(item => {
        if(item.key.endsWith('_duplicated')) {
            item.key = item.key.replace('_duplicated', '');
        }
        return item;
      });
      let careBoxIngredientsFieldsToSave = flattenFields(careBoxIngredientsFields);
      returnArray.push({
        Id: idToSave,
        Entity: Entity.CARE_BOX_INGRIDIENTS,
        Fields: careBoxIngredientsFieldsToSave
      });
    }

    return returnArray;
  }

  changeLeadStatusAfterEndOfForm(lkId) {
    return [
      {
        "Id": lkId,
        "Entity": Entity.CONTACT,
        "Fields": {'is_customer_status_de': '717700011'}
      }
    ]
  }

  isEditable(status, hasDraft) {
    if(status === '717700001' || status === '0') {
      return true;
    } else {
      return false;
    }
  }

  isDisabled(status, hasDraft) {
    return !hasDraft || !this.isEditable(status, hasDraft)
  }

  isDisabled(status, hasDraft, alwaysNew) {
    if(alwaysNew === true || hasDraft === true) {
      return false;
    } else {
      return !this.isEditable(status, hasDraft);
    }
  }
}
