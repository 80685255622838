import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import {RouteButtonComponent, AlertModalInfo} from "../../_components/index"
import ReqHelper from "../../_helpers/request_helper";
import {AutoSuggestComponent} from "../../_components/autosuggest-component/AutoSuggestComponent";
import * as LangSelect from "../../_constance/values/languageSelect";
import * as FieldTypes from '../../_forms/fieldTypes';
import InputTypeComponent from "../../_components/input-type-component/input-type-component";
import { Alert} from "../../_constance/classes/index";


class LeadPatientComponent extends React.Component {
    _isMounted = false;
    myTimout
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
            edited: this.props.edited,
            errorComponent: Alert,
            regionsFP: this.props.regionsFP?this.props.regionsFP:[]
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.remove('modal-open');
        window.scrollTo(0, 0);
      }
    
    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };

    handleChangeLastName(event){
        this.props.element.patientObj.lastName = event;
        this.props.element.patientObj.fullName = `${event} ${this.props.element.patientObj.firstName}`;
        this.props.element.patient = `${event} ${this.props.element.patientObj.firstName}`;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    patientObj: {
                        ...prevState.element.patientObj,
                        lastName: event,
                        fullName: `${event} ${prevState.element.patientObj.firstName}`
                    }
                }
            }
        });
        this.props.editedData();
    }

    handleChangeFirstName(event){
        this.props.element.patientObj.firstName = event;
        this.props.element.patientObj.fullName = `${this.props.element.patientObj.lastName} ${event}`;
        this.props.element.patient = `${this.props.element.patientObj.lastName} ${event}`;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    patientObj: {
                        ...prevState.element.patientObj,
                        firstName: event,
                        fullName: `${prevState.element.patientObj.lastName} ${event}`
                    }
                }
            }
        });
        this.props.editedData();
    }
   
    handlechangePostalCode = event => {
        if(typeof event === 'object'){
            if(!this.checkIfAssignToRegion(event.value)){
                this.setState({
                    errorComponent: {
                        show: true,
                        type: "info",
                        message: "franchise_region_for_other_FP_message"
                    },
                })    
                clearTimeout(this.myTimeout);
                this.myTimeout = setTimeout(() => {
                  this.hideAlertModalComponent();
                }, 3000); 
            }
            this.props.element.patientObj.address.postalCode = event.value;
            this.props.element.patientObj.address.city = event.city;
            this.setState(prevState => {
                return {
                    ...prevState,
                    element: {
                        ...prevState.element,
                        patientObj: {
                            ...prevState.element.client,
                            address: {
                                ...prevState.element.client.address,
                                postalCode: event.value,
                                city: event.city,
                            }
                        }
                    }
                }
            });
        }else{
            this.props.element.patientObj.address.postalCode = event;
            this.setState(prevState => {
                return {
                    ...prevState,
                    element: {
                        ...prevState.element,
                        patientObj: {
                            ...prevState.element.client,
                            address: {
                                ...prevState.element.client.address,
                                postalCode: event,
                            }
                        }
                    }
                }
            });
        }
        this.props.editedData();
    }

    handleChangeCity(event){
        if(this.props.appLang === LangSelect.EN){
            this.props.element.patientObj.address.city = event;
            this.setState(prevState => {
                return {
                    ...prevState,
                    element: {
                        ...prevState.element,
                        patientObj: {
                            ...prevState.element.patientObj,
                            address: {
                                ...prevState.element.patientObj.address,
                                city: event
                            }
                        }
                    }
                }
            });
            this.props.editedData();
        }
    }

    handleChangeValueKey = (key, value) =>{
        this.props.element.patientObj[key] = value;
        this.setState(prevState => {
          let elem = {...prevState.element.patientObj}
          elem[key] = value;
          return {
            ...prevState,
            element: {
                ...prevState.element,
                patientObj: elem
            }
        }
        });
        this.props.editedData();
    }

    handleChangeValueKeyNotArrow(key, value){
        this.props.element.patientObj[key] = value;
        this.setState(prevState => {
          let elem = {...prevState.element.patientObj}
          elem[key] = value;
          return {
            ...prevState,
            element: {
                ...prevState.element,
                patientObj: elem
            }
        }
        });
        this.props.editedData();
    }

    handleChangeValueAddressKeyNotArrow(key, value){
        this.props.element.patientObj.address[key] = value;
        this.setState(prevState => {
          let elem = {...prevState.element.patientObj.address}
          elem[key] = value;
          return {
            ...prevState,
            element: {
                ...prevState.element,
                patientObj: {
                    ...prevState.element.patientObj,
                    address: elem
                }
            }
        }
        });
        this.props.editedData();
    }

    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    prepareMeSelect = () =>{
        if(this.props.cities.length>0){
            return this.props.cities.map(x => ({
                value: x.PostalCode!==null?x.PostalCode:'', 
                city:  x.City!==null?x.City:'',
                label:  x.City!==null?x.City:''
            }));
        }else{
            return [];
        }
    }

    onCopyData = () =>{
        const { element } = this.props;

        //first column
        element.patientObj.firstName = element.client.firstName;
        element.patientObj.lastName = element.client.lastName;
        element.patientObj.fullname = `${element.client.lastName} ${element.client.firstName}`;
        element.patient = `${element.client.lastName} ${element.client.firstName}`;
        element.patientObj.cellPhone = element.client.cellPhone;
        element.patientObj.phone = element.client.phone;
        element.patientObj.email = element.client.email;

        //second column
        element.patientObj.address.street = element.client.address.street;
        element.patientObj.address.buildingNumber = element.client.address.buildingNumber;
        element.patientObj.address.apartmentNumber = element.client.address.apartmentNumber;
        element.patientObj.address.postalCode = element.client.address.postalCode;
        element.patientObj.address.city = element.client.address.city;

        let errorTemp = this.state.errorComponent;
        let clearInfo = false;
        if(!this.checkIfAssignToRegion(element.client.address.postalCode)){
            errorTemp = {
                show: true,
                type: "info",
                message: "franchise_region_for_other_FP_message"
            }
            clearInfo = true;
        }

        this.setState(prevState => {
            return {
                ...prevState,
                errorComponent: errorTemp,
                element: {
                    ...prevState.element,
                    patient: `${element.client.lastName} ${element.client.firstName}`,
                    patientObj: {
                        ...prevState.element.patientObj,
                        firstName: element.client.firstName,
                        lastName: element.client.lastName,
                        fullname: element.client.fullname,
                        cellPhone: element.client.cellPhone,
                        phone: element.client.phone,
                        email: element.client.email,
                        address:{
                            ...prevState.element.patientObj.address,
                            street: element.client.address.street,
                            buildingNumber: element.client.address.buildingNumber,
                            apartmentNumber: element.client.address.apartmentNumber,
                            postalCode: element.client.address.postalCode,
                            city: element.client.address.city
                        }
                    }
                }
            }
        });
        if(clearInfo){
            clearTimeout(this.myTimeout);
            this.myTimeout = setTimeout(() => {
              this.hideAlertModalComponent();
            }, 3000); 
        }
        this.props.editedData();
    }

    hideAlertModalComponent(){
        if (this._isMounted) {
            this.setState({errorComponent: {show: false}})
        }
        document.body.classList.remove('modal-open');
      }

    checkIfAssignToRegion = (codeParam) =>{
        if(this.props.regionsFP.length!==0){
            let todayTimestamp = Math.floor(new Date().getTime() / 1000);
            let dateFilter = this.props.regionsFP.filter(element => {
                if(todayTimestamp >= element.RegionInclusionDate){
                    if(element.RegionExclusionDate!==null && element.RegionExclusionDate!==0){
                        if(todayTimestamp <= element.RegionExclusionDate){
                            return true
                        }else{
                            return false
                        }
                    }else{
                        return true
                    }
                }else{
                    return false
                }
            });
            let isZipCodeTheSame = dateFilter.filter(element => {
                if(element.RegionAssignedToUser === true){
                    if(element.RegionPostalCodes!==null){
                        let splitArray = element.RegionPostalCodes.split(",");
                        splitArray = splitArray.filter(code => {
                            return code.trim() === codeParam
                        })
                        if(splitArray.length>0){
                            return true;
                        }else{
                            return false;
                        }
                    }else{
                        return false;
                    }
                }else{
                    return false
                }
            });
            if(isZipCodeTheSame.length>0){
                return true;
            }else{
                return false
            }
        }else{
            return false;
        }
    }

    render() {
        const preparedSelectGroup = this.prepareMeSelect();
        const { translateKey, element, showValidation, disabled, appLang} = this.props;
        const { errorComponent } = this.state;
        let patientData;
        if(this.state.isOpen){
            patientData = (
                <div className="lead-details-client-info lead-details-patient-info">
                    <div className="col-sm-12 col-lg-5">
                        <fieldset disabled={disabled}>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("last_name")}<span className="red-star-required-field"> *</span></label>
                                <div className="col-sm-8">
                                    <input type="text" className={"form-control lead-input-wrapper "+(!ReqHelper.checkNotNullAndNotEmptyString(element.patientObj.lastName) && showValidation?"is-invalid": '')} onChange={e => this.handleChangeLastName(e.target.value)} value={element.patientObj.lastName?element.patientObj.lastName:''}/>
                                    <div className="invalid-feedback">{this.props.translateKey("form_contains_errors")}</div>
                                </div>
                            </div>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("first_name")}<span className="red-star-required-field"> *</span></label>
                                <div className="col-sm-8">
                                    <input type="text" className={"form-control lead-input-wrapper "+(!ReqHelper.checkNotNullAndNotEmptyString(element.patientObj.firstName) && showValidation?"is-invalid": '')} onChange={e => this.handleChangeFirstName(e.target.value)} value={element.patientObj.firstName?element.patientObj.firstName:''}/>
                                    <div className="invalid-feedback">{this.props.translateKey("form_contains_errors")}</div>
                                </div>
                            </div>
                            <InputTypeComponent appLang={appLang} disabled={false} keyName={"cellPhone"} value={element.patientObj.cellPhone} type={FieldTypes.PHONE_NUMBER} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("mobile_phone")}/>
                            <InputTypeComponent appLang={appLang} disabled={false} keyName={"phone"} value={element.patientObj.phone} type={FieldTypes.PHONE_NUMBER} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("home_phone")}/>
                            <InputTypeComponent disabled={false} keyName={"email"} value={element.patientObj.email} type={FieldTypes.EMAIL} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("email")}/>
                        </fieldset>
                    </div>
                    <div className="col-sm-12 col-lg-5">
                        <fieldset disabled={disabled}>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("street")}</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("street", e.target.value)} value={element.patientObj.address.street?element.patientObj.address.street:''}/>
                                </div>
                            </div>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("building_number")}</label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("buildingNumber", e.target.value)} value={element.patientObj.address.buildingNumber?element.patientObj.address.buildingNumber:''}/>
                                </div>
                            </div>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("apartment_number")}</label>
                                <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("apartmentNumber", e.target.value)} value={element.patientObj.address.apartmentNumber?element.patientObj.address.apartmentNumber:''}/>
                                </div>
                            </div>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("postal_code")}<span className="red-star-required-field"> *</span></label>
                                <div className="col-sm-8 plz-required">
                                    {!disabled?
                                        preparedSelectGroup.length>0 && 
                                        <>
                                            <div className={(!ReqHelper.checkNotNullAndNotEmptyString(element.patientObj.address.postalCode) && showValidation?"is-invalid": '')}>
                                                <AutoSuggestComponent onChange={this.handlechangePostalCode} value={element.patientObj.address.postalCode} suggestions={preparedSelectGroup}/>
                                            </div>
                                            <div className="invalid-feedback">{this.props.translateKey("form_contains_errors")}</div>
                                        </>
                                    :
                                        <>
                                            <input type="text" className={"form-control lead-input-wrapper"+(!ReqHelper.checkNotNullAndNotEmptyString(element.patientObj.address.postalCode) && showValidation?"is-invalid": '')}  value={element.patientObj.address.postalCode} readOnly/>
                                            <div className="invalid-feedback">{this.props.translateKey("form_contains_errors")}</div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("city")}</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control lead-input-wrapper" disabled={this.props.appLang === LangSelect.DE} onChange={e => this.handleChangeCity(e.target.value)} value={element.patientObj.address.city?element.patientObj.address.city:''}/>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className="col-sm-2 text-right lead-route-container">
                        <RouteButtonComponent disabled={disabled} element={element.patientObj.address} text={translateKey("route")}/>
                    </div>
                </div>
            )
        }
        return (
            <div className="lead-client-row">
                <div className="lead-details-header">
                    <div className="filter-modal-option-text">{translateKey("beneficiary")}</div>
                    <div className="copy-patient-wrapper">
                        <div className="filter-modal-option-text btn btn-primary special-copy-button" onClick={()=> this.onCopyData()}>
                            {translateKey("copy_patient")}
                        </div>
                        <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                            {this.state.isOpen?translateKey("show_less"):translateKey("show_more")}
                        </div>
                        <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={translateKey(errorComponent.message)}/>
                    </div>
                </div>
                {patientData}
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    appLang: state.lang.appLanguage,
    cities: state.enums.citiesList,
    citiesStatus: state.enums.citiesListStatus,
}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadPatientComponent));