import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import Validate from "../../_helpers/validation";
import {TableEnums} from "../../_constance/enums";
import { FilterDateComponent } from "../index";
import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import { ReqHelper } from "../../_helpers/index";
import * as FilterType from "../../_constance/enums/filterType";
import TableSortingComponent from './TableSortingComponent'


class TableProperties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options,
            searchQuery:{
                placehoder: this.props.translateKey("search_bar_title"),
                value:'',
                valid:false,
                validationRules: {
                  maxLength: 250
                }
            },
            sortData: props.sortData?JSON.parse(JSON.stringify(props.sortData)):[],
            filerData: props.filterData,
            showFilters: false,
            gotFilter: false,
            columnSelect: false
        };
        this.handleQueryChange = this.handleQueryChange.bind(this);
    }

    handleQueryChange(e){
        const { value } = e.target;
        this.setState(prevState => {
            return {
                ...prevState,
                searchQuery: {
                    ...prevState.searchQuery,
                    value: value,
                    valid: Validate(value, prevState.searchQuery.validationRules)
                }
            }
        });
        if(Validate(value, this.state.searchQuery.validationRules)){
            this.props.onSearch(value);
        }
    };

    clearSearchInput = () =>{
        this.setState(prevState => {
            return {
                ...prevState,
                searchQuery: {
                    ...prevState.searchQuery,
                    value: '',
                    valid: Validate('', prevState.searchQuery.validationRules)
                }
            }
        });
        this.props.onSearch('');
    }

    updateElement(items, element){
        element.active===true? element.active=false : element.active=true;
        this.setState({showFilters: true })
    }

    clearThisOptions(){
        this.props.filterData.map((element)=>{
            return element.filterOptions.map((filterOption)=>{
                if(element.filterType){
                    if(element.filterType === FilterType.FROMTO){
                        filterOption.date = 0;
                    }
                }
                return filterOption.active = false
            })
        })
        this.setState({
            sortData:  JSON.parse(JSON.stringify(this.props.sortData)),
            showFilters: false,
            gotFilter: false
        })
        this.props.onShowMeData();
    }

    
    showData(){
        this.props.prepareColumnsData(this.state.sortData);
        let filters = ReqHelper.getMeFilersObj(this.props.filterData);
        if(filters.length>0){
            this.setState({
                gotFilter: true,
                showFilters: false
            })
        }else{
            this.setState({
                gotFilter: false,
                showFilters: false
            })
        }
        this.props.onShowMeData();
    }

    changeDateElement(event, element){
        if(element.active!==true){
            element.active=true
        }
        element.date = event;
        this.setState({showFilters: true})
    }

    showColumns(){
        this.props.prepareColumnsData(this.state.sortData);
        this.setState({columnSelect: false})
        this.props.onShowMeData();
    }

    filtersToggle = () => {
        if(this.state.showFilters){
            //document.body.classList.remove('modal-open');
        }
        this.setState({
            showFilters: !this.state.showFilters
        });
    }

    openFilters(){
        this.setState({
            showFilters: true,
            sortData:  JSON.parse(JSON.stringify(this.props.sortData)),
        })
        //document.body.classList.add('modal-open');
    }

    columnsToggle = () => {
        if(this.state.showFilters){
            //document.body.classList.remove('modal-open');
        }
        this.setState({
            columnSelect: !this.state.columnSelect
        });
    }

    openColumns(){
        this.setState({
            sortData: this.props.sortData?JSON.parse(JSON.stringify(this.props.sortData)):[],
            columnSelect: true
        })
        //document.body.classList.add('modal-open');
    }

    render() {
        const { options, translateKey, hideBar, className, showCategoryMessage = false } = this.props;
        let content = null;
        if(options){
            if(options.length>0){
                let searchInput = null
                if(options.indexOf(TableEnums.SEARCH)>-1){
                    searchInput = (
                        <div className="input-group search-table-wrapper">
                            <div className="input-group-prepend"><i className="fas fa-search norbsoft-filter-icon"></i></div>
                            <input id="search-query-table" type="text" className="form-control norbsoft-search-input"
                                placeholder={this.state.searchQuery.placehoder}
                                name="searchQuery"
                                value={this.state.searchQuery.value}
                                onChange={this.handleQueryChange}
                                required
                            />
                            {this.state.searchQuery.value.toString().length>0 &&
                                <i className="fas fa-times-circle norbsoft-filter-icon" onClick={this.clearSearchInput}></i>
                            }
                        </div>
                    );
                }
                let filterButton = null;
                let filterModal = null;
                if(options.indexOf(TableEnums.FILTER)>-1){
                    filterModal = (
                        <MDBModal isOpen={this.state.showFilters} toggle={this.filtersToggle} fullHeight size="lg">
                            <MDBModalHeader toggle={this.filtersToggle}></MDBModalHeader>
                            <MDBModalBody className="filter-modal-body">
                                {
                                    this.props.filterData.map((item,i)=>{
                                        return <div className="filer-category-wrapper" key={i}>
                                            <div className="filter-modal-option">
                                                <div className="filter-modal-option-text">{translateKey("filter_by") + ' '+translateKey(item.filterName)}</div>
                                            </div>
                                            <div className="filter-modal-option-container">
                                                {
                                                    item.filterOptions?
                                                    item.filterOptions.map((element,iter)=>{
                                                        if(element.type==="dateFrom"){
                                                            return <FilterDateComponent onChangeDate={(e)=> this.changeDateElement(e, element)} key={iter} name={element.column} timestamp={element.date}/>
                                                        }else{
                                                            if(element.type==="dateTo"){
                                                                return <FilterDateComponent onChangeDate={(e)=> this.changeDateElement(e, element)} key={iter} name={element.column} timestamp={element.date}/>
                                                            }else{
                                                                return <button key={iter} type="button" onClick={()=>this.updateElement(item.filterOptions, element)} className={"btn btn-light btn-sm option" + (element.active===true? ' active-filter': '')}>{element.column}</button>
                                                            }
                                                        }
                                                    })
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </MDBModalBody>
                            <div className="filteredFloatingFooterButtonClear">
                                <button type="button" onClick={()=>this.clearThisOptions()} className="btn btn-light show-results-filter-button btn-sm" data-dismiss="modal">{translateKey('clear')}</button>
                            </div>
                            <div className="filteredFloatingFooterButtons">
                                <button type="button" onClick={()=>this.showData()} className="btn btn-primary show-results-filter-button btn-sm" data-dismiss="modal">{translateKey('show_results')}</button>
                            </div>
                        </MDBModal>
                    )
                    filterButton = (
                        <button type="button" className={"btn norbsoft-filter-button" + (ReqHelper.getMeFilersObj(this.props.filterData).length>0? ' btn-primary': ' btn-light')} onClick={()=>{this.openFilters()} }><i className="fas fa-align-left norbsoft-filter-icon"></i>{translateKey("filter_by")}</button>
                    )
                }
                let columnsSelect = null;
                let columnsButton = null;
                if(options.indexOf(TableEnums.ACTIVECOLUMNS)>-1){
                    columnsSelect = (
                        <MDBModal isOpen={this.state.columnSelect} toggle={this.columnsToggle} fullHeight size="lg">
                            <MDBModalHeader toggle={this.columnsToggle}></MDBModalHeader>
                            <MDBModalBody className="filter-modal-body">
                                <TableSortingComponent sortData={this.state.sortData}/>
                            </MDBModalBody>
                            <div className="filteredFloatingFooterButtonClear">
                                <button type="button" onClick={this.columnsToggle} className="btn btn-light show-results-filter-button btn-sm" data-dismiss="modal">{translateKey('cancel')}</button>
                            </div>
                            <div className="filteredFloatingFooterButtons">
                                <button type="button" onClick={()=>this.showColumns()} className="btn btn-primary show-results-filter-button btn-sm" data-dismiss="modal">{translateKey('save')}</button>
                            </div>
                        </MDBModal>
                    );     
                    columnsButton = (
                        <button type="button" className="btn norbsoft-filter-button btn-light" onClick={()=>{this.openColumns()}}><i className="fas fa-columns norbsoft-filter-icon"></i>{translateKey("column")}</button>
                    )
                    
                }
                let addNewButton = null;
                if(options.indexOf(TableEnums.ADD)>-1){
                    addNewButton = (
                        <button type="button" onClick={()=>this.props.addClick()} className="btn btn-success norbsoft-filter-button">
                            <i className="fas fa-plus norbsoft-filter-icon"></i>
                            {translateKey("add_new")}
                        </button>
                    );        
                }

                let sendKopiloten = null;
                if(options.indexOf(TableEnums.SENDKOPILOTEN)>-1){
                    sendKopiloten = (
                        <button type="button" onClick={()=>this.props.addClick()} className="btn btn-success norbsoft-meetings-button">
                            <i className="far fa-file-pdf norbsoft-filter-icon"></i>
                            {translateKey("ko-piloten-sende-liste")}
                        </button>
                    );        
                }

                let multiCategories = null;
                if(options.indexOf(TableEnums.MILTICATEGORIES)>-1){
                    multiCategories = (
                        <button type="button" className={`btn norbsoft-filter-button btn-light btn-categories ${this.props.activeCategoryButton ? 'btn-categories-active' : ''}`} onClick={()=>{this.props.showFilter()} }>
                            <i className="fas fa-align-left norbsoft-filter-icon"></i>
                            {translateKey("filter-by-categories")}
                        </button>
                    );        
                }

                let addMeeting = null;
                if(options.indexOf(TableEnums.ADDMEETING)>-1){
                    addMeeting = (
                        <button type="button" onClick={()=>this.props.addClick()} className="btn btn-success norbsoft-meetings-button">
                            <i className="far fa-calendar-alt norbsoft-filter-icon"></i>
                            {translateKey("arrange-meeting-text")}
                        </button>
                    );        
                }

                let editButton = null;
                if(options.indexOf(TableEnums.EDIT)>-1){
                    editButton = (
                        <button type="button" className="btn btn-info norbsoft-filter-button"><i className="fas fa-edit norbsoft-filter-icon"></i>{translateKey("edit")}</button>
                    );
                }
                let saveButton = null;
                if(options.indexOf(TableEnums.SAVE)>-1){
                    saveButton = (
                        <button type="button" className="btn btn-success norbsoft-filter-button"><i className="fas fa-save norbsoft-filter-icon"></i>{translateKey("save")}</button>
                    );
                }
                let createContract = null;
                if(options.indexOf(TableEnums.CREATECONTRACT)>-1){
                    createContract = (
                        <button type="button" onClick={()=>this.props.showMeContract(true)} className="btn btn-success add-form-bb-button"><i className="fas fa-plus norbsoft-filter-icon"></i>{translateKey("create_a_contract")}</button>
                    );
                }
                let createQuotation = null;
                if(options.indexOf(TableEnums.CREATEQUATATION)>-1){
                    createQuotation = (
                        <button type="button" onClick={()=>this.props.showMeQuatation(true)} className="btn btn-success add-form-bb-button"><i className="fas fa-plus norbsoft-filter-icon"></i>{translateKey("contract_status_during_the_creation")}</button>
                    );
                }
                content =  
                    <React.Fragment>
                        {searchInput}
                        {columnsSelect}
                        {columnsButton}
                        {filterButton}
                        {multiCategories}
                        {addNewButton}
                        {addMeeting}
                        {editButton}
                        {saveButton}
                        {filterModal}
                        {createContract}
                        {createQuotation}
                        {sendKopiloten}
                    </React.Fragment>
            }
        }
        return (
            <div className={`tableFilterWrapper ${hideBar?content===null?'emptyWrap':'':''} ${className?className:''}`}>
                <div className={`tableFilter ${hideBar?content===null?'hideBar':'':''}`}>
                        {content}
                </div>
                {ReqHelper.getMeFilersObj(this.props.filterData).length>0 &&
                    <div className="tableFilter filter-message-holder">
                        <p className="filter-message">
                            {translateKey("filter-message")}
                        </p>
                    </div>
                }
                {showCategoryMessage &&
                    <div className="tableFilter filter-message-holder">
                        <p className="filter-message">
                            {translateKey("filter-message-categories")}
                        </p>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {return {login: state.auth.appLogin}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableProperties));