import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import  ListClothesComponent  from '../../form-bb-components/list-clothes-component/list-clothes-component';
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import { IconsMap } from '../../../_helpers';
import produce from "immer";

class Section12 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-12'),
            showValidation: props.showValidationErrors,
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    render() {
        const {
          disabled,
          sectionNumber,
          subsectionName,
          showValidationErrors,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const is_dressing_upper_part_of_body = section.getFieldByKey('is_dressing_upper_part_of_body');
        const is_dressing_pants_skirt = section.getFieldByKey('is_dressing_pants_skirt');
        const is_dressing_underwear = section.getFieldByKey('is_dressing_underwear');
        const is_dressing_shoes = section.getFieldByKey('is_dressing_shoes');

        const is_undress_upper_part_of_body = section.getFieldByKey('is_undress_upper_part_of_body');
        const is_undress_underwear = section.getFieldByKey('is_undress_underwear');
        const is_undress_pants_skirt = section.getFieldByKey('is_undress_pants_skirt');
        const is_undress_shoes = section.getFieldByKey('is_undress_shoes');

        const listClassName = "col-sm-12 col-lg-3 form-margin-bottom-fix";
        const directionType ="column";
        const isNewUI = true; 
        
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListClothesComponent newUI={isNewUI} type={directionType} svgIcon icon={IconsMap.svg.sichKleiden.ankleidenOberteil}  showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={listClassName} transKey="form-text-ankleiden-oberteil" key="is_dressing_upper_part_of_body" keyName="is_dressing_upper_part_of_body" field={is_dressing_upper_part_of_body} value={is_dressing_upper_part_of_body.value} validationErrors={is_dressing_upper_part_of_body.validationErrors}/>
                                <ListClothesComponent newUI={isNewUI} type={directionType} svgIcon icon={IconsMap.svg.sichKleiden.ankleidenUnterhose}  showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={listClassName} transKey="form-text-ankleiden-hose" key="is_dressing_pants_skirt" keyName="is_dressing_pants_skirt" field={is_dressing_pants_skirt} value={is_dressing_pants_skirt.value} validationErrors={is_dressing_pants_skirt.validationErrors}/>
                                <ListClothesComponent newUI={isNewUI} type={directionType} svgIcon icon={IconsMap.svg.sichKleiden.ankleidenHose}  showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={listClassName} transKey="form-text-ankleiden-unterhose" key="is_dressing_underwear" keyName="is_dressing_underwear" field={is_dressing_underwear} value={is_dressing_underwear.value} validationErrors={is_dressing_underwear.validationErrors}/>
                                <ListClothesComponent newUI={isNewUI} type={directionType} svgIcon icon={IconsMap.svg.sichKleiden.ankleidenSchuhe}  showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={listClassName} transKey="form-text-ankleiden-schuhe" key="is_dressing_shoes" keyName="is_dressing_shoes" field={is_dressing_shoes} value={is_dressing_shoes.value} validationErrors={is_dressing_shoes.validationErrors}/>
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListClothesComponent newUI={isNewUI} type={directionType} svgIcon icon={IconsMap.svg.sichKleiden.auskleidenOberteil}  showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={listClassName} transKey="form-text-auskleiden-oberteil" key="is_undress_upper_part_of_body" keyName="is_undress_upper_part_of_body" field={is_undress_upper_part_of_body} value={is_undress_upper_part_of_body.value} validationErrors={is_undress_upper_part_of_body.validationErrors}/>
                                <ListClothesComponent newUI={isNewUI} type={directionType} svgIcon icon={IconsMap.svg.sichKleiden.auskleidenUnterhose}  showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={listClassName} transKey="form-text-auskleiden-hose" key="is_undress_pants_skirt" keyName="is_undress_pants_skirt" field={is_undress_pants_skirt} value={is_undress_pants_skirt.value} validationErrors={is_undress_pants_skirt.validationErrors}/>
                                <ListClothesComponent newUI={isNewUI} type={directionType} svgIcon icon={IconsMap.svg.sichKleiden.auskleidenHose}  showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={listClassName} transKey="form-text-auskleiden-unterhose" key="is_undress_underwear" keyName="is_undress_underwear" field={is_undress_underwear} value={is_undress_underwear.value} validationErrors={is_undress_underwear.validationErrors}/>
                                <ListClothesComponent newUI={isNewUI} type={directionType} svgIcon icon={IconsMap.svg.sichKleiden.auskleidenSchuhe}  showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={listClassName} transKey="form-text-auskleiden-schuhe" key="is_undress_shoes" keyName="is_undress_shoes" field={is_undress_shoes} value={is_undress_shoes.value} validationErrors={is_undress_shoes.validationErrors}/>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section12));