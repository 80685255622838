import * as React from "react";
import {connect} from "react-redux";
import {Prompt, withRouter} from "react-router-dom";
import {
    authSetActionTimestamp,
    getLeadStatusData,
    getPatientLead,
    pendingLeadsData,
    removeLeadsData,
    removeLeadStatusData,
    setCareBoxIds,
    setCareBoxIndividualPrices,
    setCareBoxSets,
    setCareBoxSetsList,
    setGloveType,
    setLeadsData,
    translateKey
} from "../../_redux/actions/index";
import {
    AlertModalInfo,
    InfoModal,
    LoaderModal,
    LoaderTable,
    NavBar,
    SideBar,
    TableProperties
} from "../../_components/index";
import {shouldRefresh} from "../../_helpers/index";
import {SectionCareBox} from "../../_components/form-bb-sections/index";
import SectionNavigationToolbarComponent
    from "../../_components/form-bb-components/section-navigation-toolbar/section-navigation-toolbar";
import axiosAuth from "../../_services/config/axios-auth";
import {baseData, refreshTokenTime} from '../../_constance/base_data';
import {getRegionsFP, getUserProducts} from "../../_redux/actions/bb-form-services";
import BBFormService from '../../_services/bbform';
import * as Entity from '../../_forms/BBForm/values/entity';
import * as Relationship from '../../_forms/BBForm/values/relationship';
import * as FormTypeToShow from '../../_forms/BBForm/values/formTypeToShow';
import * as IsContactPersonDataSameAs from '../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as Sections from '../../_forms/BBForm/values/sections';
import {Alert, FormLoadedState, FormNavigationActions} from "../../_constance/classes/index";
import CareBoxForm from '../../_forms/CareBoxForm/IngredientsCareBoxForm';
import {FormConfig} from '../../_forms/CareBoxForm/ingredientsFormConfig';
import {ResponseStatus} from '../../_helpers/response_status';
import * as RecordType from "../../_constance/values/recordType";
import {OFFER_SECTION, SECTION_OUTSIDE_MENU} from "../../_constance/enums/summaryOfferMenuSections";
import {getSectionNameByNumber, getSectionNumberByName} from "../../_constance/enums/sectionPageListCareBox";
import {toDuplicatIngredients} from "../../_forms/BBForm/values/careBoxIngredientsDuplicated";
import {getCareBoxSets, getDynamicFields, getPicklist} from '../../_services/user_service';

const LAST_SECTION_KEY = 'is_last_section';
const SKIP_SAVE_IS_DIET_DIABETES = 'skip_save_is_diet_diabetes';
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles';

class ingredientsCareBoxWrapperClient extends React.Component {
    _isMounted = false;
    myTimout;

    constructor(props) {
        super(props);
        this.state = {
            clientLeadBaseData: null,
            editedForm: false,
            currentSection: 0,
            modalLoader: true,
            savingData: false,
            errorsMessage: null,
            errorsType: "danger",
            errorComponent: Alert,
            LKId: 0,
            patientId: 0,
            products: [],
            regionsFP: [],
            isServiceType: '0',
            hasDraftCareBox: false,
            type: FormTypeToShow.FORM,
            toPage: null,
            isImportant: false,
            hideBar: false,
            breadCrumbs: [
                {
                    link: "clients",
                    key: "clients",
                    name: null
                }
            ],
            showValidationErrors: false,
            form: new CareBoxForm(),
            rerender: Date.now(),
            newOne: false,
            leadPatients: [],
            wholeResponse: null,
            sectionMenuOpen: false,
            analizeFinished: true,
            prevPageNumber: 0,
            rodoDisabled: false,
            firstInit: true,
            // final offer variables
            activeSection: SECTION_OUTSIDE_MENU,

            isOfferLoaded: false,
            isOfferOpened: false,

            hideMeAll: false,

            secondPatientForm: new CareBoxForm(),
            secondPatientId: null,
            multiplePatients: false,
            otherPatient: [],
            showMultiPatientModal: false,
            isAditionalPatient: false,
            wasCreateNewPatient: false,
            showModal: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const {match: {params}} = this.props;
        let type = params.type;
        if (type !== FormTypeToShow.CONTRACT && type !== FormTypeToShow.ENQUIRY) {
            type = FormTypeToShow.FORM
        }
        this.setState({
            LKId: params.id,
            patientId: params.patientId,
            type: type,
            hideBar: true,
            hideMeAll: true,
            modalLoader: true
        })
        document.body.classList.remove('modal-open');
        window.scrollTo(0, 0);
        this.myTimeout = setTimeout(() => {
            this.getInitialDataTime();
        }, 200);
        this.getDataForPflegbox();
    }

    componentDidUpdate() {
        this._promptUnsavedChange(this.state.editedForm)
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };

    toggleFirstInit = () => {
        this.setState({
            firstInit: false
        });
    }

    changeAditionalPateint = (value) => {
        this.setState({
            isAditionalPatient: value,
        });
    }

    toggleFullScreen = () => {
        const {hideMeAll} = this.state;
        if (hideMeAll) {
            this.setState({hideMeAll: false})
            if (document.exitFullscreen && document.fullscreenElement) {
                document.exitFullscreen();
            }
        } else {
            this.setState({hideMeAll: true})
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            }
        }
    }

    _promptUnsavedChange(isUnsaved = false) {
        window.onbeforeunload = isUnsaved;
    }

    scrollToErrorOrImportantAlert = () => {
        const section = this.state.form.getSectionByOrder(this.state.currentSection);
        setTimeout(() => {
            const scrollToField = section.fields.find(element => !element.isValid);
            if (scrollToField) {
                const elmnt = document.getElementById(`${scrollToField.key}_error_id`);
                if (elmnt) {
                    elmnt.parentNode.scrollIntoView({behavior: 'smooth'});
                }
            } else {
                const importantMessageList = document.getElementsByClassName('important-message-class');
                if (importantMessageList.length > 0) {
                    importantMessageList[0].parentNode.scrollIntoView({behavior: 'smooth'});
                }
            }
        }, 50);
    }

    navigationChangeState = (navObj = new FormNavigationActions()) => {
        if (this._isMounted) {
            this.setState({
                currentSection: navObj.currentSection,
                savingData: navObj.savingData,
                showValidationErrors: navObj.showValidationErrors,
                isImportant: navObj.isImportant,
                prevPageNumber: navObj.prevPageNumber
            })
            if (navObj.showValidationErrors === true || navObj.isImportant === true) {
                this.scrollToErrorOrImportantAlert();
            }
        }
        window.scrollTo(0, 0);
    }

    rerender = newPatient => {
        const {secondPatientId} = this.state;

        this.myTimeout = setTimeout(() => {
            this.getInitialDataTime();
        }, 200);

        this.prepareMeForm();

        this.setState({
            modalLoader: true,
            patientId: secondPatientId,
            multiplePatients: true,
            wasCreateNewPatient: newPatient,
        });
    }

    changePage = async number => {
    };

    previousPageClick = async () => {
    };

    openMultiplePatientsModal = () => {
        const {match: {params}} = this.props;

        let isFormsCompleated = false;
        const patientsIDs = this.getMeInitLeadPatientsList();

        if (patientsIDs.length < 2) {
            this.setState({
                showMultiPatientModal: true,
            });
        } else {
            BBFormService.retrievePatient(this.state.form.getRetiveDataALl(params.id, patientsIDs)).then(response => {
                const {data} = response;
                const isFormsFill = data.map(element => {
                    if (element.entity === 'is_patient') {
                        const pageNumber = getSectionNumberByName(element.fields.is_last_section);
                        if (pageNumber >= 4) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                }).every(item => item);
                isFormsCompleated = isFormsFill;
            }).then(() => {
                if (isFormsCompleated) {
                    this.nextPageClick();
                } else {
                    this.setState({
                        showMultiPatientModal: true,
                    });
                }
            })
        }
    }

    acceptMultiplePatients = async (firstName, lastName) => {
        const {match: {params}, history} = this.props;
        const dataToSend = [{
            "Id": "0",
            "Entity": "is_patient",
            "Fields": {
                "is_contact_id": params.id,
                "is_last_section": "section-care-box-rodo",
                "is_first_name_and_last_name_of_patient": `${firstName} ${lastName}`
            }
        },
            {
                "Id": params.id,
                "Entity": "contact",
                "Fields": {
                    "is_patient_last_name": lastName,
                    "is_patient_first_name": firstName,
                }
            }];

        try {
            const response = await BBFormService.savePatient(dataToSend);
            let lkid = params.id ? params.id : 0;
            let patientid = params.patientId ? params.patientId : 0;
            const {data} = response;
            data.forEach(element => {
                if (element.entity === Entity.PATIENT) {
                    patientid = element.id;
                } else {
                    if (element.entity === Entity.CONTACT) {
                        lkid = element.id;
                    }
                }
            });
            history.push(`${baseData.subFolders}bb-form-lead/${lkid}/${patientid}`);
            history.go(0);
        } catch (e) {
            console.log(e);
        }
    }

    redirectToSecondPatient = (patientId) => {
        const {match: {params}, history} = this.props;

        this.setState({
            showMultiPatientModal: false,
            multiplePatients: true,
        });

        history.push(`${baseData.subFolders}bb-form-lead/${params.id}/${patientId}`);
        history.go(0);
    }

    cancelMultiplePatients = () => {
        this.setState({
            showMultiPatientModal: false,
        });

        this.nextPageClick();
    }

    finishFormClick = async () => {
    }

    closeModalClick = () => {
        const {history} = this.props;
        history.push(`${baseData.subFolders}clientDetails/${this.state.LKId}`);
        return true
    }

    nextPageClick = async () => {
    };

    disableSupportFields = () => {
        const saveDiabetesField = this.state.form.getFieldByKey(SKIP_SAVE_IS_DIET_DIABETES);
        if (saveDiabetesField) {
            saveDiabetesField.value = false
        }
        const saveDiabetesTileField = this.state.form.getFieldByKey(SKIP_SAVE_STRING_TILES);
        if (saveDiabetesTileField) {
            saveDiabetesTileField.value = false
        }
    }

    giveMeSpecialToGoSection() {
        const {currentSection, form} = this.state;
        const section = form.getSectionByOrder(currentSection);
        let toPageNumber = section.prevSection;
        if (toPageNumber === Sections.PATIENT) {
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
            if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
                const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
                if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
                    toPageNumber = Sections.CLIENT
                } else {
                    toPageNumber = Sections.CONTACT_PERSON
                }
            }
        } else {
            if (toPageNumber === Sections.CONTACT_PERSON) {
                const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
                if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
                    toPageNumber = Sections.CLIENT
                }
            }
        }
        return toPageNumber;
    }

    clearAlertComponent = (withTimeOut = false) => {
        let timeOutTime = 0;
        if (withTimeOut) {
            timeOutTime = 2000;
        }
        setTimeout(() => {
            if (this._isMounted) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        errorComponent: {
                            ...prevState.errorComponent,
                            show: false,
                            type: "danger",
                            message: null
                        }
                    }
                })
            }
        }, timeOutTime);
    }

    showAlertMessage = (type, message) => {
        if (this._isMounted) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    errorComponent: {
                        ...prevState.errorComponent,
                        show: true,
                        type: type,
                        message: message
                    }
                }
            })
        }
        this.clearAlertComponent(true);
    }

    getDataForPflegbox = () => {
        getCareBoxSets()
            .then(async res => {
                this.props.setCareBoxSetsList(res);
                const definedBoxesId = res.filter(item => item !== baseData.individualCareBoxId);
                const careBoxSets = await Promise.all(definedBoxesId.map(async item => {
                    let dataToSend = [
                        {
                            Id: item.Id,
                            Entity: 'is_care_box_set',
                            Fields: [
                                "is_protective_aprons_number",
                                "is_bed_protectors_number",
                                "is_disposable_masks_number",
                                "is_disposable_ffp2_masks_number",
                                "is_disposable_gloves_number",
                                "is_surface_disinfectant_number",
                                "is_hand_sanitizer_number",
                                "is_reusable_bed_protectors_number",
                                "is_disposable_finger_overlay_number",
                                "is_surface_disinfectant_1000_number",
                                "is_hand_sanitizer_1000_number",
                                "is_reusable_protective_aprons_number",
                                "is_disinfecting_wipes_number",
                                "is_disposable_masks_ffp2_set_number"
                            ]
                        }
                    ];
                    return await getDynamicFields(dataToSend)
                        .then(res => {
                            res[0].fields.id = item.Id;
                            return res[0].fields
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }));

                this.props.setCareBoxSets(careBoxSets);
            })
            .then(async () => {
                let dataToSend = [
                    {
                        Id: baseData.individualCareBoxId,
                        Entity: 'is_care_box_set',
                        Fields: [
                            "is_protective_aprons_return",
                            "is_bed_protectors_return",
                            "is_disposable_masks_return",
                            "is_disposable_masks_ffp2_return",
                            "is_one_time_gloves_return",
                            "is_surface_disinfectant_500_return",
                            "is_hand_sanitizer_500_return",
                            "is_reusable_bed_protectors_return",
                            "is_disposable_finger_overlay_return",
                            "is_surface_disinfectant_1000_return",
                            "is_hand_sanitizer_1000_return",
                            "is_reusable_protective_aprons_return",
                            "is_disinfecting_wipes_return",
                            "is_disposable_masks_ffp2_set_return"
                        ]
                    }
                ];
                const careBoxPricesForIndividualSet = await getDynamicFields(dataToSend)
                    .then(res => {
                        return res[0].fields
                    })
                    .catch(error => {
                        console.log(error);
                    });
                this.props.setCareBoxIndividualPrices(careBoxPricesForIndividualSet);
            }).then(() => {
                const gloveTypeJson = {
                    "EntityName": "is_care_box_ingridients",
                    "AttributeName": "is_one_time_gloves_type"
                };
                getPicklist(gloveTypeJson).then(res => {
                    this.props.setGloveType(res.Translations[0].Values);
                }).catch(error => {
                    console.log(error)
                })
            }
        )
            .catch(error => {
                console.log(error);
            });
    }

    getCareBoxData = async () => {
        const {match: {params}} = this.props;
        const {form} = this.state;

        const response = await BBFormService.getCareBoxPflegebox(params.patientId);
        let toFind = params.careBoxId !== undefined && params.careBoxId !== null ? params.careBoxId : null;
        const filteredCraeboxes = toFind !== null ? response.data.filter(item => item.Id == toFind) : response.data.filter(item => item.Status == '717700000').sort((a, b) => b.CreatedOn - a.CreatedOn);
        this.setDraftCareBox(filteredCraeboxes.length > 0);
        if (filteredCraeboxes.length > 0) {
            const filteredItem = filteredCraeboxes[0];
            this.props.setCareBoxIds({careBoxId: filteredItem.Id, ingredientsId: filteredItem.CareBoxIngridientId});

            await BBFormService.retrievePatient(form.getRetiveDataCareBox(filteredItem.Id)).then(resCareBox => {
                const {data} = resCareBox;
                data.forEach(entity => {
                    let fields = entity.fields
                    for (const element in fields) {
                        if (element === 'is_pflegegrad') {
                            fields.is_pflegegrad_duplicated = fields[element];
                            delete fields['is_pflegegrad'];
                        }
                    }
                    form.setFields(fields)
                });
            });

            await BBFormService.retrievePatient(form.getRetiveDataCareBoxIng(filteredItem.CareBoxIngridientId)).then(resCareBoxIng => {
                const {data} = resCareBoxIng;
                data.forEach(entity => {
                    let fields = entity.fields;
                    for (const element in fields) {
                        if (toDuplicatIngredients.includes(element)) {
                            fields[element.concat('_duplicated')] = fields[element];
                            delete fields[element];
                        }
                    }

                    form.setFields(fields)
                });
            });
        }

    }

    savePatient = async () => {
        const {form, currentSection, isServiceType, isImportant, hasDraftCareBox} = this.state;
        const {match: {params}} = this.props;
        let isFormDisabled = form.isDisabled(isServiceType, hasDraftCareBox);
        if (!isFormDisabled) {
            if (this._isMounted) {
                this.setState({savingData: true});
            }
            const section = form.getSectionByOrder(currentSection);
            let allImportantFieldsFilled = true;
            if (isImportant === false && !section.allImportantsFilled) {
                allImportantFieldsFilled = false;
            }
            if (section.isValid && allImportantFieldsFilled) {
                try {
                    const response = await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, params.patientId, this.props.careBoxId));
                    const isCareBoxSet = form.getFieldByKey('is_lead_status_care_box').value;
                    if (isCareBoxSet == '717700003' && section.key === 'section-care-box') {
                        const responseFromCareBox = await BBFormService.savePatient(form.getCareBox(section.key, this.props.careBoxId));
                        const {data: dataCareBox} = responseFromCareBox;
                        await BBFormService.savePatient(form.getCareBoxIngredients(section.key, dataCareBox[0].id, this.props.careBoxIngredientsId));
                    }
                    let lkid = params.id ? params.id : 0;
                    let patientid = params.patientId ? params.patientId : 0;
                    const {data} = response;
                    data.forEach(element => {
                        if (element.entity === Entity.PATIENT) {
                            patientid = element.id;
                        } else {
                            if (element.entity === Entity.CONTACT) {
                                lkid = element.id;
                            }
                        }
                    });
                    if (this._isMounted) {
                        this.setState({
                            savingData: false,
                            showValidationErrors: false,
                            isImportant: false,
                        });
                    }
                    this.disableSupportFields();
                    if (currentSection === FormConfig.firstPageAfterRodo) {
                        const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
                        if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
                            /// pass fields from 1 to third section
                            const firstNameValue = form.getFieldByKey('firstname').value;
                            const lastNameValue = form.getFieldByKey('lastname').value;
                            form.getFieldByKey('is_patient_first_name').value = firstNameValue;
                            form.getFieldByKey('is_patient_last_name').value = lastNameValue;
                            form.getFieldByKey('is_first_name_and_last_name_of_patient').value = firstNameValue + " " + lastNameValue;
                            form.getFieldByKey('is_service_address_city').value = form.getFieldByKey('is_registered_city').value;
                            form.getFieldByKey('is_service_address_house_number').value = form.getFieldByKey('is_registered_house_number').value;
                            form.getFieldByKey('is_patient_cellphone').value = form.getFieldByKey('is_mobile_phone').value;
                            form.getFieldByKey('is_patient_phone').value = form.getFieldByKey('is_home_phone').value;
                            form.getFieldByKey('is_service_address_postal_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
                            form.getFieldByKey('is_service_address_street_number').value = form.getFieldByKey('is_registered_street_number').value;
                            form.getFieldByKey('is_service_address_street').value = form.getFieldByKey('is_registered_street').value;
                            this.simpleSaveWithoutErrors(Sections.PATIENT, patientid);
                        }
                    }
                    return [true, lkid, patientid];
                } catch (e) {
                    console.log(e);
                    if (this._isMounted) {
                        this.setState({
                            savingData: false,
                            showValidationErrors: false,
                            isImportant: false
                        });
                    }
                    return [false];
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        savingData: false,
                        showValidationErrors: true,
                        isImportant: true,
                    });
                    setTimeout(() => {
                        const scrollToField = section.fields.find(element => !element.isValid);
                        if (scrollToField) {
                            const elmnt = document.getElementById(`${scrollToField.key}_error_id`);
                            if (elmnt) {
                                elmnt.parentNode.scrollIntoView({behavior: 'smooth'});
                            }
                        } else {
                            const importantMessageList = document.getElementsByClassName('important-message-class');
                            if (importantMessageList.length > 0) {
                                importantMessageList[0].parentNode.scrollIntoView({behavior: 'smooth'});
                            }
                        }
                    }, 50);
                }
                return [false];
            }
        } else {
            if (this._isMounted) {
                this.setState({
                    savingData: false,
                    showValidationErrors: false,
                    isImportant: false
                });
            }
            return [true, params.id, params.patientId]
        }
    };

    savePatientWithoutStateChange = async () => {
        const {form, currentSection, isServiceType, hasDraftCareBox, isImportant} = this.state;
        const {match: {params}} = this.props;
        let isFormDisabled = form.isDisabled(isServiceType, hasDraftCareBox);
        if (!isFormDisabled) {
            if (this._isMounted) {
                this.setState({savingData: true});
            }
            const section = form.getSectionByOrder(currentSection);
            let allImportantFieldsFilled = true;
            if (isImportant === false && !section.allImportantsFilled) {
                allImportantFieldsFilled = false;
            }
            if (section.isValid && allImportantFieldsFilled) {
                try {
                    const response = await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, params.patientId));
                    let lkid = params.id ? params.id : 0;
                    let patientid = params.patientId ? params.patientId : 0;
                    response.data.forEach(element => {
                        if (element.entity === Entity.PATIENT) {
                            patientid = element.id;
                        } else {
                            if (element.entity === Entity.CONTACT) {
                                lkid = element.id;
                            }
                        }
                    });
                    if (currentSection === FormConfig.firstPageAfterRodo) {
                        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
                        const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
                        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
                            /// pass fields from 1 to second section
                            form.getFieldByKey('is_contact_person_firstname').value = form.getFieldByKey('firstname').value;
                            form.getFieldByKey('is_contact_person_lastname').value = form.getFieldByKey('lastname').value;
                            form.getFieldByKey('is_contact_person_mobile_phone').value = form.getFieldByKey('is_mobile_phone').value;
                            form.getFieldByKey('is_relationship_with_patient_op').value = isDegreeRelationShipWithPatientCarer.value;
                            this.simpleSaveWithoutErrors(Sections.CONTACT_PERSON, patientid);
                        }
                        if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {
                            /// pass fields from 1 to third section
                            const firstNameValue = form.getFieldByKey('firstname').value;
                            const lastNameValue = form.getFieldByKey('lastname').value;
                            form.getFieldByKey('is_patient_first_name').value = firstNameValue;
                            form.getFieldByKey('is_patient_last_name').value = lastNameValue;
                            form.getFieldByKey('is_first_name_and_last_name_of_patient').value = firstNameValue + " " + lastNameValue;
                            form.getFieldByKey('is_service_address_city').value = form.getFieldByKey('is_registered_city').value;
                            form.getFieldByKey('is_service_address_house_number').value = form.getFieldByKey('is_registered_house_number').value;
                            form.getFieldByKey('is_patient_cellphone').value = form.getFieldByKey('is_mobile_phone').value;
                            form.getFieldByKey('is_service_address_postal_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
                            form.getFieldByKey('is_service_address_street_number').value = form.getFieldByKey('is_registered_street_number').value;
                            form.getFieldByKey('is_service_address_street').value = form.getFieldByKey('is_registered_street').value;
                            this.simpleSaveWithoutErrors(Sections.PATIENT, patientid);
                        }
                    }
                    this.disableSupportFields();
                    return [true, lkid, patientid];
                } catch (e) {
                    return [false];
                }
            }
            return [false]
        }
        return [true, params.id, params.patientId]
    };

    simpleSaveWithoutErrors = async (sectionNumber, patientid) => {
        const {match: {params}} = this.props;
        const {form, isServiceType, hasDraftCareBox} = this.state;
        let isFormDisabled = form.isDisabled(isServiceType, hasDraftCareBox);
        if (!isFormDisabled) {
            const section = form.getSectionByOrder(sectionNumber);
            if (section.isValid) {
                let newPatient = params.patientId;
                if (patientid !== 0 || patientid !== "0") {
                    newPatient = patientid;
                }
                await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, newPatient));
                this.props.getPatientLeadData(this.props.appLang);
            }
        }
    };

    getMeInitLeadPatientsList() {
        const {match: {params}, patientLeadData} = this.props;
        let pushedElements = [];
        let leadPatientsList = patientLeadData.filter(element => {
            if (element.leadId === params.id) {
                if (pushedElements.indexOf(element.id) > -1) {
                    return false;
                } else {
                    pushedElements.push(element.id);
                    return element
                }
            } else {
                return false;
            }
        })
        if (this._isMounted) {
            this.setState({leadPatients: leadPatientsList})
        }
        return pushedElements;
    }

    gotBasicDataPrepareWhichPageToShow() {
        this.prepareMeForm();
    }

    formLoadedChangeState = (formObj = new FormLoadedState()) => {
        if (this._isMounted) {
            this.setState({
                currentSection: formObj.currentSection,
                savingData: formObj.savingData,
                modalLoader: formObj.modalLoader,
                showValidationErrors: formObj.showValidationErrors,
                isImportant: formObj.isImportant,
                prevPageNumber: formObj.prevPageNumber,
                rodoDisabled: formObj.rodoDisabled
            })
        }
        window.scrollTo(0, 0);
    }


    prepareMeForm() {
        const {match: {params}, patientLeadData} = this.props;
        const {
            form,
            showValidationErrors,
            currentSection,
            savingData,
            modalLoader,
            prevPageNumber,
            rodoDisabled,
            secondPatientForm
        } = this.state;
        let patientIdList = [];
        if (params.patientId !== 0 && params.patientId !== "0") {
            patientIdList.push(params.patientId);
        }
        const allPatientsforLead = patientLeadData.filter(element => (element.leadId === params.id));
        if (allPatientsforLead.length > 1) {
            const otherPatients = allPatientsforLead.filter(element => (element.id !== params.patientId)).flat();
            BBFormService.retrievePatient(secondPatientForm.getRetiveDataALl(params.id, [otherPatients[0].id])).then(response => {
                const {data} = response;
                data.forEach(entity => secondPatientForm.setFields(entity.fields));
            });
            this.setState({
                multiplePatients: true,
                otherPatient: otherPatients,
                secondPatientId: otherPatients[0].id,
            });
        } else {
            if (!this.state.wasCreateNewPatient) {
                this.setState({
                    multiplePatients: false,
                    wasCreateNewPatient: false,
                });
            }
        }
        BBFormService.retrievePatient(form.getRetiveDataALl(params.id, patientIdList)).then(response => {
            const {data} = response;
            data.forEach(entity => form.setFields(entity.fields));
            let formState = new FormLoadedState(showValidationErrors, currentSection, savingData, modalLoader, prevPageNumber, rodoDisabled);
            formState = new FormLoadedState(true, FormConfig.finalPageNumber, false, false, FormConfig.finalPageNumber, true);
            this.myTimeout = setTimeout(() => {
                this.formLoadedChangeState(formState);
            }, 3000);
        }).then(() => {
            this.getCareBoxData();
        }).then(() => {
            if (this._isMounted) {
                this.setState({rerender: Date.now()});
            }
        })
    }

    getInitialDataTime() {
        if (this.props.leadsStatus.length > 0) {
            clearTimeout(this.myTimeout);
            this.getClientsDataByTimestamp();
        } else {
            if (this.props.leadsStatusStatus !== ResponseStatus.PENDING) {
                this.props.getStatusLead(this.props.appLang);
            }
            this.myTimeout = setTimeout(() => {
                this.getInitialDataTime()
            }, 500);
        }
    }

    getClientsList = () => {
        this.props.pendingRequestLeads();
        axiosAuth.get("clients/list/0" + this.props.appLang)
            .then(res => {
                if (res.status === 200) {
                    return res;
                } else {
                    return Promise.reject(res.status);
                }
            })
            .then(json => {
                return json.data
            })
            .then(res => {
                const {match: {params}} = this.props;
                const {translateKey} = this.props;
                for (let i = 0; i < res.length; i++) {
                    if (res[i].id === params.id) {
                        let name = this.buildName(res[i]);
                        if (this._isMounted) {
                            this.setState({
                                LKId: params.id,
                                clientLeadBaseData: res[i],
                                breadCrumbs: [...this.state.breadCrumbs, {
                                    link: "clientDetails/" + params.id,
                                    key: "",
                                    name: name
                                },
                                    {
                                        link: "",
                                        key: "",
                                        name: translateKey('section-care-box')
                                    }]
                            })
                        }
                        this.getServiceData();
                        this.gotBasicDataPrepareWhichPageToShow();
                        break;
                    }
                }
            })
            .catch((error) => {
                this.props.clearDataLeads();
                this.getBreadCrumb();
            });
    }

    buildName(res) {
        let name = '---';
        if (res.firstName !== null) {
            name = res.lastName;
            if (res.firstName !== null) {
                name += ' ' + res.firstName;
            }
        } else {
            if (res.firstName !== null) {
                name = res.firstName;
            }
        }
        return name;
    }

    getServiceData() {
        const {
            match: {params}
        } = this.props;
        let dataToSend = [
            {
                Id: params.id,
                Entity: Entity.CONTACT,
                Fields: ["is_service_type_de"]
            }
        ];
        getDynamicFields(dataToSend)
            .then(res => {
                if (res.length > 0) {
                    if (res[0].fields["is_service_type_de"] === null || res[0].fields["is_service_type_de"] === undefined) {
                        res[0].fields["is_service_type_de"] = '717700001'
                    }
                    if (this._isMounted) {
                        this.setState({
                            isServiceType: res[0].fields["is_service_type_de"]
                        });
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    getClientsDataByTimestamp() {
        const lastUpdate = this.props.leadsTimestamp;
        const actualTime = new Date().getTime();
        if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
            this.getClientsList();
        } else {
            if (this.props.leadsData.length > 0) {
                this.getBreadCrumb();
            } else {
                this.getClientsList();
            }
        }
    }

    getBreadCrumb() {
        const {match: {params}} = this.props;
        for (let i = 0; i < this.props.leadsData.length; i++) {
            if (this.props.leadsData[i].id === params.id) {
                let name = '---';
                if (this.props.leadsData[i].client.lastName !== null) {
                    name = this.props.leadsData[i].client.lastName;
                    if (this.props.leadsData[i].client.firstName !== null) {
                        name += ' ' + this.props.leadsData[i].client.firstName;
                    }
                } else {
                    if (this.props.leadsData[i].client.firstName !== null) {
                        name = this.props.leadsData[i].client.firstName;
                    }
                }
                if (this._isMounted) {
                    this.setState({
                        LKId: params.id,
                        clientLeadBaseData: this.props.leadsData[i],
                        breadCrumbs: [...this.state.breadCrumbs, {
                            link: "clientDetails/" + params.id,
                            key: "",
                            name: name
                        },
                            {
                                link: "",
                                key: "",
                                name: "BB"
                            }]
                    })
                }
                this.gotBasicDataPrepareWhichPageToShow();
                break;
            }
        }
    }

    toggleAnalizeLoader = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                analizeFinished: !prevState.analizeFinished
            }
        })
    }

    toggleOffAnalizer = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                analizeFinished: true
            }
        })
    }

    toggleOffer = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                isOfferOpened: !prevState.isOfferOpened
            }
        })
    }

    setOfferLoaded = () => {
        this.setState({
            isOfferLoaded: true
        });
    }

    setActiveSection = (section) => {
        this.setState({activeSection: section});
        setTimeout(() => {
            document.body.classList.remove('modal-open');
        }, 100);
    }

    get isOfferButtonVisibleInToolbar() {
        return false;
    }

    get isAnwendedButtonVisibleInToolbar() {
        return false;
    }

    getDataBasic(isFormDisabled) {
        const {translateKey} = this.props;
        const {
            form,
            rerender,
            isImportant,
            sectionMenuOpen,
            modalLoader,
            clientLeadBaseData,
            analizeFinished,
            secondPatientForm,
            isAditionalPatient
        } = this.state;

        let sectionData = (
            <LoaderTable/>
        );
        if (!modalLoader) {
            sectionData = (
                <div className="section-class-container">
                    <SectionCareBox
                        isImportant={isImportant}
                        subsectionName={translateKey("section-care-box")}
                        sectionNumber={1}
                        form={form}
                        showValidationErrors={false}
                        disabled={true}
                        rerender={rerender}
                        analizeFinished={analizeFinished}
                        currentSection={1}
                        disableAdditionalData={true}
                        hideNavigation={true}
                    />
                </div>
            );
        }

        const infoBox = (
            <InfoModal okMe={this.closeModalClick} show={this.state.showModal} titleMessage=""
                       mainMessage={translateKey("care-box-saved-modal-content")} okMessage={translateKey("Ok")}/>
        )

        const content = (
            <React.Fragment>
                <SectionNavigationToolbarComponent
                    rerender={rerender}
                    showSectionValidation={true}
                    changeSection={(number) => this.changePage(number)}
                    toggleSectionMenu={this.toggleSectionMenu}
                    hideSectionMenu={this.hideSectionMenu}
                    form={form}
                    currentSection={0}
                    sectionMax={FormConfig.finalPageNumber}
                    sectionMenuOpen={sectionMenuOpen}
                    isUnderAnalize={this.state.analizeFinished}
                    // additionalToolbar={currentSection === 5}
                    additionalToolbar={false}
                    onOpenOffer={this.toggleOffer}
                    setOfferLoaded={this.setOfferLoaded}
                    isOfferButtonVisibleInToolbar={this.isOfferButtonVisibleInToolbar}
                    isAnwendedButtonVisibleInToolbar={this.isAnwendedButtonVisibleInToolbar}
                    setActiveSection={this.setActiveSection}
                    toggleFullScreen={this.toggleFullScreen}
                    type={RecordType.LEAD}
                    clientLeadBaseData={clientLeadBaseData}
                    secondPatientForm={secondPatientForm}
                    isAditionalPatient={isAditionalPatient}
                    isCareBoxForm={true}
                />
                {sectionData}
                {infoBox}
            </React.Fragment>
        )
        return content;
    }

    setStatus = (status) => {
        if (this._isMounted) {
            this.setState({isServiceType: status})
        }
    }

    setDraftCareBox = (hasDraft) => {
        if (this._isMounted) {
            this.setState({hasDraftCareBox: hasDraft})
        }
    }

    showSaveModal = () => {
        if (this._isMounted) {
            this.setState({savingData: !this.state.savingData})
        }
    }

    hideSaveModal = () => {
        if (this._isMounted) {
            this.setState({savingData: false})
        }
    }

    shouldWeRerenderBasedOnImportant() {
        if (this._isMounted) {
            this.setState({rerender: Date.now()});
        }
    }

    toggleSectionMenu = () => {
    }

    hideSectionMenu = () => {
    }

    getMeClassesToMainContainer = () => {
        const {hideMeAll, currentSection, sectionMenuOpen} = this.state;
        let classesForContainer = '';
        if (hideMeAll) {
            classesForContainer += 'fullScreenClass allow-overflow';
        } else {
            if (currentSection === 5 || currentSection === 3 || sectionMenuOpen || currentSection === 12) {
                classesForContainer += 'allow-overflow';
            }
        }
        return classesForContainer;
    }

    render() {
        const {
            form,
            isServiceType,
            hasDraftCareBox,
            errorComponent,
            hideBar,
            hideMeAll,
            editedForm,
            modalLoader,
            savingData,
            breadCrumbs
        } = this.state;
        const {translateKey} = this.props;
        const isFormDisabled = form.isDisabled(isServiceType, hasDraftCareBox);
        return (
            <div className={`mainContentWrapper ${hideMeAll ? 'white' : ''}`} onClick={() => {
                this.props.tookAction()
            }}>
                <NavBar breadCrumbs={breadCrumbs} className={`${hideMeAll ? 'hideMeAllClass' : ''}`}/>
                <TableProperties className={`${hideMeAll ? 'hideMeAllClass' : ''}`} hideBar={hideBar} options={[]}
                                 filterData={[]}/>
                <div className="mainContent">
                    <SideBar className={`${hideMeAll ? 'hideMeAllClass' : ''}`}/>
                    <div className={`listDataWrapper ${this.getMeClassesToMainContainer()}`}>
                        {this.getDataBasic(isFormDisabled)}
                        <AlertModalInfo size="lg" centeredAlert={true} show={errorComponent.show}
                                        key="save-success-fail-message" type={errorComponent.type}
                                        message={errorComponent.message}/>
                    </div>
                </div>
                <Prompt
                    when={editedForm}
                    message={translateKey("unsaved_data_lost")}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        leadsData: state.database.leads,
        leadsStatus: state.enums.leadStatus,
        leadsStatusStatus: state.enums.leadStatusStatus,
        patientLeadData: state.database.patientLead,
        careBoxId: state.database.careBoxId,
        careBoxIngredientsId: state.database.careBoxIngredientsId,
    };
};
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    tookAction: () => dispatch(authSetActionTimestamp()),
    setLeadsData: (data) => dispatch(setLeadsData(data)),
    getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
    pendingRequestLeads: () => dispatch(pendingLeadsData()),
    clearDataLeads: () => dispatch(removeLeadsData()),
    getProducts: () => dispatch(getUserProducts()),
    getRegions: () => dispatch(getRegionsFP()),
    getStatusLead: (lang) => dispatch(getLeadStatusData(lang)),
    removeLeadsStatus: () => dispatch(removeLeadStatusData()),
    setCareBoxIds: (data) => dispatch(setCareBoxIds(data)),
    setCareBoxSets: (data) => dispatch(setCareBoxSets(data)),
    setCareBoxSetsList: (data) => dispatch(setCareBoxSetsList(data)),
    setGloveType: (data) => dispatch(setGloveType(data)),
    setCareBoxIndividualPrices: (data) => dispatch(setCareBoxIndividualPrices(data)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ingredientsCareBoxWrapperClient));