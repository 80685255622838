import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { translateKey, setFontSize } from "../../_redux/actions/index";
import { IconsMap } from '../../_helpers/icons_set'
import { baseData } from '../../_constance/base_data';
import { FontType } from '../../_constance/enums/fontType';
import SearchContractModal from "../../_components/search-contract-modal/SearchContractModal";

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeRoute: '',
            breadCrumbs: this.props.breadCrumbs,
            isSearchModalOpen: false,
        };
    }

    logMeOutWithCleaning = () =>{
        this.props.history.push(`${baseData.subFolders}logout`);
    }

    redirectToLead = () => {
        this.props.history.push(`${baseData.subFolders}leads`);
        this.props.history.go(0);
    }

    searchContactAndReactivate = () => {
        this.setState({
            isSearchModalOpen: true,
        })
    }

    togglesearchContractModal = () => {
        this.setState({
            isSearchModalOpen: !this.state.isSearchModalOpen,
        })
    }

    prepareMeBreadCrumbs = () =>{
        let breadCrumbs = this.props.breadCrumbs.map((element, i, arr) => {
            if(i===arr.length-1){
                return <li key={Math.random().toString(36).substr(2, 9)} className="nav-item">
                    <span className="nav-link not-active-break-crumbs">{element.key?this.props.translateKey(element.key): element.name}</span>
                </li>
            }else{
                return <li key={Math.random().toString(36).substr(2, 9)} className="nav-item">
                <Link className="nav-link" to={baseData.subFolders+element.link}>{element.key?this.props.translateKey(element.key): element.name} <span className="bread-crumbs-selector">/</span></Link>
                </li>
            }
        });
        return breadCrumbs;
    }

    setFontType(type) {
        const { setFontSize } = this.props;
    
        switch (type) {
            case FontType.SMALL:
                setFontSize(FontType.SMALL);
                if(document.body.classList.contains('medium-text')) {
                    document.body.classList.remove('medium-text');
                }
                if(document.body.classList.contains('big-text')) {
                    document.body.classList.remove('big-text');
                }
                break;
            case FontType.MEDIUM:
                setFontSize(FontType.MEDIUM);
                document.body.classList.add('medium-text');
                if(document.body.classList.contains('big-text')) {
                    document.body.classList.remove('big-text');
                }
                break;
            case FontType.BIG:
                setFontSize(FontType.BIG);
                document.body.classList.add('big-text');
                if(document.body.classList.contains('medium-text')) {
                    document.body.classList.remove('medium-text');
                }
                break;
            default: 
                setFontSize(FontType.SMALL);
                if(document.body.classList.contains('medium-text')) {
                    document.body.classList.remove('medium-text');
                }
                if(document.body.classList.contains('big-text')) {
                    document.body.classList.remove('big-text');
                }
        }
    }


    render() {
        const { className, fontSize } = this.props;
        return (
            <>
                <nav className={`navbar navbar-expand-lg fixed-top ${className?className:''}`}>
                    <Link to={`${baseData.subFolders}leads`}><img className="navbar-brand-icon" src={IconsMap.rest.white_logo} alt={"Icon"}/></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">{this.prepareMeBreadCrumbs()}</ul>
                        <div className="form-inline my-2 my-lg-0 rest-items-nav-wrapper">
                            <div className="reactivate-contact">
                                <button className="btn norbsoft-filter-button btn-light" onClick={this.searchContactAndReactivate}>
                                    <i className="fas fa-search norbsoft-filter-icon"></i>{this.props.translateKey('reactivate_contact')}
                                </button>
                            </div>
                            <div className="fontSize">
                                <button
                                    type="button" 
                                    className={`fontSize__btn ${ fontSize === FontType.SMALL ? 'active' : null }`}
                                    onClick={() => {this.setFontType(FontType.SMALL)}} 
                                >
                                    <i className="fas fa-font"></i>
                                </button>
                                <button
                                    type="button" 
                                    className={`fontSize__btn fontSize__btn--medium ${ fontSize === FontType.MEDIUM ? 'active' : null }`}
                                    onClick={() => {this.setFontType(FontType.MEDIUM)}} 
                                >
                                    <i className="fas fa-font"></i>
                                </button>
                                <button
                                    type="button" 
                                    className={`fontSize__btn fontSize__btn--big ${ fontSize === FontType.BIG ? 'active' : null }`}
                                    onClick={() => {this.setFontType(FontType.BIG)}} 
                                >
                                    <i className="fas fa-font"></i>
                                </button>
                            </div>
                            <div className="loginWrapper">
                                <i className="fas fa-user fa-1x user-name-icon"></i>
                                <span>{this.props.login? this.props.login : "undefinied"}</span>
                            </div>
                            <div className="logOutIconWrapper">
                                <button className="btn btn-primary logout-button" onClick={this.logMeOutWithCleaning}>{this.props.translateKey('logout')}</button>
                            </div>
                        </div>
                    </div>
                </nav>
                <SearchContractModal 
                    show={ this.state.isSearchModalOpen } 
                    onClose={ this.togglesearchContractModal } 
                    translateKey={this.props.translateKey}
                    redirectToLead={this.redirectToLead}
                />
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        login: state.auth.appLogin,
        fontSize: state.fontSize.fontSize,
    }
}  
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    setFontSize: type => dispatch(setFontSize(type)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));