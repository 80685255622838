import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import SwitchComponent from "../../form-bb-components/switch-component/switch-component";
import produce from "immer";

class SectionKrankheitsbilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-krankheitsbilder'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const {
          disabled,
          translateKey,
          subsectionName,
          sectionNumber,
          showValidationErrors,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
          form
        } = this.props;
        const { section } = this.state;
        const isInfectiousDiseases = section.getFieldByKey('is_infectious_diseases');
        const isFamilySufferInfectionusDiseases = section.getFieldByKey('is_family_suffer_infectionus_diseases');
        const isNewUI = true; 
        const classForCheckBoxSimple = "newUI-radio-check-box-class row";
 
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>

                        <div className="newUI-section-padding">
                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <SwitchComponent
                                        labelClassName="no_margin"
                                        setTypeChangeToParent={this.setTypeChange}
                                        showValidation={showValidationErrors}
                                        className={classForCheckBoxSimple} 
                                        transKey="form-text-anstecken"
                                        key="is_infectious_diseases"
                                        keyName="is_infectious_diseases"
                                        value={isInfectiousDiseases.value}
                                        validationErrors={isInfectiousDiseases.validationErrors}
                                        valueYes = {true}
                                        valueNo = {false}
                                    />
                                    { isInfectiousDiseases.value === true && (
                                        <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_infectious_diseases_what_de')} text={translateKey("form-text-welche")} transKey="form-text-welche" keyName="is_infectious_diseases_what_de"/>
                                    )}
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <SwitchComponent
                                        labelClassName="no_margin"
                                        setTypeChangeToParent={this.setTypeChange}
                                        showValidation={showValidationErrors}
                                        className={classForCheckBoxSimple} 
                                        transKey="form-text-alzheimer-krankheiten"
                                        key="is_family_suffer_infectionus_diseases"
                                        keyName="is_family_suffer_infectionus_diseases"
                                        value={isFamilySufferInfectionusDiseases.value}
                                        validationErrors={isFamilySufferInfectionusDiseases.validationErrors}
                                        valueYes = {true}
                                        valueNo = {false}
                                    />
                                    { isFamilySufferInfectionusDiseases.value === true && (
                                        <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_family_suffer_infectionus_diseases_des_de')} text={translateKey("form-text-welche")} transKey="form-text-welche" keyName="is_family_suffer_infectionus_diseases_des_de"/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    form={form}
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                    showNotePop={disabled?false:true}
                />
            </div>
        ); 
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(SectionKrankheitsbilder));