import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey, getTasks, setLeadsData, setLeadStatusData } from "../../_redux/actions/index";
import { shouldRefresh, IconsMap, ResponseStatus, ReqHelper } from "../../_helpers/index";
import SideBarElement from './SideBarElement'
import NavigationBar from '../../_components/form-bb-components/navigationBar/NavigationBar';
import { getLeadsDataCall, getLeadsStatusCall } from "../../_services/user_service";
import { refreshTokenTime, baseData } from '../../_constance/base_data';
import { LeadTable } from "../../_constance/classes/index";
import * as Relationship from '../../_forms/BBForm/values/relationship';
import * as IsContactPersonDataSameAs from '../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as Sections from '../../_forms/BBForm/values/sections';

class SideBar extends React.Component {
    _isMounted = false;
    leadsTimeout;
    taskTimeout;
    constructor(props) {
        super(props);
        this.state = {
            activeRoute: '',
            positionFixed: false,
            sections: this.props.form,
            leadsBadgeReady: false,
            taskBadgeReady: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('scroll', this.handleScroll);
        this.getBasicTasksData();
        this.getDataLeadBasic();
    }
    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('scroll', this.handleScroll);
    };
    
    handleScroll = () => {
        let clientHeight = document.getElementsByClassName("tableFilter")[0].clientHeight;
        if(clientHeight!==0){
            clientHeight=clientHeight+20;
        }
        if(window.pageYOffset>clientHeight){
            if (this._isMounted) {
                this.setState({positionFixed: true})
            }
        }else{
            if (this._isMounted) {
                this.setState({positionFixed: false})
            }
        }
    };

    getLeadsList = () => {
        getLeadsDataCall(this.props.appLang)
            .then(res => {
                let mapedRes = res.map((element)=>{
                    element.typeText = ReqHelper.getMeLeadStatusText(element.status, this.props.leadsStatus);
                    return new LeadTable(element);
                });
                this.props.setLeadsData(mapedRes);
                if (this._isMounted) {
                    this.setState({leadsBadgeReady: true})
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    
    getLeadsDataByTimestamp(){
        const lastUpdate = this.props.leadsTimestamp;
        const actualTime = new Date().getTime();
        if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
            this.getLeadsList();
        }else{
            if (this._isMounted) {
                this.setState({leadsBadgeReady: true})
            }
        }
    }
    
    getLeadsStatus = () => {
        getLeadsStatusCall(this.props.appLang)
        .then(res => {
            this.props.setLeadsStatus(res);
            this.getLeadsDataByTimestamp();
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    getDataLeadBasic() {
        if (this.props.leadsStatus.length === 0) {
            if (this.props.leadsStatus.length !== 0 && this.props.leadStatus !== undefined) {
                this.getLeadsDataByTimestamp();
            } else {
                this.getLeadsStatus();
            }
        }else{
            this.getLeadsDataByTimestamp();
        }
    }
    
    getBasicTasksData(){
        if(this.props.tasksStatus === ResponseStatus.READY){
            clearTimeout(this.taskTimeout);
            if (this._isMounted) {
                this.setState({taskBadgeReady: true})
            }
        }else{
            if(this.props.tasksStatus === ResponseStatus.FREE){
                this.props.getGlobalTasksData();
                this.taskTimeout = setTimeout(() => {
                    this.getBasicTasksData()
                }, 500);
            }else{
                if(this.props.tasksStatus === ResponseStatus.ERROR){
                    clearTimeout(this.taskTimeout);
                    if (this._isMounted) {
                        this.setState({ loadingTasks: false })
                    }
                }else{
                    this.taskTimeout = setTimeout(() => {
                    this.getBasicTasksData()
                    }, 500);
                }
            }
        }
    }

    changeSectionIfUCan = (number) =>{
        const { form } = this.props;
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
        if(number===Sections.CONTACT_PERSON){
            if (isContactPersonDataSameAs.value !== IsContactPersonDataSameAs.YES) {
                this.props.changeSection(number)
            }
        }else{
            if(number===Sections.PATIENT){
                if(isDegreeRelationShipWithPatientCarer.value !== Relationship.TYPE_8){
                    this.props.changeSection(number)
                }
            }else{
                this.props.changeSection(number)
            }
        }
    }


    render() {
        const { translateKey, showSectionValidation, form, rerender, leadsData, tasksData, className } = this.props;
        const {positionFixed, leadsBadgeReady, taskBadgeReady} = this.state;
        const mainPath = this.props.location.pathname;
        return (
            <React.Fragment>
                <div className={`sideBarWrapper ${positionFixed && !form? "fixedSideBar":''} ${className?className:''}`}>
                    <SideBarElement badge={leadsBadgeReady} data={leadsData} isActive={mainPath.indexOf("lead")>-1? "true" : "false"} topage={`${baseData.subFolders}leads`} label={translateKey("leads")} icon={IconsMap.svg.menu_leads}/>
                    <SideBarElement isActive={mainPath.indexOf("client")>-1? "true" : "false"} topage={`${baseData.subFolders}clients`} label={translateKey("clients")} icon={IconsMap.svg.menu_clients}/>
                    <SideBarElement isActive={mainPath.indexOf("multiplier")>-1? "true" : "false"} topage={`${baseData.subFolders}multipliers`} label={translateKey("multipliers")} icon={IconsMap.svg.menu_multipliers}/>
                    <SideBarElement isActive={mainPath.indexOf("invoice")>-1? "true" : "false"} topage={`${baseData.subFolders}invoices`} label={translateKey("invoices")} icon={IconsMap.svg.menu_invoices}/>
                    <SideBarElement badge={taskBadgeReady} data={tasksData} type ="TASKS" isActive={mainPath.indexOf("task")>-1? "true" : "false"} topage={`${baseData.subFolders}tasks`} label={translateKey("tasks")} icon={IconsMap.svg.menu_tasks}/>
                    <SideBarElement isActive={mainPath.indexOf("dashboard")>-1? "true" : "false"} topage={`${baseData.subFolders}dashboard`} label={translateKey("dashboard")} icon={IconsMap.svg.dashboard}/>
                    <SideBarElement isActive={mainPath.indexOf("arrivals")>-1? "true" : "false"} topage={`${baseData.subFolders}arrivals`} label={translateKey("bk-arrivals")} icon={IconsMap.svg.arrival}/>
                    <SideBarElement isActive={mainPath.indexOf("contact")>-1? "true" : "false"} topage={`${baseData.subFolders}contact`} label={translateKey("contact")} icon={IconsMap.svg.menu_info}/>
                    <SideBarElement isActive={mainPath.indexOf("profile")>-1? "true" : "false"} topage={`${baseData.subFolders}profile`} label={translateKey("profile-text")} icon={IconsMap.svg.menu_info}/>
                    {form &&
                        <NavigationBar rerender={rerender} showSectionValidation={showSectionValidation} changeSection={this.changeSectionIfUCan} sectionList={form} activeSection={this.props.activeSectionNumber}/>
                    }
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        leadsState: state.database.leadsState,
        leadsData: state.database.leads,
        leadsStatus: state.enums.leadStatus,
        tasksData: state.database.tasks,
        tasksStatus: state.database.tasksState
    };
};
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    getGlobalTasksData: () => dispatch(getTasks()),
    setLeadsData: (data) => dispatch(setLeadsData(data)),
    setLeadsStatus: (data) => dispatch(setLeadStatusData(data)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBar));