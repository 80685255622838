import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { baseData } from './_constance/base_data';

class RouteWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      component: props.component
    }

  }

  render() {
    const { component } = this.props;
    const isAuth = this.props.isAuthenticated;
    return (
      <>
        {isAuth?
          component
          :
          <Redirect to={`${baseData.subFolders}logout`} />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      isAuthenticated: state.auth.appToken !== null && state.auth.appToken !== undefined,
      basicDataSaved: state.enums.finishSave
  };
};
export default connect(mapStateToProps, null)(RouteWrapper);