import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import PriorityOffer from '../../../_constance/classes/dndPriorityOffer/PriorityOffer';
import TileOffer from './tile-offer'
const keyName = 'is_most_important_taking_care_patient';
const IS_DIET_DIABETES  = "is_diet_diabetes";
const SKIP_SAVE_IS_DIET_DIABETES = 'skip_save_is_diet_diabetes'; 

class TileWrapperComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            priorityOffer: new PriorityOffer(), 
            rerender: Date.now()
        }
        const field = this.props.section.getFieldByKey(keyName);
        this.state.priorityOffer.prepareMeListFromString(field.value);
    }

    setStatus(element){
        const { form } = this.props;
        if(element.action===true){
            const field = this.props.section.getFieldByKey(keyName);
            let arrList = field.value.split(';');
            if(element.active===true){
                if(arrList.indexOf(element.id)>=0){
                    arrList.splice(arrList.indexOf(element.id),1);
                }
                if(arrList.indexOf(element.id_deutch)>=0){
                    arrList.splice(arrList.indexOf(element.id_deutch),1);
                }
            }else{
                if(arrList.indexOf(element.id_deutch)<0){
                    arrList.push(element.id_deutch)
                }
            }
            if(element.id==='Przygotowywanie posiłków dla diabetyków' ||
               element.id_deutch==='Eine Betreuungskraft die Diätkost für Diabetiker vorbereiten kann' ){
                const diabeticsField = form.getFieldByKey(IS_DIET_DIABETES);
                if(diabeticsField){diabeticsField.value = !element.active}
                const saveDiabetesField = form.getFieldByKey(SKIP_SAVE_IS_DIET_DIABETES);
                if(saveDiabetesField){saveDiabetesField.value = true}
            }
            this.state.priorityOffer.changeActive(element);
            this.props.setTypeChangeToParent(keyName, this.state.priorityOffer.prepareMeStringFromListActive());
        }
        this.setState({render: Date.now()})
    }

    render() {
        const { className, translateKey, showAll } = this.props;
        return (
            <React.Fragment>
                <div className={`${className} init-offer-list-component-wrapper`}>
                    {
                        this.state.priorityOffer.fields.map((element,index) => 
                            <TileOffer 
                                showAll={showAll} 
                                rerender={this.state.rerender} 
                                key={element.id} 
                                element={element} 
                                index={index} 
                                takeAction={()=>this.setStatus(element)} 
                                translateKey={translateKey}
                            />
                        )
                    }
                </div>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default withRouter(connect(null, mapDispatchToProps)(TileWrapperComponent));  