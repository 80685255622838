import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//kopiloten
import { MultiplierTable, Alert } from "../../../_constance/classes/index";
import axiosAuth from "../../../_services/config/axios-auth";
import { refreshTokenTime } from '../../../_constance/base_data';
import { shouldRefresh, KoPilotenCategoryHelper } from "../../../_helpers/index";
import { setMultipliersData, pendingMultipliersData, removeMultipliersData, setCustomersOptions } from "../../../_redux/actions/index";
import KoPilotenCategoryComponent from '../../../_components/MultipliersCategoryComponents/Ko-Piloten-Category-Component';
import { LoaderTable, EmptyTableIcon, LoaderModal, InfoModal, ConfirmModal, AlertModalInfo, MultiPatientModal } from "../../../_components/index";
// KoPiloten Functions
import { getMultiplierCategoryList, getMultiplierCategoryRange, saveMultiplierCategoryRange } from "../../../_services/kopiloten_service";
import { KoPilotenMainForm, KoPilotenCategory, KoPilotenSaveRangeCategory}  from "../../../_forms/KoPilotenForm/index";
import { CategoriesKeyList } from "../../../_forms/KoPilotenForm/values/categoriesKeyList";
import {generatePdfBasedOnMultipliers, getFooterData, saveOptionData} from '../../../_services/backend_service';
// import { generatePdfBasedOnMultipliers, getOptionData, cancelContractSimpleOption } from '../../../_services/backend_service';
import * as Gender from "../../../_constance/enums/Gender";
import { baseData} from '../../../_constance/base_data';
import BBFormService from '../../../_services/bbform';
import { getSectionNumberByName } from "../../../_constance/enums/sectionPageList";
// components
import SummaryMenu from './components/SummaryMenu';
import SectionDieBestellung from '../SectionDieBestellung/SectionDieBestellung';
/* hiiden due to FP-343
import SectionSuchanfrage from '../SectionSuchenfragen/SectionSuchanfrage';
*/
// menu sections components
import { 
  FirstSliderContent,
  SecondSliderContent,
  OfferSection,
  CancelContract,
  SumaryWhySection
} from './components/index';

// consts, utils
import { 
  SUMMARY_OFFER_MENU_SECTIONS,
  MENU_SECTIONS,
  OFFER_SECTION,
  SECTION_OUTSIDE_MENU,
  DIE_BESTELLUNG_SECTION,
  DIE_SUCHANfRAGE,
  CANCEL_CONTRACT
} from '../../../_constance/enums/summaryOfferMenuSections'; 

import { LANGUAGE_PACKET } from '../../../_constance/enums/summaryOfferLanguagePackets';
import { translateKey } from "../../../_redux/actions/index";
import { getUserProducts, getRegionsFP } from "../../../_redux/actions/bb-form-services";
import * as FormTypeToShow from '../../../_forms/BBForm/values/formTypeToShow';
import * as MultiplierStatus from "../../../_constance/enums/MultiplierStatus";
import produce from "immer";
import { saveStats } from "../../../_services/backend_service";
import { StatsType } from "../../../_constance/enums/statsType";
import * as Entity from '../../../_forms/BBForm/values/entity';

// additional styles for the whole group of final offer
import "./SectionOfferSummary.css";

import {
  getGreetings
} from '../../../_services/footer_formatter';

//client gender key
const genderKey=  'is_candidate_gender';

class SectionOfferSummaryCareBox extends React.Component {
  _isMounted = false;
  myTimeout;

  constructor(props) {
    super(props);
    this.state = {
      element: props.data,
      analizeOffer: false,
      progressWidth: 0,
      myInterval: null,
      choosenOptionAmount: 0,
      section: props.form.getSectionByKey('section-16'),
      tempSection: props.form.getSectionByKey('section-initial-offer'),
      initialSection: SUMMARY_OFFER_MENU_SECTIONS[0].labelKey,
      activeSection: undefined,
      isVideoActive: false,

      recommendedLanguagePacket: LANGUAGE_PACKET.PACKET_300,
      selectedLanguagePacket: LANGUAGE_PACKET.PACKET_300,

      availableAdditionalOptions: this.getAvailableAdditionalOptions(),
      recommendedAdditionalOptions: [],
      selectedAdditionalOptions: [],
      selectedAdditionalOptionsForOfferSection: [],
      savedAdditionalOption: [],
      selectedAdditionalOptionToSave: [],
      savedLanguagePacket: null,
      gettingDataFromDbStatusReady: false,

      cannotChangePromPlus: false,
      displayPriceInOffer: false,
      loadingMultipliers: true,
      products: [],
      productsLoaded: false,
      regionsFP: [],
      regionsFPLoaded: false,


      //KOPILOTEN DATA
      KoPilotenEdited: false,
      KoPilotenActiveCategoryId: '0',
      KoPilotenLoadingBasics: true,
      KoPilotenForm: new KoPilotenMainForm(),
      KoPilotenRerender: Date.now(),
      KoPilotenSaving: false,
      KoPilotenModificatedCategoryList:[],
      KoPilotenErrorComponent: Alert,
      infoModalOptions: Alert,
      loaderModal: false,

      confirmCancellation: false,
      cancelationOptions: [],
      errorComponent: Alert,

      isAditionalPatient: false,
      savedLanguagePacketSecondPatient: null,
      selectedAdditionalOptionToSaveSecondPatient: [],
      showMultiPatientModal: false,
      isMultiplePatients: false,
      showRedirection: false,
      rerenderOptions: true,
      rerenderAditionalOptions: true,
      customGreetingsText: null
    };
  }

  componentDidMount() {
    const {match: { params }} = this.props;
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    this.inCaseWhereAnalizeNotNeeded();
    this.loadCustomGreetings();
    // get initial data
    this.getProductData();
    this.getRegionsData();
    this.prepareMeBasicKoPilotenFormData();

    if (this.props.isOfferOpened) {
      this.setState({
        activeSection: undefined
      })
    }

    this.getInitialMultipliers();

    // const { match: { params } } = this.props;
    const type = params.type;
    if (type === FormTypeToShow.ENQUIRY) {
      this.props.setActiveSection(DIE_SUCHANfRAGE);
    }else{
      if(type === FormTypeToShow.CONTRACT){
        this.props.setActiveSection(DIE_BESTELLUNG_SECTION);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.myInterval);
    clearTimeout(this.myTimeout);

    //Kopiloten
    const { KoPilotenEdited } = this.state;
    if (KoPilotenEdited) {this.saveChangeKoPiloten();}
  };

  //KOPILOTEN FUNCTIONS
  saveChangeKoPiloten =(withoutMessage=false) =>{
    const { KoPilotenForm, KoPilotenModificatedCategoryList } = this.state;
    if(KoPilotenModificatedCategoryList.length>0){
      if(this._isMounted){this.setState({KoPilotenSaving: true});}
      KoPilotenModificatedCategoryList.forEach((catIdData, index) => {
        const foundedCat =  KoPilotenForm.getCategoryByKey(catIdData);
        if(foundedCat){
          const dataToSend = new KoPilotenSaveRangeCategory(foundedCat.MultiplierRangeId, foundedCat.CategoryId, foundedCat.ClientId, foundedCat.Note, foundedCat.MultiplierIds);
          saveMultiplierCategoryRange(JSON.parse(JSON.stringify(dataToSend)))
          .then(res => {
            foundedCat.MultiplierRangeId = res;
            if(!withoutMessage){
              if (this._isMounted) {
                this.setState({
                  KoPilotenEdited: false,
                  KoPilotenSaving: false,
                  KoPilotenErrorComponent: {
                    show: true,
                    type: "success",
                    message: "ko-piloten-multiplikator-success-assigned"
                  },
                })
                document.body.classList.remove('overflow-y-scroll');
              }
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({ 
                    KoPilotenErrorComponent: Alert,
                    KoPilotenSaving: false
                  });
                }
              }, 2500);
            }
          })
          .catch(error => {
            if(!withoutMessage){
              if (this._isMounted) {
                this.setState({
                  KoPilotenSaving: false,
                  KoPilotenErrorComponent: {
                    show:true,
                    type: "danger",
                    message: "basic_error_message"
                  }
                });
                document.body.classList.remove('overflow-y-scroll');
              }
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({ 
                    KoPilotenErrorComponent: Alert,
                    KoPilotenSaving: false
                  });
                }
              }, 2500);
            }
          });
        }else{
          if(this._isMounted){this.setState({KoPilotenSaving: false});}
        }
      });
    }else{
      if(!withoutMessage){
        if(this._isMounted){this.setState({KoPilotenSaving: true});}
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ 
              KoPilotenSaving: false,
              KoPilotenEdited: false
            });
          }
        }, 500);
      }
    }
  }

  putNewCategoryKoPiloten(catId, label, multi) {
    const {match: { params } } = this.props;
    const nextState = produce(this.state, (draftState) => {
      const { KoPilotenForm } = draftState;
      KoPilotenForm.categories.push(
        new KoPilotenCategory(
          null,
          KoPilotenCategoryHelper.getMeNameBasedOnLabel(label),
          catId,
          params.id,
          null,
          multi.map((element)=>{return element.toUpperCase()}),
          [],
          KoPilotenCategoryHelper.getMeSubcategoriesBasedOnLabel(label),
          [],
          KoPilotenCategoryHelper.isSubCategory(label)
        )
      );
    });
    this.setState(nextState);
  }

  prepareMeBasicKoPilotenFormData() {
    const { multiCategoryEnum } = this.props;
    const { loadingMultipliers } = this.state;
    if(multiCategoryEnum.length > 0 && !loadingMultipliers ) {
      clearTimeout(this.myTimeouKoPiloten);
      let count = 0;
      multiCategoryEnum.forEach((element, index) => {
        getMultiplierCategoryList(element.Value)
        .then((res) => {
          count++;
          this.putNewCategoryKoPiloten(element.Value, element.Label, res.MultiplierIds); 
          if(count===multiCategoryEnum.length-1){
            this.myTimeouKoPiloten = setTimeout(() => {this.prepareMultiplierRangeKoPiloten()}, 500);
          }
        })
        .catch(error => {
          count++;
          if(count===multiCategoryEnum.length-1){
            this.myTimeouKoPiloten = setTimeout(() => {this.prepareMultiplierRangeKoPiloten()}, 500);
          }
        });
      });
    } else {
      this.myTimeouKoPiloten = setTimeout(() => {this.prepareMeBasicKoPilotenFormData();}, 100);
    }
  }

  prepareMultiplierRangeKoPiloten() {
    const {match: { params } } = this.props;
    const { KoPilotenForm } = this.state;
    getMultiplierCategoryRange(params.id)
      .then((res) => {
        if (res.length > 0) {
          res.forEach((element, index) => {
            let newObj = {
              MultiplierIds: element.MultiplierIds.map((element)=>{return element.toUpperCase()}),
              MultiplierRangeId: element.MultiplierRangeId,
              Note: element.Note,
            };
            KoPilotenForm.setDataToCategory(newObj, element.CategoryId.toUpperCase());
            if (index === res.length - 1) {
              this.finalPreparationKoPiloten();
            }
          });
        } else {
          this.finalPreparationKoPiloten();
        }
      })
      .catch((error) => {
        console.log(error);
        this.finalPreparationKoPiloten();
      });
  }

  finalPreparationKoPiloten(){
    const { KoPilotenForm } = this.state;
    CategoriesKeyList.forEach((element,index) => {
      const category = KoPilotenForm.getCategoryByName(element.key);
      KoPilotenForm.moveCategory(index, category);
    });  
    let activeCat = '0';
    KoPilotenForm.categories.forEach((element, index) => {
      if(activeCat==='0'){
        if(element.gotMultipliersToSelect){
          activeCat = element.CategoryId;
        }
      }
      if (element.IsSubCategory === false) {
        if (element.SubCategoriesKeyList.length > 0) {
          element.SubCategoriesKeyList.forEach((transKey) => {
            let subKey = KoPilotenForm.getCategoryIdByName(transKey);
            if (subKey) {
              KoPilotenForm.assingSubCategorie(element.CategoryId, subKey);
            }
          });
        }
      }
      if (index >= KoPilotenForm.categories.length - 1) {
        this.myTimeouKoPiloten = setTimeout(() => {
          this.setState({
            KoPilotenLoadingBasics: false,
            KoPilotenActiveCategoryId: activeCat
          });
        }, 100);
      }
    });
  }

  changeCategoryActiveKoPiloten = (id) => {
    this.setState({KoPilotenActiveCategoryId: id})
  }

  addMultiToCategory=(multiplicatorId, value)=>{
    const { KoPilotenForm, KoPilotenActiveCategoryId, KoPilotenModificatedCategoryList } = this.state;
    if(!this.props.disabled){
      let add="ADD";
      if(!value){add = "REMOVE";}
      KoPilotenForm.addOrRemoveMultipliersFromToCategory(multiplicatorId, KoPilotenActiveCategoryId, add);
      const exists = KoPilotenModificatedCategoryList.indexOf(KoPilotenActiveCategoryId);
      if(exists===-1){
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenModificatedCategoryList: [...prevState.KoPilotenModificatedCategoryList, KoPilotenActiveCategoryId],
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }else{
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }
    }
  }

  changeNoteDataKopiloten=(key, val)=>{
    if(!this.props.disabled){
      const { KoPilotenForm, KoPilotenModificatedCategoryList} = this.state;
      const editedCategory = KoPilotenForm.getCategoryByKey(key);
      if(editedCategory){editedCategory.Note = val;}
      const exists = KoPilotenModificatedCategoryList.indexOf(key);
      if(exists===-1){
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenModificatedCategoryList: [...prevState.KoPilotenModificatedCategoryList, key],
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }else{
        this.setState(prevState => {
          return {
              ...prevState,
              KoPilotenEdited: true,
              KoPilotenRerender: Date.now()
              }
          }
        );
      }
    }
  }

  loadCustomGreetings = () => {
    getFooterData(this.props.userId).then(response => {
      if (response && this._isMounted) {
        this.setState({
          customGreetingsText: response.data.data[0].wysiwyg
        })
      }
    })
  }

  getMeMultiEmailContent = () =>{
    const { translateKey, fpName, fpFirstMail, form, fpPhone } = this.props;
    const tdStyles = 'border:0;padding:0;';
    const spanClass = 'font-weight:bold;color:#000000;';
    const breakLineTable = `<tr><td style="border:0;padding: 0px 0 20px 0;"></td></tr>`;
    const genderClient = form.getFieldByKey(genderKey);
    let genderText = '';
    if(genderClient.value === parseInt(Gender.MALE)){
      genderText =  translateKey('contract-text-herr');
    }else{
      if(genderClient.value === parseInt(Gender.FEMALE)){
        genderText =  translateKey('form-text-frau');
      }
    }
    const greetings = getGreetings(this.state.customGreetingsText, breakLineTable, tdStyles, fpName, fpPhone, fpFirstMail);
    const lastNameField = form.getFieldByKey('lastname');
    const htmlMailContent = `
    <table style="width:100%;color:black;border:0;outline:0;">
      <tbody>
        <tr><td style="${tdStyles}">Sehr geehrter <span style="${spanClass}">${genderText} ${lastNameField.value},</span></td></tr>
        ${breakLineTable}
        <tr><td style="${tdStyles}">Im Anhang finden Sie die Liste der Ko-Piloten. </td></tr>
        ${breakLineTable}
        <tr><td style="${tdStyles}">Bei Fragen stehe ich Ihnen gerne zur Verfügung. </td></tr>
        ${greetings}
      </tbody>
    </table>
    `;
    return htmlMailContent;
  }

  prepareDataAndSendPDF = () =>{
    const { KoPilotenForm, loadingMultipliers} = this.state;
    const { multipliersData, translateKey, form } = this.props;
    const isEmailField = form.getFieldByKey('is_email');
    if(!loadingMultipliers && multipliersData!==false && multipliersData !== null && multipliersData.length > 0){
      if(isEmailField.value){
        this.setState({loaderModal: true})
        let pdfDataToSend = [];
        const catList = [...KoPilotenForm.categories];
        catList.forEach(cat => {
          if(cat.SubCategoriesList.length===0){
            //main category
            if(cat.gotMultipliers(multipliersData)){
              let multipliersDataToSend = [];
              cat.MultiplierIds.forEach(multiId => {
                let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                if(foundMulti){
                  if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                    let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                    if(exists>-1){
                      multipliersDataToSend.push(foundMulti);
                    }
                  }
                }
              });
              if(multipliersDataToSend.length>0){
                // we got main category and some multipliers with data to send
                pdfDataToSend.push({
                  parentName: translateKey(cat?cat.Name:''),
                  childName: null,
                  categoryId: cat?cat.CategoryId:'',
                  note: cat?cat.Note:'',
                  multipliersData: multipliersDataToSend
                })
              }
            }
          }else{
            //got subcategories
            cat.SubCategoriesList.forEach(subCat => {
              if(subCat.gotMultipliers(multipliersData)){
                let multipliersDataToSend = [];
                subCat.MultiplierIds.forEach(multiId => {
                  let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                  if(foundMulti){
                    if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                      let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                      if(exists>-1){
                        multipliersDataToSend.push(foundMulti);
                      }
                    }
                  }
                });
                if(multipliersDataToSend.length>0){
                  // we got subcategory and some multipliers with data to send
                  pdfDataToSend.push({
                    parentName: translateKey(cat?cat.Name:''),
                    childName: translateKey(subCat?subCat.Name:''),
                    categoryId: subCat?subCat.CategoryId:'',
                    note: subCat?subCat.Note:'',
                    multipliersData: multipliersDataToSend
                  })
                }
              }
            });
          }
        });
        if(pdfDataToSend.length>0){
          //got some checked multipliers so we can generate pdf
          const koPilotenDataToSend = {
            userID: this.props.userId,
            list: pdfDataToSend,
            toEmail: isEmailField.value,
            content: this.getMeMultiEmailContent()
          }
          generatePdfBasedOnMultipliers(koPilotenDataToSend)
          .then(res => {
            if(res){
              if(res.status===200){
                if (this._isMounted) {
                  this.setState({
                    loaderModal: false,
                    infoModalOptions: {
                      show: true,
                      type: "success",
                      message: translateKey('ko-piloten-success-pdf-send')
                    },
                  })
                }
              }else{
                if (this._isMounted) {
                  this.setState({
                    loaderModal: false,
                    infoModalOptions: {
                      show: true,
                      type: "danger",
                      message: translateKey('basic_error_message')
                    }
                  })
                }
              }
            }
          })
          .catch((error) => {
            if (this._isMounted) {
              this.setState({
                loaderModal: false,
                errorComponent: {
                  show: true,
                  type: "danger",
                  message: "basic_error_message"
                },
              })
              document.body.classList.remove('overflow-y-scroll');
              this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                  this.setState({ 
                    errorComponent: Alert,
                    KoPilotenSaving: false
                  });
                }
              }, 3000);
            }
          });
        }else{
          if (this._isMounted) {
            this.setState({
              loaderModal: false,
              infoModalOptions: {
                show: true,
                type: "danger",
                message: translateKey('ko-piloten-at-least-one-category')
              }
            })
          }
        }
      }else{
        this.setState({
          loaderModal: false,
          infoModalOptions: {
            show: true,
            type: "danger",
            message: translateKey("form-text-err_not_an_email_address")
          },
        })
      }
    }
  }

  hideInfo = () => {
    this.setState({
      infoModalOptions: {
        show: false,
        type: "danger",
        message: this.props.translateKey("basic_error_message")
      },
    })
  }


  getProductData = () => {
    this.props.getProducts().then((data) => {
      if (this._isMounted) {
        if (data.length > 1) {
          data.sort((a, b) => {
            const nameA = a.ProductName;
            const nameB = b.ProductName;
            if (nameA === null || nameA === undefined) { return -1; }
            else if (nameB === null || nameB === undefined) { return 1; }
            else if (nameA === nameB) { return 0; }
            if (nameA.localeCompare(nameB) === -1) { return -1; }
            else { return 1; }
          })
        }
        this.setState({
          products: data,
          productsLoaded: true
        });
      }
    })
    .catch((error) => {
        console.log(error);
    })
  }
  
  getRegionsData = () => {
    this.props.getRegions().then((data) => {
      if (this._isMounted) {
        this.setState({
          regionsFP: data,
          regionsFPLoaded: true
        });
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  getAvailableAdditionalOptions = () => {
    return this.props.promedicaProductsPrices.filter(item => item.category === "Dodatek");
  }


  static getDerivedStateFromProps(props, state) {
    if(props.isOfferOpened) {
      return {activeSection: undefined}
    }
    return null;
  }

  getInitialMultipliers(){
    const lastUpdate = this.props.multipliersTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getMultipliersList();
    } else {
      if (this._isMounted) {
        this.setState({
          loadingMultipliers: false,
        });
      }
    }
  }

  getMultipliersList = () => {
    if (this._isMounted) {this.setState({ loadingMultipliers: true })}
    this.props.pendingMultiplikatorenRequest();
    axiosAuth.get("multipliers/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new MultiplierTable(element);});
        this.props.setMultiplikatorenData(mapedRes);
        if (this._isMounted) {
          this.setState({
            loadingMultipliers: false,
          });
        }
      })
      .catch((error) => {
        this.props.clearMultiplikatorenData();
        this.setState({
          loadingMultipliers: false,
        });
      });
  }

  exitsInAdditionalSavedOption = (name) =>{
    const { savedAdditionalOption } = this.state;
    if(savedAdditionalOption.length>0){
      for (const element in savedAdditionalOption) {
        for (const key in savedAdditionalOption[element]) {
          if(key === name){
            return savedAdditionalOption[element][key];
          }
        }
      }
    }
    return null
  }

  toggleAdditionalOption = (value) => {
    const index = this.state.selectedAdditionalOptionsForOfferSection.findIndex(item => item.id === value);

    if (index >= 0) {
      const array = [...this.state.selectedAdditionalOptionsForOfferSection];
      array[index].active = !array[index].active
      this.setState({
        selectedAdditionalOptionsForOfferSection: array
      });
    }
  }

  setTypeChange = (key, value) => {
    const nextState = produce(this.state, draftState => {
      const { section, tempSection } = draftState;
      const field = section.getFieldByKey(key);
      if (field) {
        field.value = value;
      }
      const fieldT = tempSection.getFieldByKey(key);
      if (fieldT) {
        fieldT.value = value;
      }
    });
    this.setState(nextState);
  };

  analizeComplete = () => {
    this.props.toggleAnalizeLoader();
    this.setState({ analizeOffer: false })
  }

  increaseProgress = () => {
    if (this._isMounted) {
      if (this.state.progressWidth >= 100) {
        clearInterval(this.state.myInterval);
        this.myTimeout = setTimeout(() => {
          this.analizeComplete()
        }, 1000);
      } else {
        this.setState(previousState => ({
          ...previousState,
          progressWidth: previousState.progressWidth + 2
        }));
      }
    }
  }

  startProgress = () => {
    if (this.props.disabled) {
      this.props.toggleOffAnalizer();
      this.setState({ analizeOffer: false })
    } else {
      if ((this.props.prevPage < 12 || !this.props.prevPage) && this.state.analizeOffer === true) {
        this.props.toggleAnalizeLoader();
        let intervalId = setInterval(() => {
          this.increaseProgress();
        }, 100);
        this.setState({ myInterval: intervalId });
      } else {
        this.props.toggleOffAnalizer();
        this.setState({ analizeOffer: false })
      }
    }
  }

  saveOptionToDB = (type, data) =>{
    const {match: { params } } = this.props;
    const dataToSend = {
      client_id:params.id,
      patient_id:params.patientId,
      type: type,
      value: data
    }
    saveOptionData(dataToSend)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            //seems ok
            //console.log(res);
          }
        }
      }
    })
    .catch((error) => {
      console.log("Unable to save data to DB");
    });
  }

  saveOptionToDBSecondPatient = (type, data) =>{
    const {match: { params }, secondPatientId } = this.props;
    const dataToSend = {
      client_id:params.id,
      patient_id:secondPatientId,
      type: type,
      value: data
    }
    saveOptionData(dataToSend)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            //seems ok
            //console.log(res);
          }
        }
      }
    })
    .catch((error) => {
      console.log("Unable to save data to DB");
    });
  }

  setCustomersData = (lang, additionalOptions) => {
    const {match: { params } } = this.props;
    const customersData = this.props.customersOptions;
    const clientData = {
      id: params.patientId,
      langOption: lang,
      aditionalOptions: additionalOptions,
    };

    if(customersData.length > 0) {
      customersData.map(item => {
        if(item.id === params.patientId) {
          item.aditionalOptions = additionalOptions;
        }
      });
    } else {
      customersData.push(clientData);
    }
    this.props.setCustomersOptions(customersData);
  }

  redirectToSecondPatient = () => {
    const { match: { params }, history, secondPatientId } = this.props;

    this.setState({
      showMultiPatientModal: false,
    });

    this.props.setActiveSection(SECTION_OUTSIDE_MENU);
    history.push(`${baseData.subFolders}bb-form-lead/${params.id}/${secondPatientId}`);
    this.props.rerenderPage(false);
  }

  acceptMultiplePatients = async (firstName, lastName) => {
    const { match: { params }, history } = this.props;
    const dataToSend = [{
            "Id": "0",
            "Entity": "is_patient",
            "Fields": {
                "is_contact_id": params.id,
                "is_last_section": "rodo_section_1",
                "is_first_name_and_last_name_of_patient": `${firstName} ${lastName}`
            }
        },
        {
            "Id": params.id,
            "Entity": "contact",
            "Fields": {
                "is_patient_last_name": lastName,
                "is_patient_first_name": firstName,
            }
        }];

    try{
      const response = await BBFormService.savePatient(dataToSend);
      let lkid = params.id?params.id:'0';
      let patientid = params.patientId?params.patientId:'0';
      const { data } = response;
      data.forEach(element => {
        if (element.entity === Entity.PATIENT) {
          patientid = element.id;
        } else {
          if (element.entity === Entity.CONTACT) {
            lkid = element.id;
          }
        }
      });
      this.props.setActiveSection(SECTION_OUTSIDE_MENU);
      history.push(`${baseData.subFolders}bb-form-lead/${lkid}/${patientid}`);
      this.props.rerenderPage(true);
    } catch (e) {
      console.log(e);
    }
  }

  cancelMultiplePatients = () => { 
    this.setState({
      showMultiPatientModal: false,
    });
  }

  inCaseWhereAnalizeNotNeeded = () => {
    this.props.toggleOffAnalizer();
    this.setState({ analizeOffer: false })
  }

  onSectionClick = (section) => {
    if (section === MENU_SECTIONS.SECTION_SUMMARY) {
      this.setState({
        activeSection: section,
        displayPriceInOffer: true
      })
    } else {
      this.setState({
        activeSection: section
      })
    }
    this.props.setActiveSection(section);
  }

  toggleVideoPlayer = () => {
    this.setState({
      isVideoActive: !this.state.isVideoActive
    })
  }

  renderCarouselButton = (direction) => {
    const iconDirection = direction === 'prev' ? 'left' : 'right';
    return (
      <a 
        role="button" 
        data-slide={direction}
        href="#carouselExampleInterval" 
        className={`carousel-control-${direction} carousel-control-area`}
      >
        <i className={`fas fa-chevron-${iconDirection} fa-2x navigation_icon_color ${direction}-navi`} />
      </a>
    )
  }

  getMeKoPilotenSection = () =>{
    const { multipliersData, disabled } = this.props;
    const {
      loadingMultipliers,
      KoPilotenForm,
      KoPilotenRerender,
      KoPilotenErrorComponent,
      KoPilotenEdited,
      KoPilotenActiveCategoryId,
      KoPilotenSaving,
      KoPilotenLoadingBasics
    } = this.state;
    let content;
    if (loadingMultipliers) {
      content = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(multipliersData===false){
        content = <EmptyTableIcon RefreshMe={() => this.props.getInitialMultipliers()} text={translateKey("no_data")} />
      }else{
        if (multipliersData === null) {
          content = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.getInitialMultipliers()} text={translateKey("no_data_to_show")} /></div>
        } else {
          if (multipliersData.length === 0) {
            content = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.getInitialMultipliers()} text={translateKey("no_data_to_show")} /></div>
          }else{
            content = <KoPilotenCategoryComponent
              generatePdf={true}
              generatePdfFunction={this.prepareDataAndSendPDF}
              saving={KoPilotenSaving}
              activeCategoryId= {KoPilotenActiveCategoryId}
              edited = {KoPilotenEdited}
              errorComponent={KoPilotenErrorComponent}
              form={KoPilotenForm}
              rerender={KoPilotenRerender}
              loadingBasics = {KoPilotenLoadingBasics}
              loadingMultipliers={loadingMultipliers}
              refreshData={() => this.getInitialMultipliers()}
              disabled={disabled}
              saveChange={this.saveChangeKoPiloten}
              changeCategoryActiveKoPiloten={this.changeCategoryActiveKoPiloten}
              addMultiToCategory={this.addMultiToCategory}
              changeNoteData={this.changeNoteDataKopiloten}
            />
          }
        }
      }
    }
    return content;
  }

  toggleCancelation = () =>{
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            confirmCancellation: !prevState.confirmCancellation
        }
      });
    };
  }

  saveStatistics() {
    const { userId, fpName, fpFirstMail, clientLeadBaseData } = this.props;
    const statsData = {
      type: StatsType.CANCELLATION_CONTRACT,
      data: {
        userId: userId,
        fpName: fpName,
        fpFirstMail: fpFirstMail,
        leadId: clientLeadBaseData.leadId,
        firstName: clientLeadBaseData.client.firstName,
        lastName: clientLeadBaseData.client.lastName
      }
    }
    saveStats(statsData)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            console.log(res.data.data);
          }
        }else{
          console.log("Unable to save data to Statistics");
        }
      }
    })
    .catch((error) => {
      console.log("Unable to save data to Statistics");
    });
  }

  confirmCancellationYes = (options) =>{
    if(this.state.confirmCancellation){this.toggleCancelation();}
    this.setState({loaderModal: true});
    if(!options){
      const { match: { params } } = this.props;
      BBFormService.cancelContract(params.id)
      .then(() => {
        this.sendRejectSimpleMail(options);
        this.saveStatistics();
      }).catch(() => {
        if (this._isMounted) {
          this.setState({
            loaderModal: false,
            infoModalOptions: {
              show: true,
              type: "danger",
              message: translateKey('basic_error_message')
            }
          })
        }
      })
    }else{
      this.sendRejectSimpleMail(options);
    }
  }

  onCancelOfferClick = (buttonNumber, options) =>{
    if(buttonNumber === 1){
      this.confirmCancellationYes(options);
    }else{
      this.setState({
        confirmCancellation: true,
        cancelationOptions: options
      })
    }
  }

  generateKoPilotenData = () =>{
    const { KoPilotenForm, loadingMultipliers} = this.state;
    const { multipliersData, translateKey, form } = this.props;
    const isEmailField = form.getFieldByKey('is_email');
    if(!loadingMultipliers && multipliersData!==false && multipliersData !== null && multipliersData.length > 0){
      if(isEmailField.value){
        let pdfDataToSend = [];
        const catList = [...KoPilotenForm.categories];
        catList.forEach(cat => {
          if(cat.IsSubCategory===false){
            if(cat.SubCategoriesList.length===0){
              //main category
              if(cat.gotMultipliers(multipliersData)){
                let multipliersDataToSend = [];
                cat.MultiplierIds.forEach(multiId => {
                  let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                  if(foundMulti){
                    if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                      let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                      if(exists>-1){
                        multipliersDataToSend.push(foundMulti);
                      }
                    }
                  }
                });
                if(multipliersDataToSend.length>0){
                  // we got main category and some multipliers with data to send
                  pdfDataToSend.push({
                    parentName: translateKey(cat?cat.Name:''),
                    childName: null,
                    categoryId: cat?cat.CategoryId:'',
                    note: cat?cat.Note:'',
                    multipliersData: multipliersDataToSend
                  })
                }
              }
            }else{
              //got subcategories
              cat.SubCategoriesList.forEach(subCat => {
                if(subCat.gotMultipliers(multipliersData)){
                  let multipliersDataToSend = [];
                  subCat.MultiplierIds.forEach(multiId => {
                    let foundMulti = multipliersData.find(element => element.id.toUpperCase() === multiId)
                    if(foundMulti){
                      if(foundMulti.statusMultiplier === null || foundMulti.statusMultiplier.toString() !== MultiplierStatus.NOT_ACTIVE){
                        let exists = cat.MultiCanBeSelected.indexOf(multiId.toUpperCase())
                        if(exists>-1){
                          multipliersDataToSend.push(foundMulti);
                        }
                      }
                    }
                  });
                  if(multipliersDataToSend.length>0){
                    // we got subcategory and some multipliers with data to send
                    pdfDataToSend.push({
                      parentName: translateKey(cat?cat.Name:''),
                      childName: translateKey(subCat?subCat.Name:''),
                      categoryId: subCat?subCat.CategoryId:'',
                      note: subCat?subCat.Note:'',
                      multipliersData: multipliersDataToSend
                    })
                  }
                }
              });
            }
          }
        });
        if(pdfDataToSend.length>0){
          //got some checked multipliers so we can generate pdf
          return pdfDataToSend;
        }
      }
    }
    return [];
  }

  render() {
    const { 
      activeSection, 
      form,
      previousPageClick,
      clientLeadBaseData,
      formbbStatus,
      disabled,
      userType,
      match: { params },
      wholeResponse,
      translateKey,
      secondPatientForm,
      secondPatientId,
    } = this.props;

    const {
      selectedLanguagePacket,
      availableAdditionalOptions,
      selectedAdditionalOptions,
      selectedAdditionalOptionsForOfferSection,
      displayPriceInOffer,
      products,
      productsLoaded,
      regionsFP,
      regionsFPLoaded,
      savedAdditionalOption,
      savedLanguagePacket,
      gettingDataFromDbStatusReady,
      infoModalOptions,
      loaderModal,
      confirmCancellation,
      errorComponent,
      isAditionalPatient
    } = this.state;
    let content = '';
    const secondPatientName = this.props.secondPatientForm.getFieldByKey("is_first_name_and_last_name_of_patient").value;

    if (activeSection === DIE_BESTELLUNG_SECTION) {
      content = (
        <div className="d-flex w-100">
          <SectionDieBestellung 
            sectionName="die-bestellung-title"
            form={form}
            wholeResponse={wholeResponse}
            disabled={disabled}
            formbbStatus={formbbStatus}
            onClick={this.onSectionClick}
            selectedLanguagePacket={selectedLanguagePacket}
            selectedAdditionalOptions={selectedAdditionalOptions}
            availableAdditionalOptions={availableAdditionalOptions}
            availableLanguagePackets={null}
            previousPageClick={previousPageClick}
            clientLeadBaseData={clientLeadBaseData}
            products={products}
            productsLoaded={productsLoaded}
            regionsFP={regionsFP}
            regionsFPLoaded={regionsFPLoaded}
            secondPatientForm={secondPatientForm}
            isAditionalPatient={isAditionalPatient}
          />
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }

    if (activeSection === SECTION_OUTSIDE_MENU) {
      content = (
        <div className="d-flex">
          <div 
            id="carouselExampleInterval" 
            className="carousel slide w-100" 
            data-ride="carousel"
            data-interval="false"
          >
            <FirstSliderContent />
            {this.renderCarouselButton('prev')}
            {this.renderCarouselButton('next')}
          </div>
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={true}
          />
        </div>
      )
    }

    if (activeSection === OFFER_SECTION) {
      content = (
        <div className="d-flex">
          <OfferSection
            form={form}
            disabled={disabled}
            availableLanguagePackets={null}
            selectedLanguagePacket={selectedLanguagePacket} 
            savedLanguagePacket={savedLanguagePacket}
            availableAdditionalOptions={availableAdditionalOptions}
            savedAdditionalOption={savedAdditionalOption}
            toggleAdditionalOption={this.toggleAdditionalOption}
            selectedAdditionalOptions={selectedAdditionalOptions}
            selectedAdditionalOptionsForOfferSection={selectedAdditionalOptionsForOfferSection}
            gettingDataFromDbStatusReady={gettingDataFromDbStatusReady}
            displayPrice={displayPriceInOffer}
            onSectionClick={this.onSectionClick}
            clientLeadBaseData={clientLeadBaseData}
            userType={userType}
            paramID={params.id}
            secondPatientForm={secondPatientForm}
            secondPatientId={secondPatientId}
            isAditionalPatient={isAditionalPatient}
          />
          <SummaryMenu  
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }

    if (activeSection === MENU_SECTIONS.SECTION_WHY_PM24) {
      content = (
        <div className="d-flex">
          <SumaryWhySection 
            translateKey={ item => translateKey(item) }
          />
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }

    if (activeSection === MENU_SECTIONS.SECTION_ABOUT_OFFER) {
      content = (
        <div className="d-flex">
          <div 
            id="carouselExampleInterval" 
            className="carousel slide w-100" 
            data-ride="carousel"
            data-interval="false"
          >
            <SecondSliderContent />
            {this.renderCarouselButton('prev')}
            {this.renderCarouselButton('next')}
          </div>
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }
  
  
    if (activeSection === MENU_SECTIONS.SECTION_PEOPLE) {
      content = (
        <div className="d-flex justify-content-center">
          <div className="container-fluid no-gutters p-0 d-flex flex-column h-100">
            {this.getMeKoPilotenSection()}
          </div>
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }
 
    if (activeSection === MENU_SECTIONS.SECTION_SUMMARY) {
      content = (
        <div className="d-flex justify-content-center">
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }
  
    if (activeSection === CANCEL_CONTRACT) {
      content = (
        <div className="d-flex justify-content-center">
          <CancelContract 
            form={form}
            cancelContract={this.onCancelOfferClick}
            secondPatientForm={secondPatientForm}
            isAditionalPatient={isAditionalPatient}
          />
          <SummaryMenu 
            activeSection={activeSection} 
            onClick={this.onSectionClick}
            disabled={false}
          />
        </div>
      )
    }
    return (
      <React.Fragment>
        <LoaderModal show={loaderModal}/>
        <MultiPatientModal 
          show={ this.state.showMultiPatientModal } 
          accept={ this.acceptMultiplePatients } 
          cancel={ this.cancelMultiplePatients } 
          redirect={ this.redirectToSecondPatient }
          translateKey={ item => translateKey(item) }
          secondPatientName={ secondPatientName }
          showRedirection={ this.state.showRedirection }
        />
        <ConfirmModal 
          rejectMe={this.toggleCancelation} 
          acceptMe={this.confirmCancellationYes} 
          show={confirmCancellation} 
          titleMessage="" 
          mainMessage={translateKey("confirm_cancel_contract")} 
          messageYes={translateKey("yes")} 
          messageNo={translateKey("no")}
        />
        <InfoModal okMe={this.hideInfo} show={infoModalOptions.show} titleMessage="" mainMessage={infoModalOptions.message} okMessage={translateKey("Ok")} />
        {content?content:<div className="d-flex" />}
        <AlertModalInfo size="lg" centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message} />
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
    multipliersData: state.database.multipliers,
    multipliersTimeStamp: state.database.multipliersTimeStamp,
    promedicaProductsPrices: state.enums.promedicaProductsPrices,
    multiCategoryEnum: state.enums.multipliersCategoryList,
    multiCategoryEnumStatus: state.enums.multipliersCategoryStatus,
    fpFirstMail: state.auth.contactFpFirstEmail,
    fpName: state.auth.contactFpName,
    fpPhone: state.auth.FPphone,
    cities: state.enums.citiesList,
    userId: state.auth.userId,
    customersOptions: state.customersOptions.customersOptions,
    priceType: state.priceType.priceType,
  };
};

const mapDispatchToProps = dispatch => ({ 
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  setMultiplikatorenData: (data) => dispatch(setMultipliersData(data)),
  pendingMultiplikatorenRequest: () => dispatch(pendingMultipliersData()),
  clearMultiplikatorenData: () => dispatch(removeMultipliersData()),
  getProducts: () => dispatch(getUserProducts()),
  getRegions: () => dispatch(getRegionsFP()),
  setCustomersOptions: options => dispatch(setCustomersOptions(options)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionOfferSummaryCareBox));