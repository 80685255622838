import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { TableColumHead, TablePagination, LoaderTable, EmptyTableIcon, LoaderModal, ConfirmModal, TableDateFormat } from "../../index";
import { SortingBoxes } from "../../../_constance/classes/sortingClasses/index";
import { Alert, Paging} from "../../../_constance/classes/index";
import { ReqHelper } from "../../../_helpers/index";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { baseData } from '../../../_constance/base_data';
import * as CareBoxStatus from '../../../_forms/BBForm/values/careBoxStatus';

class ClientPatientCareBoxData extends React.Component {
    _isMounted = false;
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            data: props.careBoxes.map(e => { let cb = e.careBox; cb.Id = e.Id; return cb; } ).filter(e => e.is_status != null),
            id: props.id,
            loading: true,
            sorting: JSON.parse(JSON.stringify(SortingBoxes)),
            errorComponent: Alert,
            pagingItem: Paging,
            retries: 0,
            clientId: props.clientId,
            patientId: props.patientId,
            careBoxes: props.careBoxes,
            onIngredientsSelected: props.onIngredientsSelected
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.remove('modal-open');
        this.getInitialDataTime();
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };

    getInitialDataTime(){
        if(this.state.data !== undefined && this.state.data.length > 0){
          clearTimeout(this.myTimeout);
          this.filterOrDataChange();
        }else{
            if(this.state.retries<10){
                if (this._isMounted) {
                    this.setState({retries: this.state.retries+1})
                }
                this.myTimeout = setTimeout(() => {
                    this.getInitialDataTime()
                  }, 500);
            }else{
                if (this._isMounted) {
                    this.setState({
                        retries: 0,
                        loading: false
                    })
                }
            }
        }
    }
    
    filterOrDataChange(){
        let finalDataToShow = this.state.data;
        ReqHelper.sortMeData(finalDataToShow, this.state.sorting);
        this.propsPagingData(finalDataToShow);
        setTimeout(() => {
            let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
            if (this._isMounted) {
                this.setState({
                    data: finalData,
                    loading: false
                })
            }
        }, 50);
    }
    
    propsSortType(type){
        let sortingObj = [];
        for(let i=0; i<this.state.sorting.length; i++){
            if(this.state.sorting[i].column===type){
                if(this.state.sorting[i].active===true){
                    let item = this.state.sorting[i];
                    item.type==='ASC'? item.type="DESC" : item.type='ASC';
                    sortingObj.push(item);
                }else{
                    let item = this.state.sorting[i];
                    item.type='ASC';
                    item.active = true;
                    sortingObj.push(item);
                }
            }else{
                let item = this.state.sorting[i];
                item.type='ASC';
                item.active = false;
                sortingObj.push(item);
            }
        }
        this.setState({sorting: sortingObj});
        this.filterOrDataChange();
    }

    propsPagingData(data){
        if(data.length<=25){
            if (this._isMounted) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        pagingItem: {
                            totalItems: data.length,
                            itemsPerPage: prevState.pagingItem.itemsPerPage,
                            currentPage: 1,
                            visiblePages: 5,
                            pageFrom: 1,
                            pageTo: 5,
                            totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                        }
                    }
                });
            }
        }else{
            if (this._isMounted) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        pagingItem: {
                        ...prevState.pagingItem,
                        totalItems: data.length,
                        pageFrom: prevState.pagingItem.pageFrom,
                        pageTo: prevState.pagingItem.pageTo,
                        currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                        totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                        }
                    }
                });
            }
        }
    }

    rejectModalToggle = () => {
        if(this.state.editToggle===true){
            this.addClick();
        }
        if (this._isMounted) {
            this.setState({editToggle: !this.state.editToggle});
        }
    }

    errorModal = () => {
        if (this._isMounted) {
            this.setState({errorModal: !this.state.errorModal});
        }
    }

    addClick = () =>{
        if (this._isMounted) {
            this.setState({
            })
        }
    }

    openCareBoxDetails(element) {
        const { match: { params }, history } = this.props;
        let item = this.state.careBoxes.filter(e => e.Id === element.Id)[0]
        if(item.careBox.is_status === CareBoxStatus.APP_NEW) {
            history.push(`${baseData.subFolders}care-box-form-client-sketch/${this.state.clientId}/${this.state.patientId}/${item.Id}`);
        } else {
            this.state.onIngredientsSelected(item)
        }
    }

    addCareBox = () => {
        const { history } = this.props;
        history.push(`${baseData.subFolders}new-care-box-form-client/${this.state.clientId}/${this.state.patientId}`);
    }

    formatDate(timestamp) {
        if(timestamp !== undefined && timestamp !== null) {
            let date = new Date(timestamp * 1000);
            let dd = date.getDate();
            let mm = date.getMonth() + 1;
            let yyyy = date.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let converted = dd + '.' + mm + '.' + yyyy;
            return converted;
        } else {
            return null;
        }
    }

    render() {
        const {translateKey} = this.props;
        let emptyHandler;
        let careBoxData;
        if (this.state.loading) {
            emptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
        }else{
            if (this.state.data.length === 0) {
                emptyHandler = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.RefreshMe()} text={translateKey("no_data_to_show")} /></div>
            }else{
                careBoxData = this.state.data.map(element => (
                <tr className="table-row-wrapper cursor-pointer" key={element.Id} onClick={() => this.openCareBoxDetails(element)}>
                    <td className={"table-cell-wrapper " + ((element.is_status !== null) ? "got-data" : 'no-data')} >{this.props.careBoxStatus[element.is_status]}</td>
                    <td className={"table-cell-wrapper " + ((element.is_care_box_shipment_date !== null) ? "got-data" : 'no-data')} >{this.formatDate(element.is_care_box_shipment_date)}</td>
                    <td className={"table-cell-wrapper " + ((element.is_delivery_date !== null) ? "got-data" : 'no-data')} >{this.formatDate(element.is_delivery_date)}</td>
                    <td className={"table-cell-wrapper " + ((element.is_delivery_to_address !== null) ? "got-data" : 'no-data')} >{this.props.careBoxDeliveryToAddress[element.is_delivery_to_address]}</td>
                </tr>
                ))
            }
        }
        let alertModal = (
            <MDBModal isOpen={this.state.errorModal} toggle={this.errorModal} size="lg">
                <MDBModalBody>
                    <div className="alert alert-danger centeredAlertNoLimit" role="alert">
                        {this.state.errorComponent.message}
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <button type="button" className="btn btn-primary" onClick={this.errorModal}>{translateKey('Ok')}</button>
                </MDBModalFooter>
            </MDBModal>
        )
        const lengthOfCareBoxes = this.state.data.length
        const lengthOfFiltered = this.state.data.filter(function (item) { return item.is_status === '717700004' }).length
        const showAddCareBox = lengthOfCareBoxes === 0 || lengthOfFiltered === lengthOfCareBoxes;
        return (
            <div className="client-data-wrapper-tab">
                {showAddCareBox &&
                    <div className="add-icon-wrapper">
                        <button className="btn btn-success norbsoft-filter-icon" onClick={this.addCareBox}><i
                            className="fas fa-plus norbsoft-filter-icon"></i>{this.props.translateKey('patient-new-care-box')}
                        </button>
                    </div>
                }
                <LoaderModal show={this.state.loaderModal}/>
                {alertModal}
                <div className="listData">
                    <table className="table">
                        <thead>
                            <tr>
                                <TableColumHead sorting={this.state.sorting} name={translateKey("patient-care-box-status")} onSortType={(data) => this.propsSortType(data)} type="is_status" />
                                <TableColumHead sorting={this.state.sorting} name={translateKey("patient-care-box-shipment-date")} onSortType={(data) => this.propsSortType(data)} type="is_care_box_shipment_date" />
                                <TableColumHead sorting={this.state.sorting} name={translateKey("patient-care-box-delivery-date")} onSortType={(data) => this.propsSortType(data)} type="is_delivery_date" />
                                <TableColumHead sorting={this.state.sorting} name={translateKey("patient-care-box-delivery-to-address")} onSortType={(data) => this.propsSortType(data)} type="is_delivery_to_address" />
                                <th scope="col" className="table-add-data"></th>
                            </tr>
                        </thead>
                        <tbody>{careBoxData}</tbody>
                    </table>
                    {emptyHandler}
                    <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    loginName: state.auth.appLogin,
    userId: state.auth.userId,
    careBoxStatus: state.enums.careBoxStatusList,
    careBoxDeliveryToAddress: state.enums.careBoxDeliveryToAddressList
}}
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientPatientCareBoxData));