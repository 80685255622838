import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
    auth,
    getDSTemplatesData,
    getPatientClient,
    getPatientLead,
    removeOneLeadData,
    translateKey
} from "../../../_redux/actions/index";
import {InfoModal, LoaderModal} from "../../../_components/index";
import {sendDocuSign} from '../../../_services/docuSign_service';
import * as Entity from '../../../_forms/BBForm/values/entity';
import * as Relationship from '../../../_forms/BBForm/values/relationship';
import BBFormService from '../../../_services/bbform';
import {SectionDieBestellungFirst, SectionDieBestellungSecond} from './Sections/index'
import ContractFormFinalOffer from "../../../_forms/ContractFormFinalOffer/ContractFormFinalOffer";
import {baseData} from '../../../_constance/base_data';
import * as LanguageSelect from "../../../_constance/values/languageSelect";
import * as FormTypeToShow from '../../../_forms/BBForm/values/formTypeToShow';
import {ADDITIONAL_OPTIONS} from '../../../_constance/enums/summaryOfferAdditionalOptions';
import {
    getEnquiryOrContractData,
    saveEnquiryOrContractData,
    sendErrorLogToBackend
} from '../../../_services/backend_service';
import {Alert} from "../../../_constance/classes/index";
import {ResponseStatus} from '../../../_helpers/response_status';
import {addNoteData} from "../../../_redux/actions/auth";
import "./SectionDieBestellung.css";

//keys to contract begin and end
const startDayKey = "is_start_date";
const endDateKey = "is_end_date";

// static value for ds list to check
const staticDsEnumListAmount = 3;

class SectionDieBestellung extends React.Component {
  _isMounted = false;
  productsTimeout;

  constructor(props) {
    super(props);
    this.state = {
      activeSection: "section-die-bestellung-1",
      modalLoader: false,
      showMessage: false,
      errorClass: '',
      contractForm: new ContractFormFinalOffer(),
      rerender: Date.now(),
      message: '',
      showValidationErrors: false,
      isFranchiseRegionText: null,
      isFranchiseRegionOtherText: null,
      regionsRetries: 0,
      showCanvas: false,
      leadPatients: [],
      loadingPatients: false,
      savingContract: false,
      redirect: false,
      alertList:[],
      linkToDocuSign:'',
      consentUrl: '',
    }
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.prepareMeContract();
    this.showCommunicates();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.productsTimeout);
  };

  showCommunicates = () =>{
    const { LoginDS, translateKey } = this.props;
    if(LoginDS===null){
      let alertToPush = Alert;
      alertToPush.message = translateKey("summary_empty_login_ds");
      this.setState({alertList: [...this.state.alertList, alertToPush]
      })
    }
  }

  prepareMeContract() {
    const { match: { params }, appLang } = this.props;
    const { contractForm } = this.state;
    const leadId = params.id;
    const type = params.type;
    let offerOrContractId = 0;
    if (type === FormTypeToShow.CONTRACT || type === FormTypeToShow.ENQUIRY ) {
      offerOrContractId = params.offerOrContractId;
    }
    if(offerOrContractId!==0){
      this.setState({modalLoader: true})
      BBFormService.retrievePatient(contractForm.getRetiveDataALl(leadId, offerOrContractId)).then(response => {
        const { data } = response;
        data.forEach(entity => contractForm.setFields(entity.fields));
        if(appLang === LanguageSelect.DE){
            const fregField = contractForm.getFieldByKey('is_frequency_of_invoicing');
            if(fregField.value === null || fregField.value === ''){fregField.value = '717700003';}
        }
      }).then(()=>{
        this.afterContentLoadWithData();
      }).catch((error)=>{
        this.afterContentLoadWithData();
      })
    }else{
      this.afterContentLoadWithData();
    }
  }

  afterContentLoadWithData = () =>{
    this.prepareMeFranchiseRegion();
    this.prepareMeBasics();
    this.prepareMeAddition();
    this.checkIfdsTemplatesReady();
  }

  checkIfdsTemplatesReady = () => {
    const { dsTemplatesStatus, dsTemplates, getDSData } = this.props;
    if(dsTemplatesStatus !== ResponseStatus.READY){
      getDSData();
    }else{
      if(!dsTemplates || dsTemplates.length!==staticDsEnumListAmount){
        getDSData()
      }
    }
  }

  prepareMeBasics = () => {
    const { form, match: { params }, productsLoaded, secondPatientForm, isAditionalPatient} = this.props;
    const { contractForm } = this.state;
    contractForm.getFieldByKey('is_start_date').value = form.getFieldByKey('is_start_date_contact').value;
    contractForm.getFieldByKey('is_end_date').value = form.getFieldByKey('is_end_date_contact').value;
    contractForm.getFieldByKey('is_permanent_contract').value = form.getFieldByKey('is_permanent_contract_contact').value;
    contractForm.getFieldByKey('is_invoice_delivery_method_letter').value = "717700001";
    contractForm.getFieldByKey('is_invoice_delivery_method_mail').value = "717700000";
    contractForm.getFieldByKey('is_frequency_of_invoicing').value = "717700003";
    contractForm.getFieldByKey('is_rate').value = this.props.rateData;
    if(productsLoaded){
      contractForm.getFieldByKey('is_product_id').value = this.getMeProductID(true);
    }else{
      this.waitForProductsAndAssign();
    }
    const patientName = form.getFieldByKey("is_first_name_and_last_name_of_patient").value;
    const secondPatientName = secondPatientForm.getFieldByKey("is_first_name_and_last_name_of_patient").value;
    if(isAditionalPatient) {
      contractForm.getFieldByKey('skip_patient_full_name').value = `${patientName} und ${secondPatientName}`;
    } else {
      contractForm.getFieldByKey('skip_patient_full_name').value = patientName;
    }
    const offerOrContractId = params.offerOrContractId;
    if(offerOrContractId!==0 && offerOrContractId){
      this.getDataFromKundeServer(offerOrContractId);
    }else{
      this.setState({
        modalLoader: false,
        rerender: Date.now()
      });
    }
  }

  getDataFromKundeServer = (offerId) =>{
    getEnquiryOrContractData(offerId)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            const { contractForm } = this.state;
            const { data } = res.data;
            contractForm.setFieldsKunde(data);
            this.setState({
              modalLoader: false,
              rerender: Date.now()
            });
          }
        }else{
          this.setState({
            modalLoader: false,
            rerender: Date.now()
          });
          console.log("Unable to get data from DB");
        }
      }
    })
    .catch((error) => {
      this.setState({
        modalLoader: false,
        rerender: Date.now()
      });
      console.log("Unable to get data from DB");
    });
  }

  saveOfferToKundeDB = (offerId) =>{
    const { match: { params }} = this.props;
    const { contractForm } = this.state;
    const leadId = params.id;
    const patientId = params.patientId;
    let dataToSend = {
      client_id:leadId,
      patient_id: patientId,
      offer_id: offerId,
      list: contractForm.getSkippedPayload()
    }
    saveEnquiryOrContractData(dataToSend)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            console.log(res.data.data);
          }
        }else{
          console.log("Unable to save data to Kunde DB");
        }
      }
    })
    .catch((error) => {
      console.log("Unable to save data to Kunde DB");
    });

  }

  waitForProductsAndAssign = () =>{
    if(this.props.productsLoaded){
      this.state.contractForm.getFieldByKey('is_product_id').value = this.getMeProductID(true);
    }else{
      this.productsTimeout = setTimeout(() => {
        this.waitForProductsAndAssign();
      }, 750);
    }
  }

  getDataFromKundeServer = (offerId) =>{
    getEnquiryOrContractData(offerId)
    .then(res => {
      if(res){
        if(res.status===200){
          if (this._isMounted) {
            const { contractForm } = this.state;
            const { data } = res.data;
            contractForm.setFieldsKunde(data);
            this.setState({
              modalLoader: false,
              rerender: Date.now()
            });
          }
        }else{
          this.setState({
            modalLoader: false,
            rerender: Date.now()
          });
          console.log("Unable to get data from DB");
        }
      }
    })
    .catch((error) => {
      this.setState({
        modalLoader: false,
        rerender: Date.now()
      });
      console.log("Unable to get data from DB");
    });
  }

  prepareMeFranchiseRegion = () => {
    const { form } = this.props;
    const { contractForm } = this.state;
    const isDegreeRelationshipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
    if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
      contractForm.getFieldByKey('is_zip_postal_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
    } else {
      contractForm.getFieldByKey('is_zip_postal_code').value = form.getFieldByKey('is_service_address_postal_code').value;
    }
    if (this.props.regionsFP.length !== 0 && this.state.regionsRetries < 10) {
      let zipCodeValue = contractForm.getFieldByKey('is_zip_postal_code').value;
      let todayTimestamp = Math.floor(new Date().getTime() / 1000);
      let dateFilter = this.props.regionsFP.filter(element => {
        if (todayTimestamp >= element.RegionInclusionDate) {
          if (element.RegionExclusionDate !== null && element.RegionExclusionDate !== 0) {
            if (todayTimestamp <= element.RegionExclusionDate) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        } else {
          return false
        }
      });
      let isZipCodeTheSame = dateFilter.filter(element => {
        if (element.RegionPostalCodes !== null) {
          let splitArray = element.RegionPostalCodes.split(",");
          splitArray = splitArray.filter(code => {
            return code.trim() === zipCodeValue
          })
          if (splitArray.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      if (isZipCodeTheSame.length > 0) {
        if (isZipCodeTheSame[0].RegionAssignedToUser === true) {
          contractForm.getFieldByKey('is_franchise_regionid').value = isZipCodeTheSame[0].RegionId
          contractForm.getFieldByKey('is_other_region_franchiseid').value = null;
          this.setState({
            isFranchiseRegionText: isZipCodeTheSame[0].RegionName,
            isFranchiseRegionOtherText: null
          })
        } else {
          let regionsAssigned = dateFilter.filter(element => element.RegionAssignedToUser === true);
          if (regionsAssigned.length > 1) {
            regionsAssigned.sort((a, b) => a.CreatedDate > b.CreatedDate ? -1 : (a.CreatedDate < b.CreatedDate ? 1 : 0))
            contractForm.getFieldByKey('is_franchise_regionid').value = regionsAssigned[0].RegionId
            contractForm.getFieldByKey('is_other_region_franchiseid').value = isZipCodeTheSame[0].RegionId;
            this.setState({
              isFranchiseRegionText: regionsAssigned[0].RegionName,
              isFranchiseRegionOtherText: isZipCodeTheSame[0].RegionName
            })
          } else {
            if (regionsAssigned.length === 1) {
              contractForm.getFieldByKey('is_franchise_regionid').value = regionsAssigned[0].RegionId
              contractForm.getFieldByKey('is_other_region_franchiseid').value = isZipCodeTheSame[0].RegionId;
              this.setState({
                isFranchiseRegionText: regionsAssigned[0].RegionName,
                isFranchiseRegionOtherText: isZipCodeTheSame[0].RegionName
              })
            } else {
              contractForm.getFieldByKey('is_franchise_regionid').value = "";
              contractForm.getFieldByKey('is_other_region_franchiseid').value = isZipCodeTheSame[0].RegionId;
              this.setState({
                isFranchiseRegionText: "",
                isFranchiseRegionOtherText: isZipCodeTheSame[0].RegionName
              })
            }
          }
        }
      } else {
        let regionsAssigned = dateFilter.filter(element => element.RegionAssignedToUser === true);
        if (regionsAssigned.length > 1) {
          regionsAssigned.sort((a, b) => a.CreatedDate > b.CreatedDate ? -1 : (a.CreatedDate < b.CreatedDate ? 1 : 0))
          contractForm.getFieldByKey('is_franchise_regionid').value = regionsAssigned[0].RegionId
          contractForm.getFieldByKey('is_other_region_franchiseid').value = '';
          this.setState({
            isFranchiseRegionText: regionsAssigned[0].RegionName,
            isFranchiseRegionOtherText: null
          })
        } else {
          if (regionsAssigned.length === 1) {
            contractForm.getFieldByKey('is_franchise_regionid').value = regionsAssigned[0].RegionId
            contractForm.getFieldByKey('is_other_region_franchiseid').value = '';
            this.setState({
              isFranchiseRegionText: regionsAssigned[0].RegionName,
              isFranchiseRegionOtherText: null
            })
          } else {
            contractForm.getFieldByKey('is_franchise_regionid').value = '';
            contractForm.getFieldByKey('is_other_region_franchiseid').value = '';
            this.setState({
              isFranchiseRegionText: '',
              isFranchiseRegionOtherText: ''
            })
          }
        }
      }
    } else {
      setTimeout(() => {
        this.setState({ regionsRetries: this.state.regionsRetries + 1 })
        this.prepareMeFranchiseRegion();
      }, 300);
    }
  }

  prepareMeAddition = () => {
    const { selectedAdditionalOptions } = this.props;
    const { contractForm } = this.state;

    const isDrivingLicenceForm = contractForm.getFieldByKey("is_driving_licence");
    if(isDrivingLicenceForm){
      if(selectedAdditionalOptions.indexOf(ADDITIONAL_OPTIONS.OPTION_LICENSE)>-1){
        isDrivingLicenceForm.value = '717700000';
        this.assignContractDatesToFields('is_date_from_1', 'is_date_to_1', false);
      }else{
        isDrivingLicenceForm.value = '717700001';
        this.assignContractDatesToFields('is_date_from_1', 'is_date_to_1', true);
      }
    }

    const isSecondOoo = contractForm.getFieldByKey("is_second_ooo");
    if(isSecondOoo){
      if(selectedAdditionalOptions.indexOf(ADDITIONAL_OPTIONS.OPTION_2NDPAT)>-1){
        isSecondOoo.value = '717700000';
        this.assignContractDatesToFields('is_date_from_2', 'is_date_to_2', false);
      }else{
        isSecondOoo.value = '717700001';
        this.assignContractDatesToFields('is_date_from_2', 'is_date_to_2', true);
      }
    }

    const isTransfer = contractForm.getFieldByKey("is_transfer");
    if(isTransfer){
      if(selectedAdditionalOptions.indexOf(ADDITIONAL_OPTIONS.OPTION_PLUS)>-1){
        isTransfer.value = '717700000';
        this.assignContractDatesToFields('is_date_from_4', 'is_date_to_4', false);
      }else{
        isTransfer.value = '717700001';
        this.assignContractDatesToFields('is_date_from_4', 'is_date_to_4', true);
      }
    }

    const isCarerWithDiploma = contractForm.getFieldByKey("is_carer_with_diploma");
    if(isCarerWithDiploma){
      if(selectedAdditionalOptions.indexOf(ADDITIONAL_OPTIONS.OPTION_DIPLOMA)>-1){
        isCarerWithDiploma.value = '717700000';
        this.assignContractDatesToFields('is_date_from_5', 'is_date_to_5', false);
      }else{
        isCarerWithDiploma.value = '717700001';
        this.assignContractDatesToFields('is_date_from_5', 'is_date_to_5', true);
      }
    }

    const isAdditionalForeignLanguage = contractForm.getFieldByKey("is_additional_foreign_language");
    if(isAdditionalForeignLanguage){
      if(selectedAdditionalOptions.indexOf(ADDITIONAL_OPTIONS.OPTION_2NDLANG)>-1){
        isAdditionalForeignLanguage.value = '717700000';
        this.assignContractDatesToFields('is_date_from_3', 'is_date_to_3', false);
      }else{
        isAdditionalForeignLanguage.value = '717700001';
        this.assignContractDatesToFields('is_date_from_3', 'is_date_to_3', true);
      }
    }

    const isAdditionalPersonInHouse = contractForm.getFieldByKey("is_additional_person_in_house");
    if(isAdditionalPersonInHouse){
      if(selectedAdditionalOptions.indexOf(ADDITIONAL_OPTIONS.OPTION_ADDPERSON)>-1){
        isAdditionalPersonInHouse.value = '717700000';
        this.assignContractDatesToFields('is_date_from_6', 'is_date_to_6', false);
      }else{
        isAdditionalPersonInHouse.value = '717700001';
        this.assignContractDatesToFields('is_date_from_6', 'is_date_to_6', true);
      }
    }
  }

  assignContractDatesToFields=(firstKey, lastKey, emptyFields = false)=>{
    const { contractForm } = this.state;
    const beginAdditionlDate = contractForm.getFieldByKey(firstKey);
    const endAdditionlDate = contractForm.getFieldByKey(lastKey);
    if(beginAdditionlDate){
      if(emptyFields){
        beginAdditionlDate.value = null;
      }else{
        const startD = contractForm.getFieldByKey(startDayKey);
        if(startD){
          beginAdditionlDate.value = startD.value;
        }
      }
    }
    if(endAdditionlDate){
      if(emptyFields){
        endAdditionlDate.value = null;
      }else{
        const endD = contractForm.getFieldByKey(endDateKey);
        if(endD){
          endAdditionlDate.value = endD.value
        }
      }
    }
  }

  getMeProductID = (withError=false) => {
    const { products, availableLanguagePackets, selectedLanguagePacket, translateKey } = this.props;
    let selectedProduct = availableLanguagePackets.find(item => item.id === selectedLanguagePacket);
    if(selectedProduct.label_crm){
      let finalProduct = products.find(item => item.ProductName === selectedProduct.label_crm);
      if (finalProduct) {
        return finalProduct.ProductId;
      } else {
        if(withError){
          this.addProductErrorToList(translateKey("summary_empty_product_id"))
        }
        return 0;
      }
    }else{
      if(withError){
        this.addProductErrorToList(translateKey("summary_empty_product_id"))
      }
      return 0;
    }
  }

  addProductErrorToList(message){
    let alertToPush = Alert;
    alertToPush.message = message;
    this.setState({alertList: [...this.state.alertList, alertToPush]})
  }

  nextPageClick = () => {
    const { contractForm, activeSection } = this.state;
    switch (activeSection) {
      case 'section-die-bestellung-1':
        const sectionFrist = contractForm.getSectionByOrder(0);
        if (sectionFrist.isValid) {
          this.setState({
            activeSection: 'section-die-bestellung-2'
          })
        } else {
          this.setState({
            showValidationErrors: true
          })
        }
        break;
      case 'section-die-bestellung-2':
        const sectionSecond = contractForm.getSectionByOrder(1);
        if (sectionSecond.isValid) {
          this.setState({
            activeSection: 'section-die-bestellung-3'
          })
        } else {
          this.setState({
            showValidationErrors: true
          })
        }
        break;
      default:
    }
  }

  prevPageClick = () => {
    const { activeSection } = this.state;
    switch (activeSection) {
      case 'section-die-bestellung-2': this.setState({
        activeSection: 'section-die-bestellung-1'
      })
        break;
      case 'section-die-bestellung-3': this.setState({
        activeSection: 'section-die-bestellung-2'
      })
        break;
      default:
    }
  }

  saveToDocuSign = async () => {
    const { form, match: { params } } = this.props;
    if(this.state.savingContract!==true){
      this.setState({
        modalLoader: true,
        savingContract: true
      });
      await this.saveContract();
      await BBFormService.savePatient(form.changeLeadStatusAfterEndOfForm(params.id))
    }
  }

  saveDocuSignContract = (contractID) => {
    const { translateKey, LoginDS, authLogin } = this.props;
    if (contractID) {
      if(LoginDS){
        this.finalDocuSignCall(contractID);
      }else{
        const rsaToken = localStorage.getItem('ap:rsaToken');
        const login = localStorage.getItem('ap:appLogin');
        authLogin(login, rsaToken)
          .then(res => {
            this.finalDocuSignCall(contractID);
          })
          .catch((err)=>{
            if(this._isMounted) {
              this.setState({
                modalLoader: false,
                showMessage: true,
                errorClass: 'alert alert-danger',
                message: translateKey("basic_error_message"),
              })
            }
          })
      }
    } else {
      const { fpName, LoginDS } = this.props;
      const errorContent = {
        fpName: fpName,
        loginDS: LoginDS,
        page: "CONTRAT DOCUSIGN",
        api: "WRONG CONTRACT ID. NOT SET",
        content: '',
        error: "WRONG CONTRACT ID. NOT SET"
      }
      sendErrorLogToBackend(errorContent).then(res => {console.log(res)}).catch((error) => {console.log(error);});
      this.setState({
        modalLoader: false,
        showMessage: true,
        message: translateKey("basic_error_message"),
      })
    }
  }

  finalDocuSignCall = (contractID) =>{
    const { translateKey, fpName, fpFirstMail, LoginDS, clientLeadBaseData, dsTemplates, form, isAditionalPatient, secondPatientForm} = this.props;
    const { contractForm } = this.state;
    const isSkipPcmNameContract = contractForm.getFieldByKey('skip_pcm_name_contract');
    const isSkipPcmEmailContract = contractForm.getFieldByKey('skip_pcm_email_contract');
    const isSkipCityDocusign = contractForm.getFieldByKey('skip_is_city_docusign');
    const isSkipDlvDocusign = contractForm.getFieldByKey('skip_is_dlv_docusign');
    const signatureMethodDocusign = contractForm.getFieldByKey('signature_method_docusign').value;
    const isSpecialArrangements = contractForm.getFieldByKey('is_special_arrangements').value;

    const pilotIdList = [
      // '3cbc56e9-3dd9-e911-80d3-00155d140351', //api-test
      '993243A7-FFA3-EA11-80D8-00155D2D0C70',
      'EC4C4B09-625C-EA11-80D6-00155D2D0E4B',
      'BD66E032-6D82-EB11-80E4-00155D0BA10F',
      '59AC6E26-D9E1-E811-80CD-00155D13F319',
      '79CB104F-9B0E-E911-80CC-00155D13F31A',
      '97985EEF-7B01-EB11-80D9-00155D0BAC06',
      '1BD3C2D3-8309-EC11-80E8-00155D0BAC07',
      '6C01B37F-62F3-EB11-80E9-00155D0BA10F',
      'C29E953E-8DEF-EB11-80E9-00155D0BA10F',
      '1F9BCC1D-8F4E-EB11-80E4-00155D0BA10F',
      '4A8EF3CD-B75E-EA11-80D6-00155D2D0E4B',
      '04BBE37F-308E-EB11-80E4-00155D0BA10F',


      '993243a7-ffa3-ea11-80d8-00155d2d0c70',
      'ec4c4b09-625c-ea11-80d6-00155d2d0e4b',
      'bd66e032-6d82-eb11-80e4-00155d0ba10f',
      '59ac6e26-d9e1-e811-80cd-00155d13f319',
      '79cb104f-9b0e-e911-80cc-00155d13f31a',
      '97985eef-7b01-eb11-80d9-00155d0bac06',
      '1bd3c2d3-8309-ec11-80e8-00155d0bac07',
      '6c01b37f-62f3-eb11-80e9-00155d0ba10f',
      'c29e953e-8def-eb11-80e9-00155d0ba10f',
      '1f9bcc1d-8f4e-eb11-80e4-00155d0ba10f',
      '4a8ef3cd-b75e-ea11-80d6-00155d2d0e4b',
      '04bbe37f-308e-eb11-80e4-00155d0ba10f',
    ];

    let filterDsTemlates = dsTemplates;
    let DocuSignTemplateId = "";
    let signatureMethod = "";
    let isPilotUser = 'False';

    if(dsTemplates) {
      filterDsTemlates = filterDsTemlates.filter((item) => {
        if(pilotIdList.includes(this.props.userId)) {
          return item[0].FieldValue === 'True' && item[2].FieldValue === signatureMethodDocusign;
        } else {
          return item[0].FieldValue === 'False' && item[2].FieldValue === signatureMethodDocusign;
        }
      });

      filterDsTemlates = filterDsTemlates.filter((item) => {
        if(isSpecialArrangements==='717700014') {
          if(isSkipDlvDocusign.value==='717700000') {
            return item[1].FieldValue === 'Zugunsten Express';
          } else {
            return item[1].FieldValue === 'Selbst Express';
          }
        } else {
          if (isSkipDlvDocusign.value==='717700000') {
            return item[1].FieldValue === 'Zugunsten';
          } else if (isSkipDlvDocusign.value==='717700001') {
            return item[1].FieldValue === 'Bevollmacht';
          } else {
            return item[1].FieldValue === 'Selbst';
          }
        }
      });

      DocuSignTemplateId = filterDsTemlates[0][3].FieldValue;
      signatureMethod = filterDsTemlates[0][2].FieldValue;
      isPilotUser = filterDsTemlates[0][0].FieldValue;
    }
    // if(isSkipDlvDocusign.value==='717700000'){
    //   if(dsTemplates){
    //     if(dsTemplates.length>0){
    //       if(dsTemplates[0].length>1){
    //         if(dsTemplates[0][1].FieldValue){
    //           DocuSignTemplateId = dsTemplates[0][1].FieldValue ;
    //         }
    //       }
    //     }
    //   }
    // }
    // if(isSkipDlvDocusign.value==='717700001'){
    //   if(dsTemplates){
    //     if(dsTemplates.length>1){
    //       if(dsTemplates[1].length>1){
    //         if(dsTemplates[1][1].FieldValue){
    //           DocuSignTemplateId = dsTemplates[1][1].FieldValue;
    //         }
    //       }
    //     }
    //   }
    // }
    // if(isSkipDlvDocusign.value==='717700002'){
    //   if(dsTemplates){
    //     if(dsTemplates.length>1){
    //       if( dsTemplates[2].length>1){
    //         if(dsTemplates[2][1].FieldValue){
    //           DocuSignTemplateId = dsTemplates[2][1].FieldValue;
    //         }
    //       }
    //     }
    //   }
    // }
    let specialIllness = null;
    const additionalData = []
    const isDekubitus = form.getFieldByKey('is_bedsores_list');
    const isCancer = form.getFieldByKey('is_cancer_list');
    const isPalliative = form.getFieldByKey('is_palliative_care');
    if(isDekubitus.value || isCancer.value || isPalliative.value){
      specialIllness = {
        "DSName":"NoteTumorDekuPall",
        "DSValue":"1"
      }
    }

    if (isAditionalPatient) {
      const patientName = form.getFieldByKey("is_first_name_and_last_name_of_patient").value;
      const secondPatientName = secondPatientForm.getFieldByKey("is_first_name_and_last_name_of_patient").value;
      additionalData.push({
        "DSName":"is_first_name_pod",
        "DSValue":`${patientName}`
      });
      additionalData.push({
        "DSName":"is_last_name_pod",
        "DSValue":`und ${secondPatientName}`
      });
    }
    let dataToSend = {
      "ContractId": contractID,
      "DocuSignTemplateId": DocuSignTemplateId,
      "ContactFpName": fpName,
      "ContactFpFirstEmail": fpFirstMail,
      "ClientName": this.resolveName(clientLeadBaseData),
      "ClientEmail": this.resolveEmail(form, contractForm, clientLeadBaseData),
      "ClientPostalCode": this.resolvePostalCode(form, clientLeadBaseData),
      "PCM": isSkipPcmNameContract.value,
      "PCMEmail": isSkipPcmEmailContract.value,
      "City": isSkipCityDocusign.value,
      "LoginDS": LoginDS,
      "InlineDataItems": specialIllness,
      "AditionalData": additionalData,
      "Rate" : this.props.rateData
    }
    sendDocuSign(dataToSend, signatureMethod, isPilotUser)
    .then(res => {
      if(res.status===200){
        this.changeLeadStatus(res.data);
      } else if (res.status===403) {
        if (this._isMounted) {
          this.setState({
            modalLoader: false,
            showMessage: true,
            message: translateKey("text-failed-docusign"),
            consentUrl: res.data.ConsentUrl,
          })
        }
      } else{
        if (this._isMounted) {
          this.setState({
            modalLoader: false,
            showMessage: true,
            message: translateKey("text-failed-docusign"),
          })
        }
      }
    })
    .catch((error) => {
      console.log(error);
      if (this._isMounted) {
        this.setState({
          modalLoader: false,
          showMessage: true,
          message: translateKey("text-failed-docusign"),
        })
      }
    });
  }

    resolvePostalCode(form, clientLeadBaseData) {
        const postalCode = form.getFieldsByEntityAndKey(Entity.CONTACT, "is_correspondence_zip_postal_code").value;
        if (clientLeadBaseData.client.address !== undefined
            && this.isFilled(clientLeadBaseData.client.address.postalCode)) {
            return clientLeadBaseData.client.address.postalCode;
        } else if (clientLeadBaseData.correspondenceAddress !== undefined
            && this.isFilled(clientLeadBaseData.correspondenceAddress.postalCode)) {
            return clientLeadBaseData.correspondenceAddress.postalCode;
        } else if (this.isFilled(postalCode)) {
            return postalCode;
        } else {
            return null;
        }
    }

    resolveName(clientLeadBaseData) {
        return clientLeadBaseData.client.firstName !== undefined ?
            clientLeadBaseData.client.firstName + " " + clientLeadBaseData.client.lastName :
            clientLeadBaseData.firstName + " " + clientLeadBaseData.lastName
    }

    resolveEmail(form, contractForm, clientLeadBaseData) {
        const clientEmail = form.getFieldsByEntityAndKey(Entity.CONTACT, "is_email").value;
        if (clientLeadBaseData.client !== undefined && this.isFilled(clientLeadBaseData.client.email)) {
            return clientLeadBaseData.client.email;
        } else if (this.isFilled(clientLeadBaseData.email)) {
            return clientLeadBaseData.email;
        } else if (this.isFilled(clientEmail)) {
            return clientEmail
        } else {
           return contractForm.getFieldByKey('is_payer_email').value;
        }
    }

    isFilled(field) {
      return field !== undefined && field !== null && field.length > 0;
    }

  changeLeadStatus=(docuSignId)=>{
    const { match: { params }, translateKey, appLang, fpName, LoginDS, getPatientLeadData, getPatientClientData, removeLead} = this.props;
    const { contractForm } = this.state;
    const saveSNewtatus = contractForm.changeLeadStatusAfterNewContractCreated(params.id);
    BBFormService.savePatient(saveSNewtatus).then(response => {
      getPatientLeadData(appLang);
      getPatientClientData(appLang);
      removeLead(params.id);
      if (this._isMounted) {
        this.setState({
          modalLoader: false,
          savingContract: false,
          showMessage: true,
          redirect:true,
          linkToDocuSign: baseData.base_docuSign+docuSignId,
          message: `${translateKey("text_success_docusign_part_1")}<br/>${translateKey("text_success_docusign_part_2")}<br/>${translateKey("text_success_docusign_part_3")}`,
        })
      }
    }).catch(error => {
      const errorContent = {
        fpName: fpName,
        loginDS: LoginDS,
        page: "CONTRAT CHANGE STATUS",
        api: "/dynamic/send",
        content: saveSNewtatus,
        error: error.code
      }
      sendErrorLogToBackend(errorContent).then(res => {console.log(res)}).catch((error) => {console.log(error);});
      this.setState({
        modalLoader: false,
        savingContract: false,
        showMessage: true,
        message: translateKey("offert-lead-status-change-failed"),
      })
      return false;
    });
  }

  assignPatient=(newofferID)=>{
    const { match: { params } } = this.props;
    let prepareMePatientToAssign = {
      "ContractId": newofferID,
      "Patients": [params.patientId]
    }
    BBFormService.assignPatients(prepareMePatientToAssign).then(() => {
      this.saveDocuSignContract(newofferID);
    }).catch(error => {
      const { fpName, LoginDS } = this.props;
      const errorContent = {
        fpName: fpName,
        loginDS: LoginDS,
        page: "CONTRAT ASSIGN PATIENTS",
        api: "contracts/assignPatients",
        content: prepareMePatientToAssign,
        error: error
      }
      sendErrorLogToBackend(errorContent).then(res => {console.log(res)}).catch((error2) => {console.log(error2);});
        this.setState({
          modalLoader: false,
          savingContract: false,
          showMessage: true,
          message: translateKey("form-error-data-saved-failed"),
        })
        console.log(error);
    });
  }

  saveContract = async () => {
    const { coopId, form, match: { params }, translateKey } = this.props;
    const { contractForm } = this.state;
    contractForm.getFieldByKey('is_contact_patientID').value = params.id;
    contractForm.getFieldByKey('is_primary_patientid').value = params.patientId;
    contractForm.getFieldByKey('is_cooperation_condition_id').value = coopId;
    contractForm.getFieldByKey('is_inquiry_based_contract').value = "true";
    const isDegreeRelationshipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
    if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
      contractForm.getFieldByKey('is_identical_patient_data').value = '717700001';
      contractForm.getFieldByKey('is_first_name').value = form.getFieldByKey('firstname').value;
      contractForm.getFieldByKey('is_last_name').value = form.getFieldByKey('lastname').value;
      contractForm.getFieldByKey('is_street').value = form.getFieldByKey('is_registered_street').value;
      contractForm.getFieldByKey('is_house_number').value = form.getFieldByKey('is_registered_street_number').value;
      contractForm.getFieldByKey('is_appartment_number').value = form.getFieldByKey('is_registered_house_number').value;
      contractForm.getFieldByKey('is_zip_postal_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
      contractForm.getFieldByKey('is_city').value = form.getFieldByKey('is_registered_city').value;
    } else {
      contractForm.getFieldByKey('is_identical_patient_data').value = '717700000';
      contractForm.getFieldByKey('is_first_name').value = form.getFieldByKey('is_patient_first_name').value;
      contractForm.getFieldByKey('is_last_name').value = form.getFieldByKey('is_patient_last_name').value;
      contractForm.getFieldByKey('is_street').value = form.getFieldByKey('is_service_address_street').value;
      contractForm.getFieldByKey('is_house_number').value = form.getFieldByKey('is_service_address_street_number').value;
      contractForm.getFieldByKey('is_appartment_number').value = form.getFieldByKey('is_service_address_house_number').value;
      contractForm.getFieldByKey('is_zip_postal_code').value = form.getFieldByKey('is_service_address_postal_code').value;
      contractForm.getFieldByKey('is_city').value = form.getFieldByKey('is_service_address_city').value;
    }

    const payerData = contractForm.getFieldByKey('is_identical_payer_data').value
    if (payerData === '717700002') {
      contractForm.getFieldByKey('is_payer_first_name').value = form.getFieldByKey('firstname').value;
      contractForm.getFieldByKey('is_payer_last_name').value = form.getFieldByKey('lastname').value;
      contractForm.getFieldByKey('is_payer_registered_street').value = form.getFieldByKey('is_registered_street').value;
      contractForm.getFieldByKey('is_payer_registered_house_number').value = form.getFieldByKey('is_registered_street_number').value;
      contractForm.getFieldByKey('is_payer_registered_appartment_number').value = form.getFieldByKey('is_registered_house_number').value;
      contractForm.getFieldByKey('is_payer_registered_zip_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
      contractForm.getFieldByKey('is_payer_registered_city').value = form.getFieldByKey('is_registered_city').value;
      contractForm.getFieldByKey('is_payer_mobile_phone').value = form.getFieldByKey('is_mobile_phone').value;
    } else {
      if (payerData === '717700001') {
        contractForm.getFieldByKey('is_payer_first_name').value = form.getFieldByKey('is_patient_first_name').value;
        contractForm.getFieldByKey('is_payer_last_name').value = form.getFieldByKey('is_patient_last_name').value;
        contractForm.getFieldByKey('is_payer_registered_street').value = form.getFieldByKey('is_service_address_street').value;
        contractForm.getFieldByKey('is_payer_registered_house_number').value = form.getFieldByKey('is_service_address_street_number').value;
        contractForm.getFieldByKey('is_payer_registered_appartment_number').value = form.getFieldByKey('is_service_address_house_number').value;
        contractForm.getFieldByKey('is_payer_registered_zip_code').value = form.getFieldByKey('is_service_address_postal_code').value;
        contractForm.getFieldByKey('is_payer_registered_city').value = form.getFieldByKey('is_service_address_city').value;
      }
    }

    const InvoiceDeliveryData = contractForm.getFieldByKey('is_identical_invoice_delivery_address').value
    if (InvoiceDeliveryData === '717700000') {
      contractForm.getFieldByKey('is_payer_correspondence_first_name').value = form.getFieldByKey('firstname').value;
      contractForm.getFieldByKey('is_payer_correspondence_last_name').value = form.getFieldByKey('lastname').value;
      contractForm.getFieldByKey('is_payer_correspondence_street').value = form.getFieldByKey('is_registered_street').value;
      contractForm.getFieldByKey('is_payer_correspondence_house_number').value = form.getFieldByKey('is_registered_street_number').value;
      contractForm.getFieldByKey('is_payer_correspondance_appartment_number').value = form.getFieldByKey('is_registered_house_number').value;
      contractForm.getFieldByKey('is_payer_correspondence_zip_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
      contractForm.getFieldByKey('is_payer_correspondence_city').value = form.getFieldByKey('is_registered_city').value;
    } else {
      if (InvoiceDeliveryData === '717700001') {
        contractForm.getFieldByKey('is_payer_correspondence_first_name').value = contractForm.getFieldByKey('is_payer_first_name').value;
        contractForm.getFieldByKey('is_payer_correspondence_last_name').value = contractForm.getFieldByKey('is_payer_last_name').value;
        contractForm.getFieldByKey('is_payer_correspondence_street').value = contractForm.getFieldByKey('is_payer_registered_street').value;
        contractForm.getFieldByKey('is_payer_correspondence_house_number').value = contractForm.getFieldByKey('is_payer_registered_house_number').value;
        contractForm.getFieldByKey('is_payer_correspondance_appartment_number').value = contractForm.getFieldByKey('is_payer_registered_appartment_number').value;
        contractForm.getFieldByKey('is_payer_correspondence_zip_code').value = contractForm.getFieldByKey('is_payer_registered_zip_code').value;
        contractForm.getFieldByKey('is_payer_correspondence_city').value = contractForm.getFieldByKey('is_payer_registered_city').value;
      }
    }
    if (contractForm.isValid) {
      const contractFromtoSendData = contractForm.getPayload(params.id, params.offerOrContractId?params.offerOrContractId:0);
      BBFormService.savePatient(contractFromtoSendData).then(response => {
        const { data } = response;
        let newofferID = 0;
        data.forEach(element => { if (element.entity === Entity.CONTRACT) { newofferID = element.id } });
        this.assignPatient(newofferID);
        // this.saveOfferToKundeDB(newofferID);

        const date = new Date();
        const note = `DLV am ${date.getDate() < 10 ? '0' : ''}${date.getDate()}.${date.getMonth()+1 < 10 ? '0' : ''}${date.getMonth()+1}.${date.getFullYear()} abgeschlossen/verschickt`;
        this.props.addNote({Note: note}, this.props.clientLeadBaseData.leadId, "contact")
        .catch((err)=>{
            console.log(err);
        });
        return newofferID;
      }).catch(error => {
        const { fpName, LoginDS } = this.props;
        const errorContent = {
          fpName: fpName,
          loginDS: LoginDS,
          page: "CONTRAT SAVE",
          api: "/dynamic/send",
          content: contractFromtoSendData,
          error: error.code
        }
        sendErrorLogToBackend(errorContent).then(res => {console.log(res)}).catch((error) => {console.log(error);});
        this.setState({
          modalLoader: false,
          savingContract: false,
          showMessage: true,
          message: translateKey("basic_error_message"),
        })
        return false;
      });
    } else {
      this.setState({
        modalLoader: false,
        showMessage: true,
        savingContract: false,
        message: translateKey("basic_error_message"),
      })
      return false;
    }
  };

  renderProductItem = (label) => {
    return (
      <div className="product-item">
        {label}
      </div>
    )
  }

  hideInfo = () => {
    if(this.state.redirect){
      this.props.history.push(`${baseData.subFolders}leads`);
    }else{
      this.setState({
        modalLoader: false,
        errorClass:'',
        showMessage: false,
        message: '',
      })
    }
  }

  hideInfoList = () => {
    const {alertList} = this.state;
    if(alertList.length>1){
      alertList.shift();
      this.setState({
        alertList: alertList
      })
    }else{
      this.setState({
        alertList: []
      })
    }
  }

  render() {
    const {
      translateKey,
      form,
      selectedLanguagePacket,
      selectedAdditionalOptions,
      availableAdditionalOptions,
      availableLanguagePackets,
      clientLeadBaseData,
      isAditionalPatient
    } = this.props;
  
    const {
      activeSection,
      contractForm,
      showValidationErrors,
      isFranchiseRegionText,
      isFranchiseRegionOtherText,
      rerender,
      modalLoader,
      alertList,
      showMessage,
      errorClass,
      message,
      linkToDocuSign,
      consentUrl
    } = this.state;

    let content = '';
    if (activeSection === "section-die-bestellung-1") {
      content = (
        <SectionDieBestellungFirst
          rerender = {rerender}
          form = {form}
          contractForm={contractForm}
          showValidationErrors={showValidationErrors}
          selectedLanguagePacket={selectedLanguagePacket}
          selectedAdditionalOptions={selectedAdditionalOptions}
          availableLanguagePackets={availableLanguagePackets}
          availableAdditionalOptions={availableAdditionalOptions}
          isFranchiseRegionText={isFranchiseRegionText}
          isFranchiseRegionOtherText={isFranchiseRegionOtherText}
          updateContractDateOptions={this.prepareMeAddition}
          isAditionalPatient={isAditionalPatient}
        />
      )
    }

    if (activeSection === "section-die-bestellung-2") {
      content = (
        <SectionDieBestellungSecond
          contractForm={contractForm}
          showValidationErrors={showValidationErrors}
          form={form}
          clientLeadBaseData={clientLeadBaseData}
        />
      )
    }

    if (activeSection === "section-die-bestellung-3") {
      content = (
        <div className="die-bestellung-subtitle text-align-center w-100" style={{margin: "0 auto"}}>
          {translateKey('text-final-docusign')}
        </div>
      )
    }

    return (
      <div className="form-section-main w-100">
        <LoaderModal show={modalLoader} text={''} />
        <InfoModal okMe={this.hideInfo} show={showMessage} linkToDocuSign={linkToDocuSign} linkMessage={translateKey("docu_sign_success_button_link")} messageClass={errorClass} titleMessage="" mainMessage={message} okMessage={linkToDocuSign?translateKey("docu_sign_success_button_ok"):translateKey("Ok")} consentUrl={consentUrl}  consentUrlMessage={translateKey("docu_sign_consent_url_message")}/>
        <InfoModal key="new-info-modal-list" okMe={this.hideInfoList} show={alertList.length>0} titleMessage="" mainMessage={alertList.length>0?alertList[0].message:''} okMessage={translateKey("Ok")} />
        <div className="new-section-name-wrapper">
          {translateKey('die-bestellung-title')}
        </div>
        {content}
        <div className="zone-form-navigation-buttons">
          <div className="previous-next-wrapper">
            {activeSection !== 'section-die-bestellung-1' &&
              <button
                type="button"
                className="btn btn-primary cursor-pointer next-previous-class prev-next-normal-button"
                onClick={() => this.prevPageClick()}
              >
                <i className="fas fa-chevron-left fa-1x section-navigation-icon"></i>
                <div className="section-navigation-button-text">{translateKey("previous").toUpperCase()}</div>
              </button>
            }
            {activeSection !== 'section-die-bestellung-3' &&
              <button
                type="button"
                className="btn btn-primary cursor-pointer next-previous-class prev-next-active-button"
                onClick={() => this.nextPageClick()}>
                <div className="section-navigation-button-text">{translateKey("next").toUpperCase()}</div>
                <i className="fas fa-chevron-right fa-1x section-navigation-icon"></i>
              </button>
            }
            {activeSection === 'section-die-bestellung-3' &&
              <button
                type="button"
                className="btn btn-primary cursor-pointer text-align-center next-previous-class w-auto prev-next-active-button save"
                onClick={() => this.saveToDocuSign()}
              >
                <div className="section-navigation-button-text w-auto">{translateKey("die-bestellung-form-vertrag-unterzeichnen")}</div>
                <i className="fas fa-chevron-right fa-1x section-navigation-icon"></i>
              </button>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    coopId: state.auth.CooperationId,
    fpFirstMail: state.auth.contactFpFirstEmail,
    fpName: state.auth.contactFpName,
    LoginDS: state.auth.LoginDS,
    dsTemplates: state.enums.dsTemplates,
    dsTemplatesStatus: state.enums.dsTemplatesStatus,
    patientLeadData: state.database.patientLead,
    userId: state.auth.userId,
    rateData: state.enums.rateData,
    rateDataStatus: state.enums.rateDataStatus,
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
  getPatientClientData: (lang) => dispatch(getPatientClient(lang)),
  removeLead: (id) => dispatch(removeOneLeadData(id)),
  getDSData: () => dispatch(getDSTemplatesData()),
  authLogin: (login, rsapassword) => dispatch(auth(login, rsapassword)),
  addNote: (note, userid, type) => dispatch(addNoteData(note, userid, type)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionDieBestellung)); 