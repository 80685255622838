import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../../_redux/actions/index";
import { CheckBoxComponent } from "../../form-bb-components/checkbox-component/checkbox-component";
import { RadioButtonsComponent } from "../../form-bb-components/radio-button-component/radio-button-component";
import SelectUniversalComponent from "../../select-universal-component/SelectUniversalComponent";
import { AutoSuggestComponent } from "../../../_components/autosuggest-component/AutoSuggestComponent";
import { InputTypeTextComponent } from "../../form-bb-components/input-type-text-component/input-type-text-component";
import produce from "immer";
import DatePickerMeetingComponent from "../date-picker-for-meeting/datePickerMeetingComponent";
import TimePickerMeetingComponent from "../date-picker-for-meeting/TimePickerMeetingComponent";
import { Base64 } from "../../../_helpers/base64Coder";
import { baseData } from '../../../_constance/base_data';
import Separator from '../../style-helpers/separator';
import InfoMessage from '../../style-helpers/infoMessage';
import styled from 'styled-components';
import * as TaskType from "../../../_constance/values/taskType";
import * as AddressType from "../../../_constance/values/addressType";

class MeetingSection1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crateLinnkFlag: true,
      cratePlaceFlag: true,
      arrangeForm: props.arrangeForm,
      section: props.arrangeForm.getSectionByKey("section-meeting"),
      changeAddressData: false,
    };
  }
  componentDidUpdate() {
    if(this.state.arrangeForm.getFieldByKey("meeting_email").value && this.state.crateLinnkFlag){
      this.crateLink();
      this.setState({
        crateLinnkFlag: false
      });
    }

    if(this.props.leadData.client && this.state.cratePlaceFlag){
      const address = this.props.leadData.client.address;
      this.setTypeChange('meeting_strasse', address.street);
      this.setTypeChange('meeting_strasse_nr', address.buildingNumber);
      this.setTypeChange('meeting_wohungsnummer', address.apartmentNumber);
      this.setTypeChange('meeting_plz', address.postalCode);
      this.setTypeChange('meeting_stadt', address.city);
      this.setState({
        cratePlaceFlag: false
      });
    }
  }

  setTypeChange = (key, value) => {
    const nextState = produce(this.state, (draftState) => {
      const { section } = draftState;
      const field = section.getFieldByKey(key);
      if (field) {
        field.value = value;
      }
    });
    this.setState(nextState);
  };

  changeDateTime = (dateValue) => {
    this.setTypeChange('meeting_timestamp', new Date(dateValue));
  }

  nextPageClick= () =>{
    this.props.nextPageClick();
  }

  setDocumentStatus = meetingDocuments => {
    const { section } = this.state;
    const meetingFugtDokumenteHinzu = section.getFieldByKey('meeting_fugt_dokumente_hinzu');

    meetingDocuments.forEach((item) => {
      !meetingFugtDokumenteHinzu.value ? item.value = true : item.value = false
    });

    this.setTypeChange('meeting_fugt_dokumente_hinzu', !meetingFugtDokumenteHinzu.value);
  }

  crateLink = () => {
    const meetingEmail = this.state.arrangeForm.getFieldByKey("meeting_email");
    let encodeValueEmail='';

    if(meetingEmail.value){
      encodeValueEmail = Base64.encode(meetingEmail.value)
    }
    const hrefToWebsite = encodeURI(`${baseData.base_kunde}?mail=${encodeValueEmail}`);
    this.setTypeChange('meeting_kundenbereich_link', hrefToWebsite);
  }

  handleRdioButtonChange = (key, value)  => {
    this.setTypeChange(key, value);
    this.setDefaultMeetingPlace();
  }

  handleChangeMeetingPlace = (key, value) => {
    this.setTypeChange('meeting_place_type', value);
    this.setDefaultMeetingPlace();
  }

  setDefaultMeetingPlace = () => {
    const meetingPlaceType = this.state.section.getFieldByKey("meeting_place_type");
    const { leadData } = this.props;

    switch (meetingPlaceType.value){
      case AddressType.CLIENT: 
        this.setTypeChange('meeting_strasse', leadData.client.address.street);
        this.setTypeChange('meeting_strasse_nr', leadData.client.address.buildingNumber);
        this.setTypeChange('meeting_wohungsnummer', leadData.client.address.apartmentNumber);
        this.setTypeChange('meeting_plz', leadData.client.address.postalCode);
        this.setTypeChange('meeting_stadt', leadData.client.address.city);
        break;
      case AddressType.PATIENT: 
        this.setTypeChange('meeting_strasse', leadData.patient.address.street);
        this.setTypeChange('meeting_strasse_nr', leadData.patient.address.buildingNumber);
        this.setTypeChange('meeting_wohungsnummer', leadData.patient.address.apartmentNumber);
        this.setTypeChange('meeting_plz', leadData.patient.address.postalCode);
        this.setTypeChange('meeting_stadt', leadData.patient.address.city);
        break;
      default:
        this.setTypeChange('meeting_strasse', '');
        this.setTypeChange('meeting_strasse_nr', '');
        this.setTypeChange('meeting_wohungsnummer', '');
        this.setTypeChange('meeting_plz', '');
        this.setTypeChange('meeting_stadt', '');
    }
  }

  compareData = () => {
    const { section } = this.state;
    const { leadData } = this.props;
    const meetingPlaceType = section.getFieldByKey("meeting_place_type");
    const meetingStrasse = section.getFieldByKey("meeting_strasse");
    const meetingStrasseNr = section.getFieldByKey("meeting_strasse_nr");
    const meetingWohungsnummer = section.getFieldByKey("meeting_wohungsnummer");
    const meetingPlz = section.getFieldByKey("meeting_plz");

    switch (meetingPlaceType.value){
      case AddressType.CLIENT: 
        if(
          meetingStrasse.value !== leadData.client.address.street ||
          meetingStrasseNr.value !== leadData.client.address.buildingNumber ||
          meetingWohungsnummer.value !== leadData.client.address.apartmentNumber ||
          meetingPlz.value !== leadData.client.address.postalCode
          ) {
            this.setState({
              changeAddressData: true,
            });
        } else {
          this.setState({
            changeAddressData: false,
          });
        }
        break;
      case AddressType.PATIENT: 
        if(
          meetingStrasse.value !== leadData.patient.address.street ||
          meetingStrasseNr.value !== leadData.patient.address.buildingNumber ||
          meetingWohungsnummer.value !== leadData.patient.address.apartmentNumber ||
          meetingPlz.value !== leadData.patient.address.postalCode
          ) {
            this.setState({
              changeAddressData: true,
            });
        } else {
          this.setState({
            changeAddressData: false,
          });
        }
        break;
      default:
        this.setState({
          changeAddressData: false,
        });
    }
  }

  setAdress = (key, value) => {
    this.setTypeChange(key, value);
    this.compareData();
  }

  prepareMeSelect = () =>{
    if(this.props.cities.length>0){
        return this.props.cities.map(x => ({
            value: x.PostalCode!==null?x.PostalCode:'', 
            city:  x.City!==null?x.City:'',
            label:  x.City!==null?x.PostalCode+"-"+x.City:''
        }));
    }else{
        return [];
    }
  }

  handlechangePostalCode = event => {
    if(typeof event === 'object'){
      this.setTypeChange('meeting_plz', event.value);
      this.setTypeChange('meeting_stadt', event.city);
    }else{
      this.setTypeChange('meeting_plz', event);
    }
    this.compareData();
  }

  render() {
    const { translateKey, title } = this.props;
    const { section, changeAddressData } = this.state;
    const isNewUI = true;
    const checboxClass = "col-sm-12 col-lg-12";
    const classForCheckBox = "newUI-radio-check-box-class";
    const showValidation = false;
    const meetingFirstName = section.getFieldByKey("meeting_first_name");
    const meetingLastName = section.getFieldByKey("meeting_last_name");
    const meetingEmail = section.getFieldByKey("meeting_email");
    const meetingTimestamp = section.getFieldByKey("meeting_timestamp");
    const meetingNurDieMaterialen = section.getFieldByKey("meeting_nur_die_materialen");
    const meetingArtDesGesprachs = section.getFieldByKey("meeting_art_des_gesprachs");
    const meetingFugenSieDenLink = section.getFieldByKey('meeting_fugen_sie_den_link');
    const meetingKundenbereichLink = section.getFieldByKey('meeting_kundenbereich_link');
    //address
    const meetingPlaceType = section.getFieldByKey("meeting_place_type");
    const meetingStrasse = section.getFieldByKey("meeting_strasse");
    const meetingStrasseNr = section.getFieldByKey("meeting_strasse_nr");
    const meetingWohungsnummer = section.getFieldByKey("meeting_wohungsnummer");
    const meetingPlz = section.getFieldByKey("meeting_plz");
    const meetingStadt = section.getFieldByKey("meeting_stadt");
    const preparedSelectGroup = this.prepareMeSelect();

    const meetingFugtDokumenteHinzu = section.getFieldByKey('meeting_fugt_dokumente_hinzu');
    const meetingBetreuungsfragebogen = section.getFieldByKey("meeting_betreuungsfragebogen");
    const meetingIsoZertifikat = section.getFieldByKey("meeting_iso_zertifikat");
    const meetingSicher = section.getFieldByKey("meeting_sicher_und_gut_versorgt");
    const meetingPflegeratgeber = section.getFieldByKey("meeting_pflegeratgeber");
    const meetingPrasentationPm24 = section.getFieldByKey("meeting_prasentation_pm24");
    const meetingTuvZertifikat = section.getFieldByKey("meeting_tuv_zertifikat");
    const meetingVollmacht = section.getFieldByKey("meeting_vollmacht");
    const meetingMultiplePatients = section.getFieldByKey("meeting_multiple_patients");
    const meetingCareBoxLeaflet = section.getFieldByKey("meeting_carebox_leaflet");
    const meetingKundeportal = section.getFieldByKey("meeting_kundeportal");
    // const meetingCopyToFP = section.getFieldByKey("meeting_copy_to_fp");
    const meetingDocuments = [
      section.getFieldByKey("meeting_betreuungsfragebogen"),
      section.getFieldByKey("meeting_iso_zertifikat"),
      section.getFieldByKey("meeting_sicher_und_gut_versorgt"),
      section.getFieldByKey("meeting_pflegeratgeber"),
      section.getFieldByKey("meeting_prasentation_pm24"),
      section.getFieldByKey("meeting_tuv_zertifikat"),
      section.getFieldByKey("meeting_vollmacht"),
      section.getFieldByKey("meeting_carebox_leaflet"),
      section.getFieldByKey("meeting_kundeportal"),
    ];

    if(meetingDocuments.every(item => !item.value)) {
      meetingFugtDokumenteHinzu.value = false; 
    } else {
      meetingFugtDokumenteHinzu.value = true; 
    }

    const CheckboxLinkWrapper = styled.div`
      margin-top: 20px;
      width: 100%;

      .form-control {
        &.newUI {
          margin-left: 50px;

          @media (max-width: 992px) {
            margin-left: 0;
          }
        }
      } 
    `;

    const SelectWrapper = styled.div`
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      margin-bottom: 20px;

      .quick-label-position-fix {
        text-transform: uppercase;
      }
    `;

    return (
      <div className="form-section-main">
        <div className="new-section-name-wrapper">{title}</div>
        <form className="new-section-content-wrapper container form_serializer">
          <div className="newUI-section-padding">
            <div className="row">
              <InputTypeTextComponent
                showValidation={showValidation}
                className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                setTypeChangeToParent={this.setTypeChange}
                field={meetingFirstName}
                text={translateKey("first_name")}
                transKey="meeting_first_name"
                keyName="meeting_first_name"
                disabled={true}
                newUI={true}
              />
              <InputTypeTextComponent
                showValidation={showValidation}
                className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                setTypeChangeToParent={this.setTypeChange}
                field={meetingLastName}
                text={translateKey("last_name")}
                transKey="meeting_last_name"
                keyName="meeting_last_name"
                disabled={true}
                newUI={true}
              />
              <InputTypeTextComponent
                showValidation={showValidation}
                className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                setTypeChangeToParent={this.setTypeChange}
                field={meetingEmail}
                text={translateKey("email")}
                transKey="meeting_email"
                keyName="meeting_email"
                disabled={true}
                newUI={true}
              />
              <CheckboxLinkWrapper>
                <CheckBoxComponent
                  newUI={isNewUI}
                  checkClassWrapper={classForCheckBox}
                  showValidation={showValidation}
                  className={checboxClass}
                  setTypeChangeToParent={this.setTypeChange}
                  value={meetingFugenSieDenLink.value}
                  validationErrors={meetingFugenSieDenLink.validationErrors}
                  text={translateKey("add-link-to-client-zone")}
                  transKey="meeting_fugen_sie_den_link"
                  keyName="meeting_fugen_sie_den_link"
                />
                {meetingFugenSieDenLink.value === true && (
                  <InputTypeTextComponent
                    showValidation={showValidation}
                    className="col-sm-12 col-lg-12 form-margin-bottom-fix"
                    setTypeChangeToParent={this.setTypeChange}
                    field={meetingKundenbereichLink}
                    text=""
                    transKey="meeting_kundenbereich_link"
                    keyName="meeting_kundenbereich_link"
                    disabled={true}
                    newUI={true}
                  />
                )}
              </CheckboxLinkWrapper>
            </div>
          </div>
          <Separator />

          <div className="newUI-section-padding">
            <div className="row">
              <div className="col-sm-12 col-lg-12">
                <CheckBoxComponent
                  newUI={isNewUI}
                  checkClassWrapper={classForCheckBox}
                  showValidation={showValidation}
                  className=''
                  setTypeChangeToParent={this.setTypeChange}
                  value={meetingNurDieMaterialen.value}
                  validationErrors={meetingNurDieMaterialen.validationErrors}
                  text={translateKey("arrange-meeting-send-option")}
                  transKey="meeting_nur_die_materialen"
                  keyName="meeting_nur_die_materialen"
                />
              </div>
            </div>
          </div>

          {meetingNurDieMaterialen.value === true && (
            <div className="newUI-section-padding">
              <div className="row">
                <div className="col-sm-12 col-lg-12">
                  <RadioButtonsComponent 
                    name="meeting_art_des_gesprachs"
                    options={
                      [
                        {
                          name: 'meeting_art_des_gesprachs',
                          value: TaskType.EVENT,
                          text: translateKey("personal-meeting"),
                        },
                        {
                          name: 'meeting_art_des_gesprachs',
                          value: TaskType.CALL,
                          text: translateKey("meeting-call"),
                        },
                      ]
                    }
                    value={meetingArtDesGesprachs.value}
                    setTypeChangeToParent={this.handleRdioButtonChange}
                  />
                </div>
                <div className="col-sm-12 col-lg-6">
                  <DatePickerMeetingComponent
                    timestamp={meetingTimestamp.value}
                    lang={translateKey("lang")}
                    type="ISO 8601"
                    changePropsDT={this.changeDateTime}
                    tasks={this.props.tasks}
                   
                  />
                </div>
                <div className="col-sm-12 col-lg-6 timepicker-wrapper">
                  <div>
                    <TimePickerMeetingComponent
                      value={meetingTimestamp.value}
                      timestamp={meetingTimestamp.value}
                      changePropsDT={this.changeDateTime}
                      transStunde={translateKey("arrange-meeting-stunde")}
                    />
                  </div>
                </div>
              </div>
              { meetingArtDesGesprachs.value === TaskType.EVENT && (
                <div className="row">
                  <SelectWrapper className="col-sm-12 col-lg-4">
                    <label className="form-label-basic newUI">
                        <span className="quick-label-position-fix">
                            { translateKey("meetig-choose-select") }
                        </span>
                    </label>
                    <SelectUniversalComponent
                        keyName="source"
                        valuesList={[ 
                          {
                            label: translateKey("meeting-address-kunde"),
                            value: AddressType.CLIENT,
                          },
                          {
                            label: translateKey("meeting-address-person"),
                            value: AddressType.PATIENT,
                          },
                          {
                            label: translateKey("meeting-address-other"),
                            value: AddressType.OTHER,
                          }
                        ]} 
                        value={meetingPlaceType.value}
                        optionValueKey="value"
                        optionLabelKey="label"
                        setTypeChangeToParent={this.handleChangeMeetingPlace}
                    />
                  </SelectWrapper>
                  <div className="col-12 col-lg-8"></div>
                  <InputTypeTextComponent
                    showValidation={showValidation}
                    className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                    setTypeChangeToParent={this.setAdress}
                    field={meetingStrasse}
                    text={translateKey("street")}
                    transKey="meeting_strasse"
                    keyName="meeting_strasse"
                    disabled={false}
                    newUI={true}
                  />
                  <InputTypeTextComponent
                    showValidation={showValidation}
                    className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                    setTypeChangeToParent={this.setAdress}
                    field={meetingStrasseNr}
                    text={translateKey("building_number")}
                    transKey="meeting_strasse_nr"
                    keyName="meeting_strasse_nr"
                    disabled={false}
                    newUI={true}
                  />
                  <InputTypeTextComponent
                    showValidation={showValidation}
                    className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                    setTypeChangeToParent={this.setAdress}
                    field={meetingWohungsnummer}
                    text={translateKey("apartment_number")}
                    transKey="meeting_wohungsnummer"
                    keyName="meeting_wohungsnummer"
                    disabled={false}
                    newUI={true}
                  />
                  <div className="col-sm-12 col-lg-4 form-margin-bottom-fix meeting-autosuggestion-wrapper">
                    <label className="form-label-basic newUI">
                        <span className="quick-label-position-fix">
                            { translateKey("postal_code") }
                        </span>
                    </label>
                    <AutoSuggestComponent 
                      onChange={this.handlechangePostalCode} 
                      value={meetingPlz.value} 
                      suggestions={preparedSelectGroup}
                    />
                  </div>
                  <InputTypeTextComponent
                    showValidation={showValidation}
                    className="col-sm-12 col-lg-4 form-margin-bottom-fix"
                    setTypeChangeToParent={this.setAdress}
                    field={meetingStadt}
                    text={translateKey("city")}
                    transKey="meeting_stadt"
                    keyName="meeting_stadt"
                    disabled={true}
                    newUI={true}
                  />
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  { changeAddressData ? 
                      <InfoMessage text={ translateKey("meeting-change-data-note") } />
                    : ''
                  }
                </div>
              </div>
            </div>
          )}
          <Separator />
          <div className="newUI-section-padding">
            <div className="row">
              <div className="check-box-padding-bottom-newUI">
                <CheckBoxComponent
                    newUI={isNewUI}
                    checkClassWrapper={classForCheckBox}
                    showValidation={showValidation}
                    className={checboxClass}
                    setTypeChangeToParent={() => this.setDocumentStatus(meetingDocuments)}
                    value={meetingFugtDokumenteHinzu.value}
                    validationErrors={meetingFugtDokumenteHinzu.validationErrors}
                    text={translateKey("add-documents-to-email")}
                    transKey="meeting_fugt_dokumente_hinzu"
                    keyName="meeting_fugt_dokumente_hinzu"
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-5 offset-lg-1">
                  <CheckBoxComponent
                    newUI={isNewUI}
                    checkClassWrapper={classForCheckBox}
                    showValidation={showValidation}
                    className={checboxClass}
                    setTypeChangeToParent={this.setTypeChange}
                    value={meetingBetreuungsfragebogen.value}
                    validationErrors={meetingBetreuungsfragebogen.validationErrors}
                    text={translateKey("meeting_betreuungsfragebogen")}
                    transKey="meeting_betreuungsfragebogen"
                    keyName="meeting_betreuungsfragebogen"
                  />
                  <CheckBoxComponent
                    newUI={isNewUI}
                    checkClassWrapper={classForCheckBox}
                    showValidation={showValidation}
                    className={checboxClass}
                    setTypeChangeToParent={this.setTypeChange}
                    value={meetingIsoZertifikat.value}
                    validationErrors={meetingIsoZertifikat.validationErrors}
                    text={translateKey("docs-iso-cert")}
                    transKey="meeting_iso_zertifikat"
                    keyName="meeting_iso_zertifikat"
                  />
                  <CheckBoxComponent
                    newUI={isNewUI}
                    checkClassWrapper={classForCheckBox}
                    showValidation={showValidation}
                    className={checboxClass}
                    setTypeChangeToParent={this.setTypeChange}
                    value={meetingSicher.value}
                    validationErrors={meetingSicher.validationErrors}
                    text={translateKey("meeting_sicher")}
                    transKey="meeting_sicher"
                    keyName="meeting_sicher_und_gut_versorgt"
                  />
                  <CheckBoxComponent
                    newUI={isNewUI}
                    checkClassWrapper={classForCheckBox}
                    showValidation={showValidation}
                    className={checboxClass}
                    setTypeChangeToParent={this.setTypeChange}
                    value={meetingPflegeratgeber.value}
                    validationErrors={meetingPflegeratgeber.validationErrors}
                    text={translateKey("docs-advisor")}
                    transKey="meeting_pflegeratgeber"
                    keyName="meeting_pflegeratgeber"
                  />
                  <CheckBoxComponent
                    newUI={isNewUI}
                    checkClassWrapper={classForCheckBox}
                    showValidation={showValidation}
                    className={checboxClass}
                    setTypeChangeToParent={this.setTypeChange}
                    value={meetingPrasentationPm24.value}
                    validationErrors={meetingPrasentationPm24.validationErrors}
                    text={translateKey("docs-presentation")}
                    transKey="meeting_prasentation_pm24"
                    keyName="meeting_prasentation_pm24"
                  />
              </div>
              <div className="col-sm-12 col-lg-5">
                  <CheckBoxComponent
                    newUI={isNewUI}
                    checkClassWrapper={classForCheckBox}
                    showValidation={showValidation}
                    className={checboxClass}
                    setTypeChangeToParent={this.setTypeChange}
                    value={meetingTuvZertifikat.value}
                    validationErrors={meetingTuvZertifikat.validationErrors}
                    text={translateKey("docs-tuv-cert")}
                    transKey="meeting_tuv_zertifikat"
                    keyName="meeting_tuv_zertifikat"
                  />
                  <CheckBoxComponent
                    newUI={isNewUI}
                    checkClassWrapper={classForCheckBox}
                    showValidation={showValidation}
                    className={checboxClass}
                    setTypeChangeToParent={this.setTypeChange}
                    value={meetingVollmacht.value}
                    validationErrors={meetingVollmacht.validationErrors}
                    text={translateKey("docs-fullpower")}
                    transKey="meeting_vollmacht"
                    keyName="meeting_vollmacht"
                  />
                  <CheckBoxComponent
                      newUI={isNewUI}
                      checkClassWrapper={classForCheckBox}
                      showValidation={showValidation}
                      className={checboxClass}
                      setTypeChangeToParent={this.setTypeChange}
                      value={meetingCareBoxLeaflet.value}
                      validationErrors={meetingCareBoxLeaflet.validationErrors}
                      text={translateKey("docs-care-box-leaflet")}
                      transKey="meeting_carebox_leaflet"
                      keyName="meeting_carebox_leaflet"
                  />
                  <CheckBoxComponent
                      newUI={isNewUI}
                      checkClassWrapper={classForCheckBox}
                      showValidation={showValidation}
                      className={checboxClass}
                      setTypeChangeToParent={this.setTypeChange}
                      value={meetingKundeportal.value}
                      validationErrors={meetingKundeportal.validationErrors}
                      text={translateKey("docs-kundeportal")}
                      transKey="meeting_kundeportal"
                      keyName="meeting_kundeportal"
                  />
              </div>
            </div>
          </div>
          <Separator />
          <div className="newUI-section-padding">
            <div className="row">
              <div className="check-box-padding-bottom-newUI">
                <CheckBoxComponent
                    newUI={isNewUI}
                    checkClassWrapper={classForCheckBox}
                    showValidation={showValidation}
                    className={checboxClass}
                    setTypeChangeToParent={this.setTypeChange}
                    value={meetingMultiplePatients.value}
                    validationErrors={ meetingMultiplePatients.validationErrors }
                    text={translateKey("second_patient_checkbox_label")}
                    transKey="meeting_multiple_patients"
                    keyName="meeting_multiple_patients"
                  />
              </div>
            </div>
          </div>
        </form>
        <div className='zone-form-navigation-buttons'>
          <div className="previous-next-wrapper">
            <button type="button" className="btn btn-primary cursor-pointer next-previous-class prev-next-active-button" style={{margin: '5px'}} onClick={this.nextPageClick}>
              <div className="section-navigation-button-text special-width">{translateKey("next").toUpperCase()}</div>
              <i className="fas fa-chevron-right fa-1x section-navigation-icon"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {return {
  cities: state.enums.citiesList,
}}
const mapDispatchToProps = (dispatch) => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeetingSection1));
