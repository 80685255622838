import * as React from 'react';
import { connect } from 'react-redux';
import { translateKey } from '../../../../_redux/actions/index';
import { OFFER_SECTION } from '../../../../_constance/enums/summaryOfferMenuSections';
import styled from 'styled-components';

const StyledCancelButton = styled.div`
  background: ${props => props.active ? "#1F51A2" : "white"};
  color: ${props => props.active ? "white" : 'rgb(96,103,110)'};
  font-weight: bold;
  font-size: 1.25em;
  padding: 1em 2em;
  border: 2px solid ${props => props.active ? "#1F51A2" : "rgb(170,181,193)"};
  border-radius: 5px;
  width: 400px;
  float: ${props => props.left ? "left" : 'right'};
  display: flex;
  align-items: center;
  text-align: center;
  cursor:pointer;
`;

const StyledCheckBoxWrapper = styled.div`
  color: rgb(96,103,110);
  font-weight:bold;
  padding-bottom:5px;
`;

const StyledCheckBoxLabel= styled.label`
  padding-top:3px;
`;

class CancelContract extends React.Component {
  state = {
    activeButton: null,
    contract_option_email: true,
    contract_option_summary: false,
    contract_option_rodo: false,
    contract_option_promdoc: false,
    contract_option_kopiloten: false,
    contract_option_summary_second_pateint: false, 
    contract_option_rodo_second_pateint: false,

  }

  setActiveButton = (activeNumber) =>{
    this.setState({activeButton: activeNumber})
  }

  renderButton = (activeNumber, text) => {
    const { activeButton } = this.state;

    return (
      <div className="col-6 pt-4 pb-5 pr-5 no-gutters">
        <StyledCancelButton active = {activeButton === activeNumber} left = {activeNumber!==1} className='text-center w-100 h-100' onClick={()=>this.setActiveButton(activeNumber)}>
          {text}
        </StyledCancelButton>
      </div>
    )
  }

  renderCheckBoxes = () => {
    const { activeButton } = this.state;
    const { translateKey, form, secondPatientForm, isAditionalPatient } = this.props;
    const patientName = form.getFieldByKey("is_first_name_and_last_name_of_patient").value;
    const secondPateintName = secondPatientForm.getFieldByKey("is_first_name_and_last_name_of_patient").value;
    if(activeButton!==null){
      const { contract_option_email, contract_option_summary, contract_option_rodo, contract_option_promdoc, contract_option_kopiloten, contract_option_summary_second_pateint, contract_option_rodo_second_pateint } = this.state;
      return (
        <div className='row pt-3'>
          <div className="col-12 no-gutters">
            <StyledCheckBoxWrapper>{translateKey('from_cancel_contract_checkbox_wrapper_text').toUpperCase()}</StyledCheckBoxWrapper>
            {this.renderCheckBox('contract_option_email', translateKey('from_cancel_contract_option_email'), contract_option_email, true)}
            {this.renderCheckBox('contract_option_summary', `${translateKey('from_cancel_contract_option_summary')} (${patientName})`, contract_option_summary, false)}
            {this.renderCheckBox('contract_option_rodo', `${translateKey('from_cancel_contract_option_rodo')} (${patientName})`, contract_option_rodo, false)}
            {isAditionalPatient && this.renderCheckBox('contract_option_summary_second_pateint', `${translateKey('from_cancel_contract_option_summary')} (${secondPateintName})`, contract_option_summary_second_pateint, false)}
            {isAditionalPatient && this.renderCheckBox('contract_option_rodo_second_pateint', `${translateKey('from_cancel_contract_option_rodo')} (${secondPateintName})`, contract_option_rodo_second_pateint, false)}
            {this.renderCheckBox('contract_option_promdoc', translateKey('from_cancel_contract_option_promdoc'), contract_option_promdoc, false)}
            {this.renderCheckBox('contract_option_kopiloten', translateKey('from_cancel_contract_option_kopiloten'), contract_option_kopiloten, false)}
          </div>
        </div>
      )
    }
    return null
  }

  setCheckboxValue = (key, disabled) =>{
    if(!disabled){
      this.setState({
        [key]: !this.state[key]
      })
    }
  }

  changeCheckboxValue = (key, disabled) =>{
    if(!disabled){
      this.setState({
        [key]: !this.state[key]
      })
    }
  }

  renderCheckBox = (key, text, value, disabled) => {
    return (
        <div className='checkbox-button-group-wrapper pt-1 pb-1'>
          <div className={`custom-control custom-checkbox custom-control-inline ${disabled ? '' : 'cursor-pointer'}`}>
            <i className={`fas fa-2x fa-check check-fa-checkbox-icon ${value ? 'check-fa-active' : 'check-fa-not-active'}`} onClick={() => this.setCheckboxValue(key, disabled)}></i>
            <input className="form-check-input" hidden onChange={()=> this.changeCheckboxValue(key, disabled)} checked={value} type="checkbox" name={key} id={key} value={value} />
            {text && 
              <StyledCheckBoxLabel className={`form-check-label newUI-text-color ${disabled ? '' : 'cursor-pointer'}`} htmlFor={key}>{text}</StyledCheckBoxLabel>
            }
          </div>
        </div>
    )
  }
  
  nextPageClick = () =>{
    const { activeButton } = this.state;
    let options = [];
    const { contract_option_email, contract_option_summary, contract_option_rodo, contract_option_promdoc, contract_option_kopiloten, contract_option_summary_second_pateint, contract_option_rodo_second_pateint, } = this.state;
    if(contract_option_email){options.push('contract_option_email');}
    if(contract_option_summary){options.push('contract_option_summary');}
    if(contract_option_rodo){options.push('contract_option_rodo');}
    if(contract_option_promdoc){options.push('contract_option_promdoc');}
    if(contract_option_kopiloten){options.push('contract_option_kopiloten');}
    if(contract_option_summary_second_pateint){options.push('contract_option_summary_second_pateint');}
    if(contract_option_rodo_second_pateint){options.push('contract_option_rodo_second_pateint');}

    this.props.cancelContract(activeButton, options)
  }

  setActiveOfferSection = () => {
    this.props.setActiveSection(OFFER_SECTION);
  }

  render() {
    const { translateKey } = this.props;
    const { activeButton } = this.state;

    return (
      <div className="container-fluid no-gutters p-0 d-flex flex-column cancel-contract-container">
        <div className="container">
          <div className="row no-gutters pb-4">
            <div className="col-12 no-gutters">
              <div className="section-title text-center pt-5">
                {translateKey("summary-offer-offer-section-die-auf-vertrag-werzichten")}
              </div>
            </div>
          </div>

          <div className="row">
            {this.renderButton(1, translateKey("from_cancel_contract_text_1"))}
            {this.renderButton(2, translateKey("from_cancel_contract_text_2"))}
          </div>

          {this.renderCheckBoxes()}

          {activeButton!==null && 
            <div className="zone-form-navigation-buttons">
              <div className="previous-next-wrapper">
                  <button
                    type="button"
                    className="btn btn-primary cursor-pointer next-previous-class prev-next-active-button"
                    onClick={() => this.nextPageClick()}>
                    <div className="section-navigation-button-text">{translateKey("next").toUpperCase()}</div>
                    <i className="fas fa-chevron-right fa-1x section-navigation-icon"></i>
                  </button>
              </div>
            </div>
          }
          <div className="row row-center">
            <button 
              type="button" 
              className="btn btn-success summary-offer-button summary-offer-button--long"
              onClick={this.setActiveOfferSection} 
            >
              {translateKey("summary-offer-footer-anwenden")}
              <i className="fas fa-chevron-right summary-offer-icon" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default connect(null, mapDispatchToProps)(CancelContract);