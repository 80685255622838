import * as React from 'react';

import { connect } from 'react-redux';
import { translateKey } from '../../../../_redux/actions/index';
import { IconsMap } from "../../../../_helpers/icons_set";

class MovieButton extends React.Component {
  render() {
    const { translateKey, onClick, secondTransKey } = this.props;
    return (
      <div role="button" className="movie-button default-btn-hover" onClick={onClick} >
        <img className="movie-icon" src={IconsMap.svg.movie} alt="movie"/>
        <div className="movie-button-label">
          {translateKey("summary-offer-movie-button-label")}
          {secondTransKey &&
            <>
            <br/>
            {translateKey(secondTransKey)}
            </>
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });

export default connect(null, mapDispatchToProps)(MovieButton);