import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ListComponent from "../../form-bb-components/list-component/list-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import produce from "immer";
import { Asterisk } from "../../../_helpers/field_asterix";

class SectionBeschreibung extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      element: this.props.data,
      allrequired: false,
      section: props.form.getSectionByKey('section-beschreibung'),
      showValidation: props.showValidationErrors,
      isImportant: props.isImportant
    };
  }

  componentDidUpdate(prevProps) {
    const { showValidationErrors } = this.props;
    if (prevProps.showValidationErrors !== showValidationErrors) {
      this.setState({
        showValidation: showValidationErrors,
      });
    }
  }

  setTypeChange = (key, value) => {
    const nextState = produce(this.state, draftState => {
      const { section } = draftState;
      const field = section.getFieldByKey(key);
      if (field) {
        field.value = value;
      }
    });
    this.setState(nextState);
  };

  setCheckboxValue = (value) => {
    this.props.setTypeChangeToParent(this.props.keyName, value);
  };

  render() {
    const {
      disabled,
      subsectionName,
      sectionNumber,
      showValidationErrors,
      translateKey,
      analizeFinished,
      currentSection,
      rerender,
      previousPageClick,
      nextPageClick,
      finishMe,
      form
    } = this.props;
    const { section } = this.state;
    const isPatientLives = section.getFieldByKey('is_patient_lives');
    const isNewUI = true;

    const labelClass = 'label-in-list-short'
    const columnClass = 'col-sm-12 col-lg-4 family-list-class';

    return (
      <div className="form-section-main">
        <div className="new-section-name-wrapper">
          {sectionNumber}. {subsectionName}
        </div>
        <form className="new-section-content-wrapper container form_serializer">
          <fieldset disabled={disabled} className={`${disabled ? 'no-pointer-events' : ''}`}>
            
            <div className="newUI-section-padding">
              <div className="row justify-content-md-center">
                <div className={columnClass}>
                  <div className="form-label-basic newUI cursor-pointer quick-label-position-fix">{translateKey("form-text-wohntdie")} <Asterisk isRequired/></div>
                </div>
                <div className={columnClass} />
              </div>
              <div className="row justify-content-md-center">
                <ListComponent
                  newUI={isNewUI}
                  setTypeChangeToParent={this.setTypeChange}
                  showValidation={showValidationErrors}
                  className={columnClass}
                  transKey=""
                  labelClass={labelClass}
                  key="is_patient_lives675"
                  keyName="is_patient_lives"
                  type="column"
                  value={isPatientLives.value}
                  validationErrors={isPatientLives.validationErrors}
                  valuesList={[
                    { value: '717700004', label: translateKey("form-text-alleine") },
                    { value: '717700000', label: translateKey("form-text-ehefrau") },
                    { value: '717700005', label: translateKey("form-text-enkel-enkelin") },
                    { value: '717700003', label: translateKey("form-text-mehrgenerationenhaushalt") },
                  ]}
                />
                <ListComponent
                  newUI={isNewUI}
                  setTypeChangeToParent={this.setTypeChange}
                  showValidation={false}
                  className={columnClass}
                  transKey=""
                  labelClass={labelClass}
                  key="is_patient_lives897"
                  keyName="is_patient_lives"
                  type="column"
                  value={isPatientLives.value}
                  validationErrors={isPatientLives.validationErrors}
                  valuesList={[
                    { value: '717700001', label: translateKey("form-text-sohn-tochter") },
                    { value: '717700006', label: translateKey("form-text-bruder-schwester") },
                    { value: '717700002', label: translateKey("form-text-andre") },
                  ]}
                />
                <div className="custom-control custom-radio custom-control-inline hidden">
                  <input className="form-check-input" defaultChecked={isPatientLives.value === 717700004 || isPatientLives.value === true ? true : false} data-field-name="is_patient_lives" type="radio" name="form-text-wohntdie" id="form-text-wohntdie44" value="717700004" />
                </div>
              </div>
            </div>

          </fieldset>
        </form>
        <SectionNavigationButtonsComponent
          form={form}
          analizeFinished={analizeFinished}
          currentSection={currentSection}
          rerender={rerender}
          previousPageClick={previousPageClick}
          nextPageClick={nextPageClick}
          finishMe={finishMe}
          showNotePop={disabled?false:true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) })
export default withRouter(connect(null, mapDispatchToProps)(SectionBeschreibung));