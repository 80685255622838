import * as React from 'react';
import PropTypes from 'prop-types';

class CircleWithBadge extends React.Component {
  static propTypes = {
    iconSource: PropTypes.string,
    rightNode: PropTypes.node,
    bottomLabel: PropTypes.string,
    className: PropTypes.string,
    circleClassName: PropTypes.string,
    iconClassName: PropTypes.string
  }

  static defaultProps = {
    className: '',
    circleClassName: '',
    iconClassName: ''
  }

  render() {
    const { 
      iconSource, 
      rightNode, 
      bottomLabel, 
      className, 
      circleClassName,
      iconClassName
    } = this.props; 

    return (
      <div className={`d-flex ${bottomLabel && 'flex-column'} ${className} `}>
        <div className={`circle-with-badge d-flex flex-colum justify-content-center align-items-center mb-md-2 ${circleClassName}`}>
          <img className={iconClassName} src={iconSource} alt="icon"/>
        </div>
        {bottomLabel && <div className="d-flex align-items-center text-center circle-with-badge-bottom-label">
          {bottomLabel}
        </div>}
        {rightNode && <div className="d-flex align-items-center">
          {rightNode}
        </div>}
      </div>
    );
  }
}

export default CircleWithBadge;