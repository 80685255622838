import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";
import { IconsMap } from "../../../../_helpers/index";
import * as Entity from '../../../../_forms/BBForm/values/entity';
import { getDynamicFields } from '../../../../_services/user_service';
import { UsesApp }from "../../../../_constance/enums/usesApp";

class ClientDetailsBasicComponent extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      element: this.props.element,
      is_source_acquisitionid: null,
      sourceText: "",
      exists:false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getDynamic();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  ///// Dynamic

  getDynamic() {
    const {
      match: { params }
    } = this.props;
    let dataToSend = [
      {
        Id: params.id,
        Entity: Entity.CONTACT,
        Fields: ["is_source_acquisitionid"]
      }
    ];
    getDynamicFields(dataToSend)
      .then(res => {
        if (res.length > 0) {
          if (
            res[0].fields["is_source_acquisitionid"] !== null &&
            res[0].fields["is_source_acquisitionid"] !== undefined
          ) {
            if (this._isMounted) {
              this.setState({
                is_source_acquisitionid:
                  res[0].fields["is_source_acquisitionid"]
              });
            }
          }
        }
        this.sourceOfAcquisitionFounded();
      })
      .catch(error => {
        console.log(error);
      });
  }

  sourceOfAcquisitionFounded = () => {
    if(this.state.is_source_acquisitionid){
      this.props.leadsSourceStatus.map(element => {
        if (
          element.Value.toString() ===
          this.state.is_source_acquisitionid.toUpperCase()
        ) {
          this.setState({
            sourceText: element.Label,
            exists: true
          });
          return true;
        } else {
          return false;
        }
      });
    }else{
      return false;
    }
  };

  render() {
    const { translateKey, leadsSourceStatus, element, isUsesApp } = this.props;
    const { sourceText, exists } = this.state;
    if (
      element.phone !== null ||
      element.cellPhone !== null ||
      element.email !== null ||
      element.other !== null ||
      exists
    ) {
      return (
        <div
          className="lead-first-row"
          key="clientDetailsBasicInformationLongKey1"
        >
          <div className="col-sm-12 col-lg-5">
            {element.phone !== null && (
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">
                  <a href={"tel:" + element.phone} target="_top">
                    <img
                      src={IconsMap.svg.phone}
                      alt="Email"
                      className="user-name-icon"
                    />
                  </a>
                  {translateKey("phone")}
                </label>
                <div className="col-sm-8">{element.phone}</div>
              </div>
            )}
            {element.cellPhone !== null && (
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">
                  <a href={"tel:" + element.cellPhone} target="_top">
                    <img
                      src={IconsMap.svg.phone}
                      alt="Email"
                      className="user-name-icon"
                    />
                  </a>
                  {translateKey("mobile_phone")}
                </label>
                <div className="col-sm-8">{element.cellPhone}</div>
              </div>
            )}
            {element.email !== null && (
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">
                  <a href={"mailto:" + element.email} target="_top">
                    <img
                      src={IconsMap.svg.email}
                      alt="Email"
                      className="user-name-icon"
                    />
                  </a>
                  {translateKey("email")}
                </label>
                <div className="col-sm-8">{element.email}</div>
              </div>
            )}
            {element.other !== null && (
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">
                  <span className="user-name-icon other-without-icon"></span>
                  {translateKey("other")}
                </label>
                <div className="col-sm-8">
                  <span className="lead-input-wrapper">{element.other}</span>
                </div>
              </div>
            )}
            {leadsSourceStatus.length > 0 && exists && (
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">
                  <span className="user-name-icon other-without-icon"></span>
                  {translateKey("source_of_acquisition")}
                </label>
                <div className="col-sm-8">
                  <span className="lead-input-wrapper">{sourceText}</span>
                </div>
              </div>
            )}
             {isUsesApp !== null && (
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">
                  {translateKey("uses_app")}
                </label>
                <div className="col-sm-8">
                  <span className="lead-input-wrapper">{UsesApp.filter(item => item.Value === isUsesApp).map(item => (item.Label))}</span>
                </div>
              </div>
            )}
            
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = state => {
  return {
      appLang: state.lang.appLanguage,
      leadsStatus: state.enums.leadStatus,
      leadsSourceStatus: state.enums.leadSource
  };
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientDetailsBasicComponent));