import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey, setFontSize } from '../../../../_redux/actions/index';
import { CheckBoxComponent } from '../../../form-bb-components/checkbox-component/checkbox-component';
import { FontType } from '../../../../_constance/enums/fontType';

class Section2 extends React.Component {
  constructor(props) {
    super(props);
    this.showComponent = this.showComponent.bind(this);
    this.state = {
      sectionList: this.props.sectionList,
      activeSection: this.props.activeSection,
      idComponent: false,
      smallTextCheckboxChecked: true,
      mediumTextCheckboxChecked: false,
      largeTextCheckboxChecked: false,
      fontSize: this.props.fontSize,
    };
  }

  componentDidMount() {
    this.setFontType();
  }

  changeSectionPage(number) {
    this.props.changeSection(number);
  }
  
  showComponent(idComponent) {
    this.props.showComponent(idComponent);
  }
  
  setFontType = () => {
    const { fontSize } = this.state
    switch (fontSize) {
      case FontType.SMALL :
        this.setTypeChange('smallTextCheckboxChecked', true);
        break;
      case FontType.MEDIUM : 
        this.setTypeChange('mediumTextCheckboxChecked', true);
        break;
      case FontType.BIG : 
        this.setTypeChange('largeTextCheckboxChecked', true);
        break;
      default:
        this.setTypeChange('smallTextCheckboxChecked', true);
    } 
  }

  setTypeChange = (key, value) => {
    if(value) {
      switch (key) {
        case 'smallTextCheckboxChecked':
          this.setState({
            smallTextCheckboxChecked: true,
            mediumTextCheckboxChecked: false,
            largeTextCheckboxChecked: false,
          });
          break;
        case 'mediumTextCheckboxChecked':
          this.setState({
            smallTextCheckboxChecked: false,
            mediumTextCheckboxChecked: true,
            largeTextCheckboxChecked: false,
          });
          break;
        case 'largeTextCheckboxChecked':
          this.setState({
            smallTextCheckboxChecked: false,
            mediumTextCheckboxChecked: false,
            largeTextCheckboxChecked: true,
          });
          break;
          default:
            this.setState({
              smallTextCheckboxChecked: true,
              mediumTextCheckboxChecked: false,
              largeTextCheckboxChecked: false,
            });
      }
    }
  }

  setFontSize() {
    const { setFontSize } = this.props;

    if (this.state.mediumTextCheckboxChecked) {
      setFontSize(FontType.MEDIUM);
      document.body.classList.add('medium-text');
      if(document.body.classList.contains('big-text')) {
        document.body.classList.remove('big-text');
      }
    } else if (this.state.largeTextCheckboxChecked) {
      setFontSize(FontType.BIG);
      document.body.classList.add('big-text');
      if(document.body.classList.contains('medium-text')) {
        document.body.classList.remove('medium-text');
      }
    } else {
      setFontSize(FontType.SMALL);
      if(document.body.classList.contains('medium-text')) {
        document.body.classList.remove('medium-text');
      }
      if(document.body.classList.contains('big-text')) {
        document.body.classList.remove('big-text');
      }
    }
    this.props.toogleMenu();
  }

  render() {
    const {
      translateKey,
    } = this.props;
    return (
      <div className="newUI-section-padding new-menu__section-row ">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="new-menu__section-title">
                {translateKey("new-hamburger-menu_change-font-size").toUpperCase()}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <CheckBoxComponent
              newUI={ true }
              checkClassWrapper='newUI-radio-check-box-class'
              showValidation={ false }
              className='col-sm-12 col-lg-12'
              setTypeChangeToParent={ this.setTypeChange }
              value={ this.state.smallTextCheckboxChecked }
              validationErrors={ false }
              text={ translateKey("new-hamburger-menu_small") }
              transKey="smallTextCheckboxChecked"
              keyName="smallTextCheckboxChecked"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <CheckBoxComponent
              newUI={ true }
              checkClassWrapper='newUI-radio-check-box-class'
              showValidation={ false }
              className='col-sm-12 col-lg-12'
              setTypeChangeToParent={ this.setTypeChange }
              value={ this.state.mediumTextCheckboxChecked }
              validationErrors={ false }
              text={ translateKey("new-hamburger-menu_medium") }
              transKey="mediumTextCheckboxChecked"
              keyName="mediumTextCheckboxChecked"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <CheckBoxComponent
              newUI={ true }
              checkClassWrapper='newUI-radio-check-box-class'
              showValidation={ false }
              className='col-sm-12 col-lg-12'
              setTypeChangeToParent={ this.setTypeChange }
              value={ this.state.largeTextCheckboxChecked }
              validationErrors={ false }
              text={ translateKey("new-hamburger-menu_large") }
              transKey="largeTextCheckboxChecked"
              keyName="largeTextCheckboxChecked"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <button 
                type="button" 
                className="btn btn-success button-order"
                onClick={() => {this.setFontSize()}} 
                >
                    {translateKey("new-hamburger-menu_save").toUpperCase()}
                <i className="fas fa-chevron-right summary-offer-icon" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    fontSize: state.fontSize.fontSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  setFontSize: type => dispatch(setFontSize(type)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section2));
