export class CarerTable {
    constructor(element, survey = []){
        let carer ='';
        if(element.lastName){
            carer +=element.lastName;
            if(element.firstName){
                carer += ' ' + element.firstName;
            }
        }else{
            if(element.firstName){
                carer += ' ' + element.firstName;
            }
        }
        this.about = element.about? element.about: '';
        this.age = element.age? element.age: 0;
        this.clientId = element.clientId? element.clientId: 0;
        this.contractId = element.contractId? element.contractId: 0;
        this.contactId = element.contactId? element.contactId: 0;
        this.country = element.country? element.country: '';
        this.drivingAutomatic = element.drivingAutomatic? element.drivingAutomatic: '';
        this.drivingManual = element.drivingManual? element.drivingManual: '';
        this.experience = element.experience? element.experience: '';
        this.firstName = element.firstName? element.firstName: '';
        this.from = element.from? element.from: 0;
        this.id = element.id? element.id: 0;
        this.lastName = element.lastName? element.lastName: '';
        this.phoneNumber = element.phoneNumber? element.phoneNumber: '';
        this.photo = element.photo? element.photo: '';
        this.photoVersion = element.photoVersion? element.photoVersion: '';
        this.status = element.status? element.status: 0;
        this.to = element.to? element.to: 0;
        this.wzid = element.wzid? element.wzid: '';
        this.carer = carer;
        this.trackingStatus = element.trackingStatus ? element.trackingStatus : null;
        this.dateAndTimeArrival = element.dateAndTimeArrival ? element.dateAndTimeArrival : 0;
        this.tripTrackingLink = element.tripTrackingLink ? element.tripTrackingLink : '';
        this.travelStatus = element.travelStatus ? element.travelStatus : null;
        this.isBreak = element.isBreak? element.isBreak: false;
        this.cvProduct = element.cvProduct? element.cvProduct: '';
        this.survey = survey;
    }
};