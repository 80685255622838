import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ListMovementComponent from '../../form-bb-components/list-movement-component/list-movement-component';
import { CheckBoxSmallMultipleComponent } from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import { IconsMap } from '../../../_helpers/icons_set';
import produce from "immer";

class Section10 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      element: props.data,
      allrequired: false,
      section: props.form.getSectionByKey('section-10'),
      showValidation: props.showValidationErrors,
    };
  }

  componentDidUpdate(prevProps) {
    const { showValidationErrors } = this.props;
    if (prevProps.showValidationErrors !== showValidationErrors) {
      this.setState({
        showValidation: showValidationErrors,
      });
    }
  }

  setTypeChange = (key, value) => {
    const nextState = produce(this.state, draftState => {
      const { section } = draftState;
      const field = section.getFieldByKey(key);
      if (field) {
        field.value = value;
      }
    });

    this.setState(nextState);
  };

  render() {
    const {
      disabled,
      sectionNumber,
      subsectionName,
      showValidationErrors,
      translateKey,
      analizeFinished,
      currentSection,
      rerender,
      previousPageClick,
      nextPageClick,
      finishMe,
      form
    } = this.props;
    const { section } = this.state;
    const isNewUI = true;
    const classForCheckBox = "custom-control-inline illness-wrapper newUI-radio-check-box-class cursor-pointer";
    const classForList = 'col-sm-12 col-lg-3 form-margin-bottom-fix text-align-center';
    const is_bathing_shower = section.getFieldByKey('is_bathing_shower');
    const is_skin_care = section.getFieldByKey('is_skin_care');
    const is_oral_care_and_dentures = section.getFieldByKey('is_oral_care_and_dentures');
    const is_hair_care = section.getFieldByKey('is_hair_care');
    const is_shaving = section.getFieldByKey('is_shaving')
    const is_intimate_hygiene = section.getFieldByKey('is_intimate_hygiene')

    return (
      <div className="form-section-main">
        <div className="new-section-name-wrapper">
          {sectionNumber}. {subsectionName}
        </div>
        <form className="new-section-content-wrapper container form_serializer">
          <fieldset disabled={disabled} className={`${disabled ? 'no-pointer-events' : ''}`}>
            <div className="newUI-section-padding">
              <div className="row">
                <ListMovementComponent newUI={isNewUI} type="column" svgIcon icon={IconsMap.svg.hygiene.duschen} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={classForList} transKey="form-text-duschen-baden" key="is_bathing_shower" keyName="is_bathing_shower" field={is_bathing_shower} value={is_bathing_shower.value} validationErrors={is_bathing_shower.validationErrors} />
                <ListMovementComponent newUI={isNewUI} type="column" svgIcon icon={IconsMap.svg.hygiene.hautpflege} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={classForList} transKey="form-text-hautpflege" key="is_skin_care" keyName="is_skin_care" field={is_skin_care} value={is_skin_care.value} validationErrors={is_skin_care.validationErrors} />
                <ListMovementComponent newUI={isNewUI} type="column" svgIcon icon={IconsMap.svg.hygiene.mundpflege} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={classForList} transKey="form-text-mundpflege" key="is_oral_care_and_dentures" keyName="is_oral_care_and_dentures" field={is_oral_care_and_dentures} value={is_oral_care_and_dentures.value} validationErrors={is_oral_care_and_dentures.validationErrors} />
                <ListMovementComponent newUI={isNewUI} type="column" svgIcon icon={IconsMap.svg.hygiene.haarpflege} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={classForList} transKey="form-text-haarpflege" key="is_hair_care" keyName="is_hair_care" field={is_hair_care} value={is_hair_care.value} validationErrors={is_hair_care.validationErrors} />
              </div>
            </div>
            <div className="newUI-section-padding">
              <div className="row">
                <ListMovementComponent newUI={isNewUI} type="column" svgIcon icon={IconsMap.svg.hygiene.intimpflege} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={classForList} transKey="form-text-intimpflege" key="is_intimate_hygiene" keyName="is_intimate_hygiene" field={is_intimate_hygiene} value={is_intimate_hygiene.value} validationErrors={is_intimate_hygiene.validationErrors} />
                <ListMovementComponent newUI={isNewUI} type="column" svgIcon icon={IconsMap.svg.hygiene.rasieren} showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className={classForList} transKey="form-text-rasieren" key="is_shaving" keyName="is_shaving" field={is_shaving} value={is_shaving.value} validationErrors={is_shaving.validationErrors} />
              </div>
            </div>
            <div className="newUI-section-padding">
              <div className="form-label-basic newUI cursor-pointer quick-label-position-fix">{translateKey("form-text-hilfsmittel").toUpperCase()}</div>
              <div className="checkboxes_wrapper row">
                <div className="col-sm-6 col-lg-3">
                  <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-nachtstuhl"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_toilet_chair')} text={translateKey("form-text-nachtstuhl")} transKey="form-text-nachtstuhl" keyName="is_toilet_chair" />
                </div>
                <div className="col-sm-6 col-lg-3">
                  <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-duschstuhl"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_shower_chair')} text={translateKey("form-text-duschstuhl")} transKey="form-text-duschstuhl" keyName="is_shower_chair" />
                </div>
                <div className="col-sm-6 col-lg-3">
                  <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-badewannenlift"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_lift_by_the_bath')} text={translateKey("form-text-badewannenlift")} transKey="form-text-badewannenlift" keyName="is_lift_by_the_bath" />
                </div>
                <div className="col-sm-6 col-lg-3">
                  <CheckBoxSmallMultipleComponent newUI={isNewUI} className={classForCheckBox} fieldName={"form-text-toilettensitzerhöhung"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_toilet_seat')} text={translateKey("form-text-toilettensitzerhöhung")} transKey="form-text-toilettensitzerhöhung" keyName="is_toilet_seat" />
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <SectionNavigationButtonsComponent
          form={form}
          analizeFinished={analizeFinished}
          currentSection={currentSection}
          rerender={rerender}
          previousPageClick={previousPageClick}
          nextPageClick={nextPageClick}
          finishMe={finishMe}
          showNotePop={disabled?false:true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) })
export default withRouter(connect(null, mapDispatchToProps)(Section10));